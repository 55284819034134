<template>
  <b-form
    class="d-flex align-center gap-3 justify-space-between"
    style="height: 36px"
    hide-action-buttons
    :submit="submit">
    <div
      :style="{ 'max-width': showInput ? '100%' : '85%' }"
      :class="{ 'w-100': showInput }">
      <v-scroll-x-transition hide-on-leave>
        <b-input
          v-show="showInput"
          v-model="value"
          hide-details
          placeholder="введіть значення"></b-input>
      </v-scroll-x-transition>
      <v-scroll-x-transition hide-on-leave>
        <div v-show="!showInput" class="w-100">
          <a
            v-if="value"
            :href="value"
            target="_blank"
            class="link text-truncate d-inline-block w-100">
            {{ value }}
          </a>
          <span v-else class="text-disabled">Не вказано</span>
        </div>
      </v-scroll-x-transition>
    </div>

    <v-btn
      v-if="showInput"
      v-tooltip:left="'Підтвердити'"
      icon="mdi-check"
      density="comfortable"
      size="small"
      color="#fb7146"
      type="submit"></v-btn>
    <v-btn
      v-else
      v-tooltip:left="'Редагувати'"
      icon="mdi-pencil"
      density="comfortable"
      size="small"
      color="#fb7146"
      @click="showInput = true"></v-btn>
  </b-form>
</template>

<script lang="ts">
import { ref, watch } from 'vue'
import { BForm } from 'best-modules/components'

export default {
  name: 'LinkEditor',
  emits: ['update:model-value'],
  components: { BForm },
  props: {
    modelValue: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const value = ref<string>()
    const showInput = ref<boolean>(false)

    const submit = () => {
      emit('update:model-value', value.value)
      showInput.value = false
    }

    watch(
      () => props.modelValue,
      v => {
        if (v && v !== value.value) {
          value.value = String(v)
        }
      },
      { immediate: true }
    )

    return { value, showInput, submit }
  },
}
</script>

<style scoped></style>
