<template>
  <div>
    <SectionLoader v-if="asyncLoading('getPlanList')" />
    <div v-else>
      <v-data-table
        class="calc-table --cust-cell-pd"
        :items="planList"
        :headers="planListHeaders"
        :items-per-page="-1"
        :hide-default-header="!planList.length"
        hide-default-footer
        @dblclick:row="navigateToPlan">
      </v-data-table>
    </div>
  </div>
</template>

<script>
import SectionLoader from '@/components/section-loader.vue'
import { urlGetPlanList } from '@/pages/request'
import { useSelect } from '@/utils/mixins/useSelect'
import { planListHeaders } from '@/utils/tableHeaders'

export default {
  name: 'PlanList',
  components: { SectionLoader },
  setup() {
    return {
      ...useSelect(),
    }
  },
  data() {
    return {
      planList: [],
      planListHeaders,
    }
  },
  methods: {
    getPlanList() {
      return this.asyncAction('getPlanList', null, () => {
        return this.$axios.get(urlGetPlanList()).then(res => {
          this.planList = res.data
        })
      })
    },
    navigateToPlan(e, row) {
      this.$router.push({ name: 'plan-single', params: { id: row.item.id } })
    },
  },
  created() {
    this.getPlanList()
  },
}
</script>
