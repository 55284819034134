<template>
  <div>
    <Item
      v-model="carData.leasingObjectType"
      label="Тип об'єкта"
      placeholder="оберіть тип"
      :items="items.leasingObjectTypes"
      :loading="asyncLoading('getLeasingObjectTypes')"
      :error-messages="leasingObjectTypeErr"
      @update:model-value="carSelectHandler('leasingObjectType', $event)"
      @focus="getCarData('leasingObjectType')"
      @blur="v && v.leasingObjectType.$touch()" />
    <Item
      v-if="!standardType"
      v-model="carData.body"
      label="Тип кузова"
      placeholder="оберіть тип кузова"
      :items="items.bodies"
      :loading="asyncLoading('getBodies')"
      :error-messages="bodyErr"
      @update:model-value="carSelectHandler('body', $event)"
      @focus="getCarData('body')"
      @blur="v && v.body.$touch()" />
    <Item
      v-model="carData.leasedAssertMark"
      label="Марка"
      placeholder="оберіть марку"
      component="b-autocomplete"
      :items="items.leasedAssertMarks"
      :loading="asyncLoading('getLeasedAssertMarks')"
      :error-messages="leasedAssertMarkErr"
      @update:model-value="carSelectHandler('leasedAssertMark', $event)"
      @focus="getCarData('leasedAssertMark')"
      @blur="v && v.leasedAssertMark.$touch()" />
    <v-row v-if="commercialProperty" class="mb-3">
      <v-col :cols="5" class="pt-0 pb-0">
        <span
          class="card-label calc"
          :class="{ 'required-value': !carData.leasedAssertModel }">
          Адреса
        </span>
      </v-col>
      <v-col :cols="7" class="pt-0 pb-0">
        <div>
          <v-text-field
            v-model="carData.leasedAssertModel.name"
            class="mt-0 pt-0"
            placeholder="введіть адресу"
            :error-messages="leasedAssertModelErr"
            @blur="v && v.leasedAssertModel.$touch()"></v-text-field>
        </div>
      </v-col>
    </v-row>
    <Item
      v-else
      v-model="carData.leasedAssertModel"
      label="Модель"
      component="b-autocomplete"
      placeholder="оберіть модель"
      :items="items.leasedAssertModels"
      :loading="asyncLoading('getLeasedAssertModels')"
      :error-messages="leasedAssertModelErr"
      @update:model-value="carSelectHandler('leasedAssertModel', $event)"
      @focus="getCarData('leasedAssertModel')"
      @blur="v && v.leasedAssertModel.$touch()" />
    <v-row v-if="!standardType" class="mb-3">
      <v-col :cols="5" class="pt-0 pb-0">
        <span
          class="card-label calc"
          :class="{ 'required-value': !carData.leasingObjectYear }">
          Рік випуску
        </span>
      </v-col>
      <v-col :cols="7" class="pt-0 pb-0">
        <div>
          <v-select
            v-model="carData.leasingObjectYear"
            placeholder="оберіть рік"
            :items="[...items.leasingObjectYears].sort((a, b) => b - a)"
            :error-messages="leasingObjectYearErr"
            hide-details
            @blur="v && v.leasingObjectYear.$touch()">
          </v-select>
        </div>
      </v-col>
    </v-row>
    <div v-if="standardType">
      <Item
        v-model="carData.leasingObjectYear"
        class="mt-1"
        label="Рік випуску"
        placeholder="оберіть рік"
        :items="[...items.leasingObjectYears].sort((a, b) => b - a)"
        :error-messages="leasingObjectYearErr"
        item-value="year"
        item-title="year"
        @update:model-value="carSelectHandler('leasingObjectYear', $event)"
        @focus="getCarData('leasingObjectYear')"
        @blur="v && v.leasingObjectYear.$touch()" />
      <Item
        v-model="carData.generation"
        label="Покоління"
        placeholder="оберіть покоління"
        :items="items.generations"
        :loading="asyncLoading('getGenerations')"
        :error-messages="generationErr"
        @update:model-value="carSelectHandler('generation', $event)"
        @focus="getCarData('generation')"
        @blur="v && v.generation.$touch()" />
      <Item
        v-model="carData.body"
        label="Тип кузова"
        placeholder="оберіть тип кузова"
        :items="items.bodies"
        :loading="asyncLoading('getBodies')"
        :error-messages="bodyErr"
        @update:model-value="carSelectHandler('body', $event)"
        @focus="getCarData('body')"
        @blur="v && v.body.$touch()" />
      <Item
        v-model="carData.modification"
        label="Модифікація"
        placeholder="оберіть модифікація"
        :items="items.modifications"
        :loading="asyncLoading('getModifications')"
        :error-messages="modificationErr"
        @update:model-value="carSelectHandler('modification', $event)"
        @focus="getCarData('modification')"
        @blur="v && v.modification.$touch()" />
      <v-row
        v-if="truck"
        class="mb-3"
        style="min-height: 45px; padding-top: 10px">
        <v-col :cols="12" :sm="5" class="pt-0 pb-0">
          <span
            class="card-label calc"
            :class="{ 'required-value': !carData.weight }">
            Вага
          </span>
        </v-col>
        <v-col :cols="12" :sm="7" class="pt-0 pb-0">
          <div>
            <v-card v-if="carData.weight" elevation="0" outlined>
              <v-card-text style="background: #fff" class="pr-4">
                <div class="d-flex align-center justify-space-between mb-2">
                  <span class="disabled">Споряджена маса</span>
                  <span>{{ carData.weight.curbWeight }} кг</span>
                </div>
                <div class="d-flex align-center justify-space-between mb-2">
                  <span class="disabled">Повна маса</span>
                  <span>{{ carData.weight.totalWeight }} кг</span>
                </div>
                <div class="d-flex align-center justify-space-between mb-2">
                  <span class="disabled">Вантажопідйомність</span>
                  <span>{{ carData.weight.loadCapacity }} кг</span>
                </div>
              </v-card-text>
            </v-card>
            <div v-else class="disabled">Не вказано</div>
          </div>
        </v-col>
      </v-row>
      <Item
        v-model="carData.specification"
        label="Специфікація"
        placeholder="оберіть специфікацію"
        :items="items.specifications"
        :loading="asyncLoading('getSpecification')"
        :error-messages="specificationErr"
        @update:model-value="carSelectHandler('specification', $event)"
        @focus="getCarData('specification')"
        @blur="v && v.specification.$touch()" />
    </div>
  </div>
</template>

<script>
import carApi from './car-api'

export default carApi
</script>
