<template>
  <div class="d-block flex-column">
    <div class="mb-5 font-weight-bold d-flex">
      <v-icon style="margin-right: 8px" size="20">
        {{ 'mdi-blur' }}
      </v-icon>
      Нагадування: {{ dialog.dialogItem.taskMessage || 'данi Відсутні' }}
    </div>
    <v-divider></v-divider>
    <div class="mb-2 mt-2 d-flex">
      <v-icon style="margin-right: 8px" size="20">
        {{ 'mdi-check-circle' }}
      </v-icon>
      Статус:
      {{ dialog.dialogItem.isDobe ? 'Завершено' : 'Не завершено' }}
    </div>
    <v-divider></v-divider>
    <div class="mb-5 mt-5 d-flex">
      <v-icon style="margin-right: 8px" size="20">
        {{ 'mdi-alert-circle-check-outline' }}
      </v-icon>
      Заплановано на:
      {{ toFormatDate(dialog.dialogItem.date) }}
    </div>
    <div class="mb-5 mt-2 d-flex">
      <v-icon style="margin-right: 8px" size="20">
        {{ 'mdi-account-box-outline' }}
      </v-icon>
      Відповідальний:
      {{ getContactInitials(dialog.dialogItem.user, { isResponsible: true }) }}
    </div>
    <div class="mb-5 mt-4 d-flex">
      <v-icon style="margin-right: 8px" size="20">
        {{ 'mdi-account-box-outline' }}
      </v-icon>
      <div>
        Нагадування створено:
        {{ toFormatDate(dialog.dialogItem.created_at) }}
      </div>
      <div>
        Остання редакцiя:
        {{ toFormatDate(dialog.dialogItem.updated_at) }}
      </div>
    </div>
  </div>
</template>
<script>
import { toFormatDate, getContactInitials } from '@/utils/helperFunc'
export default {
  props: {
    dialog: { type: Object },
  },
  methods: {
    toFormatDate,
    getContactInitials,
  },
}
</script>

<style></style>
