<template>
  <div class="mt-2">
    <SectionLoader v-if="!callingHistoryItems"></SectionLoader>
    <div v-show="callingHistoryItems">
      <CallingHistory class="mt-3" :tableBody="callingHistoryItems"> </CallingHistory>
    </div>
  </div>
</template>

<script>
import CallingHistory from '@/components/CallingHistory.vue'
import SectionLoader from '@/components/section-loader.vue'
import { urlGetContractorCallingHistory } from '@/pages/request'
export default {
  components: {
    SectionLoader,
    CallingHistory,
  },
  data: () => ({
    callingHistoryItems: null,
  }),
  methods: {
    getContractorCallingHistory() {
      return this.$axios
        .get(urlGetContractorCallingHistory(this.$route.params.id))
        .then(res => {
          this.callingHistoryItems = res.data.data
        })
        .catch(this.$err)
    },
  },
  mounted() {
    this.getContractorCallingHistory()
  },
}
</script>

<style scoped></style>
