<template>
  <div>
    <SectionLoader v-if="!editorInit" />
    <v-card v-else :disabled="isOnlyView">
      <Editor
        :id="editorId"
        :api-key="key"
        language="uk"
        :initial-value="manualEditorship"
        :init="editorConfig(editorId, [], [])"
        @on-init="customizeEditor()">
      </Editor>
    </v-card>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
import { urlUpdateExtraAgreementEditorShip } from '@/pages/request'
import Editor from '@tinymce/tinymce-vue'
import { editorConfig, getHtml, setHtml } from '@/utils/tinymce-config'
import SectionLoader from '@/components/section-loader.vue'

export default {
  components: { SectionLoader, Editor },
  emits: ['editorInit'],
  props: {
    dialog: { type: Object },
  },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  data: () => {
    return {
      editorInit: false,
      manualEditorship: null,
      editorId: 'tiny-manualEditorship-du',
    }
  },
  computed: {
    key() {
      return import.meta.env.VITE_API_KEY_TINYMCE
    },
    isOnlyView() {
      return this.dialog.params.isOnlyView
    },
  },
  methods: {
    getHtml,
    setHtml,
    editorConfig,
    customizeEditor() {
      this.$emit('editorInit')
      setTimeout(() => {
        document.getElementById(this.editorId).style.display = 'none'
        this.editorInit = true
      }, 0)
    },
    handleRequest() {
      return this.$axios.post(
        urlUpdateExtraAgreementEditorShip(this.dialog.dialogItem.id),
        {
          editorshipId: this.dialog.dialogItem.editorshipId,
          manualEditorship: this.manualEditorship,
        }
      )
    },
    checkExist() {
      return this.$setSnackbar({
        text: 'Пункт договору: ручне введення',
        color: 'error',
      })
    },
    submit() {
      this.manualEditorship = this.getHtml(this.editorId)

      if (this.manualEditorship) {
        return this.handleRequest()
      } else {
        this.checkExist()
        return Promise.reject('Пункт договору: ручне введення')
      }
    },
  },
  created() {
    this.manualEditorship = this.dialog.dialogItem?.manualEditorship
    this.editorInit = true
  },
}
</script>

<style scoped></style>
