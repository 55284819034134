<template>
  <v-card-text
    style="
      width: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;
    ">
    <div v-if="guarantor.id">
      <div class="d-flex align-center" style="justify-content: space-between">
        <div class="data-card">
          <span class="area-label">Поручитель</span>
          <p class="title-label">
            <ALink
              :text="guarantor.shortName || guarantor.fullName"
              :route="
                getEntityRoute(guarantor.entityId, guarantor.entityTypeId)
              ">
            </ALink>
          </p>
        </div>
        <div v-if="printedForms.length">
          <TableCrud
            class="ml-2"
            customIcon="Printer"
            :actionsArray="printedForms" />
        </div>
      </div>
      <OpenableSection title="Документи">
        <template #body>
          <FilesUploader
            readonly
            label="Заявка анкета поручителя"
            :documents="guarantorApplicationForm" />
          <FilesUploader
            v-model:documents="guarantorApplicationFormSigned"
            :readonly="readonly"
            label="Заявка анкета поручителя (підписаний)"
            :disableDeleteAction="!userAdmin" />
          <div v-if="guarantor.entityTypeId === 2">
            <FilesUploader
              v-if="projecContractortDocs.variousWritingsExplanations.show"
              showDelete
              :readonly="readonly"
              label="Різні листи - пояснення"
              :documents="
                $getSafe(
                  () =>
                    projectData.variousWritingsExplanations[
                      docIdx('variousWritingsExplanations')
                    ].doc
                )
              "
              createProp
              :disableDeleteAction="!userAdmin"
              @delete-field="
                deleteFile(
                  'variousWritingsExplanations',
                  docIdx('variousWritingsExplanations')
                )
              "
              @loaded="addFile('variousWritingsExplanations', $event)"
              @delete="
                deleteFile(
                  'variousWritingsExplanations',
                  docIdx('variousWritingsExplanations')
                )
              " />
            <FilesUploader
              v-if="projecContractortDocs.protocolPPE.show"
              showDelete
              :readonly="readonly"
              label="Рішення/Протокол ЗЗУ"
              :documents="
                $getSafe(
                  () => projectData.protocolPPE[docIdx('protocolPPE')].doc
                )
              "
              createProp
              :disableDeleteAction="!userAdmin"
              @delete-field="deleteFile('protocolPPE', docIdx('protocolPPE'))"
              @loaded="addFile('protocolPPE', $event)"
              @delete="deleteFile('protocolPPE', docIdx('protocolPPE'))" />
            <FilesUploader
              v-if="projecContractortDocs.certificateInstaller.show"
              showDelete
              :readonly="readonly"
              label="Довідка про установчі"
              :documents="
                $getSafe(
                  () =>
                    projectData.certificateInstaller[
                      docIdx('certificateInstaller')
                    ].doc
                )
              "
              createProp
              :disableDeleteAction="!userAdmin"
              @delete-field="
                deleteFile(
                  'certificateInstaller',
                  docIdx('certificateInstaller')
                )
              "
              @loaded="addFile('certificateInstaller', $event)"
              @delete="
                deleteFile(
                  'certificateInstaller',
                  docIdx('certificateInstaller')
                )
              " />
            <AdditionalDocs
              :key="updateKey"
              v-model:documents="projecContractortDocs" />
          </div>
          <div v-else>
            <FilesUploader
              v-if="projecContactDocs.consentOfSpouses.show"
              showDelete
              :readonly="readonly"
              label="Згода подружжя"
              :documents="
                $getSafe(
                  () =>
                    projectData.consentOfSpouses[docIdx('consentOfSpouses')].doc
                )
              "
              createProp
              :disableDeleteAction="!userAdmin"
              @delete-field="
                deleteFile('consentOfSpouses', docIdx('consentOfSpouses'))
              "
              @loaded="addFile('consentOfSpouses', $event)"
              @delete="
                deleteFile('consentOfSpouses', docIdx('consentOfSpouses'))
              " />
            <FilesUploader
              v-if="projecContactDocs.certificateOfMaritalStatus.show"
              showDelete
              :readonly="readonly"
              label="Довідка про сімейний стан"
              :documents="
                $getSafe(
                  () =>
                    projectData.certificateOfMaritalStatus[
                      docIdx('certificateOfMaritalStatus')
                    ].doc
                )
              "
              createProp
              :disableDeleteAction="!userAdmin"
              @delete-field="
                deleteFile(
                  'certificateOfMaritalStatus',
                  docIdx('certificateOfMaritalStatus')
                )
              "
              @loaded="addFile('certificateOfMaritalStatus', $event)"
              @delete="
                deleteFile(
                  'certificateOfMaritalStatus',
                  docIdx('certificateOfMaritalStatus')
                )
              " />
            <FilesUploader
              v-if="projecContactDocs.variousWritingsExplanations.show"
              showDelete
              :readonly="readonly"
              label="Різні листи - пояснення"
              :documents="
                $getSafe(
                  () =>
                    projectData.variousWritingsExplanations[
                      docIdx('variousWritingsExplanations')
                    ].doc
                )
              "
              createProp
              :disableDeleteAction="!userAdmin"
              @delete-field="
                deleteFile(
                  'variousWritingsExplanations',
                  docIdx('variousWritingsExplanations')
                )
              "
              @loaded="addFile('variousWritingsExplanations', $event)"
              @delete="
                deleteFile(
                  'variousWritingsExplanations',
                  docIdx('variousWritingsExplanations')
                )
              " />
            <AdditionalDocs
              :key="updateKey"
              v-model:documents="projecContactDocs" />
          </div>
        </template>
      </OpenableSection>
    </div>
  </v-card-text>
</template>

<script>
import AdditionalDocs from '@/components/AdditionalDocs.vue'
import ALink from '@/components/Link.vue'
import OpenableSection from '@/components/OpenableSection.vue'
import FilesUploader from '@/components/FilesUploader.vue'
import { mapState } from 'vuex'
import { getEntityRoute, getEntityName, openDocument } from '@/utils/helperFunc'
import {
  urlGetGuarantorDecisionParticipantPf,
  urlGetGuarantorFoundingDocuments,
  urlGetGuarantorGeneralMeetingsPf,
  urlGetPersonalDataGuarantor,
  urlGetProjectPf,
  urlUpdateGuarantor,
} from '@/pages/request'

import { useAdditionalDocs } from '@/utils/mixins/useAdditionalDocs'
import { setSnackbar } from 'best-modules/plugins'
import TableCrud from '@/components/table-crud.vue'

export default {
  components: {
    TableCrud,
    OpenableSection,
    FilesUploader,
    ALink,
    AdditionalDocs,
  },
  setup() {
    return {
      ...useAdditionalDocs(),
    }
  },
  props: {
    project: { type: Object },
    guarantor: { type: Object },
    readonly: { type: Boolean, default: false },
  },
  data: () => ({
    generalObject: 'project',
    projecContactDocs: {
      variousWritingsExplanations: {
        label: 'Різні листи - пояснення',
      },
      certificateOfMaritalStatus: {
        label: 'Довідка про сімейний стан',
      },
      consentOfSpouses: { label: 'Згода подружжя' },
    },
    projecContractortDocs: {
      variousWritingsExplanations: {
        label: 'Різні листи - пояснення',
      },
      protocolPPE: { label: 'Рішення/Протокол ЗЗУ' },
      certificateInstaller: { label: 'Довідка про установчі' },
    },
    documents: {
      contractor: [
        {
          label: 'Заявка анкета поручителя (підписаний)',
          key: 'applicationFormSigned',
        },
        { label: 'Довідка про установчі', key: 'certificateInstaller' },
        { label: 'Рішення/Протокол ЗЗУ', key: 'protocolPPE' },
        {
          label: 'Різні листи - пояснення',
          key: 'variousWritingsExplanations',
        },
      ],
      contact: [
        {
          label: 'Заявка анкета поручителя (підписаний)',
          key: 'applicationFormSigned',
        },
        { label: 'Згода подружжя', key: 'consentOfSpouses' },
        {
          label: 'Довідка про сімейний стан',
          key: 'certificateOfMaritalStatus',
        },
        {
          label: 'Різні листи - пояснення',
          key: 'variousWritingsExplanations',
        },
      ],
    },
  }),
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    userAdmin() {
      return this.user.roleId === 1
    },
    projectData() {
      return this.project
    },
    printedForms() {
      const forms = []

      if (this.guarantor?.contact) {
        const title = this.guarantor?.contact?.marriedId
          ? 'Згода подружжя Порука'
          : 'Заява сімейний стан'

        forms.push(
          {
            action: () => this.downloadPf(urlGetProjectPf, title),
            text: title,
            icon: 'mdi-file',
          },
          {
            action: () =>
              this.downloadPf(
                urlGetPersonalDataGuarantor,
                'Згода на обробку персональних даних поручителя'
              ),
            text: 'Згода на обробку персональних даних поручителя',
            icon: 'mdi-file',
          }
        )
      } else {
        forms.push({
          action: () =>
            this.downloadPf(
              urlGetPersonalDataGuarantor,
              'Згода на обробку персональних даних поручителя'
            ),
          text: 'Згода на обробку персональних даних поручителя',
          icon: 'mdi-file',
        })
      }

      if (this.guarantor.entityTypeId === 2) {
        forms.push(
          {
            action: () =>
              this.downloadPf(
                urlGetGuarantorGeneralMeetingsPf,
                'ПРОТОКОЛ № 1 Загальних зборів учасників'
              ),
            text: 'ПРОТОКОЛ № 1 Загальних зборів учасників',
            icon: 'mdi-file',
          },
          {
            action: () =>
              this.downloadPf(
                urlGetGuarantorDecisionParticipantPf,
                'Рішення учасника поручителя'
              ),
            text: 'Рішення учасника поручителя',
            icon: 'mdi-file',
          }
        )
      }

      if (this.guarantor.entityTypeId === 2) {
        forms.push({
          action: () =>
            this.downloadPf(
              urlGetGuarantorFoundingDocuments,
              'Довідка про установчі документи'
            ),
          text: 'Довідка про установчі документи',
          icon: 'mdi-file',
        })
      }

      return forms
    },
    entity() {
      return this.guarantor.entityTypeId === 2 ? 'contractor' : 'contact'
    },
    guarantorApplicationForm() {
      return this.guarantor.guarantorApplicationForm
    },
    guarantorApplicationFormSigned: {
      get() {
        return this.guarantor.guarantorApplicationFormSigned
      },
      set(val) {
        return this.$axios
          .post(urlUpdateGuarantor(this.guarantor.id), {
            guarantorApplicationForm: this.guarantor.guarantorApplicationForm,
            guarantorApplicationFormSigned: val,
          })
          .then(res => {
            this.$store.commit('setAssociatedPartyApplicationFormSigned', {
              doc: res.data.guarantorApplicationFormSigned,
              associatedParty: this.guarantor,
              associatedPartyType: 'guarantor',
            })
          })
      },
    },
  },
  methods: {
    getEntityRoute,
    getEntityName,
    downloadPf(url, text) {
      return this.$axios.get(url(this.guarantor.id)).then(res => {
        if (res.data.message) {
          return setSnackbar({ text: res.data.message, color: 'warning' })
        }
        return openDocument({
          text: text,
          url: res.data.url,
        })
      })
    },
    addFile(key, doc) {
      const guarantorDoc = {
        doc: doc,
        guarantorId: this.guarantor.entityId,
        guarantorTypeId: this.guarantor.entityTypeId,
        id: this.guarantor.id,
      }
      const idx = this.docIdx(key)

      idx !== -1 && this.deleteFile(key, idx)

      if (!this.projectData[key]) {
        this.projectData[key] = []
      }
      this.projectData[key].push(guarantorDoc)
    },
    docIdx(key) {
      return this.$getSafe(
        () =>
          this.projectData[key].findIndex(el => {
            const id = `${this.guarantor.entityId}${this.guarantor.entityTypeId}`
            return `${el.guarantorId}${el.guarantorTypeId}` === id
          }),
        -1
      )
    },
    deleteFile(key, idx) {
      console.log('deleteFile', key)
      this.projectData[key] = this.projectData[key].filter((_, i) => i !== idx)
      const docs =
        this.guarantor.entityTypeId === 2
          ? this.projecContractortDocs
          : this.projecContactDocs
      docs[key].show = false
    },
  },
  created() {
    const docs =
      this.guarantor.entityTypeId === 2
        ? this.projecContractortDocs
        : this.projecContactDocs

    Object.keys(docs).forEach(d => {
      if (this.$getSafe(() => this.projectData[d][this.docIdx(d)].doc))
        docs[d].show = true
    })
  },
}
</script>

<style scoped></style>
