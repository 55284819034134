<template>
  <div>
    <div
      class="subtitle-1 pb-0 mb-2 mt-3 d-flex align-center"
      :class="justifySpaceBetween === true ? 'justify-space-between' : ''"
      style="padding-left: 5px">
      <div class="btn-panel" @click="showSection = !showSection">
        <span class="d-block mr-2">
          <span v-if="leasingObjectIcon">
            <svg
              class="mr-1"
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none">
              <path
                d="M2.05556 5.25L3.22222 1.3125H11.7778L12.9444 5.25M11.7778 9.625C11.4684 9.625 11.1716 9.48672 10.9528 9.24058C10.734 8.99444 10.6111 8.6606 10.6111 8.3125C10.6111 7.9644 10.734 7.63056 10.9528 7.38442C11.1716 7.13828 11.4684 7 11.7778 7C12.0872 7 12.3839 7.13828 12.6027 7.38442C12.8215 7.63056 12.9444 7.9644 12.9444 8.3125C12.9444 8.6606 12.8215 8.99444 12.6027 9.24058C12.3839 9.48672 12.0872 9.625 11.7778 9.625ZM3.22222 9.625C2.9128 9.625 2.61606 9.48672 2.39726 9.24058C2.17847 8.99444 2.05556 8.6606 2.05556 8.3125C2.05556 7.9644 2.17847 7.63056 2.39726 7.38442C2.61606 7.13828 2.9128 7 3.22222 7C3.53164 7 3.82839 7.13828 4.04718 7.38442C4.26597 7.63056 4.38889 7.9644 4.38889 8.3125C4.38889 8.6606 4.26597 8.99444 4.04718 9.24058C3.82839 9.48672 3.53164 9.625 3.22222 9.625ZM12.8822 0.875C12.7267 0.3675 12.2911 0 11.7778 0H3.22222C2.70889 0 2.27333 0.3675 2.11778 0.875L0.5 6.125V13.125C0.5 13.3571 0.581944 13.5796 0.727806 13.7437C0.873667 13.9078 1.0715 14 1.27778 14H2.05556C2.26184 14 2.45967 13.9078 2.60553 13.7437C2.75139 13.5796 2.83333 13.3571 2.83333 13.125V12.25H12.1667V13.125C12.1667 13.3571 12.2486 13.5796 12.3945 13.7437C12.5403 13.9078 12.7382 14 12.9444 14H13.7222C13.9285 14 14.1263 13.9078 14.2722 13.7437C14.4181 13.5796 14.5 13.3571 14.5 13.125V6.125L12.8822 0.875Z"
                fill="#08487A" />
            </svg>
          </span>
          <span v-if="graphicIcon">📆</span>
          <span v-if="!hideText">{{
            showSection ? 'Приховати' : 'Показати'
          }}</span>
          {{ title }}</span
        >
        <div v-if="showSection">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="8"
            viewBox="0 0 13 8"
            fill="none">
            <path
              d="M2.21924 7.60948C1.71156 8.13017 0.888446 8.13017 0.380763 7.60947C-0.126918 7.08877 -0.126918 6.24456 0.380763 5.72386L5.58076 0.390524C6.08844 -0.130174 6.91156 -0.130174 7.41924 0.390524L12.6192 5.72386C13.1269 6.24456 13.1269 7.08878 12.6192 7.60948C12.1116 8.13017 11.2884 8.13017 10.7808 7.60948L6.5 3.21895L2.21924 7.60948Z"
              fill="#FC7247" />
          </svg>
        </div>
        <div v-else>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none">
            <path
              d="M9.95147 0.390524C10.4201 -0.130175 11.1799 -0.130175 11.6485 0.390524C12.1172 0.911224 12.1172 1.75544 11.6485 2.27614L6.84853 7.60948C6.3799 8.13017 5.6201 8.13017 5.15147 7.60948L0.351472 2.27614C-0.117157 1.75544 -0.117157 0.911224 0.351472 0.390524C0.820102 -0.130175 1.5799 -0.130175 2.04853 0.390524L6 4.78105L9.95147 0.390524Z"
              fill="#FC7247" />
          </svg>
        </div>
      </div>
      <!--      <v-btn-->
      <!--        text-->
      <!--        x-small-->
      <!--        class="btn-panel"-->
      <!--        :ripple="false"-->
      <!--        @click="showSection = !showSection">-->
      <!--        {{ title }}-->
      <!--        &nbsp;-->
      <!--      </v-btn>-->
      <slot name="append-title"></slot>
    </div>
    <v-fade-transition hide-on-leave>
      <div v-show="showSection">
        <slot name="default"></slot>
        <slot name="body">
          <!-- Additional content -->
        </slot>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: 'документи' },
    hideIcon: { type: Boolean, default: false },
    justifySpaceBetween: { type: Boolean, default: false },
    hideText: { type: Boolean, default: false },
    graphicIcon: { type: Boolean, default: false },
    leasingObjectIcon: { type: Boolean, default: false },
  },
  data() {
    return {
      showSection: false,
    }
  },
}
</script>

<style scoped>
.btn-panel {
  cursor: pointer;
  display: flex;
  letter-spacing: normal;
  border: none !important;
  color: #08487a !important;
  font-style: normal;
  font-weight: 500;
  font-size: 13px !important;
  padding-right: 5px !important;
}
</style>
