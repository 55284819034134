<template>
  <div>
    <div
      v-if="!computedAssociatedParties.length"
      class="associated-party-block"
      :class="{ 'associated-mobile': $vuetify.breakpoint?.xs }">
      <v-card-title class="block text-center" style="color: grey">
        {{ isGuarantor ? 'Поручителі' : 'Суборендатори' }} відсутні або не
        вказані
      </v-card-title>
    </div>
    <modal
      v-if="dialog"
      v-model:dialog="dialog"
      :title="`Додати ${isGuarantor ? 'поручителя' : 'суборендатора'}`"
      :exclude="existAssociatedParties"
      @submit="addAssociatedParties($event)" />
    <div class="mb-3">
      <v-btn
        v-if="!project.readOnly"
        style="
          text-transform: none;
          background: #08487a;
          border-radius: 4px;
          font-weight: 600;
          color: #fff;
          border: 2px solid #08487a;
        "
        :disabled="!!computedAssociatedParties.filter(g => !g).length"
        @click="dialog = true">
        <v-icon class="mr-1" color="white">{{ 'mdi-plus' }}</v-icon>
        Додати {{ isGuarantor ? 'поручителя' : 'суборендатора' }}
      </v-btn>
    </div>
    <v-row class="ml-0">
      <v-col cols="12" md="12" sm="12">
        <div style="position: relative">
          <v-tabs
            v-model="currentTab"
            :show-arrows="computedAssociatedParties.length > 1">
            <div style="position: relative; max-width: 90%">
              <v-tab
                v-for="(associatedParty, idx) in computedAssociatedParties"
                :key="
                  associatedParty
                    ? `${associatedParty.entityId || null}${
                        associatedParty.entityTypeId
                      }`
                    : null
                "
                :value="idx">
                <span>
                  {{ getShortName(associatedParty) }}
                  <v-btn
                    v-if="!project.readOnly"
                    size="x-small"
                    dense
                    icon
                    :loading="asyncLoading('associatedPartiesLoading')"
                    @click="deleteAssociatedParty(associatedParty)">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 26 26"
                      fill="none">
                      <path
                        d="M25 13C25 6.375 19.625 1 13 1C6.375 1 1 6.375 1 13C1 19.625 6.375 25 13 25C19.625 25 25 19.625 25 13Z"
                        stroke="#C0C1C7"
                        stroke-width="2"
                        stroke-miterlimit="10" />
                      <path
                        d="M17 17L9 9M9 17L17 9"
                        stroke="#C0C1C7"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </v-btn>
                </span>
              </v-tab>
            </div>
          </v-tabs>
          <v-tabs-window v-model="currentTab">
            <v-tabs-window-item
              v-for="(associatedParty, idx) in computedAssociatedParties"
              :key="
                associatedParty
                  ? `${associatedParty.entityId}${associatedParty.entityTypeId}`
                  : null
              "
              :value="idx">
              <SectionLoader
                v-if="asyncLoading('associatedPartiesLoading')"
                height="200px" />
              <v-row v-else>
                <v-col cols="12" md="12" sm="12">
                  <AssociatedParty
                    :idx="idx"
                    :readonly="project.readOnly"
                    :associatedParty="associatedParty"
                    :associatedPartyType="associatedPartyType"
                    @update-associated-party="
                      updateAssociatedParty(associatedParty, $event)
                    ">
                  </AssociatedParty>
                </v-col>
              </v-row>
            </v-tabs-window-item>
          </v-tabs-window>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SectionLoader from '@/components/section-loader.vue'
import AssociatedParty from './AssociatedParty.vue'
import modal from '../EntitySelect.vue'
import {
  urlAddGuarantor,
  urlAddSubtenant,
  urlDeleteGuarantor,
  urlDeleteSubtenant,
  urlUpdateGuarantor,
  urlUpdateSubtenant,
} from '@/pages/request'
import { getContactInitials } from '@/utils/helperFunc'
import { mapState } from 'vuex'
import { useSelect } from '@/utils/mixins/useSelect'

export default {
  components: {
    AssociatedParty,
    SectionLoader,
    modal,
  },
  setup() {
    return {
      ...useSelect(),
    }
  },
  props: {
    projectData: { type: Object },
    associatedParties: { type: Object },
    associatedPartyType: { type: String },
    v: { type: Object },
  },
  data() {
    return {
      currentTab: 0,
      dialog: false,
    }
  },
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    urls() {
      return {
        add: this.isGuarantor ? urlAddGuarantor : urlAddSubtenant,
        update: this.isGuarantor ? urlUpdateGuarantor : urlUpdateSubtenant,
        delete: this.isGuarantor ? urlDeleteGuarantor : urlDeleteSubtenant,
      }
    },
    isGuarantor() {
      return this.associatedPartyType === 'guarantor'
    },
    project() {
      return this.projectData
    },
    existAssociatedParties() {
      const g = Object.clone(this.associatedParties)
      g.contacts = g.contacts.map(g => g.entityId)
      g.contractors = g.contractors.map(g => g.entityId)

      return g
    },
    computedAssociatedParties() {
      const a = this.associatedParties
      return [
        ...a.contacts.filter(g => typeof g === 'object'),
        ...a.contractors.filter(g => typeof g === 'object'),
      ].sort((prev, cur) => (cur ? 1 : -1))
    },
  },
  methods: {
    getContactInitials,
    addAssociatedParties(entity) {
      const request = {
        entityId: entity.id,
        entityTypeId: entity.typeId,
        sliderId: this.projectData.id,
      }

      return this.asyncAction('associatedPartiesLoading', null, () => {
        return this.$axios.post(this.urls.add(), request).then(res => {
          const associatedParty = res.data
          this.$emit('addAssociatedParty', associatedParty)
          this.$nextTick(() => {
            this.currentTab = Math.max(
              0,
              this.computedAssociatedParties.findIndex(
                itm => itm.id === associatedParty.id
              )
            )
          })
        })
      })
    },
    updateAssociatedParty(associatedParty, applicationForm) {
      return this.asyncAction('associatedPartiesLoading', null, () => {
        const request = {}
        request[`${this.associatedPartyType}ApplicationForm`] = applicationForm
        return this.$axios
          .post(this.urls.update(associatedParty.id), request)
          .then(res => {
            return this.$emit('updateAssociatedParty', res.data)
          })
      })
    },
    deleteAssociatedParty(associatedParty) {
      const currentIndex =
        this.computedAssociatedParties.indexOf(associatedParty)
      const newIndex = currentIndex === 0 ? 0 : currentIndex - 1

      this.asyncAction('associatedPartiesLoading', null, () => {
        return this.$axios
          .delete(this.urls.delete(associatedParty.id))
          .then(() => {
            this.$emit('deleteAssociatedParty', associatedParty)
            this.currentTab = Math.max(0, newIndex)
          })
      })
    },
    getShortName(associatedParty) {
      return (
        associatedParty?.contractor?.shortName ||
        getContactInitials(associatedParty?.contact) ||
        (this.isGuarantor ? 'Поручитель' : 'Суборендатор')
      )
    },
  },
}
</script>

<style scoped>
.associated-party-block {
  position: absolute;
  top: 50%;
  left: 15%;
  transform: translate(50%, 50%);
  height: 30vh;
}
.v-tab {
  height: 35px !important;
  border: 1px solid #c0c1c7 !important;
  letter-spacing: normal !important;
  margin-right: 10px !important;
}
.v-tab.v-tab-item--selected {
  background: #f4f6f9 !important;
  border: 1px solid #c0c1c7 !important;
}
.associated-mobile {
  display: block !important;
  position: relative !important;
  transform: none !important;
  left: 0 !important;
  top: 0 !important;
  height: auto !important;
}
</style>
