<template>
  <div>
    <div class="mb-6">Оберіть додаткову угоду до розірвання договору</div>

    <v-select
      v-model="agreementId"
      :items="agreements"
      item-title="number"
      item-value="id"
      placeholder="обреіть зі списку"
      :error-messages="getErrorMessages(v$.agreementId)"
      @blur="v$.agreementId.$touch()"></v-select>
  </div>
</template>

<script lang="ts">
import { urlTerminateDfl, urlTerminateDkp } from '@/pages/request.js'
import { ref } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import axios from '@/plugins/axios.js'
import { required } from '@vuelidate/validators'
import { getErrorMessages } from 'best-modules/utils'
import { handleAsync } from 'best-modules/plugins'

export default {
  name: 'TerminateContract',
  props: {
    dialog: {
      type: Object,
    },
  },
  methods: { getErrorMessages },
  setup(props) {
    const agreements = props.dialog.dialogItem.agreements || []

    const agreementId = ref()

    const rules = { agreementId: { required } }
    const v$ = useVuelidate(rules, { agreementId })

    const submit = () => {
      const url =
        props.dialog.action === 'terminateDfl'
          ? urlTerminateDfl
          : urlTerminateDkp
      return handleAsync('terminateContract', () => {
        return axios.post(url(props.dialog.dialogItem.contractId), {
          terminateAgreementId: agreementId.value,
        })
      })
    }

    return { agreements, agreementId, v$, submit }
  },
}
</script>

<style scoped></style>
