<template>
  <div>
    <span class="subtitle">Місяць</span>
    <span class="text-desc mb-2">{{ dialog.dialogItem.shortDate }}</span>
    <span class="subtitle">Витрата</span>
    <v-text-field
      id="amount"
      v-model="expenseMarket.amount"
      :error-messages="amountErr"
      class="label-top"
      placeholder="Вкажіть витрату"
      dense
      @blur="inputNumber($event, { float: 2, saveFloat: true })"
      @focusout="Input.restoreData('amount')"
      @focusin="Input.hideData('amount')"
      @input="changeInputVal('amount', 'input', [keepDigit, formutSum])">
    </v-text-field>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Input, setErrHandler } from '@/utils/helperFunc'
import { minLength, required } from '@vuelidate/validators'
import { urlUpdateExpensesMarketingCompany } from '@/pages/request'
import { useVuelidate } from '@vuelidate/core'
import {
  changeInputVal,
  formutSum,
  inputNumber,
  keepDigit,
} from '@/utils/formatFunc'
export default {
  setup() {
    return { v$: useVuelidate() }
  },
  props: {
    dialog: { type: Object },
  },
  validations() {
    return {
      expenseMarket: {
        amount: { required, minLength: minLength(2) },
      },
    }
  },
  data: () => {
    return {
      expenseMarket: {
        // date: null,
        amount: null,
      },
      Input: new Input(),
    }
  },
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    validation() {
      return { v$: this.v$.expenseMarket, name: 'expensesMarket' }
    },
    amountErr() {
      return setErrHandler(this.v$.expenseMarket?.amount)
    },
  },
  methods: {
    formutSum,
    keepDigit,
    changeInputVal,
    inputNumber,
    submit() {
      return this.editExpenses()
    },
    editExpenses() {
      const req = this.expenseMarket
      req.amount = parseFloat(this.expenseMarket.amount.replace(/\s/g, ''))
      return this.$axios
        .post(urlUpdateExpensesMarketingCompany(this.dialog.dialogItem.id), req)
        .then(() => {
          this.$setSnackbar({ text: 'Виплата відредагована' })
          this.$store.commit('refreshPage')
        })
    },
  },
  mounted() {
    this.expenseMarket.amount = this.dialog.dialogItem.amount
  },
}
</script>

<style scoped>
.subtitle {
  color: #838282;
  font-weight: 500;
  font-size: 13px;
  display: block;
  margin-bottom: 7px;
}

.text-desc {
  font-weight: 400;
  font-size: 16px;
  color: #1c1d21;
  display: block;
}
</style>
