<template>
  <div class="px-1 pt-1">
    <v-tabs
      v-model="currentTab"
      class="mb-3"
      :class="{ 'v-tabs--mobile': $vuetify.breakpoint?.xs }"
      :direction="$vuetify.breakpoint?.xs ? 'vertical' : 'horizontal'"
      slider-color="red"
      :height="$vuetify.breakpoint?.xs ? null : 38">
      <v-tab class="v-tab">Загальна інформація</v-tab>
      <v-tab class="v-tab">Шаблони</v-tab>
    </v-tabs>
    <v-scroll-x-transition hide-on-leave>
      <common-info v-show="choosedManagerInfo"> </common-info>
    </v-scroll-x-transition>
    <v-scroll-x-transition hide-on-leave>
      <offer-templates v-show="choosedTemplates"> </offer-templates>
    </v-scroll-x-transition>
  </div>
</template>
<script>
import commonInfo from './tabs/common-info.vue'
import offerTemplates from './tabs/offer-templates.vue'
export default {
  components: { commonInfo, offerTemplates },
  data: () => ({ currentTab: 0 }),
  computed: {
    choosedManagerInfo() {
      return this.currentTab === 0
    },
    choosedTemplates() {
      return this.currentTab === 1
    },
  },
}
</script>

<style lang="scss" scoped>
.v-tab.v-tab {
  background: #09487a;
  margin-right: 15px;
  color: white !important;
  border-radius: 4px;
  font-size: 12px;
}
</style>
