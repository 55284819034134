<template>
  <div>
    <span class="subtitle">Держ. номер</span>
    <v-text-field
      v-model="carNumber"
      style="width: 15rem"
      placeholder="Введіть текст">
      <template #append>
        <v-btn
          v-if="carNumber"
          :loading="asyncLoading('saveCarNumber')"
          :disabled="asyncLoading('saveCarNumber')"
          icon
          size="small"
          density="compact"
          @click="saveCarNumber">
          <v-icon v-if="asyncLoading('saveCarNumber')" class="spin">{{
            'mdi-reload'
          }}</v-icon>
          <v-icon v-else>{{ 'mdi-check' }}</v-icon>
        </v-btn>
      </template>
    </v-text-field>
    <div>
      <SectionLoader v-if="asyncLoading('getLeasingObject')" />
      <div v-if="leasingObject">
        <Fields :fields="leasingObjectFields" />
      </div>
    </div>
  </div>
</template>

<script>
import SectionLoader from '@/components/section-loader.vue'
import Fields from '@/components/Fields.vue'
import {
  urlGetServiceApplicationOL,
  urlUpdateServiceApplication,
} from '@/pages/request'
import { useSelect } from '@/utils/mixins/useSelect'

export default {
  components: { SectionLoader, Fields },
  props: {
    application: { type: Object },
    cache: { type: Object },
  },
  setup() {
    return {
      ...useSelect(),
    }
  },
  data() {
    return {
      leasingObject: null,
      carNumber: null,
    }
  },
  computed: {
    applicationId() {
      return this.$route.params.id
    },
    leasingObjectFields() {
      if (!this.leasingObject) return []
      return [
        { label: 'Реєстраційний номер', value: this.leasingObject.ГосНомер },
        { label: 'Тип', value: this.leasingObject.Тип },
        { label: 'Марка', value: this.leasingObject.Марка },
        { label: 'Модель', value: this.leasingObject.Модель },
        { label: 'Комплектація', value: this.leasingObject.Комплектация },
        {
          label: 'Номер кузова/VIN -code',
          value: this.leasingObject.НомерКузова,
        },
        { label: "Об'єм", value: this.leasingObject.ОбъемДвигателя },
        { label: 'Тип КПП', value: this.leasingObject.ТипКПП },
        { label: 'Тип палива', value: this.leasingObject.ТипТоплива },
        { label: 'Колір', value: this.leasingObject.Цвет },
      ]
    },
  },
  methods: {
    saveCarNumber() {
      this.asyncAction('saveCarNumber', null, () => {
        return this.$axios
          .post(urlUpdateServiceApplication(this.applicationId), {
            ...this.cache,
            carNumber: this.carNumber,
          })
          .then(this.getLeasingObject)
      })
    },
    getLeasingObject() {
      this.asyncAction('getLeasingObject', null, () => {
        return this.$axios
          .get(urlGetServiceApplicationOL(this.applicationId))
          .then(res => {
            if (res.data.message)
              return this.$setSnackbar({
                text: res.data.message,
                color: 'warning',
              })
            this.leasingObject = res.data
          })
      })
    },
  },
  created() {
    this.carNumber = this.application.carNumber
    this.carNumber && this.getLeasingObject()
  },
}
</script>

<style lang="scss" scoped>
.subtitle {
  color: #838282;
  font-weight: 500;
  font-size: 13px;
  display: block;
}
</style>
