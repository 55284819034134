<template>
  <div class="mt-2">
    <div>
      <div>
        <slot name="title"></slot>
      </div>
      <slot name="body"></slot>
    </div>
  </div>
</template>
