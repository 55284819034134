<template>
  <div>
    <v-row>
      <v-col cols="12" md="4" sm="12">
        <span class="label"
          >Середньомісячний підтверджений сукупний чистий дохід, грн</span
        >
        <v-text-field
          v-model="contactData.provedIncome"
          :error-messages="provedIncomeErr"
          placeholder="0"
          hide-details
          dense
          :disabled="readonly"
          @input="
            inputNumber($event, { float: 2, minValue: 0, maxValue: 500000000 })
          "
          @blur="
            $event => {
              inputNumber($event, { float: 2, saveFloat: true })
              $v.contactData.provedIncome.$touch()
            }
          ">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <span class="label"
          >Середньомісячний непідтверджений сукупний чистий дохід, грн</span
        >
        <v-text-field
          id="contact-unprovedIncome"
          v-model="contactData.unprovedIncome"
          :error-messages="unprovedIncomeErr"
          placeholder="0"
          hide-details
          dense
          :disabled="readonly"
          @input="
            inputNumber($event, { float: 2, minValue: 0, maxValue: 500000000 })
          "
          @blur="
            $event => {
              inputNumber($event, { float: 2, saveFloat: true })
              $v.contactData.unprovedIncome.$touch()
            }
          "
          @focusin="Input.hideData('contact-unprovedIncome')"
          @focusout="Input.restoreData('contact-unprovedIncome')">
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" sm="12">
        <span class="label"
          >Кількість непрацездатних членів сімї та утриманців</span
        >
        <v-text-field
          v-model="contactData.dependentsNumber"
          :error-messages="dependentsNumberErr"
          placeholder="0"
          hide-details
          dense
          type="number"
          :disabled="readonly"
          @blur="$v.contactData.dependentsNumber.$touch()">
        </v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Input, setErrHandler } from '@/utils/helperFunc'
import { inputNumber } from '@/utils/formatFunc'

export default {
  props: {
    readonly: { type: Boolean },
    contact: { type: Object },
    v: { type: Object },
  },
  data: () => ({
    Input: new Input(),
  }),
  computed: {
    contactData() {
      return this.contact
    },
    $v() {
      return this.v
    },
    provedIncomeErr() {
      return setErrHandler(this.$v?.contactData?.provedIncome)
    },
    unprovedIncomeErr() {
      return setErrHandler(this.$v?.contactData?.unprovedIncome)
    },
    dependentsNumberErr() {
      return setErrHandler(this.$v?.contactData?.dependentsNumber)
    },
  },
  methods: { inputNumber },
}
</script>

<style scoped></style>
