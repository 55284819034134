// import pdfMake from 'pdfmake/build/pdfmake'
// import pdfFonts from 'pdfmake/build/vfs_fonts'
// todo
import { fonts } from '@/assets/base64'
const pdfMake = {}
const pdfFonts = {}
// todo
pdfMake.vfs = pdfFonts.pdfMake?.vfs

// setting default fonts
if (pdfFonts.pdfMake && pdfFonts.pdfMake.vfs !== undefined) {
  Object.keys(fonts).forEach(font => {
    Object.entries(fonts[font]).forEach(f => {
      const fontWeight = f[0]
      const { base64, fileName } = f[1]
      pdfMake.vfs[fileName] = base64

      !pdfMake.fonts && (pdfMake.fonts = {})
      !pdfMake.fonts[font] && (pdfMake.fonts[font] = {})

      pdfMake.fonts[font][fontWeight] = fileName
    })
  })
}

function download(content, fileName = 'default.pdf', params) {
  // @ts-ignore
  return pdfMake.createPdf(docDefinition(content, params)).download(fileName)
}
function open(content, params) {
  // @ts-ignore
  return pdfMake.createPdf(docDefinition(content, params)).open()
}
function openInSameWindow(content, params) {
  // @ts-ignore
  return pdfMake.createPdf(docDefinition(content, params)).open({}, window)
}
function print(content, params) {
  // @ts-ignore
  return pdfMake.createPdf(docDefinition(content, params)).print()
}
function getAsBase64(content, params) {
  // @ts-ignore
  const pdfDocGenerator = pdfMake.createPdf(docDefinition(content, params))
  /** @type {string} */
  return new Promise(resolve => {
    pdfDocGenerator.getBase64(v => resolve(v))
  })
}
function docDefinition(content, params) {
  return {
    defaultStyle: { font: 'Helvetica', fontSize: 7.5 },
    content: content,
    ...params,
  }
}
function dataURLtoFile(mimeType, dataurl, fileName = 'КП') {
  if (!mimeType || !dataurl) {
    throw new Error(
      `Not valid parameters. Mime type: ${mimeType}. Data url: ${dataurl}`
    )
  }

  return fetch(`data:${mimeType};base64,` + dataurl)
    .then(res => res.blob())
    .then(blob => {
      return new File([blob], fileName, { type: mimeType })
    })
}

export { download, open, openInSameWindow, print, getAsBase64, dataURLtoFile }
