<template>
  <div>
    <EntityAgreements :entityTypeId="1" :entityData="contact" />
  </div>
</template>
<script>
import EntityAgreements from '@/components/EntityAgreements.vue'
export default {
  components: { EntityAgreements },
  props: { contact: { type: Object } },
}
</script>
