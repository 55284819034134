<template>
  <div>
    <div class="mr-3">
      <filters v-model:filters="filters" class="ml-2 mb-3">
        <template #header-action>
          <v-btn
            variant="flat"
            class="text-white"
            color="#fb7146"
            @click="carForSaleCreate">
            <v-icon class="mr-1">mdi-plus</v-icon>
            Додати авто
          </v-btn>
        </template>
      </filters>
    </div>
    <v-data-table
      v-if="!$loading.isLoading('getCarForSaleList')"
      :headers="carForSaleHeaders"
      :items="carForSaleList"
      item-value="id"
      hover
      hide-default-footer
      @click:row="
        (e, row) =>
          $router.push({
            name: 'car-for-sale-single',
            params: { id: row.item.id },
          })
      ">
    </v-data-table>
    <section-loader v-else></section-loader>
    <pagination
      :getItems="getCarForSaleList"
      :trigger="paginationTrigger"></pagination>
  </div>
</template>

<script lang="ts">
import { useFilter } from '@/utils/mixins/useFilter'
import { reactive, ref } from 'vue'
import { getContactInitials } from '@/utils/helperFunc'
import Filters from '@/components/Filters.vue'
import Pagination from '@/components/Pagination.vue'
import axios from '@/plugins/axios.js'
import { handleAsync } from 'best-modules/plugins'
import { urlCarForSaleList } from '@/pages/request'
import SectionLoader from '@/components/section-loader.vue'
import { useRouter } from 'vue-router'
import { carForSaleHeaders } from '@/utils/tableHeaders'
import { fillDirectory } from '@/plugins/directory'

export default {
  name: 'CarSaleList',
  components: { SectionLoader, Pagination, Filters },
  data: () => {
    return {
      carForSaleHeaders,
    }
  },
  setup() {
    const router = useRouter()

    const filters = reactive({
      search: { value: null },
      statusId: {
        value: null,
        component: 'v-select',
        attrs: {
          items: 'carForSaleStatuses',
          label: 'Статус',
        },
      },
      responsibleId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Відповідальний',
          itemTitle: u => getContactInitials(u, { isResponsible: true }),
          items: 'activeUsers',
        },
        focus: fillDirectory('allUsers'),
      },
      year: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Рік',
          items: 'itemYears',
          itemTitle: String,
        },
      },
      minPrice: {
        value: null,
        component: 'v-text-field',
        attrs: {
          label: 'Ціна від',
        },
      },
      maxPrice: {
        value: null,
        component: 'v-text-field',
        attrs: {
          label: 'Ціна до',
        },
      },
    })
    const filter = useFilter(filters)

    const carForSaleList = ref([])

    const getCarForSaleList = (page = 1, cancelToken) => {
      return handleAsync('getCarForSaleList', () => {
        return axios
          .get(urlCarForSaleList(page, filter.filtersQueryObj.value), {
            cancelToken,
          })
          .then(res => {
            carForSaleList.value = res.data.data

            return res
          })
      })
    }

    const carForSaleCreate = () => {
      router.push({ name: 'car-for-sale-create' })
    }

    return {
      ...filter,
      filters,
      getCarForSaleList,
      carForSaleList,
      carForSaleCreate,
    }
  },
}
</script>

<style scoped></style>
