<template>
  <v-fade-transition hide-on-leave>
    <slot></slot>
  </v-fade-transition>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
