<template>
  <div>
    <v-row class="mt-3">
      <v-col cols="12" md="6" sm="12">
        <CarIem
          v-model="carData.category"
          :items="items.categories"
          hide-details
          return-object
          :loading="asyncLoading('getCategories')"
          placeholder="оберіть тип"
          label="Тип об'єкта"
          :item-disabled="item => item.value !== 1"
          :error-messages="categoryErr"
          @blur="getSafe(() => v && v.category.$touch())"
          @update:model-value="carSelectHandler('category')" />
        <CarIem
          v-model="carData.state"
          :items="$directory.get('averagePriceStates')"
          hide-details
          component="v-autocomplete"
          placeholder="оберіть регіон"
          :loading="$loading.isLoading('averagePriceStates')"
          label="Регіон"
          @focus="$directory.fill('averagePriceStates')" />
        <CarIem
          v-model="carData.mark"
          component="v-autocomplete"
          hide-details
          placeholder="оберіть марку"
          :loading="asyncLoading('getMarks')"
          :items="items.marks"
          label="Марка"
          :error-messages="markErr"
          @update:model-value="carSelectHandler('mark')"
          @blur="getSafe(() => v && v.mark.$touch())" />
        <CarIem
          v-model="carData.model"
          component="v-autocomplete"
          hide-details
          :loading="asyncLoading('getModels')"
          placeholder="оберіть модель"
          :items="items.models"
          label="Модель"
          :error-messages="modelErr"
          @update:model-value="carSelectHandler('model')"
          @blur="getSafe(() => v && v.model.$touch())" />
        <CarIem
          v-model="carData.body"
          hide-details
          return-object
          placeholder="оберіть тип кузова"
          :items="items.bodies"
          :loading="asyncLoading('getBodies')"
          label="Тип кузова" />
        <v-row class="align-center mb-3">
          <v-col cols="12" md="5" sm="12" class="pt-0 pb-0">
            <span class="card-label calc">Рік випуску:</span>
          </v-col>
          <v-col cols="12" md="7" sm="12" class="pt-0 pb-0 d-flex gap-2">
            <v-select
              v-model="carData.yearFrom"
              class="pt-0"
              hide-details
              item-title="name"
              item-value="value"
              placeholder="рік від"
              :error-messages="yearFromErr"
              :loading="asyncLoading('getYears')"
              :items="items.years"
              @blur="getSafe(() => v && v.yearFrom.$touch())">
            </v-select>
            <v-select
              v-model="carData.yearTo"
              class="pt-0"
              hide-details
              item-title="name"
              item-value="value"
              :error-messages="yearToErr"
              :loading="asyncLoading('getYears')"
              placeholder="рік до"
              :items="items.years"
              @blur="getSafe(() => v && v.yearTo.$touch())">
            </v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-row class="align-center mb-3">
          <v-col cols="12" md="5" sm="12" class="pt-0 pb-0">
            <span class="card-label calc">Об’єм двигуна:</span>
          </v-col>
          <v-col cols="12" md="7" sm="12" class="pt-0 pb-0 d-flex gap-2">
            <v-text-field
              v-model="carData.engineVolume"
              class="pt-0"
              hide-details
              dense
              :min="0"
              :max="2000000"
              placeholder="1.4 / 1.6 / 3.5"
              @input="
                inputNumber($event, {
                  float: 2,
                  minValue: 0,
                  maxValue: 5000000,
                  rankSpacing: true,
                })
              ">
              <template #append-inner>
                <span>л.</span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <CarIem
          v-model="carData.fuel"
          component="v-select"
          hide-details
          placeholder="оберіть вид палива"
          :items="$directory.get('averagePriceFuels')"
          label="Вид палива"
          :loading="$loading.isLoading('averagePriceFuels')"
          @focus="$directory.fill('averagePriceFuels')" />
        <CarIem
          v-model="carData.gear"
          component="v-select"
          hide-details
          return-object
          placeholder="оберіть КПП"
          :loading="asyncLoading('getGears')"
          :items="items.gears"
          label="Трансмісія" />
        <v-row class="align-center mb-3">
          <v-col cols="12" md="5" sm="12" class="pt-0 pb-0">
            <span class="card-label calc">Пробіг:</span>
          </v-col>
          <v-col cols="12" md="7" sm="12" class="pt-0 pb-0 d-flex gap-2">
            <v-text-field
              v-model="carData.raceIntFrom"
              hide-details
              dense
              :min="0"
              :max="2000000"
              class="pt-0"
              placeholder="від, тис. км"
              @input="
                inputNumber($event, {
                  minValue: 0,
                  maxValue: 5000000,
                  rankSpacing: true,
                })
              "></v-text-field>
            <v-text-field
              v-model="carData.raceIntTo"
              hide-details
              dense
              :min="0"
              :max="2000000"
              class="pt-0"
              placeholder="до, тис. км"
              @input="
                inputNumber($event, {
                  minValue: 0,
                  maxValue: 5000000,
                  rankSpacing: true,
                })
              "></v-text-field>
          </v-col>
        </v-row>
        <CarIem
          v-model="carData.driverType"
          component="v-select"
          hide-details
          return-object
          :loading="asyncLoading('getDriverTypes')"
          placeholder="оберіть привід"
          :items="items.driverTypes"
          label="Привід" />
        <v-row class="align-center mb-3 mt-3">
          <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
            <v-checkbox
              v-model="carData.isCustom"
              hide-details
              class="mt-0"
              :false-value="false"
              :true-value="true">
              <template #label>
                <span style="font-size: 12px">Авто не розмитнене</span>
              </template>
            </v-checkbox>
          </v-col>
          <v-col cols="12" md="3" sm="12" class="pt-0 pb-0">
            <v-checkbox
              v-model="carData.isDamage"
              hide-details
              class="mt-0"
              :false-value="false"
              :true-value="true">
              <template #label>
                <span style="font-size: 12px">Був у ДТП</span>
              </template>
            </v-checkbox>
          </v-col>
          <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
            <v-checkbox
              v-model="carData.isOnRepair"
              hide-details
              class="mt-0"
              :false-value="false"
              :true-value="true">
              <template #label>
                <span style="font-size: 12px">Авто не на ходу</span>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CarGeneral from './car-general'

export default CarGeneral
</script>
