<template>
  <div>
    <v-dialog v-model="dialog" :max-width="400" persistent>
      <div style="background: #fff">
        <div
          class="pa-3"
          style="
            display: flex;
            justify-content: space-between;
            height: 60px;
            align-items: center;
          ">
          <span
            style="color: rgb(8, 72, 122); font-weight: 600; font-size: 1rem"
            >Шаблон постачальника</span
          >

          <v-btn size="small" icon @click="dialog = !dialog">
            <v-icon size="19">mdi-close</v-icon>
          </v-btn>
        </div>
        <div style="padding: 0px 15px">
          <AddTemplate
            :callBack="() => (dialog = false)"
            :dialogData="dialogData"
            @create="getDKPTemplates" />
        </div>
      </div>
    </v-dialog>
    <SectionLoader v-if="loading && routeTemplates"></SectionLoader>
    <div v-if="routeTemplates && !loading" class="templates-wrapper">
      <v-btn
        class="text-white mb-3 ml-3"
        style="background: rgb(252, 114, 71); border-radius: 4px"
        @click="openDialog('create')">
        Додати шаблон
      </v-btn>
      <div>
        <v-data-table
          :headers="dkpTHead"
          :items="dkpTemplates"
          class="calc-table --cust-cell-pd"
          mobileBreakpoint="750"
          dense
          :hide-default-footer="!dkpTemplates.length"
          :hide-default-header="!dkpTemplates.length">
          <!-- eslint-disable-next-line -->
          <template #item.provider="{ item }">
            <ALink
              v-if="item.provider"
              :text="getEntityName(item.provider)"
              :route="{
                name: 'contractors-form',
                params: { id: item.provider.id },
              }">
            </ALink>
            <div v-else>---</div>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.created_at="{ item }">
            {{ humanDate(formatDate(item.created_at, true)) }}
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.fileName="{ item }">
            {{ item.fileName ? item.fileName : '---' }}
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.updated_at="{ item }">
            {{ humanDate(formatDate(item.updated_at, true)) }}
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.actions="{ item }">
            <TableCrud
              :actionsArray="[
                {
                  action: () => getDKPPrintData(item),
                  text: 'Завантажити файл',
                  icon: 'mdi-download',
                },
                {
                  action: () => openDialog('update', item),
                  text: 'Оновити шаблон',
                  icon: 'mdi-upload',
                },
              ]">
            </TableCrud>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>
<script>
import TableCrud from '@/components/table-crud.vue'
import SectionLoader from '@/components/section-loader.vue'
import AddTemplate from './AddTemplate.vue'
import ALink from '@/components/Link.vue'
import { urlGetDKPTemplates } from '@/pages/request'
import { formatDate, humanDate } from '@/utils/formatFunc'
import { getEntityName } from '@/utils/helperFunc'
export default {
  components: {
    TableCrud,
    SectionLoader,
    ALink,
    AddTemplate,
  },
  data: () => ({
    dkpTemplates: [],
    loading: false,
    dkpTHead: [
      {
        title: 'Постачальник',
        key: 'provider',
        align: 'start',
        sortable: false,
      },
      { title: 'Файл', key: 'fileName', align: 'center', sortable: false },
      {
        title: 'Дата створення',
        key: 'created_at',
        align: 'center',
        sortable: false,
      },
      {
        title: 'Дата змiни',
        key: 'updated_at',
        align: 'center',
        sortable: false,
      },
      { title: 'Дії', key: 'actions', align: 'end', sortable: false },
    ],
    dialog: false,
    dialogData: {
      action: 'create',
      provider: null,
    },
  }),
  computed: {
    routeTemplates() {
      return this.$route.name === 'tiny-templates'
    },
  },
  methods: {
    getEntityName,
    formatDate,
    humanDate,
    urlGetDKPTemplates,
    clearDialogData() {
      this.dialogData = {
        action: 'create',
        provider: null,
      }
    },
    openDialog(action, item) {
      this.dialogData.action = action
      if (action === 'update') {
        this.dialogData.provider = {
          name: item.provider?.shortName || '',
          providerId: item.providerId,
          providerTypeId: item.providerTypeId,
        }
        this.dialogData.doc = { url: item.fileName, text: item.fileName }
      }
      this.dialog = !this.dialog
    },
    getDKPPrintData(template) {
      const link = document.createElement('a')
      const url =
        new URL(location.href).origin + '/storage/' + template.fileName
      link.href = url
      link.setAttribute('download', template?.provider?.name + '.docx')
      document.body.appendChild(link)
      link.click()
      link.remove()
      window.URL.revokeObjectURL(url)
    },
    getDKPTemplates() {
      this.loading = true
      return this.$axios
        .get(this.urlGetDKPTemplates())
        .then(res => {
          this.dkpTemplates.splice(0)
          this.dkpTemplates.push(...res.data)
          this.loading = false
          return res
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
  },
  watch: {
    dialog(v) {
      if (!v) this.clearDialogData()
    },
  },
  created() {
    this.getDKPTemplates()
  },
}
</script>
