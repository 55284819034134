/* Opendata contractor */
const contractorMap = {
  'registry.address': 'addressParts',
  statuteCapitalSize: 'statuteCapitalSize',
  beneficiarOwners: 'beneficiarOwners',
  'registry.code': 'edrpou',
  'registry.shortName': 'name',
  'registry.address.country': 'country',
  'registry.address.parts.atu': 'city',
  'registry.address.address': 'address',
  'registry.address.zip': 'postIndex',
  'registry.fullName': 'companyFullName',
  'registry.olfName': 'formOwnershipId',
  'registry.fullNameEn': 'companyFullNameEnglish',
  'registry.registrationDate': 'foundationDate',
  'registry.registration.recordDate': 'stateRegistrationDate',
  'registry.registration.recordNumber': 'stateRegistrationNumber',
  'registry.ceoName': 'realOwner',
  'registry.email': {
    key: 'emails',
    transform: v => {
      return v ? [{ email: v, typeId: 1 }] : []
    },
  },
  'registry.phones': {
    key: 'phones',
    transform: phones => {
      return phones
        .filter(phone => phone.length === 12)
        .map((p, idx) => {
          const typeId = idx === 0 ? 5 : 4
          const phone = `+${p.slice(0, 2)} (${p.slice(2, 5)}) ${p.slice(
            5,
            8
          )} - ${p.slice(8, 10)} - ${p.slice(10)}`

          return { phone, typeId }
        })
    },
  },
  'registry.capital': {
    key: 'statuteCapitalSize',
    transform: v => v?.toString(),
  },
  'registry.state': 'stateId',
  'registry.activities': 'activities',
  'registry.olfCode': {
    key: 'formOwnershipId',
    transform: v => (v ? +v : v),
  },
}

const mapPerson = {
  'registry.fullName': 'name',
  'registry.birthDate': 'birthday',
  'registry.registrationDate': 'fop.date',
  'registry.registrationNumber': 'fop.number',
}

const contactorDateMap = {
  'country.id': 'countryId',
  'region.id': 'regionId',
  'district.id': 'districtId',
  'type.id': 'addressTypeId',
  'city.id': 'cityId',
  address: 'address',
  index: 'index',
  id: 'id',
}

export { contractorMap, contactorDateMap, mapPerson }
