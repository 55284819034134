<template>
  <div>
    <v-data-table
      v-if="employees?.length"
      :headers="employeesHeaders"
      :items="employees || []"
      dense
      :items-per-page="-1"
      hide-default-footer>
      <template #item.isSignatureRight="{ item }">
        <div style="position: relative">
          <div class="d-flex align-center" style="justify-content: center">
            <v-checkbox
              v-model="item.isSignatureRight"
              style="
                transform: scale(0.7);
                margin-top: 0 !important;
                padding-top: 0 !important;
              "
              readonly
              hide-details
              dense>
            </v-checkbox>
            <v-menu v-if="item.isSignatureRight" open-on-hover>
              <template #activator="{ props }">
                <v-btn
                  icon="mdi-information-variant-box"
                  density="compact"
                  size="small"
                  color="red"
                  v-bind="props">
                </v-btn>
              </template>

              <v-card>
                <v-card-text>
                  <div>
                    <div class="mb-3">Підписант на підставі:</div>
                    <div v-if="item.employeeSignatoryBases">
                      <div
                        v-for="(s, idx) in item.employeeSignatoryBases"
                        :key="s.id">
                        <div class="font-weight-bold d-flex gap-2">
                          <span>{{ s.basisDocument?.name }}</span>
                          <span v-if="s.documentComment">
                            ({{ s.documentComment }})
                          </span>
                        </div>
                        <v-divider
                          v-if="idx < item.employeeSignatoryBases.length - 1"
                          class="mt-1 mb-1"></v-divider>
                      </div>
                    </div>
                    <div v-else>Дані не вказано</div>
                  </div>
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
        </div>
      </template>
      <template #item.post="{ item }">
        <div class="d-flex align-center gap-2">
          <span style="font-size: 13px">
            {{ item.post?.name || '---' }}
          </span>
          <v-menu right offset-x open-on-hover>
            <template #activator="{ props }">
              <v-btn
                v-if="item.fullPostName"
                style="margin-top: -5px"
                icon="mdi-information-variant-box"
                size="x-small"
                density="compact"
                color="red"
                v-bind="props">
              </v-btn>
            </template>
            <v-card>
              <v-card-text style="font-size: 13px">
                {{ item.fullPostName || '---' }}
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
      </template>
      <template #item.role="{ item }">
        <span style="font-size: 13px">{{ item.role?.name || '---' }}</span>
      </template>
      <template #item.department="{ item }">
        <span style="font-size: 13px">{{
          item.department?.name || '---'
        }}</span>
      </template>
      <template #item.contact="{ item }">
        <ALink
          style="font-size: 13px !important"
          :text="item?.contact?.fullName"
          :route="{
            name: 'individuals-form',
            params: { id: item.contact.id },
          }">
        </ALink>
      </template>
      <template #item.actions="{ item }">
        <div style="margin-right: -10px">
          <TableCrud
            data-readonly="hide"
            :actionsArray="[
              {
                action: () => employeeDialog(item, 'update'),
                text: 'Редагувати',
                icon: 'mdi-pencil',
                color: 'red',
                disabled: readonly,
              },
              {
                action: () => employeeDialog(item, 'delete'),
                text: 'Видалити',
                icon: 'mdi-delete',
                color: 'red',
                disabled: readonly,
              },
              {
                action: () => signatureBasisDialog(item),
                text: 'Право підпису',
                color: 'red',
                icon: 'mdi-draw',
                disabled: readonly,
              },
            ]">
          </TableCrud>
        </div>
      </template>
    </v-data-table>
    <div v-else class="text-disabled text-center">Співробітники відсутні</div>
  </div>
</template>

<script lang="ts">
import ALink from '@/components/Link.vue'
import TableCrud from '@/components/table-crud.vue'
import { employeesHeaders } from '@/utils/tableHeaders'
import { useStore } from 'vuex'
import { Employee } from '@/utils/types'
import { PropType } from 'vue'

export default {
  name: 'EmployeesList',
  emits: ['tableChange'],
  components: { TableCrud, ALink },
  props: {
    employees: {
      type: Array as PropType<Employee[]>,
    },
    readonly: {
      type: Boolean,
    },
  },
  data: () => {
    return { employeesHeaders }
  },
  setup(_, { emit }) {
    const { commit } = useStore()
    const employeeDialog = (
      employee: Employee,
      action: 'delete' | 'update'
    ) => {
      return commit('setDialog', {
        title: 'Співробітник',
        dialogItem: {
          employee,
          from: 'contractor',
        },
        params: {
          cb: () => emit('tableChange'),
        },
        action: `${action}Employee`,
      })
    }
    const signatureBasisDialog = (employee: Employee) => {
      return commit('setDialog', {
        title: 'Підписант',
        dialogItem: {
          employeeId: employee.id,
          employeeSignatoryBases: employee.employeeSignatoryBases,
        },
        params: {
          cb: () => emit('tableChange'),
          rejectCb: () => emit('tableChange'),
          closeCb: () => emit('tableChange'),
        },
        action: 'signatureBasis',
      })
    }

    return { employeeDialog, signatureBasisDialog }
  },
}
</script>

<style scoped></style>
