<template>
  <v-row>
    <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
      <span class="label">Назва заявки</span>
      <v-text-field
        v-model="serviceApplication.serviceApplicationName"
        placeholder="Введіть текст"
        hide-details
        :error-messages="serviceApplicationNameErr"
        @blur="v$.serviceApplication.serviceApplicationName.$touch()">
      </v-text-field>
    </v-col>
    <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
      <span class="label">Тип</span>
      <v-select
        v-model="serviceApplication.typeId"
        placeholder="Оберіть зі списку"
        hide-details
        :items="$directory.get('serviceApplicationTypes')"
        :loading="$loading.isLoading('serviceApplicationTypes')"
        item-title="name"
        item-value="id"
        :error-messages="typeIdErr"
        @blur="v$.serviceApplication.typeId.$touch()">
        <template v-if="$loading.isLoading('serviceApplicationTypes')" #no-data>
          <loader></loader>
        </template>
      </v-select>
    </v-col>
    <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
      <span class="label">Прізвище</span>
      <v-text-field
        v-model="serviceApplication.surname"
        placeholder="Введіть текст"
        hide-details>
      </v-text-field>
    </v-col>
    <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
      <span class="label">Ім'я</span>
      <v-text-field
        v-model="serviceApplication.name"
        placeholder="Введіть текст"
        hide-details
        :error-messages="nameErr"
        @blur="v$.serviceApplication.name.$touch()">
      </v-text-field>
    </v-col>
    <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
      <span class="label">По батькові</span>
      <v-text-field
        v-model="serviceApplication.patronymic"
        placeholder="Введіть текст"
        hide-details>
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import Loader from '@/components/Loader.vue'
import { required } from '@vuelidate/validators'
import { setErrHandler } from '@/utils/helperFunc'
import { urlCreateServiceApplication } from '@/pages/request'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
import { fillDirectory } from '@/plugins/directory/index.ts'

export default {
  components: { Loader },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  validations() {
    return {
      serviceApplication: {
        serviceApplicationName: { required },
        sourceId: { required },
        typeId: { required },
        name: { required },
      },
    }
  },
  data() {
    return {
      serviceApplication: {
        serviceApplicationName: null,
        sourceId: 3,
        typeId: null,
        name: null,
        patronymic: null,
        surname: null,
      },
    }
  },
  computed: {
    validation() {
      return { v$: this.v$.serviceApplication, name: 'serviceApplication' }
    },
    serviceApplicationNameErr() {
      return setErrHandler(this.v$.serviceApplication.serviceApplicationName)
    },
    typeIdErr() {
      return setErrHandler(this.v$.serviceApplication.typeId)
    },
    nameErr() {
      return setErrHandler(this.v$.serviceApplication.name)
    },
  },
  methods: {
    submit() {
      return this.$axios.post(
        urlCreateServiceApplication(),
        this.serviceApplication
      )
    },
  },
  created() {
    fillDirectory('serviceApplicationTypes')
  },
}
</script>

<style lang="scss" scoped></style>
