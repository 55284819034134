<template>
  <div class="d-block flex-column">
    <div class="mb-5 font-weight-bold">
      <v-icon style="margin-left: 8px" size="20">
        {{ 'mdi-account' }}
      </v-icon>
      ПIБ: {{ dialog.dialogItem.fullName || 'данi Відсутні' }}
    </div>

    <div class="mb-5 font-weight-bold">
      <v-icon style="margin-left: 8px" size="20">
        {{ 'mdi-phone' }}
      </v-icon>
      Телефон:
      {{ dialog.dialogItem.phone }}
    </div>
    <div class="mb-5">
      <v-icon
        v-if="dialog.dialogItem?.status_call?.id === 1"
        style="margin-left: 8px"
        size="20">
        {{ 'mdi-check-circle' }}
      </v-icon>
      Статус:
      {{ dialog.dialogItem.status_call?.name || '---' }}
    </div>
    <div class="mb-2">
      Вид дзвінка:
      {{ dialog.dialogItem.type_call }}
    </div>
    <div class="mb-5">
      Платформа:
      {{ dialog.dialogItem.telephony }}
    </div>

    <div class="mb-2">
      Початок дзвінка:
      {{ dialog.dialogItem.startTime }}
    </div>

    <div class="mb-2">
      Завершення дзвінка:
      {{ dialog.dialogItem.endTime }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dialog: { type: Object },
  },
}
</script>

<style></style>
