<template>
  <div>
    <v-data-table
      class="calc-table --cust-cell-pd"
      mobileBreakpoint="750"
      dense
      :headers="THead"
      hide-default-footer
      :hide-default-header="!history.length"
      :items-per-page="-1"
      :items="history || []">
      <template #item.created_at="{ item }">
        <span>
          {{ toFormatDate(item.created_at, false, true) }}
        </span>
      </template>
      <template #item.responsible="{ item }">
        <div v-if="item.responsible">
          <v-avatar
            size="25px"
            color="indigo"
            style="margin-right: 5px"
            class="pb-0">
            <v-icon
              v-if="item.responsible && !item.responsible.photo"
              color="white">
              {{ 'mdi-account-circle' }}
            </v-icon>
            <img
              v-if="item.responsible.photo"
              alt="user photo"
              :src="item.responsible.photo" />
          </v-avatar>
          <span>
            {{ item.responsible.fullName || '---' }}
          </span>
        </div>
        <div v-else>Сформовано автоматично</div>
      </template>
      <template #item.status="{ item }">
        <div class="d-flex align-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6"
            height="6"
            viewBox="0 0 6 6"
            fill="none">
            <circle cx="3" cy="3" r="3" fill="#08487A" />
          </svg>
          <span style="padding-left: 10px">
            {{ getStatus(item, 'oldStatus') }}
            {{ '&#8594;' }}<br />
            {{ getStatus(item, 'newStatus') }}
          </span>
        </div>
      </template>
      <template #item.comment="{ item }">
        <div class="table-cell text-left">
          <div class="d-flex align-start flex-column">
            <span class="commentTable">{{ item.comment || 'Відсутній' }}</span>
            <div v-if="item.comment" style="margin-bottom: 0">
              <v-menu>
                <template #activator="{ props }">
                  <span class="link" v-bind="props"> Показати повністю </span>
                </template>
                <v-card
                  :elevation="13"
                  :style="{ minWidth: getCommentWidth(item.comment) }">
                  <div class="d-flex justify-end pa-2">
                    <v-btn icon size="x-small" @click="commentDialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                  <p class="pa-3">
                    {{ item.comment }}
                  </p>
                </v-card>
              </v-menu>
            </div>
          </div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  getContactInitials,
  toFormatDate,
  getResponsibleShortName,
} from '@/utils/helperFunc'

export default {
  props: {
    project: { type: Object },
  },
  data() {
    return {
      THead: [
        { title: 'Дата', key: 'created_at', sortable: true },
        { title: 'Тип події', key: 'status', sortable: false },
        { title: 'Відповідальний', key: 'responsible', sortable: false },
        { title: 'Коментар', key: 'comment', sortable: false },
      ],
      commentDialog: false,
      commentModal: false,
    }
  },
  computed: {
    history() {
      if (this.project && this.project.logs) {
        return Object.clone(this.project.logs || []).sort((a, b) => {
          const dateA = new Date(a.created_at)
          const dateB = new Date(b.created_at)
          return dateB - dateA
        })
      } else return []
    },
  },
  methods: {
    getContactInitials,
    toFormatDate,
    getResponsibleShortName,
    getCommentWidth(comment) {
      const maxWidth = 700
      const minWidth = 250
      const width = comment.length * 6.5
      const resultWidth =
        width > minWidth ? Math.min(maxWidth, width) : minWidth

      return `${resultWidth}px`
    },
    getStatus(item, type) {
      const serviceMap = {
        1: ' (СБ)',
        2: ' (ЮР)',
        3: ' (Ризик)',
        4: ' (ФМ)',
      }

      let service = ''

      if (type === 'newStatus' && item.newStatusServiceId !== null) {
        service = serviceMap[item.newStatusServiceId] || ''
      } else if (type === 'oldStatus' && item.oldStatusServiceId !== null) {
        service = serviceMap[item.oldStatusServiceId] || ''
      }

      const statusName = item[type] ? item[type].name : ''
      return statusName + service || '---'
    },
  },
}
</script>

<style lang="scss" scoped>
.commentTable {
  display: block;
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.history-item {
  font-size: 12px !important;
}
</style>
