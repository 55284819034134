<template>
  <v-card-text>
    <v-card-title>Шаблони ДКП Бест Лізинг</v-card-title>
    <v-row>
      <v-col :cols="12" :md="4">
        <FilesUploader
          v-model:documents="dkps.dkpLeasReverseNoVat"
          label="ДКП зворотній лізинг без ПДВ"
          :uploadPath="urlUploadBlDkp"
          dkp
          acceptedExt=".doc, .docx"
          leaseReverse>
        </FilesUploader>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12" :md="4">
        <FilesUploader
          v-model:documents="dkps.dkpLeasReverseVat"
          label="ДКП зворотній лізинг з ПДВ"
          :uploadPath="urlUploadBlDkp"
          dkp
          acceptedExt=".doc, .docx"
          leaseReverse
          pdv>
        </FilesUploader>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12" :md="4">
        <FilesUploader
          v-model:documents="dkps.dkpNoVat"
          label="ДКП без ПДВ"
          :uploadPath="urlUploadBlDkp"
          dkp
          acceptedExt=".doc, .docx">
        </FilesUploader>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12" :md="4">
        <FilesUploader
          v-model:documents="dkps.dkpVat"
          label="ДКП з ПДВ"
          :uploadPath="urlUploadBlDkp"
          dkp
          acceptedExt=".doc, .docx"
          pdv>
        </FilesUploader>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import FilesUploader from '@/components/FilesUploader.vue'
import { urlUploadBlDkp } from '@/pages/request'
import { mapState } from 'vuex'

export default {
  components: {
    FilesUploader,
  },
  data() {
    return {
      dkpLeasReverseNoVat: null,
      dkpLeasReverseVat: null,
      dkpNoVat: null,
      dkpVat: null,
      loading: false,
    }
  },
  computed: {
    urlUploadBlDkp,
    ...mapState({
      dkps: state => state.blDkps.dkps,
    }),
  },
  created() {
    this.$store.dispatch('loadBlDkps')
  },
}
</script>

<style scoped></style>
