<template>
  <v-card>
    <v-card-text class="mr-0">
      <div
        v-if="notifications.length"
        id="notification-container"
        class="notification__container">
        <div
          v-for="item in notifications"
          :id="`notification-${item.id}`"
          :key="item.id"
          class="notification"
          @click="
            $router.push(getRouteByEntityType(item.entityTypeId, item.entityId))
          ">
          <div
            class="notification__status"
            :class="{
              isRead: item.isRead,
            }"></div>
          <div>
            <div class="mb-1">
              <span class="d-block font-bold mb-1">
                {{ item.entity_type?.name }}
              </span>
              <span
                class="d-inline-block"
                style="max-width: 300px"
                v-html="item.message"></span>
            </div>
            <div>
              <span style="font-size: 11px">{{
                toFormatDate(item.created_at, false, true)
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else>Список порожній</div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { toFormatDate, getRouteByEntityType } from '@/utils/helperFunc'

export default {
  name: 'NotificationList',
  data: () => {
    return {
      notificationNodes: [],
      notificationsContainer: null,
    }
  },
  computed: {
    ...mapState({
      notifications: state => state.notifications.notificationList,
    }),
  },
  methods: {
    toFormatDate,
    getRouteByEntityType,
    markVisibleNotificationsAsRead() {
      if (!this.notificationsContainer) {
        throw new Error('notificationsContainer node is not found')
      }
      this.notificationNodes.forEach((notification, idx, arr) => {
        const curHeight =
          (this.notificationsContainer.scrollHeight / arr.length) * (idx + 1)
        const isVisible =
          this.notificationsContainer.scrollTop <= curHeight &&
          this.notificationsContainer.scrollTop +
            this.notificationsContainer.clientHeight +
            1 >=
            curHeight

        const notificationId = Number(notification.id.split('-')[1])
        const isRead = this.notifications.find(
          n => n.id === notificationId
        ).isRead

        if (isVisible && !isRead) {
          this.$store.dispatch('readNotification', notificationId)
        }
      })
    },
  },
  mounted() {
    const notificationsContainer = document.getElementById(
      'notification-container'
    )

    if (!notificationsContainer) {
      console.error("element with id 'notification-container' not found")
      return
    }

    this.notificationsContainer = notificationsContainer

    this.notificationNodes = Array.from(notificationsContainer.children)

    this.markVisibleNotificationsAsRead()

    notificationsContainer.addEventListener(
      'scroll',
      this.markVisibleNotificationsAsRead
    )
  },
  beforeUnmount() {
    if (
      typeof this.notificationsContainer?.removeEventListener === 'function'
    ) {
      this.notificationsContainer.removeEventListener(
        'scroll',
        this.markVisibleNotificationsAsRead
      )
    }
  },
}
</script>

<style lang="scss" scoped>
.notification {
  margin-bottom: 0.5rem;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  padding: 0.5rem 0.5rem 0.5rem 0;
  &:hover {
    background: #f0f0f0;
  }
  &__container {
    width: 380px;
    height: auto;
    max-height: calc(100vh - 10rem);
    position: relative;
    overflow: auto;
    flex-grow: 1;
    padding-right: 1rem;
  }
  &__status {
    width: 5px;
    height: 80px;
    border-radius: 0 25px 25px 0;
    background: #fc7247;
    margin-right: 0.5rem;
    &.isRead {
      background: transparent !important;
    }
  }
}
</style>
