<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none">
    <path
      d="M3.357 1.85725C2.95918 1.85725 2.57765 2.01528 2.29634 2.29659C2.01504 2.57789 1.857 2.95942 1.857 3.35725V16.643C1.85701 17.0036 1.98699 17.3523 2.22312 17.6249C2.45926 17.8976 2.78573 18.076 3.14272 18.1275V11.9287C3.14272 11.4172 3.34591 10.9266 3.70758 10.565C4.06926 10.2033 4.5598 10.0001 5.07129 10.0001H14.9284C15.4399 10.0001 15.9305 10.2033 16.2921 10.565C16.6538 10.9266 16.857 11.4172 16.857 11.9287V18.1275C17.214 18.076 17.5405 17.8976 17.7766 17.6249C18.0127 17.3523 18.1427 17.0036 18.1427 16.643V5.53096C18.1427 5.13239 17.985 4.75096 17.703 4.46982L15.5301 2.29696C15.3908 2.15755 15.2254 2.04696 15.0433 1.97151C14.8613 1.89606 14.6661 1.85723 14.469 1.85725H14.2856V5.5001C14.2856 5.75337 14.2357 6.00415 14.1388 6.23814C14.0418 6.47212 13.8998 6.68472 13.7207 6.86381C13.5416 7.04289 13.329 7.18495 13.095 7.28187C12.861 7.37879 12.6103 7.42867 12.357 7.42867H6.78557C6.27408 7.42867 5.78355 7.22549 5.42187 6.86381C5.06019 6.50213 4.857 6.01159 4.857 5.5001V1.85725H3.357ZM6.14272 1.85725V5.5001C6.14272 5.85496 6.43072 6.14296 6.78557 6.14296H12.357C12.5275 6.14296 12.691 6.07523 12.8116 5.95467C12.9321 5.83411 12.9999 5.6706 12.9999 5.5001V1.85725H6.14272ZM15.5713 18.143V11.9287C15.5713 11.7582 15.5036 11.5947 15.383 11.4741C15.2624 11.3535 15.0989 11.2858 14.9284 11.2858H5.07129C4.90079 11.2858 4.73728 11.3535 4.61672 11.4741C4.49616 11.5947 4.42843 11.7582 4.42843 11.9287V18.143H15.5713ZM0.571289 3.35725C0.571289 2.61843 0.864783 1.90987 1.38721 1.38745C1.90963 0.865027 2.61819 0.571533 3.357 0.571533H14.4699C15.2087 0.571577 15.9172 0.8651 16.4396 1.38753L18.6124 3.56039C19.1353 4.08325 19.4284 4.7921 19.4284 5.53096V16.643C19.4284 17.3818 19.1349 18.0903 18.6125 18.6128C18.0901 19.1352 17.3815 19.4287 16.6427 19.4287H3.357C2.61819 19.4287 1.90963 19.1352 1.38721 18.6128C0.864783 18.0903 0.571289 17.3818 0.571289 16.643V3.35725Z"
      fill="#FC7247" />
  </svg>
</template>

<script>
export default {
  name: 'Save',
}
</script>

<style scoped></style>
