<template>
  <div>
    <span class="label">Ліміт фінансування</span>
    <v-text-field
      id="limitOfFinancing"
      v-model="klpLimitOfFinancing.limitOfFinancing"
      dense
      hide-details
      :error-messages="limitOfFinancingErr"
      placeholder="Введіть ліміт"
      @input="inputNumber($event, { float: 2 })"
      @blur="
        $event => {
          inputNumber($event, { float: 2, saveFloat: true })
          v$.klpLimitOfFinancing.limitOfFinancing.$touch()
        }
      "
      @focusin="Input.hideData('limitOfFinancing')"
      @focusout="Input.restoreData('limitOfFinancing')">
    </v-text-field>
  </div>
</template>

<script>
import {
  changeInputVal,
  formutSum,
  inputNumber,
  keepDigit,
} from '@/utils/formatFunc'
import { urlKlpSetResult } from '@/pages/request'
import { Input, setErrHandler } from '@/utils/helperFunc'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

export default {
  props: {
    dialog: { type: Object },
  },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      klpLimitOfFinancing: {
        limitOfFinancing: { required },
      },
    }
  },
  data: () => ({
    klpLimitOfFinancing: {
      limitOfFinancing: null,
    },
    Input: new Input(),
  }),
  computed: {
    validation() {
      return { v$: this.v$.klpLimitOfFinancing, name: 'klpLimitOfFinancing' }
    },
    project() {
      return this.dialog.dialogItem?.project || {}
    },
    limitOfFinancingErr() {
      return setErrHandler(this.v$.klpLimitOfFinancing.limitOfFinancing)
    },
  },
  methods: {
    inputNumber,
    formutSum,
    keepDigit,
    changeInputVal,
    submit() {
      return this.$axios
        .post(urlKlpSetResult(this.project.id), {
          limitOfFinancing: parseFloat(
            this.klpLimitOfFinancing?.limitOfFinancing.replace(/\s/g, '')
          ),
        })
        .then(() => {
          this.$emit('loading', false)
        })
    },
  },
  created() {
    this.klpLimitOfFinancing.limitOfFinancing =
      this.dialog.dialogItem?.project?.limitOfFinancing
  },
}
</script>

<style scoped></style>
