<template>
  <div style="position: relative">
    <div class="white-block" style="padding-bottom: 0">
      <v-tabs
        show-arrows
        :direction="$vuetify.breakpoint?.xs ? 'vertical' : 'horizontal'"
        :height="$vuetify.breakpoint?.xs ? null : 38"
        background-color="grey darken-2"
        color="white"
        dark
        slider-color="red"
        dense
        class="mb-5">
        <v-tab
          replace
          :to="{
            name: 'ss-client-verification',
            params: { id: $route.params.id, tabState: $route.params.tabState },
          }">
          Клієнт
        </v-tab>
        <v-tab
          v-for="(provider, idx) in providers"
          :key="provider.providerId"
          replace
          :to="{
            name: 'ss-provider-verification',
            params: {
              id: $route.params.id,
              tabState: $route.params.tabState,
              providerIdx: idx + 1,
            },
          }">
          <span
            style="
              overflow: hidden;
              display: block;
              white-space: nowrap;
              text-overflow: ellipsis;
            ">
            Постачальник {{ getEntityName(provider) }}
          </span>
        </v-tab>
        <v-tab
          v-for="(item, index) in guarantors"
          :key="`guarantor_${item.id}`"
          replace
          :to="{
            name: 'ss-guarantor',
            params: {
              idx: index + 1,
              id: $route.params.id,
              tabState: $route.params.tabState,
            },
          }">
          <span
            style="
              overflow: hidden;
              display: block;
              white-space: nowrap;
              text-overflow: ellipsis;
            ">
            Поручитель {{ getGuarantorName(guarantors[index]) }}
          </span>
        </v-tab>
        <v-tab
          v-for="(item, index) in subtenants"
          :key="`subtenant_${item.id}`"
          replace
          :to="{
            name: 'ss-subtenant',
            params: {
              idx: index + 1,
              id: $route.params.id,
              tabState: $route.params.tabState,
            },
          }">
          <span
            style="
              overflow: hidden;
              display: block;
              white-space: nowrap;
              text-overflow: ellipsis;
            ">
            Суборендатор {{ getSubtenantName(subtenants[index]) }}
          </span>
        </v-tab>
      </v-tabs>
      <v-tabs
        v-if="loTabs && calculations && calculations.length"
        slider-color="#ff000000"
        class="newForTab">
        <v-tab
          class="fortab"
          replace
          :to="{
            name: 'ss-provider-verification',
            params: {
              providerIdx: $route.params.providerIdx,
              id: $route.params.id,
              tabState: $route.params.tabState,
            },
          }">
          Загальні дані
        </v-tab>
        <v-tab
          v-for="(calc, idx) in calculationsByProvider"
          :key="calc.id"
          class="fortab"
          replace
          :to="{
            name: 'ss-leasing-object',
            params: {
              calculationIdx: idx + 1,
              id: $route.params.id,
              tabState: $route.params.tabState,
              providerIdx: $route.params.providerIdx,
            },
          }">
          <svg
            class="mr-1"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 19 18"
            fill="none">
            <path
              d="M2.5 6.75L4 1.6875H15L16.5 6.75M15 12.375C14.6022 12.375 14.2206 12.1972 13.9393 11.8807C13.658 11.5643 13.5 11.1351 13.5 10.6875C13.5 10.2399 13.658 9.81072 13.9393 9.49426C14.2206 9.17779 14.6022 9 15 9C15.3978 9 15.7794 9.17779 16.0607 9.49426C16.342 9.81072 16.5 10.2399 16.5 10.6875C16.5 11.1351 16.342 11.5643 16.0607 11.8807C15.7794 12.1972 15.3978 12.375 15 12.375ZM4 12.375C3.60218 12.375 3.22064 12.1972 2.93934 11.8807C2.65804 11.5643 2.5 11.1351 2.5 10.6875C2.5 10.2399 2.65804 9.81072 2.93934 9.49426C3.22064 9.17779 3.60218 9 4 9C4.39782 9 4.77936 9.17779 5.06066 9.49426C5.34196 9.81072 5.5 10.2399 5.5 10.6875C5.5 11.1351 5.34196 11.5643 5.06066 11.8807C4.77936 12.1972 4.39782 12.375 4 12.375ZM16.42 1.125C16.22 0.4725 15.66 0 15 0H4C3.34 0 2.78 0.4725 2.58 1.125L0.5 7.875V16.875C0.5 17.1734 0.605357 17.4595 0.792893 17.6705C0.98043 17.8815 1.23478 18 1.5 18H2.5C2.76522 18 3.01957 17.8815 3.20711 17.6705C3.39464 17.4595 3.5 17.1734 3.5 16.875V15.75H15.5V16.875C15.5 17.1734 15.6054 17.4595 15.7929 17.6705C15.9804 17.8815 16.2348 18 16.5 18H17.5C17.7652 18 18.0196 17.8815 18.2071 17.6705C18.3946 17.4595 18.5 17.1734 18.5 16.875V7.875L16.42 1.125Z"
              fill="#838282" />
          </svg>
          {{ calc.leasingObjectName }}
        </v-tab>
      </v-tabs>
    </div>
    <SectionLoader v-if="loading"></SectionLoader>
    <div v-if="!loading" style="position: absolute; right: 5px; top: 15px">
      <TableCrud
        data-readonly="hide"
        style="color: #fc7247"
        color="orange"
        :actionsArray="[
          {
            action: takeToWork,
            text: 'Взяти в роботу',
            icon: 'mdi-timer-outline',
            disabled: [2, 3, 4].includes(ssAnalyze.statusId),
          },
          {
            action: revision,
            text: 'Відправити на доопрацювання',
            icon: 'mdi-timer-off-outline',
            disabled: [1, 3, 4].includes(ssAnalyze.statusId),
          },
        ]">
      </TableCrud>
    </div>
    <router-view
      v-if="!loading"
      :id="id"
      :key="$route.fullPath"
      :ssAnalyze="ssAnalyze"
      :isInWork="isInWork"
      :calculation="
        calculationsByProvider[$route.params.calculationIdx - 1 || 0]
      "
      :provider="providers[$route.params.providerIdx - 1]"
      :conclusionsState="conclusionsState"
      :guarantors="guarantors"
      :subtenants="subtenants"
      @submit="getSequrityServiceAnalyzesById"
      @get="$emit('get')"
      @set-conclusion="setConclusion">
    </router-view>
  </div>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'

import {
  urlGetSequrityServiceAnalyzesById,
  urlSecurityServiceTakeToWork,
  urlSetSecurityServiceStatus,
  urlSecurityServiceResult,
} from '@/pages/request'

import {
  LODescription as getLoDescription,
  getProjectGuarantors,
  getProjectSubtenants,
  getContactInitials,
  getEntityName,
} from '@/utils/helperFunc'
import { checkService } from '../../helper'
import TableCrud from '@/components/table-crud.vue'

export default {
  components: {
    TableCrud,
    SectionLoader,
  },
  emits: ['get'],
  data: () => ({
    currentTab: 0,
    string: 1,
    ssAnalyze: {},
    loading: false,
    conclusionsState: {
      clientConclusion: null,
      providerResults: [],
      guarantorResults: [],
      subtenantResults: [],
    },
  }),
  computed: {
    id() {
      return this.$route.params.id
    },
    loTabs() {
      return ['ss-provider-verification', 'ss-leasing-object'].includes(
        this.$route.name
      )
    },
    isInWork() {
      return this.ssAnalyze.statusId === 2
    },
    calculations() {
      return this.ssAnalyze.calculations || []
    },
    guarantors() {
      return this.getProjectGuarantors(this.ssAnalyze?.guarantors)
    },
    subtenants() {
      return this.getProjectSubtenants(this.ssAnalyze?.subtenants)
    },
    providers() {
      if (!this.ssAnalyze.providers) return []
      return this.ssAnalyze.providers.map(p => {
        const getTypeId = () => {
          switch (true) {
            case !!p.contractor:
              return 2
            case !!p.contact && !!p.contact.fop:
              return 3
            case !!p.contact && !p.contact.fop:
              return 1
          }
        }

        const providerTypeId = getTypeId()
        const providerEntity = providerTypeId === 2 ? p.contractor : p.contact
        const providerId = `${providerTypeId}${providerEntity.id}`

        return { ...providerEntity, providerTypeId, providerId }
      })
    },
    calculationsByProvider() {
      if (!this.ssAnalyze.calculations || !this.ssAnalyze.calculations.length)
        return []
      return this.loTabs
        ? this.calculations.filter(
            c =>
              `${c.providerTypeId}${c.providerId}` ===
              this.providers[this.$route.params.providerIdx - 1].providerId
          )
        : []
    },
    isLastConclusion() {
      const totalConclusionsAmount =
        1 +
        (this.ssAnalyze.guarantors ? this.ssAnalyze.guarantors.length : 0) +
        (this.ssAnalyze.subtenants ? this.ssAnalyze.subtenants.length : 0) +
        (this.ssAnalyze.providers ? this.ssAnalyze.providers.length : 0)
      let curConclusionsAmount = 0
      const increment = () => (curConclusionsAmount += 1)

      for (const key in this.conclusionsState) {
        const conclusion = this.conclusionsState[key]

        if (Array.isArray(conclusion)) {
          conclusion.forEach(() => increment())
        } else {
          conclusion && increment()
        }
      }

      return totalConclusionsAmount - 1 === curConclusionsAmount
    },
  },
  methods: {
    getEntityName,

    getProjectGuarantors,
    getProjectSubtenants,
    getContactInitials,
    urlGetSequrityServiceAnalyzesById,
    urlSecurityServiceTakeToWork,
    urlSetSecurityServiceStatus,
    urlSecurityServiceResult,
    getLoDescription,
    checkService,
    async setConclusion(payload) {
      if (this.isLastConclusion) {
        const invalid = await this.checkService(this.ssAnalyze.projectId)
        if (invalid) return
      }
      if (!this.id) {
        return this.$setSnackbar({
          text: 'Зачекайте будь ласка',
          color: 'warning',
        })
      }

      const { request, params = {} } = payload

      // set provider conclusion for leaseReverse calculations
      if (request.type === 1) {
        for (const calc of this.calculations) {
          if (calc.leaseReverse) {
            const r = {
              providerResultId: request.clientResultId,
              providerConclusion: request.clientConclusion,
              providerId: this.ssAnalyze?.lesseeId,
              providerTypeId: this.ssAnalyze?.lesseeTypeId,
              type: 2,
            }
            await this.setConclusion({
              request: r,
              params: { hideSuccessMsg: true },
            })
          }
        }
      }

      const entityMap = {
        1: { ruName: 'Клієнту', enName: 'client' },
        2: { ruName: 'Постачальнику', enName: 'provider' },
        3: { ruName: 'Поручителю', enName: 'guarantor' },
        4: { ruName: 'Суборендатору', enName: 'subtenant' },
      }
      this.loading = true
      return this.$axios
        .post(this.urlSecurityServiceResult(this.id), request)
        .then(async res => {
          if (!params.hideSuccessMsg) {
            const entity = entityMap[request.type]
            const resultText =
              request[`${entity.enName}ResultId`] === 1
                ? 'задоволено'
                : 'відмовлено'

            this.$setSnackbar({
              text: `${entity.ruName} ${resultText} в наданнi послуг`,
            })
            this.$emit('get')
            await this.getSequrityServiceAnalyzesById()
          }
          return res
        })
        .catch(this.$err)
    },
    getGuarantorName(g) {
      if (!g) return
      return g.guarantorTypeId === 2
        ? g.contractor.shortName
        : this.getContactInitials(g.contact)
    },
    getSubtenantName(g) {
      if (!g) return
      return g.subtenantTypeId === 2
        ? g.contractor.shortName
        : this.getContactInitials(g.contact)
    },
    revision() {
      const serviceObj = {
        url: this.urlSetSecurityServiceStatus(this.ssAnalyze.id),
        name: 'security',
      }
      return this.$store.commit('setDialog', {
        title: 'Відправити на доопрацювання',
        dialogItem: { serviceObj },
        params: {
          cb: () => this.getSequrityServiceAnalyzesById(),
        },
        action: 'sendToRevision',
      })
    },
    getSequrityServiceAnalyzesById() {
      if (!this.id) return
      this.loading = true
      return this.$axios
        .get(this.urlGetSequrityServiceAnalyzesById(this.id))
        .then(res => {
          Object.assign(this.ssAnalyze, res.data)
          const {
            clientConclusion,
            providerResults,
            guarantorResults,
            subtenantResults,
          } = this.ssAnalyze
          this.conclusionsState.clientConclusion = clientConclusion
          this.conclusionsState.providerResults = providerResults
          this.conclusionsState.guarantorResults = guarantorResults
          this.conclusionsState.subtenantResults = subtenantResults

          this.loading = false
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
    takeToWork() {
      return this.$axios
        .get(this.urlSecurityServiceTakeToWork(this.id))
        .then(res => {
          if (res.data.message) {
            this.$setSnackbar({ text: res.data.message, color: 'warning' })
          } else {
            this.$setSnackbar({ text: 'Статус: "В роботi"' })
            this.getSequrityServiceAnalyzesById()
          }
          return res
        })
    },
  },
  created() {
    this.getSequrityServiceAnalyzesById()
  },
}
</script>
<style scoped>
.verification-subheader {
  padding-top: 0 !important;
  font-size: 1rem !important;
}
.v-tab-item--selected.v-tab {
  font-weight: 700 !important;
  color: #08487a !important;
  font-size: 13px;
}
.v-tab {
  color: #838282 !important;
  font-size: 13px;
  letter-spacing: normal;
  text-transform: uppercase;
}
</style>
