<template>
  <div>
    <Comments
      style="margin-top: -20px !important"
      :label="false"
      :comments="contact.comments ? contact.comments : []"
      :entityId="contact.id"
      :entityTypeId="1" />
  </div>
</template>

<script>
import Comments from '@/components/comments/Index.vue'

export default {
  components: {
    Comments,
  },
  props: {
    contact: { type: Object },
  },
}
</script>

<style scoped></style>
