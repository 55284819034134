<template>
  <div>
    <SectionLoader v-if="pageLoad"></SectionLoader>
    <v-fade-transition hide-on-leave>
      <div v-if="!pageLoad" class="pl-3 pr-3 pb-0 agent-block">
        <v-fade-transition hide-on-leave>
          <v-row>
            <CommonCard :agent="agentData" :readOnly="readOnly"> </CommonCard>
            <v-col :cols="12" :md="9" class="pr-0 pb-1">
              <div class="mb-2">
                <div style="min-height: 350px">
                  <v-tabs
                    v-model="currentTab"
                    style="background: white !important"
                    class="contact-tab-agent pl-2"
                    show-arrows
                    :height="$vuetify.breakpoint?.xs ? null : 38"
                    :direction="
                      $vuetify.breakpoint?.xs ? 'vertical' : 'horizontal'
                    "
                    :class="{ 'v-tabs--mobile': $vuetify.breakpoint?.xs }"
                    color="white"
                    dark
                    slider-color="#FC7247"
                    dense>
                    <v-tab
                      replace
                      :to="{
                        name: 'agent',
                        params: { id: $route.params.id },
                      }">
                      Агент
                    </v-tab>
                    <v-tab
                      replace
                      :to="{
                        name: 'payment-parameters',
                        params: { id: $route.params.id },
                      }">
                      Договір (агентський)
                    </v-tab>
                    <v-tab
                      replace
                      :to="{
                        name: 'applications-info',
                        params: { id: $route.params.id },
                      }">
                      Заявки
                    </v-tab>
                    <v-tab
                      replace
                      :to="{
                        name: 'agent-payment',
                        params: { id: $route.params.id },
                      }">
                      Виплати
                    </v-tab>
                  </v-tabs>
                  <div class="pa-0 pt-0">
                    <RouterView
                      :pageLoad="pageLoad"
                      :agent="agentData"
                      :readOnly="readOnly"
                      :v="v$"
                      @refresh-data="getAgentById">
                    </RouterView>
                  </div>
                </div>
              </div>
            </v-col>
            <ActionButtons
              :confirm="submit"
              :cancel="getAgentById"
              :cancelDisable="
                (!agentData.$hasChanges && !agencyContractData.$hasChanges) ||
                loading ||
                readOnly
              "
              :confirmDisable="
                (!agentData.$hasChanges && !agencyContractData.$hasChanges) ||
                readOnly
              ">
            </ActionButtons>
          </v-row>
        </v-fade-transition>
      </div>
    </v-fade-transition>
  </div>
</template>
<script>
import CommonCard from './CommonCard.vue'
import ActionButtons from '@/components/action-buttons.vue'
import SectionLoader from '@/components/section-loader.vue'
import {
  urlGetAgent,
  urlUpdateAgent,
  urlUpdateAgencyContract,
} from '@/pages/request'
import { v$Notify, toFormatDate, backDate } from '@/utils/helperFunc'
import { mapState } from 'vuex'
import { ADMIN } from '@/roles'
import { required, minLength } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { cachedObject } from 'best-modules/utils'
export default {
  components: { CommonCard, ActionButtons, SectionLoader },
  emits: ['dataChanged'],
  setup() {
    const agentData = cachedObject({
      contractorId: null,
      dealerPointId: null,
      isPaymentThrough: false,
      paymentThroughFio: null,
      paymentThroughInn: null,
      paymentThroughPassport: null,
      paymentThroughBankName: null,
      paymentThroughIban: null,
      paymentThroughMfo: null,
      paymentThroughCardNumber: null,
      paymentThroughAddress: {},
    })
    const agencyContractData = cachedObject({
      isAgentContract: null,
      signedAgencyAgreement: {
        doc: null,
        date: null,
        number: null,
      },
    })
    return { v$: useVuelidate(), agentData, agencyContractData }
  },
  validations() {
    const pThrough = this.agentData.isPaymentThrough
    const agent = this.agentData

    const paymentThroughInn =
      pThrough && agent.paymentThroughInn
        ? { required, minLength: minLength(10) }
        : {}
    const paymentThroughIban =
      pThrough && agent.paymentThroughIban
        ? { required, minLength: minLength(29) }
        : {}
    const paymentThroughBankName =
      pThrough && agent.paymentThroughIban ? { required } : {}
    const paymentThroughMfo =
      pThrough && agent.paymentThroughIban ? { required } : {}
    const paymentThroughCardNumber =
      pThrough && agent.paymentThroughCardNumber
        ? { required, minLength: minLength(22) }
        : {}
    const agencyContract = {
      signedAgencyAgreement: {
        number: this.agencyContractData.isAgentContract ? { required } : {},
        date: this.agencyContractData.isAgentContract ? { required } : {},
        doc: this.agencyContractData.isAgentContract ? { required } : {},
      },
    }

    return {
      agentData: {
        paymentThroughInn: paymentThroughInn,
        paymentThroughIban: paymentThroughIban,
        paymentThroughBankName: paymentThroughBankName,
        paymentThroughMfo: paymentThroughMfo,
        paymentThroughCardNumber: paymentThroughCardNumber,
        ...agencyContract,
      },
    }
  },
  data: () => ({
    pageLoad: false,
    loading: false,
    currentTab: 0,
  }),
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    readOnly() {
      const isResponsible =
        this.agentData?.contact?.responsible.id === this.user?.id
      const isAdmin = this.user.roleId === ADMIN

      return !(isResponsible || isAdmin)
    },
  },
  methods: {
    toFormatDate,
    getAgentById() {
      this.pageLoad = true
      return this.$axios
        .get(urlGetAgent(this.$route.params.id))
        .then(res => {
          if (res?.data?.message) {
            return Promise.reject(res.data.message)
          }
          res.data.dateContract = res.data?.dateContract
            ? this.toFormatDate(res.data?.dateContract, true)
            : null
          const paymentThroughAddress = res?.data?.paymentThroughAddress
            ? {}
            : { paymentThroughAddress: {} }

          this.agentData.$setIgnore('isActive', 'isPaymentThrough')
          this.agencyContractData.$setIgnore('isAgentContract')
          this.agentData.$set({
            ...res.data,
            ...paymentThroughAddress,
          })
          this.agencyContractData.$set({
            isAgentContract: res?.data?.isAgentContract,
            signedAgencyAgreement: {
              doc: res.data?.signedAgencyAgreement?.doc || null,
              date: res.data?.signedAgencyAgreement?.date || null,
              number: res.data?.signedAgencyAgreement?.number || null,
            },
          })

          this.setBreadscrumb()
          this.pageLoad = false
          return res
        })
        .catch(err => {
          if (err) {
            this.$router.push({ name: 'agent-list' })
          }
        })
    },
    setBreadscrumb() {
      this.$store.commit('setBreadScrumbPart', [
        null,
        null,
        this.agentData.contact.fullName,
        null,
      ])
    },
    async submit() {
      if (this.v$.$invalid) {
        this.v$.$anyError
        this.v$.$touch()
        return v$Notify(this.v$.agentData, 'agent')
      }

      const reqObject = this.agentData?.$object || this.agentData
      reqObject.dateContract = reqObject?.dateContract
        ? backDate(reqObject.dateContract)
        : null
      reqObject.dealerPointId = reqObject?.dealerPoint?.id
      this.loading = true
      if (reqObject.isAgentContract) {
        await this.$axios.post(
          urlUpdateAgencyContract(this.$route.params.id),
          this.agencyContractData?.$object
        )
      }
      return this.$axios
        .post(urlUpdateAgent(this.$route.params.id), reqObject)
        .then(res => {
          Object.assign(this.agentData, res.data)
          this.$setSnackbar({ text: 'Дані оновлено' })
          this.getAgentById()
          this.loading = false
          this.$emit('dataChanged')
          return res
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
  },
  watch: {
    '$route.name': function () {
      this.setBreadscrumb()
    },
    'agentData.isAgentContract': {
      handler(val) {
        this.agencyContractData.isAgentContract = val
      },
      immediate: true,
    },
    'agentData.signedAgencyAgreement.number': {
      handler(val) {
        this.agencyContractData.signedAgencyAgreement.number = val
      },
      immediate: true,
      deep: true,
    },
    'agentData.signedAgencyAgreement.doc': {
      handler(val) {
        this.agencyContractData.signedAgencyAgreement.doc = val
      },
      immediate: true,
      deep: true,
    },
    'agentData.signedAgencyAgreement.date': {
      handler(val) {
        this.agencyContractData.signedAgencyAgreement.date = val
          ? backDate(val)
          : null
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.getAgentById()
  },
}
</script>
<style>
.agent-block {
  position: relative;
  align-items: start;
}
.v-tab {
  color: #838282;
  font-weight: 400;
  font-size: 14px;
}
.v-tab-item--selected.v-tab {
  font-weight: 700 !important;
  color: #08487a !important;
}
.contact-tab-agent .v-tabs-bar.v-item-group > * {
  background: white !important;
}
</style>

<style scoped lang="scss">
.contact-tab-agent .v-tabs-bar.v-item-group > * {
  background: white !important;
}
.v-tabs {
  & .v-tab {
    margin-right: 25px;
  }
  &--mobile {
    padding-right: 12px;
    .v-tab {
      margin-right: 0;
      margin-bottom: 12px;
      min-width: 100%;
      border: 1px solid #eaeaea;
      background: #eaeaea;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
      border-radius: 4px;
    }
  }
}
</style>
