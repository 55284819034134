<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" width="800" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h5">📑 Інформація</span>
        </v-card-title>
        <v-card-text style="height: 300px; overflow: scroll">
          <div v-html="parseInfo"></div>
        </v-card-text>
        <v-card-actions class="position-absolute right-0 bottom-0">
          <v-spacer></v-spacer>
          <v-btn
            style="background: #444444; color: #fff"
            variant="text"
            @click="parseReject">
            Скасувати
          </v-btn>
          <v-btn
            style="background: #09487a; color: #fff"
            variant="text"
            @click="parseAgreed">
            Ознайомився
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  emits: ['reject', 'agreed'],
  props: {
    info: { type: Object },
  },
  data() {
    return {
      dialog: true,
    }
  },
  computed: {
    parseInfo() {
      const infoHtml = Object.entries(this.info)
        .map(([key, value]) => `${key}: <strong>${value}</strong><br>`)
        .join('')
      return infoHtml
    },
  },
  methods: {
    parseReject() {
      this.$emit('reject')
      this.dialog = false
    },
    parseAgreed() {
      this.$emit('agreed')
      this.dialog = false
    },
  },
}
</script>

<style scoped></style>
