import {
  urlCreateEducation,
  urlUpdateEducation,
  urlDeleteEducation,
} from '@/pages/request'
import { makeRequest } from '../helper.js'
/* eslint-disable no-unused-vars */
// import { mutationPayload, actionParams } from './type-defs'

const dialog = {
  state: () => ({
    title: null,
    dialogItem: {},
    params: {},
    action: null,
    disabled: false,
  }),
  mutations: {
    // /** @type { mutationPayload } */
    setDialog(state, value) {
      return Object.assign(state, value)
    },
    closeDialog(state) {
      ;(state.title = null),
        (state.dialogItem = {}),
        (state.params = {}),
        (state.action = null),
        (state.disabled = false)
    },
  },
  actions: {
    // /** @type { actionParams } */
    async createEducation({ commit }, config) {
      const response = await makeRequest(urlCreateEducation(), config)
      return response
    },
    // /** @type { actionParams } */
    async updateEducation({ commit }, config) {
      const response = await makeRequest(urlUpdateEducation(config.urlParams[0]), config)
      return response
    },
    // /** @type { actionParams } */
    async deleteEducation({ commit }, config) {
      const response = await makeRequest(urlDeleteEducation(config.urlParams[0]), config)
      return response
    },
  },
  /* eslint-enable no-unused-vars */
  getters: {
    //...
  },
}

export { dialog }
