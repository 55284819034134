<template>
  <v-card-text>
    <SectionLoader v-if="pageLoad" />
    <div v-if="!pageLoad">
      <v-row class="d-flex justify-space-between">
        <v-col :cols="12" :sm="4">
          <v-text-field
            v-model="search"
            class="pt-0 mt-0"
            hide-details
            placeholder="Пошук"
            dense>
          </v-text-field>
        </v-col>
        <v-col :cols="12" :sm="4" class="d-flex justify-end">
          <v-btn
            data-readonly="hide"
            variant="flat"
            color="#fc7247"
            class="mr-3"
            @click="handlePresentationDialog('create')">
            <v-icon style="margin-right: 5px; color: #fff !important">
              {{ 'mdi-plus' }}
            </v-icon>
            Додати&nbsp;
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        :headers="faqPresentationHeaders"
        :items="presentations"
        class="calc-table --cust-cell-pd mt-3"
        mobileBreakpoint="750"
        dense
        :hide-default-footer="!presentations.length"
        :hide-default-header="!presentations.length"
        :search="search">
        <!-- eslint-disable-next-line -->
        <template #item.actions="{ item }">
          <TableCrud
            :actionsArray="[
              {
                action: () => handlePresentationDialog('update', item),
                text: 'Редагувати',
                icon: 'mdi-pencil',
              },
              {
                action: () => deletePresentation(item),
                text: 'Видалити',
                icon: 'mdi-delete',
              },
            ]">
          </TableCrud>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.created_at="{ item }">
          {{ toFormatDate(item.created_at) }}
        </template>
      </v-data-table>
    </div>
    <RouterView v-if="!faqOtherListRoute" />
  </v-card-text>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'
import TableCrud from '@/components/table-crud.vue'
import { faqPresentationHeaders } from '@/utils/tableHeaders'
import {
  urlFaqDeletePresentation,
  urlFaqGetPresentations,
} from '@/pages/request'
import { toFormatDate } from '@/utils/helperFunc'
export default {
  components: {
    TableCrud,
    SectionLoader,
  },
  data: () => ({
    search: null,
    pageLoad: false,
    presentations: [],
    faqPresentationHeaders,
  }),
  computed: {
    faqOtherListRoute() {
      return this.$route.name === 'admin-faq-presentations'
    },
  },
  methods: {
    toFormatDate,
    handlePresentationDialog(action, item) {
      this.$store.commit('setDialog', {
        title: action === 'create' ? 'Створити презентацію' : 'Презентація',
        dialogItem: action === 'update' ? item : {},
        params: {
          action: action,
          cb: res => {
            if (res.data && action === 'create') {
              this.presentations.push(res.data)
            }
          },
        },
        action: 'faq-presentation',
      })
    },
    deletePresentation(obj) {
      return this.$axios.delete(urlFaqDeletePresentation(obj.id)).then(res => {
        this.$setSnackbar({ text: 'Презентацію видалено' })
        this.presentations = this.presentations.filter(
          item => item.id !== obj.id
        )
        return res
      })
    },
    getPresentations() {
      this.pageLoad = true
      return this.$axios.get(urlFaqGetPresentations()).then(res => {
        this.presentations = res.data
        this.pageLoad = false
      })
    },
  },
  created() {
    this.getPresentations()
  },
}
</script>
