<template>
  <a
    ref="link"
    style="visibility: hidden; position: absolute; top: -50000px"
    :href="curUrl"
    :download="fileName">
  </a>
</template>
<script>
export default {
  props: {
    url: { type: String },
    fileName: { type: String, default: '' },
  },
  computed: {
    curUrl() {
      if (this.url.startsWith('http') || this.url.startsWith('/')) {
        return this.url
      } else {
        return `/${this.url}`
      }
    },
  },
}
</script>
