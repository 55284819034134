<template>
  <v-row>
    <v-col cols="12" md="12" sm="12" class="pb-0 pt-2">
      <span class="label">Дата та час</span>
      <DatePicker
        v-model="messagePlan.date"
        :minDate="minDate"
        time-picker
        show-time
        hide-dettails
        placeholder="Оберіть дату" />
    </v-col>
    <v-col cols="12" md="12" sm="12" class="pt-0">
      <span class="label">Назва нагадування</span>
      <v-textarea
        v-model="messagePlan.taskMessage"
        placeholder="Введіть текст"
        :error-messages="messagePlanErr"
        :rows="2"
        hide-details
        dense>
      </v-textarea>
    </v-col>
  </v-row>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue'
import { urlTaskPlanTask } from '@/pages/request'
import { setErrHandler } from '@/utils/helperFunc'
import { mapState } from 'vuex'
import { minLength, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
export default {
  components: {
    DatePicker,
  },
  setup() {
    return { v$: useVuelidate() }
  },
  props: {
    dialog: { type: Object },
  },
  validations() {
    return {
      messagePlan: {
        date: { required },
        taskMessage: { required, minLength: minLength(3) },
      },
    }
  },
  data: () => {
    return {
      messagePlan: {
        date: null,
        taskMessage: null,
        entityId: null,
        entityTypeId: null,
      },
    }
  },
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    validation() {
      return { v$: this.v$.messagePlan, name: 'planTask' }
    },
    datePlanErr() {
      return setErrHandler(this.v$.messagePlan?.date)
    },
    messagePlanErr() {
      return setErrHandler(this.v$.messagePlan?.taskMessage)
    },
    minDate() {
      const yesterday = new Date()
      return yesterday
    },
  },
  methods: {
    submit() {
      return this.addPlan()
    },
    addPlan() {
      const req = { ...this.messagePlan }
      req.entityId = this.dialog.dialogItem.entityId
      req.entityTypeId = this.dialog.dialogItem.entityTypeId

      return this.$axios.post(urlTaskPlanTask(), req)
    },
  },
}
</script>

<style>
.v-picker {
  flex-direction: row !important;
}
</style>
