<template>
  <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0.5H20V7.5H0V0.5Z" fill="#0098EE"/>
    <path d="M0 7.5H20V14.5H0V7.5Z" fill="#F9E475"/>
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
