<template>
  <div>
    <SectionLoader v-if="pageLoad" />
    <v-fade-transition hide-on-leave>
      <div v-if="!pageLoad">
        <v-card-title style="font-size: 1.05rem" class="pb-1 pt-2">
          Група супервайзера
        </v-card-title>
        <span class="label">Назва</span>
        <v-text-field
          v-model="supervisor.name"
          placeholder="Введіть назву"
          :error-messages="nameErr"
          readonly
          disabled
          @blur="v$.supervisor.name.$touch()">
        </v-text-field>
        <v-row>
          <v-col :cols="12" :sm="6">
            <span class="label">Супервайзер</span>
            <v-autocomplete
              v-model="supervisor.supervisorId"
              :error-messages="supervisorIdErr"
              :items="supervisors"
              :item-title="
                item => getContactInitials(item, { isResponsible: true })
              "
              item-value="id"
              placeholder="Оберіть зі списку"
              readonly
              disabled
              @blur="v$.supervisor.supervisorId.$touch()">
            </v-autocomplete>
          </v-col>
          <v-col :cols="12" :sm="6">
            <span class="label">Мiдл менеджер</span>
            <v-autocomplete
              v-model="supervisor.middleManagerId"
              :error-messages="middleManagerIdErr"
              :items="middleManagers"
              :item-title="
                item => item.surname + ' ' + item.name + ' ' + item.patronymic
              "
              item-value="id"
              placeholder="Оберіть зі списку"
              readonly
              disabled
              @blur="v$.supervisor.middleManagerId.$touch()">
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-card-title
          v-show="getSafe(() => supervisor.front_managers.length)"
          style="font-size: 1.05rem">
          Фронт менеджери
        </v-card-title>
        <v-row>
          <v-col :cols="12" :sm="4">
            <v-text-field
              v-show="getSafe(() => supervisor.front_managers.length)"
              v-model="search"
              class="ml-2"
              placeholder="Пошук"
              dense>
            </v-text-field>
          </v-col>
          <v-col :cols="12" :sm="8">
            <div class="d-flex justify-end">
              <v-tooltip bottom text="Додати фронт менеджера">
                <template #activator="{ props }">
                  <v-btn icon v-bind="props" @click="opendFrontDialog">
                    <v-icon>
                      {{ 'mdi-plus-circle-outline' }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Додати фронт менеджера</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
        <v-data-table
          :headers="tHead"
          :items="supervisor.front_managers"
          class="calc-table --cust-cell-pd"
          mobileBreakpoint="750"
          dense
          :hide-default-footer="!supervisor.front_managers.length"
          :hide-default-header="!supervisor.front_managers.length"
          :search="search">
          <!-- eslint-disable-next-line -->
          <template #no-data> Фронт менеджери Відсутні </template>
          <!-- eslint-disable-next-line -->
          <template #item.actions="{ item }">
            <TableCrud
              :actionsArray="[
                {
                  action: () =>
                    $store.commit('setDialog', {
                      title: 'Фронт менеджер',
                      dialogItem: { frontManagerId: item.id },
                      params: {
                        cb: () => {
                          getSupervisorTeam($route.params.id)
                          $setSnackbar({
                            text: 'Фронт менеджера видалено',
                          })
                          $emit('dataChanged')
                        },
                        supervisorTeamId: $route.params.id,
                      },
                      action: 'supervisorDeleteFront',
                    }),
                  text: 'Видалити',
                  icon: 'mdi-delete',
                },
              ]">
            </TableCrud>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.name="{ item }">
            {{ getResponsible(item) }}
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.phoneNum="{ item }">
            {{ item.phone }}
          </template>
        </v-data-table>
      </div>
    </v-fade-transition>
  </div>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'

import { urlGetSupervisorTeam } from '@/pages/request'
import { required } from '@vuelidate/validators'
import {
  setErrHandler,
  getResponsible,
  getResponsibleShortName,
  getContactInitials,
  getSafe,
} from '@/utils/helperFunc'
import { mapState } from 'vuex'
import { MIDLE_MANAGER, SUPERVISOR, FRONT_MANAGER, MIDLE_HEAD } from '@/roles'
import { useVuelidate } from '@vuelidate/core'
import TableCrud from '@/components/table-crud.vue'
import { fillDirectory, getDirectory } from '@/plugins/directory'
export default {
  components: { TableCrud, SectionLoader },
  emits: ['dataChanged'],
  setup() {
    return { v$: useVuelidate() }
  },
  props: {
    supervisorTeams: { type: Array },
  },
  validations() {
    return {
      supervisor: {
        name: { required },
        supervisorId: { required },
        middleManagerId: { required },
      },
    }
  },
  data: () => ({
    supervisor: {
      front_managers: [],
      middleManagerId: null,
      name: null,
      supervisorId: null,
    },
    search: null,
    pageLoad: false,
    tHead: [
      { title: 'ПIБ', key: 'name', align: 'start', sortable: true },
      { title: 'Email', key: 'email', sortable: false, align: 'center' },
      { title: 'Телефон', key: 'phoneNum', sortable: false, align: 'center' },
      { title: 'Дії', key: 'actions', align: 'end', sortable: false },
    ],
  }),
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    middleManagers() {
      return getDirectory('allUsers').filter(
        user =>
          (user.roleId === MIDLE_MANAGER || user.roleId === MIDLE_HEAD) &&
          user.isActive === true
      )
    },
    supervisors() {
      return getDirectory('allUsers').filter(user => user.roleId === SUPERVISOR)
    },
    frontManagers() {
      return getDirectory('allUsers').filter(
        user => user.roleId === FRONT_MANAGER
      )
    },
    supervisorIdErr() {
      return setErrHandler(this.v$?.supervisor?.supervisorId)
    },
    middleManagerIdErr() {
      return setErrHandler(this.v$?.supervisor?.middleManagerId)
    },
    nameErr() {
      return setErrHandler(this.v$?.supervisor?.name)
    },
    existedFrontManagers() {
      const arrFront = this.supervisorTeams.map(team =>
        (team.front_managers || []).map(manager => manager.id)
      )
      // eslint-disable-next-line prefer-spread
      return [].concat.apply([], arrFront).filter(v => v)
    },
  },
  methods: {
    getSafe,
    getContactInitials,
    getResponsibleShortName,
    getResponsible,
    opendFrontDialog() {
      this.$store.commit('setDialog', {
        title: 'Фронт менеджер',
        dialogItem: {},
        params: {
          cb: () => {
            this.getSupervisorTeam(this.$route.params.id)
            this.$setSnackbar({ text: 'Фронт менеджера додано' })
            this.$emit('dataChanged')
          },
          existedFrontManagers: this.existedFrontManagers,
          supervisorTeamId: this.$route.params.id,
        },
        action: 'supervisorAddFront',
      })
    },
    getSupervisorTeam(id) {
      this.pageload = true

      return this.$axios
        .get(urlGetSupervisorTeam(id))
        .then(res => {
          res.data.front_managers = (res.data.front_managers || []).map(
            front => {
              front.phoneNum = (front.phone || '').replace(/\D/g, '') || '---'
              return front
            }
          )
          Object.assign(this.supervisor, res.data)
          this.pageload = false
          return res
        })
        .catch(err => this.$err(err, () => (this.pageload = false)))
    },
  },
  watch: {
    supervisor: {
      handler: function (supervisor) {
        this.$store.commit('setBreadScrumbPart', [null, null, supervisor.name])
      },
      deep: true,
    },
  },
  created() {
    this.getSupervisorTeam(this.$route.params.id)
    fillDirectory('allUsers')
  },
}
</script>
