<template>
  <div>
    <ServiceTabs>
      <template #projects>
        <Projects />
      </template>
      <template #scoring>
        <Scoring />
      </template>
    </ServiceTabs>
  </div>
</template>

<script>
import Projects from './Projects.vue'
import Scoring from '@/pages/projects-verification/scoring/List.vue'
import ServiceTabs from '@/pages/projects-verification/components/ServiceTabs.vue'
export default {
  name: 'SecurityServiceIndex',
  components: {
    Projects,
    Scoring,
    ServiceTabs,
  },
}
</script>

<style lang="scss" scoped></style>
