<template>
  <div class="block-shadow">
    <span class="label mb-5">
      Дізнайтесь ринкову вартість автомобіля онлайн
    </span>
    <CarApi
      :v="v$"
      @car-data-changed="handleCarDataChanged"
      @init-car-data="handleInitCarData" />
    <div>
      <SectionLoader v-if="loading" />
      <AverageStatistics
        v-if="!loading && (isEditAverage || averageResponseData !== null)"
        :averageResponseData="averageResponseData" />
    </div>
    <div class="d-flex justify-end mt-5 mb-5">
      <ActionButtons
        :confirmBtnText="isEditAverage ? 'Розрахувати повторно' : 'Розрахувати'"
        cancelBtnText="Відхилити"
        :cancelDisable="!carData.$hasChanges || loading"
        :confirmDisable="!carData.$hasChanges || loading"
        :confirm="() => calculate()"
        :cancel="() => $store.commit('refreshPage')" />
    </div>
  </div>
</template>

<script>
import CarApi from '@/pages/average-price/components/CarApi.vue'
import ActionButtons from '@/components/action-buttons.vue'
import { urlCreateAveragePrice, urlUpdateAveragePrice } from '@/pages/request'
import { required } from '@vuelidate/validators'
import AverageStatistics from '@/pages/average-price/components/statistics/AverageStatistics.vue'
import SectionLoader from '@/components/section-loader.vue'
import { useVuelidate } from '@vuelidate/core'
import { cachedObject } from 'best-modules/utils'

export default {
  components: {
    SectionLoader,
    AverageStatistics,
    ActionButtons,
    CarApi,
  },
  setup() {
    const carData = cachedObject({
      category: null,
      mark: null,
      model: null,
      yearFrom: null,
      yearTo: null,
    })
    return { v$: useVuelidate(), carData }
  },
  validations() {
    return {
      carData: {
        category: { required },
        mark: { required },
        model: { required },
        yearFrom: { required },
        yearTo: { required },
      },
    }
  },
  data: () => ({
    loading: false,
    averageResponseData: null,
  }),
  computed: {
    validation() {
      return { v$: this.v$.average, name: 'average' }
    },
    isEditAverage() {
      return this.$route.name === 'average-price-edit-calculation'
    },
  },
  methods: {
    highLightErr() {
      this.$setSnackbar({
        text: "Поля обов'язкові для заповнення: тип, марка, модель, рік від/рік до",
        color: 'error',
      })
      this.v$.$anyError
      this.v$.$touch()
    },
    calculate() {
      if (this.v$.$invalid) {
        return this.highLightErr()
      }
      this.loading = true

      const url = this.isEditAverage
        ? urlUpdateAveragePrice(+this.$route.params.id)
        : urlCreateAveragePrice()
      const req = Object.clone(this.carData.$object)
      req.mark = {
        name: req?.mark?.name,
        value: req?.mark?.id,
      }
      if (!this.isEditAverage) {
        req.engineVolume =
          req.engineVolume !== null ? Number(req.engineVolume) : null
        if (req.raceIntFrom !== null && typeof req.raceIntFrom === 'string') {
          req.raceIntFrom = parseInt(req.raceIntFrom.replace(/\s/g, ''))
        }
        if (req.raceIntTo !== null && typeof req.raceIntTo === 'string') {
          req.raceIntTo = parseInt(req.raceIntTo.replace(/\s/g, ''))
        }
      }
      return this.$axios.post(url, req).then(res => {
        this.carData.$set(res.data)
        this.averageResponseData = res.data
        this.loading = false
        return res
      })
    },
    setAverageResponseData(data) {
      if (this.isEditAverage) {
        const formatData = Object.clone(data)
        delete formatData.statistics
        this.averageResponseData = this.averageResponseData
          ? { ...this.averageResponseData, ...formatData }
          : { ...this.carData.$object, ...formatData }
      }
    },
    handleInitCarData(data) {
      this.carData.$set(data)
      this.setAverageResponseData(data)
    },
    handleCarDataChanged(data) {
      Object.assign(this.carData.$object, data)
      this.setAverageResponseData(data)
    },
  },
}
</script>

<style></style>
