<template>
  <v-card-text>
    <SectionLoader v-if="pageLoad" />
    <div v-if="!pageLoad && faqVideoListRoute">
      <v-row class="align-center">
        <v-col :cols="12" :sm="4">
          <v-text-field
            v-show="videos.length"
            v-model="search"
            hide-details
            placeholder="Пошук"
            dense>
          </v-text-field>
        </v-col>
        <v-col :cols="12" :sm="8" class="d-flex align-center justify-end">
          <v-btn
            v-show="videos.length"
            data-readonly="hide"
            variant="flat"
            color="#fc7247"
            class="mr-3"
            @click="openDialog">
            <v-icon style="margin-right: 5px; color: #fff !important">
              {{ 'mdi-plus' }}
            </v-icon>
            Додати&nbsp;
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tHead"
        :items="videos"
        class="calc-table --cust-cell-pd mt-3"
        mobileBreakpoint="750"
        dense
        :hide-default-footer="!videos.length"
        :hide-default-header="!videos.length"
        :search="search">
        <!-- eslint-disable-next-line -->
        <template #no-data>
          <EmptySection
            :icon="EmptyFile"
            title="Роздiл тимчасово порожнiй"
            :iconWidth="38"
            :iconHeight="38"
            @click="openDialog" />
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.actions="{ item }">
          <TableCrud
            :actionsArray="[
              {
                action: () => openDeleteDialog(item),
                text: 'Видалити',
                icon: 'mdi-delete',
              },
            ]">
          </TableCrud>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.name="{ item }">
          <ALink
            :text="item.name"
            :route="{ name: 'admin-watch-faq-video', params: { id: item.id } }">
          </ALink>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.created_at="{ item }">
          {{ formatDate(item.created_at) }}
        </template>
      </v-data-table>
    </div>
    <RouterView v-if="!faqVideoListRoute" />
  </v-card-text>
</template>
<script>
import ALink from '@/components/Link.vue'

import EmptySection from '@/components/empty-section.vue'
import EmptyFile from '@/assets/svg/empty-file.vue'
import SectionLoader from '@/components/section-loader.vue'
import { formatDate } from '@/utils/formatFunc'
import { urlFaqGetAllVideos, urlFaqDeleteVideoById } from '@/pages/request'
import TableCrud from '@/components/table-crud.vue'
export default {
  components: {
    TableCrud,
    ALink,
    EmptySection,
    // eslint-disable-next-line
    EmptyFile,
    SectionLoader,
  },
  data: () => ({
    search: null,
    pageLoad: false,
    videos: [],
    tHead: [
      {
        title: 'Назва',
        key: 'name',
        align: 'start',
        sortable: false,
        width: '70%',
      },
      { title: 'Дата', key: 'created_at', align: 'center', sortable: true },
      { title: 'Дії', key: 'actions', align: 'end', sortable: false },
    ],
    EmptyFile,
  }),
  computed: {
    faqVideoListRoute() {
      return this.$route.name === 'admin-faq-videos'
    },
  },
  methods: {
    formatDate,
    openDialog() {
      this.$store.commit('setDialog', {
        title: 'Відео',
        dialogItem: {},
        params: {
          cb: this.getVideos,
        },
        action: 'faqCreateVideo',
      })
    },
    openDeleteDialog(item) {
      this.$store.commit('setDialog', {
        title: 'Відео',
        dialogItem: item,
        params: {
          cb: this.getVideos,
          deleteVideo: this.deleteVideo,
        },
        action: 'faqDeleteVideo',
      })
    },
    getVideos() {
      this.pageLoad = true

      return this.$axios
        .get(urlFaqGetAllVideos())
        .then(res => {
          this.videos.splice(0)
          this.videos.push(...res.data)
          this.pageLoad = false
        })
        .catch(err => this.$err(err, () => (this.pageLoad = false)))
    },
    deleteVideo(id) {
      return this.$axios.delete(urlFaqDeleteVideoById(id)).then(res => {
        this.getVideos()
        return res
      })
    },
  },
  created() {
    this.getVideos()
  },
}
</script>
