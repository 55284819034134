<template>
  <div v-if="participantData" class="mt-3 chapter" style="height: 100%">
    <div class="chapter-title">
      <span>{{ label }}</span>
    </div>
    <div class="chapter-general">
      <v-row>
        <v-col cols="12" md="12" sm="12">
          <span class="label">{{ type.ru }}</span>
          <v-tooltip bottom :text="getEntityName(participantData)">
            <template #activator="{ props }">
              <div v-bind="props">
                <ALink
                  style="
                    width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  "
                  class="d-block mb-2"
                  :text="getEntityName(participantData)"
                  :route="getEntityRoute(participantData.id, typeId)" />
              </div>
            </template>
            <span>{{ getEntityName(participantData) }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <div v-if="details">
        <span class="label">{{ type.contractor ? 'ЄДРПОУ' : 'ІПН' }}</span>
        <span class="d-block mb-2" style="font-size: 14px">
          {{ participantData.inn || participantData.edrpou }}
        </span>
      </div>
      <div>
        <div v-if="single">
          <SignatoryItem
            v-if="!hideSignatory"
            :signatoriesList="signatoriesList"
            :signatory="localSignatories"
            :v$="v$"
            simpleText
            :readonly="readonly"
            @update="localSignatories = $event" />
        </div>
        <div v-else>
          <div
            v-for="(signatory, idx) in localSignatories"
            :key="signatory.contactId">
            <SignatoryItem
              v-if="!hideSignatory"
              :signatoriesList="
                signatoriesList.filter(s => {
                  return !localSignatories
                    .map(l => l.contactId)
                    .filter(l => l !== signatory.contactId)
                    .includes(s.contact.id)
                })
              "
              :signatory="localSignatories[idx]"
              :showDelete="localSignatories.length > 1"
              :readonly="readonly"
              :loading="loading"
              simpleText
              @update="event => updateSignatory(event, idx)"
              @delete="deleteSignatory" />
          </div>
          <v-btn
            v-if="
              localSignatories?.length < signatoriesList.length &&
              participantData.executiveAuthorityId === 2
            "
            :loading="loading"
            @click="addSignatory">
            Додати підписанта
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SignatoryItem from './SignatoryItem.vue'
import ALink from '@/components/Link.vue'
import { getAddress, getEntityRoute, getEntityName } from '@/utils/helperFunc'

export default {
  components: {
    SignatoryItem,
    ALink,
  },
  emits: ['update:signatoryId', 'update:basisId', 'update:signatories'],
  props: {
    label: { type: String },
    typeId: { type: Number, required: true },
    participant: { type: Object, required: true },
    signatories: { type: Array },
    signatoryId: { type: Number },
    basisId: { type: Number },
    v$: { type: Object },
    single: { type: Boolean },
    readonly: { type: Boolean },
    hideSignatory: { type: Boolean },
    details: { type: Boolean },
  },
  data() {
    return {
      loading: false,
      localSignatories: null,
    }
  },
  computed: {
    type() {
      const contractor = {
        contractor: true,
        ru: 'Контрагент',
        en: 'contractor',
      }
      const contact = {
        contact: true,
        ru: 'Контакт',
        en: 'contact',
      }
      return this.typeId === 2 ? contractor : contact
    },
    participantData() {
      return this.participant
        ? this.participant[this.type.en] || this.participant
        : null
    },
    signatoriesList() {
      const list = []

      if (this.type.contact) {
        list.push({
          contact: this.participantData,
          employeeSignatureBases: this.participantData.employeeSignatureBases,
        })
        list.push(
          ...(this.participantData.representatives || []).map(r => {
            return {
              contact: r.representative,
              employeeSignatureBases: [r.documentType],
            }
          })
        )
      }
      if (this.type.contractor) {
        list.push(...(this.participantData.employees || []))
      }

      return list
    },
  },
  methods: {
    getAddress,
    getEntityRoute,
    getEntityName,
    deleteSignatory(contactId) {
      this.loading = true
      this.localSignatories = this.localSignatories.filter(
        s => s.contactId !== contactId
      )
      this.loading = false
    },
    addSignatory() {
      this.loading = true
      this.localSignatories = [
        ...this.localSignatories,
        { contactId: null, basisId: null },
      ]
      this.loading = false
    },
    updateSignatory(signatory, index) {
      this.localSignatories = this.localSignatories.map((s, idx) =>
        idx === index ? signatory : s
      )
    },
  },
  created() {
    this.localSignatories = this.single
      ? { contactId: this.signatoryId, basisId: this.basisId }
      : Object.clone(this.signatories) || [{ contactId: null, basisId: null }]
    this.$watch(
      'localSignatories',
      val => {
        if (this.single) {
          this.$emit('update:signatoryId', val.contactId)
          this.$emit('update:basisId', val.basisId)
        } else {
          this.$emit('update:signatories', val)
        }
      },
      { deep: true }
    )
  },
}
</script>

<style lang="scss" scoped>
.label {
  display: block;
  color: #838282;
  font-size: 12px;
}
.title {
  display: block;
  color: #08487a;
  font-weight: 700;
}
</style>
