import { urlCheckServices } from '@/pages/request'

function checkService(projectId) {
  return this.$axios.get(urlCheckServices(projectId)).then(res => {
    res.data.message &&
      this.$setSnackbar({ text: res.data.message, color: 'warning' })
    return !!res.data.message
  })
}

export { checkService }
