<template>
  <div>
    <v-row class="pr-3">
      <v-col :cols="12" :sm="6">
        <LeasingParticipant
          v-model:signatoryId="agreementData.creditorSignatoryId"
          v-model:basisId="agreementData.creditorBasisId"
          label="Кредитор"
          :typeId="2"
          :participant="agreementData.creditor"
          :readonly="readonly"
          :v$="{
            contactId: $v.agreement.creditorSignatoryId,
            basisId: $v.agreement.creditorBasisId,
          }"
          single />
      </v-col>
      <v-col :cols="12" :sm="6" :class="`${mobile ? '' : 'pl-0'}`">
        <LeasingParticipant
          v-model:signatories="agreementData.guarantorSignatories"
          label="Поручитель"
          :readonly="readonly"
          :typeId="agreementData.guarantor.lesseeTypeId"
          :participant="agreementData.guarantor"
          :v$="$v.agreement.guarantorSignatories" />
      </v-col>
      <v-col style="margin-top: -10px" :cols="12" :sm="6">
        <LeasingParticipant
          v-model:signatories="agreementData.debtorSignatories"
          label="Боржник"
          :readonly="readonly"
          :typeId="agreementData.debtor.lesseeTypeId"
          :participant="agreementData.debtor"
          :v$="$v.agreement.debtorSignatories"
          :hideSignatory="!agreementData.tripartiteAgreement" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import LeasingParticipant from '@/pages/contracts/components/leasingParticipant/LeasingParticipant.vue'
export default {
  components: { LeasingParticipant },
  props: {
    agreement: { type: Object },
    v: { type: Object },
    guarantorContractor: { type: Boolean },
    guarantorContact: { type: Boolean },
    readonly: { type: Boolean },
    mobile: { type: Boolean },
  },
  computed: {
    agreementData() {
      return this.agreement
    },
    $v() {
      return this.v
    },
  },
  mounted() {},
}
</script>
