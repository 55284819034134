import { style } from './styles'

const operativeCalculationTable = obj => {
  return [
    {
      margin: [0, 35, 0, 1],
      layout: {
        hLineColor: () => '#e0e0e0',
        vLineColor: () => '#e0e0e0',
        paddingRight: () => 0,
        paddingLeft: () => 0,
      },
      table: {
        widths: ['30%', '70%'],
        body: [
          [
            {
              text: "Об'єкт та його вартiсть, з ПДВ",
              fillColor: style.colors.mainRed,
              color: 'white',
              margin: [5, 4],
            },
            {
              text: `${obj?.requestData?.leasedAssertMark?.name} ${
                obj?.requestData?.leasedAssertModel.name
              } / ${
                obj?.resultData[Object.keys(obj?.resultData)[0]][
                  'offer-price-netto'
                ]
              }`,
              alignment: 'center',
              margin: [5, 4],
            },
          ],
        ],
      },
    },
    {
      margin: [0, 1],
      layout: {
        hLineColor: () => '#e0e0e0',
        vLineColor: () => '#e0e0e0',
        paddingRight: () => 0,
        paddingLeft: () => 0,
      },
      table: {
        widths: ['30%', '70%'],
        body: [
          [
            {
              text: 'Вартість з реєстрацією, з ПДВ',
              fillColor: style.colors.mainRed,
              color: 'white',
              margin: [5, 4],
            },
            {
              text: `${
                obj?.resultData[Object.keys(obj?.resultData)[0]][
                  'offer-price-brutto'
                ]
              }`,
              alignment: 'center',
              margin: [5, 4],
            },
          ],
        ],
      },
    },
    {
      margin: [0, 1],
      layout: {
        hLineColor: () => '#e0e0e0',
        vLineColor: () => '#e0e0e0',
        paddingRight: () => 3,
        paddingLeft: () => 3,
      },
      table: {
        widths: ['30.9%', '12.1%', '23%', '20%', '14%'],
        body: [
          [
            {
              text: 'Нульовий авансовий платiж. з ПДВ',
              fillColor: style.colors.mainRed,
              color: 'white',
              margin: [5, 4],
            },
            {
              text: `${obj?.requestData?.advance} %`,
              alignment: 'center',
              margin: [5, 4],
            },
            {
              text: `${
                obj?.resultData[Object.keys(obj?.resultData)[0]][
                  'offer-advance'
                ] || 0
              }`,
              margin: [5, 4],
              alignment: 'center',
            },
            {
              text: 'Термiн лізингу',
              margin: [5, 4],
              fillColor: style.colors.mainRed,
              color: 'white',
              alignment: 'center',
            },
            {
              text: `${obj?.requestData?.leasingTerm} мiс`,
              margin: [5, 4],
              alignment: 'center',
            },
          ],
        ],
      },
    },
    {
      margin: [0, 1],
      layout: {
        hLineColor: () => '#e0e0e0',
        vLineColor: () => '#e0e0e0',
        paddingRight: () => 3,
        paddingLeft: () => 3,
      },
      table: {
        widths: ['30%', '12%', '58%'],
        body: [
          [
            {
              text: 'Разова комiсiя, з ПДВ',
              fillColor: style.colors.mainRed,
              color: 'white',
              margin: [5, 4],
            },
            {
              text: `${(
                parseFloat(
                  obj?.resultData[Object.keys(obj?.resultData)[0]][
                    'offer-administrative-payment-per'
                  ] || 0
                ) * 100
              ).toFixed(2)} %`,
              alignment: 'center',
              margin: [5, 4],
            },
            {
              text: `${
                obj?.resultData[Object.keys(obj?.resultData)[0]][
                  'offer-administrative-payment'
                ]
              }`,
              alignment: 'center',
              margin: [5, 4, 50, 4],
            },
          ],
        ],
      },
    },
    {
      margin: [0, 1],
      layout: {
        hLineColor: () => '#e0e0e0',
        vLineColor: () => '#e0e0e0',
        paddingRight: () => 3,
        paddingLeft: () => 3,
      },
      table: {
        widths: ['30.6%', '35.95%', '19.7%', '13.85%'],
        body: [
          [
            {
              text: 'Валюта фiнансування',
              fillColor: style.colors.mainRed,
              color: 'white',
              margin: [5, 11],
            },
            {
              text: `${obj?.requestData?.currency}`,
              alignment: 'center',
              margin: [5, 11],
            },
            {
              text: 'Щомiсячний платiж, з ПДВ',
              margin: [5, 4],
              alignment: 'center',
              fillColor: style.colors.mainRed,
              color: 'white',
            },
            {
              text: `${
                obj.resultData['oper-leasing']
                  ? obj?.resultData['oper-leasing']['offer-month-payment']
                  : obj?.requestData?.leasingRest
              }`,
              margin: [5, 11],
              alignment: 'center',
            },
          ],
        ],
      },
    },
  ]
}

export { operativeCalculationTable }
