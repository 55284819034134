<template>
  <div>
    <v-row class="align-center mt-1">
      <v-col cols="7" md="7" sm="12">
        <div class="d-flex align-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="20"
            viewBox="0 0 23 20"
            fill="none">
            <path
              d="M18.7 10.9L13 16.6H10.7V14.3L16.4 8.6L18.7 10.9ZM22.1 10.1C22.1 10.4 21.8 10.7 21.5 11L19 13.5L18.1 12.6L20.7 10L20.1 9.4L19.4 10.1L17.1 7.8L19.3 5.7C19.5 5.5 19.9 5.5 20.2 5.7L21.6 7.1C21.8 7.3 21.8 7.7 21.6 8C21.4 8.2 21.2 8.4 21.2 8.6C21.2 8.8 21.4 9 21.6 9.2C21.9 9.5 22.2 9.8 22.1 10.1ZM2 18V2H9V7H14V8.5L16 6.5V6L10 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.9 20 2 20H14C15.1 20 16 19.1 16 18H2ZM10 15.1C9.8 15.1 9.6 15.2 9.5 15.2L9 13H7.5L5.4 14.7L6 12H4.5L3.5 17H5L7.9 14.4L8.5 16.7H9.5L10 16.6V15.1Z"
              fill="#08487A" />
          </svg>
          <span class="card-block-title">
            Договори
            {{ entityTypeId === 1 ? 'контакта' : 'контрагента' }}
          </span>
        </div>
      </v-col>
      <v-col v-if="agreements && !loading" cols="5" md="5" sm="12">
        <v-text-field v-model="search" hide-details placeholder="Пошук" dense>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12" md="12" sm="12">
        <SectionLoader v-if="loading"></SectionLoader>
        <NoData v-if="!agreements" />
        <v-data-table
          v-if="agreements && !loading"
          :headers="entityAgreementsHeaders"
          :items="agreements"
          :search="search"
          :hide-default-footer="!agreements"
          class="pb-2 custom-table head-line"
          dense>
          <!-- eslint-disable-next-line -->
          <template #item.status="{ item }">
            <span>{{ item?.status?.name || '---' }}</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.lessee="{ item }">
            <span v-if="item?.lesseeTypeId === 1">Фізична особа</span>
            <span v-if="item?.lesseeTypeId === 2">Контрагент</span>
            <span v-if="item?.lesseeTypeId === 3">
              Фізична особа-підприємець
            </span>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.leasingObjectName="{ item }">
            <div class="d-flex align-center">
              <svg
                class="mr-1"
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none">
                <path
                  d="M2.05556 5.25L3.22222 1.3125H11.7778L12.9444 5.25M11.7778 9.625C11.4684 9.625 11.1716 9.48672 10.9528 9.24058C10.734 8.99444 10.6111 8.6606 10.6111 8.3125C10.6111 7.9644 10.734 7.63056 10.9528 7.38442C11.1716 7.13828 11.4684 7 11.7778 7C12.0872 7 12.3839 7.13828 12.6027 7.38442C12.8215 7.63056 12.9444 7.9644 12.9444 8.3125C12.9444 8.6606 12.8215 8.99444 12.6027 9.24058C12.3839 9.48672 12.0872 9.625 11.7778 9.625ZM3.22222 9.625C2.9128 9.625 2.61606 9.48672 2.39726 9.24058C2.17847 8.99444 2.05556 8.6606 2.05556 8.3125C2.05556 7.9644 2.17847 7.63056 2.39726 7.38442C2.61606 7.13828 2.9128 7 3.22222 7C3.53164 7 3.82839 7.13828 4.04718 7.38442C4.26597 7.63056 4.38889 7.9644 4.38889 8.3125C4.38889 8.6606 4.26597 8.99444 4.04718 9.24058C3.82839 9.48672 3.53164 9.625 3.22222 9.625ZM12.8822 0.875C12.7267 0.3675 12.2911 0 11.7778 0H3.22222C2.70889 0 2.27333 0.3675 2.11778 0.875L0.5 6.125V13.125C0.5 13.3571 0.581944 13.5796 0.727806 13.7437C0.873667 13.9078 1.0715 14 1.27778 14H2.05556C2.26184 14 2.45967 13.9078 2.60553 13.7437C2.75139 13.5796 2.83333 13.3571 2.83333 13.125V12.25H12.1667V13.125C12.1667 13.3571 12.2486 13.5796 12.3945 13.7437C12.5403 13.9078 12.7382 14 12.9444 14H13.7222C13.9285 14 14.1263 13.9078 14.2722 13.7437C14.4181 13.5796 14.5 13.3571 14.5 13.125V6.125L12.8822 0.875Z"
                  fill="#08487A" />
              </svg>
              <span>{{ item.leasingObjectName || '---' }}</span>
            </div>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.type="{ item }">
            <div class="d-flex align-center">
              <v-icon class="mr-1">{{ item.type?.icon }}</v-icon>
              <span v-if="item.agreementDate">
                {{ item.type?.name }} ({{
                  toFormatDate(item.agreementDate, true)
                }})
              </span>
              <span v-else>{{ item.type?.name }}</span>
            </div>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.number="{ item }">
            <ALink
              v-if="item.id"
              style="font-size: 14px !important"
              :text="item.number || '---'"
              :route="getAgreementsRoute(item.id, item?.type?.id)">
            </ALink>
            <div v-if="!item.id">
              <span class="link" @click="getOneCInfo(item.oneCId)">
                {{ `${item.number}` }}
                <span
                  style="color: #09487a; font-weight: 700; margin-bottom: -4px"
                  ><OneCIcon
                    class="ml-1"
                    fill="#09487a"
                    width="19px"
                    height="19px" /></span
              ></span>
              <v-dialog
                v-model="dialogInfo[item.oneCId]"
                width="600px"
                persistent>
                <div>
                  <div
                    style="
                      background: white;
                      position: sticky;
                      right: 0;
                      top: 0;
                      height: 40px;
                      display: flex;
                      justify-content: end;
                    ">
                    <v-btn
                      style="margin-right: 10px; margin-top: 10px"
                      size="x-small"
                      icon
                      dense
                      @click="dialogInfo[item.oneCId] = false">
                      <CloseIcon />
                    </v-btn>
                  </div>
                  <div style="background: #fff">
                    <div class="pa-2" style="background: #fff">
                      <ModalInfo :info="oneCInfo" />
                    </div>
                  </div>
                </div>
              </v-dialog>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { toFormatDate } from '@/utils/helperFunc'
import ALink from '@/components/Link.vue'
import { mapState } from 'vuex'
import NoData from '@/components/no-table-data.vue'
import {
  urlGetContactDfls,
  urlGetContractorDfls,
  urlGetOneCDflInfo,
} from '@/pages/request'
import SectionLoader from '@/components/section-loader.vue'
import CloseIcon from '@/assets/svg/close-icon.vue'
import OneCIcon from '@/assets/svg/oneCIcon.vue'
import ModalInfo from '@/components/OneC/Modal-info.vue'
import { entityAgreementsHeaders } from '@/utils/tableHeaders'
export default {
  name: 'EntityAgreements',
  components: { ModalInfo, OneCIcon, CloseIcon, SectionLoader, NoData, ALink },
  props: {
    entityData: { type: Object },
    entityTypeId: { type: Number },
  },
  data() {
    return {
      entityAgreementsHeaders,
      agreements: [],
      oneCInfo: null,
      loading: false,
      dialogInfo: {},
      search: null,
    }
  },
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    id() {
      return this.$route?.params?.id
    },
    oneCInfoText() {
      return this.formatObject(this.oneCInfo)
    },
  },
  methods: {
    toFormatDate,
    urlGetContractorDfls,
    getAgreementsRoute(id, typeId) {
      switch (typeId) {
        case 18:
          return { name: 'dfl-contract', params: { id } }
        case 19:
          return { name: 'dkp-contract', params: { id } }
        case 20:
          return { name: 'du-dfl-agreement', params: { id } }
        case 21:
          return { name: 'surety-agreement', params: { id } }
        case 22:
          return { name: 'mortgagor-agreement', params: { id } }
        default:
          return this.$setSnackbar({ text: 'Неможливо перейти в договір' })
      }
    },
    mappedAgreementsArray(contracts) {
      const allContracts = []

      contracts.forEach(item => {
        item.type = {
          ...item.type,
          name: item.oneCId ? 'ДФЛ' : item?.type?.name,
          icon: 'mdi-file-document-edit-outline',
        }
        allContracts.push(item)
      })

      return (this.agreements = [...contracts])
    },
    formatObject(obj, indent = 0) {
      if (!obj) {
        return ''
      }
      const indentString = '  '.repeat(indent)
      const lines = []

      for (const [key, value] of Object.entries(obj)) {
        if (typeof value === 'object' && value !== null) {
          const nestedObj = this.formatObject(value, indent + 1)
          lines.push(`${indentString}${key}:`)
          lines.push(nestedObj)
        } else {
          lines.push(`${indentString}${key}: ${value}`)
        }
      }

      return lines.join('\n')
    },
    getDfls() {
      const url =
        this.entityTypeId === 1 ? urlGetContactDfls : urlGetContractorDfls
      this.loading = true
      return this.$axios.get(url(this.id)).then(res => {
        this.mappedAgreementsArray(res.data || [])
        this.loading = false
      })
    },
    getOneCInfo(id) {
      const req = {
        oneCId: id,
      }
      return this.$axios.post(urlGetOneCDflInfo(), req).then(res => {
        this.oneCInfo = res.data
        this.dialogInfo = {
          ...this.dialogInfo,
          [id]: { id: id, isOpen: true },
        }
      })
    },
  },
  mounted() {
    this.getDfls()
  },
}
</script>
<style>
.text {
  white-space: pre-wrap;
}
.menu {
  max-height: 85vh;
  overflow: scroll;
}
</style>
