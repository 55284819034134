<template>
  <div>
    <component
      :is="guarantor.guarantorTypeId === 2 ? 'ContractorCard' : 'ContactCard'"
      v-if="guarantor"
      :contact="guarantor.contact"
      :contractor="guarantor.contractor"
      :showEmployees="false"
      :isNew="FDAnalyze.status?.id === 1"
      :showAuthorizedCapitalFull="false"
      finDocs
      legalDocs>
      <template #main-info>
        <FilesUploader
          label="Заявка анкета поручителя"
          :documents="guarantor.guarantorApplicationForm"
          readonly />
      </template>
    </component>
  </div>
</template>

<script>
import ContactCard from '@/components/ContactCard.vue'
import ContractorCard from '@/components/ContractorCard.vue'
import FilesUploader from '@/components/FilesUploader.vue'
export default {
  components: {
    ContactCard,
    ContractorCard,
    FilesUploader,
  },
  props: {
    guarantors: { type: Array },
    loading: { type: Boolean },
    FDAnalyze: { type: Object },
  },
  computed: {
    guarantor() {
      return this.guarantors[this.$route.params.idx - 1]
    },
  },
}
</script>

<style scoped></style>
