<template>
  <v-fade-transition hide-on-leave>
    <div class="section-loader" :style="`height: ${height}`">
      <v-progress-circular
        indeterminate
        :size="size"
        :width="width"
        :color="color">
      </v-progress-circular>
    </div>
  </v-fade-transition>
</template>
<script>
export default {
  props: {
    size: { type: Number, default: 27 },
    width: { type: Number, default: 2 },
    color: { type: String, default: 'red lighten-2' },
    height: { type: String, default: '420px' },
  },
}
</script>
<style scoped lang="scss">
.section-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
