<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none">
    <path
      d="M12.0002 2.3999V7.7999C12.0002 8.27729 12.1898 8.73513 12.5274 9.07269C12.865 9.41026 13.3228 9.5999 13.8002 9.5999H19.2002V19.7999C19.2002 20.2773 19.0106 20.7351 18.673 21.0727C18.3354 21.4103 17.8776 21.5999 17.4002 21.5999H11.6918C12.588 20.5146 13.1138 19.1711 13.1924 17.7658C13.271 16.3604 12.8982 14.9668 12.1285 13.7883C11.3588 12.6098 10.2325 11.7083 8.91411 11.2154C7.59571 10.7224 6.15423 10.6639 4.8002 11.0483V4.1999C4.8002 3.72251 4.98984 3.26468 5.3274 2.92711C5.66497 2.58954 6.12281 2.3999 6.6002 2.3999H12.0002ZM13.2002 2.6999V7.7999C13.2002 7.95903 13.2634 8.11164 13.3759 8.22417C13.4885 8.33669 13.6411 8.3999 13.8002 8.3999H18.9002L13.2002 2.6999ZM12.0002 17.3999C12.0002 18.8321 11.4313 20.2056 10.4186 21.2183C9.40588 22.231 8.03236 22.7999 6.6002 22.7999C5.16803 22.7999 3.79452 22.231 2.78182 21.2183C1.76912 20.2056 1.2002 18.8321 1.2002 17.3999C1.2002 15.9677 1.76912 14.5942 2.78182 13.5815C3.79452 12.5688 5.16803 11.9999 6.6002 11.9999C8.03236 11.9999 9.40588 12.5688 10.4186 13.5815C11.4313 14.5942 12.0002 15.9677 12.0002 17.3999ZM7.2002 14.9999C7.2002 14.8408 7.13698 14.6882 7.02446 14.5756C6.91194 14.4631 6.75933 14.3999 6.6002 14.3999C6.44107 14.3999 6.28845 14.4631 6.17593 14.5756C6.06341 14.6882 6.0002 14.8408 6.0002 14.9999V16.7999H4.2002C4.04107 16.7999 3.88845 16.8631 3.77593 16.9756C3.66341 17.0882 3.6002 17.2408 3.6002 17.3999C3.6002 17.559 3.66341 17.7116 3.77593 17.8242C3.88845 17.9367 4.04107 17.9999 4.2002 17.9999H6.0002V19.7999C6.0002 19.959 6.06341 20.1116 6.17593 20.2242C6.28845 20.3367 6.44107 20.3999 6.6002 20.3999C6.75933 20.3999 6.91194 20.3367 7.02446 20.2242C7.13698 20.1116 7.2002 19.959 7.2002 19.7999V17.9999H9.0002C9.15933 17.9999 9.31194 17.9367 9.42446 17.8242C9.53698 17.7116 9.6002 17.559 9.6002 17.3999C9.6002 17.2408 9.53698 17.0882 9.42446 16.9756C9.31194 16.8631 9.15933 16.7999 9.0002 16.7999H7.2002V14.9999Z"
      fill="#FC7247" />
  </svg>
</template>

<script>
export default {
  name: 'AgreementIcon',
}
</script>

<style scoped></style>
