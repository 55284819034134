/**
 * "Why we best" - section. (Static)
 *
 */

import { style } from './styles'

const aboutUs = () => [
  {
    margin: [0, 20, 0, 20],
    text: 'ЧОМУ МИ БЕСТ?',
    fontSize: 15,
    bold: true,
  },
  {
    margin: [0, 10, 0, 5],
    columns: [
      {
        image: 'timeManagment',
        fit: [37, 37],
        alignment: 'center',
        width: '25%',
        margin: [10, 0],
      },
      {
        image: 'financialStatement',
        fit: [37, 37],
        alignment: 'center',
        width: '25%',
        margin: [10, 0],
      },
      {
        image: 'financialBurden',
        fit: [37, 37],
        alignment: 'center',
        width: '25%',
        margin: [10, 0],
      },
      {
        image: 'settingsIcon',
        fit: [37, 37],
        alignment: 'center',
        width: '25%',
        margin: [30, 0, 0, 0],
      },
    ],
    columnGap: 10,
  },
  {
    columns: [
      {
        width: '25%',
        text: 'ЦІНУЄМО ВАШ ЧАС',
        color: '#d7514f',
        alignment: 'center',
        fontSize: 11,
        bold: true,
        margin: [10, 0, 0, 5],
      },
      {
        width: '25%',
        text: 'МІНІМУМ ДОКУМЕНТІВ',
        color: '#d7514f',
        alignment: 'center',
        fontSize: 11,
        bold: true,
        margin: [-10, 0, 0, 5],
      },
      {
        width: '25%',
        text: 'РОЗУМIЄМО ФIНАНСОВЕ НАВАНТАЖЕННЯ',
        color: '#d7514f',
        alignment: 'center',
        fontSize: 11,
        bold: true,
        margin: [-10, 0, 0, 5],
      },
      {
        width: '25%',
        text: 'УНIКАЛЬНА СЕРВIСНА ПIДТРИМКА',
        color: '#d7514f',
        alignment: 'center',
        fontSize: 11,
        bold: true,
        margin: [10, 0, 0, 5],
      },
    ],
    columnGap: 20,
  },
  {
    margin: [0, 0, 0, 0],
    fontSize: 10,
    columns: [
      {
        width: '25%',
        text: 'та приймаємо рішення швидко – за 30 хв.',
        alignment: 'center',
      },
      {
        width: '20%',
        text: 'для оформлення – не більше 8',
        alignment: 'center',
      },
      {
        width: '30%',
        text: 'на ваш бюджет, – сплачуйте при оформленні лише аванс',
        alignment: 'center',
      },
      {
        width: '25%',
        text: 'якicне та швидке обслуговування, знижки',
        alignment: 'center',
      },
    ],
    color: style.colors.darkGrey,
    columnGap: 20,
  },
]

const fsnAboutUs = () => [
  {
    margin: [0, 45, 0, 5],
    lineHeight: 1.5,
    fontSize: 12,
    text: `Компанія BEST Leasing – учасник Держпрограми «Доступний фінансовий лізинг – 5-7-9%», запровадженої за ініціативи Президента та Уряду України. Завдяки Держпрограмі та зручним умовам лізингу ви розвиваєте бізнес та заощаджуєте кошти.
      З нами легко, вигідно та швидко!`,
  },
  {
    margin: [0, 20, 0, 5],
    text: 'ПЕРЕВАГИ ЛІЗИНГУ ДЛЯ ВАС: ',
    fontSize: 14,
    bold: true,
  },
  {
    margin: [0, 10, 0, 10],
    columns: [
      {
        image: 'financialBurden',
        fit: [25, 25],
        width: '35%',
        margin: [10, 0],
      },
      {
        image: 'equalFinancialBurden',
        fit: [25, 25],
        width: '35%',
        margin: [10, 0],
      },
      {
        image: 'timeManagment',
        fit: [25, 25],
        width: '30%',
        margin: [10, 0],
      },
    ],
    columnGap: 10,
  },
  {
    margin: [0, 0, 0, 0],
    columns: [
      {
        width: '35%',
        text: `ЕКОНОМІЯ
          ГРОШЕЙ`,
        lineHeight: 1.2,
        color: '#d7514f',
        fontSize: 14,
      },
      {
        width: '35%',
        text: `МІНІМУМ
          НАВАНТАЖЕННЯ`,
        lineHeight: 1.2,
        color: '#d7514f',
        fontSize: 14,
      },
      {
        width: '30%',
        text: `ШВИДКЕ
          ОФОРМЛЕННЯ`,
        lineHeight: 1.2,
        color: '#d7514f',
        fontSize: 14,
      },
    ],
    columnGap: 20,
  },
  {
    margin: [0, 4, 0, 25],
    fontSize: 12,
    columns: [
      {
        width: '35%',
        text: 'Отримуєте низькі відсотковіставки - 5%, 7% або 9% річних у гривні.',
        lineHeight: 1.2,
      },
      {
        width: '35%',
        text: 'Реєстрація та страхування розподілені на щомісячні платежі.',
        lineHeight: 1.2,
      },
      {
        width: '30%',
        text: 'Ми цінуємо ваш час і рішення щодо лізингу готуємо за 30 хв.',
        lineHeight: 1.2,
      },
    ],
    color: style.colors.darkGrey,
    columnGap: 20,
  },
]

export { aboutUs, fsnAboutUs }
