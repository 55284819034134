<template>
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	:width="width" :height="height" :fill="color" viewBox="0 0 46.259 46.259" style="enable-background:new 0 0 46.259 46.259;"
	xml:space="preserve">
<g>
	<g>
		<path d="M35.043,13.514c-3.405,0-6.164-2.749-6.164-6.153V0.797c0-0.44-0.356-0.797-0.797-0.797H8.278
			c-2.54,0-4.607,2.06-4.607,4.6v37.059c0,2.54,2.067,4.6,4.607,4.6h29.7c2.541,0,4.609-2.06,4.609-4.6V14.311
			c0-0.44-0.355-0.797-0.797-0.797H35.043z"/>
		<path d="M35.043,10.395h5.519c0.321,0,0.612-0.194,0.735-0.493c0.123-0.298,0.054-0.641-0.175-0.868
			c-2.016-2.004-5.762-5.724-7.77-7.707c-0.229-0.226-0.57-0.292-0.867-0.168c-0.295,0.123-0.488,0.413-0.488,0.734v5.468
			C31.998,9.026,33.377,10.395,35.043,10.395z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      default: 30
    },
    width: {
      type: Number,
      default: 30
    },
    color: {
      type: String,
      default: 'white'
    }
  }
}
</script>