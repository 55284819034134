<template>
  <div>
    <a ref="fakeLink" class="fake-link" href="#" target="_blank"></a>
    <div class="d-flex align-center">
      <v-text-field
        id="site"
        v-model="internalValue"
        :placeholder="`${placeholder}`"
        :class="{ link: !editState }"
        dense
        maxLength="250"
        :hideDetails="hideDetails"
        :readonly="!editState || readonly"
        @click="setLink">
      </v-text-field>
      <v-btn
        v-show="isValidUrl(internalValue) && editState"
        :disabled="readonly"
        icon
        :width="25"
        :height="25"
        @click.stop="editState = !editState">
        <v-icon :size="18" color="#09487a">
          {{ 'mdi-check' }}
        </v-icon>
      </v-btn>
      <v-btn
        v-show="internalValue && !editState"
        :disabled="readonly"
        icon
        :width="25"
        :height="25"
        @click.stop="editState = !editState">
        <v-icon :size="18" color="#09487a">
          {{ 'mdi-pencil' }}
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { isValidUrl } from '@/utils/helperFunc'
export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: { type: String },
    hideDetails: { type: Boolean, default: true },
    placeholder: { type: String, default: 'Вставте посилання' },
    readonly: { type: Boolean },
  },
  data() {
    return {
      editState: true,
      internalValue: this.modelValue,
    }
  },
  methods: {
    isValidUrl,
    setLink(e) {
      if (this.editState || e.target.closest('v-btn')) return

      const link = e?.target?.value
      this.$refs.fakeLink.href = link
      this.$refs.fakeLink.click()
    },
  },
  watch: {
    modelValue(newValue) {
      this.internalValue = newValue
    },
    internalValue(newValue) {
      this.$emit('update:modelValue', newValue)
    },
  },
  mounted() {
    if (this.isValidUrl(this.modelValue)) {
      this.editState = false
    }
  },
}
</script>

<style>
.site-label {
  line-height: 0.4rem;
  font-size: 0.58rem;
  padding-top: 5px;
}

.fake-link {
  display: none;
}
</style>
