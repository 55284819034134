<template>
  <TaskPlan
    :readonly="!!lead.responsibleId"
    :tasks="lead.task_schedulers"
    :leadId="lead.id"
    @update="$emit('updateLead')" />
</template>

<script>
import TaskPlan from '@/components/TaskPlan.vue'
export default {
  emits: ['updateLead'],
  components: { TaskPlan },
  props: {
    lead: { type: Object },
  },
}
</script>

<style lang="scss" scoped></style>
