<template>
  <div 
    ref="open-layer-container" 
    :style="`width: ${width}; height: ${isValidPosition ? height : 0}px`">
  </div>
</template>
<script>
import View from 'ol/View'
import Map from 'ol/Map'
import TileLayer from 'ol/layer/Tile'
import OSM from 'ol/source/OSM'
import { Style, Icon } from 'ol/style'
import { Vector } from 'ol/layer'
import { Point } from 'ol/geom'
import { Vector as SourceVector} from 'ol/source'
import { Feature } from 'ol'
import { transform } from 'ol/proj'
import 'ol/ol.css'

export default {
  props: {
    height: {
      type: Number,
      default: 500,
    },
    width: {
      type: String,
      default: 'auto',
    },
    zoom: {
      type: Number,
      default: 16,
    },
    markerUrl: {
      type: String,
      default: 'http://cdn.mapmarker.io/api/v1/pin?text=P&size=50&hoffset=1',
    },
    markerAnchorSize: { 
      type: Array,
      default: () => [0.5, 46],
    },
    position: {
      type: Object,
      default: () => ({
        longitude: null,
        latitude: null,
      })
    },
  },
  methods: {
    setPosition() {
      if (!this.position.longitude || !this.position.latitude) {
        return
      }

      this.$refs['open-layer-container'].innerHTML = ''
      const position = transform([
        this.position.longitude, 
        this.position.latitude
        ], 'EPSG:4326', 'EPSG:3857')

      new Map({
        target: this.$refs['open-layer-container'],
        layers: [
          new TileLayer({ source: new OSM() }),
          new Vector({
            source: new SourceVector({ 
              features: [ new Feature({ geometry: new Point(position) }) ] 
            }),
            style: new Style({
              image: new Icon({
                anchor: this.markerAnchorSize,
                anchorXUnits: 'fraction',
                anchorYUnits: 'pixels',
                src: this.markerUrl,
              })
            })
          })
        ],
        view: new View({
          zoom: this.zoom,
          center: position,
          constrainResolution: true
        }),
      })
    }
  },
  computed: {
    isValidPosition() {
      return !!(this.position?.longitude && this.position?.latitude)
    },
  },
  mounted() {
    this.setPosition()
  },
  watch: {
    "position.longitude": function() {
      this.$nextTick(this.setPosition)
    },
    "position.latitude": function() {
      this.$nextTick(this.setPosition)
    },
  },
}
</script>