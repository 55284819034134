<template>
  <div class="add-btn" style="cursor: pointer">
    <v-icon class="add-icon" color="red">
      {{ 'mdi-plus' }}
    </v-icon>
    <span class="link">{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    text: { type: String, default: 'Додати' },
  },
}
</script>

<style lang="scss" scoped>
.add-icon {
  transition: 0.3s;
}
.add-btn:hover .add-icon {
  transform: rotate(90deg);
}
</style>
