<template>
  <div>
    <v-textarea :model-value="dialog.dialogItem.conclusion" readonly />
  </div>
</template>

<script>
export default {
  props: { dialog: { type: Object } },
}
</script>

<style scoped></style>
