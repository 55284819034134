<template>
  <svg id="Capa_1" fill="white" style="vertical-align: text-bottom" enable-background="new 0 0 512 512" height="27" viewBox="0 0 512 512" width="27" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="m257.756 273.413c75.381 0 136.707-61.326 136.707-136.707 0-75.38-61.326-136.706-136.707-136.706s-136.707 61.326-136.707 136.706c0 75.381 61.327 136.707 136.707 136.707zm0-258.384c67.093 0 121.677 54.584 121.677 121.677s-54.584 121.678-121.677 121.678-121.678-54.584-121.678-121.678c0-67.092 54.585-121.677 121.678-121.677z"/>
      <path d="m257.755 235.574c54.516 0 98.868-44.352 98.868-98.867s-44.352-98.867-98.868-98.867c-24.609 0-48.193 9.099-66.407 25.621-3.074 2.789-3.305 7.541-.517 10.615 2.789 3.074 7.542 3.307 10.615.517 15.443-14.009 35.441-21.724 56.309-21.724 46.229 0 83.839 37.61 83.839 83.838s-37.61 83.838-83.839 83.838c-46.228 0-83.837-37.609-83.837-83.838 0-13.96 3.497-27.777 10.114-39.957 1.981-3.647.631-8.209-3.016-10.19-3.646-1.982-8.208-.63-10.19 3.016-7.809 14.375-11.937 30.673-11.937 47.131 0 54.515 44.351 98.867 98.866 98.867z"/>
      <path d="m254.898 172.111c-11.802-.719-15.044-7.822-15.645-9.491-.86-3.212-3.782-5.58-7.265-5.58-4.158 0-7.529 3.371-7.529 7.53 0 .604.079 1.188.213 1.751l-.003.001c.007.027.021.079.04.144.092.355.201.703.342 1.036 1.39 3.834 6.959 15.729 23.371 18.907v9.999c0 4.15 3.365 7.515 7.515 7.515 4.151 0 7.515-3.364 7.515-7.515v-9.773c7.193-1.252 12.025-4.396 15.046-7.239 5.621-5.29 8.977-13.286 8.977-21.39 0-7.615-2.986-25.655-30.649-28.707-5.48-.605-9.389-2.349-11.62-5.183-1.969-2.503-2.182-5.268-2.182-6.341 0-1.322.363-12.913 12.913-12.913 8.234 0 11.559 5.237 12.184 6.376 1.114 2.766 3.818 4.72 6.982 4.72 4.158 0 7.529-3.371 7.529-7.529 0-1.236-.304-2.4-.832-3.429l.004-.002c-2.118-4.604-8.099-11.807-18.353-14.287v-10.087c0-4.15-3.364-7.515-7.515-7.515-4.15 0-7.515 3.365-7.515 7.515v10.055c-14.832 3.522-20.428 17.361-20.428 27.095 0 10.823 7.119 24.248 27.183 26.462 17.268 1.906 17.268 10.835 17.268 13.769 0 3.117-1.115 7.498-4.247 10.445-2.961 2.787-7.433 4.019-13.299 3.661z"/>
      <path d="m130.238 347.994c-1.42-1.51-3.401-2.366-5.474-2.366s-4.053.856-5.474 2.366l-51.047 54.268c-2.054 2.184-2.613 5.379-1.424 8.13 1.189 2.752 3.9 4.533 6.898 4.533h14.754v27.289c0 4.15 3.365 7.515 7.515 7.515s7.515-3.365 7.515-7.515v-34.804c0-4.15-3.365-7.515-7.515-7.515h-4.884l33.661-35.786 33.661 35.786h-4.884c-4.15 0-7.515 3.365-7.515 7.515v89.56h-42.526v-24.697c0-4.15-3.365-7.515-7.515-7.515s-7.515 3.365-7.515 7.515v32.212c0 4.15 3.365 7.515 7.515 7.515h57.555c4.15 0 7.515-3.365 7.515-7.515v-89.56h14.754c2.997 0 5.708-1.781 6.898-4.533 1.189-2.751.63-5.947-1.424-8.13z"/>
      <path d="m263.229 296.294c-1.42-1.51-3.401-2.366-5.474-2.366s-4.053.856-5.474 2.366l-51.046 54.269c-2.054 2.184-2.614 5.379-1.424 8.13 1.189 2.752 3.9 4.533 6.898 4.533h14.754v141.259c0 4.15 3.365 7.515 7.515 7.515h57.555c4.151 0 7.515-3.365 7.515-7.515v-141.259h14.754c2.998 0 5.708-1.781 6.898-4.533 1.189-2.751.629-5.947-1.425-8.13zm23.304 51.903c-4.151 0-7.515 3.365-7.515 7.515v141.259h-42.526v-141.26c0-4.15-3.365-7.515-7.515-7.515h-4.884l33.661-35.786 33.661 35.786h-4.882z"/>
      <path d="m396.221 248.46c-1.42-1.51-3.401-2.366-5.474-2.366-2.072 0-4.054.856-5.474 2.366l-51.047 54.268c-2.054 2.183-2.614 5.379-1.425 8.13 1.19 2.752 3.9 4.533 6.898 4.533h14.754v45.859c0 4.15 3.364 7.515 7.515 7.515s7.515-3.365 7.515-7.515v-53.373c0-4.15-3.364-7.515-7.515-7.515h-4.883l33.661-35.786 33.66 35.786h-4.883c-4.151 0-7.515 3.365-7.515 7.515v189.094h-42.526v-105.662c0-4.15-3.364-7.515-7.515-7.515s-7.515 3.364-7.515 7.515v113.177c0 4.15 3.364 7.515 7.515 7.515h57.556c4.151 0 7.515-3.365 7.515-7.515v-189.095h14.754c2.998 0 5.708-1.781 6.898-4.533 1.189-2.751.629-5.947-1.425-8.13z"/>
      <path d="m92.805 139.396c0-4.15-3.365-7.515-7.515-7.515h-44.075c-4.15 0-7.515 3.365-7.515 7.515s3.364 7.515 7.515 7.515h44.075c4.151-.001 7.515-3.365 7.515-7.515z"/>
      <path d="m95.012 198.37-15.676 6.141c-3.865 1.514-5.77 5.874-4.256 9.738 1.161 2.964 3.997 4.775 6.999 4.775.912 0 1.839-.167 2.739-.52l15.676-6.141c3.865-1.514 5.77-5.874 4.256-9.738-1.515-3.863-5.875-5.769-9.738-4.255z"/>
      <path d="m79.336 74.279 15.676 6.141c.901.353 1.827.52 2.739.52 3.002 0 5.838-1.812 6.999-4.775 1.514-3.864-.391-8.224-4.256-9.738l-15.676-6.141c-3.866-1.514-8.224.392-9.738 4.256-1.514 3.863.392 8.223 4.256 9.737z"/>
      <path d="m470.785 131.881h-44.075c-4.151 0-7.515 3.365-7.515 7.515s3.364 7.515 7.515 7.515h44.075c4.151 0 7.515-3.365 7.515-7.515-.001-4.15-3.365-7.515-7.515-7.515z"/>
      <path d="m411.506 212.365 15.675 6.141c.901.353 1.828.52 2.74.52 3.002 0 5.838-1.812 6.999-4.775 1.514-3.864-.391-8.224-4.255-9.738l-15.675-6.141c-3.868-1.514-8.224.392-9.739 4.256-1.514 3.862.391 8.222 4.255 9.737z"/>
      <path d="m414.25 80.94c.912 0 1.84-.167 2.74-.52l15.675-6.141c3.864-1.514 5.769-5.874 4.255-9.738-1.515-3.865-5.876-5.768-9.739-4.256l-15.675 6.141c-3.864 1.514-5.769 5.874-4.255 9.738 1.161 2.965 3.997 4.776 6.999 4.776z"/>
    </g>
  </svg>
</template>