<template>
  <div>
    <list-section
      :title="'Шаблони'"
      :addActionTitle="'Додати шаблон'"
      :addAction="() => openDialog('addCustomTemplates')"
      :elevation="0">
      <template #body>
        <v-data-table
          :headers="tHeadTemplates"
          :items="templates"
          hide-default-footer
          :hide-default-header="!templates.length"
          class="pb-2 pt-1 custom-table head-line"
          dense>
          <!-- eslint-disable-next-line -->
          <template #no-data>
            <no-data></no-data>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.actions="{ item }">
            <TableCrud
              :actionsArray="[
                {
                  action: () => openDialog('viewCustomTemplates', item),
                  text: 'Переглянути',
                  icon: 'mdi-magnify-plus',
                },
                {
                  action: () => openDialog('editCustomTemplates', item),
                  text: 'Редагувати',
                  icon: 'mdi-pencil',
                },
                {
                  action: () => openDialog('deleteCustomTemplates', item),
                  text: 'Видалити',
                  icon: 'mdi-delete',
                },
              ]">
            </TableCrud>
          </template>
        </v-data-table>
      </template>
    </list-section>
  </div>
</template>
<script>
import listSection from '@/components/list-section.vue'
import noData from '@/components/no-table-data.vue'
import { urlGetClientOfferTemplates } from '@/pages/request.js'
import { mapState } from 'vuex'

import TableCrud from '@/components/table-crud.vue'
export default {
  components: {
    TableCrud,
    listSection,
    noData,
  },
  data: () => ({
    templateDialog: false,
    dialogItem: null,
    dialogState: null,
    templates: [],
    tHeadTemplates: [
      { title: 'Назва шаблону', key: 'name', sortable: false },
      {
        title: 'Дії',
        key: 'actions',
        sortable: false,
        width: '10%',
        align: 'end',
      },
    ],
  }),
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    userId() {
      return this.user?.id
    },
  },
  methods: {
    urlGetClientOfferTemplates,
    getTemplates() {
      return this.$axios
        .get(this.urlGetClientOfferTemplates(this.userId))
        .then(res => {
          this.templates.splice(0)
          this.templates.push(...res.data)
          return res
        })
        .catch(this.$err)
    },
    openDialog(action, item) {
      this.$store.commit('setDialog', {
        title: 'Шаблон',
        action: action,
        dialogItem: {
          item: item,
        },
        params: {
          dialogWidth: 700,
          cb: res => {
            if (res) {
              window.tinymce.remove()
              this.$forceUpdate()
              this.$store.commit('refreshPage')
            }
          },
        },
      })
    },
  },
  watch: {
    userId(userId) {
      this.getTemplates(userId)
    },
  },
  mounted() {
    this.userId && this.getTemplates(this.userId)
  },
}
</script>
