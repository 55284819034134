<template>
  <div>
    <AnalyzeService
      :analyzeData="DocData"
      :securityResult="securityResults"
      :legalResult="legalResults"
      @downloadPrintedForm="service => downloadPrintedForm(service)">
      <template #finDocuments>
        <FilesUploader
          label="Санційні документи клієнта"
          readonly
          :documents="DocData.project.financialMonitoringResult.clientSanctionDocument" />
        <FilesUploader
          label="Санційні документи постачальника"
          readonly
          :documents="
            DocData.project.financialMonitoringResult.providerSanctionDocument
          " />
      </template>
      <template #riskDocuments>
        <FilesUploader
          label="Параметри угоди"
          readonly
          :documents="DocData.project.riskResult.agreementParameters" />
        <FilesUploader
          label="Рейтинг"
          readonly
          :documents="DocData.project.riskResult.nbuRanking" />
        <FilesUploader
          label="Аналіз клієнта"
          readonly
          :documents="DocData.project.riskResult.templateClientAnalysis" />
        <FilesUploader
          label="Заявка анкета"
          readonly
          :documents="DocData.project.riskResult.applicationForm" />
        <FilesUploader
          label="Опитувальник"
          readonly
          :documents="DocData.project.riskResult.questionnaire" />
      </template>
      <template #klpDocuments>
        <div>
          <FilesUploader
            v-if="DocData.project.klp.signedKlp"
            label="Протокол КЛП(підписаний)"
            readonly
            :documents="DocData.project.klp.signedKlp" />
          <span v-else>"Протокол КЛП(підписаний) - відсутній </span>
        </div>
      </template>
    </AnalyzeService>
  </div>
</template>

<script>
import {
  getContactInitials,
  getSafe,
  openDocument,
  toFormatDate,
} from '@/utils/helperFunc'
import FilesUploader from '@/components/FilesUploader.vue'
import AnalyzeService from '@/components/AnalyzeService.vue'
import {
  urlCreateLegalDepartmentPrintedForm as legalPrintedForm,
  urlSecurityServicePrintedForm as securityPrintedForm,
} from '@/pages/request'

export default {
  components: { AnalyzeService, FilesUploader },
  props: {
    DocData: { type: Object },
  },
  computed: {
    securityResults() {
      const results = []
      const s = this.DocData.project.securityResult
      if (!s) return results

      results.push({
        name: `${s.client.name} (Клієнт)`,
        finishDate: s.client.finishDate,
        responsible: s.responsible,
        result: s.client.result?.name || '---',
        status: s.status,
      })
      if (s.guarantors) {
        s.guarantors.forEach(g => {
          results.push({
            name: `${g.name} (Поручитель)`,
            finishDate: g.finishDate,
            responsible: s.responsible,
            result: g?.result?.name || '---',
            status: s.status,
          })
        })
      }
      if (s.providers) {
        s.providers.forEach(p => {
          results.push({
            name: `${p.name} (Постачальник)`,
            finishDate: p.finishDate,
            responsible: s.responsible,
            result: p?.result?.name || '---',
            status: s.status,
          })
        })
      }

      return results
    },
    legalResults() {
      const results = []
      const l = this.DocData.project.legalResult
      if (!l) return results

      results.push({
        name: `${l.client.name} (Клієнт)`,
        finishDate: l.client?.finishDate,
        responsible: l.responsible,
        conclusion: l.generalConclusion,
        status: l.status,
      })

      if (l.guarantors) {
        l.guarantors
          .filter(g => g.guarantorTypeId === 2)
          .forEach(g => {
            results.push({
              name: `${g.name} (Поручитель)`,
              finishDate: g.finishDate,
              responsible: l.responsible,
              conclusion: g.guarantorConclusion,
              status: l.status,
            })
          })
      }
      if (l.providers) {
        l.providers.forEach(p => {
          results.push({
            name: `${p.name} (Постачальник)`,
            finishDate: p.finishDate,
            responsible: l.responsible,
            conclusion: p.providerConclusion,
            status: l.status,
          })
        })
      }

      return results
    },
  },
  methods: {
    toFormatDate,
    getSafe,
    getContactInitials,
    openDocument,
    securityPrintedForm, // used in urlPrintedForm
    legalPrintedForm, // used in urlPrintedForm
    async downloadPrintedForm(service, baseUrl) {
      const serviceId =
        service === 'security'
          ? this.DocData?.project?.securityResult?.id
          : this.DocData?.project?.legalResult?.id
      const urlPrintedForm = this[`${service}PrintedForm`]
      const { data } = await this.$axios.get(urlPrintedForm(serviceId))
      const url = data.url
      if (!url) return

      const link = document.createElement('a')
      const fileName = url.split('/').pop()
      if (baseUrl) {
        link.href = `${baseUrl}${url}`
      } else link.href = `${url}`
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
      window.URL.revokeObjectURL(link.href)
    },
  },
}
</script>

<style scoped></style>
