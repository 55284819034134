import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from '@/app.vue'
import store from '@/store/index'
import router from '@/router/router'
import axios from '@/plugins/axios'
import DirectoryPlugin from '@/plugins/directory/index.ts'
import catchError from '@/utils/catchHandler'
// import * as VueGoogleMaps from 'vue2-google-maps'
import { documentState } from '@/utils/debug'
import { sortByDate, formatDate } from '@/utils/formatFunc'
import VueApexCharts from 'vue3-apexcharts'
import vueRecaptcha from 'vue3-recaptcha2'

import {
  getSafe,
  handleError,
  toNumber,
  toBoolean,
  toString,
  toObject,
  toArray,
} from './utils/helperFunc'
import * as Sentry from '@sentry/vue'
import 'vue-router/dist/vue-router'
import vuetify from './plugins/vuetify/index'

import { vMaska } from 'maska'

import './assets/css/main.scss'
import BestModules from 'best-modules'
import DatePicker from '@/components/DatePicker.vue'

const pinia = createPinia()
const app = createApp(App)

app.directive('mask', vMaska)

app.component('DatePicker', DatePicker)

documentState()

app.config.globalProperties.$baseURL = new URL(location.href).origin
app.config.globalProperties.$catchErr = catchError
app.config.globalProperties.$sortByDate = sortByDate
app.config.globalProperties.$formatDate = formatDate
app.config.globalProperties.$log = msg => console.log(msg)
app.config.globalProperties.$getSafe = getSafe

app.config.globalProperties.$object = toObject
app.config.globalProperties.$string = toString
app.config.globalProperties.$array = toArray
app.config.globalProperties.$number = toNumber
app.config.globalProperties.$boolean = toBoolean

app.config.globalProperties.$err = handleError
app.config.globalProperties.$axios = axios

Object.clone = function (obj) {
  return obj ? JSON.parse(JSON.stringify(obj)) : obj
}
String.prototype.capitalize = function (params = {}) {
  const { hard = false } = params
  const endString = hard ? this.substring(1).toLowerCase() : this.substring(1)
  return `${this[0].toUpperCase()}${endString}`
}

Array.prototype.ucFirst = function (str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

console.log('npm package version', import.meta.env.PACKAGE_VERSION)

Sentry.init({
  app,
  release: `erm@${import.meta.env.PACKAGE_VERSION}`,
  enabled: true,
  dsn: 'https://00d5fe341aab99fd65730d198dda959a@o4507386165919744.ingest.de.sentry.io/4507842335866960',
  maxBreadcrumbs: 20,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.debugIntegration(),
  ],
  initialScope: scope => {
    scope.setUser(store.state.user)
    return scope
  },

  // transactions
  tracesSampleRate: 0,

  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  async beforeSend(event, hint) {
    let originalException

    try {
      if (hint.originalException instanceof Promise) {
        originalException = await hint.originalException
      }
      if (
        typeof hint.originalException === 'object' &&
        hint.originalException !== null
      ) {
        originalException = hint.originalException
      }
    } catch (e) {
      originalException = e
    }

    const isServerError =
      typeof originalException === 'object' && !!originalException.isAxiosError
    // && originalException.response.status !== 422
    const isHandledError = originalException.throwError === false

    console.groupCollapsed(
      `sentry - before send, isSend - ${!isServerError && !isHandledError}`
    )
    console.log('event', event)
    console.log('hint', hint)
    console.log('originalException', originalException)
    console.log('isServerError', isServerError)
    console.log('isHandledError', isHandledError)
    console.groupEnd()

    if (location.origin.includes('localhost')) {
      return null
    }
    if (isServerError || isHandledError) {
      return null
    } else {
      return event
    }
  },
})

app.use(router)
app.use(store)
app.use(pinia)
app.use(vueRecaptcha)
app.use(VueApexCharts)
app.use(vuetify)
app.use(BestModules, {
  axios,
  urls: {
    urlUploadFiles: () => '/json/document/uploadFiles',
  },
})
app.use(DirectoryPlugin)

app.mount('#app')
