<template>
  <div>
    <v-row class="pr-3">
      <v-col :cols="12" :md="6">
        <LeasingParticipant
          v-model:signatoryId="contract.signatoryId"
          v-model:basisId="contract.dkpBuyerBasisId"
          label="Покупець"
          :typeId="2"
          :participant="contract.buyer"
          :v$="{
            contactId: v$.signatoryId,
            basisId: v$.dkpBuyerBasisId,
          }"
          details
          :readonly="readonly"
          single />
      </v-col>

      <v-col :cols="12" :md="6" :class="`${mobile ? '' : 'pl-0'}`">
        <LeasingParticipant
          v-model:signatories="contract.providerSignatories"
          label="Постачальник"
          :typeId="contract.providerTypeId"
          :participant="contract.provider"
          :readonly="readonly"
          details
          :v$="v$.providerSignatories" />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import LeasingParticipant from '../../components/leasingParticipant/LeasingParticipant.vue'
import { computed, inject } from 'vue'
import { contractKey, updateContractKey, v$Key } from '../injectionKeys'

export default {
  components: {
    LeasingParticipant,
  },
  props: {
    readonly: { type: Boolean },
    mobile: { type: Boolean },
  },
  setup() {
    const injectedContract = inject(contractKey)
    const updateContract = inject(updateContractKey)
    const v$ = inject(v$Key)

    const contract = computed({
      get() {
        return injectedContract
      },
      set(v) {
        return updateContract(v)
      },
    })

    return { contract, v$ }
  },
}
</script>
