<template>
  <div>
    <v-img
      src="https://www.adm-km.gov.ua/wp-content/uploads/2023/05/credits_img.png"
      :width="width"
      :height="height" />
  </div>
</template>

<script>
export default {
  props: {
    width: { type: String, default: '20px' },
    height: { type: String, default: '20px' },
  },
}
</script>

<style scoped></style>
