const FsnAccountingAdvanteges = () => {
  return [
    [
      {
        text: [
          {
            text: 'ПЕРЕВАГИ БУХГАЛТЕРСЬКОГО ОБЛІКУ',
            margin: [0, 4, 0, 0],
          },
        ],
        margin: [0, 25, 0, 10],
        fontSize: 15,
        bold: true,
      },
    ],
    {
      margin: [0, 0, 0, 2],
      columns: [
        {
          image: 'dashIcon',
          fit: [9, 9],
          width: '2%',
          color: '#d7514f',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Бух.облік і амортизація предмету лізингу здійснюються на балансі клієнта',
          margin: [5, 0, 0, 0],
        },
      ],
    },
    {
      margin: [0, 0, 0, 2],
      columns: [
        {
          image: 'dashIcon',
          fit: [9, 9],
          width: '2%',
          color: '#d7514f',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Комісія лізингової компанії відноситься до валових витрат, зменшуючи базу оподаткування',
          margin: [5, 0, 0, 0],
        },
      ],
    },
    {
      margin: [0, 0, 0, 2],
      columns: [
        {
          image: 'dashIcon',
          fit: [9, 9],
          width: '2%',
          color: '#d7514f',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Ви отримуєте податковий кредит на загальну суму предмета лізингу',
          margin: [5, 0, 0, 0],
        },
      ],
    },
    {
      margin: [0, 0, 0, 2],
      columns: [
        {
          image: 'dashIcon',
          fit: [9, 9],
          width: '2%',
          color: '#d7514f',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Ви отримуєте податковий кредит на загальну суму предмета лізингу',
          margin: [5, 0, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          image: 'dashIcon',
          fit: [9, 9],
          width: '2%',
          color: '#d7514f',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Активи, передані у лізинг не підлягають відчуженню податковими органами',
          margin: [5, 0, 0, 0],
        },
      ],
    },
  ]
}

export { FsnAccountingAdvanteges }
