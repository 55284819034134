<template>
  <div>
    <SectionLoader v-if="loading"></SectionLoader>
    <v-fade-transition hide-on-leave>
      <div v-if="!loading" style="background: #fff">
        <v-row v-if="!loading">
          <v-col cols="12" md="3" sm="12">
            <FieldsColumnBlock title="Основні дані">
              <template #general-fields>
                <Fields :fields="fields" :type="2" direction="column"> </Fields>
              </template>
              <template #default>
                <div class="mb-2">
                  <span class="label">Вид підпису</span>
                  <v-select
                    v-model="agreement.signingTypeId"
                    :items="
                      $directory.get('signingTypes', agreement.signingType)
                    "
                    item-title="name"
                    item-value="id"
                    hide-details
                    :readonly="readonly"
                    :disabled="readonly"
                    placeholder="Оберіть вид"
                    :error-messages="
                      getErrorMessages(v$.agreement.signingTypeId)
                    "
                    :loading="$loading.isLoading('signingTypes')"
                    @focus="$directory.fill('signingTypes')"
                    @blur="v$.agreement.signingTypeId.$touch()">
                  </v-select>
                </div>
              </template>
            </FieldsColumnBlock>
            <FieldsColumnBlock title="Візування" :fullHeight="!mobile">
              <template #agreements>
                <Agreements :agreements="agreement.agreements" class="mb-2" />
              </template>
            </FieldsColumnBlock>
          </v-col>

          <v-col cols="12" md="9" sm="12" :class="`${mobile ? '' : 'pl-0'}`">
            <div class="white-block" style="padding-left: 7px">
              <ContractStatusBar
                class="mb-3"
                :contract="agreement"
                :mobile="mobile"
                :isCancelContract="agreement.isCancelContract"
                :isTerminated="agreement.isTerminated"
                :currentStatus="agreement.statusId"
                :submit="setExtraAgreementStatus"
                :statuses="filteredStatuses"
                spreadItems
                small>
                <template #actions>
                  <ContractActionButton :actions="btnActions" />
                </template>
              </ContractStatusBar>
              <v-tabs
                v-model="currentTab"
                class="dkpTabs"
                show-arrows
                color="white"
                dark
                dense
                slider-color="#fc7247"
                :height="$vuetify.breakpoint?.xs ? null : 38">
                <v-tab
                  replace
                  :to="{
                    name: `du-dkp-parties-requisites`,
                    params: { id: $route.params.id },
                  }">
                  Реквізити сторін
                </v-tab>
                <v-tab
                  replace
                  :to="{
                    name: `du-dkp-agreements`,
                    params: { id: $route.params.id },
                  }">
                  Договори
                </v-tab>
                <v-tab
                  replace
                  :to="{
                    name: `du-dkp-history`,
                    params: { id: $route.params.id },
                  }">
                  Історія
                </v-tab>
              </v-tabs>
            </div>
            <router-view
              :agreement="agreement"
              :mobile="mobile"
              :readonly="readonly"></router-view>
            <ActionButtons
              class="mt-10"
              :confirm="submit"
              :cancel="getExtraAgreement"
              :confirmLoading="submitLoading"
              :cancelDisable="submitLoading || agreementState === cache"
              :confirmDisable="agreementState === cache">
            </ActionButtons>
          </v-col>
        </v-row>
      </div>
    </v-fade-transition>
  </div>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'
import ActionButtons from '@/components/action-buttons.vue'
import {
  setCache,
  setErrHandler,
  v$Notify,
  toFormatDate,
  getEntityName,
} from '@/utils/helperFunc'
import {
  urlUpdateExtraAgreementDkp,
  urlSetCancelExtraAgreementDkp,
  urlSetExtraAgreementDkpStatus,
} from '@/pages/request'
import { mapState } from 'vuex'
import { required, minLength } from '@vuelidate/validators'
import Fields from '@/components/Fields.vue'
import { useVuelidate } from '@vuelidate/core'
import { confirm } from 'best-modules/plugins'
import { duTypeKey } from '../injectionKeys.ts'
import { fillDirectory, getDirectory } from '@/plugins/directory/index.ts'
import FieldsColumnBlock from '@/pages/contracts/components/FieldsColumnBlock.vue'
import Agreements from '@/components/Agreements.vue'
import ContractStatusBar from '@/components/contracts/ContractStatusBar.vue'
import ContractActionButton from '@/pages/contracts/components/ContractActionButton.vue'
import { useDisplay } from 'vuetify'
import { useSelect } from '@/utils/mixins/useSelect'
import { computed } from 'vue'
import { getErrorMessages } from 'best-modules/utils'

export default {
  components: {
    ContractActionButton,
    ContractStatusBar,
    Agreements,
    FieldsColumnBlock,
    Fields,
    SectionLoader,
    ActionButtons,
  },
  setup() {
    const { xs, sm } = useDisplay()
    const mobile = computed(() => xs.value || sm.value)

    return { v$: useVuelidate(), ...useSelect(), mobile }
  },
  inject: {
    duType: {
      from: duTypeKey,
    },
  },
  validations() {
    return {
      agreement: {
        date: { required, minLength: minLength(10) },
        signatoryId: { required },
        buyerBasisId: { required },
        signingTypeId: { required },
        providerSignatories: {
          required,
          minLength: minLength(1),
          $each: {
            contactId: { required },
            basisId: { required },
          },
        },
      },
    }
  },
  data: () => ({
    submitLoading: false,
    currentTab: 0,
  }),
  computed: {
    ...mapState({
      agreement: state => state.contracts.extraAgreement,
      loading: state => state.contracts.extraAgreementLoading,
      selectItems: state => state.selectItems,
      cache: state => state.contracts.extraAgreementCache,
      dfl: state => state.contracts?.extraAgreement?.contract,
      user: state => state.user,
      lessee: state => state.contracts.dflContract.lessee,
    }),
    filteredStatuses() {
      const filteredStatuses = getDirectory('dkpStatuses').filter(item => {
        if (item.id === 6) {
          return this.agreement.isCancelContract
        }
        if (item.id === 7) {
          return false
        }
        return true
      })
      return filteredStatuses.map(s => {
        let showCheckIcon = false

        if (this.agreement.statusId === 6) {
          showCheckIcon = s.id === 6
        }

        return { ...s, showCheckIcon }
      })
    },
    btnActions() {
      return {
        main: { label: 'Дії', disabled: false },
        list: [
          {
            show: this.agreement.statusId !== 5,
            label: 'Анулювати договір',
            icon: 'mdi-file-document-remove-outline',
            action: () => this.setCancel(),
            disabled:
              (!this.isAdmin && this.readonly) ||
              this.agreement.isCancelContract,
          },
        ],
      }
    },
    fields() {
      return [
        {
          label: 'Дата договору',
          value: toFormatDate(this.agreement.date, true),
        },
        { label: '№ Договору', value: this.agreement.number },
        {
          label: 'ДКП',
          value: this.agreement.dkp?.number,
          class: { link: true },
          click: () => {
            this.$router.push({
              name: 'dkp-contract',
              params: { id: this.agreement.dkp?.id },
            })
          },
        },
      ]
    },
    agreementId() {
      return this.$route?.params?.id
    },
    agreementState() {
      const obj = Object.clone(this.agreement)
      return this.setCache([obj])
    },
    typeIdErr() {
      return this.setErrHandler(this.v$?.agreement?.typeId)
    },
    route() {
      return this.$route.name
    },
    isAdmin() {
      return this.user.roleId === 1
    },
    readonly() {
      return this.agreement.readOnly
    },
  },
  methods: {
    getErrorMessages,
    setCache,
    setErrHandler,
    getExtraAgreement() {
      this.$store.dispatch('addAgreementTypes')
      this.$store
        .dispatch('getExtraAgreement', {
          id: this.agreementId,
          duType: this.duType,
        })
        .then(() => {
          this.setStoreCache()
          this.setBreadScrumb()
        })
        .catch(this.$err)
    },
    setBreadScrumb(res) {
      this.$store.commit('setBreadScrumbPart', [
        null,
        null,
        `${getEntityName(this.agreement.lessee)} - ${this.agreement.number}`,
      ])
      return res
    },
    setCancel() {
      confirm({
        text: 'Ви дійсно бажаєте анулювати договір?',
      }).then(() => {
        return this.$axios
          .post(urlSetCancelExtraAgreementDkp(this.agreement.id), {
            isCancelContract: true,
          })
          .then(res => {
            this.$setSnackbar({ text: 'Оновлено' })
            Object.assign(this.agreement, res.data)
            this.setCache([this.agreement])
            this.setStoreCache()

            return res
          })
      })
    },
    setStoreCache(res) {
      const obj = Object.clone(this.agreement)
      this.$store.commit('setExtraAgreementCache', this.setCache([obj]))
      return res
    },
    async setExtraAgreementStatus(statusId) {
      if (this.v$.$invalid) {
        return Promise.reject(v$Notify(this.v$.agreement, 'extraAgreement'))
      }

      const currentStatus = this.agreement.statusId

      if (currentStatus === 5) {
        return this.$setSnackbar({
          text: 'Договір з статусу підписаного не може бути змiнено',
          color: 'warning',
        })
      }
      if (
        [4, 5].includes(statusId) &&
        this.agreement.agreements?.some(a => !a.isAgreed)
      ) {
        return this.$setSnackbar({
          text: 'Договір повинен бути завізований всiма учасниками',
          color: 'warning',
        })
      }
      if (statusId < currentStatus || statusId > currentStatus + 1) {
        return this.$setSnackbar({ text: 'Дія неможлива', color: 'error' })
      }

      if ([2, 3].includes(statusId)) {
        try {
          const statusNames = {
            2: 'Підписано клієнтом',
            3: 'Візування',
          }
          await confirm({
            text: `Ви впевнені що хочете перевести договір в статус "${statusNames[statusId]}"?`,
          })
        } catch (e) {
          return
        }
      }

      return this.$axios
        .post(urlSetExtraAgreementDkpStatus(this.agreementId), {
          statusId: statusId,
        })
        .then(res => {
          if (res.data.message) {
            this.$setSnackbar({ text: res.data.message, color: 'warning' })
          } else this.$setSnackbar({ text: 'Статус успішно змiнено' })
          this.getExtraAgreement()
          return res
        })
        .catch(this.$err)
    },
    submit() {
      if (this.v$.$invalid) {
        this.v$.$anyError
        this.v$.$touch()
        v$Notify(this.v$.agreement, 'extraAgreement')
        return Promise.resolve()
      }

      const requestObj = Object.clone(this.agreement)
      if (requestObj.lesseeSignatories)
        requestObj.lesseeSignatories = requestObj.lesseeSignatories.map(s => ({
          contactId: s.contactId,
          basisId: s.basisId,
        }))

      this.submitLoading = true
      return this.$axios
        .post(urlUpdateExtraAgreementDkp(this.agreementId), requestObj)
        .then(res => {
          if (res.data.message) throw new Error(res.data.message)
          this.submitLoading = false
          this.setStoreCache()
          this.$setSnackbar({ text: 'Данi оновлено' })
          return res
        })
        .catch(err => this.$err(err, () => (this.submitLoading = false)))
    },
  },
  watch: {
    route() {
      this.setBreadScrumb()
    },
    v$: {
      deep: true,
      handler(v) {
        this.$store.commit('agreementValidity', v)
      },
    },
  },
  created() {
    fillDirectory('dkpStatuses')
    this.getExtraAgreement()
  },
}
</script>

<style scoped>
.agr-label {
  color: rgb(131, 130, 130);
  font-size: 12px;
  display: block;
  font-weight: 500;
}
.label-bottom {
  color: #000;
  font-weight: 700;
  font-size: 14px;
}
.edit-calc {
  opacity: 0;
  transition: 0.3s;
}
.calc-block {
  transition: 0.3s;
}
.calc-block:hover {
  background: rgb(240, 240, 240);
}
.calc-block:hover .edit-calc {
  opacity: 1;
  transition: 0.3s;
}
a.v-tab-item--selected.v-tab {
  color: #08487a !important;
  font-weight: 700 !important;
  font-size: 14px;
  letter-spacing: normal;
  padding-left: 0;
  padding-right: 0;
}
a.v-tab {
  margin-right: 20px;
  padding-left: 0;
  padding-right: 0;
  color: #838282 !important;
  letter-spacing: normal !important;
}
.v-slide-group__wrapper {
  background: #fff !important;
}
.dkpTabs .v-slide-group__wrapper {
  background: #fff !important;
}
</style>
