<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none">
    <path
      d="M0.390454 22.6055L0.394532 22.6095C0.502673 22.7185 0.631281 22.805 0.772961 22.864C0.91464 22.9231 1.0666 22.9536 1.2201 22.9537C1.34925 22.9536 1.47752 22.9323 1.59978 22.8906L8.25631 20.6326L21.0298 7.85915C21.8106 7.07821 22.2493 6.01905 22.2492 4.91468C22.2492 3.81031 21.8104 2.75119 21.0295 1.97031C20.2486 1.18944 19.1894 0.750776 18.085 0.750824C16.9807 0.750872 15.9215 1.18963 15.1407 1.97057L2.36722 14.744L0.109345 21.4004C0.0382219 21.6073 0.0268693 21.8302 0.076589 22.0433C0.126309 22.2565 0.235088 22.4513 0.390454 22.6055ZM16.2014 3.03121C16.7016 2.53477 17.3782 2.25679 18.083 2.25815C18.7878 2.2595 19.4633 2.54007 19.9617 3.03843C20.46 3.53679 20.7406 4.21232 20.7419 4.91709C20.7432 5.62187 20.4652 6.29845 19.9688 6.7987L18.2891 8.47841L14.5216 4.71093L16.2014 3.03121ZM3.6755 15.5571L13.4609 5.77157L17.2284 9.53905L7.44294 19.3245L1.74134 21.2586L3.6755 15.5571Z"
      fill="#FC7247" />
  </svg>
</template>

<script>
export default {
  name: 'Edit',
}
</script>

<style scoped></style>
