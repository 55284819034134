import axios from 'axios'
const common = {
  state: () => ({
    // drawer
    drawer: true,
    // subheader
    subheader: {
      state: false,
      name: null,
      data: null,
    },
    // unique identifier
    uniqueStr: null,
    // key to rerender view
    componentKey: 0,
    // contact id for application
    contactId: null,
    // contractor id for application
    contractorId: null,
    token: null,
    renderSideBarMenu: true,
  }),
  mutations: {
    rerenderMenu(state) {
      state.renderSideBarMenu = false
      setTimeout(() => (state.renderSideBarMenu = true), 100)
    },
    setSubheader(state, obj) {
      Object.assign(state.subheader, obj)
    },
    setDrwState(state, val) {
      state.drawer = val
    },
    refreshPage(state) {
      state.componentKey += 1
    },
    setUnique(state, value) {
      state.uniqueStr = value
    },
    saveContactId(state, id) {
      state.contactId = id
    },
    saveContractorId(state, id) {
      state.contractorId = id
    },
  },
  actions: {
    // eslint-disable-next-line
    getContractorEmployees({ commit }, { contractorId }) {
      return axios
        .get(`/json/contractor/getContacts/${contractorId}`)
        .then(res => res)
        .catch(err => {
          throw err
        })
    },
  },
  getters: {
    //...
  },
}

export { common }
