<template>
  <div>
    <v-dialog v-model="dialog" width="450px" persistent>
      <v-card>
        <v-toolbar
          style="
            color: #08487a;
            font-weight: 700 !important;
            font-size: 16px;
            background-color: #fff;
            box-shadow: none;
          "
          :height="60"
          dark>
          <v-toolbar-title style="font-size: 1rem"
            >Підтвердження дії</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn size="small" icon dense @click="reject">
            <CloseIcon />
          </v-btn>
        </v-toolbar>
        <v-card-text
          :style="{
            backgroundColor: '#ffffff !important',
            height: 450,
            overflowY: 'auto',
            overflowX: 'hidden',
          }">
          <div class="pa-2 pt-0" style="background: #fff">
            <span>{{ text }}</span>
          </div>
        </v-card-text>
        <v-card-actions
          style="background: white"
          class="pb-2 justify-space-around">
          <v-btn
            text
            :style="{
              width: '45%',
              color: '#08487a',
              'font-weight': 700,
              'font-size': '12px',
              background: '#fff',
              border: '2px solid #08487a',
            }"
            :height="33"
            small
            @click="reject">
            ВІДХИЛИТИ
          </v-btn>
          <v-btn
            class="text-white"
            :style="{
              width: '45%',
              background: '#08487a',
              'border-radius': '4px',
            }"
            :height="33"
            small
            @click="submit">
            ПІДТВЕРДИТИ
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CloseIcon from '@/assets/svg/close-icon.vue'

export default {
  components: { CloseIcon },
  emits: ['submit', 'reject'],
  props: {
    text: { type: [String, Number] },
  },
  data: () => ({
    dialog: true,
  }),
  methods: {
    submit() {
      this.$emit('submit')
    },
    reject() {
      this.$emit('reject')
    },
  },
}
</script>

<style scoped>
.header {
  position: sticky;
  right: 0;
  top: 0;
  height: 40px;
  display: flex;
  justify-content: flex-end;
}
</style>
