<template>
  <div class="mt-5 d-flex justify-center">
    <table>
      <tbody>
        <tr>
          <td><strong>Клієнт</strong></td>
          <td colspan="3">
            {{ applicationData?.lessee?.fullName || 'Відсутній ' }}
          </td>
        </tr>
        <tr>
          <td><strong>Контактні дані</strong></td>
          <td colspan="3">
            <div>
              <span class="d-block"
                >Телефон:
                {{
                  contactDetailsWithType(
                    applicationData?.lessee?.phones,
                    'phone'
                  )
                }}</span
              >
              <span class="d-block"
                >Пошта:
                {{
                  contactDetailsWithType(
                    applicationData?.lessee?.emails,
                    'email'
                  )
                }}</span
              >
            </div>
          </td>
        </tr>
        <tr>
          <td><strong>Контактна особа клієнта</strong></td>
          <td colspan="3">
            <div>
              <span class="d-block"
                >ФІО:
                {{
                  applicationData?.lessee?.lesseeEmployee?.contact?.fullName ||
                  'Відсутній'
                }}</span
              >
              <span
                v-if="applicationData?.lessee?.lesseeEmployee?.fullPostName"
                class="d-block"
                >Посада:{{
                  applicationData?.lessee?.lesseeEmployee?.fullPostName ||
                  'Відсутня'
                }}</span
              >
              <span class="d-block"
                >Телефон:
                {{
                  contactDetailsWithType(
                    applicationData?.lessee?.lesseeEmployee?.contact?.phones,
                    'phone'
                  )
                }}</span
              >
              <span class="d-block"
                >Пошта:
                {{
                  contactDetailsWithType(
                    applicationData?.lessee?.lesseeEmployee?.contact?.emails,
                    'email'
                  )
                }}</span
              >
            </div>
          </td>
        </tr>
        <tr>
          <td><strong>Предмет лізингу</strong></td>
          <td colspan="3">
            <span>{{ calculation?.leasingObjectName || 'Відсутній' }}</span>
          </td>
        </tr>
        <tr>
          <td><strong>Рік випуску</strong></td>
          <td>{{ calculation?.leasingObjectYear || 'Відсутній' }}</td>
          <td><strong>Кількість</strong></td>
          <td>{{ calculation?.leasingQuantity || 'Відсутня' }}</td>
        </tr>
        <tr>
          <td><strong>Тип кузова</strong></td>
          <td>{{ calculation?.body?.name || 'Відсутній' }}</td>
          <td><strong>Об'єм двигуна</strong></td>
          <td>
            {{
              ((calculation.leasedAssertEngine || 0) / 1000).toFixed(1) ||
              'Відсутній'
            }}
            см3
          </td>
        </tr>
        <tr>
          <td><strong>Тип ГО (закладено в калькуляторі)</strong></td>
          <td colspan="3">
            <span class="d-blocl">{{
              applicationData?.insurance?.insuranceVehicleType
            }}</span>
          </td>
        </tr>
        <tr style="height: 100px">
          <td><strong>Дообладнання</strong></td>
          <td>
            Сума:
            {{
              getBeautyNum(calculation?.addEquipmentSum || 0, {
                float: 2,
              })
            }}, знижка:
            {{
              getBeautyNum(calculation?.equipmentDiscount || 0, {
                float: 2,
              })
            }}, сума зі знижкою:
            {{
              getBeautyNum(calculation?.equipSumWithDiscount || 0, {
                float: 2,
              })
            }}
          </td>
          <td
            colspan="2"
            :style="{
              'background-color': applicationData.equipment
                ? 'yellow'
                : '#fb8383',
            }"
            @dblclick="toggleEquipment">
            <v-textarea
              v-if="showEquipment"
              v-model="applicationData.equipment"
              class="pt-0 mt-0"
              rows="3"
              hide-details>
            </v-textarea>
            <span v-else>{{ applicationData?.equipment || 'Відсутній' }}</span>
          </td>
        </tr>
        <tr @dblclick="toggleVinCode">
          <td><strong>Номер кузова/шасі</strong></td>
          <td
            colspan="3"
            :style="{
              'background-color': calculation.vinCode ? 'yellow' : '#fb8383',
            }">
            <v-text-field
              v-if="showVinCode"
              v-model="calculation.vinCode"
              class="pt-0 mt-0"
              hide-details>
            </v-text-field>
            <span v-else>{{ calculation.vinCode || 'Відсутній' }}</span>
          </td>
        </tr>
        <tr>
          <td><strong>Колір</strong></td>
          <td
            :style="{
              'background-color': applicationData.leasingObjectColor
                ? 'yellow'
                : '#fb8383',
            }"
            @dblclick="toggleLeasingObjectColor">
            <v-text-field
              v-if="showLeasingObjectColor"
              v-model="applicationData.leasingObjectColor"
              class="pt-0 mt-0"
              hide-details>
            </v-text-field>
            <span v-else>{{
              applicationData?.leasingObjectColor || 'Відсутній'
            }}</span>
          </td>
          <td><strong>Номер двигуна</strong></td>
          <td
            :style="{
              'background-color': applicationData.leasingObjectEngineNumber
                ? 'yellow'
                : '#fb8383',
            }"
            @dblclick="toggleLeasingObjectEngineNumber">
            <v-text-field
              v-if="showLeasingObjectEngineNumber"
              v-model="applicationData.leasingObjectEngineNumber"
              class="pt-0 mt-0"
              hide-details>
            </v-text-field>
            <span v-else>{{
              applicationData?.leasingObjectEngineNumber || 'Відсутній'
            }}</span>
          </td>
        </tr>
        <tr>
          <td><strong>Адміністрування паливний карт</strong></td>
          <td>{{ calculation?.patrolCardsSupport || 'ні' }}</td>
          <td><strong>Кількість </strong></td>
          <td>{{ calculation?.patrolCardQuantity || 0 }}</td>
        </tr>
        <tr>
          <td><strong>Наявність GPS Маячка</strong></td>
          <td>
            {{ calculation?.gpsBeaconType?.name }}
          </td>
          <td><strong>Кількість</strong></td>
          <td>{{ calculation?.gpsBeaconType ? '1' : '0' }}</td>
        </tr>
        <tr>
          <td><strong>GPS - трекер</strong></td>
          <td colspan="3">{{ calculation?.gpsTrackerType?.name }}</td>
        </tr>
        <tr>
          <td><strong>Подарунок</strong></td>
          <td>{{ calculation?.gifts || 'Відсутні' }}</td>
          <td><strong>Пакет 'Assistance'</strong></td>
          <td>{{ calculation?.assistancePackage || 'Відсутній' }}</td>
        </tr>
        <tr>
          <td><strong>Постачальник</strong></td>
          <td colspan="3">{{ applicationData?.provider?.fullName }}</td>
        </tr>
        <tr>
          <td><strong>Контактна особа постачальника</strong></td>
          <td colspan="3">
            <div>
              <span class="d-block"
                >ФІО:{{
                  applicationData?.provider?.providerEmployee?.contact
                    ?.fullName || 'Відсутній'
                }}</span
              >
              <span class="d-block"
                >Посада:{{
                  applicationData?.provider?.providerEmployee?.fullPostName ||
                  'Відсутня'
                }}</span
              >
              <span class="d-block"
                >Телефон:
                {{
                  contactDetailsWithType(
                    applicationData?.provider?.providerEmployee?.contact
                      ?.phones,
                    'phone'
                  )
                }}</span
              >
              <span class="d-block"
                >Пошта:
                {{
                  contactDetailsWithType(
                    applicationData?.provider?.providerEmployee?.contact
                      ?.emails,
                    'email'
                  )
                }}</span
              >
            </div>
          </td>
        </tr>
        <tr>
          <td><strong>Договір ФЛ</strong></td>
          <td colspan="3">
            <div>
              <span class="d-block"
                >ДФЛ {{ applicationData?.contract?.number }} від
                {{
                  toFormatDate(
                    applicationData?.contract?.agreementDate,
                    true,
                    false
                  )
                }}</span
              >
              <span class="d-block"
                >Ціна по договору ДФЛ
                <strong>{{
                  getBeautyNum(applicationData?.contract?.leasingAmount)
                }}</strong>
                грн
              </span>
            </div>
          </td>
        </tr>
        <tr v-for="du in applicationData?.extraAgreements" :key="du.id">
          <td><strong>Додаткова угода</strong></td>
          <td colspan="3">
            <div>
              <span class="d-block"
                >ДУ {{ du?.number }} від
                {{ toFormatDate(du?.date, true, false) }}</span
              >
              <span class="d-block"
                >Ціна по договору ДУ
                <strong>{{ getBeautyNum(du?.leasingAmount) }}</strong> грн
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td><strong>Договір КП</strong></td>
          <td colspan="3">
            <div>
              <span class="d-block"
                >ДКП {{ applicationData?.dkp?.number }} від
                {{
                  toFormatDate(applicationData?.dkp?.date, true, false)
                }}</span
              >
              <span class="d-block"
                >Ціна по договору ДКП
                <strong>{{
                  getBeautyNum(applicationData?.dkp?.leasingAmountDkp)
                }}</strong>
                грн
              </span>
            </div>
          </td>
        </tr>
        <tr v-for="pl in applicationData?.pledgeContracts" :key="pl.id">
          <td><strong>Договір застави</strong></td>
          <td colspan="3">
            <span
              >{{ pl?.number || 'Відсутній' }} від
              {{ toFormatDate(pl?.date, true, false) }}</span
            >
          </td>
        </tr>
        <tr v-for="gu in applicationData?.guaranteeContracts" :key="gu.id">
          <td><strong>Договір поруки</strong></td>
          <td colspan="3">
            <div>
              <span
                >{{ gu?.number || 'Відсутній' }} від
                {{ toFormatDate(gu?.date, true, false) }}</span
              >
            </div>
          </td>
        </tr>
        <tr>
          <td><strong>Авансовий платіж від клієнта</strong></td>
          <td>
            {{ getBeautyNum(calculation?.advancePayment || 0, { float: 2 }) }}
            грн
          </td>
          <td><strong>Дата отримання</strong></td>
          <td
            :style="{
              'background-color': calculation.advanceDate
                ? 'yellow'
                : '#fb8383',
            }"
            @dblclick="toggleAdvanceDate">
            <date-picker
              v-if="showAdvanceDate"
              v-model="calculation.advanceDate"
              style="margin-top: -10px !important"
              single></date-picker>
            <span v-else>{{
              toFormatDate(calculation?.advanceDate, true, false) || 'Відсутня'
            }}</span>
          </td>
        </tr>
        <tr>
          <td><strong>Авансовий платіж постачальнику</strong></td>
          <td
            :style="{
              'background-color': applicationData.advanceToProvider
                ? 'yellow'
                : '#fb8383',
            }"
            @dblclick="toggleAdvanceToProvider">
            <v-text-field
              v-if="showAdvanceToProvider"
              id="ct-tr-price"
              v-model="applicationData.advanceToProvider"
              class="pt-0 mt-0"
              hide-details
              @blur="inputNumber($event, { float: 2 })"
              @input="
                $event => {
                  inputNumber($event, { float: 2, minValue: 0 })
                  changeInputVal('ct-tr-price', 'input', [keepDigit, formutSum])
                }
              ">
            </v-text-field>
            <span v-else
              >{{
                getBeautyNum(applicationData?.advanceToProvider || 0, {
                  float: 2,
                }) || 0
              }}
              грн</span
            >
          </td>
          <td><strong>Дата оплати</strong></td>
          <td
            :style="{
              'background-color': applicationData.advanceDateToProvider
                ? 'yellow'
                : '#fb8383',
            }"
            @dblclick="toggleAdvanceDateToProvider">
            <date-picker
              v-if="showAdvanceDateToProvider"
              v-model="applicationData.advanceDateToProvider"
              style="margin-top: -10px !important"
              single></date-picker>
            <span v-else>{{
              toFormatDate(
                applicationData?.advanceDateToProvider,
                true,
                false
              ) || 'Відсутня'
            }}</span>
          </td>
        </tr>
        <tr>
          <td><strong>Оплата на постачальника</strong></td>
          <td
            :style="{
              'background-color': applicationData.paymentToProvider
                ? 'yellow'
                : '#fb8383',
            }"
            @dblclick="togglePaymentToProvider">
            <v-text-field
              v-if="showPaymentToProvider"
              id="ct-tv-price"
              v-model="applicationData.paymentToProvider"
              class="pt-0 mt-0"
              hide-details
              @blur="inputNumber($event, { float: 2 })"
              @input="
                $event => {
                  inputNumber($event, { float: 2, minValue: 0 })
                  changeInputVal('ct-tv-price', 'input', [keepDigit, formutSum])
                }
              ">
            </v-text-field>
            <span v-else>
              {{
                getBeautyNum(applicationData?.paymentToProvider || 0, {
                  float: 2,
                }) || 0
              }}
              грн
            </span>
          </td>
          <td><strong>Дата оплати</strong></td>
          <td
            :style="{
              'background-color': applicationData.paymentDateToProvider
                ? 'yellow'
                : '#fb8383',
            }"
            @dblclick="togglePaymentDateToProvider">
            <date-picker
              v-if="showPaymentDateToProvider"
              v-model="applicationData.paymentDateToProvider"
              style="margin-top: -10px !important"
              single></date-picker>
            <span v-else>
              {{
                toFormatDate(
                  applicationData?.paymentDateToProvider,
                  true,
                  false
                ) || 'Відсутня'
              }}
            </span>
          </td>
        </tr>
        <tr>
          <td><strong>Місце передачі ОЛ</strong></td>
          <td
            colspan="3"
            :style="{
              'background-color': calculation.placeOfShipment
                ? 'yellow'
                : '#fb8383',
            }"
            @dblclick="togglePlaceOfShipment">
            <v-text-field
              v-if="showPlaceOfShipment"
              v-model="calculation.placeOfShipment"
              class="pt-0 mt-0"
              hide-details></v-text-field>
            <span v-else>{{ calculation?.placeOfShipment || 'Відсутнє' }}</span>
          </td>
        </tr>
        <tr>
          <td><strong>Дата реєстрації</strong></td>
          <td
            :style="{
              'background-color': applicationData.registrationDate
                ? 'yellow'
                : '#fb8383',
            }"
            @dblclick="toggleRegistrationDate">
            <date-picker
              v-if="showRegistrationDate"
              v-model="applicationData.registrationDate"
              style="margin-top: -10px !important"
              single></date-picker>
            <span v-else>{{
              toFormatDate(applicationData?.registrationDate, true, false) ||
              'Відсутня'
            }}</span>
          </td>
          <td><strong>Дата відвантаження</strong></td>
          <td
            :style="{
              'background-color': calculation.shipmentDate
                ? 'yellow'
                : '#fb8383',
            }"
            @dblclick="toggleShipmentDate">
            <date-picker
              v-if="showShipmentDate"
              v-model="calculation.shipmentDate"
              style="margin-top: -10px !important"
              single></date-picker>
            <span v-else>{{
              toFormatDate(calculation?.shipmentDate, true, false) || 'Відсутня'
            }}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span class="d-block"
              ><strong>Договір ФЛ (хто страхує)</strong></span
            >
          </td>
          <td colspan="3">
            {{ applicationData?.insurance?.shallInsure || 'Відсутній' }}
          </td>
        </tr>
        <tr>
          <td><strong>Реквізити Лізингоотримувача</strong></td>
          <td colspan="3">
            <div>
              <span class="d-block"
                >Лізингоотримувач:
                {{ applicationData?.lessee?.fullName || 'Відсутній' }}</span
              >
              <span class="d-block"
                >ЄДРПОУ:
                {{ applicationData?.lessee?.lesseeCode || 'Відсутній' }}
              </span>
              <span class="d-block"
                >Фактична адреса:
                {{
                  getAddress(
                    applicationData?.lessee?.addresses?.find(
                      a => a.typeId === 2
                    )
                  ) || '---'
                }}
              </span>
              <span
                v-if="applicationData?.lessee?.lesseeTypeId === 2"
                class="d-block"
                >Юридична адреса:
                {{
                  getAddress(
                    applicationData?.lessee?.addresses?.find(
                      a => a.typeId === 1
                    )
                  ) || '---'
                }}
              </span>
              <span
                v-if="!applicationData?.lessee?.lesseeTypeId === 2"
                class="d-block"
                >Адреса реєстрації:
                {{
                  getAddress(
                    applicationData?.lessee?.addresses?.find(
                      a => a.typeId === 4
                    )
                  ) || '---'
                }}
              </span>
              <span class="d-block">Банківські реквізити:</span>
              <div
                v-for="ac in applicationData?.lessee?.currentAccounts"
                :key="ac.id"
                class="d-block">
                <span>
                  {{ `${ac.bankName} МФО:${ac.mfo} (${ac.accountNumber})` }}
                </span>
              </div>
              <span class="d-block"
                >Індивідуальний податковий номер:
                {{
                  applicationData?.lessee?.lesseeTaxNumber || 'Відсутній'
                }}</span
              >
              <div v-if="applicationData?.lessee?.lesseeSignatories">
                <span
                  v-for="director in applicationData?.lessee?.lesseeSignatories"
                  :key="director.contactId"
                  class="d-block"
                  >{{ director?.fullPostName || 'Посада відсутня' }}:
                  {{ director?.contact?.fullName || 'Відсутній' }}</span
                >
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td><strong>Договір ФЛ (Термін лізингу)</strong></td>
          <td colspan="3">
            {{ applicationData?.insurance?.leasingTerm }}
          </td>
        </tr>
        <tr>
          <td><strong>Програма страхування КАСКО </strong></td>
          <td colspan="3">
            {{
              applicationData?.insurance?.insuranceProgramType?.name ||
              'Відсутній'
            }}
          </td>
        </tr>
        <tr>
          <td><strong>Страховий тариф</strong></td>
          <td colspan="3">
            {{
              applicationData?.insurance?.insuranceFranchiseType?.name ||
              'Відсутній'
            }}
          </td>
        </tr>
        <tr>
          <td><strong>Розміри франшиз</strong></td>
          <td colspan="3">
            <span class="d-block"
              >ДТП -
              {{ applicationData?.insurance?.deductibleFranchise }}%</span
            >
            <span class="d-block"
              >Стихійні лиха -
              {{ applicationData?.insurance?.disasterFranchise }}%</span
            >
            <span class="d-block"
              >Протиправні дії третіх осіб -
              {{ applicationData?.insurance?.pdflFranchise }}%</span
            >
            <span class="d-block"
              >Незаконне заволодіння -
              {{
                applicationData?.insurance?.illegalProcessionFranchise
              }}%</span
            >
          </td>
        </tr>
        <tr>
          <td><strong>Регіон страхування</strong></td>
          <td colspan="3">
            {{ applicationData?.insurance?.insuranceRegion || 'Відсутній' }}
          </td>
        </tr>
        <tr>
          <td><strong>Фото об'єкту лізингу</strong></td>
          <td colspan="3">
            <div>
              <v-img
                v-for="(img, idx) in calculation?.leasingObjectPhotos"
                :key="idx"
                max-height="70"
                max-width="180"
                :src="imgUrl(img.url)"></v-img>
            </div>
          </td>
        </tr>
        <tr style="height: 85px">
          <td><strong>Особливості</strong></td>
          <td
            colspan="3"
            :style="{
              'background-color': applicationData.notes ? 'yellow' : '#fb8383',
            }"
            @dblclick="toggleNotes">
            <v-textarea
              v-if="showNotes"
              v-model="applicationData.notes"
              class="pt-0 mt-0"
              rows="3"
              hide-details>
            </v-textarea>
            <span v-else v-html="formatNotes(applicationData?.notes)"></span>
          </td>
        </tr>
        <tr>
          <td><strong>Менеджери проекту</strong></td>
          <td colspan="3">
            {{
              getResponsibleShortName(
                applicationData?.frontManagerResponsible
              ) || 'Відсутній'
            }}
            /
            {{
              getResponsibleShortName(
                applicationData?.middleManagerResponsible
              ) || 'Відсутній'
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  contactDetailsWithType,
  getAddress,
  getBeautyNum,
  getEntityName,
  getEntityRoute,
  getResponsibleShortName,
  toFormatDate,
} from '@/utils/helperFunc'
import {
  changeInputVal,
  formutSum,
  inputNumber,
  keepDigit,
} from '@/utils/formatFunc'
import DatePicker from '@/components/DatePicker.vue'

export default {
  components: { DatePicker },
  props: {
    application: { type: Object },
  },
  data: () => ({
    showVinCode: false,
    showLeasingObjectColor: false,
    showLeasingObjectEngineNumber: false,
    showAdvanceToProvider: false,
    showAdvanceDateToProvider: false,
    showPaymentToProvider: false,
    showPaymentDateToProvider: false,
    showPlaceOfShipment: false,
    showRegistrationDate: false,
    showShipmentDate: false,
    showNotes: false,
    showEquipment: false,
    showAdvanceDate: false,
  }),
  computed: {
    applicationData() {
      return this.application
    },
    calculation() {
      return this.application.calculation
    },
    baseUrl() {
      return location.origin
    },
  },
  methods: {
    getResponsibleShortName,
    formutSum,
    keepDigit,
    changeInputVal,
    inputNumber,
    getAddress,
    contactDetailsWithType,
    toFormatDate,
    getBeautyNum,
    getEntityName,
    getEntityRoute,
    imgUrl(url) {
      return `${this.baseUrl}/${url}`
    },
    formatNotes(notes) {
      if (notes) {
        return notes.replace(/\n/g, '<br>')
      } else {
        return 'Відсутній'
      }
    },
    handleInput() {
      this.applicationData.leasingObjectEngineNumber =
        this.applicationData.leasingObjectEngineNumber.replace(/[^0-9]/g, '')
    },
    toggleVinCode() {
      this.showVinCode = !this.showVinCode
    },
    toggleLeasingObjectColor() {
      this.showLeasingObjectColor = !this.showLeasingObjectColor
    },
    toggleLeasingObjectEngineNumber() {
      this.showLeasingObjectEngineNumber = !this.showLeasingObjectEngineNumber
    },
    toggleAdvanceToProvider() {
      this.showAdvanceToProvider = !this.showAdvanceToProvider
    },
    toggleAdvanceDateToProvider() {
      this.showAdvanceDateToProvider = !this.showAdvanceDateToProvider
    },
    togglePaymentToProvider() {
      this.showPaymentToProvider = !this.showPaymentToProvider
    },
    togglePaymentDateToProvider() {
      this.showPaymentDateToProvider = !this.showPaymentDateToProvider
    },
    togglePlaceOfShipment() {
      this.showPlaceOfShipment = !this.showPlaceOfShipment
    },
    toggleRegistrationDate() {
      this.showRegistrationDate = !this.showRegistrationDate
    },
    toggleShipmentDate() {
      this.showShipmentDate = !this.showShipmentDate
    },
    toggleNotes() {
      this.showNotes = !this.showNotes
    },
    toggleEquipment() {
      this.showEquipment = !this.showEquipment
    },
    toggleAdvanceDate() {
      this.showAdvanceDate = !this.showAdvanceDate
    },
  },
}
</script>

<style scoped>
table {
  border-spacing: 0;
  border-collapse: collapse;
}
td {
  border: 1px solid black;
  padding: 2px 5px;
}
</style>
