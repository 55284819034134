<template>
  <v-card flat>
    <v-fade-transition hide-on-leave>
      <div>
        <Filters :filters="filters" class="ml-2" />
        <v-fade-transition hide-on-leave>
          <div>
            <SectionLoader v-if="loading" />
            <div>
              <v-data-table
                v-show="!loading"
                :headers="callsHeader"
                :items="calls"
                :custom-sort="$sortByDate"
                class="calc-table --cust-cell-pd"
                hide-default-footer
                :hide-default-header="!calls.length"
                @dblclick:row="navigateToCall">
                <!-- eslint-disable-next-line -->
                <template #item.phone="{ item }">
                  {{ item.phone }}
                </template>
                <!-- eslint-disable-next-line -->
                <template #item.startTime="{ item }">
                  {{ toFormatDate(item.startTime) }}
                </template>
                <!-- eslint-disable-next-line -->
                <template #item.endTime="{ item }">
                  {{ toFormatDate(item.endTime) }}
                </template>
                <!-- eslint-disable-next-line -->
                <template #item.action="{ item }">
                  <TableCrud
                    :actionsArray="[
                      {
                        action: () => openCallsDialog(item),
                        text: 'Переглянути',
                        icon: 'mdi-eye',
                      },
                    ]">
                  </TableCrud>
                </template>
              </v-data-table>
              <Pagination :getItems="getCalls" :trigger="paginationTrigger" />
            </div>
          </div>
        </v-fade-transition>
      </div>
    </v-fade-transition>
    <SectionLoader v-if="loading"></SectionLoader>
  </v-card>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'
import { toFormatDate, getContactInitials } from '@/utils/helperFunc'

import Pagination from '@/components/Pagination.vue'
import { urlGetCalls } from '@/pages/request'
import { phoneMask } from '@/utils/masks'
import Filters from '@/components/Filters.vue'
import { useFilter } from '@/utils/mixins/useFilter'
import { reactive } from 'vue'
import TableCrud from '@/components/table-crud.vue'

export default {
  components: { TableCrud, Filters, SectionLoader, Pagination },
  setup() {
    const filters = reactive({
      startTime: {
        value: null,
        component: 'DatePicker',
        attrs: {
          label: 'Період',
          range: true,
          keys: { startDate: 'startTime', endDate: 'endTime' },
        },
      },
      callStatus: {
        value: null,
        component: 'v-autocomplete',
        attrs: { label: 'Статус', items: 'callStatus' },
      },
      type_call: {
        value: null,
        component: 'v-autocomplete',
        attrs: { label: 'Тип', items: 'callTypes' },
      },
      telephony: {
        value: null,
        component: 'v-autocomplete',
        attrs: { label: 'Платформа', items: 'callTelephony' },
      },
      userId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Відповідальний',
          itemTitle: u => getContactInitials(u, { isResponsible: true }),
          items: 'allUsers',
        },
      },
      search: { value: null },
    })
    return {
      ...useFilter(filters),
      filters,
    }
  },
  data: () => ({
    callsHeader: [
      { title: 'Тип', key: 'type_call', sortable: false, align: 'start' },
      { title: 'Телефон', key: 'phone', sortable: false, align: 'start' },
      {
        title: 'Дата початку',
        key: 'startTime',
        sortable: false,
        align: 'start',
      },
      {
        title: 'Дата завершення',
        key: 'endTime',
        sortable: false,
        align: 'start',
      },
      { title: 'Дії', key: 'action', sortable: false, align: 'end' },
    ],
    calls: [],
    loading: false,
  }),
  methods: {
    phoneMask,
    getContactInitials,
    toFormatDate,
    getCalls(page = 1) {
      this.loading = true
      const modifyCall = calls => {
        return calls.data?.map(call => {
          call.userId = call.userId || '---'
          call.telephony = call.telephony.name || '---'
          call.phone = call.phone || '' || '---'
          call.startTime = call.startTime || '---'
          call.endTime = call.endTime || '---'
          call.statusCall = call.status_call?.name || '---' //fix
          call.type_call = call.type_call.name || '---'
          call.fullName = getContactInitials(call.user, { isResponsible: true })
          return call
        })
      }

      return this.$axios
        .get(urlGetCalls(page, this.filtersQueryObj))
        .then(v => {
          this.calls.splice(0)
          this.calls.push(...modifyCall(v.data))
          this.loading = false
          return v
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
    openCallsDialog(item) {
      this.$store.commit('setDialog', {
        title: 'Деталі по дзвінку',
        dialogItem: item,
        action: 'phone-call-dialog',
        params: { hideActionsButtons: true },
      })
    },
    navigateToCall(e, row) {
      this.openCallsDialog(row.item)
    },
    closeFilter() {
      this.filter = !this.filter
      for (const filter in this.filters) {
        if (this.filters[filter]) this.filters[filter] = null
      }
    },
  },
}
</script>
