<template>
  <div>
    <SectionLoader v-if="loading" />
    <v-data-table
      v-else
      :items="leadCallHistory || []"
      :headers="historyLeadCallHeaders"
      class="calc-table --cust-cell-pd mt-3"
      :hide-default-header="!leadCallHistory.length"
      :items-per-page="20">
      <template #item.startTime="{ item }">
        {{ toFormatDate(item.startTime, false, true) }}
      </template>
      <template #item.user="{ item }">
        {{ getContactInitials(item.user) }}
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { historyLeadCallHeaders } from '@/utils/tableHeaders'
import { getContactInitials, toFormatDate } from '@/utils/helperFunc'
import { urlGetLeadCallHistory } from '@/pages/request'
import SectionLoader from '@/components/section-loader.vue'

export default {
  components: { SectionLoader },
  props: {
    dialog: { type: Object },
  },
  data() {
    return {
      historyLeadCallHeaders,
      leadCallHistory: [],
      loading: false,
    }
  },
  methods: {
    getContactInitials,
    toFormatDate,
    getLeadCallHistory() {
      this.loading = true
      console.log(
        this.dialog.dialogItem,
        'this.dialog.dialogData?.detail_call?.phone'
      )
      return this.$axios
        .post(urlGetLeadCallHistory(), {
          phone: this.dialog.dialogItem?.detail_call?.phone,
        })
        .then(res => {
          Object.assign(this.leadCallHistory, res.data)
          this.loading = false
        })
    },
  },
  created() {
    this.getLeadCallHistory()
  },
}
</script>

<style scoped></style>
