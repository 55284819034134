<template>
  <svg
    class="ml-1"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 18 18"
    fill="none">
    <path
      d="M2 0H16C16.5304 0 17.0391 0.210714 17.4142 0.585786C17.7893 0.960859 18 1.46957 18 2V16C18 16.53 17.79 17.04 17.41 17.41C17.04 17.79 16.53 18 16 18H2C1.47 18 0.96 17.79 0.59 17.41C0.21 17.04 0 16.53 0 16V2C0 0.89 0.89 0 2 0ZM9.3 4.29C9.11 4.5 9 4.74 9 5C9 5.27 9.11 5.5 9.3 5.71C9.5 5.9 9.74 6 10 6C10.27 6 10.5 5.9 10.71 5.71C10.9 5.5 11 5.27 11 5C11 4.74 10.9 4.5 10.71 4.29C10.5 4.11 10.27 4 10 4C9.74 4 9.5 4.11 9.3 4.29ZM6.8 8.97C6.7 9.06 6.7 9.07 6.77 9.17L6.82 9.25L6.85 9.31C6.92 9.44 6.93 9.44 7.04 9.36C7.17 9.27 7.39 9.13 7.76 8.91C8.68 8.32 8.5 9 8.09 10.5C7.87 11.33 7.59 12.39 7.38 13.62C7.14 15.37 8.71 14.47 9.12 14.2C9.5 13.96 10.44 13.3 10.66 13.15L10.7 13.13C10.82 13.04 10.77 13 10.68 12.86L10.62 12.78C10.54 12.67 10.46 12.75 10.46 12.75L10.3 12.86C9.85 13.16 9.23 13.59 9.13 13.25C9.04 13 9.41 11.64 9.79 10.25C9.96 9.64 10.13 9 10.26 8.47L10.28 8.41C10.35 7.97 10.5 7.12 9.77 7.18C8.97 7.25 6.8 8.97 6.8 8.97Z"
      fill="#838282" />
  </svg>
</template>
