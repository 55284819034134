<template>
  <div class="pt-0 pb-0 pr-3">
    <AnalyzeService
      hideFin
      :analyzeData="FDAnalyze"
      :securityResult="securityResults"
      :legalResult="legalResults"
      @download-printed-form="service => downloadPrintedForm(service)">
      <template #riskDocuments>
        <div>
          <FilesUploader
            label="Параметри угоди"
            readonly
            :documents="FDAnalyze.project.riskResult.agreementParameters" />
          <FilesUploader
            label="Рейтинг"
            readonly
            :documents="FDAnalyze.project.riskResult.nbuRanking" />
          <FilesUploader
            label="Аналіз клієнта"
            readonly
            :documents="FDAnalyze.project.riskResult.templateClientAnalysis" />
        </div>
      </template>
    </AnalyzeService>
  </div>
</template>
<script>
import FilesUploader from '@/components/FilesUploader.vue'
import { getContactInitials, getSafe, toFormatDate } from '@/utils/helperFunc'
import {
  urlSecurityServicePrintedForm as securityPrintedForm,
  urlCreateLegalDepartmentPrintedForm as legalPrintedForm,
} from '@/pages/request'
import AnalyzeService from '@/components/AnalyzeService.vue'
export default {
  components: { AnalyzeService, FilesUploader },
  props: {
    FDAnalyze: { type: Object },
  },
  computed: {
    securityResults() {
      const results = []
      const s = this.FDAnalyze?.project?.securityResult
      if (!s) return results

      results.push({
        name: `${s.client.name} (Клієнт)`,
        finishDate: s.client.finishDate,
        responsible: s.responsible,
        result: s.client.result?.name || '---',
        status: s.status,
      })
      if (s.guarantors) {
        s.guarantors.forEach(g => {
          results.push({
            name: `${g.name} (Поручитель)`,
            finishDate: g.finishDate,
            responsible: s.responsible,
            result: g?.result?.name || '---',
            status: s.status,
          })
        })
      }
      if (s.providers) {
        s.providers.forEach(p => {
          results.push({
            name: `${p.name} (Постачальник)`,
            finishDate: p.finishDate,
            responsible: s.responsible,
            result: p?.result?.name || '---',
            status: s.status,
          })
        })
      }

      return results
    },
    legalResults() {
      const results = []
      const l = this.FDAnalyze.project.legalResult
      if (!l) return results

      results.push({
        name: `${l.client.name} (Клієнт)`,
        finishDate: l.client?.finishDate,
        responsible: l.responsible,
        conclusion: l.generalConclusion,
        status: l.status,
      })

      if (l.guarantors) {
        l.guarantors
          .filter(g => g.guarantorTypeId === 2)
          .forEach(g => {
            results.push({
              name: `${g.name} (Поручитель)`,
              finishDate: g.finishDate,
              responsible: l.responsible,
              conclusion: g.guarantorConclusion,
              status: l.status,
            })
          })
      }
      if (l.providers) {
        l.providers.forEach(p => {
          results.push({
            name: `${p.name} (Постачальник)`,
            finishDate: p.finishDate,
            responsible: l.responsible,
            conclusion: p.providerConclusion,
            status: l.status,
          })
        })
      }

      return results
    },
  },
  methods: {
    getSafe,
    getContactInitials,
    toFormatDate,
    securityPrintedForm, // used in urlPrintedForm
    legalPrintedForm, // used in urlPrintedForm
    async downloadPrintedForm(service, baseUrl) {
      const serviceId =
        service === 'security'
          ? this.FDAnalyze?.project?.securityResult?.id
          : this.FDAnalyze?.project?.legalResult?.id
      const urlPrintedForm = this[`${service}PrintedForm`]
      const { data } = await this.$axios.get(urlPrintedForm(serviceId))
      const url = data.url
      if (!url) return

      const link = document.createElement('a')
      const fileName = url.split('/').pop()
      if (baseUrl) {
        link.href = `${baseUrl}${url}`
      } else link.href = `${url}`
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
      window.URL.revokeObjectURL(link.href)
    },
  },
}
</script>

<style lang="scss" scoped>
.card-block-title {
  display: block;
  color: #08487a;
  font-weight: 600;
  font-size: 16px;
  margin-left: 9px;
}
.v-data-table tbody tr {
  background: #fff !important;
}
</style>
