<template>
  <div>
    <div v-if="verificationList">
      <Filters :filters="filters" class="ml-2" />
      <SectionLoader v-if="loading"></SectionLoader>
      <v-fade-transition hide-on-leave>
        <div v-show="!loading">
          <v-data-table
            :headers="leasingCommitteeListHeaders"
            :items="leasingCommitteeProjects"
            class="calc-table application-table --cust-cell-pd"
            mobileBreakpoint="750"
            dense
            hide-default-footer
            :items-per-page="-1"
            :hide-default-header="!leasingCommitteeProjects.length"
            @dblclick:row="navigateToKlp">
            <!-- eslint-disable-next-line -->
            <template #item.date="{ item }">
              {{ toFormatDate(item.date) }}
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.riskManager="{ item }">
              {{
                getSafe(() =>
                  getContactInitials(item.riskManagerFio, { byName: true })
                ) || '-'
              }}
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.frontManager="{ item }">
              {{
                getSafe(() =>
                  getContactInitials(item.frontManagerFio, { byName: true })
                ) || '-'
              }}
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.status="{ item }">
              {{ getSafe(() => item.status.name) || '---' }}
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.result="{ item }">
              {{ getSafe(() => item.result?.name) || '---' }}
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.analysisType="{ item }">
              <div class="d-flex align-center">
                <Benefit v-if="item.bestBenefit" class="mr-1" />
                <ProgramFSN v-if="item.programFSN" class="mr-1" />
                <span>{{ item?.analysisType?.name }}</span>
              </div>
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.lessee="{ item }">
              <ALink
                :text="
                  item.lessee.lesseeTypeId === 3
                    ? `ФОП ${item.lesseeName}`
                    : item.lesseeName
                "
                :route="
                  getEntityRoute(item.lessee.lesseeId, item.lessee.lesseeTypeId)
                ">
              </ALink>
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.actions="{ item }">
              <TableCrud
                style="margin-right: -10px"
                :actionsArray="[
                  {
                    action: () =>
                      $router.push({
                        name: 'leasing-committee-leasing-object',
                        params: { id: item.id, idx: 1 },
                      }),
                    text: 'Відкрити',
                    icon: 'mdi-folder-open-outline',
                  },
                ]">
              </TableCrud>
            </template>
          </v-data-table>
          <Pagination
            :getItems="getLeasingCommitteeAnalyzes"
            :trigger="paginationTrigger" />
        </div>
      </v-fade-transition>
    </div>
    <router-view v-if="!verificationList" @update="getLeasingCommitteeAnalyzes">
    </router-view>
  </div>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'

import ALink from '@/components/Link.vue'
import Pagination from '@/components/Pagination.vue'
import Filters from '@/components/Filters.vue'
import { urlGetLeasingCommitteeAnalyzes } from '@/pages/request'
import {
  getContactInitials,
  getEntityRoute,
  getSafe,
  toFormatDate,
} from '@/utils/helperFunc'
import { useFilter } from '@/utils/mixins/useFilter'
import { reactive } from 'vue'
import TableCrud from '@/components/table-crud.vue'
import { leasingCommitteeListHeaders } from '@/utils/tableHeaders'
import ProgramFSN from '@/assets/png/programFSN.vue'
import Benefit from '@/assets/svg/benefit.vue'
export default {
  components: {
    Benefit,
    ProgramFSN,
    TableCrud,
    SectionLoader,

    ALink,
    Pagination,
    Filters,
  },
  setup() {
    const filters = reactive({
      search: { value: null },
      statusId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { label: 'Статус', items: 'klpStatuses' },
      },
      resultId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Участники',
          items: 'klpTypes',
        },
      },
      riskManagerId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Ризик менеджер',
          itemTitle: u => getContactInitials(u, { isResponsible: true }),
          items: 'riskManagers',
        },
      },
      frontManagerId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Фронт менеджер',
          itemTitle: u => getContactInitials(u, { isResponsible: true }),
          items: 'frontManagers',
        },
      },
      startDate: {
        value: null,
        component: 'DatePicker',
        attrs: { label: 'Період', range: true },
      },
    })
    return {
      ...useFilter(filters),
      filters,
    }
  },
  data() {
    return {
      leasingCommitteeProjects: [],
      loading: false,
      leasingCommitteeListHeaders,
    }
  },
  computed: {
    verificationList() {
      return this.$route.name === 'leasing-committee-projects'
    },
  },
  methods: {
    getSafe,
    getContactInitials,
    getEntityRoute,
    toFormatDate,
    navigateToKlp(e, row) {
      this.$router.push({
        name: 'leasing-committee-leasing-object',
        params: { id: row.item.id, idx: 1 },
      })
    },
    getLeasingCommitteeAnalyzes(page = 1) {
      this.loading = true
      return this.$axios
        .get(
          urlGetLeasingCommitteeAnalyzes({
            page,
            filters: this.filtersQueryObj,
          })
        )
        .then(res => {
          this.leasingCommitteeProjects.splice(0)
          this.leasingCommitteeProjects.push(...res.data.data)
          return res
        })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    },
  },
}
</script>
