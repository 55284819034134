<template>
  <div class="pl-2">
    <v-btn
      style="
        text-transform: none;
        background: #08487a;
        border-radius: 4px;
        font-weight: 600;
        color: #fff;
        border: 2px solid #08487a;
      "
      :loading="loading"
      :disabled="status.id === 1 && projectData.scoring?.length"
      @click="createScoring">
      Отримати рішення
    </v-btn>
    <div class="mt-5">
      <div
        v-for="(sc, idx) in sortedData"
        :key="sc.idx"
        style="position: relative">
        <OpenableList
          :title="`Рішення №${idx + 1} (${agreementsStatus(sc)})`"
          hideText>
          <div
            class="d-flex justify-end"
            style="position: absolute; right: 0; top: 0">
            <TableCrud
              color="white"
              customIcon="Printer"
              :actionsArray="printedFormActions(sc)" />
          </div>
          <div>
            <div>
              <span style="margin-left: 5px"
                >Було оформлено:
                {{ toFormatDate(sc[0].created_at) || 'дата вiдсутня' }}
              </span>
            </div>
            <div>
              <v-stepper :model-value="getStepStatus(sc)" class="stepper">
                <v-stepper-header>
                  <v-stepper-item complete value="1" title="На розгляді">
                  </v-stepper-item>

                  <v-divider></v-divider>

                  <v-stepper-item
                    :complete="getStepStatus(sc) >= 3"
                    :value="2"
                    title="Аналіз">
                  </v-stepper-item>

                  <v-divider></v-divider>

                  <v-stepper-item :value="3" title="Завершено"></v-stepper-item>
                </v-stepper-header>
              </v-stepper>
            </div>
          </div>
          <v-data-table
            :headers="getTHead(sc)"
            :items="sc"
            class="calc-table application-table --cust-cell-pd mb-3"
            mobileBreakpoint="750"
            dense
            hide-default-footer
            :items-per-page="-1"
            :hide-default-header="!sc.length"
            @dblclick:row="navigateToScoring">
            <!-- eslint-disable-next-line -->
            <template #item.responsible="{ item }">
              {{ getSafe(() => getResponsibleShortName(item.responsible)) }}
            </template>
            <template #item.scoringServiceComment="{ item }">
              <v-menu v-if="item.scoringServiceComment" left offset-x>
                <template #activator="{ props }">
                  <v-btn icon class="conclusion" v-bind="props">
                    <EyeIcon />
                  </v-btn>
                </template>
                <div class="pa-5" style="background: #fff">
                  {{ item.scoringServiceComment }}
                </div>
              </v-menu>
              <span v-else>---</span>
            </template>
            <template #item.isAgree="{ item }">
              <div class="d-flex align-center">
                <span>
                  {{
                    item.isAgree && item.endTime
                      ? 'Погоджено '
                      : item.isAgree === false && item.endTime
                      ? 'Не погоджено'
                      : item.isAgree === false && !item.endTime
                      ? '---'
                      : 'На розглядi'
                  }}</span
                >
                <div v-if="item.endTime">
                  <span>( {{ toFormatDate(item.endTime, false) }})</span>
                  <span>( Строк: {{ getBeautyNum(item.workTime) }} хв)</span>
                </div>
              </div>
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.actions="{ item }">
              <TableCrud
                :actionsArray="[
                  {
                    action: () =>
                      $router.push({
                        name: `${getService(item.entityTypeId)}-scoring-single`,
                        params: { id: item.id },
                      }),
                    text: 'Відкрити',
                    icon: 'mdi-folder-open-outline',
                  },
                ]">
              </TableCrud>
            </template>
          </v-data-table>
          <!--card documents-->
          <OpenableList title="загруженi документи">
            <div>
              <FilesUploader
                v-model:documents="sc[0].financialDocument"
                readonly
                :label="
                  projectData.application.lesseeTypeId === 3
                    ? 'Податкова декларація'
                    : 'Баланс - Ф1 і Ф2'
                " />
              <FilesUploader
                v-model:documents="sc[0].innDocument"
                readonly
                label="ІПН" />
              <FilesUploader
                v-model:documents="sc[0].passportDocument"
                readonly
                label="Паспорт" />
            </div>
          </OpenableList>

          <div>
            <v-textarea
              v-model="sc[0].comment"
              class="pt-2"
              :rows="3"
              hide-details
              readonly
              placeholder="Коментар Відсутній">
            </v-textarea>
          </div>
        </OpenableList>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getResponsibleShortName,
  toFormatDate,
  download,
  LODescription as getDescription,
  getBeautyNum,
  getSafe,
} from '@/utils/helperFunc'
import {
  urlScoringGuaranteePrintedForm,
  urlScoringAdvancePrintedForm,
} from '@/pages/request'
import OpenableList from '@/components/OpenableList.vue'
import { mapState } from 'vuex'
import FilesUploader from '@/components/FilesUploader.vue'
import EyeIcon from '@/assets/svg/eye-icon.vue'
import TableCrud from '@/components/table-crud.vue'
export default {
  name: 'Scoring',
  components: {
    TableCrud,
    EyeIcon,
    FilesUploader,
    OpenableList,
  },
  emits: ['setScoring'],
  props: {
    projectData: { type: Object },
  },
  data() {
    return {
      loading: false,
      documentTypesMap: {
        guarantee: 'Гарантійний лист',
        advance: 'Рахунок на аванс',
      },
      commentDialog: false,
    }
  },
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
      user: state => state.user,
    }),
    sortedData() {
      const groupedData = this.projectData.scoring.reduce((result, obj) => {
        const { batch, ...data } = obj
        if (!result[batch]) {
          result[batch] = []
        }
        result[batch].push(data)
        return result
      }, {})

      const sortedData = Object.values(groupedData).reduce((result, arr) => {
        while (arr.length >= 3) {
          result.push(arr.splice(0, 3))
        }
        return result
      }, [])

      return sortedData
    },
    status() {
      switch (true) {
        case this.projectData.scoring.reduce(
          (acc, cur) => (cur.isAgree === true ? acc + 1 : acc),
          0
        ) === 3:
          return { id: 3, name: 'Погоджено' }
        case this.projectData.scoring.reduce(
          (acc, cur) => (cur.endTime ? acc + 1 : acc),
          0
        ) === 3:
          return { id: 2, name: 'Не погоджено' }
        default:
          return { id: 1, name: 'Аналіз' }
      }
    },
  },
  methods: {
    getSafe,
    getBeautyNum,
    toFormatDate,
    urlScoringGuaranteePrintedForm,
    urlScoringAdvancePrintedForm,
    getResponsibleShortName,
    printedFormActions(sc) {
      return this.projectData.calculations.map(calculation => {
        const calc = {
          name: getDescription(calculation).name,
          id: calculation.id,
        }
        const printedForms = [
          {
            action: () => this.downloadPrintedForm('guarantee', calc),
            text: 'Гарантійний лист',
            icon: '',
            disabled: sc.some(item => !item.isAgree),
          },
          {
            action: () => this.downloadPrintedForm('advance', calc),
            text: 'Рахунок на аванс',
            icon: '',
            disabled: this.status.id !== 3,
          },
        ]
        return {
          text: calc.name,
          icon: 'mdi-car',
          disabled: false,
          actions: printedForms,
        }
      })
    },
    downloadPrintedForm(documentType, calc) {
      const url = this[`urlScoring${documentType.capitalize()}PrintedForm`]

      const fileName = `${this.documentTypesMap[documentType]} - ${
        this.projectData.lesseeShortName || ''
      }/${calc.name.replace(/\./g, '')}`

      return this.$axios.get(url(calc.id)).then(res => {
        return download(res.data.url, fileName)
      })
    },
    agreementsStatus(scoring) {
      const isDone = scoring.reduce((acc, cur) => {
        return acc ? !!cur.endTime : acc
      }, true)

      if (isDone) {
        const isAgree = scoring.reduce((acc, cur) => {
          return acc ? cur.isAgree : acc
        }, true)

        return isAgree ? 'Позитивне рішення' : 'Негативне рішення'
      } else {
        return 'На розгляді'
      }
    },
    getStepStatus(scoring) {
      const endTimeCount = scoring.filter(obj => obj.endTime !== null).length

      if (endTimeCount === scoring.length) {
        return 3
      } else if (endTimeCount >= 2) {
        return 2
      } else if (endTimeCount === 1) {
        return 2
      } else {
        return 0
      }
    },
    getResponsible(responsibleId) {
      const r = this.$getSafe(() =>
        this.selectItems.allUsers.find(u => u.id === responsibleId)
      )
      if (!r) return '---'
      return `${r?.surname || '-'} ${r?.name || '-'} ${r?.patronymic || '-'}`
    },
    getService(entityTypeId) {
      switch (entityTypeId) {
        case 10:
          return 'security'
        case 11:
          return 'risk'
        case 9:
          return 'fin'
        default:
          return 'incorrect route name'
      }
    },
    navigateToScoring(e, row) {
      return this.$router.push({
        name: `${this.getService(row.item.entityTypeId)}-scoring-single`,
        params: { id: row.item.id, tabState: 'scoring' },
      })
    },
    getTHead() {
      return [
        {
          title: 'Служба',
          key: 'entity_type.name',
          align: 'start',
          sortable: false,
        },
        {
          title: 'Відповідальний',
          key: 'responsible',
          align: 'start',
          sortable: false,
        },
        { title: 'Рішення', key: 'isAgree', align: 'start', sortable: false },
        {
          title: 'Коментар',
          key: 'scoringServiceComment',
          align: 'start',
          sortable: false,
        },
      ]
    },
    createScoring() {
      this.loading = true
      this.$store.commit('setDialog', {
        title: 'Отримати рішення',
        dialogItem: this.projectData,
        params: {
          dialogWidth: '850',
          submitText: 'Відправити',
          hideRejectButton: true,
          calculations: this.projectData.calculations,
          sliderId: this.projectData.id,
          lesseeTypeId: this.projectData.application.lesseeTypeId,
          cb: res => {
            this.$emit('setScoring', res.data)
            this.$setSnackbar({ text: 'Заявку передано на розгляд в служби' })
          },
        },
        action: 'scoring-create',
      })
      this.loading = false
    },
  },
}
</script>

<style>
.stepper-step .v-application .primary {
  background: red !important;
}
</style>

<style lang="scss" scoped>
.stepper {
  background: transparent !important;
}
.v-stepper {
  border-radius: 0;
  box-shadow: none !important;
}
.v-stepper-item--selected .v-stepper-item__avatar.v-avatar,
.v-stepper-item--complete .v-stepper-item__avatar.v-avatar {
  background-color: #fc7247 !important;
  border-color: #fc7247 !important;
}
.conclusion {
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
}
</style>
