<template>
  <div>
    <span class="label">Вулиця/Проспект/Провулок</span>
    <v-autocomplete
      v-model="street"
      v-mask:[mask]
      :items="streets"
      :item-title="s => getStreetTitle(s)"
      item-value="StreetId"
      :error-messages="errorMessages"
      :loading="$loading.isLoading('getStreets')"
      :readonly="!streetMoniker"
      placeholder="оберіть зі списку"
      no-filter
      return-object
      dense
      hide-details
      @update:search="getStreets"
      @blur="$emit('blur')">
      <template #item="{ item, props }">
        <v-list-item v-bind="props" :title="getStreetTitle(item.raw, true)">
        </v-list-item>
      </template>
      <template v-if="street" #prepend-inner>{{ street.StreetType }}</template>
      <template
        v-if="street?.StreetOld && street.StreetOld !== street.Street"
        #append>
        <toggle-old-name
          v-model="street.useOldStreet"
          :new-name="street.Street"
          :old-name="street.StreetOld"></toggle-old-name>
      </template>
    </v-autocomplete>
  </div>
</template>

<script lang="ts">
import { computed, PropType, ref } from 'vue'
import { Street } from '@/utils/types'
import { debounce } from 'lodash'
import { handleAsync } from 'best-modules/plugins'
import { getStreets as getStreetsRequest } from '@/utils/addressApi'
import ToggleOldName from './ToggleOldName.vue'
import { concatRegex, CYRILLIC, SPACE, NUMBER } from 'best-modules/utils/regExp'
import { getStreetTemplate } from '../address'
import { getAddressPartName } from '../utils'

export default {
  name: 'Street',
  components: { ToggleOldName },
  emits: ['update:model-value', 'blur'],
  props: {
    modelValue: {
      type: Object as PropType<Street>,
      required: true,
    },
    streetMoniker: {
      type: String as PropType<string>,
    },
    errorMessages: {
      type: Array as PropType<string[]>,
    },
  },
  data: () => {
    return {
      mask: {
        mask: 'A'.repeat(100),
        tokens: {
          A: { pattern: concatRegex(NUMBER, CYRILLIC, SPACE, '.', '-') },
        },
      },
    }
  },
  setup(props, { emit }) {
    const street = computed<Street | null>({
      get() {
        if (props.modelValue?.Street) {
          return props.modelValue
        }
        return null
      },
      set(v) {
        emit('update:model-value', v || getStreetTemplate())
      },
    })

    const getStreetTitle = (
      street: Street | null,
      appendStreetType?: boolean
    ) => {
      if (street) {
        return [
          appendStreetType ? street.StreetType : null,
          getAddressPartName(
            street.Street,
            street.StreetOld,
            street.useOldStreet
          ),
        ]
          .filter(Boolean)
          .join(' ')
      } else {
        return ''
      }
    }
    const streets = ref<Street[]>([])

    const getStreets = debounce((search: string) => {
      return handleAsync('getStreets', async () => {
        if (
          search &&
          search !== getStreetTitle(street.value) &&
          props.streetMoniker
        ) {
          streets.value = await getStreetsRequest(search, props.streetMoniker)
        }
      })
    }, 500)

    return { street, streets, getStreets, getStreetTitle }
  },
}
</script>

<style scoped></style>
