<template>
  <div>
    <v-fade-transition hide-on-leave>
      <div>
        <v-row>
          <div class="d-flex justify-space-between pr-3" style="width: 100%">
            <v-col cols="12" sm="12" class="d-flex align-center">
              <Filters :filters="filters" class="ml-2">
                <template #header-action>
                  <div class="d-inline-block mr-2">
                    <v-tooltip bottom text="Відобразити - Лист нагадувань">
                      <template #activator="{ props }">
                        <v-btn
                          class="activity-nav-btn rounded-circle"
                          icon
                          size="x-small"
                          v-bind="props"
                          @click="tasksView = 1">
                          <v-icon
                            :color="tasksView === 1 ? 'black' : 'grey darken-2'"
                            size="23">
                            {{ 'mdi-format-list-bulleted' }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Відобразити - Лист нагадувань</span>
                    </v-tooltip>
                  </div>
                  <div class="d-inline-block">
                    <v-tooltip bottom text="Відобразити - Календар нагадувань">
                      <template #activator="{ props }">
                        <v-btn
                          class="activity-nav-btn rounded-circle"
                          icon
                          size="x-small"
                          v-bind="props"
                          @click="tasksView = 2">
                          <v-icon
                            size="23"
                            :color="
                              tasksView === 2 ? 'black' : 'grey darken-2'
                            ">
                            {{ 'mdi-calendar-month-outline' }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Відобразити - Календар нагадувань</span>
                    </v-tooltip>
                  </div>
                </template>
              </Filters>
            </v-col>
          </div>
        </v-row>
        <v-row>
          <v-col :cols="12">
            <div v-show="!pageLoad">
              <!-- eslint-disable -->
              <RemindersView
                :tasksView="tasksView"
                :tasks="tasks"
                :tHead="activitiesRemindersListHeaders">
              </RemindersView>
              <Pagination
                :getItems="FullPlanTask"
                :trigger="paginationTrigger" />
            </div>
          </v-col>
        </v-row>
      </div>
    </v-fade-transition>
    <SectionLoader v-if="pageLoad"> </SectionLoader>
  </div>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'
import RemindersView from '@/components/RemindersView.vue'
import Pagination from '@/components/Pagination.vue'
import { mapState, mapGetters } from 'vuex'
import Filters from '@/components/Filters.vue'
import {
  backDate,
  toFormatArrWithDate,
  getContactInitials,
} from '@/utils/helperFunc'
import {
  urlGetActivities,
  urlFilterActivities,
  urlGetActivityCategories,
  urlFullPlanTask,
} from '@/pages/request.js'
import { useVuelidate } from '@vuelidate/core'
import { useFilter } from '@/utils/mixins/useFilter'
import { reactive } from 'vue'
import { activitiesRemindersListHeaders } from '@/utils/tableHeaders'
import { fillDirectory, getDirectory } from '@/plugins/directory'
export default {
  components: { Pagination, SectionLoader, RemindersView, Filters },
  setup() {
    const filters = reactive({
      search: { value: null },
      isDone: {
        value: null,
        component: 'v-autocomplete',
        attrs: { label: 'Статус', items: getDirectory('meetStatuses') },
      },
      startDate: {
        value: null,
        component: 'DatePicker',
        attrs: { label: 'Період', range: true },
      },
      userId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Відповідальний',
          itemTitle: u => getContactInitials(u, { isResponsible: true }),
          items: 'activeUsers',
        },
        focus: () => fillDirectory('allUsers'),
      },
    })
    return { v$: useVuelidate(), ...useFilter(filters), filters }
  },
  data() {
    return {
      activitiesRemindersListHeaders,
      event: {
        address: null,
        typeId: null,
        startDate: null,
        endDate: null,
        invitations: [],
        description: null,
        contactId: null,
        contractorId: null,
        statusId: 1,
        resultId: null,
        documents: [],
      },
      tasks: [],
      tasksView: 1,
      id: null,
      selectedEntities: [],
      pageLoad: false,
      activityStatuses: [],
    }
  },
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    ...mapGetters({
      activeUsers: 'activeUsers',
    }),
  },
  methods: {
    backDate,
    urlGetActivities,
    urlFilterActivities,
    urlGetActivityCategories,
    toFormatArrWithDate,
    getContactInitials,
    FullPlanTask(page = 1) {
      this.pageLoad = true
      const modifyPlanTask = tasks => {
        return tasks.data?.map(task => {
          task.taskMessage = task.taskMessage || '---'
          return task
        })
      }
      return this.$axios
        .get(urlFullPlanTask(page, this.filtersQueryObj))
        .then(v => {
          this.tasks.splice(0)
          this.tasks.push(...modifyPlanTask(v.data))
          this.pageLoad = false
          return v
        })
        .catch(err => this.$err(err, () => (this.pageLoad = false)))
    },
    openDialog(action, item = {}, params = {}) {
      this.$store.commit('setDialog', {
        title: 'Подiя',
        dialogItem: item,
        params: params,
        action: action,
      })
    },
  },
}
</script>
