<template>
  <div>
    <SectionLoader v-if="loading" />
    <v-row v-else>
      <v-col :cols="12" :md="3" class="common-data pr-0">
        <div class="mb-3 left-block">
          <span
            data-v-209c32ca=""
            class="mb-3 d-block"
            style="color: rgb(8, 72, 122); font-weight: 700; font-size: 16px">
            Загальні дані
          </span>
          <div v-for="(field, idx) in commonInfoFields" :key="idx">
            <span class="label">{{ field.label }}</span>
            <span style="font-size: 14px" class="mt-1 mb-1 d-block">{{
              field.value
            }}</span>
          </div>
        </div>
      </v-col>
      <v-col :cols="12" :md="9" class="pt-0 pr-0 pb-1 pl-0">
        <div class="mb-2">
          <div class="pa-0">
            <v-tabs
              v-model="currentTab"
              class="contact-tab"
              background-color="grey darken-2"
              show-arrows
              :direction="$vuetify.breakpoint?.xs ? 'vertical' : 'horizontal'"
              :class="{ 'v-tabs--mobile': $vuetify.breakpoint?.xs }"
              dark
              slider-color="#FC7247"
              :height="$vuetify.breakpoint?.xs ? null : 38"
              dense>
              <v-tab
                replace
                :to="{
                  name: 'lo-registration-data',
                  params: { id: $route.params.id },
                }">
                Реєстрація
              </v-tab>
              <v-tab
                replace
                :to="{ name: 'lo-dfls', params: { id: $route.params.id } }">
                Договори фінансового лізингу
              </v-tab>
              <v-tab
                replace
                :to="{
                  name: 'lo-insurance-contracts',
                  params: { id: $route.params.id },
                }">
                Страхові договори
              </v-tab>
              <v-tab
                replace
                :to="{
                  name: 'lo-insurance-cases',
                  params: { id: $route.params.id },
                }">
                Страхові випадки
              </v-tab>
              <v-tab
                replace
                :to="{
                  name: 'lo-technical-assistance',
                  params: { id: $route.params.id },
                }">
                Технічний асистанс
              </v-tab>
            </v-tabs>
            <div class="pb-0 pt-3 pl-3">
              <RouterView :LO="LO" />
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { urlGetLeasingObjectSingle } from '@/pages/request'
import SectionLoader from '@/components/section-loader.vue'
export default {
  components: { SectionLoader },
  props: {
    oneCLeasingObjectId: { type: Number },
    modal: { type: Boolean },
  },
  data() {
    return {
      currentTab: 0,
      loading: false,
      LO: null,
    }
  },
  computed: {
    commonInfoFields() {
      return [
        { label: 'Реєстраційний номер', value: this.LO?.ГосНомер || '' },
        { label: 'Тип', value: this.LO?.Тип || '' },
        { label: 'Марка', value: this.LO?.Марка || '' },
        { label: 'Модель', value: this.LO?.Модель || '' },
        { label: 'Комплектація', value: this.LO?.Комплектация || '' },
        { label: 'Номер кузова/VIN -code', value: this.LO?.НомерКузова || '' },
        { label: "Об'єм", value: this.LO?.ОбъемДвигателя || '' },
        { label: 'Тип КПП', value: this.LO?.ТипКПП || '' },
        { label: 'Тип палива', value: this.LO?.ТипТоплива || '' },
        { label: 'Колір', value: this.LO?.Цвет || '' },
      ]
    },
  },
  methods: {
    getLeasingObject() {
      this.loading = true
      const objectId = this.oneCLeasingObjectId

      return this.$axios
        .get(
          urlGetLeasingObjectSingle(
            this.modal ? objectId : this.$route.params.id
          )
        )
        .then(res => {
          this.LO = res.data
          this.loading = false
        })
    },
  },
  mounted() {
    this.getLeasingObject()
  },
}
</script>

<style scoped lang="scss">
.left-block {
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  padding: 10px;
}
a.v-tab-item--selected.v-tab {
  font-weight: 700 !important;
  color: #08487a !important;
  font-size: 13px;
}
a.v-tab {
  color: #838282 !important;
  font-size: 13px;
  letter-spacing: normal;
}
.container.pa-0.container--fluid {
  padding: 0px !important;
  margin: 0px !important;
}
.v-tabs {
  & a.v-tab {
    margin-right: 10px;
  }
  &--mobile {
    padding-right: 12px;
    a.v-tab {
      margin-right: 0;
      margin-bottom: 12px;
      min-width: 100%;
      border: 1px solid #eaeaea;
      background: #eaeaea;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
      border-radius: 4px;
    }
  }
}
</style>
