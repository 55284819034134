<template>
  <div>
    <div style="font-size: 14px" class="white-block">
      <v-row>
        <v-col :cols="12" :sm="3" class="pb-1">
          <div class="card-label">
            Повна назва
            <span
              style="position: absolute;
    right: 15px;
    color: grey;
}"
              >Було створено: {{ toFormatDate(scoringData.created_at) }}</span
            >
          </div>
        </v-col>
        <v-col :cols="12" :sm="9" class="pb-1 text-start">
          <span class="card-label">
            {{ contractor.fullName || contractor.companyFullName || '---' }}
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="12" :sm="3" class="pt-1 pb-1">
          <div class="card-label">Коротка назва</div>
        </v-col>
        <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
          <span class="card-label">
            {{ contractor.shortName || '---' }}
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="12" :sm="3" class="pt-1 pb-1">
          <div class="card-label">ЄДРПОУ</div>
        </v-col>
        <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
          <span class="card-label">{{
            contractor?.code || contractor?.edrpou || '---'
          }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="12" :sm="3" class="pt-1 pb-1">
          <div class="card-label">Статутний капітал</div>
        </v-col>
        <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
          <div v-html="getAuthorizedCapital()"></div>
        </v-col>
      </v-row>

      <v-col :cols="12" class="pa-0">
        <v-row>
          <v-col :cols="12" :md="3">
            <span class="card-label">Місцезнаходження</span>
          </v-col>
          <v-col :cols="12" :md="9">
            <div class="d-flex align-center">
              <div class="card-sublable mr-1 align-center">
                <AddressIcon class="mr-1" />
                Адреса:
              </div>
              <span
                v-if="contractor.addresses && contractor.addresses.length"
                class="card-label">
                {{
                  getAddress(contractor.addresses?.find(a => a.typeId === 1)) ||
                  '---'
                }}
              </span>
              <span v-else class="card-label">
                {{ getAddress(contractor.address?.address) }}
              </span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12" :md="3" class="pt-1 pb-1"></v-col>
          <v-col :cols="12" :md="9" class="pt-1 pb-1"> </v-col>
        </v-row>
      </v-col>

      <div>
        <v-col :cols="12" class="pt-0 pb-0 pl-0">
          <v-row>
            <v-col :cols="12" :md="3" class="pt-0">
              <span class="card-label">Контактні дані</span>
            </v-col>
            <v-col :cols="12" :md="9" class="pt-0">
              <div class="pl-1 d-flex align-center">
                <div class="card-sublable mr-1 align-center">
                  <EmailIcon :width="16" class="mr-1" />
                  Телефон:
                </div>
                <span
                  v-if="
                    Array.isArray(contractor.phones) && contractor.phones.length
                  "
                  class="card-label">
                  {{
                    contractor.phones.map(phone => phone.email).join(', ') ||
                    '---'
                  }}
                </span>
                <span v-else class="card-label">{{
                  contractor.phones?.join(', ') || '---'
                }}</span>
              </div>

              <div class="pl-1 d-flex align-center">
                <div class="card-sublable mr-1 align-center">
                  <PhoneIcon :width="16" class="mr-1" />
                  Email:
                </div>
                <span
                  v-if="
                    Array.isArray(contractor.emails) && contractor.emails.length
                  "
                  class="card-label">
                  {{
                    contractor.emails.map(email => email.email).join(', ') ||
                    '---'
                  }}
                </span>
                <span v-else class="card-label">{{
                  contractor.emails?.join(', ') || '---'
                }}</span>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </div>
    </div>

    <div v-if="heads.length">
      <div
        style="
          margin: 10px 0;
          background: #fff;
          padding: 13px;
          font-size: 14px;
        ">
        <div class="mt-0 section-card">
          <div>
            <v-row>
              <v-col :cols="12" :sm="3" class="pt-1 pb-1">
                <span class="mb-2 d-block card-label"> Виконавчий орган </span>
              </v-col>
              <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
                <div v-for="director in heads" :key="director.id">
                  <div>{{ director.name }}</div>
                  <OpenableList>
                    <FilesUploader
                      readonly
                      label="Паспорт"
                      :documents="scoringData.passportDocument" />
                    <FilesUploader
                      readonly
                      label="ІПН"
                      :documents="scoringData.innDocument" />
                  </OpenableList>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="white-block" style="margin: 10px 0; font-size: 14px">
      <v-row>
        <v-col :cols="12" :sm="3" class="pt-1 pb-1">
          <div class="card-label">Директор:</div>
        </v-col>
        <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
          <span class="card-value">Данi вiдсутнi</span>
        </v-col>
      </v-row>
    </div>

    <div v-if="signatorys.length">
      <div v-if="signatorys.length">
        <div
          style="
            margin: 10px 0;
            background: #fff;
            padding: 13px;
            font-size: 14px;
          ">
          <div class="mt-0 section-card">
            <div>
              <v-row>
                <v-col :cols="12" :sm="3" class="pt-1 pb-1">
                  <span class="mb-2 d-block card-label">Підписанти:</span>
                </v-col>
                <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
                  <div v-for="s in signatorys" :key="s.name">
                    <div class="d-flex">
                      {{ s.name }}
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        style="
          background: #fff;
          padding: 13px;
          margin: 10px 0;
          font-size: 14px;
        ">
        <v-row>
          <v-col :cols="12" :sm="3" class="pt-1 pb-1">
            <div class="card-label">Підписанти:</div>
          </v-col>
          <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
            <span class="card-value">Данi вiдсутнi</span>
          </v-col>
        </v-row>
      </div>
    </div>

    <div v-if="beneficiar.length" class="white-block" style="font-size: 14px">
      <span
        class="card-label d-block mb-2"
        style="margin-bottom: -20px !important; display: block"
        >Бенефіціарні власники:</span
      >
      <div v-for="b in beneficiar" :key="b.name" class="section-card">
        <div>
          <v-row>
            <v-col :cols="12" :sm="3" class="pt-1"> </v-col>
            <v-col :cols="12" :sm="9" class="pt-1 text-start">
              <span>{{ b.name || b?.contact?.fullName }}</span>
              <v-row>
                <v-col :cols="12" :md="9" class="pb-0">
                  <div class="d-flex align-center">
                    <div class="d-flex align-center mr-1">
                      <span class="card-sublable mr-1">Частка:</span>
                      <CircleDiagram
                        :percent="b.amountPercent || b.percent || 0"
                        class="mr-1" />
                      <span
                        >{{
                          getBeautyNum(b.amountPercent || b.percent || 0, {
                            float: 6,
                            round: true,
                          })
                        }}%</span
                      >
                    </div>
                    <div v-if="b.code" class="d-flex align-center">
                      <span class="card-sublable mr-1">ЄДРПОУ</span>
                      <span class="card-sublable mr-1">{{
                        b.code || '---'
                      }}</span>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col :cols="12" :md="9" class="pt-1">
                  <div class="d-flex align-start">
                    <div class="card-sublable mr-1 align-center">
                      <AddressIcon class="mr-1" />
                      Адреса:
                    </div>
                    <span
                      v-if="
                        b?.contact?.addresses && b?.contact?.addresses.length
                      "
                      class="card-label">
                      {{
                        getAddress(
                          b?.contact?.addresses?.find(a => a.typeId === 2)
                        ) || '---'
                      }}
                    </span>
                    <span v-else class="card-label">
                      {{ b.location }}
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-divider v-if="beneficiar.length > 1" class="mt-2" dark></v-divider>
    </div>
    <div v-if="!beneficiar.length" class="white-block" style="font-size: 14px">
      <v-row>
        <v-col :cols="12" :sm="3" class="pt-1 pb-1">
          <div class="card-label">Бенефіціарні власники:</div>
        </v-col>
        <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
          <span class="card-value">Дані відсутні</span>
        </v-col>
      </v-row>
    </div>

    <div
      v-if="founders.length"
      style="
        background: #fff;
        padding: 13px;
        font-size: 14px;
        margin-top: 10px;
      ">
      <span
        class="card-label d-block mb-2"
        style="margin-bottom: -20px !important; display: block"
        >Засновники:</span
      >
      <div v-for="f in founders" :key="f.name" class="section-card">
        <div>
          <v-row>
            <v-col :cols="12" :sm="3" class="pt-1"> </v-col>
            <v-col :cols="12" :sm="9" class="pt-1 text-start">
              <span>{{ f.name }} | Засновник</span>
              <v-row>
                <v-col :cols="12" :md="9" class="pb-0">
                  <div class="d-flex align-center">
                    <div class="d-flex align-center mr-1">
                      <span class="card-sublable mr-1">Частка:</span>
                      <CircleDiagram
                        :percent="f.amountPercent || 0"
                        class="mr-1" />
                      <span
                        >{{
                          getBeautyNum(f.amountPercent || 0, {
                            float: 6,
                            round: true,
                          })
                        }}
                        %</span
                      >
                    </div>
                    <div class="d-flex align-center">
                      <span class="card-sublable d-block mr-1"
                        >Сума капіталу:</span
                      >
                      <span class="d-block mr-1"
                        >{{ formutSum(f.amount) }} грн</span
                      >
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col :cols="12" :md="9" class="pt-1">
                  <div class="d-flex align-start">
                    <div class="card-sublable mr-1 align-center">
                      <AddressIcon class="mr-1" />
                      Адреса:
                    </div>
                    <span class="card-label">
                      {{ f.location }}
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-divider class="mt-2" dark></v-divider>
    </div>
    <div v-if="!founders.length" class="white-block" style="font-size: 14px">
      <v-row>
        <v-col :cols="12" :sm="3" class="pt-1 pb-1">
          <div class="card-label">Засновники:</div>
        </v-col>
        <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
          <span class="card-value">Дані відсутні</span>
        </v-col>
      </v-row>
    </div>

    <div class="white-block" style="font-size: 14px; margin: 10px 0">
      <v-row>
        <v-col :cols="12" :sm="3" class="pt-1 pb-1">
          <div class="card-label">Основний вид діяльності</div>
        </v-col>
        <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
          <span class="card-label">
            {{ contractor.primaryActivity || contractor?.main_qued?.title }}
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="12" :sm="3" class="pt-1 pb-1">
          <div class="card-label">Платник ПДВ</div>
        </v-col>
        <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
          <span v-if="contractor?.isVatPayer" class="card-label">{{
            isVatPayer ? 'Так' : 'Ні'
          }}</span>
          <span v-else class="card-label">
            {{ getSafe(() => desiredFactor.specificText, 'Не вказано') }}
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="12" :sm="3" class="pt-1 pb-1">
          <div class="card-label">IНН</div>
        </v-col>
        <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
          <span v-if="contractor?.inn" class="card-label">
            {{ getSafe(() => contractor?.inn, 'Не вказано') }}
          </span>
          <span v-else class="card-label">
            {{ getSafe(() => desiredFactor.number, 'Не вказано') }}
          </span>
        </v-col>
      </v-row>
      <div style="margin-top: 5px">
        <v-row>
          <v-col :cols="12" :sm="3" class="pb-0">
            <div class="card-label">Фінансові показники</div>
          </v-col>
          <v-col :cols="12" :sm="9" class="pl-2 pb-0 text-start">
            <span class="card-label">
              <OpenableList
                v-for="f in finances"
                :key="f.profit"
                :title="`за ${f.year.toString()}р.`">
                <div style="margin-left: 5px">
                  <span class="card-sublable">Бухгалтер:</span>
                  <span>{{ f.buhgalter }}</span>
                </div>
                <div style="margin-left: 5px">
                  <span class="card-sublable">Дохiд:</span>
                  <span>{{ f.revenue }}грн</span>
                </div>
                <div style="margin-left: 5px">
                  <span class="card-sublable">Чистий прибуток:</span>
                  <span>{{ f.profit }}грн</span>
                </div>
                <div style="margin-left: 5px">
                  <span class="card-sublable">Активи:</span>
                  <span>{{ f.currentAssets }}грн</span>
                </div>
                <div style="margin-left: 5px">
                  <span class="card-sublable">Зобов'язання:</span>
                  <span>{{ f.liability }}грн</span>
                </div>
              </OpenableList>
            </span>
          </v-col>
        </v-row>
      </div>
      <div
        style="
          background: #fff;
          padding: 13px;
          font-size: 14px;
          margin: 10px 0;
        ">
        <v-row>
          <v-col :cols="12" :sm="3" class="pt-0 pb-0"> </v-col>
          <v-col :cols="12" :sm="9" class="pt-0 pb-0 pl-2">
            <ChangesTable :changes="changes" />
          </v-col>
        </v-row>
      </div>
      <OpenableList>
        <FilesUploader
          class="mt-2"
          label="Звітність Форма 1 та Форма 2"
          :documents="scoringData.financialDocument"
          readonly />
      </OpenableList>
    </div>
  </div>
</template>

<script>
import { formutSum } from '@/utils/formatFunc'
import CircleDiagram from '@/components/CircleDiagram.vue'
import EmailIcon from '@/assets/svg/social/email-icon.vue'
import OpenableList from '@/components/OpenableList.vue'
import PhoneIcon from '@/assets/svg/phone-icon.vue'
import FilesUploader from '@/components/FilesUploader.vue'
import AddressIcon from '@/assets/svg/Address-icon.vue'
import {
  getAddress,
  getAuthorizedCapital,
  getBeautyNum,
  getSafe,
  toFormatDate,
} from '@/utils/helperFunc'
import ChangesTable from '@/pages/projects-verification/scoring/components/changesTable.vue'

export default {
  name: 'Contractor',
  components: {
    ChangesTable,
    CircleDiagram,
    AddressIcon,
    FilesUploader,
    PhoneIcon,
    OpenableList,
    EmailIcon,
  },
  props: {
    contractor: { type: Object },
    desiredFactor: { type: Object },
    client: { type: Object },
    scoringData: { type: Object },
  },
  computed: {
    finances() {
      return this.scoringData.leasingClient.contractorData?.financialStatement
    },
    founders() {
      if (
        this.contractor &&
        this.contractor.founders &&
        this.contractor.founders.length
      ) {
        return this.contractor.founders
      }
      if (this.contractor && this.contractor.beneficiaries) {
        return this.contractor.beneficiaries.filter(
          beneficiary => beneficiary.role === 'засновник'
        )
      }
      return []
    },
    beneficiar() {
      if (
        this.contractor &&
        this.contractor.beneficiar_owners &&
        this.contractor.beneficiar_owners.length
      ) {
        return this.contractor.beneficiar_owners
      }
      if (this.contractor && this.contractor.beneficiaries) {
        return this.contractor.beneficiaries.filter(
          beneficiary => beneficiary.role === 'Кінцевий бенефіціарний власник'
        )
      }
      return []
    },
    heads() {
      if (this.contractor?.heads) {
        return this.contractor.heads
          .filter(beneficiary => beneficiary.role === 'керівник')
          .map((b, idx) => {
            return {
              id: idx + 1,
              name: b.name,
              post: b.role,
            }
          })
      }
      if (this.contractor?.employees) {
        return (
          this.contractor.employees
            ?.filter(e => {
              return e.isExecutiveAuthority
            })
            .map(e => {
              return {
                id: e.id,
                name: e.contact?.fullName,
                post: e?.employee_post?.name,
              }
            }) || []
        )
      }
      return []
    },
    signatorys() {
      if (this.contractor && this.contractor.heads) {
        return this.contractor.heads.filter(
          beneficiary => beneficiary.role === 'підписант'
        )
      }
      return []
    },
    changes() {
      const factors = this.scoringData.leasingClient?.contractorData?.factors
      if (factors) {
        const edrFactor = factors.find(factor => factor.factorGroup === 'edr')
        if (edrFactor) {
          return edrFactor.items
        }
      }
      return []
    },
  },
  methods: {
    getSafe,
    getAuthorizedCapital,
    getBeautyNum,
    getAddress,
    formutSum,
    toFormatDate,
  },
}
</script>

<style scoped>
.card-label {
  color: #000 !important;
}
.card-value {
  color: #838282 !important;
}
.card-sublable {
  color: #838282;
  font-weight: 500;
  font-size: 13px;
}
</style>
