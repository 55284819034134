<template>
  <div ref="fakeClick">
    <v-row v-if="dialog.params.type === 2">
      <v-col cols="12" md="12">
        <div class="d-flex align-center" style="justify-content: space-between">
          <div class="d-block" style="flex-direction: column">
            <span class="label">Від кого:</span>
            <div class="user mt-2">
              <v-avatar
                v-if="sms.senderEmail === 1"
                size="25px"
                color="indigo"
                style="margin-right: 7px"
                class="pb-0">
                <v-icon v-if="!dialog.dialogItem.photo" color="white">
                  {{ 'mdi-account-circle' }}
                </v-icon>
                <img
                  v-if="dialog.dialogItem.photo"
                  :src="dialog.dialogItem.photo" />
              </v-avatar>
              <span style="font-weight: 600; color: rgb(7 55 96)">
                {{
                  sms.senderEmail === 2
                    ? `service@bestleasing.com.ua`
                    : `${
                        dialog.dialogItem.resEmail +
                        ` (${dialog.dialogItem.name})`
                      }`
                }}
              </span>
            </div>
          </div>
          <div>
            <v-checkbox
              v-model="sms.senderEmail"
              :true-value="2"
              :false-value="1"
              dense
              hide-details>
              <template #label>
                <span style="font-size: 12px"> Від компанiї </span>
              </template>
            </v-checkbox>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <span class="label">Кому</span>
        <v-select
          v-model="sms.emails"
          :items="emails"
          multiple
          placeholder="Оберіть одержувача"
          dense
          small-chips
          hide-details>
        </v-select>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <span class="label">Тема повідомлення</span>
        <v-text-field
          v-model="sms.title"
          placeholder="Введіть текст"
          dense
          :error-messages="titleErr"
          hide-details>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <editor
          :id="editorId"
          style="border-radius: 5px"
          :api-key="key"
          :init="editorConfig(editorId, userSigns)"
          @on-init="customizeEditor($event)" />
      </v-col>
    </v-row>

    <v-row v-if="[1, 3].includes(dialog.params.type)">
      <v-col cols="12" md="12" sm="12">
        <span class="label">Кому</span>
        <v-select
          v-model="sms.phones"
          :items="phones"
          multiple
          placeholder="Оберіть одержувача"
          dense
          small-chips
          hide-details>
        </v-select>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <span class="label">Повідомлення</span>
        <v-textarea
          v-model="sms.message"
          :maxlength="1000"
          dense
          hide-details
          placeholder="Введіть текст повідомлення"
          :error-messages="messageErr">
        </v-textarea>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import { editorConfig, setHtml, getHtml } from '@/utils/tinymce-config.js'
import { setErrHandler } from '@/utils/helperFunc'
import { urlSendSms } from '@/pages/request'
import { mapState } from 'vuex'
import { minLength, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
export default {
  components: {
    editor: Editor,
  },
  setup() {
    return { v$: useVuelidate() }
  },
  props: {
    dialog: { type: Object },
  },
  validations() {
    return {
      sms: {
        message:
          this.dialog.params.type === 1
            ? { required, minLength: minLength(3) }
            : {},
        title:
          this.dialog.params.type === 2
            ? { required, minLength: minLength(3) }
            : {},
      },
    }
  },
  data: () => {
    return {
      sms: {
        message: null,
        senderEmail: 1,
      },
      editorInit: false,
      editorId: 'tiny-send-offer',
      userSigns: [],
    }
  },
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    validation() {
      return { v$: this.v$.sms, name: 'sendSms' }
    },
    key() {
      return import.meta.env.VITE_API_KEY_TINYMCE
    },
    messageErr() {
      return setErrHandler(this.v$.sms?.message)
    },
    titleErr() {
      return setErrHandler(this.v$.sms?.title)
    },
    emails() {
      return this.dialog.params.entityTypeId === 4
        ? [this.dialog.dialogItem.emails]
        : this.dialog.dialogItem.emails.map(obj => obj.email)
    },
    phones() {
      return this.dialog.params.entityTypeId === 4
        ? [this.dialog.dialogItem.phones]
        : this.dialog.dialogItem.phones.map(obj => obj.phone)
    },
  },
  methods: {
    setHtml,
    getHtml,
    editorConfig,
    submit() {
      return this.sendSms()
    },
    sendSms() {
      const req = Object.clone(this.sms)
      req.typeId = this.dialog.params.type
      req.entityId = this.dialog.dialogItem.entityId
      req.entityTypeId = this.dialog.params.entityTypeId
      req.message = this.getHtml(this.editorId) || req.message
      if (this.dialog.params.type === 1) {
        delete req.emails
      }
      if (this.dialog.params.type === 2) {
        delete req.phones
        req.senderEmail = this.sms.senderEmail
      }
      return this.$axios
        .post(urlSendSms(), req)
        .then(() => {
          this.$setSnackbar({ text: 'Повідомлення надiслане' })
        })
        .catch(this.$err)
    },
    initializeEditor() {
      this.editorInit = true
      this.getIframe()
    },
    getIframe() {
      const closeAutocomplete = () => this.$refs.fakeClick.click()

      setTimeout(() => {
        const el = document.getElementById('tiny-send-offer_ifr')
        if (el) {
          el.contentWindow.addEventListener('click', closeAutocomplete)
        } else {
          console.error('iframe not found')
        }
      }, 500)
    },
    customizeEditor() {
      setTimeout(() => {
        setTimeout(() => {
          document.getElementById(this.editorId).style.display = 'none'
          this.editorInit = !this.editorInit
        }, 0)
      }, 0)
    },
  },
  watch: {
    editorInit(state) {
      if (state) {
        this.setHtml('', this.editorId, this.initializeEditor, this.userSigns)
      }
    },
    phones: {
      handler(val) {
        if (val?.length === 1) this.sms.phones = val
      },
      immediate: true,
    },
    emails: {
      handler(val) {
        if (val?.length === 1) this.sms.emails = val
      },
      immediate: true,
    },
  },
}
</script>
