<template>
  <div>
    <v-tabs
      v-model="currentTab"
      class="contact-tab mt-3"
      background-color="grey darken-2"
      show-arrows
      color="white"
      dark
      :height="$vuetify.breakpoint?.xs ? null : 38"
      :direction="$vuetify.breakpoint?.xs ? 'vertical' : 'horizontal'"
      :class="{ 'v-tabs--mobile': $vuetify.breakpoint?.xs }"
      slider-color="red"
      dense>
      <v-tab @click="$router.replace({ name: 'contractor-history-phone' })"
        >Iсторiя дзвiнкiв</v-tab
      >
      <v-tab @click="$router.replace({ name: 'contractor-history-scheduler' })"
        >Нагадування</v-tab
      >
      <v-tab @click="$router.replace({ name: 'contractor-history-comment' })"
        >Коментар</v-tab
      >
    </v-tabs>
    <div class="pb-0 pt-1 pl-0 pr-0">
      <RouterView
        :callingHistoryItems="callingHistoryItems"
        :contractor="contractor">
      </RouterView>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: { contractor: { type: Object } },
  data: () => ({
    callingHistoryItems: null,
    activities: [],
    filter: false,
    statusId: null,
    startDate: null,
    endDate: null,
    requestDone: false,
    currentTab: 0,
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
  },
}
</script>

<style scoped lang="scss">
.v-tabs {
  & a.v-tab {
    margin-right: 25px;
  }
  &--mobile {
    padding-right: 12px;
    a.v-tab {
      margin-right: 0;
      margin-bottom: 12px;
      min-width: 100%;
      border: 1px solid #eaeaea;
      background: #eaeaea;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
      border-radius: 4px;
    }
  }
}
</style>
