<template>
  <div>
    <SectionLoader v-if="loading"></SectionLoader>
    <v-fade-transition>
      <div v-if="!loading && application && application.id">
        <div class="pb-0">
          <v-row class="align-center">
            <v-col cols="12" md="8" sm="12">
              <div
                class="d-flex justify-space-between"
                :class="{ 'flex-column w-auto': $vuetify.breakpoint?.xs }">
                <div
                  style="
                    display: flex !important;
                    align-items: center !important;
                    max-width: 100%;
                  ">
                  <v-tabs
                    v-model="currentTab"
                    show-arrows
                    class="contact-tab"
                    background-color="grey darken-2"
                    :class="{ 'v-tabs--mobile': $vuetify.breakpoint?.xs }"
                    :direction="
                      $vuetify.breakpoint?.xs ? 'vertical' : 'horizontal'
                    "
                    color="white"
                    dark
                    slider-color="red"
                    :height="$vuetify.breakpoint?.xs ? null : 38"
                    style="display: flex; !important">
                    <v-tab
                      class="v-tab"
                      replace
                      :to="{
                        name: 'slider-main-info',
                        params: {
                          applicationId: $route.params.applicationId,
                          sliderId: $route.params.sliderId,
                        },
                      }">
                      Дані по запиту
                    </v-tab>
                    <v-tab
                      class="v-tab"
                      replace
                      :to="{
                        name: 'slider-client',
                        params: {
                          applicationId: $route.params.applicationId,
                          sliderId: $route.params.sliderId,
                        },
                      }">
                      Клієнт
                    </v-tab>
                    <v-tab
                      class="v-tab"
                      replace
                      :to="{
                        name: 'slider-leasing-objects',
                        params: {
                          applicationId: $route.params.applicationId,
                          sliderId: $route.params.sliderId,
                        },
                      }">
                      Обʼєкти лізингу
                    </v-tab>
                    <v-tab
                      class="v-tab"
                      replace
                      :to="{
                        name: 'slider-scoring',
                        params: {
                          applicationId: $route.params.applicationId,
                          sliderId: $route.params.sliderId,
                        },
                      }">
                      Попереднє рішення
                    </v-tab>
                    <v-tab
                      class="v-tab"
                      replace
                      :to="{
                        name: 'slider-guarantors',
                        params: {
                          applicationId: $route.params.applicationId,
                          sliderId: $route.params.sliderId,
                        },
                      }">
                      Поручителі
                    </v-tab>
                    <v-tab
                      class="v-tab"
                      replace
                      :to="{
                        name: 'slider-subtenants',
                        params: {
                          applicationId: $route.params.applicationId,
                          sliderId: $route.params.sliderId,
                        },
                      }">
                      Суборендатори
                    </v-tab>
                  </v-tabs>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="4" sm="12">
              <v-row
                v-if="!project.readOnly && !$route.query.frame"
                :class="{ 'flex-column': $vuetify.breakpoint?.xs }"
                style="flex: none; margin-right: 20px">
                <v-col cols="12" md="6" sm="12">
                  <v-btn
                    :loading="sendToMiddleLoading"
                    style="
                      text-transform: none;
                      background: #ffffff;
                      border: 2px solid #08487a;
                      border-radius: 4px;
                      color: #08487a !important;
                      font-weight: 600;
                      margin-right: 10px;
                    "
                    block
                    @click="submit('sendToMiddle')">
                    {{ hasProject ? 'Оновити проект' : 'Створити проект' }}
                  </v-btn>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-btn
                    :loading="sliderLoading"
                    style="
                      text-transform: none;
                      background: #08487a;
                      border-radius: 4px;
                      font-weight: 600;
                      color: #fff;
                      border: 2px solid #08487a;
                    "
                    block
                    @click="submit('update')">
                    Зберегти бігунок
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <ColumnTabs>
              <div>
                <Client
                  v-show="$route.name === 'slider-client'"
                  ref="slider-client"
                  v-model:projectData="project"
                  :v="v$"
                  :isPerson="isPerson"
                  :choosedLegal="choosedLegal"
                  :choosedPerson="choosedPerson"
                  :fop="fop" />
                <MainInfo
                  v-show="$route.name === 'slider-main-info'"
                  ref="slider-main-info"
                  v-model:projectData="project"
                  :v="v$"
                  :benefit="benefit"
                  :isPerson="isPerson" />
                <LeasingObjects
                  v-show="$route.name === 'slider-leasing-objects'"
                  ref="slider-leasing-objects"
                  v-model:projectData="project"
                  :v="v$"
                  :calculations="project.calculations" />
                <Scoring
                  v-show="$route.name === 'slider-scoring'"
                  ref="slider-scoring"
                  :projectData="project"
                  @set-scoring="project.scoring = $event" />
                <AssociatedParties
                  v-show="$route.name === 'slider-guarantors'"
                  ref="slider-guarantors"
                  v-model:projectData="project"
                  :v="v$"
                  :associatedParties="project.guarantors"
                  associatedPartyType="guarantor"
                  @add-associated-party="
                    addAssociatedParty($event, 'guarantors')
                  "
                  @update-associated-party="
                    updateAssociatedParty($event, 'guarantors')
                  "
                  @delete-associated-party="
                    deleteAssociatedParty($event, 'guarantors')
                  " />
                <AssociatedParties
                  v-show="$route.name === 'slider-subtenants'"
                  ref="slider-subtenants"
                  v-model:projectData="project"
                  :v="v$"
                  :associatedParties="project.subtenants"
                  associatedPartyType="subtenant"
                  @add-associated-party="
                    addAssociatedParty($event, 'subtenants')
                  "
                  @update-associated-party="
                    updateAssociatedParty($event, 'subtenants')
                  "
                  @delete-associated-party="
                    deleteAssociatedParty($event, 'subtenants')
                  " />
              </div>
            </ColumnTabs>
          </v-row>
        </div>
      </div>
    </v-fade-transition>
  </div>
</template>
<script>
import Scoring from './Scoring.vue'
import MainInfo from './MainInfo.vue'
import Client from './Client.vue'
import LeasingObjects from './leasing-objects/LeasingObjects.vue'
import AssociatedParties from './associated-parties/AssociatedParties.vue'
import ColumnTabs from '@/components/ColumnTabs.vue'
import SectionLoader from '@/components/section-loader.vue'
import { mapState } from 'vuex'
import { required, minLength } from '@vuelidate/validators'
import {
  getLOName,
  v$Notify,
  contactFilter,
  contractorFilter,
  toFormatDate,
} from '@/utils/helperFunc'
import {
  urlPostObjectToProject,
  urlSendSliderToMiddle,
  urlGetProjectSlider,
  urlGetSliderSingle,
  urlUpdateProjectFromSlider,
} from '@/pages/request'
import { formutSum, maxNum } from '@/utils/formatFunc'
import { backDate, getContactInitials } from '@/utils/helperFunc'
import { calculation } from '@/type-ids'
import { useVuelidate } from '@vuelidate/core'
import { fillDirectory } from '@/plugins/directory/index.ts'
export default {
  components: {
    SectionLoader,
    ColumnTabs,
    MainInfo,
    Client,
    LeasingObjects,
    AssociatedParties,
    Scoring,
  },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    const pr = this.project
    const req = (field, val) => {
      const obj = {}
      val && (obj[field] = { required })
      return obj
    }
    const minLen = length => ({ minLength: minLength(length), required })
    const dateValidator = minLen(10)

    const deviationsProjectMessage = req(
      'deviationsProjectMessage',
      pr.isDeviationsProjectMessage
    )
    const overlappedDeviationsMessage = req(
      'overlappedDeviationsMessage',
      pr.isOverlappedDeviationsMessage
    )
    const commentByAc = req('commentByAc', pr.isCommentByAc)
    const comment = req('comment', pr.isAddComment)

    const calculations = {}
    if (this.project?.calculations) {
      this.project.calculations.forEach((c, idx) => {
        this.calculationValidationKeys.forEach(field => {
          let validator = { required }

          if (c) {
            switch (true) {
              case [
                'providerEmployeeId',
                'dealerPointId',
                'providerId',
              ].includes(field):
                validator =
                  c.providerTypeId !== 2 || c.leaseReverse ? {} : { required }
                break
              case field === 'productStatusText':
                validator = c.inStock ? {} : { required }
                break
              case ['agreedMarketValuePL', 'leasingObjectPhotos'].includes(
                field
              ):
                validator = c.requestData?.isNew ? {} : minLen(1)
                break
              case field === 'vinCode':
                validator = c.requestData?.isNew ? {} : minLen(17)
                break
              case field === 'leasingObjectCountryId':
                validator = { required }
                break
            }
          }

          if (!calculations[idx]) {
            calculations[idx] = {}
          }

          calculations[idx][field] = validator
        })
      })
    }

    return {
      project: {
        calculations,
        ...deviationsProjectMessage,
        ...overlappedDeviationsMessage,
        ...commentByAc,
        ...comment,
        isAttorneyAbroad: { required },
        business_regions: this.choosedLegal || this.fop ? { required } : {},
        dateSigningContract: dateValidator,
        dateOfLeasing: dateValidator,
        advanceDate: dateValidator,
        analysisTypeId: { required },
        benefits_questionnaire: this.benefit ? { required } : {},
        guarantors: {
          // contacts: {
          //   $each: {
          //     guarantorApplicationForm: { required },
          //   },
          // },
          // contractors: {
          //   $each: {
          //     guarantorApplicationForm: { required },
          //   },
          // },
        },
        subtenants: {
          // contacts: {
          //   $each: {
          //     subtenantApplicationForm: { required },
          //   },
          // },
          // contractors: {
          //   $each: {
          //     subtenantApplicationForm: { required },
          //   },
          // },
        },
      },
    }
  },
  data: () => ({
    currentTab: 0,
    calculationValidationKeys: [
      // 'providerTypeId',
      'vinCode',
      'productStatusText',
      'providerId',
      'dealerPointId',
      'inStock',
      'prepayToSupplier',
      'placeOfShipment',
      'leasingObjectCountryId',
      'providerEmployeeId',
      'agreedMarketValuePL',
      'leasingObjectPhotos',
      'specificationDocuments',
    ],
    project: {
      id: null,
      inn: null,
      employee: null,
      employeeId: null,
      isAccept: null,
      fio: null,
      applicationId: null,
      initials: null,
      edrpou: null,
      companyName: null,
      source: null,
      manager: null,
      isDeviationsProjectMessage: 0,
      deviationsProjectMessage: null,
      isOverlappedDeviationsMessage: 0,
      overlappedDeviationsMessage: null,
      isCommentByAc: 0,
      commentByAc: null,
      isAttorneyAbroad: 0,
      dateSigningContract: null,
      dateOfLeasing: null,
      advanceDate: null,
      isAddComment: 0,
      comment: null,
      outOfStock: null,
      missingIncomeStatement: [],
      analysisTypeId: null,
      scoring: [],
    },
    providerId: null,
    sendToMiddleLoading: false,
    loading: false,
    sliderLoading: false,
    showLegalDocuments: false,
    showFinanceDocuments: false,
  }),
  computed: {
    calculation() {
      return calculation
    },
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    application() {
      return this.project.application
    },
    isFastLeasing() {
      return this.project.analysisTypeId === 3
    },
    standartLeasing() {
      return this.project.analysisTypeId === 1
    },
    notStandartLeasing() {
      return this.project.analysisTypeId === 2
    },
    isPerson() {
      return this.project?.application?.lesseeTypeId === 1
    },
    hasProject() {
      return !!this.project.project
    },
    choosedLegal() {
      return this.project?.application?.lesseeTypeId === 2
    },
    choosedPerson() {
      return [1, 3 /* person, fop */].includes(
        this.project?.application?.lesseeTypeId
      )
    },
    fop() {
      return this.project?.lessee?.contact
        ? !!this.project.lessee.contact?.fop
        : this.project?.application?.lesseeTypeId === 3
    },
    benefit() {
      return this.project?.calculations?.reduce((acc, calc) => {
        return acc || calc.leasingProgram.programId === 34
      }, false)
    },
  },
  methods: {
    contractorFilter,
    contactFilter,
    urlSendSliderToMiddle,
    urlPostObjectToProject,
    urlGetProjectSlider,
    urlUpdateProjectFromSlider,
    formutSum,
    maxNum,
    getLOName,
    backDate,
    getContactInitials,
    toFormatDate,
    addAssociatedParty(associatedParty, associatedPartyType) {
      const obj = JSON.parse(JSON.stringify(this.project[associatedPartyType]))

      const entity =
        associatedParty.entityTypeId === 2 ? 'contractors' : 'contacts'
      obj[entity].push(associatedParty)

      this.project[associatedPartyType] = obj
    },
    updateAssociatedParty(associatedParty, associatedPartyType) {
      const entity =
        associatedParty.entityTypeId === 2 ? 'contractors' : 'contacts'
      this.project[associatedPartyType][entity] = this.project[
        associatedPartyType
      ][entity].map(itm =>
        itm.id === associatedParty.id ? associatedParty : itm
      )
    },
    async deleteAssociatedParty(associatedParty, associatedPartyType) {
      const entity =
        associatedParty.entityTypeId === 2 ? 'contractors' : 'contacts'
      this.project[associatedPartyType][entity] = this.project[
        associatedPartyType
      ][entity].filter(a => a.id !== associatedParty.id)
    },
    validateCalculations() {
      const calcErrors = {}
      const calcs = []

      for (const item in this.project.calculations) {
        const calculation = this.v$.project.calculations[item]
        if (calculation) {
          calcs.push(calculation)
        }
      }

      this.calculationValidationKeys.forEach(field => {
        calcErrors[field] = {
          $invalid: !calcs.reduce(
            (acc, cur) => acc && !cur[field].$invalid,
            true
          ),
        }
      })

      return calcErrors
    },
    validateDocumentFields() {
      const errors = {}
      const validateDoc = doc =>
        !this.project[doc] ||
        (Array.isArray(this.project.doc) && !this.project[doc].length)
          ? (errors[doc] = { $invalid: true })
          : false

      validateDoc('applicationForm')
      validateDoc('questionnaire')
      if (this.project?.application?.lesseeTypeId === 2) {
        validateDoc('ownershipStructure')
      }
      if (this.benefit) {
        validateDoc('environmentalQuestionnaire')
      }
      // this.LO.stateId === 0 && validateDoc('agreedMarketValuePL')
      // this.LO.stateId === 0 && validateDoc('leasingObjectPhotos')
      // this.project.isClientAgraria

      return errors
    },
    sendSlidertoMiddle() {
      this.sendToMiddleLoading = true
      const p = JSON.parse(JSON.stringify(this.project))
      p.dateOfLeasing = backDate(this.project.dateOfLeasing)
      p.dateSigningContract = backDate(this.project.dateSigningContract)
      p.advanceDate = backDate(this.project.advanceDate)

      const url = p.project
        ? this.urlUpdateProjectFromSlider
        : this.urlSendSliderToMiddle

      return this.$axios
        .post(url(p.id), p)
        .then(() => {
          this.sendToMiddleLoading = false
          this.$setSnackbar({
            text: p.project ? 'Проект оновлено' : 'Створено проект',
          })
          this.$router.push({
            name: 'edit-application',
            params: { applicationId: this.application.id },
          })
        })
        .catch(err => this.$err(err, () => (this.sendToMiddleLoading = false)))
    },
    prepareObjToProject() {
      this.sliderLoading = true
      const p = JSON.parse(JSON.stringify(this.project))
      p.dateOfLeasing = backDate(this.project.dateOfLeasing)
      p.dateSigningContract = backDate(this.project.dateSigningContract)
      p.advanceDate = backDate(this.project.advanceDate)

      return this.$axios
        .post(this.urlPostObjectToProject(p.id), p)
        .then(res => {
          this.setSlider(res)

          this.sliderLoading = false
          this.$setSnackbar({ text: 'Бiгунок оновлено' })
        })
        .catch(err => this.$err(err, () => (this.sliderLoading = false)))
    },
    setBreadScrumb() {
      if (this.project && this.project.application) {
        this.$store.commit('setBreadScrumbPart', [
          null,
          this.project.application.applicationName,
        ])
      }
    },
    getProjectSlider() {
      const id = this.$route.params.sliderId
      return this.$axios
        .get(urlGetSliderSingle(id))
        .then(this.setSlider)
        .catch(this.$err)
    },
    setSlider(res) {
      Object.assign(this.project, res.data)
      return res
    },
    clientSync() {
      const slider = this.project
      const client = this.choosedLegal
        ? slider.lessee.contractor || {}
        : slider.lessee.contact || {}
      const docs = [{ slider: 'isClientAgraria', client: 'isAgroSectorClient' }]

      if (this.choosedLegal && client) {
        docs.push(
          ...[
            { slider: 'isModelCharter', client: 'isModelCharter' },
            { client: 'statute' },
            { client: 'codeAccessStatute' },
            { client: 'extract' },
            { client: 'protocolFounders' },
            { client: 'orderAppointment' },
            { client: 'balanceF1AndF2' },
            { client: 'declaration' },
            {
              client: 'generalTurnoverBalanceSheet',
            },
            { client: 'turnoverBalanceSheet' },
            { client: 'copiesOfContracts' },
            {
              client: 'certificatesOfAccountTurnover',
            },
            {
              client: 'certificatesOfObligations',
            },
          ]
        )
      } else if (this.choosedPerson && client) {
        if (this.fop) {
          docs.push(
            ...[
              { client: 'extractOrExcerpt' },
              { client: 'declarationForInvoice' },
              { client: 'copiesOfContracts' },
            ]
          )
        }
        docs.push(
          ...[
            { client: 'documentPassport' },
            { client: 'identificationNumber' },
            { client: 'documentsPowers' },
            { client: 'marriageCertificate' },
            { slider: 'missingIncomeStatement', client: 'incomeStatement' },
            { client: 'propertyDocuments' },
            { client: 'debtCertificate' },
          ]
        )
      }

      // set docs by priority
      client &&
        docs.forEach(d => {
          const getDocByPriority = (doc1, doc2) => {
            const hasValue = val => ![void [0], null].includes(val)
            if (hasValue(doc1)) return doc1
            if (hasValue(doc2)) return doc2
            return slider[d.slider]
          }
          const docByPriority = this.hasProject
            ? getDocByPriority(client[d.client] || slider[d.slider])
            : getDocByPriority(slider[d.slider] || client[d.client])
          slider[d.slider] = docByPriority
        })
    },
    async submit(action) {
      const errors = this.validateDocumentFields()
      const calcErrors = this.validateCalculations()

      if (this.project.calculations && this.project.calculations.length === 0) {
        return this.$setSnackbar({
          text: 'Калькуляція не погоджена або на доопрацюванні',
          color: 'error',
        })
      }

      if (
        action === 'sendToMiddle' &&
        (this.v$.$invalid || Object.keys(errors).length)
      ) {
        this.v$.$anyError
        this.v$.$touch()

        if (this.project.guarantors.contractors) {
          this.project.guarantors.contractors.forEach(g => {
            if (!g.guarantorApplicationForm) {
              this.$setSnackbar({
                text: `Заявка анкета поручителя ${g.contractor?.shortName}`,
                color: 'error',
              })
            }
          })
        }
        if (this.project.guarantors.contacts) {
          this.project.guarantors.contacts.forEach(g => {
            if (!g.guarantorApplicationForm) {
              this.$setSnackbar({
                text: `Заявка анкета поручителя ${g.contact?.fullName}`,
                color: 'error',
              })
            }
          })
        }
        if (this.project.subtenants.contractors) {
          this.project.subtenants.contractors.forEach(s => {
            if (!s.subtenantApplicationForm) {
              this.$setSnackbar({
                text: `Заявка анкета суборендатора ${s.contractor?.shortName}`,
                color: 'error',
              })
            }
          })
        }
        if (this.project.subtenants.contacts) {
          this.project.subtenants.contacts.forEach(s => {
            if (!s.subtenantApplicationForm) {
              this.$setSnackbar({
                text: `Заявка анкета суборендатора ${s.contact?.fullName}`,
                color: 'error',
              })
            }
          })
        }
        const v = Object.clone(this.v$.project)
        delete v.guarantors
        delete v.subtenants
        v$Notify({ ...v, ...errors, ...calcErrors }, 'slider')
      } else {
        switch (action) {
          case 'sendToMiddle':
            return this.sendSlidertoMiddle()
          case 'update':
            return this.prepareObjToProject()
          default:
            return this.$setSnackbar({ text: 'No handler', color: 'warning' })
        }
      }
    },
  },
  watch: {
    '$route.name': function () {
      this.setBreadScrumb()
    },
  },
  created() {
    this.loading = true
    // this.$store.dispatch('addLeasingObjectTypes')
    fillDirectory('analysisTypes')
    this.$store.dispatch('addAllProviders')
    Promise.all([
      this.getProjectSlider().then(res => {
        this.setBreadScrumb()
        this.clientSync()
        return res
      }),
    ])
      .then(() => (this.loading = false))
      .catch(err => this.$err(err, () => (this.loading = false)))
  },
}
</script>
<style lang="scss" scoped>
.v-card-tabs {
  width: 150px;
  display: flex;
  align-items: center;
}
.size-20 {
  .v-icon {
    font-size: 20px !important;
  }
}
.size-18 {
  .v-icon {
    font-size: 18px !important;
  }
}
.size-16 {
  .v-icon {
    font-size: 16px !important;
  }
}
.sm-label {
  label {
    font-size: 0.85rem !important;
  }
}
.project-application-title {
  font-size: 0.93rem;
  color: grey;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  display: flex !important;
  align-items: center !important;
}
.v-tab-item--selected.v-tab {
  font-weight: 700 !important;
  color: #08487a !important;
  font-size: 13px;
}
.v-tab {
  color: #838282 !important;
  font-size: 13px;
  letter-spacing: normal;
  text-transform: uppercase;
}
</style>
