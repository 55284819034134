<template>
  <div class="pie">
    <div
      id="pie-progress"
      class="pie__progress"
      :style="{
        transform: rotate,
        backgroundColor: percent < 50 ? 'currentColor' : 'inherit',
      }"></div>
  </div>
</template>

<script>
export default {
  props: {
    percent: {
      type: Number,
    },
  },
  computed: {
    delay() {
      return parseFloat(this.percent)
    },
    rotate() {
      const rotate = this.percent < 50 ? this.percent : this.percent - 50
      return `rotate(${(50 - rotate) / 100}turn)`
    },
  },
}
</script>

<style scoped>
.pie {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #0098ee;
  background-image: linear-gradient(to right, transparent 50%, currentColor 0);
  color: #fff;
  border: 1px solid #0098ee;
}
.pie__progress {
  display: block;
  margin-left: 50%;
  height: 100%;
  border-radius: 0 100% 100% 0 / 50%;
  transform-origin: left;
}
</style>
