<template>
  <div>
    <div class="d-flex justify-between">
      <Filters :filters="filters" class="ml-2">
        <template #header-action>
          <v-btn
            data-readonly="hide"
            variant="flat"
            color="#fc7247"
            class="mr-3 mt-2"
            @click="createApplication">
            <v-icon style="margin-right: 5px; color: #fff !important">
              {{ 'mdi-plus' }}
            </v-icon>
            Створити заявку
          </v-btn>
        </template>
      </Filters>
    </div>
    <SectionLoader v-if="asyncLoading('getApplicationList')" />
    <div style="margin-left: -10px">
      <v-col :cols="12" :sm="12">
        <div v-show="!asyncLoading('getApplicationList')">
          <v-data-table
            :items="applications"
            :headers="applicationServiceHeaders"
            class="application-table calc-table"
            hide-default-footer
            :hide-default-header="!applications?.length"
            :items-idper-page="-1"
            @dblclick:row="navigateToApplication">
            <!-- eslint-disable-next-line -->
            <template #item.serviceApplicationName="{ item }">
              <ALink
                :text="item.serviceApplicationName"
                :route="{
                  name: 'service-application-single',
                  params: { id: item.id },
                }" />
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.responsible="{ item }">
              <span>{{ getResponsibleShortName(item.responsible) }}</span>
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.created_at="{ item }">
              <span>{{ toFormatDate(item.created_at) }}</span>
            </template>
          </v-data-table>
          <Pagination
            :getItems="getApplicationList"
            :trigger="paginationTrigger" />
        </div>
      </v-col>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue'
import Filters from '@/components/Filters.vue'
import SectionLoader from '@/components/section-loader.vue'
import ALink from '@/components/Link.vue'
import { urlGetServiceApplicationList } from '@/pages/request'
import {
  getEntityRoute,
  getContactInitials,
  toFormatDate,
  getResponsibleShortName,
} from '@/utils/helperFunc'
import { mapGetters } from 'vuex'
import { useSelect } from '@/utils/mixins/useSelect'
import { useFilter } from '@/utils/mixins/useFilter'
import { reactive } from 'vue'
import { applicationServiceHeaders } from '@/utils/tableHeaders'
import { fillDirectory } from '@/plugins/directory'

export default {
  components: { Pagination, Filters, SectionLoader, ALink },
  setup() {
    const filters = reactive({
      statusId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { label: 'Статус', items: 'serviceApplicationStatuses' },
      },
      dates: {
        value: null,
        component: 'DatePicker',
        attrs: { label: 'Дата створення', range: true },
      },
      responsibleId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Відповідальний',
          itemTitle: u => getContactInitials(u, { isResponsible: true }),
          items: 'activeUsers',
        },
        focus: () => fillDirectory('allUsers'),
      },
      typeId: {
        value: null,
        component: 'v-select',
        attrs: { label: 'Тип', items: 'serviceApplicationTypes' },
      },
      search: { value: null },
    })
    return {
      ...useSelect(),
      ...useFilter(filters),
      filters,
    }
  },
  data() {
    return {
      applications: [],
      applicationServiceHeaders,
    }
  },
  computed: {
    ...mapGetters({
      activeUsers: 'activeUsers',
    }),
  },
  methods: {
    getEntityRoute,
    getContactInitials,
    toFormatDate,
    getResponsibleShortName,
    createApplication() {
      this.$store.commit('setDialog', {
        title: 'Створити заявку',
        action: 'createServiceApplication',
        params: {
          cb: res => {
            return this.$router.push({
              name: 'service-application-single',
              params: { id: res.data.id },
            })
          },
        },
      })
    },
    navigateToApplication(e, row) {
      this.$router.push({
        name: 'service-application-single',
        params: { id: row.item.id },
      })
    },
    getApplicationList(page = 1) {
      return this.asyncAction('getApplicationList', null, () => {
        return this.$axios
          .get(urlGetServiceApplicationList(page, this.filtersQueryObj))
          .then(res => {
            this.applications = res.data.data
            return res
          })
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
