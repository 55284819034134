<template>
  <div>
    <b-form :submit="submit" hideActionButtons>
      <v-row>
        <v-col cols="12" md="12" sm="12" class="pb-0">
          <span class="d-flex">Номер телефону</span>
          <v-text-field
            id="auth-phone"
            v-model="credentials.phone"
            :error-messages="phoneErr"
            placeholder="Введіть телефон"
            class="angle auth"
            dense
            @keyup.enter="submit()"
            @input="phoneMask(credentials.phone, 'auth-phone')"
            @blur="v$.phone.$touch()">
          </v-text-field>
        </v-col>
        <v-col cols="12" md="12" sm="12" class="pt-0">
          <div class="d-flex justify-space-between cursor-pointer">
            <span class="d-flex">Пароль</span>
            <a
              class="auth-nav-link"
              @click="$router.push({ name: 'recovery-password' })">
              Відновити пароль
            </a>
          </div>
          <v-text-field
            v-model="credentials.password"
            :error-messages="passwordErr"
            placeholder="Введіть пароль"
            :type="showPassword ? 'text' : 'password'"
            class="angle decrease-m auth"
            dense
            @keyup.enter="submit()"
            @blur="v$.password.$touch()">
            <template #append-inner>
              <v-btn
                variant="text"
                color="grey"
                size="x-small"
                @click="showPassword = !showPassword">
                <v-icon>
                  {{ showPassword ? 'mdi-eye-off' : 'mdi-eye' }}
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="12" sm="12" class="pt-0">
          <v-btn
            type="submit"
            :loading="loading"
            class="w-100 new-auth-btn text-white angle">
            Увійти
          </v-btn>
        </v-col>
      </v-row>
    </b-form>
  </div>
</template>

<script>
import { BForm } from 'best-modules/components'
import { computed, reactive, ref } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { phoneMask } from '@/utils/masks'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { setErrHandler } from '@/utils/helperFunc'

export default {
  components: { BForm },
  methods: { phoneMask },
  setup() {
    const showPassword = ref(false)
    const loading = ref(false)
    const router = useRouter()
    const store = useStore()

    const credentials = reactive({
      phone: null,
      password: null,
    })
    const rules = {
      phone: { required, minLength: minLength(23) },
      password: { required, minLength: minLength(5) },
    }

    const v$ = useVuelidate(rules, credentials)

    const phoneErr = computed(() => {
      return setErrHandler(v$.value.phone)
    })
    const passwordErr = computed(() => {
      return setErrHandler(v$.value.password)
    })

    const submit = () => {
      v$.value.$validate()
      if (v$.value.$error) return

      loading.value = true
      store
        .dispatch('loginFirst', credentials)
        .then(res => {
          router.push({ name: 'auth-confirm' })
          return res
        })
        .finally(() => (loading.value = false))
    }

    return {
      credentials,
      v$,
      submit,
      showPassword,
      loading,
      phoneErr,
      passwordErr,
    }
  },
}
</script>

<style scoped>
.new-auth-btn {
  background: #08487a !important;
  border-radius: 4px !important;
  width: 100%;
  height: 40px !important;
}
</style>
