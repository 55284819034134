<template>
  <div>
    <div v-if="routeMortgagorAgreements">
      <Filters :filters="filters" class="ml-2" />
      <SectionLoader v-if="loading" />
      <div v-show="!loading" class="pt-5">
        <v-row>
          <v-col :cols="12">
            <v-data-table
              :headers="mortgagorListHeaders"
              :items="mortgagorAgreements"
              class="calc-table application-table --cust-cell-pd"
              mobileBreakpoint="750"
              hide-default-footer
              :hide-default-header="!mortgagorAgreements.length"
              @dblclick:row="navigateToContract">
              <template #item.date="{ item }">
                {{ toFormatDate(item.date, true) }}
              </template>
              <template #item.actions="{ item }">
                <TableCrud
                  :actionsArray="[
                    {
                      action: () =>
                        $router.push({
                          name: 'mortgagor-agreement',
                          params: { id: item.id },
                        }),
                      text: 'Відкрити',
                      icon: 'mdi-folder-open-outline',
                    },
                  ]">
                </TableCrud>
              </template>
              <template #item.mortgagor="{ item }">
                <ALink
                  v-if="item.mortgagor"
                  :text="item.mortgagor.name"
                  :route="
                    getEntityRoute(item.mortgagor.id, item.mortgagor.typeId)
                  ">
                </ALink>
                <span v-else>---</span>
              </template>
              <template #item.number="{ item }">
                <ALink
                  :text="item.number"
                  :route="{
                    name: 'mortgagor-agreement',
                    params: { id: item.id },
                  }">
                </ALink>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <Pagination
          :getItems="getMortgagorAgreements"
          :trigger="paginationTrigger" />
      </div>
    </div>
    <router-view v-if="!routeMortgagorAgreements"></router-view>
  </div>
</template>
<script>
import TableCrud from '@/components/table-crud.vue'
import SectionLoader from '@/components/section-loader.vue'
import ALink from '@/components/Link.vue'
import Pagination from '@/components/Pagination.vue'
import Filters from '@/components/Filters.vue'
import { urlGetMortgagorAgreements } from '@/pages/request'
import { getEntityRoute, toFormatDate } from '@/utils/helperFunc'
import { useFilter } from '@/utils/mixins/useFilter'
import { reactive } from 'vue'
import { mortgagorListHeaders } from '@/utils/tableHeaders'
export default {
  components: {
    TableCrud,
    SectionLoader,
    ALink,
    Pagination,
    Filters,
  },
  setup() {
    const filters = reactive({
      statusId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { items: 'dflStatuses', label: 'Статус' },
      },
      startDate: {
        value: null,
        component: 'DatePicker',
        attrs: { label: 'Період', range: true },
      },
      search: { value: null },
    })
    return {
      ...useFilter(filters),
      filters,
    }
  },
  data() {
    return {
      mortgagorAgreements: [],
      loading: false,
      mortgagorListHeaders,
    }
  },
  computed: {
    routeMortgagorAgreements() {
      return this.$route.name === 'mortgagor-agreements'
    },
  },
  methods: {
    getEntityRoute,
    urlGetMortgagorAgreements,
    toFormatDate,
    getMortgagorAgreements(page = 1) {
      this.loading = true
      return this.$axios
        .get(this.urlGetMortgagorAgreements(page, this.filtersQueryObj))
        .then(res => {
          this.mortgagorAgreements = res.data.data
          return res
        })
        .catch(this.$err)
        .finally(() => (this.loading = false))
    },
    navigateToContract(e, row) {
      this.$router.push({
        name: 'mortgagor-agreement',
        params: { id: row.item.id },
      })
    },
  },
}
</script>
