<template>
  <div v-if="!loading">
    <div class="d-flex justify-space-between align-center mr-5">
      <v-tooltip bottom text="Повернутися в ДФЛ">
        <template #activator="{ props }">
          <v-btn
            class="mr-4"
            size="x-small"
            icon
            v-bind="props"
            @click="$router.go(-1)">
            <v-icon>{{ 'mdi-arrow-left' }}</v-icon>
          </v-btn>
        </template>
        <span>Повернутися в ДФЛ</span>
      </v-tooltip>
      <v-tabs
        v-model="currentTab"
        class="contact-tab"
        background-color="grey darken-2"
        :height="$vuetify.breakpoint?.xs ? null : 38"
        dense
        :direction="$vuetify.breakpoint?.xs ? 'vertical' : 'horizontal'"
        slider-color="#FC7247"
        :class="{ 'v-tabs--mobile': $vuetify.breakpoint?.xs }">
        <v-tab
          replace
          :to="{ name: 'ra-general-info', params: { id: $route.params.id } }">
          Загальна інформація
        </v-tab>
      </v-tabs>

      <v-tooltip bottom text="Завантажити друковану форму">
        <template #activator="{ props }">
          <v-btn
            icon
            size="small"
            :loading="pfLoading"
            v-bind="props"
            @click="downloadPf">
            <PrinterIcon />
          </v-btn>
        </template>
        <span>Завантажити друковану форму</span>
      </v-tooltip>
    </div>

    <keep-alive>
      <RouterView
        v-model:application="registrationData"
        @set-cache="cache = setCache([registrationData])"></RouterView>
    </keep-alive>

    <div class="d-flex justify-end mr-3 mt-3">
      <ActionButtons
        :confirm="updateApplication"
        :cancel="() => $store.commit('refreshPage')"
        :confirmLoading="loading"
        :confirmDisable="applicationCache === cache"
        :cancelDisable="applicationCache === cache" />
    </div>
  </div>
</template>

<script>
import {
  urlGetRegistrationApplication,
  urlRegistrationApplicationUpdate,
  urlRegistrationApplicationPrintedForm,
} from '@/pages/request'
import ActionButtons from '@/components/action-buttons.vue'
import { getBeautyNum, openDocument, setCache } from '@/utils/helperFunc'
import PrinterIcon from '@/assets/svg/Printer-icon.vue'

export default {
  components: { PrinterIcon, ActionButtons },
  data: () => ({
    registrationData: {},
    currentTab: 0,
    loading: false,
    pfLoading: false,
    cache: null,
  }),
  computed: {
    applicationCache() {
      return this.setCache([this.registrationData])
    },
  },
  methods: {
    setCache,
    urlRegistrationApplicationPrintedForm,
    getRegistrationApplicationSingle() {
      this.loading = true
      this.$axios
        .get(urlGetRegistrationApplication(this.$route.params.id))
        .then(res => {
          this.registrationData = res.data
          this.registrationData.advanceToProvider = this.registrationData
            .advanceToProvider
            ? getBeautyNum(this.registrationData.advanceToProvider, {
                float: 2,
              })
            : null
          this.registrationData.paymentToProvider = this.registrationData
            .paymentToProvider
            ? getBeautyNum(this.registrationData.paymentToProvider, {
                float: 2,
              })
            : null
          this.cache = this.setCache([this.registrationData])
          this.loading = false
          return res
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
    updateApplication() {
      const req = {
        advanceToProvider: this.registrationData?.advanceToProvider,
        paymentToProvider: this.registrationData?.paymentToProvider,
        vinCode: this.registrationData?.calculation?.vinCode,
        leasingObjectColor: this.registrationData?.leasingObjectColor,
        leasingObjectEngineNumber:
          this.registrationData?.leasingObjectEngineNumber,
        advanceDateToProvider: this.registrationData?.advanceDateToProvider,
        paymentDateToProvider: this.registrationData?.paymentDateToProvider,
        placeOfShipment: this.registrationData?.calculation?.placeOfShipment,
        registrationDate: this.registrationData?.registrationDate,
        shipmentDate: this.registrationData?.calculation?.shipmentDate,
        notes: this.registrationData?.notes,
        equipment: this.registrationData?.equipment,
        advanceDate: this.registrationData?.calculation?.advanceDate,
      }

      req.advanceToProvider = req.advanceToProvider
        ? Number(req.advanceToProvider.replace(/\s/g, ''))
        : null
      req.paymentToProvider = req.paymentToProvider
        ? Number(req.paymentToProvider.replace(/\s/g, ''))
        : null

      return this.$axios
        .post(urlRegistrationApplicationUpdate(this.$route.params.id), req)
        .then(res => {
          if (res.data.message) {
            return this.$setSnackbar({
              text: res.data.message,
              color: 'warning',
            })
          }
          this.$setSnackbar({ text: 'Дані оновлено' })
          this.$store.commit('refreshPage')
        })
    },
    downloadPf() {
      this.pfLoading = true
      return this.$axios
        .get(urlRegistrationApplicationPrintedForm(this.registrationData.id))
        .then(res => {
          if (res.data.message) {
            return this.$setSnackbar({
              text: res.data.message,
              color: 'warning',
            })
          }
          this.pfLoading = false
          return openDocument({
            text: 'Заявка на реєстрацію',
            url: res.data.url,
          })
        })
    },
  },
  created() {
    this.getRegistrationApplicationSingle()
  },
}
</script>

<style scoped lang="scss">
a.v-tab-item--selected.v-tab {
  font-weight: 700 !important;
  color: #08487a !important;
  font-size: 13px;
}
a.v-tab {
  color: #838282 !important;
  font-size: 13px;
  letter-spacing: normal;
}
.v-tabs {
  & a.v-tab {
    margin-right: 10px;
  }
  &--mobile {
    padding-right: 12px;
    a.v-tab {
      margin-right: 0;
      margin-bottom: 12px;
      min-width: 100%;
      border: 1px solid #eaeaea;
      background: #eaeaea;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
      border-radius: 4px;
    }
  }
}
</style>
