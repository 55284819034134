<template>
  <v-row class="align-center mb-3">
    <v-col cols="12" md="5" sm="12" class="pt-0 pb-0">
      <span class="card-label calc">{{ label }}:</span>
    </v-col>
    <v-col cols="12" md="7" sm="12" class="pt-0 pb-0">
      <component
        :is="component"
        v-model="localValue"
        class="pt-0 mt-0"
        :placeholder="placeholder"
        :items="directoryItems(items, localValue)"
        :error-messages="errorMessages"
        :item-value="itemValue"
        :item-title="itemTitle"
        hide-details
        return-object
        v-bind="$attrs"
        @focus="!items.length && $emit('focus')"
        @blur="$emit('blur')">
        <template
          v-if="loading && !directoryItems(items, localValue).length"
          #no-data>
          <Loader />
        </template>
        <template
          v-if="loading & !!directoryItems(items, localValue).length"
          #append-item>
          <Loader />
        </template>
      </component>
    </v-col>
  </v-row>
</template>

<script>
import Loader from '@/components/Loader.vue'
import { VSelect, VAutocomplete, VTextField } from 'vuetify/components'
import { useSelect } from '@/utils/mixins/useSelect'
export default {
  name: 'CarItem',
  components: { Loader, VSelect, VAutocomplete, VTextField },
  emits: ['change-value', 'blur', 'focus', 'update:model-value'],
  setup() {
    return {
      ...useSelect(),
    }
  },
  props: {
    modelValue: {
      type: [Object, Number],
    },
    items: {
      type: Array,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
    errorMessages: {
      type: String,
    },
    itemValue: {
      type: String,
      default: 'id',
    },
    itemTitle: {
      type: String,
      default: 'name',
    },
    component: {
      type: String,
      default: 'v-select',
    },
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue
      },
      set(v) {
        this.$emit('update:model-value', v)
      },
    },
  },
}
</script>
