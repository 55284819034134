<template>
  <div class="mt-2">
    <FieldsColumnBlock title="Підписані ДФЛ з поручителем">
      <template #default>
        <div v-if="agreement.contracts">
          <v-data-table
            :headers="guaranteeDflsHeaders"
            :items="dflTable"
            class="calc-table --cust-cell-pd ml-2 mr-2"
            mobileBreakpoint="750"
            dense
            :hide-default-footer="
              !agreement.contracts || !agreement.contracts.length
            "
            :hide-default-header="
              !agreement.contracts || !agreement.contracts.length
            ">
            <template #item.number="{ item }">
              <ALink
                :text="item.number"
                :route="{ name: 'dfl-contract', params: { id: item.id } }">
              </ALink>
            </template>
            <template #item.date="{ item }">
              {{ humanDate(formatDate(item.date, true)) }}
            </template>
          </v-data-table>
        </div>
      </template>
    </FieldsColumnBlock>
  </div>
</template>
<script>
import ALink from '@/components/Link.vue'
import { urlGetPledgeContract } from '@/pages/request.js'
import { formatDate, humanDate } from '@/utils/formatFunc'
import { guaranteeDflsHeaders } from '@/utils/tableHeaders.ts'
import FieldsColumnBlock from '@/pages/contracts/components/FieldsColumnBlock.vue'

export default {
  components: { FieldsColumnBlock, ALink },
  props: { agreement: { type: Object }, v: { type: Object } },
  data: () => ({
    guaranteeDflsHeaders,
    dfls: [],
  }),
  computed: {
    agreementData() {
      return this.agreement
    },
    dflTable() {
      return this.agreement?.contracts
    },
    $v() {
      return this.v
    },
  },
  methods: {
    urlGetPledgeContract,
    formatDate,
    humanDate,
    getDflList() {
      return this.$axios
        .get(this.urlGetPledgeContract(this.agreementData.id))
        .then(res => {
          this.$setSnackbar({ text: 'Данi оновлено' })
          this.contracts = res.data
        })
    },
  },
}
</script>
