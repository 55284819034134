<template>
  <div>
    <div id="chart" class="mt-3">
      <apexchart
        type="area"
        height="350"
        :options="chartOptions"
        :series="series"></apexchart>
    </div>
  </div>
</template>

<script>
import { getBeautyNum } from '@/utils/helperFunc'

export default {
  props: {
    averageResponseData: { type: Object },
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          height: 350,
          type: 'area',
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        title: {
          text: 'Статистика за останній рік',
          align: 'left',
        },
        legend: {
          tooltipHoverFormatter: function (val, opts) {
            return (
              val +
              ' - <strong>' +
              opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
              '</strong>'
            )
          },
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return `${getBeautyNum(value)}$`
            },
          },
        },
        xaxis: {
          categories: this.categories,
        },
        tooltip: {
          y: [
            {
              title: {
                formatter: function (val) {
                  return val
                },
              },
            },
            {
              title: {
                formatter: function (val) {
                  return val
                },
              },
            },
            {
              title: {
                formatter: function (val) {
                  return val
                },
              },
            },
          ],
        },
        grid: {
          borderColor: '#f1f1f1',
        },
      }
    },
    graphs() {
      return this.averageResponseData?.graphs || []
    },
    series() {
      const { averageResponseData, graphs } = this
      if (!averageResponseData || !graphs || !graphs.length) {
        return []
      }
      const { mark, model } = averageResponseData

      return graphs.map(({ year, graph }) => ({
        name: `${mark?.name || ''} ${model?.name || ''}, ${year}р.`,
        data: graph.map(item => item.price?.USD || 0),
      }))
    },
    categories() {
      const arr = []
      this.graphs.forEach(item => {
        item.graph.forEach(graph => {
          arr.push(graph.date)
        })
      })
      return arr
    },
  },
}
</script>

<style scoped lang="scss">
.stat-block {
  grid-gap: 8px;
  gap: 4px;
  background: #f3fafd;
  border-radius: 4px;
  padding: 11px;
  display: flex;
  flex-direction: column;
  height: 70px;

  span {
    display: block;
  }

  .label {
    font-size: 12px !important;
    color: #000 !important;
  }

  .average-price {
    font-weight: 700;
    font-size: 22px;
    color: #3c9806;
  }
}
</style>
