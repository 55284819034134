import axios from "axios"
import { urlGetLeasingObjectsList } from '@/pages/request.js'
const leasingObjects = {
  state:() => {
    return {
      leasingObjectsList: [],
    }
  },
  mutations: {
    setLeasingObjectsList(state, objects) {
      state.leasingObjectsList.splice(0)
      state.leasingObjectsList.push(...objects)
    }
  },
  actions: {
    async getLeasingObjects({ commit }, id) {
      const { data } = await axios.get(urlGetLeasingObjectsList(id), {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
      commit('setLeasingObjectsList', data.result)
    }
  }
}

export { leasingObjects }