<template>
  <div>
    <v-data-table
      :headers="tHead"
      :items="tBody || []"
      class="custom-table head-line mt-4"
      mobileBreakpoint="750"
      :items-per-page="-1"
      :hide-default-header="!tBody"
      dense
      @dblclick:row="navigateToApplication">
      <!-- eslint-disable-next-line -->
      <template #no-data>
        <NoData></NoData>
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.applicationName="{ item }">
        <ALink
          style="font-size: 0.75rem !important"
          class="link"
          :text="item.applicationName"
          :route="{
            name: 'edit-application',
            params: { applicationId: item.id },
          }">
        </ALink>
      </template>
      <template #item.responsible="{ item }">
        <span>{{
          getContactInitials(item.responsible.fullName, { byName: true })
        }}</span>
      </template>
      <template #item.created_at="{ item }">
        <span>{{ toFormatDate(item.created_at) }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  tableDateSort,
  getContactInitials,
  toFormatDate,
} from '@/utils/helperFunc'
import ALink from '@/components/Link.vue'
import NoData from '@/components/no-table-data.vue'

export default {
  name: 'ApplicationsInfo',
  components: {
    NoData,
    ALink,
  },
  props: {
    agent: { type: Object },
  },
  data() {
    return {
      tHead: [
        { title: 'Статус', key: 'status.name', align: 'start' },

        {
          title: 'Назва заявки',
          key: 'applicationName',
          align: 'start',
        },
        { title: 'Відповідальний', key: 'responsible', align: 'start' },
        { title: 'Дата заявки', key: 'created_at', align: 'start' },
      ],
    }
  },
  computed: {
    tBody() {
      return this.agent?.applications
    },
  },
  methods: {
    toFormatDate,
    getContactInitials,
    tableDateSort,
    navigateToApplication(e, row) {
      this.$router.push({
        name: 'edit-application',
        params: { applicationId: row.item.id },
      })
    },
  },
}
</script>

<style scoped></style>
