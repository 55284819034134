<template>
  <v-card-text>
    <SectionLoader v-if="pageLoad" />
    <div v-if="!pageLoad && faqVideoListRoute">
      <EmptySection
        v-if="!pageLoad && !mapedVideos.length"
        :icon="EmptyFile"
        title="Роздiл тимчасово порожнiй"
        :iconWidth="38"
        :iconHeight="38"
        :hideAction="true"
        @click="openDialog" />
      <div style="flex-wrap: wrap">
        <OpenableSection
          v-for="(video, key) in mapedVideos"
          :key="key"
          faqDoc
          :title="video.name">
          <v-card :elevation="0" class="wrapper">
            <video v-if="video.base64" controls class="keep-aspect">
              <source :src="video.base64" :type="video.ext" />
            </video>
            <iframe
              v-if="video.youtubeLink"
              title="YouTube video player"
              type="text/html"
              width="auto"
              class="keep-aspect"
              :src="video.youtubeLink"
              frameborder="0"
              allowfullscreen>
            </iframe>
          </v-card>
        </OpenableSection>
      </div>
    </div>
  </v-card-text>
</template>
<script>
import EmptySection from '@/components/empty-section.vue'
import EmptyFile from '@/assets/svg/empty-file.vue'
import SectionLoader from '@/components/section-loader.vue'
import { formatDate } from '@/utils/formatFunc'
import { urlFaqGetAllVideos } from '@/pages/request'
import { isValidUrl } from '@/utils/helperFunc'
import OpenableSection from '@/components/OpenableSection.vue'
export default {
  components: {
    OpenableSection,
    EmptySection,
    // eslint-disable-next-line
    EmptyFile,
    SectionLoader,
  },
  data: () => ({
    search: null,
    pageLoad: false,
    mapedVideos: [],
    EmptyFile,
  }),
  computed: {
    faqVideoListRoute() {
      return this.$route.name === 'faq-videos'
    },
  },
  methods: {
    getVideos() {
      this.pageLoad = true

      return this.$axios
        .get(urlFaqGetAllVideos())
        .then(this.mapVideos)
        .then(result => {
          this.mapedVideos.splice(0)
          this.mapedVideos.push(...result)
          this.pageLoad = false
          return result
        })
        .catch(err => this.$err(err, () => (this.pageLoad = false)))
    },
    mapVideos(res) {
      const maped = (res.data || []).map(video => {
        const base64 = this.setBase64(video.url)
        const youtubeLink = this.formatYoutubeLink(video.url)

        return Promise.all([base64, youtubeLink]).then(res => {
          return {
            ext: isValidUrl(video.url)
              ? null
              : `video/${(video.url || '').split('.').pop()}`,
            base64: res[0],
            youtubeLink: res[1],
            name: video.name,
            date: formatDate(video.created_at),
            id: video.id,
          }
        })
      })

      return Promise.all(maped)
    },
    setBase64(url) {
      if (!url || isValidUrl(url)) return Promise.resolve(null)

      const locationUrl = location.origin + '/' + url

      return fetch(locationUrl)
        .then(res => res.blob())
        .then(blob => {
          return new Promise((resolve, reject) => {
            if (!blob.type.includes('video')) {
              reject(new Error('Invalid file type', +blob.type))
            }
            const reader = new FileReader()
            reader.onload = e => resolve(e.target.result)
            reader.readAsDataURL(blob)
          })
        })
        .catch(this.$err)
    },
    formatYoutubeLink(url) {
      if (!url || !isValidUrl(url)) return Promise.resolve(null)

      return new Promise(resolve => {
        const separator = '?v='
        const youtube = location.protocol + '//www.youtube-nocookie.com/embed/'
        const id = (url || separator).split(separator)[1].split('&')[0]
        return resolve(youtube + id)
      })
    },
  },
  created() {
    this.getVideos()
  },
}
</script>
<style>
.content-block {
  width: 25%;
  margin: 5px auto;
  display: inline-block;
  height: 14.06%;
}
.wrapper {
  position: relative;
  padding-top: 65.94%;
}
.wrapper .keep-aspect {
  padding: 8px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
