<template>
  <div>
    <v-text-field
      v-model="document.name"
      label="Назва"
      :error-messages="nameErr"
      dense
      :readonly="faqDeleteDocument"
      :disabled="faqDeleteDocument"
      @blur="v$.document.name.$touch()">
    </v-text-field>
    <FilesUploader
      v-if="!faqDeleteDocument"
      v-model:documents="document.file"
      label="Документ"
      single
      @blur="v$.document.file.$touch()">
    </FilesUploader>
    <h4 v-if="faqDeleteDocument" class="pt-2">Документ буде видалено!</h4>
  </div>
</template>
<script>
import FilesUploader from '@/components/FilesUploader.vue'
import { required } from '@vuelidate/validators'

import { setErrHandler } from '@/utils/helperFunc'
import { urlFaqAddDocument, urlFaqUpdateDocument } from '@/pages/request'
import { useVuelidate } from '@vuelidate/core'
export default {
  components: { FilesUploader },
  setup() {
    return { v$: useVuelidate() }
  },
  props: { dialog: { type: Object } },
  validations() {
    const file =
      this.faqCreateDocument || this.faqEditDocument ? { required } : {}
    const name =
      this.faqCreateDocument || this.faqEditDocument ? { required } : {}

    return {
      document: {
        file: file,
        name: name,
      },
    }
  },
  data: () => ({
    document: {
      file: null,
      name: null,
    },
  }),
  computed: {
    faqCreateDocument() {
      return this.dialog.action === 'faqCreateDocument'
    },
    faqDeleteDocument() {
      return this.dialog.action === 'faqDeleteDocument'
    },
    faqEditDocument() {
      return this.dialog.action === 'faqEditDocument'
    },
    validation() {
      return { v$: this.v$.document, name: 'faqDocument' }
    },
    nameErr() {
      return setErrHandler(this.v$?.document?.name)
    },
  },
  methods: {
    submit() {
      const document = {
        ...this.document,
        file: this.document.file.url || this.document.url,
      }
      const create = () => this.$axios.post(urlFaqAddDocument(), document)
      const update = () =>
        this.$axios.post(urlFaqUpdateDocument(document.id), document)

      switch (true) {
        case this.faqCreateDocument:
          return create()
        case this.faqEditDocument:
          return update()
        case this.faqDeleteDocument:
          return this.dialog.params.deleteDocument(this.document.id)
      }
    },
  },
  mounted() {
    Object.assign(this.document, this.dialog.dialogItem)
  },
}
</script>
