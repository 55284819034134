<template>
  <div style="min-height: 200px" class="mt-2">
    <v-data-table
      :headers="tHead"
      :items="repurchase"
      class="calc-table --cust-cell-pd"
      mobileBreakpoint="750"
      dense
      hide-default-footer
      :hide-default-header="!repurchase.length"
      :items-per-page="1000">
      <template #item.cost-buying-property="{ item }">
        {{ formutSum(item['cost-buying-property']) }}
      </template>
      <template #item.title="{ item }">
        {{ humanDate(formatDate(item.title, true)) }}
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { formatDate, humanDate, formutSum } from '@/utils/formatFunc'
import { inject } from 'vue'
import { contractKey } from '../injectionKeys'

export default {
  setup() {
    const contract = inject(contractKey)

    return { contract }
  },
  computed: {
    repurchase() {
      return this.contract.calculation?.exportData?.repurchase || []
    },
    currency() {
      return this.contract.calculation?.requestData?.leasingCurrency?.toLowerCase()
    },
    tHead() {
      return [
        { title: '№', key: 'n', align: 'start', sortable: false },
        {
          title: 'Місяць в якому здійснюється оплата',
          key: 'title',
          align: 'center',
          sortable: false,
        },
        {
          title: 'Вартість викупу Майна, т.ч. ПДВ 20%',
          key: 'cost-buying-property',
          align: 'end',
          sortable: false,
        },
        // { text: 'Дії', value: 'actions', align: 'end', sortable: false },
      ]
    },
  },
  methods: { formatDate, humanDate, formutSum },
}
</script>
