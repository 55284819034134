<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    height="19"
    viewBox="0 0 18 19"
    fill="none">
    <rect y="0.5" width="18" height="18" rx="9" fill="#62C14A" />
    <path
      d="M13.5 9.5C13.3667 9.5 13.248 9.44667 13.144 9.34C13.0397 9.2337 12.9792 9.10185 12.9625 8.94444C12.8542 8.08333 12.5272 7.34944 11.9815 6.74278C11.4355 6.13648 10.775 5.77315 10 5.65278C9.85833 5.63426 9.73967 5.56944 9.644 5.45833C9.548 5.34722 9.5 5.21296 9.5 5.05556C9.5 4.89815 9.55 4.76611 9.65 4.65944C9.75 4.55315 9.86667 4.50926 10 4.52778C11.05 4.65741 11.9458 5.13426 12.6875 5.95833C13.4292 6.78241 13.8583 7.77778 13.975 8.94444C13.9917 9.09259 13.952 9.22222 13.856 9.33333C13.7603 9.44444 13.6417 9.5 13.5 9.5ZM11.4125 9.5C11.3042 9.5 11.2083 9.45833 11.125 9.375C11.0417 9.29167 10.9792 9.18056 10.9375 9.04167C10.8708 8.77315 10.7438 8.53463 10.5565 8.32611C10.3688 8.11796 10.1542 7.97685 9.9125 7.90278C9.7875 7.85648 9.6875 7.78704 9.6125 7.69444C9.5375 7.60185 9.5 7.49074 9.5 7.36111C9.5 7.17593 9.55833 7.02537 9.675 6.90944C9.79167 6.79389 9.92083 6.75463 10.0625 6.79167C10.5292 6.91204 10.9313 7.15963 11.269 7.53444C11.6063 7.90963 11.8292 8.35648 11.9375 8.875C11.9708 9.03241 11.9333 9.17593 11.825 9.30556C11.7167 9.43519 11.5792 9.5 11.4125 9.5ZM13.475 14.5C12.4 14.5 11.3522 14.2337 10.3315 13.7011C9.3105 13.1689 8.40633 12.4652 7.619 11.59C6.83133 10.7152 6.198 9.71056 5.719 8.57611C5.23967 7.44204 5 6.27778 5 5.08333C5 4.91667 5.05 4.77778 5.15 4.66667C5.25 4.55556 5.375 4.5 5.525 4.5H7.55C7.66667 4.5 7.77083 4.54167 7.8625 4.625C7.95417 4.70833 8.00833 4.81481 8.025 4.94444L8.35 6.88889C8.36667 7.01852 8.36467 7.13648 8.344 7.24278C8.323 7.34944 8.275 7.44444 8.2 7.52778L7 8.88889C7.35 9.55556 7.7875 10.1806 8.3125 10.7639C8.8375 11.3472 9.41667 11.8519 10.05 12.2778L11.225 10.9722C11.3 10.8889 11.398 10.8263 11.519 10.7844C11.6397 10.743 11.7583 10.7315 11.875 10.75L13.6 11.1389C13.7167 11.1667 13.8125 11.2291 13.8875 11.3261C13.9625 11.4235 14 11.537 14 11.6667V13.9167C14 14.0833 13.95 14.2222 13.85 14.3333C13.75 14.4444 13.625 14.5 13.475 14.5Z"
      fill="white" />
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: Number, default: 18 },
  },
}
</script>
