<template>
  <div class="mt-2">
    <v-data-table
      :items="contracts || []"
      :headers="dflConnectionsHeaders"
      item-value="id">
      <template #item.number="{ item }">
        <div class="d-flex align-center">
          <v-icon color="#09487a" class="mr-2">mdi-file-document</v-icon>
          <ALink
            :route="{
              name: 'du-dfl-agreement',
              params: { id: item.id },
            }"
            :text="item.number" />
        </div>
      </template>
      <template #item.type="{ item }">
        <span>{{ item.type?.name }}</span>
      </template>
      <template #item.date="{ item }">
        <span>{{ toFormatDate(item.date, true) }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ALink from '@/components/Link.vue'
import { dflConnectionsHeaders } from '@/utils/tableHeaders'
import { toFormatDate } from '@/utils/helperFunc'

export default {
  name: 'ServiceСonnections',
  components: { ALink },
  props: {
    contracts: { type: Array },
  },
  data: () => {
    return { dflConnectionsHeaders }
  },
  methods: { toFormatDate },
}
</script>

<style scoped>
.agr-block {
  width: 200px;
  height: 30px;
  border-bottom: 1px solid #09487a45;
  margin-bottom: 10px;
}
.agr-block:hover v-icon {
  color: red;
}
</style>
