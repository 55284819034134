<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0,0,256,256"
    width="18px"
    height="18px"
    fill-rule="nonzero">
    <g
      fill="none"
      fill-rule="nonzero"
      stroke="none"
      stroke-width="1"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal">
      <g transform="scale(5.33333,5.33333)">
        <path d="M39,5h-9l9,9z" fill="#ff9e06" />
        <path
          d="M12.8,21l-2.8,-2.8l9.9,-9.9l7,6l7.7,-7.7l2.8,2.8l-10.3,10.3l-7,-6z"
          fill="#ff9e06" />
        <path d="M5,23h38v20h-38z" fill="#a5d6a7" />
        <path
          d="M14,33c0,1.1 -0.9,2 -2,2c-1.1,0 -2,-0.9 -2,-2c0,-1.1 0.9,-2 2,-2c1.1,0 2,0.9 2,2zM38,33c0,1.1 -0.9,2 -2,2c-1.1,0 -2,-0.9 -2,-2c0,-1.1 0.9,-2 2,-2c1.1,0 2,0.9 2,2zM30,33c0,3.3 -2.7,6 -6,6c-3.3,0 -6,-2.7 -6,-6c0,-3.3 2.7,-6 6,-6c3.3,0 6,2.7 6,6z"
          fill="#388e3c" />
        <path d="M41,25v16h-34v-16h34M43,23h-38v20h38z" fill="#388e3c" />
        <path
          d="M6,24v3.5c0.4,0.3 0.9,0.5 1.5,0.5c1.4,0 2.5,-1.1 2.5,-2.5c0,-0.6 -0.2,-1.1 -0.5,-1.5zM42,24h-3.5c-0.3,0.4 -0.5,0.9 -0.5,1.5c0,1.4 1.1,2.5 2.5,2.5c0.6,0 1.1,-0.2 1.5,-0.5zM42,38.5c-0.4,-0.3 -0.9,-0.5 -1.5,-0.5c-1.4,0 -2.5,1.1 -2.5,2.5c0,0.6 0.2,1.1 0.5,1.5h3.5zM9.5,42c0.3,-0.4 0.5,-0.9 0.5,-1.5c0,-1.4 -1.1,-2.5 -2.5,-2.5c-0.6,0 -1.1,0.2 -1.5,0.5v3.5z"
          fill="#388e3c" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IncomeIcon',
  props: {
    color: { type: String, defaults: '#388e3c' },
  },
}
</script>

<style scoped></style>
