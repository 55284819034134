<template>
  <div>
    <LeasingObjectCard :calculation="calculation" simple />
  </div>
</template>

<script>
import LeasingObjectCard from '@/components/LeasingObjectCard.vue'
export default {
  components: {
    LeasingObjectCard,
  },
  props: {
    calculation: { type: Object },
  },
}
</script>

<style scoped></style>
