<template>
  <v-row>
    <v-col cols="12" md="12" class="pb-0">
      <span class="label">Дилерская точка</span>
      <v-text-field
        :model-value="dealerOutlet.name"
        placeholder="Оберіть зі списку"
        hide-details
        readonly>
      </v-text-field>
    </v-col>
    <v-col v-if="addDealerSeller" cols="12" md="12" class="pb-0">
      <span class="label">Постачальник</span>
      <b-server-autocomplete
        v-model="dealerSeller"
        :url="urlGetContactsAndContractors()"
        :error-messages="providerIdErr"
        :item-title="item => item.fullName || item.shortName"
        :default-item="dealerSeller"
        placeholder="Введіть текст (мін. 3 символи)"
        :readonly="deleteDealerSeller"
        :disabled="deleteDealerSeller"
        :menu-props="{ zIndex: 1000000000, maxWidth: 300 }"
        return-object
        hide-details
        @update:model-value="setDealerSeller($event)"
        @blur="v$.dealerSeller.providerId.$touch()">
      </b-server-autocomplete>
    </v-col>
    <v-col v-if="deleteDealerSeller" cols="12" md="12">
      <span class="label">Постачальник</span>
      <v-text-field
        :model-value="seller.fullName || seller.shortName"
        placeholder="Введіть назву"
        disabled>
      </v-text-field>
    </v-col>
    <h3 v-if="deleteDealerSeller">Продавця буде видалено!</h3>
  </v-row>
</template>
<script>
import { required } from '@vuelidate/validators'
import { mapState } from 'vuex'
import {
  urlCreateDealerOutletSeller,
  urlDeleteDealerOutletSeller,
  urlGetContactsAndContractors,
} from '@/pages/request'
import { setErrHandler, contractorFilter } from '@/utils/helperFunc'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
import { BServerAutocomplete } from 'best-modules/components'

export default {
  components: { BServerAutocomplete },
  props: { dialog: { type: Object } },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  validations() {
    return {
      dealerSeller: {
        providerId: this.addDealerSeller ? { required } : {},
      },
    }
  },
  data: () => ({
    dealerSeller: null, // object has { fullName: null, providerTypeId: null, providerId: null}
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    addDealerSeller() {
      return this.dialog.action === 'addDealerSeller'
    },
    deleteDealerSeller() {
      return this.dialog.action === 'deleteDealerSeller'
    },
    validation() {
      return { v$: this.v$.dealerSeller, name: 'dealerSeller' }
    },
    dealerOutlet() {
      return this.$store.state?.dialog?.params?.dealerOutlet
    },
    seller() {
      return this.$store.state?.dialog?.dialogItem?.seller
    },
    providerIdErr() {
      return setErrHandler(this.v$?.dealerSeller?.providerId)
    },
  },
  methods: {
    urlGetContactsAndContractors,
    contractorFilter,
    setDealerSeller(obj) {
      this.dealerSeller.providerId = obj?.id
      this.dealerSeller.fullName = obj?.fullName
      this.dealerSeller.shortName = obj?.shortName
      this.dealerSeller.providerTypeId = obj.fullName ? 1 : 2 // [1 - contact, 2 - contractor]
    },
    submit() {
      switch (true) {
        case this.addDealerSeller:
          return this.createDillerSeller()
        case this.deleteDealerSeller:
          return this.removeDealerSeller()
        default:
          return this.$setSnackbar({ text: 'No handler', color: 'warning' })
      }
    },
    createDillerSeller() {
      const obj = {
        providerId: this.dealerSeller.providerId,
        providerTypeId: this.dealerSeller.providerTypeId,
        dealerPointId: this.dealerOutlet.id,
      }
      return this.$axios.post(urlCreateDealerOutletSeller(), obj)
    },
    removeDealerSeller() {
      return this.$axios.delete(
        urlDeleteDealerOutletSeller(this.dialog.dialogItem?.id)
      )
    },
  },
  created() {
    if (this.dealerSeller && this.dialog && this.dialog.dialogItem) {
      Object.assign(this.dealerSeller, this.dialog.dialogItem)
    }
  },
}
</script>
