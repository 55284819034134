<template>
  <div style="position: relative">
    <v-row>
      <v-col :cols="12" :md="12" class="pb-0">
        <div>
          <span class="text-title">Клієнт:</span>
          <div class="d-flex align-center">
            <ALink
              class="link"
              :text="getEntityName(act.contract.client)"
              :route="
                getEntityRoute(act.contract.client.id, act.entityTypeId)
              " />
            <div class="ml-3 d-flex align-center">
              <span class="mr-1">{{
                act.contract.client.inn ? 'IНН' : 'ЄДРПОУ'
              }}</span>
              <span>{{
                `(${
                  act.contract.client.inn
                    ? act.contract.client.inn
                    : act.contract.client.edrpou
                })`
              }}</span>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12" :md="12" class="pb-0">
        <div>
          <span class="text-title">№ договору:</span>
          <ALink
            class="link"
            :text="act.contract.number"
            :route="{
              name: 'dfl-contract',
              params: { id: act.contract.id },
            }" />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12" :md="12" class="pb-0">
        <div>
          <span class="text-title">Об'єкт лізингу:</span>
          <span class="text-desc">{{ act.contract.leasingObjectName }}</span>
        </div>
      </v-col>
    </v-row>
    <v-row style="color: #000; margin-left: 0; margin-top: 10px">
      <div class="d-flex align-center mr-3">
        <span class="mr-1 text-title">Сума АВ:</span>
        <span class="text-desc">{{ getBeautyNum(act.amountAB) + ' грн' }}</span>
      </div>
      <div class="d-flex align-center">
        <span class="mr-1 text-title">Сума ОЛ:</span>
        <span class="text-desc">{{ getBeautyNum(act.amountOl) + ' грн' }}</span>
      </div>
    </v-row>
    <div
      style="position: absolute; top: 0; right: 0"
      class="d-flex align-center">
      <div>
        <v-tooltip bottom text="Сформувати акт на виплату">
          <template #activator="{ props }">
            <v-btn icon v-bind="props" @click="getPaymentPF(act)">
              <PrinterIcon />
            </v-btn>
          </template>
          <span>Сформувати акт на виплату</span>
        </v-tooltip>
      </div>
    </div>

    <div style="width: 300px" class="mt-3">
      <div class="d-flex" style="flex-direction: column">
        <div class="d-flex align-end line-block" style="position: relative">
          <div>
            <span class="text-desc d-block"
              >Виплата буде здiйснюватись через:</span
            >
            <ALink
              v-if="act.broker"
              :route="getEntityRoute(act.broker.id, act.entityTypeId)"
              :text="getEntityName(act.broker)" />
            <span v-else>Брокер відсутній</span>
          </div>
          <div
            v-if="act.broker"
            style="position: absolute; top: -10px; right: -5px">
            <v-tooltip right text="Банкiвськi реквізити">
              <template #activator="{ props }">
                <v-btn
                  icon
                  size="x-small"
                  v-bind="props"
                  @click="bankInfoDialog = true">
                  <InformationIcon />
                </v-btn>
              </template>
              <span>Банкiвськi реквізити</span>
            </v-tooltip>
          </div>
          <div>
            <v-dialog v-model="bankInfoDialog" width="450px">
              <div style="padding: 10px; background: #fff">
                <div class="d-flex justify-end">
                  <v-btn size="small" @click="bankInfoDialog = false">
                    <CloseIcon />
                  </v-btn>
                </div>
                <div>
                  <div
                    style="border-bottom: 1px solid #80808042"
                    class="mb-1 d-flex align-center">
                    <span class="mr-1">Банк:</span>
                    <span class="d-block">{{
                      act.broker?.basicCurrentAccount?.bankName || '---'
                    }}</span>
                  </div>
                  <div
                    style="border-bottom: 1px solid #80808042"
                    class="mb-1 d-flex align-center">
                    <span class="mr-1">Рахунок:</span>

                    <div
                      v-if="
                        act?.broker?.basicCurrentAccount &&
                        act?.broker?.basicCurrentAccount.accountNumber
                      ">
                      <v-hover>
                        <template #default="{ isHovering, props }">
                          <div class="d-flex align-center" v-bind="props">
                            <span
                              class="link"
                              @click="
                                () =>
                                  copyText(
                                    act.broker.basicCurrentAccount.accountNumber
                                  )
                              "
                              >{{
                                act?.broker?.basicCurrentAccount
                                  ?.accountNumber || '---'
                              }}</span
                            >
                            <div style="width: 16px; margin-left: 5px">
                              <v-fade-transition hide-on-leave>
                                <v-icon
                                  v-show="
                                    isHovering &&
                                    act.broker?.basicCurrentAccount
                                      .accountNumber !== '---'
                                  "
                                  class="ml-1"
                                  style="cursor: pointer"
                                  size="small"
                                  @click="
                                    () =>
                                      copyText(
                                        act.broker.basicCurrentAccount
                                          .accountNumber
                                      )
                                  ">
                                  {{
                                    copied.value ===
                                    act.broker.basicCurrentAccount.accountNumber
                                      ? 'mdi-check'
                                      : 'mdi-content-copy'
                                  }}
                                </v-icon>
                              </v-fade-transition>
                            </div>
                          </div>
                        </template>
                      </v-hover>
                    </div>
                    <span v-else>---</span>
                  </div>
                  <div
                    style="border-bottom: 1px solid #80808042"
                    class="mb-1 d-flex align-center">
                    <span class="mr-1">МФО:</span>
                    <span class="d-block">{{
                      act?.broker?.basicCurrentAccount?.mfo || '---'
                    }}</span>
                  </div>
                </div>
              </div>
            </v-dialog>
          </div>
          <div>
            <v-tooltip bottom text="Змiнити">
              <template #activator="{ props }">
                <v-btn
                  icon
                  size="x-small"
                  v-bind="props"
                  @click="selectPaymentDialog.dialog = true">
                  <EditPencil style="cursor: pointer" />
                </v-btn>
              </template>
              <span>Змінити</span>
            </v-tooltip>
          </div>
        </div>
      </div>
      <v-dialog v-model="selectPaymentDialog.dialog" width="450px">
        <div style="padding: 10px; background: #fff">
          <v-autocomplete
            v-model="selectPaymentDialog.broker"
            class="mt-5 mb-5"
            hide-details
            placeholder="Оберіть зi списку"
            dense
            return-object
            :items="activeThroughs"
            :item-title="getEntityName">
          </v-autocomplete>
          <div
            class="mt-3"
            style="justify-content: center; display: flex; gap: 15px">
            <v-btn
              variant="text"
              :style="{
                width: '45%',
                color: '#08487a',
                'font-weight': 700,
                'font-size': '12px',
                background: '#fff',
                border: '2px solid #08487a',
              }"
              size="small"
              @click="selectPaymentDialog.dialog = false">
              ВІДХИЛИТИ
            </v-btn>
            <v-btn
              class="text-white"
              :style="{
                width: '45%',
                background: '#08487a',
                'border-radius': '4px',
              }"
              size="small"
              @click="addSelectedPayment">
              ЗБЕРЕГТИ
            </v-btn>
          </div>
        </div>
      </v-dialog>
    </div>
    <div class="mt-3">
      <FilesUploader
        v-model:documents="act.signedActOfProvisionOfServices"
        label="Акт агентської виплати"
        dense
        hideEdit
        :error-messages="actDocumentErr"
        :disabled="act.status?.id === 2"
        @loaded="updatePaymentDocs($event, 'add')"
        @update-file="selectDocument($event)"
        @delete="updatePaymentDocs($event, 'delete')" />
    </div>
  </div>
</template>

<script>
import ALink from '@/components/Link.vue'
import {
  getBeautyNum,
  getEntityName,
  getEntityRoute,
  openDocument,
  setErrHandler,
} from '@/utils/helperFunc'
import {
  urlAddSelectPayment,
  urlGetPrintedFormAgentPaymentAct,
  urlSendAgentPaymentAct,
  urlSetStatusPaymentAct,
} from '@/pages/request'
import FilesUploader from '@/components/FilesUploader.vue'
import PrinterIcon from '@/assets/svg/Printer-icon.vue'
import EditPencil from '@/assets/svg/edit-pencil.vue'
import { required } from '@vuelidate/validators'
import InformationIcon from '@/assets/svg/information-icon.vue'
import CloseIcon from '@/assets/svg/close-icon.vue'
import { useVuelidate } from '@vuelidate/core'
export default {
  name: 'PaymentActCard',
  components: {
    CloseIcon,
    InformationIcon,
    EditPencil,
    PrinterIcon,
    FilesUploader,
    ALink,
  },
  setup() {
    return { v$: useVuelidate() }
  },
  props: {
    dialog: { type: Object },
  },
  validations() {
    return {
      act: {
        signedActOfProvisionOfServices: { required },
        broker: {
          basicCurrentAccount: { required },
        },
        status: {
          id: { required },
        },
      },
    }
  },
  data() {
    return {
      copied: { value: false, timeout: null },
      bankInfoDialog: false,
      selectPaymentDialog: {
        dialog: false,
        broker: null,
      },
      act: {},
    }
  },
  computed: {
    validation() {
      return { v$: this.v$.act, name: 'act' }
    },
    actDocumentErr() {
      return setErrHandler(this.v$.act?.signedActOfProvisionOfServices)
    },
    activeThroughs() {
      return (
        this.dialog.dialogItem.paymentThroughs
          ?.filter(l => l.isActive)
          .map(l => ({
            ...l.lessee,
            entityTypeId: l.entityTypeId,
          })) || []
      )
    },
  },
  methods: {
    getBeautyNum,
    getEntityName,
    getEntityRoute,
    selectDocument() {
      return this.$axios
        .post(urlSendAgentPaymentAct(this.id), {
          signedActOfProvisionOfServices:
            this.act.signedActOfProvisionOfServices,
        })
        .then(res => {
          if (res.data.message)
            return this.$setSnackbar({
              text: res.data?.message,
              color: 'warning',
            })
          Object.assign(this.act, res.data)
          this.$setSnackbar({ text: 'Акти на виплату додані' })
          return res.data
        })
    },
    getPaymentPF(item) {
      return this.$axios
        .get(urlGetPrintedFormAgentPaymentAct(item.id))
        .then(res => {
          openDocument({ url: res.data.url, text: 'Акт на виплату' }),
            this.$setSnackbar({
              text: 'Акт на виплату сформульований',
              color: 'warning',
            })
        })
    },
    copyText(text) {
      clearTimeout(this.copied.timeout)
      this.copied.value = null
      this.$setSnackbar({ text: 'Текст скопійовано' })
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.copied.value = text
          this.copied.timeout = setTimeout(
            () => (this.copied.value = null),
            3000
          )
        })
        .catch(() => {
          throw new Error('text copied failed')
        })
    },
    addSelectedPayment() {
      const req = {
        entityTypeId: this.selectPaymentDialog.broker.entityTypeId,
        entityId: this.selectPaymentDialog.broker.id,
      }
      return this.$axios
        .post(urlAddSelectPayment(this.act.id), req)
        .then(res => {
          this.selectPaymentDialog.dialog = false

          this.$setSnackbar({ text: 'Особу на виплату додану' })
          this.act.broker = this.selectPaymentDialog.broker
          this.selectPaymentDialog.broker = null

          return res
        })
    },
    submit() {
      return this.changeStatusPaymentAct(this.act)
    },
    changeStatusPaymentAct(item) {
      const status = {
        statusId: 2,
      }
      return this.$axios
        .post(urlSetStatusPaymentAct(item.id), status)
        .then(() => {
          return (
            this.$setSnackbar({ text: 'Виплату по акту було надiслано' }),
            this.$store.commit('refreshPage')
          )
        })
    },
    updatePaymentDocs(doc, action = 'add') {
      const req = {
        signedActOfProvisionOfServices:
          this.act.signedActOfProvisionOfServices || [],
      }
      if (action === 'delete') {
        req.signedActOfProvisionOfServices =
          req.signedActOfProvisionOfServices.filter(d => d.url !== doc.url)
      } else {
        req.signedActOfProvisionOfServices.push(...doc)
      }
      return this.$axios
        .post(urlSendAgentPaymentAct(this.act.id), req)
        .then(res => {
          this.act = res.data
          return res
        })
    },
  },
  created() {
    this.act = this.dialog.dialogItem.act
  },
}
</script>

<style scoped>
.btnArr {
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  background-image: linear-gradient(
    to right,
    rgb(252, 114, 71) 0%,
    #fb6e23 51%,
    #d36060 100%
  );
}
.btnArr:hover {
  background-position: right center;
}
.text-title {
  color: #1c1d21;
  font-weight: 600;
  font-size: 15px;
  margin-right: 6px;
}
.text-desc {
  font-weight: 400;
  font-size: 14px;
  color: #1c1d21;
}
.line-block {
  justify-content: space-between;
  background: #e7eef8;
  opacity: 0.7;
  padding: 10px;
  border-radius: 7px;
  margin-left: -2px;
  transition: 0.3s;
}
.line-block:hover {
  opacity: 1;
}
</style>
