<template>
  <div>
    <v-dialog v-model="alert" :max-width="550" persistent>
      <v-card>
        <v-card-title class="text-h6"> Увага! </v-card-title>
        <v-card-text>
          <div class="text-center mb-3" style="font-size: 1rem">
            Ви впевненні що хочете оновити список документів, всі незбережені
            дані будуть втрачені?
          </div>
          <ActionButtons
            :confirm="loadDocuments"
            :cancel="() => (alert = false)" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <SectionLoader v-if="asyncLoading('getArchive')" />
    <div v-else>
      <v-card class="mb-3">
        <v-card-text>
          <div class="d-flex gap-5 align-center">
            <div>
              <span class="font-weight-bold">ДФЛ №:</span>
              <InputPusher
                :routeObject="{
                  name: 'dfl-contract',
                  params: { id: archive.contract?.id },
                }"
                text="Перейти до ДФЛ" />
              <ALink
                :route="{
                  name: 'dfl-contract',
                  params: { id: archive.contract?.id },
                }"
                :text="archive.contract?.number" />
            </div>
            <div>
              <span class="font-weight-bold">Лізингоодержувач:</span>
              <InputPusher
                :routeObject="
                  getEntityRoute(archive.lesseeId, archive.lesseeTypeId)
                "
                text="Перейти до Лізингоодержувача" />
              <ALink
                :route="getEntityRoute(archive.lesseeId, archive.lesseeTypeId)"
                :text="getEntityName(archive.lessee)" />
            </div>
          </div>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-text>
          <v-text-field
            v-model="archive.archiveCode"
            style="max-width: 200px"
            label="Архівний код">
          </v-text-field>
          <div class="d-flex align-center gap-2 mb-3">
            <v-tooltip bottom text="Оновити список документів">
              <template #activator="{ props }">
                <v-btn
                  icon
                  :disabled="asyncLoading('loadDocuments')"
                  :class="{ spin: asyncLoading('loadDocuments') }"
                  v-bind="props"
                  @click="() => loadDocuments(true)">
                  <v-icon>{{ 'mdi-reload' }}</v-icon>
                </v-btn>
              </template>
              <span>Оновити список документів</span>
            </v-tooltip>
            <span class="font-weight-bold d-block text-h6">Документи:</span>
          </div>
          <FilesUploader
            v-for="(doc, idx) in documents"
            :key="idx"
            :label="doc.label"
            :documents="getSafe(() => archive[doc.key].doc)"
            :single="doc.single"
            readonly>
            <template #label-prepend>
              <div class="inline-flex align-center gap-2 mr-4">
                <v-tooltip bottom text="Прочитано">
                  <template #activator="{ props }">
                    <div v-bind="props">
                      <v-checkbox
                        v-if="archive[doc.key]"
                        v-model="archive[doc.key].isVerify"
                        style="margin: 0; padding: 0 !important"
                        hide-details
                        dense>
                      </v-checkbox>
                    </div>
                  </template>
                  <span>Прочитано</span>
                </v-tooltip>
                <v-menu bottom offset-y>
                  <template #activator="{ props }">
                    <span v-if="archive[doc.key]" class="link" v-bind="props"
                      >Коментар</span
                    >
                  </template>
                  <v-card style="width: 600px">
                    <v-card-text>
                      <v-textarea
                        v-if="archive[doc.key]"
                        v-model="archive[doc.key].comment"
                        placeholder="введіть коментар до документа"
                        @click.stop></v-textarea>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
            </template>
          </FilesUploader>
          <ActionButtons
            :confirmLoading="asyncLoading('updateArchive')"
            :cancelDisable="!hasChange"
            :confirmDisable="!hasChange"
            :confirm="submit"
            :cancel="() => setArchive(cache)" />
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import SectionLoader from '@/components/section-loader.vue'
import FilesUploader from '@/components/FilesUploader.vue'
import ALink from '@/components/Link.vue'
import InputPusher from '@/components/InputPusher.vue'
import ActionButtons from '@/components/action-buttons.vue'
import {
  urlGetArchiveSingle,
  urlUpdateArchive,
  urlLoadArchiveDocuments,
} from '@/pages/request'
import {
  getEntityName,
  getEntityRoute,
  getSafe,
  setCache,
} from '@/utils/helperFunc'
import { inputNumber } from '@/utils/formatFunc'
import { useSelect } from '@/utils/mixins/useSelect'
export default {
  components: {
    SectionLoader,
    FilesUploader,
    ALink,
    InputPusher,
    ActionButtons,
  },
  setup() {
    return {
      ...useSelect(),
    }
  },
  data() {
    return {
      archive: null,
      cache: null,
      alert: false,
      documents: [
        {
          key: 'contractDocument',
          label: 'Договір фінансового лізингу',
          single: true,
        },
        {
          key: 'extraAgreementContractDocument',
          label: 'Додаткова угода до договору фінансового лізингу',
          single: true,
        },
        { key: 'dkpDocument', label: 'Договір купівлі-продажу', single: true },
        {
          key: 'extraAgreementDkpDocument',
          label: 'Додаткова угода до договору купівлі-продажу',
          single: true,
        },
        {
          key: 'guaranteeContactDocument',
          label: 'Договір поруки',
          single: false,
        },
        {
          key: 'pledgeAgreementDocument',
          label: 'Договір застави',
          single: false,
        },
        {
          key: 'deedOfTransferUnderDkpDocument',
          label: 'Акт прийняття-передання по ДКП',
          single: true,
        },
        {
          key: 'deedOfTransferUnderContractDocument',
          label: 'Акт прийняття-передання по ДФЛ',
          single: true,
        },
        {
          key: 'expenditureOnDkpDocument',
          label: 'Видаткова по ДКП',
          single: true,
        },
        {
          key: 'certificateOfRegistrationDocument',
          label: 'Свідоцтво реєстрації',
          single: true,
        },
        { key: 'photoOlDocument', label: 'Фото ОЛ', single: false },
      ],
    }
  },
  computed: {
    archiveId() {
      return this.$route.params.id
    },
    hasChange() {
      return setCache([this.cache]) !== setCache([this.archive])
    },
  },
  methods: {
    getSafe,
    getEntityName,
    getEntityRoute,
    inputNumber,
    setArchive(obj) {
      this.archive = Object.clone(obj)
      this.cache = Object.clone(obj)
    },
    getSingleArchive() {
      this.setLoading('getArchive')
      return this.$axios
        .get(urlGetArchiveSingle(this.archiveId))
        .then(res => this.setArchive(res.data))
        .finally(() => this.removeLoading('getArchive'))
    },
    submit() {
      this.setLoading('updateArchive')
      return this.$axios
        .post(urlUpdateArchive(this.archiveId), this.archive)
        .then(res => {
          this.setArchive(res.data)
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            return this.$setSnackbar({
              text: error.response.data.message,
              color: 'warning',
            })
          }
        })
        .finally(() => this.removeLoading('updateArchive'))
    },

    loadDocuments(ask) {
      this.alert = false
      if (ask && this.hasChange) {
        this.alert = true
        return
      }
      this.setLoading('loadDocuments')
      return this.$axios
        .get(urlLoadArchiveDocuments(this.archiveId))
        .then(res => {
          this.setArchive(res.data)
          this.$setSnackbar({ text: 'Список документів оновлено' })
        })
        .finally(() => this.removeLoading('loadDocuments'))
    },
  },
  created() {
    this.getSingleArchive()
  },
}
</script>

<style lang="scss" scoped></style>
