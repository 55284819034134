<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0,0,256,256"
    :width="width"
    :height="height"
    fill-rule="nonzero">
    <g
      :fill="fill"
      fill-rule="nonzero"
      stroke="none"
      stroke-width="1"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal">
      <g transform="scale(8.53333,8.53333)">
        <path d="M0,12h2v11h2v-13h-4z" />
        <path
          d="M2,9h3v14h2v-16h-5zM11,15c0,-3.309 2.691,-6 6,-6c3.309,0 6,2.691 6,6h2c0,-4.411 -3.589,-8 -8,-8c-4.411,0 -8,3.589 -8,8c0,4.411 3.589,8 8,8h13v-2h-13c-3.309,0 -6,-2.691 -6,-6z" />
        <path
          d="M17,17c-1.103,0 -2,-0.897 -2,-2c0,-1.103 0.897,-2 2,-2c1.103,0 2,0.897 2,2h2c0,-2.206 -1.794,-4 -4,-4c-2.206,0 -4,1.794 -4,4c0,2.206 1.794,4 4,4h13v-2z" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'OneCIcon',
  props: {
    width: { type: String, default: '25px' },
    height: { type: String, default: '25px' },
    fill: { type: String, default: '#FC7247' },
  },
}
</script>

<style scoped></style>
