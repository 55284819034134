<template>
  <div class="mt-2">
    <span class="aria-label">{{ objectData.name }}</span>
    <v-autocomplete
      v-model="objectData.types"
      :items="body"
      placeholder="оберіть тип обладнання"
      item-title="type"
      item-value="id"
      hide-details
      multiple
      deletable-chips
      small-chips
      chips
      :loading="loading"
      return-object
      dense></v-autocomplete>
    <div v-for="type in objectData.types" :key="type.id">
      <MarkInput :readOnly="readOnly" :object-type="type" :v="v"></MarkInput>
    </div>
  </div>
</template>

<script>
import MarkInput from './MarkInput.vue'
import { urlGetCarData } from '@/pages/request'
export default {
  name: 'BodyTypeInput',
  components: { MarkInput },
  props: {
    readOnly: {
      type: Boolean,
    },
    objectType: {
      type: Object,
    },
    v: {
      type: Object,
    },
  },
  data: () => ({
    body: [],
    loading: false,
  }),
  computed: {
    objectData() {
      return this.objectType
    },
  },
  methods: {
    getBodyByType() {
      this.loading = true
      return this.$axios
        .get(urlGetCarData({ leasingObjectType: this.objectData.id }), {
          headers: { 'X-API-SECRET': import.meta.env.VITE_ERM_API_SECRET },
        })
        .then(res => {
          this.body.splice(0)
          this.body.push(
            ...res.data.map(t => ({
              categoryId: t.categoryId,
              type: t.name,
              typeId: t.typeId,
              id: t.id,
              marks: [],
            }))
          )
        })
        .catch(this.$err)
        .finally(() => {
          this.loading = false
        })
    },
  },
  created() {
    this.getBodyByType()
  },
}
</script>

<style scoped></style>
