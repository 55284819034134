<template>
  <div>
    <v-tabs
      v-if="!$route.params.id"
      v-model="curTab"
      slider-color="#FC7247"
      class="mb-3">
      <v-tab
        v-if="!isFront && !supervisor"
        :to="{ name: $route.name, params: { tabState: 'main' } }"
        >Основний список</v-tab
      >
      <v-tab
        :to="{
          name: $route.name,
          params: { tabState: 'scoring' },
        }"
        >Попереднє рішення</v-tab
      >
    </v-tabs>
    <div>
      <slot v-if="$route.params.tabState === 'main'" name="projects"></slot>
      <slot v-if="$route.params.tabState === 'scoring'" name="scoring"></slot>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'ServiceIndex',
  data() {
    return {
      curTab: 0,
    }
  },
  computed: {
    ...mapState({
      isFront: state => state.user.roleId === 2,
      supervisor: state => state.user.roleId === 3,
    }),
  },
  methods: {
    handleTabState() {
      if (this.isFront || this.supervisor) {
        this.$router.push({
          name: this.$route.name,
          params: { tabState: 'scoring' },
        })
      }
    },
  },
  watch: {
    '$route.fullPath': {
      handler() {
        this.handleTabState()
      },
      immediate: true,
    },
    isFront: {
      handler() {
        this.handleTabState()
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
a.v-tab-item--selected.v-tab {
  color: #08487a !important;
  font-weight: 700 !important;
  font-size: 14px;
}
</style>
