<template>
  <div>
    <div class="d-flex justify-space-between align-center mb-5">
      <div class="d-flex align-end gap-5">
        <v-menu rounded offset-y>
          <template #activator="{ props }">
            <v-btn
              style="border: 1px solid #c0c1c7 !important; background: white"
              color="grey darken-1"
              class="text-white mt-1 no-transform"
              variant="outlined"
              v-bind="props">
              <v-icon :icon="`best:${offer.sendType}`" class="mr-2"></v-icon>
              <span>{{ offer.sendType }}</span>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, key) in messengers"
              :key="key"
              class="elevation-0"
              variant="elevated"
              @click="offer.sendType = item">
              <div style="margin-top: 5px; margin-right: 12px">
                <v-icon :icon="`best:${item}`"></v-icon>
              </div>
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <div style="min-width: 200px">
          <span class="label">Телефон</span>
          <v-select
            v-if="offer.sendType"
            v-model="offer.phones"
            :items="phones"
            :item-title="p => `${p.phone} (${p.owner})`"
            item-value="phone"
            placeholder="Оберіть одержувача"
            :error-messages="getErrorMessages(v$.phones)"
            hide-details
            chips
            multiple
            @blur="v$.phones.$touch()">
          </v-select>
        </div>
      </div>
    </div>

    <div>
      <span class="label">Повідомлення</span>
      <v-textarea
        v-model="offer.messageText"
        class="mt-2"
        placeholder="Введіть текст"
        :rows="4"
        :error-messages="getErrorMessages(v$.messageText)"
        @blur="v$.messageText.$touch()">
      </v-textarea>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, PropType } from 'vue'
import { OfferCredentials } from '@/utils/types/application'
import { Validation } from '@vuelidate/core'
import { getErrorMessages } from 'best-modules/utils'

export default {
  name: 'SendOfferSocial',
  props: {
    offerCredentials: {
      type: Object as PropType<OfferCredentials>,
    },
    calculation: {
      type: Object,
    },
    v$: {
      type: Object as PropType<Validation>,
    },
    phones: {
      type: Array as PropType<Array<{ phone: string; owner: string }>>,
    },
  },
  methods: { getErrorMessages },
  emits: ['update:offer-credentials'],
  setup(props, { emit }) {
    const messengers: Array<Exclude<OfferCredentials['sendType'], 'email'>> = [
      'viber',
      'whats-up',
      'telegram',
    ]

    const offer = computed<OfferCredentials>({
      get() {
        return props.offerCredentials
      },
      set(v) {
        emit('update:offer-credentials', v)
      },
    })

    return {
      messengers,
      offer,
    }
  },
}
</script>

<style scoped></style>
