<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none">
    <path
      d="M1.00396 8.36204C0.404477 8.16106 0.399883 7.83835 1.01659 7.63278L22.9356 0.327583C23.5431 0.125459 23.8911 0.465396 23.7211 1.06029L17.4587 22.9793C17.2853 23.5868 16.935 23.6075 16.6778 23.031L12.5503 13.7413L19.4409 4.55383L10.2535 11.4444L1.00396 8.36204Z"
      fill="#FC7247" />
  </svg>
</template>

<script>
export default {
  name: 'SendCalc',
}
</script>

<style scoped></style>
