<template>
  <div>
    <SectionLoader v-if="loading" />
    <div v-else id="chart">
      <apexchart
        type="area"
        height="350"
        :options="chartOptions"
        :series="series"></apexchart>
    </div>
  </div>
</template>

<script>
import { getBeautyNum } from '@/utils/helperFunc'
import SectionLoader from '@/components/section-loader.vue'

export default {
  components: { SectionLoader },
  props: {
    stats: { type: Object },
    loading: { type: Boolean },
  },
  data: () => ({
    chartOptions: {
      noData: {
        text: 'Загрузка...',
      },
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5,
        },
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return `${getBeautyNum(value, { float: 2 })} грн`
          },
        },
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return getBeautyNum(value, { float: 0 })
          },
        },
      },
      xaxis: {
        categories: [
          'Січ',
          'Лют',
          'Бер',
          'Квіт',
          'Трав',
          'Черв',
          'Лип',
          'Серп',
          'Вер',
          'Жов',
          'Лист',
          'Груд',
        ],
      },
    },
  }),
  computed: {
    series() {
      const seriesData = []

      for (let i = 1; i <= 12; i++) {
        const value = this.stats[i] || 0
        seriesData.push(value)
      }

      return [
        {
          name: 'Бонус',
          data: seriesData,
        },
      ]
    },
  },
}
</script>

<style scoped></style>
