import notFound from '@/layout/not-found.vue'

export default [
  {
    path: '/:pathMatch(.*)*',
    component: notFound,
    name: 'notFound',
    meta: {
      title: 'Сторiнку не знайдено',
    },
  },
]
