<template>
  <div class="pb-2">
    <div v-if="approveCalculation">
      <span style="font-size: 1rem"> Оберіть графік: </span>
      <v-radio-group
        v-model="graph"
        :error-messages="graphErr"
        :hide-details="!graphErr?.length"
        class="mt-2"
        dense>
        <v-radio
          v-for="(item, key) in chartTypes"
          :key="key"
          :label="item.name"
          :value="item.value"
          color="#80c23f">
        </v-radio>
      </v-radio-group>
      <v-checkbox
        v-model="leaseReverse"
        label="Зворотній лізинг"
        color="red"
        class="mb-3"
        dense
        hide-details
        @update:model-value="
          () => {
            providerId = null
            providerTypeId = null
            provider = null
            dealerPointId = null
            v$.$reset()
          }
        ">
      </v-checkbox>
      <div>
        <div v-if="!leaseReverse" class="mb-3">
          <span class="label">Дилер</span>
          <b-server-autocomplete
            v-model="dealerPointId"
            :error-messages="dealerPointIdErr"
            :url="urlGetDealerOutlets()"
            :search-min-length="3"
            class="w-100"
            item-value="id"
            item-title="name"
            :menu-props="{ zIndex: 1000000000, maxWidth: 300 }"
            placeholder="Введіть текст (мін. 3 символи)"
            hide-details
            @update:model-value="handleUpdateModelValue"
            @blur="v$.dealerPointId.$touch()">
          </b-server-autocomplete>
        </div>
        <div v-if="!leaseReverse" class="mb-5">
          <span class="label">Постачальник</span>
          <v-select
            v-model="provider"
            :items="dealerOutletProviders"
            :error-messages="providerIdErr"
            item-value="uuid"
            item-title="name"
            placeholder="Оберіть зі списку"
            dense
            hide-details
            return-object
            :loading="dealerOutletProvidersLoading"
            @update:model-value="handleUpdateProvider"
            @blur="v$.providerId.$touch()">
            <template #item="{ item, props }">
              <EntityScope
                v-bind="props"
                :type="item.raw.providerTypeId"
                :name="item.raw.name" />
            </template>
            <template #selection="{ item, props }">
              <EntityScope
                v-bind="props"
                :type="item.raw.providerTypeId"
                :name="item.raw.name" />
            </template>
          </v-select>
        </div>
        <div>
          <ActionButtons
            v-if="canApprove"
            style="margin-top: 0 !important"
            confirm-btn-text="ПОГОДИТИ"
            cancel-btn-text="СКАСУВАТИ"
            :confirm="submit"
            :confirm-loading="loading"
            :cancel-disable="loading"
            :cancel="() => $store.commit('closeDialog')" />
          <v-alert v-else type="warning" elevation="2" dense>
            Для погодження потрібно вказати модифікацію
          </v-alert>
        </div>
      </div>
    </div>
    <div v-if="rejectCalculation">
      <h2 style="font-weight: normal; font-size: 1.2rem">
        Погодження калькуляції буде відмінено!
      </h2>
    </div>
  </div>
</template>
<script>
import EntityScope from '@/components/EntityScopeView.vue'
import {
  urlConfirmCalculation,
  urlUnsignCalculation,
  urlGetDealerOutletProviders,
  urlGetDealerOutlets,
} from '@/pages/request'
import { required } from '@vuelidate/validators'
import { setErrHandler } from '@/utils/helperFunc'
import ActionButtons from '@/components/action-buttons.vue'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
import { fillDirectory, getDirectory } from '@/plugins/directory'
import { BServerAutocomplete } from 'best-modules/components'

export default {
  name: 'ApproveCalculation',
  components: { ActionButtons, EntityScope, BServerAutocomplete },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  props: { dialog: { type: Object } },
  validations() {
    const graph = this.approveCalculation ? { required } : true
    const providerId =
      this.approveCalculation && !this.leaseReverse ? { required } : {}
    const providerTypeId =
      this.approveCalculation && !this.leaseReverse ? { required } : {}
    const dealerPointId =
      this.approveCalculation && !this.leaseReverse ? { required } : {}

    return {
      graph: graph,
      providerId: providerId,
      providerTypeId: providerTypeId,
      dealerPointId: dealerPointId,
    }
  },
  data: () => ({
    graph: null,
    providerId: null,
    provider: null,
    providerTypeId: null,
    dealerPointId: null,
    LOModifications: [],
    dealerOutletProviders: [],
    dealerOutletProvidersLoading: false,
    leaseReverse: false,
    loading: false,
  }),
  computed: {
    validation() {
      return { v$: this.v$, name: 'setApproveCalculation' }
    },
    c() {
      /* calculation */
      return this.dialog?.dialogItem?.requestData
    },
    standardLeasingObjectType() {
      return [1, 2, 6, 7].includes(this.c.leasingObjectType?.id)
    },
    canApprove() {
      return !this.standardLeasingObjectType || !!this.c.modification
    },
    chartTypes() {
      if (!this.dialog.dialogItem) return

      const charts = Object.clone(this.dialog.dialogItem.chartTypes)

      return getDirectory('chartTypes').filter(v => {
        return charts.includes(v.value)
      })
    },
    /* Validations */
    graphErr() {
      return setErrHandler(this.v$.graph)
    },
    providerIdErr() {
      return setErrHandler(this.v$.providerId)
    },
    dealerPointIdErr() {
      return setErrHandler(this.v$.dealerPointId)
    },
    /* Current state */
    rejectCalculation() {
      return this.dialog.action === 'rejectCalculation'
    },
    approveCalculation() {
      return this.dialog.action === 'approveCalculation'
    },
  },
  methods: {
    urlGetDealerOutlets,
    urlConfirmCalculation,
    urlUnsignCalculation,
    highlightErrors() {
      this.v$.$anyError
      this.v$.$touch()
    },
    handleUpdateModelValue(newValue) {
      this.provider = null
      this.providerId = null
      this.dealerOutletProviders.splice(0)
      this.getDealerProviders(newValue)
    },
    handleUpdateProvider(newProvider) {
      this.providerId = newProvider.providerId
      this.providerTypeId = newProvider.providerTypeId
      this.provider = newProvider
    },
    async submit() {
      this.v$.$touch()
      if (!this.v$.$invalid) {
        const res = {}
        if (this.rejectCalculation) {
          const unsignRes = await this.unsign()
          Object.assign(res, unsignRes)
        }
        if (this.approveCalculation) {
          const confirmRes = await this.confirmCalculation()
          Object.assign(res, confirmRes)
        }

        if (typeof this.dialog.params?.cb === 'function') {
          await this.dialog.params.cb(res)
        }

        this.$store.commit('closeDialog')
      }
    },
    unsign() {
      return this.$axios
        .get(this.urlUnsignCalculation(this.dialog.dialogItem.id))
        .then(res => {
          if (res.data.message) {
            this.$setSnackbar({ text: res.data.message, color: 'warning' })
          } else {
            this.$setSnackbar({ text: 'Погодження відмiнено' })
          }
        })
        .catch(this.$err)
    },
    confirmCalculation() {
      this.loading = true
      const object = {}
      object.graph = this.graph
      object.providerId = this.providerId
      object.providerTypeId = this.providerTypeId
      object.dealerPointId = this.dealerPointId
      object.leaseReverse = this.leaseReverse

      return this.$axios
        .post(this.urlConfirmCalculation(this.dialog.dialogItem.id), object)
        .then(() => this.$setSnackbar({ text: 'Калькуляцiю погоджено' }))
        .finally(() => (this.loading = false))
        .catch(this.$err)
    },
    getDealerProviders(providerId) {
      if (!providerId) return

      this.dealerOutletProvidersLoading = true

      return this.$axios
        .get(urlGetDealerOutletProviders(providerId))
        .then(res => {
          const legalProvider = (res.data || []).filter(
            p => p.providerTypeId === 2
          )

          if (legalProvider?.length) {
            const provider = legalProvider?.[0]
            this.provider = provider
            this.provider.id = provider?.providerId
            this.providerId = provider?.providerId
            this.providerTypeId = provider.providerTypeId
          }

          this.dealerOutletProviders.splice(0)
          this.dealerOutletProviders.push(...res.data)
          this.dealerOutletProvidersLoading = false

          return res
        })
        .catch(err =>
          this.$err(err, () => (this.dealerOutletProvidersLoading = false))
        )
    },
  },
  created() {
    if (this.approveCalculation) {
      fillDirectory('chartTypes')
    }
  },
}
</script>
