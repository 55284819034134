<template>
  <div class="text-center" style="min-height: 220px">
    <MeetingTableTemplate title="Голова КЛП" :items="headItems">
      <template #table_crud>
        <TableCrud
          data-readonly="hide"
          :actionsArray="[
            {
              action: () =>
                action('addKlpHead', 'Голова КЛП', 'Затверджено голову КЛП'),
              text: 'Обрати голову КЛП',
              icon: 'mdi-note-plus',
              disabled: readonly,
            },
          ]"
          icon="mdi-plus-circle-outline">
        </TableCrud>
      </template>
    </MeetingTableTemplate>
    <v-divider class="my-3"></v-divider>
    <MeetingTableTemplate title="Члени КЛП" :items="membersItems">
      <template #table_crud>
        <TableCrud
          data-readonly="hide"
          :actionsArray="[
            {
              action: () =>
                action(
                  'chooseKlpParticipants',
                  'Члени КЛП',
                  'Внесено змiни в склад членiв КЛП'
                ),
              text: 'Внести змiни в склад членiв КЛП',
              icon: 'mdi-note-plus',
              disabled: readonly,
            },
          ]"
          icon="mdi-plus-circle-outline">
        </TableCrud>
      </template>
    </MeetingTableTemplate>
    <v-divider class="my-3"></v-divider>
    <MeetingTableTemplate title="Запрошені до КЛП" :items="invationsItems">
      <template #table_crud>
        <TableCrud
          data-readonly="hide"
          :actionsArray="[
            {
              action: () =>
                action(
                  'chooseKlpInvited',
                  'Запрошені на участь в КЛП',
                  'Внесено зміни в склад запрошених до КЛП'
                ),
              text: 'Внести зміни в склад запрошених до КЛП',
              icon: 'mdi-note-plus',
              disabled: readonly,
            },
          ]"
          icon="mdi-plus-circle-outline">
        </TableCrud>
      </template>
    </MeetingTableTemplate>
  </div>
</template>
<script>
import { getContactInitials } from '@/utils/helperFunc'
import { urlKlpInvationsDelete } from '@/pages/request'
import TableCrud from '@/components/table-crud.vue'
import { klpInvitedTHeaders } from '@/utils/tableHeaders'
import MeetingTableTemplate from '@/pages/projects-verification/leasing-committee/components/MeetingTableTemplate.vue'

export default {
  emits: ['dataChanged'],
  components: { MeetingTableTemplate, TableCrud },
  props: {
    project: { type: Object },
    submit: { type: Function },
    readonly: { type: Boolean, default: false },
  },
  data: () => ({
    klpInvitedTHeaders,
  }),
  computed: {
    invationsItems() {
      return this.project.invitations
        ? this.project.invitations.filter(e => e.type.id === 3)
        : []
    },
    membersItems() {
      return this.project.invitations
        ? this.project.invitations.filter(e => e.type.id === 2)
        : []
    },
    headItems() {
      return this.project.invitations
        ? this.project.invitations.filter(e => e.type.id === 1)
        : []
    },
    klpEntities() {
      return {
        ...this.project,
        members: this.project?.members?.map(m => m.id) || [],
        invitations: this.project?.invitations?.map(i => i.id) || [],
        headId: this.project?.head?.id || null,
      }
    },
  },
  methods: {
    getContactInitials,
    urlKlpInvationsDelete,
    action(action, title, completeMsg) {
      this.$store.commit('setDialog', {
        title: title,
        dialogItem: this.klpEntities,
        params: {
          project: this.project,
          submit: this.submit,
          cb: () => {
            this.$setSnackbar({ text: completeMsg })
            this.$emit('dataChanged')
          },
        },
        action: action,
      })
    },
  },
}
</script>
