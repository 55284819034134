<template>
  <v-data-table
    v-if="tBody"
    :headers="tHead"
    :items="tBody"
    :hide-default-footer="hideFooter"
    :hide-default-header="!tHead.length"
    class="pb-2 pt-1 custom-table head-line"
    dense>
    <template #no-data>
      <no-data></no-data>
    </template>
  </v-data-table>
</template>
<script>
import noData from '@/components/no-table-data.vue'
export default {
  components: { noData },
  props: {
    tHead: {
      type: Array,
      default: () => [],
    },
    tBody: {
      type: Array,
      default: () => [],
    },
    hideFooter: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
