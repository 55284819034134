<template>
  <div>
    <v-tabs
      v-model="currentTab"
      class="elevation"
      background-color="grey darken-2"
      show-arrows
      color="white"
      dark
      dense
      slider-color="#b0c4de"
      :height="38">
      <v-tab replace :to="{ name: 'front-sales-plan' }"> План продаж </v-tab>
      <v-tab replace :to="{ name: 'front-activities-plan' }">
        План зустріч
      </v-tab>
    </v-tabs>
    <keep-alive>
      <RouterView></RouterView>
    </keep-alive>
  </div>
</template>
<script>
export default {
  data: () => ({
    currentTab: 0,
  }),
}
</script>
