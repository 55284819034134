function formutSum(str = '') {
  str = keepDigit(str)

  if (!str && str !== '0') return '0'

  const tempStr = str
  const delimiterIndex = str.indexOf('.')

  // @ts-ignore
  // eslint-disable-next-line
  str = new Intl.NumberFormat('ru-RU').format(str).replace(/\,/g, '.')

  if (delimiterIndex !== -1) {
    const differenceNum = str?.length + 1 - tempStr?.length
    str = (
      str.substr(0, delimiterIndex + differenceNum) +
      '.' +
      str.substr(delimiterIndex + differenceNum)
    ).replace(/\.(?=.*\.)/, '')
  }

  return str
}

function keepDigit(str) {
  /** @type {string} */
  let numStr = str
    .toString()
    .replace(/\.(?=.*\.)/, '')
    .replace(/[^\d.-]/g, '')
    .replace(/(?!^)-/, '')

  if (numStr && numStr[0] === '.') return numStr.replace(/\D/g, '')
  if (!numStr && numStr !== '0') return ''
  if (numStr.match(/^0\d/g)) {
    numStr = parseFloat(
      (numStr.substring(0, 1) + '.' + numStr.substring(1)).replace(
        /\.(?=.*\.)/,
        ''
      )
    ).toString()
  }
  if (numStr.match(/^0\.$/g)) return '0'
  return numStr
}

function humanDate(date) {
  if (!date || (!date.includes('-') && !date.includes('.'))) return '--'

  const dashParts = date.split('-')
  const dotParst = date.split('.')

  if (dashParts.length >= 3) {
    const [year, month, day] = dashParts
    return `${day}.${month}.${year}`
  } else {
    const [year, month, day] = dotParst
    return `${day}.${month}.${year}`
  }
}

// module.exports.formutSum = formutSum
// module.exports.keepDigit = keepDigit
// module.exports.formutSum = formutSum
// module.exports.humanDate = humanDate

export { humanDate, keepDigit, formutSum }
