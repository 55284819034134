import { ref } from 'vue'
import { City, Street, House } from '@/utils/types'

const apiUrl = (route: string) => {
  return `https://api.dmsolutions.com.ua:2661${route}`
}
import axios from '@/plugins/axios.js'

const token = ref()
const getAuthorization = (): string | null => {
  if (token.value) {
    return `Bearer ${token.value}`
  } else {
    return null
  }
}

const checkApiToken = () => {
  if (!token.value) {
    throw new Error('Address API - missing API token')
  }
}

const checkFunctionParams = (
  param: any,
  paramName: string,
  functionName: string
): param is string => {
  if (!param) {
    throw new Error(`${functionName}() ${paramName} - required parameter`)
  } else {
    return true
  }
}

function getAddressApiToken(): Promise<void> {
  const credentials = new URLSearchParams({
    username: import.meta.env.VITE_API_ADDRESS_USER,
    password: import.meta.env.VITE_API_ADDRESS_PASSWORD,
    grant_type: 'password',
  })
  return axios({
    url: apiUrl('/Token'),
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    data: credentials,
  }).then(res => {
    token.value = res.data.access_token
  })
}

function getCities(city: string): Promise<Array<City>> {
  checkApiToken()
  checkFunctionParams(city, 'city', 'getCities')

  return axios({
    url: apiUrl(`/api/Cities?sRequest=${city}&sLang=uk_UA`),
    method: 'get',
    headers: {
      Authorization: getAuthorization(),
    },
  }).then(res => res.data)
}

function getStreets(
  street: string,
  streetMoniker: string
): Promise<Array<Street>> {
  checkApiToken()
  checkFunctionParams(street, 'street', 'getStreets')
  checkFunctionParams(streetMoniker, 'streetMoniker', 'getStreets')

  return axios({
    url: apiUrl(
      `/api/Streets?sRequest=${street}&stMoniker=${streetMoniker}&sLang=uk_UA`
    ),
    method: 'get',
    headers: {
      Authorization: getAuthorization(),
    },
  }).then(res => res.data)
}

function getHouses(
  houseNum: string,
  houseMoniker: string
): Promise<Array<House>> {
  checkApiToken()
  checkFunctionParams(houseNum, 'houseNum', 'getHouses')
  checkFunctionParams(houseMoniker, 'houseMoniker', 'getHouses')

  return axios({
    url: apiUrl(
      `/api/Houses?sRequest=${houseNum}&houseMoniker=${houseMoniker}&sLang=uk_UA`
    ),
    method: 'get',
    headers: {
      Authorization: getAuthorization(),
    },
  }).then(res => res.data)
}

async function generateAddress(address: {
  zip: string
  parts: {
    atu: string
    street: string
    house: string
  }
}) {
  checkApiToken()
  checkFunctionParams(address, 'address', 'generateAddress')
  if (!address || !address.parts || !address.zip) return

  function getCity(city: string): string {
    checkFunctionParams(city, 'city', 'getCity')

    if (city.split(',').length > 1) {
      const cityPart = city.split(',').find(p => p.includes('місто'))

      if (cityPart) {
        return cityPart.replace(/місто[\s+]/gi, '').trim()
      } else {
        return city.trim()
      }
    } else {
      return city.replace(/місто[\s+]/gi, '').trim()
    }
  }

  function getStreet(street: string): string {
    checkFunctionParams(street, 'street', 'getStreet')
    return street.replace(/ВУЛИЦЯ/gi, '').trim()
  }

  function getHouse(house: string): string {
    checkFunctionParams(house, 'house', 'getHouse')
    const matchedNums = house.match(/^(\d+)/gi)
    if (matchedNums?.length) {
      return matchedNums[0]
    } else {
      throw new Error('getHouse() house is invalid')
    }
  }
  function upperCase(str: string): string | null {
    if (!str) return null
    return str.toString().toUpperCase()
  }

  try {
    const parts = address.parts
    const cityString = getCity(parts.atu)
    if (!cityString) {
      throw new Error(`generateAddress() - city is invalid: ${parts.atu}`)
    }
    const streetString = getStreet(parts.street)
    const houseString = getHouse(parts.house)
    const zipString = address.zip

    let city: City = {
      City: cityString,
      Id: '1',
      st_moniker: '1',
    } as City
    let street: Street = {
      Street: streetString,
      StreetId: '1',
      house_moniker: '1',
    } as Street
    let house: House = {
      HouseId: '1',
      HouseNum: houseString,
      Index_: zipString,
    } as House

    /** city */
    if (cityString) {
      const cities = await getCities(cityString)
      city = cities.find(
        c => upperCase(c.City) === upperCase(cityString)
      ) as City
    }

    /** street */
    if (streetString && city?.st_moniker) {
      const streets = await getStreets(streetString, city.st_moniker)
      street = streets.find(
        s => upperCase(s.Street) === upperCase(streetString)
      ) as Street
    }

    /** house */
    if (houseString && street?.house_moniker) {
      const houses = await getHouses(houseString, street.house_moniker)
      house = houses.find(h => {
        return String(upperCase(h.HouseNum)).includes(
          upperCase(houseString) as string
        )
      }) as House
    }

    return {
      addressTypeId: 1,
      country: 'Україна',
      city,
      street,
      house,
    }
  } catch (err) {
    console.log(err)
    throw err
  }
}

export { getAddressApiToken, getCities, getStreets, getHouses, generateAddress }
