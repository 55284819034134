<template>
  <ProjectHistoryTable :project="project" />
</template>

<script>
import ProjectHistoryTable from '@/components/ProjectHistoryTable.vue'
export default {
  components: { ProjectHistoryTable },
  props: {
    project: { type: Object },
  },
}
</script>

<style scoped></style>
