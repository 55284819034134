import {
  urlCarForSaleSetSale,
  urlCarForSaleSingle,
  urlCarForSaleUpdate,
} from '@/pages/request.js'
import axios from '@/plugins/axios.js'
import { useCar } from './car'
import { handleAsync, setSnackbar } from 'best-modules/plugins'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { pick } from 'lodash'
import { useStore } from 'vuex'
import { Car } from './types'

export function useCarUpdate() {
  const store = useStore()
  const route = useRoute()
  const carId = computed(() => Number(route.params.id))
  const { car, getRequestObject, v$, handleCarApi } = useCar()

  const setCar = (newCar: Record<string, any>) => {
    car.$set({
      leasingObjectType: newCar.category,
      leasedAssertMark: newCar.mark,
      leasedAssertModel: newCar.model,
      leasingObjectYear: newCar.year,
      generation: newCar.generation,
      body: newCar.body,
      modification: newCar.modification,
      specification: newCar.specification,
      engine: newCar.engine,
      transmission: newCar.transmission,
      price: newCar.price,
      carNumber: newCar.carNumber,
      photos: newCar.photos,
      mileage: newCar.mileage,
      description: newCar.description,
      vinCode: newCar.vinCode,
      link: newCar.link,
      ...pick(newCar, [
        'status',
        'statusId',
        'responsible',
        'responsibleId',
        'created_at',
        'updated_at',
        'id',
      ]),
    } as Car)
  }

  const getCar = () => {
    return handleAsync('carForSaleSingle', () => {
      return axios.get(urlCarForSaleSingle(carId.value)).then(res => {
        setCar(res.data)
        const name = [res.data.mark?.name, res.data.model?.name]
          .filter(Boolean)
          .join(' ')
        store.commit('setBreadScrumbPart', [null, name])
      })
    })
  }
  const updateCar = () => {
    return handleAsync('carForSaleSubmit', () => {
      return axios
        .post(urlCarForSaleUpdate(carId.value), getRequestObject())
        .then(res => {
          setCar(res.data)
          setSnackbar({ text: 'Дані збережено' })
        })
    })
  }

  const submit = () => {
    v$.value.$validate()
    if (!v$.value.$invalid) {
      return updateCar()
    }
  }

  const cancel = () => {
    return car.$reset()
  }

  const setSale = () => {
    return handleAsync('carForSaleSetSale', () => {
      return axios.get(urlCarForSaleSetSale(carId.value)).then(res => {
        return setCar(res.data)
      })
    })
  }

  getCar()

  return { car, v$, submit, cancel, handleCarApi, setSale }
}
