<template>
  <v-dialog v-model="dialog" :width="730" persistent>
    <v-card>
      <v-toolbar
        style="
          font-weight: 500 !important;
          font-size: 16px;
          box-shadow: none;
          background: #f6f6f6;
        "
        :height="60">
        <v-toolbar-title style="font-size: 1rem"
          >Будь ласка, визначте <b>один або декілька</b> інших видів діяльності
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon color="#cccccc">{{ 'mdi-close-circle-outline' }}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text style="min-height: 306px">
        <v-text-field
          v-model="search"
          :loading="$loading.isLoading('quedList')"
          placeholder="Введіть номер або назву кведа">
        </v-text-field>
        <div class="queds" style="font-size: 13px">
          <div
            v-for="qued in visibleQuedsList"
            :key="qued?.code"
            class="qued"
            :class="{ 'qued-selected': isSelected(qued) }"
            @click="() => addQued(qued)">
            <span class="qued__title">
              <v-icon v-show="isSelected(qued)" size="small" color="red">{{
                'mdi-check'
              }}</v-icon>
              <span>
                {{ `${qued.code} ${qued.title}` }}
              </span>
            </span>
            <v-btn
              v-show="isSelected(qued)"
              size="x-small"
              icon
              @click.stop="deleteQued(qued.id)">
              <v-icon small>{{ 'mdi-close' }}</v-icon>
            </v-btn>
          </div>
          <div
            v-if="!visibleQuedsList.length"
            class="disabled text-center"
            style="font-size: 16px">
            Список порожній
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="pb-2">
        <ActionButtons
          :confirm="submit"
          :cancel="reject"
          confirmBtnText="Підтвердити" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ActionButtons from '@/components/action-buttons.vue'
import { useSelect } from '@/utils/mixins/useSelect'
import { computed } from 'vue'
import { fillDirectory, getDirectory } from '@/plugins/directory'
export default {
  components: {
    ActionButtons,
  },
  emits: ['update:modelValue', 'submit'],
  props: {
    modelValue: { type: Boolean, required: true },
    queds: { type: Array, required: true },
  },
  setup(props, { emit }) {
    const dialog = computed({
      get() {
        return props.modelValue
      },
      set(val) {
        emit('update:modelValue', val)
      },
    })

    return {
      dialog,
      ...useSelect(),
    }
  },
  data() {
    return {
      selectedQueds: [],
      search: '',
    }
  },
  computed: {
    visibleQuedsList() {
      const searchQueds = getDirectory('quedList')
        .filter(qued => {
          const hasSearch =
            this.search && `${qued.code} ${qued.title}`.includes(this.search)

          return hasSearch && !this.isSelected(qued)
        })
        .filter((_, idx) => idx < Math.max(7 - this.selectedQueds.length, 3))

      return Object.clone([...searchQueds, ...this.selectedQueds]).sort(
        (a, b) => {
          return this.selectedQueds.includes(b.id) ? 0 : b ? -1 : 1
        }
      )
    },
  },
  methods: {
    reject() {
      this.dialog = false
      this.selectedQueds = Object.clone(this.queds || [])
      this.search = ''
    },
    submit() {
      this.$emit('submit', Object.clone(this.selectedQueds))
      this.dialog = false
      this.search = ''
    },
    addQued(qued) {
      if (this.isSelected(qued)) return
      this.selectedQueds = [...this.selectedQueds, qued]
    },
    deleteQued(quedId) {
      this.selectedQueds = this.selectedQueds.filter(q => q.id !== quedId)
    },
    isSelected(qued) {
      return this.selectedQueds.map(q => q.id).includes(qued.id)
    },
  },
  watch: {
    queds: {
      handler(val) {
        this.selectedQueds = Object.clone(val || [])
      },
      immediate: true,
    },
    dialog(val) {
      val && fillDirectory('quedList')
    },
  },
}
</script>

<style lang="scss" scoped>
.qued {
  padding: 2px 5px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: 0.3s;
  border-bottom: 1px solid #838282;
  color: #838282;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__title {
    transition: 0.3s;
  }
  &:not(.qued-selected):hover {
    color: #000;
    background: #efefef;
    & .qued__title {
      transform: translateX(4px);
    }
  }
  &-selected {
    color: #000;
    cursor: default;
  }
}
</style>
