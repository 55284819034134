<template>
  <div>
    <BSectionLoader :loading="$loading.isLoading('getLeadCallSingle')">
      <div>
        <div
          v-if="!leadCallData.isCreateLead"
          class="d-flex justify-end gap-2 mr-2">
          <v-btn color="green" variant="flat" @click="createLeadByCall"
            >Створити лід</v-btn
          >
          <v-btn
            color="red"
            variant="flat"
            :loading="$loading.isLoading('cancelLeadByCall')"
            @click="cancelLeadByCall"
            >Скасувати</v-btn
          >
        </div>
        <div class="d-block flex-column">
          <div>
            <div class="mb-2 font-weight-bold">
              Відповідальний:
              {{
                getContactInitials(leadCallData?.detail_call?.user) ||
                'дані відсутні'
              }}
            </div>
            <div class="mb-2 font-weight-bold">
              Телефон:
              {{ leadCallData?.detail_call?.phone }}
            </div>
            <div class="mb-2 font-weight-bold">
              Статус дзвінка:
              {{ leadCallData?.detail_call?.status_call?.name || '---' }}
            </div>
          </div>

          <div class="mt-3">
            <div>
              Тип дзвiнка:
              {{ leadCallData?.detail_call?.type_call?.name }}
            </div>
            <div>
              Платформа:
              {{ leadCallData?.detail_call?.telephony?.name }}
            </div>
            <div>
              Початок дзвiнка:
              {{ toFormatDate(leadCallData?.detail_call?.startTime) }}
            </div>
            <div>
              Завершення дзвiнка:
              {{ toFormatDate(leadCallData?.detail_call?.endTime) }}
            </div>
          </div>

          <div v-if="connections.length" class="connect w-50 mt-3">
            <h3>Звʼязки</h3>
            <div v-for="(item, idx) in connections" :key="idx" class="item">
              <v-tooltip :text="item.name">
                <template #activator="{ props }">
                  <span class="name" style="width: 200px" v-bind="props">{{
                    item?.name
                  }}</span>
                </template>
              </v-tooltip>
              <span class="d-block text-start" style="width: 100px">{{
                item.type?.name
              }}</span>
              <span
                class="link d-block"
                @click="$router.replace(getEntityRoute(item.id, item.type.id))"
                >Перейти</span
              >
            </div>
          </div>
        </div>
      </div>
    </BSectionLoader>
  </div>
</template>

<script lang="ts">
import { handleAsync, setSnackbar } from 'best-modules/plugins'
import {
  urlCancelAdminLeadCall,
  urlGetAdminLeadCallSingle,
} from '@/pages/request'
import { useRoute } from 'vue-router'
import { computed, ref } from 'vue'
import { sendRequest } from 'best-modules/utils/request/request'
import { BSectionLoader } from 'best-modules/components'
import {
  getContactInitials,
  getEntityRoute,
  toFormatDate,
} from '@/utils/helperFunc'
import router from '@/router/router'
import { useStore } from 'vuex'

export default {
  components: {
    BSectionLoader,
  },
  methods: { getEntityRoute, toFormatDate, getContactInitials },
  setup() {
    const route = useRoute()
    const leadCallData = ref({})
    const store = useStore()

    const getSingleCard = () => {
      return handleAsync('getLeadCallSingle', () => {
        return sendRequest({
          name: 'getLeadCallSingle',
          url: urlGetAdminLeadCallSingle(route.params.id),
        }).then(res => {
          leadCallData.value = res
        })
      })
    }

    const cancelLeadByCall = () => {
      return handleAsync('cancelLeadByCall', () => {
        return sendRequest({
          name: 'cancelLeadCall',
          url: urlCancelAdminLeadCall(route.params.id),
          method: 'get',
        }).then(res => {
          if (res.data.message) {
            setSnackbar({
              text: res.data.message,
              color: 'warning',
            })
          }
          setSnackbar({
            text: 'Дзвінок скасовано',
          })
          return router.replace({ name: 'admin-lead-calls' })
        })
      })
    }

    const createLeadByCall = () => {
      store.commit('setDialog', {
        title: 'Створити лід',
        dialogItem: { id: route.params.id },
        params: {
          cb: res =>
            router.push({
              name: 'edit-lead',
              params: { id: res.data.leadId },
            }),
        },
        action: 'createLeadByCall',
      })
    }

    const connections = computed(() => {
      const application = leadCallData.value?.detail_call?.application || null
      const lead = leadCallData.value?.detail_call?.lead || null
      const contact = leadCallData.value?.detail_call?.contact || null
      const contractor = leadCallData.value?.detail_call?.contractor || null

      const result = []

      if (application) {
        result.push({
          type: {
            name: 'Заявка',
            id: 5,
          },
          name: application.applicationName,
          id: application.id,
          obj: application,
        })
      }
      if (lead) {
        result.push({
          type: {
            name: 'Лід',
            id: 4,
          },
          name: lead.name,
          id: lead.id,
          obj: lead,
        })
      }
      if (contact) {
        result.push({
          type: {
            name: 'Контакт',
            id: 1,
          },
          name: contact.fullName
            ? contact.fullName
            : getContactInitials(contact, { isResponsible: true }),
          id: contact.id,
          obj: contact,
        })
      }
      if (contractor) {
        result.push({
          type: {
            name: 'Контрагент',
            id: 2,
          },
          name: contractor.shortName || contractor.fullName,
          id: contractor.id,
          obj: contractor,
        })
      }

      return result
    })

    getSingleCard()

    return {
      leadCallData,
      cancelLeadByCall,
      createLeadByCall,
      connections,
    }
  },
}
</script>

<style scoped></style>
