<template>
  <div style="margin-right: 10px; margin-left: 10px">
    <SectionLoader v-if="loading" />
    <div v-else>
      <div class="d-flex align-center mb-5 justify-space-between">
        <v-tabs
          v-model="currentTab"
          style="margin-left: -5px !important"
          :height="$vuetify.breakpoint?.xs ? null : 38"
          color="white"
          dark
          slider-color="#fc7247"
          dense>
          <v-tab
            replace
            :to="{
              name: 'book-payment-general',
              params: { id: $route.params.id },
            }">
            Основна інформація
          </v-tab>
        </v-tabs>
        <div class="d-flex align-center">
          <span
            class="d-block mr-2"
            style="color: rgb(131, 130, 130); font-size: 14px"
            >Статус:</span
          >
          <span style="color: rgb(8, 72, 122); font-weight: 600">{{
            payment?.status?.name || 'Новий'
          }}</span>
        </div>
      </div>

      <RouterView
        v-model:payment="payment"
        style="min-height: 390px"
        :cb="() => $store.commit('refreshPage')">
      </RouterView>

      <ActionButtons
        v-if="payment.statusId !== 3"
        hideCancel
        confirmBtnText="Підтвердити виплату"
        :confirm="confirmPaymentAct"
        :confirmLoading="loading">
      </ActionButtons>
    </div>
  </div>
</template>

<script>
import { setCache } from '@/utils/helperFunc'
import ActionButtons from '@/components/action-buttons.vue'
import SectionLoader from '@/components/section-loader.vue'
import {
  urlGetSingleAgentPaymentAct,
  urlSetStatusPaymentAct,
} from '@/pages/request'
export default {
  components: { ActionButtons, SectionLoader },
  data: () => ({
    currentTab: 0,
    loading: false,
    pageLoad: false,
    payment: {},
    cache: null,
  }),
  computed: {
    cacheState() {
      return setCache([this.payment])
    },
  },
  methods: {
    urlGetSingleAgentPaymentAct,
    urlSetStatusPaymentAct,
    getSinglePayment() {
      this.loading = true
      return this.$axios
        .get(urlGetSingleAgentPaymentAct(this.$route.params.id))
        .then(res => {
          this.payment = res.data
          this.cache = setCache([this.payment])
          return res
        })
        .finally(() => (this.loading = false))
        .catch(this.$err)
    },
    confirmPaymentAct() {
      const status = {
        statusId: 3,
      }
      return this.$axios
        .post(urlSetStatusPaymentAct(this.$route.params.id), status)
        .then(() => {
          return (
            this.$setSnackbar({ text: 'Виплата здійснена' }),
            this.$store.commit('refreshPage')
          )
        })
    },
    setBreadScrumb() {
      this.$store.commit('setBreadScrumbPart', [null, this.payment.id, null])
    },
  },
  watch: {
    loading(val) {
      if (!val) setTimeout(() => (this.cache = setCache([this.payment])), 1000)
    },
  },
  async created() {
    await this.getSinglePayment()
  },
}
</script>

<style scoped lang="scss">
a.v-tab-item--selected.v-tab {
  background: #f2f5fa;
  border: 1px solid #1c1d21;
  box-shadow: 0 2px 8px rgb(0 0 0 / 4%);
  border-radius: 4px;
}
a.v-tab {
  margin-right: 25px;
  border: 1px solid #eaeaea;
  background: #eaeaea;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}
.list-item {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.subtitle {
  color: #838282;
  font-weight: 500;
  font-size: 13px;
  display: block;
}
.card-block-title {
  display: block;
  color: #08487a;
  font-weight: 600;
  font-size: 16px;
  margin-left: 9px;
}
.title-label {
  font-weight: 600;
  font-size: 15px;
  color: #1c1d21;
  margin-top: 5px;
  font-weight: 400 !important;
}
.data-card {
  display: flex;
  flex-direction: column;
}
.container.container--fluid {
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin: 0 !important;
}

.text-desc {
  font-weight: 400;
  font-size: 16px;
  color: #1c1d21;
  display: block;
}
</style>
