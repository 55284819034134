<template>
  <div class="mt-2">
    <FieldsColumnBlock title="Прикріплення документів">
      <template #default>
        <FilesUploader
          v-model:documents="contractData.scanServiceDocument"
          label="Договір сервісу (підписаний)"
          :iconSize="26"
          single
          :readonly="readonly">
        </FilesUploader>
        <FilesUploader
          v-model:documents="contractData.otherDocument"
          label="Необов`язкові документи"
          icon="mdi-file-document-multiple-outline"
          :readonly="readonly">
        </FilesUploader>
      </template>
    </FieldsColumnBlock>
  </div>
</template>
<script>
import FilesUploader from '@/components/FilesUploader.vue'
import { mapState } from 'vuex'
import { urlSetDocumentServiceContract } from '@/pages/request'
import FieldsColumnBlock from '@/pages/contracts/components/FieldsColumnBlock.vue'

export default {
  components: { FieldsColumnBlock, FilesUploader },
  props: {
    serviceContract: { type: Object },
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    readonly() {
      return ![1, 5, 6].includes(this.user.roleId)
    },
    contractData() {
      return this.serviceContract
    },
  },
  methods: {
    setDocument(document) {
      return this.$axios
        .post(urlSetDocumentServiceContract(this.serviceContract.id), document)
        .then(() => {
          return this.$setSnackbar({ text: 'Документ оновлено' })
        })
    },
  },
  watch: {
    'contractData.scanServiceDocument': {
      deep: true,
      handler(newValue) {
        this.setDocument({ scanServiceDocument: newValue })
      },
    },
    'contractData.otherDocument': {
      deep: true,
      handler(newValue) {
        this.setDocument({ otherDocument: newValue })
      },
    },
  },
}
</script>
