<template>
  <div>
    <div v-if="calculationRoute">
      <RouterView :calculation="calculation" />
    </div>
    <span v-if="!calculation">Калькуляція на доопрацюванні</span>
    <component
      :is="isContractor ? 'ContractorCard' : 'ContactCard'"
      v-if="!calculationRoute"
      :contractor="provider"
      :contact="provider"
      legalDocs
      :isNew="LDAnalyze.status?.id === 1 && roleAccess"
      :finDocsOpenable="false"
      :finDocs="isContractor ? ['balanceF1AndF2'] : []">
      <template #after>
        <div style="background: #fff; padding: 13px">
          <v-row>
            <v-col :cols="12" :sm="3" class="pt-1 pb-1">
              <div class="d-flex align-center">
                <span class="d-block">
                  <svg
                    class="mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="14"
                    viewBox="0 0 10 14"
                    fill="none">
                    <path
                      d="M7.34035 3.30275C7.34035 3.23716 7.29248 3.18349 7.23397 3.18349H2.12764C2.06913 3.18349 2.02126 3.23716 2.02126 3.30275V4.01835C2.02126 4.08395 2.06913 4.13761 2.12764 4.13761H7.23397C7.29248 4.13761 7.34035 4.08395 7.34035 4.01835V3.30275ZM7.23397 5.33028H2.12764C2.06913 5.33028 2.02126 5.38394 2.02126 5.44954V6.16514C2.02126 6.23073 2.06913 6.2844 2.12764 6.2844H7.23397C7.29248 6.2844 7.34035 6.23073 7.34035 6.16514V5.44954C7.34035 5.38394 7.29248 5.33028 7.23397 5.33028ZM4.57442 7.47706H2.12764C2.06913 7.47706 2.02126 7.53073 2.02126 7.59633V8.31193C2.02126 8.37752 2.06913 8.43119 2.12764 8.43119H4.57442C4.63293 8.43119 4.6808 8.37752 4.6808 8.31193V7.59633C4.6808 7.53073 4.63293 7.47706 4.57442 7.47706ZM3.93613 12.0688H0.957437V1.57339H8.40417V6.70183C8.40417 6.76743 8.45204 6.8211 8.51055 6.8211H9.25522C9.31373 6.8211 9.3616 6.76743 9.3616 6.70183V0.977064C9.3616 0.713188 9.17145 0.5 8.93608 0.5H0.425527C0.190158 0.5 0 0.713188 0 0.977064V12.6651C0 12.929 0.190158 13.1422 0.425527 13.1422H3.93613C3.99464 13.1422 4.04251 13.0885 4.04251 13.0229V12.1881C4.04251 12.1225 3.99464 12.0688 3.93613 12.0688ZM8.68076 10.9596C9.06639 10.5675 9.30841 10.001 9.30841 9.37041C9.30841 8.18521 8.45071 7.22362 7.39354 7.22362C6.33637 7.22362 5.47867 8.18521 5.47867 9.37041C5.47867 10.001 5.72068 10.5675 6.10632 10.9596C5.34835 11.4442 4.82708 12.3416 4.78718 13.3763C4.78452 13.4433 4.83373 13.5 4.89357 13.5H5.53319C5.58904 13.5 5.63558 13.4508 5.63957 13.3867C5.69143 12.355 6.45871 11.5321 7.39354 11.5321C8.32837 11.5321 9.09565 12.355 9.14751 13.3867C9.15017 13.4493 9.19671 13.5 9.25389 13.5H9.89351C9.95468 13.5 10.0026 13.4433 9.99989 13.3763C9.96133 12.3401 9.43873 11.4442 8.68076 10.9596ZM7.39354 8.17775C7.9813 8.17775 8.45736 8.71147 8.45736 9.37041C8.45736 10.0294 7.9813 10.5631 7.39354 10.5631C6.80578 10.5631 6.32972 10.0294 6.32972 9.37041C6.32972 8.71147 6.80578 8.17775 7.39354 8.17775Z"
                      fill="#08487A" />
                  </svg>
                </span>
                <span
                  class="d-block"
                  style="
                    color: rgb(8, 72, 122);
                    font-weight: 500;
                    font-size: 15px;
                  "
                  >Наявність або відсутність<br />обмежень</span
                >
              </div>
            </v-col>
            <v-col :cols="12" :sm="9" class="text-start">
              <span class="label"
                >Відомості про наявність або відсутність обмежень</span
              >
              <v-textarea
                v-model="
                  conclusionData.providersResults[conclusionIdx]
                    .providerConclusion
                "
                placeholder="Введіть текст"
                :rows="5"
                solo
                hide-details
                :readonly="readonly">
              </v-textarea>
            </v-col>
          </v-row>
        </div>
      </template>
    </component>
  </div>
</template>

<script>
import ContactCard from '@/components/ContactCard.vue'
import ContractorCard from '@/components/ContractorCard.vue'
import { mapState } from 'vuex'

export default {
  components: { ContactCard, ContractorCard },
  props: {
    calculation: { type: Object },
    provider: { type: Object },
    conclusion: { type: Object },
    readonly: { type: Boolean, default: false },
    LDAnalyze: { type: Object },
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    roleAccess() {
      return [10, 11, 19].includes(this.user?.role?.id)
    },
    conclusionData() {
      return this.conclusion
    },
    isContact() {
      return [1, 3].includes(this.provider.providerTypeId)
    },
    isContractor() {
      return this.provider.providerTypeId === 2
    },
    conclusionIdx() {
      const idx = this.conclusion.providersResults.findIndex(
        c => `${c.providerTypeId}${c.providerId}` === this.provider.providerId
      )
      return idx >= 0 ? idx : 0
    },
    calculationRoute() {
      return this.$route.name === 'ld-leasing-object'
    },
  },
}
</script>

<style scoped></style>
