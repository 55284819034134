<template>
  <v-col :cols="12" :md="3" class="pl-0 pr-3 common-data">
    <div class="mb-3" style="background: #ffffff; border-radius: 4px">
      <span class="card-block-title" style="padding: 10px">Загальнi данi</span>
      <v-card-text class="pt-0">
        <div class="block-left">
          <span class="area-label">Назва</span>
          <v-text-field
            v-model="dealerData.name"
            dense
            :readonly="
              !(user.canCreateDealerPoint || [1, 4].includes(user.roleId))
            ">
          </v-text-field>
        </div>
        <div class="block-left">
          <span class="area-label">Тип</span>
          <v-select
            v-model="dealerData.typeId"
            :items="directoryItems('contractorDealerTypes', dealerData.type)"
            :error-messages="typeIdErr"
            item-title="name"
            item-value="id"
            dense
            :readonly="readOnly"
            @focus="asyncAction('addContractorDealerTypes')"
            @update:model-value="dealerData.categoryId = null"
            @blur="getSafe(() => $v.dealerData.typeId.$touch())">
          </v-select>
        </div>
        <div v-if="dealerData.typeId === D.type.DEALER" class="block-left">
          <span class="area-label">Категорія</span>
          <v-select
            v-model="dealerData.categoryId"
            :error-messages="categoryIdErr"
            :items="$directory.get('dealerCategories', dealerData.category)"
            :loading="$loading.isLoading('dealerCategories')"
            item-title="name"
            item-value="id"
            @update:model-value="changeDealerCategory"
            @focus="$directory.fill('dealerCategories')"
            @blur="getSafe(() => $v.dealerData.categoryId.$touch())">
          </v-select>
        </div>
        <div class="block-left">
          <span class="area-label">Основний напрямок</span>
          <v-select
            v-model="dealerData.mainDirectionId"
            :items="
              $directory.get('dealerPointDirections', dealerData.main_direction)
            "
            item-title="name"
            item-value="id"
            dense
            placeholder="оберіть зі списку"
            :readonly="readOnly"
            :loading="$loading.isLoading('dealerPointDirections')"
            @focus="$directory.fill('dealerPointDirections')">
          </v-select>
        </div>
        <div class="block-left">
          <ChangeResponsible
            v-model:responsibleId="dealerData.responsibleId"
            :read-only="dealerData.readOnly"
            :responsible="dealerData.responsible" />
        </div>
        <div
          class="block-checkbox"
          :style="changeResponsible ? 'margin-top: 40px' : ''">
          <v-checkbox
            v-model="dealerData.signsOfTradeBoo"
            dense
            :readonly="readOnly">
            <template #label>
              <span style="font-size: 0.9rem">Торгівля Б/У</span>
            </template>
          </v-checkbox>
        </div>
      </v-card-text>
    </div>
    <div style="background: #ffffff; border-radius: 4px">
      <v-card-text>
        <div class="block-left">
          <span class="area-label">Лояльність</span>
          <v-select
            v-model="dealerData.loyaltyId"
            :items="$directory.get('loyaltyPrograms', dealerData.loyalty)"
            item-title="name"
            item-value="id"
            dense
            placeholder="Оберіть зі списку"
            readonly>
          </v-select>
        </div>
        <div
          class="block-left"
          :style="{
            'margin-bottom': dealerData.otherLkIds.length > 2 ? '50px' : '5px',
          }">
          <span class="area-label">Програми з іншими лк</span>
          <v-select
            v-model="dealerData.otherLkIds"
            :items="$directory.get('otherLk', dealerData.other_lk).flat()"
            placeholder="Оберіть зi списку"
            item-title="name"
            item-value="id"
            multiple
            chips
            small-chips
            deletable-chips
            :list-props="{
              density: 'compact',
              slim: true,
            }"
            density="compact"
            :return-object="false"
            dense
            :readonly="readOnly"
            :loading="$loading.isLoading('otherLk')"
            @focus="$directory.fill('otherLk')" />
        </div>
      </v-card-text>
    </div>
  </v-col>
</template>
<script>
import { dealer as D } from '@/type-ids'
import { mapState, mapGetters } from 'vuex'
import {
  setErrHandler,
  getResponsibleShortName,
  getContactInitials,
  getSafe,
} from '@/utils/helperFunc'
import { urlDealerPointChangeResponsible } from '@/pages/request'
import ChangeResponsible from '@/components/ChangeResponsible.vue'
import { useSelect } from '@/utils/mixins/useSelect'

export default {
  components: { ChangeResponsible },
  setup() {
    return {
      ...useSelect(),
    }
  },
  props: {
    dealer: { type: Object },
    readOnly: { type: Boolean },
    v: { type: Object },
  },
  data: () => ({
    D: D,
    changeResponsible: false,
  }),
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
      user: state => state.user,
    }),
    ...mapGetters({
      activeUsers: 'activeUsers',
    }),
    dealerData() {
      return this.dealer
    },
    $v() {
      return this.v
    },
    categoryIdErr() {
      return setErrHandler(this.$v.dealerData?.categoryId)
    },
    typeIdErr() {
      return setErrHandler(this.$v.dealerData?.typeId)
    },
  },
  methods: {
    getSafe,
    getContactInitials,
    getResponsibleShortName,
    changeDealerCategory(delaerCategoryId) {
      if ([3 /* commision platform */].includes(delaerCategoryId)) {
        this.dealerData.contractorId = null
        this.dealerData.contractor = null
      }
    },
    refChangeResponsible(id) {
      return this.$axios
        .post(urlDealerPointChangeResponsible(this.dealerData.id), {
          newResponsibleId: id,
        })
        .then(res => {
          this.$setSnackbar({ text: 'Відповідального змінено' })
          this.$store.commit('refreshPage')
          return res.data
        })
    },
  },
  watch: {
    'dealerData.responsibleId': {
      handler(newVal) {
        if (newVal) {
          this.refChangeResponsible(newVal)
        }
      },
      immediate: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.res-post {
  display: block;
  width: 215px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 13px;
}
.res-block {
  margin-bottom: 15px;
  &:hover .res-change {
    display: block !important;
  }
  .res-change {
    display: none;
    cursor: pointer;
    color: #09487a;
    font-weight: 500;
    font-size: 13px;
  }
}
.text {
  &-field {
    margin-bottom: 15px;
  }
  &-title {
    color: #1c1d21;
    font-weight: 600;
    font-size: 15px;
    margin-right: 6px;
  }
  &-desc {
    font-weight: 400;
    font-size: 16px;
    color: #1c1d21;
  }
}
.card-block-title {
  display: block;
  color: #08487a;
  font-weight: 600;
  font-size: 16px;
  margin-left: 9px;
}
.title-label {
  font-size: 15px;
  color: #1c1d21;
  margin-top: 5px;
  font-weight: 400 !important;
}
.block-left {
  height: 75px;
}
.block-checkbox {
  height: 40px;
}
.v-input--selection-controls {
  margin-top: 0 !important;
}
</style>
