<template>
  <span
    v-if="simpleText"
    class="link"
    style="font-size: 12px !important"
    @click="pushTo">
    Перейти в карточку
  </span>
  <v-tooltip v-else bottom :text="text">
    <template #activator="{ props }">
      <v-btn
        :class="{ 'pb-1': !small }"
        :size="small ? 'x-small' : 'small'"
        icon
        density="comfortable"
        class="rounded-circle"
        v-bind="props"
        @click="pushTo">
        <linkedIcon :width="17" :height="16" />
      </v-btn>
    </template>
  </v-tooltip>
</template>
<script>
import linkedIcon from '@/assets/svg/linked-icon.vue'
export default {
  components: { linkedIcon },
  emits: ['click'],
  props: {
    routeObject: {
      type: Object,
      default: () => ({ name: '', params: { id: '' } }),
    },
    text: {
      type: String,
      default: 'text',
    },
    icon: {
      type: String,
      default: 'mdi-link-box-variant',
    },
    small: { type: Boolean },
    simpleText: { type: Boolean },
  },
  methods: {
    pushTo(e) {
      this.$emit('click', e)
      this.$router.push(this.routeObject)
    },
  },
}
</script>
