<template>
  <div>
    <ContractorCard
      v-if="typeContractor"
      :contractor="subtenant.contractor"
      contactDetails
      :isNew="ssAnalyze.status?.id === 1 && roleAccess"
      showKbvDocs>
      <template #main-info>
        <FilesUploader
          v-model:documents="subtenant.subtenantApplicationForm"
          readonly
          label="Заявка-анкета" />
      </template>
    </ContractorCard>
    <ContactCard
      v-if="typeContact"
      :contact="subtenant.contact"
      marriedDocs
      :generalObj="ssAnalyze"
      :isNew="ssAnalyze.status?.id === 1 && roleAccess">
      <template #main-info>
        <FilesUploader
          readonly
          label="Заявка-анкета"
          :documents="subtenant.subtenantApplicationForm" />
        <FilesUploader
          readonly
          label="Паспорт"
          :documents="subtenant.contact.documentPassport" />
        <FilesUploader
          readonly
          label="Ідентифiкацiйний номер"
          :documents="subtenant.contact.identificationNumber" />
        <FilesUploader
          readonly
          label="Свідоцтво про укладення/розірвання шлюбу"
          :documents="subtenant.contact.marriageCertificate" />
      </template>
    </ContactCard>

    <div class="white-block" style="margin-top: 10px">
      <v-row>
        <v-col :cols="12" :md="3">
          <span> Коментар: </span>
        </v-col>
        <v-col :cols="12" :md="9">
          <v-textarea
            v-model="subtenantConclusion"
            class="pt-0"
            :rows="3"
            placeholder="Напишіть коментар"
            hide-details
            :readonly="!isInWork">
          </v-textarea>
        </v-col>
      </v-row>
    </div>

    <ActionButtons
      class="mt-5"
      :confirm="() => setConclusion(1)"
      :cancel="() => setConclusion(2)"
      confirmBtnText="Позитивне рішення"
      cancelBtnText="Негативне рішення"
      :confirmLoading="false"
      :cancelLoading="false"
      :cancelDisable="!isInWork || computedResultId === 2"
      :confirmDisable="!isInWork || computedResultId === 1">
    </ActionButtons>
  </div>
</template>

<script>
import ContactCard from '@/components/ContactCard.vue'
import ContractorCard from '@/components/ContractorCard.vue'
import FilesUploader from '@/components/FilesUploader.vue'
import ActionButtons from '@/components/action-buttons.vue'
import { mapState } from 'vuex'

export default {
  emits: ['setConclusion'],
  components: {
    ContactCard,
    ContractorCard,
    FilesUploader,
    ActionButtons,
  },
  props: {
    subtenants: { type: Array },
    ssAnalyze: { type: Object },
    isInWork: { type: Boolean },
    conclusionsState: { type: Object },
  },
  data: () => {
    return {
      subtenantConclusion: null,
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    roleAccess() {
      return [2, 9].includes(this.user?.role?.id)
    },
    typeContractor() {
      return this.subtenant.subtenantTypeId === 2
    },
    typeContact() {
      return [1, 3].includes(this.subtenant.subtenantTypeId)
    },
    subtenant() {
      return this.subtenants[this.$route.params.idx - 1]
    },
    subtenantId() {
      return this.typeContractor
        ? this.subtenant.contractor.id
        : this.subtenant.contact.id
    },
    computedResultId() {
      const subtenantResult = this.ssAnalyze.subtenantResults?.find(
        g =>
          `${g.subtenantId}${g.subtenantTypeId}` ===
          `${this.subtenantId}${this.subtenant.subtenantTypeId}`
      )

      return subtenantResult?.subtenantResultId ?? null
    },
  },
  methods: {
    setConclusion(resultId) {
      const request = {
        subtenantConclusion: this.subtenantConclusion,
        subtenantId: this.subtenantId,
        subtenantTypeId: this.subtenant.subtenantTypeId,
        subtenantResultId: resultId,
        type: 4,
      }
      this.$emit('setConclusion', { request })
    },
  },
  watch: {
    conclusionsState: {
      handler(val) {
        if (val.subtenantResults && val.subtenantResults.length > 0) {
          const foundSubtenant = val.subtenantResults.find(
            g =>
              `${g.subtenantId}${g.subtenantTypeId}` ===
              `${this.subtenantId}${this.subtenant.subtenantTypeId}`
          )

          this.subtenantConclusion = foundSubtenant?.subtenantConclusion || null
        } else {
          this.subtenantConclusion = null
        }
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style scoped></style>
