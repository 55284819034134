import axios from 'axios'
import { urlGetSingleProject, urlSetProjectStatus } from '@/pages/request'
import { toFormatDate, setCache } from '@/utils/helperFunc'

const projects = {
  state: () => ({
    project: {},
    slider: {},
    docs: [],
    $project: {},
    cache: null,
  }),
  getters: {
    hasProject(state) {
      return (
        !!Object.keys(state.project).length &&
        !!Object.keys(state.slider).length
      )
    },
  },
  mutations: {
    assignProject(state, project) {
      Object.assign(state.project, project)
    },
    setProjectCache(state) {
      state.cache = setCache(
        { ...(state.project || {}), slider: state.slider },
        ['slider.guarantors', 'slider.subtenants']
      )
    },
    setAssociatedPartyApplicationFormSigned(state, payload) {
      const { doc, associatedParty, associatedPartyType } = payload
      const entity =
        associatedParty.entityTypeId === 2 ? 'contractors' : 'contacts'
      const entityIdx = state.slider[`${associatedPartyType}s`].findIndex(
        a => a.id === associatedParty.id
      )
      state.slider[`${associatedPartyType}s`][entityIdx][
        `${associatedPartyType}ApplicationFormSigned`
      ] = doc
    },
    clearProjectStatue(state) {
      state.project.lessee.statute = null
      state.project.lessee.codeAccessStatute = null
    },
    clearProject(state) {
      state.project = {}
      state.slider = {}
      state.cache = null
    },
    setProjectSlider(state, slider) {
      slider.dateOfLeasing = toFormatDate(slider.dateOfLeasing, true)
      slider.dateSigningContract = toFormatDate(
        slider.dateSigningContract,
        true
      )
      slider.advanceDate = toFormatDate(slider.advanceDate, true)
      Object.assign(state.slider, slider)
      delete state.project.slider
    },
    setProject(state, res) {
      if (res?.data?.constructor?.name === 'Object') {
        const p = res.data
        p.projectDate = toFormatDate(p.projectDate, true)
        this.commit('assignProject', p)
        this.commit('setProjectSlider', p.slider)
        this.commit('setProjectCache')
        return res
      } else throw new Error('Project invalid type: ' + res?.data)
    },
    /* validations */
    projectValidity(state, value) {
      state.$project = Object.assign({}, state.$project, value)
    },
  },
  /* eslint-enable no-unused-vars */
  actions: {
    getProject({ commit }, id) {
      return axios
        .get(urlGetSingleProject(id))
        .then(res => commit('setProject', res))
    },
    setProjectStatus(_, { projectId, request }) {
      return axios.post(urlSetProjectStatus(projectId), request).then(res => {
        return res
      })
    },
    rejectProject(_, { rejectionTypeId, rejectionComments, projectId }) {
      return axios
        .post(urlSetProjectStatus(projectId), {
          statusId: 11,
          rejectionTypeId,
          rejectionComments,
        })
        .then(res => {
          return res
        })
    },
  },
}

export { projects }
