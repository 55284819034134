<template>
  <div>
    <v-scroll-y-transition hide-on-leave>
      <div v-show="hasApartment">
        <div>
          <span class="label">Тип помешкання</span>
          <v-select
            v-model="localApartmentType"
            :items="
              $directory.get(
                'apartmentTypes',
                localApartmentType as unknown as undefined
              ) as any[]
            "
            item-title="name"
            item-value="id"
            placeholder="Оберіть зі списку"
            return-object
            dense
            hide-details
            :loading="$loading.isLoading('apartmentTypes')"
            @update:model-value="localApartmentTypeId = $event?.id || null"
            @focus="$directory.fill('apartmentTypes')">
          </v-select>
        </div>
        <div v-if="localApartmentTypeId">
          <span class="label">{{ apartmentLabel }}</span>
          <v-text-field
            v-model="localApartmentNum"
            placeholder="Введіть текст"
            hide-details
            dense>
          </v-text-field>
        </div>
      </div>
    </v-scroll-y-transition>
    <div v-if="hasApartment">
      <v-btn variant="plain" color="error" @click="removeApartment">
        <v-icon>mdi-delete</v-icon>
        <span>Видалити помешкання</span>
      </v-btn>
    </div>
    <div v-if="!hasApartment">
      <v-btn variant="plain" color="error" @click="hasApartment = true">
        <v-icon>mdi-plus</v-icon>
        <span>Додати помешкання</span>
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, PropType, ref } from 'vue'
import { getDirectory } from '@/plugins/directory'
import { DefaultItem } from 'best-modules/plugins/directory/types'

export default {
  name: 'Apartment',
  emits: [
    'update:apartmentTypeId',
    'update:apartmentType',
    'update:apartmentNum',
  ],
  props: {
    apartmentTypeId: {
      type: Number,
    },
    apartmentType: {
      type: Object as PropType<DefaultItem>,
    },
    apartmentNum: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const localApartmentTypeId = computed<number | null>({
      get() {
        return props.apartmentTypeId || null
      },
      set(v) {
        emit('update:apartmentTypeId', v)
      },
    })
    const localApartmentType = computed<DefaultItem | null>({
      get() {
        return props.apartmentType || null
      },
      set(v) {
        emit('update:apartmentType', v)
      },
    })

    const localApartmentNum = computed<string | null>({
      get() {
        return props.apartmentNum || null
      },
      set(v) {
        emit('update:apartmentNum', v)
      },
    })
    const hasApartment = ref(!!props.apartmentType)

    const apartmentLabel = computed(() => {
      if (localApartmentTypeId.value) {
        const name = getDirectory(
          'apartmentTypes',
          localApartmentType.value as DefaultItem,
          {
            type: 'collection',
          }
        ).getItem(localApartmentTypeId.value)?.name
        return `${name} №`
      } else {
        return null
      }
    })
    const removeApartment = () => {
      localApartmentTypeId.value = null
      localApartmentType.value = null
      localApartmentNum.value = null
      hasApartment.value = false
    }

    return {
      localApartmentTypeId,
      localApartmentType,
      localApartmentNum,
      apartmentLabel,
      hasApartment,
      removeApartment,
    }
  },
}
</script>

<style scoped></style>
