<template>
  <div>
    <component
      :is="typeContractor ? 'ContractorCard' : 'ContactCard'"
      :contractor="calculation.provider.contractor"
      :isNew="rdAnalyze.status?.id === 1 && roleAccess"
      :contact="calculation.provider.contact" />
  </div>
</template>

<script>
import ContractorCard from '@/components/ContractorCard.vue'
import ContactCard from '@/components/ContactCard.vue'
import { mapState } from 'vuex'
export default {
  components: {
    ContractorCard,
    ContactCard,
  },
  props: {
    calculation: { type: Object },
    rdAnalyze: { type: Object },
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    roleAccess() {
      return [2, 7, 8].includes(this.user?.role?.id)
    },
    typeContractor() {
      return this.calculation.providerTypeId === 2
    },
    typeContact() {
      return [1, 3].includes(this.calculation.providerTypeId)
    },
  },
}
</script>

<style scoped></style>
