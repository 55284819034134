/**
 * moreAdvantures (Static)
 *
 */

const moreAdvantures = () => {
  return [
    {
      image: 'moreAdvanceBackground',
      absolutePosition: { x: 16, y: 32 },
      opacity: 0.4,
      pageBreak: 'before',
    },
    {
      image: 'moreAdvanceFigure',
      absolutePosition: { x: 300, y: 0 },
    },
    [
      {
        text: [
          {
            text: 'ПЕРЕВАГИ СЕРВІСНИХ ПАКЕТІВ',
            margin: [0, 12, 0, 0],
            width: '100%',
            bold: true,
            color: '#d7514f',
          },
        ],
        margin: [0, 5, 0, 3],
        fontSize: 17,
        bold: true,
      },
    ],
    [
      {
        text: [
          {
            text: 'лізингу ВIД BEST LEASING',
            margin: [0, 3, 0, 0],
            width: '100%',
            bold: true,
            color: '#d7514f',
          },
        ],
        margin: [0, 0, 0, 20],
        fontSize: 18,
        bold: true,
      },
    ],
    [
      {
        text: [
          {
            text: 'Вигоди',
            margin: [0, 12, 0, 0],
            bold: true,
          },
        ],
        margin: [0, 30, 0, 13],
        fontSize: 13,
        bold: true,
      },
    ],
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Вам не зроблять додаткові не потрібні роботи',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Ми проконтролюємо якість технічного обслуговування (ТО) та ремонтів',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          lineHeight: 1.5,
          text: 'Ви отримаєте додаткову знижку на сервісне обслуговування від нас (від 5% і більше), та акційну знижку від дилера',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    [
      {
        text: [
          {
            text: 'Зручності',
            margin: [0, 12, 0, 0],
            bold: true,
          },
        ],
        margin: [0, 30, 0, 13],
        fontSize: 13,
        bold: true,
      },
    ],
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Витрати на сервісу рівномірно розбити в продовж строку дії сервісного договору',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Сервісний платіж відноситься на валові витрати',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Планове отримання ПДВ щомісяця',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Потужна допомога у врегулюванні спірних питань щодо гарантії',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Запис на сервіс у зручний час для Вас',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Зручне адміністрування шин у піковий сезон',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    [
      {
        text: [
          {
            text: 'Сервіс',
            margin: [0, 12, 0, 0],
            bold: true,
          },
        ],
        margin: [0, 30, 0, 13],
        fontSize: 13,
        bold: true,
      },
    ],
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Збір документів / Електронний документообіг з СТО виконує BEST LEASING',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Надання щомісячних звітів',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    [
      {
        text: [
          {
            text: 'Підтримка',
            margin: [0, 12, 0, 0],
            bold: true,
          },
        ],
        margin: [0, 30, 0, 13],
        fontSize: 13,
        bold: true,
      },
    ],
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Цілодобова допомога у дорозі 24/7 в Києві та по всій Україні',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Організація евакуації автомобіля по території України',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Розгалужена мережа сервіс-партнерів по всій Україні',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    [
      {
        text: [
          {
            text: 'Переваги бухгалтерського обліку',
            margin: [0, 12, 0, 0],
            bold: true,
          },
        ],
        margin: [0, 30, 0, 13],
        fontSize: 13,
        bold: true,
      },
    ],
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Бух.облік іамортизація предмету лізингу здійснюються на балансі клієнта',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Комісія лізингової компанії відноситься до валових витрат, зменшуючи базу оподаткування',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          lineHeight: 1.5,
          text: 'Ви отримуєте податковий кредит на загальну суму предмета лізингу',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          lineHeight: 1.5,
          text: 'Активи, передані у лізинг не підлягають відчуженнюподатковими органами та третіми особами',
          margin: [0, 2, 0, 0],
        },
      ],
    },
  ]
}

export { moreAdvantures }
