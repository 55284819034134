<template>
  <div>
    <SectionLoader v-if="asyncLoading('getPlanList')" />
    <div v-else>
      <div class="d-flex w-100 justify-end px-2">
        <v-btn
          class="mb-3"
          style="background: rgb(252, 114, 71); border-radius: 4px"
          @click="createPlan">
          <span class="text-white">Створити план</span>
        </v-btn>
      </div>
      <v-data-table
        class="calc-table --cust-cell-pd"
        :items="planList"
        :headers="headers"
        :items-per-page="-1"
        :hide-default-header="!planList.length"
        hide-default-footer
        @dblclick:row="navigateToPlan">
        <template #item.actions="{ item }">
          <TableCrud
            :actions-array="[
              {
                action: () => updatePlan(item),
                text: 'Редагувати',
                icon: 'mdi-pencil',
              },
              {
                action: () => deletePlan(item.id),
                text: 'Видалити',
                icon: 'mdi-delete',
              },
            ]"></TableCrud>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import SectionLoader from '@/components/section-loader.vue'
import { urlDeletePlan, urlGetPlanList } from '@/pages/request'
import TableCrud from '@/components/table-crud.vue'
import { useSelect } from '@/utils/mixins/useSelect'

export default {
  name: 'PlanList',
  components: { TableCrud, SectionLoader },
  setup() {
    return {
      ...useSelect(),
    }
  },
  data: () => {
    return {
      planList: [],
      headers: [
        { title: 'Назва', key: 'name' },
        { title: 'Тип', key: 'type.name' },
        { title: 'Департамент', key: 'department.name' },
        { title: 'Дії', key: 'actions', align: 'end' },
      ],
    }
  },
  methods: {
    navigateToPlan(e, row) {
      this.$router.push({ name: 'plan-single', params: { id: row.item.id } })
    },
    getPlanList() {
      return this.asyncAction('getPlanList', null, () => {
        return this.$axios.get(urlGetPlanList()).then(res => {
          this.planList = res.data
        })
      })
    },
    updatePlan(plan) {
      this.$store.commit('setDialog', {
        title: 'Редагувати план',
        action: 'updatePlan',
        dialogItem: plan,
        params: {
          cb: res => {
            const editedPlan = this.planList.find(p => p.id === res.data.id)
            if (editedPlan) {
              Object.assign(editedPlan, res.data)
            } else {
              throw new Error(
                'updatePlan dialog cb(), can not find edited plan'
              )
            }
          },
        },
      })
    },
    createPlan() {
      this.$store.commit('setDialog', {
        title: 'Створити план',
        action: 'createPlan',
        params: {
          cb: res => {
            this.planList.push(res.data)
          },
        },
      })
    },
    deletePlan(planId) {
      return this.$axios.delete(urlDeletePlan(planId)).then(() => {
        this.planList = this.planList.filter(p => p.id !== planId)
      })
    },
  },
  created() {
    this.getPlanList()
  },
}
</script>
