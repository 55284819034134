<template>
  <div class="info-fields">
    <Field
      v-for="(f, idx) in fields"
      :key="idx"
      :field="f"/>
  </div>
</template>

<script>
import Field from './Field.vue'
export default {
  components: { Field },
  props: {
    fields: { type: Array, required: true },
    colSize: { type: String },
  },
}
</script>

<style lang="scss" scoped>
.info-fields {
  max-width: 60em;
  padding: 0 1rem;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));

}
</style>
