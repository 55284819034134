<template>
  <div
    class="text-center pt-0 pb-2">
    <div
      style="font-size: 0.8rem" 
      class="text-center">
      {{ noDataText }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    noDataText: {
      type: String,
      default: 'Немає даних для відображення',
    }
  }
}
</script>