<template>
  <div>
    <div v-if="contracts.length > 0">
      <div
        v-for="(dfl, idx) in contracts"
        :key="idx"
        class="d-flex"
        style="padding-bottom: 1rem; margin-bottom: 1rem"
        :style="{ borderBottom: idx + 1 < contracts.length ? '1px solid #ccc' : 'none' }">
        <InfoField v-for="field in dfl" :key="field.label" class="mr-6" :field="field" />
      </div>
    </div>
    <div v-else class="text-center disabled">Немає даних для відображення</div>
  </div>
</template>

<script>
import InfoField from '@/components/info-fields/Field.vue'
import { toFormatDate } from '@/utils/helperFunc'

export default {
  components: { InfoField },
  props: {
    LO: { type: Object },
  },
  computed: {
    contracts() {
      if (this.LO && this.LO.Договоры) {
        return this.LO.Договоры.map(d => [
          { label: 'Дата', value: toFormatDate(d.Дата, true) },
          { label: '№ Договору', value: d.Договор },
        ])
      } else {
        return []
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
