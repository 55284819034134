<template>
  <div>
    <v-fade-transition hide-on-leave>
      <Filters :filters="filters" class="ml-2" />
    </v-fade-transition>
    <SectionLoader v-if="loading" />
    <v-row>
      <v-col v-show="!loading" :cols="12" class="pr-0">
        <v-data-table
          :headers="leasingObjectListHeaders"
          :items="leasingObjectsList"
          mobileBreakpoint="1200"
          dense
          hide-default-footer
          :items-per-page="-1"
          @dblclick:row="navigateToLeasingObject">
          <!-- eslint-disable-next-line -->
          <template #item.Контрагент_Name="{ item }">
            <ALink
              v-if="item.lesseeTypeId && item.lesseeId"
              :text="item.Контрагент_Name"
              :route="{
                name: [1, 3].includes(item.lesseeTypeId)
                  ? 'individuals-form'
                  : 'contractors-form',
                params: { id: item.lesseeId },
              }">
            </ALink>
            <span v-else>{{ item.Контрагент_Name }}</span>
          </template>
          <template #item.Договор_Name="{ item }">
            <ALink
              v-if="admin && item.contractId"
              style="font-size: 14px !important"
              :text="item.Договор_Name"
              :route="{
                name: 'dfl-contract',
                params: { id: item.contractId },
              }">
            </ALink>
            <span
              v-if="admin && !item.contractId"
              class="link"
              @click="
                $router.push({
                  name: 'leasing-object-single',
                  params: { id: item.ОбъектЛизинга_1C_ID },
                })
              "
              >{{ item.Договор_Name }}</span
            >
            <span v-else>{{ item.Договор_Name }}</span>
          </template>
          <template #item.actions="{ item }">
            <TableCrud
              :color="
                item.isAgreed
                  ? 'green darken-2'
                  : item.isSendOffer
                  ? 'orange darken-1'
                  : ''
              "
              :actionsArray="[
                {
                  action: () =>
                    $router.push({
                      name: 'leasing-object-single',
                      params: { id: item.ОбъектЛизинга_1C_ID },
                    }),
                  text: 'Переглянути',
                  icon: 'mdi-eye',
                },
              ]">
            </TableCrud>
          </template>
        </v-data-table>
        <Pagination
          :getItems="getLeasingObjects"
          :trigger="paginationTrigger" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TableCrud from '@/components/table-crud.vue'
import axios from 'axios'
import {
  urlGetLeasingObjectsList,
  urlGetOneCLeasingObjectByOneCId,
} from '@/pages/request'
import SectionLoader from '@/components/section-loader.vue'
import Pagination from '@/components/Pagination.vue'
import ALink from '@/components/Link.vue'
import Filters from '@/components/Filters.vue'
import { useSelect } from '@/utils/mixins/useSelect'
import { useFilter } from '@/utils/mixins/useFilter'
import { reactive } from 'vue'
import { leasingObjectListHeaders } from '@/utils/tableHeaders'

export default {
  components: {
    Filters,
    ALink,
    Pagination,
    TableCrud,
    SectionLoader,
  },
  setup() {
    const filters = reactive({
      leasingObjectStatusId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Статус',
          itemTitle: u => u.name,
          items: 'leasingObjectStatuses',
        },
      },
      search: { value: null },
    })
    return {
      ...useSelect(),
      ...useFilter(filters),
      filters,
    }
  },
  data() {
    return {
      leasingObjectListHeaders,
      loading: false,
      leasingObjectsList: [],
      leasingObjectDialog: false,
      oneCLeasingObjectId: null,
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    admin() {
      return this.user.roleId === 1
    },
  },
  methods: {
    navigateToLeasingObject(e, row) {
      this.$router.push({
        name: 'leasing-object-single',
        params: { id: row.item.ОбъектЛизинга_1C_ID },
      })
    },
    getLeasingObject(cid) {
      const req = {
        oneCId: cid,
      }
      return this.$axios
        .post(urlGetOneCLeasingObjectByOneCId(), req)
        .then(res => {
          this.oneCLeasingObjectInfo = res.data
          this.oneCLeasingObjectId = cid
          this.leasingObjectDialog = true
        })
    },
    getLeasingObjects(page = 1) {
      this.loading = true
      const params = { page: page }
      return axios
        .get(urlGetLeasingObjectsList(this.user.oneCId, this.filtersQueryObj), {
          params,
        })
        .then(res => {
          this.leasingObjectsList = res.data.data
          this.loading = false
          return res
        })
    },
  },
}
</script>

<style scoped></style>
