<template>
  <div>
    <v-row>
      <v-col :cols="12">
        <span class="label">Постачальник</span>
        <v-autocomplete
          v-model="provider"
          placeholder="Оберіть зі списку"
          :items="providers"
          item-title="name"
          :item-value="
            item => ({
              name: item.name,
              providerId: item.providerId,
              providerTypeId: item.providerTypeId,
            })
          "
          hide-details>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12">
        <FilesUploader
          v-model:documents="doc"
          label="Microsoft .doc - шаблон"
          acceptedExt=".doc, .docx" />
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col :cols="12">
        <v-btn
          class="text-white mb-3"
          style="background: rgb(8, 72, 122); border-radius: 4px"
          :loading="loading"
          @click="uploadDoc">
          {{ createMode ? 'Додати шаблон до постачальника' : 'Оновити шаблон' }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { urlAddTemplate } from '@/pages/request'
import FilesUploader from '@/components/FilesUploader.vue'
export default {
  components: { FilesUploader },
  emits: ['create'],
  props: {
    callBack: { type: Function, default: () => false },
    dialogData: { type: Object },
  },
  data: () => {
    return {
      provider: null,
      doc: null,
      loading: false,
    }
  },
  computed: {
    ...mapState({
      providers: state => state.selectItems.allProviders,
    }),
    createMode() {
      return this.dialogData.action === 'create'
    },
    updateMode() {
      return this.dialogData.action === 'update'
    },
  },
  methods: {
    urlAddTemplate,
    uploadDoc() {
      if (
        this.doc &&
        this.provider.providerId &&
        this.provider.providerTypeId
      ) {
        this.loading = true
        const formData = new FormData()
        formData.append('dkp', this.doc[0].url)
        formData.append('providerId', this.provider.providerId)
        formData.append('providerTypeId', this.provider.providerTypeId)

        return this.$axios
          .post(this.urlAddTemplate(), formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then(res => {
            if (this.callBack && typeof this.callBack === 'function') {
              this.callBack()
            }
            this.$setSnackbar({ text: 'Додано шаблон до постачальника' })
            this.$emit('create')
            this.loading = false
            return res
          })
          .catch(err => this.$err(err, () => (this.loading = false)))
      }
    },
  },
  mounted() {
    if (this.updateMode) {
      this.provider = this.dialogData.provider
    }
  },
  created() {
    this.$store.dispatch('addAllProviders')
  },
}
</script>

<style scoped></style>
