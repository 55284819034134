function catchError(error) {
  console.log({CatchError: error})
  let errTitle = error.response.status || error.response.statusText
  let errMessage = error.response.data.message || error.response.statusText

  return {
    message: errMessage,
    title: errTitle,
  }
} 

export default catchError