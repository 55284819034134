// @ts-check

const application = {
  sourceType: {
    AGENT: 1,
    ONLINE: 2,
    RE_TREATMENT: 3,
    COMPANY_BINDED_TO_CLIENT: 4,
    RECOMENDATION_FROM_EMPLOYEE: 5,
    CURRENT_CLIENT_RECOMENDATION: 6,
    OTHER: 7,
  },
}

const phone = {
  type: {
    MOBILE: 1,
    WORK: 2,
    HOME: 3,
    ADDITIONAL: 4,
    MAIN: 5,
    FAX: 6,
  },
}

const email = {
  type: {
    WORK: 1,
    PRIVATE: 2,
    MAILING: 3,
    OTHER: 4,
  },
}

const contact = {
  type: {
    CLIENT: 1,
    AGENT: 2,
    CONTACT_PERSON: 3,
    EMPLOYEE: 4,
    PROVIDER: 5,
    PARTNER: 6,
  },
}

const contractor = {
  type: {
    PROVIDER: 1,
    CLIENT: 2,
    PARTNER: 3,
    LESSOR: 4,
  },
  name: {
    1: 'Постачальник',
    2: 'Клієнт',
    3: 'Партнер',
    4: 'Лізингодавець',
  },
  authorityType: {
    SINGLE_OWNER: 1,
    COLLEGIUM: 2,
  },
}

const dealer = {
  type: {
    DEALER: 1,
    BANK: 2,
    INSURANCE_COMPANY: 3,
    BROKER: 4,
  },
  category: {
    OFFICIAL: 1,
    NOT_OFFICIAL: 2,
    COMMISSION_AREA: 3,
  },
}

const leasingClient = {
  type: {
    PERSON: 1,
    LEGAL: 2,
    ENTEPRENEUR: 3,
  },
}

const entityTypeId = {
  type: {
    PERSON: 1,
    LEGAL: 2,
    ENTEPRENEUR: 3,
    LEAD: 4,
    APPLICATION: 5,
    PROJECT: 6,
    CALCULATION: 7,
    APPLICATION_SERVICE: 8,
    FINANCIAL_MONITORING: 9,
    SECURITY_DEPARTMENT: 10,
    RISK_DEPARTMENT: 11,
    SLIDER: 12,
    LEGAL_DEPARTMENT: 13,
    DKP: 19,
  },
}

const calculation = {
  leasingObjectType: {
    CAR: 1,
    MOTO: 2,
    WATER: 3,
    SPECIAL: 4,
    TRAILER: 5,
    TRUCK: 6,
    BUS: 7,
    CAMPER: 8,
    AIR: 9,
    AGRICULTURAL: 10,
    RAILWAY: 11,
    COMMERCIAL_PROPERTY: 12,
    EQUIPMENT: 13,
  },
  registrationPlaceType: {
    STATE_LABOR: 2,
    STATE_CONSUMER_SERVICE: 3,
    MANUAL: 4,
  },
  patrolCardsSupport: {
    ENABLED: 1,
    DISABLED: 2,
  },
  truckMass: {
    LESS_THAN_THREE: 1,
    MORE_THAN_THREE: 2,
  },
  truckMassCapacity: {
    LESS_THAN_TWO: 1,
    MORE_THAN_TWO: 2,
  },
  customGraphicType: {
    SEASSON: 1,
    ENHANCED_FACTORIAL: 2,
    STEPPED: 3,
    FACTORIAL: 4,
    UNIVERSAL_ENHANCED: 5,
    ANNUITY_REDUCTION: 6,
    FIELD_DAY: 7,
    SEASSON_FIELD_DAY: 8,
  },
  graphType: {
    EVEN: 'even',
    ANNUITY: 'annuity',
    IRREGULAR: 'irregular',
    EVEN_PLUS: 'even-plus',
    ANNUITY_PLUS: 'annuity-plus',
    OPER_LEASING: 'oper-leasing',
  },
}

const financingCurrency = {
  UAH: 1,
  USD: 2,
  EUR: 3,
}

const insuranceProgram = {
  STANDARD: 1,
  CAUTIOUS: 2,
  TAXI: 3,
  WITHOUT_INSURANCE: 5,
}

const insuranceFranchise = {
  // key = percents
  0: 1,
  0.5: 3,
  1: 4,
  2: 5,
  5: 7,
  2.5: 10,
  10: 9,
}

export {
  contact,
  contractor,
  dealer,
  leasingClient,
  entityTypeId,
  calculation,
  financingCurrency,
  insuranceProgram,
  insuranceFranchise,
  application,
  phone,
  email,
}
