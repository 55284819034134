<template>
  <div>
    <div v-if="deleteFrontSalesPlan">
      План продаж за менеджером "<span class="underline">{{ manager }}</span
      >" по програмi "<span class="underline">{{
        getSafe(() => managerPlan.type.name, '---')
      }}</span
      >" буде видалено!
    </div>
    <div v-if="editFrontSalesPlan">
      <v-row>
        <v-col :cols="12" :sm="3" class="pt-0 pb-0">
          <span>Менеджер:</span>
        </v-col>
        <v-col :cols="12" :sm="9" class="pt-0 pb-0">
          <span class="col-value">{{ manager }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="12" :sm="3" class="pb-0">
          <span>Програма:</span>
        </v-col>
        <v-col :cols="12" :sm="9" class="pb-0">
          <span class="col-value">
            {{ getSafe(() => managerPlan.type.name, '---') }}
          </span>
        </v-col>
      </v-row>
      <v-row class="pt-0">
        <v-col :cols="12" :sm="3" class="pt-4 pb-0 flex-start">
          <span class="card-label calc"> Мiсяць </span>
        </v-col>
        <v-col :cols="12" :sm="9" class="pt-0 pb-0">
          <v-text-field
            :id="`front-sales-plan`"
            v-model="managerPlan.plan"
            :error-messages="managerPlanErr"
            dense
            hide-details
            @input="inputNumber($event, { float: 2, minValue: 1 })"
            @blur="
              $event => {
                inputNumber($event, { float: 2, saveFloat: true })
                v$.managerPlan.plan.$touch()
              }
            ">
            <template #append>
              <span class="append-text pb-2">
                {{
                  (
                    ukrMonths[parseInt(managerPlan.month) - 1] || ''
                  ).toLowerCase()
                }}
                (грн)
              </span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { required } from '@vuelidate/validators'
import { mapState } from 'vuex'

import { getBeautyNum, getSafe, setErrHandler } from '@/utils/helperFunc'
import {
  formutSum,
  keepDigit,
  changeInputVal,
  inputNumber,
} from '@/utils/formatFunc'
import {
  urlUpdateFrontSalesPlan,
  urlDeleteFrontSalePlan,
} from '@/pages/request'
import { useVuelidate } from '@vuelidate/core'
export default {
  props: { dialog: { type: Object } },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      managerPlan: {
        plan: { required },
      },
    }
  },
  data: () => ({
    managerPlan: {},
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    deleteFrontSalesPlan() {
      return this.dialog.action === 'deleteFrontSalesPlan'
    },
    editFrontSalesPlan() {
      return this.dialog.action === 'editFrontSalesPlan'
    },
    validation() {
      return { v$: this.v$.managerPlan, name: 'frontSalesPlan' }
    },
    managerPlanErr() {
      return this.setErrHandler(this.v$.managerPlan.plan)
    },
    manager() {
      const m = this.managerPlan?.manager
      if (!m) return
      return `${m.surname} ${m.name} ${m.patronymic}`
    },
    ukrMonths() {
      return this.selectItems.ukrMonths
    },
  },
  methods: {
    getSafe,
    getBeautyNum,
    inputNumber,
    formutSum,
    keepDigit,
    changeInputVal,

    setErrHandler,
    submit() {
      switch (true) {
        case this.deleteFrontSalesPlan:
          return this.deleteSalesPlan()
        case this.editFrontSalesPlan:
          return this.editSalesPlan()
      }
    },
    editSalesPlan() {
      const req = Object.clone(this.managerPlan)
      req.month = parseInt(req.month)
      req.year = parseInt(req.year)

      const plan = req.plan
      const NumberPlan = plan.replace(/\s/g, '')

      const currNumberPlan = Number(NumberPlan)
      req.plan = currNumberPlan

      return this.$axios.post(urlUpdateFrontSalesPlan(this.managerPlan.id), req)
    },
    deleteSalesPlan() {
      return this.$axios.delete(urlDeleteFrontSalePlan(this.managerPlan.id))
    },
  },
  mounted() {
    Object.assign(this.managerPlan, this.dialog.dialogItem)
  },
}
</script>
<style scoped>
.col-value {
  color: #000000d3;
}
</style>
