const standardOrder = [
  'leasingObjectType',
  'leasedAssertMark',
  'leasedAssertModel',
  'leasingObjectYear',
  'generation',
  'body',
  'modification',
  'specification',
  'weight',
  'tires',
  'engine',
  'transmission',
]

const specialOrder = [
  'leasingObjectType',
  'body',
  'leasedAssertMark',
  'leasedAssertModel',
]

const getItemsKey = name => {
  if (!name) return null

  if (name.endsWith('y')) {
    return `${name.slice(0, -1)}ies`
  } else {
    return `${name}s`
  }
}

const topLeasingObjects = [
  'Легковий автомобіль',
  'Вантажний автомобіль',
  'Сільгосптехніка',
  'Спецтехніка',
  'Причепи',
  'Повітряний транспорт',
  'Автобуси',
  'Oбладнання',
  'Комерційна нерухомість',
  'Мото',
  'Водний транспорт',
  'ЖД транспорт',
  'Автобудинки',
]

function getTopMarks() {
  switch (true) {
    case this.car:
      return [
        'toyota',
        'renault',
        'peugeot',
        'opel',
        'fiat',
        'nissan',
        'mitsubishi',
        'mercedes-benz',
        'lexus',
        'citroen',
        'infiniti',
        'jac',
      ]
    case this.truck:
      return [
        'mercedes-benz',
        'renault',
        'toyota',
        'citroen',
        'peugeot',
        'opel',
        'fiat',
        'nissan',
        'jac',
      ]
    case this.special:
      return ['bobcat', 'caterpillar', 'jcb', 'man', 'mercedes-benz']
    case this.agricultural:
      return ['case ih', 'claas', 'john deere', 'new holland']
    case this.bus:
      return ['ford', 'iveco', 'man', 'mercedes-benz', 'neoplan']
  }
}

function getTopBodies() {
  switch (true) {
    case this.special:
      return [
        'Автокран',
        'Бетонозмішувач (Міксер)',
        'Вилочний навантажувач',
        'Гусеничний екскаватор',
        'Колісний екскаватор',
        'Екскаватор навантажувач',
        'Телескопічні навантажувачі',
        'Трактор',
      ]
    case this.agricultural:
      return ['Борона', 'Комбайн', 'Обприскувач', 'Плуг', 'Сівалка', 'Трактори']
    default:
      return []
  }
}

export {
  getItemsKey,
  standardOrder,
  specialOrder,
  topLeasingObjects,
  getTopMarks,
  getTopBodies,
}
