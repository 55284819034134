<template>
  <v-col :cols="12" :md="3" class="pa-0 pr-3 common-data">
    <v-card class="mb-3" :elevation="0">
      <slot></slot>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "SliderSideBar"
}
</script>

<style scoped>

</style>