<template>
  <div>
    <div class="mt-2">
      <span class="area-label">{{ objectData.name || objectData.type }}</span>
      <v-autocomplete
        v-model="objectData.marks"
        :error-messages="getSafe(() => objectData.marks.length) ? '' : errorMsg"
        hide-details
        :items="availableMarks"
        placeholder="оберіть марку"
        item-title="name"
        item-value="id"
        :list-props="{
          density: 'compact',
          slim: true,
        }"
        multiple
        deletable-chips
        small-chips
        chips
        dense
        :readonly="readOnly"
        :loading="loading">
      </v-autocomplete>
    </div>
  </div>
</template>
<script>
import { urlGetCarData } from '@/pages/request'
import { getSafe, setErrHandler } from '@/utils/helperFunc'
export default {
  props: {
    readOnly: { type: Boolean },
    dealerData: { type: Object },
    objectType: { type: Object },
    v: { type: Object },
  },
  data: () => ({
    availableMarks: [],
    body: [],
    loading: false,
    errorMsg: 'Вкажіть марку',
  }),
  computed: {
    dealer() {
      return this.dealerData
    },
    $v() {
      return this.v
    },
    objectData() {
      return this.objectType
    },
  },
  methods: {
    getSafe,
    setErrHandler,
    getMarkByType() {
      this.loading = true
      return this.$axios
        .get(
          urlGetCarData({
            leasingObjectType: this.objectData.categoryId || this.objectData.id,
            body: this.objectData.categoryId ? this.objectData.id : null,
          }),
          {
            headers: { 'X-API-SECRET': import.meta.env.VITE_ERM_API_SECRET },
          }
        )
        .then(res => {
          this.availableMarks.splice(0)
          this.availableMarks.push(...res.data)
        })
        .catch(this.$err)
        .finally(() => {
          this.loading = false
        })
    },
  },
  created() {
    this.getMarkByType()
  },
}
</script>

<style scoped>
.card-block-title {
  display: block;
  color: #08487a;
  font-weight: 600;
  font-size: 16px;
  margin-left: 9px;
}
</style>
