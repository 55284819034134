<template>
  <div class="sales-plan pt-4">
    <SectionLoader v-if="loading"></SectionLoader>
    <div
      v-if="Object.keys(managersPlan).length && !loading"
      style="min-height: 230px">
      <v-tabs
        v-model="currentTab"
        class="application-tabs"
        show-arrows
        :height="38"
        slider-color="red"
        @change="slide = true">
        <v-tab :href="'#' + currentMonthNum" :ripple="false">{{
          currentMonth
        }}</v-tab>
        <v-tab :href="'#' + nextMonthNum" :ripple="false">{{
          nextMonth
        }}</v-tab>
      </v-tabs>
      <v-scroll-x-transition hide-on-leave>
        <div
          v-if="currentTab && selectItems.frontManagers.length && !slide"
          class="pt-4">
          <v-row class="row-padding ml-3 mr-3">
            <v-col :cols="12" :sm="4" class="pt-0 pb-0 flex-start">
              <span class="card-label">Менеджер</span>
            </v-col>
            <v-col :cols="12" :sm="2" class="pt-0 pb-0">
              <span class="card-label">План грн.</span>
            </v-col>
            <v-col :cols="12" :sm="2" class="pt-0 pb-0">
              <span class="card-label">План факт грн.</span>
            </v-col>
            <v-col :cols="12" :sm="2" class="pt-0 pb-0">
              <span class="card-label">Фактично %</span>
            </v-col>
            <v-col :cols="12" :sm="2" class="pt-0 pb-0 text-right">
              <span class="card-label pr-2">Дії</span>
            </v-col>
          </v-row>
          <div v-for="(item, key) in selectItems.frontManagers" :key="key">
            <v-row class="ml-3 mr-3 manager-row">
              <v-col :cols="12" :sm="4" class="pt-2 pb-0">
                <span class="card-label">{{ getManager(item.id) }}</span>
              </v-col>
              <v-col :cols="12" :sm="2" class="pt-2 pb-0 pl-5">
                <span class="card-label">{{
                  getFilledPlansSum(item.id, currentTab)
                }}</span>
              </v-col>
              <v-col :cols="12" :sm="2" class="pt-2 pb-0 pl-5">
                <span class="card-label">---</span>
              </v-col>
              <v-col :cols="12" :sm="2" class="pt-2 pb-0 pl-5">
                <span class="card-label">---</span>
              </v-col>
              <v-col :cols="12" :sm="2" class="pt-2 pb-0 text-right">
                <v-btn
                  size="small"
                  icon
                  @click="
                    managersPlan[currentTab][item.id].state =
                      !managersPlan[currentTab][item.id].state
                  ">
                  <v-icon>
                    {{
                      managersPlan[currentTab][item.id].state
                        ? 'mdi-menu-up'
                        : 'mdi-menu-down'
                    }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-fade-transition hide-on-leave>
              <div
                v-show="managersPlan[currentTab][item.id].state"
                class="pb-3">
                <div
                  v-for="(program, key) in managersPlan[currentTab][item.id]
                    .programs"
                  :key="key">
                  <v-row class="pt-0 manager-detail-row ml-7 mr-5">
                    <v-col :cols="12" :sm="4" class="pt-4 pb-0 pl-0 flex-start">
                      <span class="switch-label calc">
                        {{ program.name || getSafe(() => program.type.name) }}
                      </span>
                    </v-col>
                    <v-col :cols="12" :sm="2" class="pt-0 pb-0">
                      <v-text-field
                        :id="`row-${currentTab}-${item.id}-${key}`"
                        v-model="program.plan"
                        class="border-bottom-none mb-0 mt-2"
                        placeholder="Вкажіть значення"
                        dense
                        hide-details
                        :disabled="!!program.id"
                        :readonly="!!program.id"
                        @input="
                          changeInputVal(
                            `row-${currentTab}-${item.id}-${key}`,
                            'input',
                            [keepDigit, formutSum]
                          )
                        ">
                      </v-text-field>
                    </v-col>
                    <v-col :cols="12" :sm="2" class="pt-4 pb-0 flex-start">
                      <span class="switch-label calc">---</span>
                    </v-col>
                    <v-col :cols="12" :sm="2" class="pt-4 pb-0 flex-start">
                      <span class="switch-label calc">---</span>
                    </v-col>
                    <v-col :cols="12" :sm="2" class="pt-0 pb-0 pr-0 text-right">
                      <TableCrud
                        :actionsArray="[
                          {
                            action: () =>
                              openFrontSalesDialog(
                                program,
                                'editFrontSalesPlan',
                                'План продаж оновлено'
                              ),
                            text: 'Редагувати план',
                            icon: 'EditPencil',
                            customIcon: true,
                            disabled: !!!program.plan || !!!program.id,
                          },
                          {
                            action: () =>
                              openFrontSalesDialog(
                                program,
                                'deleteFrontSalesPlan',
                                'План продаж видалено'
                              ),
                            text: 'Видалити план',
                            icon: 'mdi-delete',
                            disabled: !!!program.plan || !!!program.id,
                          },
                        ]">
                      </TableCrud>
                    </v-col>
                  </v-row>
                </div>
                <v-row class="ml-3 mr-3">
                  <v-col :cols="12" :sm="12" class="pb-0 text-right">
                    <v-btn
                      :disabled="
                        getCurrentPlansSum(item.id, currentTab) ===
                        getFilledPlansSum(item.id, currentTab)
                      "
                      class="text-white no-transform mt-3 mb-9"
                      color="grey darken-3"
                      size="small"
                      @click="
                        createProgramsPlan(
                          getSafe(
                            () => managersPlan[currentTab][item.id].programs,
                            {}
                          )
                        )
                      ">
                      <v-icon color="white" size="small">
                        {{ 'mdi-check-underline' }}
                      </v-icon>
                      &nbsp;Встановити план
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-fade-transition>
          </div>
        </div>
      </v-scroll-x-transition>
    </div>
  </div>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'
import { mapState } from 'vuex'
import { urlPostCreateSalesPlan, urlGetFrontSalesPlan } from '@/pages/request'
import { formutSum, keepDigit, changeInputVal } from '@/utils/formatFunc'
import { getBeautyNum, getSafe } from '@/utils/helperFunc'
import TableCrud from '@/components/table-crud.vue'
import { getDirectory } from '@/plugins/directory/index.ts'
export default {
  components: { TableCrud, SectionLoader },
  data: () => ({
    managersPlan: {},
    state: {},
    currentTab: 0,
    slide: false,
    plans: [],
    loading: false,
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    currentMonth() {
      return this.ukrMonths[new Date().getMonth()]
    },
    nextMonth() {
      return this.ukrMonths[new Date().getMonth() + 1]
    },
    currentMonthNum() {
      return (new Date().getMonth() + 1).toString()
    },
    nextMonthNum() {
      return (new Date().getMonth() + 2).toString()
    },
    months() {
      return [this.currentMonthNum, this.nextMonthNum]
    },
    ukrMonths() {
      return this.selectItems.ukrMonths
    },
  },
  methods: {
    getSafe,
    formutSum,
    keepDigit,
    changeInputVal,
    getPlan(id) {
      const plan = getDirectory('salesPlanTypes', null, {
        type: 'collection',
      }).getItem(id)
      return plan?.name
    },
    getFilledPlansSum(id, month) {
      const managerPlansSum = Object.values(
        this.managersPlan?.[month]?.[id]?.programs || {}
      )
        .filter(p => p.plan !== undefined)
        .map(p => parseFloat(keepDigit(p?.plan || 0)))
        .reduce((acc, val) => acc + val, 0)

      return getBeautyNum(managerPlansSum)
    },
    getCurrentPlansSum(id, month) {
      const managerPlans = this.plans
        .filter(p => p.managerId === id && p.month === month)
        .map(p => parseFloat(keepDigit(p?.plan || 0)))
        .reduce((acc, val) => acc + val, 0)

      return managerPlans
    },
    getManager(id) {
      if (!this.selectItems?.frontManagers?.length) return
      const manager =
        this.selectItems.frontManagers.find(m => m.id === id) || {}
      return `${manager.surname || '-'} ${manager.name || '-'} ${
        manager.patronymic || '-'
      }`
    },
    openFrontSalesDialog(item, action, message) {
      return this.$store.commit('setDialog', {
        title: 'План продаж',
        dialogItem: item,
        params: {
          cb: () => {
            this.getPlans()
            this.$setSnackbar({ text: message })
            item.plan = null
          },
        },
        action: action,
        disabled: false,
      })
    },
    initManagersPlan() {
      const managersPlan = {}

      const planTypesArray = (managerId, month) =>
        Object.keys(getDirectory('salesPlanTypes')).map(key => {
          const saleTypeId = getDirectory('salesPlanTypes')[key]?.id
          const existingPlan = this.plans.find(p => {
            return (
              p.type.id === saleTypeId &&
              +p.managerId === +managerId &&
              +p.month === +month
            )
          })

          return [
            saleTypeId,
            existingPlan || {
              name: getDirectory('salesPlanTypes')[key]?.name,
              managerId: managerId,
              plan:
                this.managersPlan?.[month]?.[managerId]?.programs?.[saleTypeId]
                  ?.plan || null,
              planTypeId: saleTypeId,
              month: month,
              year: new Date().getFullYear().toString(),
            },
          ]
        })

      const buildManagerPlan = month => {
        const manager = {}
        this.selectItems.frontManagers.forEach(m => {
          const state = this.managersPlan?.[month]?.[m?.id]?.state || false
          manager[m.id] = {
            state: state,
            programs: { ...Object.fromEntries(planTypesArray(m.id, month)) },
          }

          Object.keys(manager[m.id].programs).forEach(key => {
            if (manager[m.id].programs[key].plan !== null) {
              manager[m.id].programs[key].plan = getBeautyNum(
                manager[m.id].programs[key].plan
              )
            }
          })
        })
        return manager
      }

      this.months.forEach(month => {
        managersPlan[month] = { ...buildManagerPlan(month) }
      })

      return Object.assign(this.managersPlan, managersPlan)
    },
    createSalesPlan(plan) {
      const req = plan
      req.month = parseInt(req.month)
      req.year = parseInt(req.year)

      const reqPlan = req.plan
      const NumberPlan = reqPlan.replace(/\s/g, '')

      const currNumberPlan = Number(NumberPlan)
      req.plan = currNumberPlan

      return this.$axios
        .post(urlPostCreateSalesPlan(), req)
        .then(res => res)
        .catch(this.$err)
    },
    createProgramsPlan(programs) {
      const plans = Object.values(programs).filter(p => p.plan && !p.id)

      return Promise.all(plans.map(this.createSalesPlan))
        .then(res => {
          this.getPlans()
          this.$setSnackbar({ text: 'План встановлено' })
          return res
        })
        .catch(this.$err)
    },
    getPlans() {
      this.loading = true
      return this.$axios
        .get(urlGetFrontSalesPlan())
        .then(res => {
          this.plans.splice(0)
          this.plans.push(...res.data)
          this.loading = false
          return res
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
  },
  watch: {
    plans() {
      this.initManagersPlan()
    },
    slide() {
      this.$nextTick(() => (this.slide = false))
    },
  },
  created() {
    Promise.all([
      this.$store.dispatch('addFrontManagers'),
      this.$store.dispatch('addSalesPlanTypes'),
      this.getPlans(),
    ]).then(this.initManagersPlan)
  },
}
</script>
<style lang="scss">
.sales-plan {
  .v-input--is-disabled {
    input {
      color: grey !important;
    }
  }
}
.switch-label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.95rem;
  margin: 0 5px;
}
.row-padding {
  border-bottom: 1px solid gainsboro;
  padding: 10px 0;
}
.main-col-text {
  font-size: 0.95rem;
}
.manager-detail-row {
  border-bottom: 1px solid lightblue;
  &:hover {
    background: #25212107;
  }
  &:focus-within {
    background: #25212107;
  }
}
.manager-row {
  border-bottom: 1px solid gainsboro;
  padding-bottom: 8px;
}
</style>
