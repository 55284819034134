<template>
  <div class="mt-2">
    <v-data-table
      :items="connections"
      :headers="dkpConnectionsHeaders"
      item-value="id">
      <template #item.number="{ item }">
        <div class="d-flex align-center">
          <v-icon color="#09487a" class="mr-2">mdi-file-document</v-icon>
          <ALink
            :route="{
              name: item.routeName,
              params: { id: item.id },
            }"
            :text="item.number" />
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script lang="ts">
import ALink from '@/components/Link.vue'
import { getEntityRoute, toFormatDate } from '@/utils/helperFunc'
import { dkpConnectionsHeaders } from '@/utils/tableHeaders'
import { computed, inject } from 'vue'
import { contractKey } from '../injectionKeys'

export default {
  components: { ALink },
  data: () => {
    return { dkpConnectionsHeaders }
  },
  methods: {
    getEntityRoute,
  },
  setup() {
    const contract = inject(contractKey)

    const connections = computed(() => {
      const list = []

      if (contract.contract) {
        const { id, number, date, status } = contract.contract
        list.push({
          type: 'Договір фінансового лізингу',
          routeName: 'dfl-contract',
          id,
          number,
          date: toFormatDate(date, true),
          status,
        })
      }

      contract.extraAgreementsDkp?.forEach(agreement => {
        const { id, number, date, status } = agreement
        list.push({
          type: 'Додаткова угода',
          routeName: 'du-dkp-agreement',
          id,
          number,
          date: toFormatDate(date, true),
          status,
        })
      })

      return list
    })

    return { connections }
  },
}
</script>
