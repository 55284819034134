// @ts-check
/* eslint-disable */
import {
  ADMIN,
  FRONT_MANAGER,
  SUPERVISOR,
  SALES_DIRECTOR,
  MIDLE_MANAGER,
  MIDLE_HEAD,
  RISK_MANAGER,
  RISK_HEAD,
  SECURITY_SERVICE,
  LEGAL_MANAGER,
  LEGAL_HEAD,
  FINANCIAL_MONITORING,
  MARKETING_MANAGER,
  CEO,
  SERVICE_MANAGER,
  SERVICE_HEAD,
  ARCHIVIST,
  DISABLED,
  BOOK_KEEPER,
  LEGAL_CASE,
  AUDITOR,
} from '@/roles'
/* eslint-enable */

export default [
  {
    title: 'Головна',
    to: { name: 'main' },
    icon: 'mdi-view-dashboard',
    roleId: [],
  },
  {
    title: 'Лiди',
    to: { name: 'leads' },
    icon: 'mdi-book-information-variant',
    roleId: [
      ADMIN,
      FRONT_MANAGER,
      SUPERVISOR,
      SALES_DIRECTOR,
      MIDLE_MANAGER,
      MIDLE_HEAD,
      RISK_MANAGER,
      RISK_HEAD,
      SECURITY_SERVICE,
      LEGAL_MANAGER,
      LEGAL_HEAD,
      FINANCIAL_MONITORING,
      MARKETING_MANAGER,
      CEO,
      SERVICE_MANAGER,
      SERVICE_HEAD,
      ARCHIVIST,
      BOOK_KEEPER,
    ],
  },
  {
    title: 'Заявки',
    to: { name: 'applications' },
    icon: 'mdi-format-list-checks',
    roleId: [
      ADMIN,
      FRONT_MANAGER,
      SUPERVISOR,
      SALES_DIRECTOR,
      SECURITY_SERVICE,
      FINANCIAL_MONITORING,
      CEO,
      MARKETING_MANAGER,
      SERVICE_MANAGER,
      SERVICE_HEAD,
    ],
  },
  {
    title: 'Сервіс',
    to: { name: 'service' },
    icon: 'mdi-toolbox-outline',
    roleId: [ADMIN, SERVICE_MANAGER, SERVICE_HEAD],
    nested: [
      {
        title: 'Заявки',
        to: 'service-application-list',
        icon: 'mdi-progress-wrench',
        roleId: [],
      },
    ],
  },
  {
    title: 'Розрахунки',
    to: { name: 'user-calculations' },
    icon: 'mdi-calculator-variant',
    roleId: [
      ADMIN,
      FRONT_MANAGER,
      SUPERVISOR,
      SALES_DIRECTOR,
      SECURITY_SERVICE,
      FINANCIAL_MONITORING,
      CEO,
      MARKETING_MANAGER,
      SERVICE_MANAGER,
    ],
  },
  {
    title: 'Контакти',
    to: { name: 'contacts' },
    icon: 'mdi-account-circle',
    roleId: [
      ADMIN,
      FRONT_MANAGER,
      SUPERVISOR,
      SALES_DIRECTOR,
      MIDLE_MANAGER,
      MIDLE_HEAD,
      RISK_MANAGER,
      RISK_HEAD,
      SECURITY_SERVICE,
      LEGAL_MANAGER,
      LEGAL_HEAD,
      FINANCIAL_MONITORING,
      MARKETING_MANAGER,
      CEO,
      SERVICE_MANAGER,
      SERVICE_HEAD,
      ARCHIVIST,
    ],
    nested: [
      {
        title: 'Контрагенти',
        to: { name: 'contractors' },
        icon: 'mdi-sitemap',
        roleId: [],
      },
      {
        title: 'Фiзичнi особи та ФОП',
        to: { name: 'individuals' },
        icon: 'mdi-account-tie',
        roleId: [],
      },
      {
        title: 'Торгові точки',
        to: { name: 'dealer-list' },
        icon: 'mdi-file-check-outline',
        roleId: [ADMIN, FRONT_MANAGER, SALES_DIRECTOR, SUPERVISOR, CEO],
      },
      {
        title: 'Агенти',
        to: { name: 'agent-list' },
        icon: 'mdi-file-check-outline',
        roleId: [ADMIN, FRONT_MANAGER, SALES_DIRECTOR, SUPERVISOR, CEO],
      },
    ],
  },
  {
    title: 'Проекти',
    to: { name: 'projects' },
    icon: 'mdi-briefcase-check',
    roleId: [
      ADMIN,
      FRONT_MANAGER,
      MIDLE_MANAGER,
      MIDLE_HEAD,
      SALES_DIRECTOR,
      SUPERVISOR,
      CEO,
    ],
  },
  {
    title: 'Legal Case',
    to: { name: 'legalcase' },
    icon: 'mdi-text-box-check',
    roleId: [
      ADMIN,
      LEGAL_MANAGER,
      LEGAL_HEAD,
      LEGAL_CASE,
      SALES_DIRECTOR,
      SUPERVISOR,
      FRONT_MANAGER,
    ],
    nested: [
      {
        title: 'Дебiторська заборгованнiсть',
        to: { name: 'delayList' },
        icon: 'mdi-file-check-outline',
        roleId: [],
      },
    ],
  },
  {
    title: 'Договори',
    to: { name: 'contracts' },
    icon: 'mdi-text-box-check',
    roleId: [ADMIN, MIDLE_MANAGER, MIDLE_HEAD, CEO],
    nested: [
      {
        title: 'Договір купівлі-продажу',
        to: { name: 'dkp-contracts' },
        icon: 'mdi-file-check-outline',
        roleId: [],
      },
      {
        title: 'Договір фiнансового лізингу',
        to: { name: 'dfl-contracts' },
        icon: 'mdi-file-check-outline',
        roleId: [],
      },
      {
        title: 'Договір оперативного лізингу',
        to: { name: 'dol-contracts' },
        icon: 'mdi-file-check-outline',
        roleId: [],
      },
      {
        title: 'Договір сервісу',
        to: { name: 'services-contracts' },
        icon: 'mdi-file-check-outline',
        roleId: [],
      },
      {
        title: 'Додаткова угода',
        to: { name: 'additional-agreements' },
        icon: 'mdi-file-check-outline',
        roleId: [],
      },
      {
        title: 'Договір поруки',
        to: { name: 'surety-agreements' },
        icon: 'mdi-file-check-outline',
        roleId: [],
      },
      {
        title: 'Договір застави',
        to: { name: 'mortgagor-agreements' },
        icon: 'mdi-file-check-outline',
        roleId: [],
      },
    ],
  },
  {
    title: 'Оцінка середньої вартості',
    to: { name: 'average-price' },
    icon: 'mdi-calculator',
    roleId: [ADMIN, FRONT_MANAGER, SUPERVISOR, MARKETING_MANAGER],
  },
  {
    title: 'E-Docs',
    to: { name: 'e-docs' },
    icon: 'mdi-file-check-outline',
    roleId: [
      BOOK_KEEPER,
      ADMIN,
      LEGAL_CASE,
      SECURITY_SERVICE,
      AUDITOR,
      SERVICE_HEAD,
    ],
  },
  {
    title: 'Автопарк',
    to: { name: 'car-for-sale-list' },
    icon: 'mdi-car-multiple',
    roleId: [ADMIN, FRONT_MANAGER, SALES_DIRECTOR],
  },
  {
    title: 'Обʼєкти лізингу',
    to: { name: 'leasing-object-list' },
    icon: 'mdi-file-check-outline',
    roleId: [
      ADMIN,
      FRONT_MANAGER,
      SUPERVISOR,
      SALES_DIRECTOR,
      MIDLE_MANAGER,
      MIDLE_HEAD,
      SECURITY_SERVICE,
      FINANCIAL_MONITORING,
      CEO,
      BOOK_KEEPER,
      MARKETING_MANAGER,
      SERVICE_MANAGER,
      SERVICE_HEAD,
    ],
  },
  {
    title: 'Бухгалтерiя',
    to: { name: 'bookkeeping' },
    icon: 'mdi-file-check-outline',
    roleId: [BOOK_KEEPER, ADMIN],
    nested: [
      {
        title: 'Виплати',
        to: { name: 'book-keeping-payments' },
        icon: 'mdi-file-document-edit',
        roleId: [ADMIN, BOOK_KEEPER],
      },
    ],
  },
  {
    title: 'Маркетинг',
    to: { name: 'marketing' },
    icon: 'mdi-calendar-clock',
    roleId: [ADMIN, MARKETING_MANAGER],
    nested: [
      {
        title: 'Рекламнi кампанії',
        to: { name: 'marketing-company' },
        icon: 'mdi-file-document-edit',
        roleId: [ADMIN, MARKETING_MANAGER],
      },
    ],
  },
  {
    title: 'Активностi',
    to: { name: 'activity' },
    icon: 'mdi-calendar-clock',
    roleId: [
      ADMIN,
      FRONT_MANAGER,
      SUPERVISOR,
      SALES_DIRECTOR,
      MIDLE_MANAGER,
      MIDLE_HEAD,
      SECURITY_SERVICE,
      FINANCIAL_MONITORING,
      CEO,
      MARKETING_MANAGER,
      SERVICE_MANAGER,
      SERVICE_HEAD,
    ],
    nested: [
      {
        title: 'Мої зустрiчi',
        to: { name: 'my-meetings' },
        icon: 'mdi-account-tie',
        roleId: [
          ADMIN,
          FRONT_MANAGER,
          SUPERVISOR,
          SALES_DIRECTOR,
          SECURITY_SERVICE,
          LEGAL_MANAGER,
          LEGAL_HEAD,
          FINANCIAL_MONITORING,
          CEO,
          BOOK_KEEPER,
          MARKETING_MANAGER,
          SERVICE_MANAGER,
          SERVICE_HEAD,
        ],
      },
      {
        title: 'Мої нагадування',
        to: { name: 'my-reminder' },
        icon: 'mdi-sitemap',
        roleId: [
          ADMIN,
          FRONT_MANAGER,
          SUPERVISOR,
          SALES_DIRECTOR,
          MIDLE_MANAGER,
          MIDLE_HEAD,
          SECURITY_SERVICE,
          LEGAL_MANAGER,
          LEGAL_HEAD,
          FINANCIAL_MONITORING,
          CEO,
          BOOK_KEEPER,
          MARKETING_MANAGER,
          SERVICE_MANAGER,
          SERVICE_HEAD,
        ],
      },
      {
        title: 'Запрошення',
        to: { name: 'invitations' },
        icon: 'mdi-sitemap',
        roleId: [
          ADMIN,
          FRONT_MANAGER,
          SUPERVISOR,
          SALES_DIRECTOR,
          MIDLE_MANAGER,
          MIDLE_HEAD,
          SECURITY_SERVICE,
          LEGAL_MANAGER,
          LEGAL_HEAD,
          FINANCIAL_MONITORING,
          CEO,
          BOOK_KEEPER,
          MARKETING_MANAGER,
          SERVICE_MANAGER,
          SERVICE_HEAD,
        ],
      },
    ],
  },
  {
    title: 'Проекти на перевірку',
    to: { name: 'verification' },
    icon: 'mdi-check-outline',
    roleId: [
      ADMIN,
      RISK_MANAGER,
      SECURITY_SERVICE,
      LEGAL_MANAGER,
      LEGAL_HEAD,
      FINANCIAL_MONITORING,
      RISK_HEAD,
      FRONT_MANAGER,
      AUDITOR,
      SUPERVISOR,
      CEO,
    ],
    nested: [
      {
        title: 'Служба безпеки',
        to: {
          name: 'security-service-verification-list',
          params: { tabState: 'main' },
        },
        icon: 'mdi-alert-decagram-outline',
        roleId: [ADMIN, FRONT_MANAGER, SECURITY_SERVICE, SUPERVISOR, CEO],
      },
      {
        title: 'Юридичний департамент',
        to: { name: 'security-service-legal-department' },
        icon: 'mdi-alert-decagram-outline',
        roleId: [ADMIN, LEGAL_MANAGER, LEGAL_HEAD, LEGAL_CASE, CEO],
      },
      {
        title: 'Ризик департамент',
        to: { name: 'risk-department', params: { tabState: 'main' } },
        icon: 'mdi-alert-decagram-outline',
        roleId: [
          ADMIN,
          FRONT_MANAGER,
          RISK_MANAGER,
          RISK_HEAD,
          SUPERVISOR,
          CEO,
        ],
      },
      {
        title: 'Фiнансовий монiторинг',
        to: { name: 'financial-department', params: { tabState: 'main' } },
        icon: 'mdi-alert-decagram-outline',
        roleId: [
          ADMIN,
          FRONT_MANAGER,
          FINANCIAL_MONITORING,
          LEGAL_HEAD,
          SUPERVISOR,
          CEO,
          AUDITOR,
        ],
      },
      {
        title: 'Комітет по лізинговим проектам',
        to: { name: 'leasing-committee-projects' },
        icon: 'mdi-alert-decagram-outline',
        roleId: [ADMIN, CEO, RISK_MANAGER, RISK_HEAD, AUDITOR],
      },
    ],
  },
  {
    title: 'Архівні дані',
    to: { name: 'archive-list' },
    icon: 'mdi-archive',
    roleId: [ADMIN, ARCHIVIST],
  },
  {
    title: 'Плани',
    to: { name: 'plan-list' },
    icon: 'mdi-file-document-edit',
    roleId: [ADMIN, MIDLE_HEAD, RISK_HEAD, SALES_DIRECTOR],
  },
  {
    title: 'Адміністрування',
    to: { name: 'administrIndex' },
    icon: 'mdi-cogs',
    roleId: [ADMIN],
    nested: [
      {
        title: 'Плани',
        to: { name: 'admin-plan-list' },
        icon: 'mdi-file-document-edit',
        roleId: [ADMIN],
      },
      {
        title: 'Співробітники',
        to: { name: 'employees' },
        icon: 'mdi-account-supervisor',
        roleId: [ADMIN],
      },
      {
        title: 'Департаменти',
        to: { name: 'departments' },
        icon: 'mdi-account-network',
        roleId: [ADMIN],
      },
      {
        title: 'Супервайзери',
        to: { name: 'admin-supervisor-list' },
        icon: 'mdi-file-document-edit',
        roleId: [ADMIN],
      },
      {
        title: 'Телефонi дзвінки',
        to: { name: 'admin-calls-phones' },
        icon: 'mdi-file-document-edit',
        roleId: [ADMIN],
      },
      {
        title: 'Дзвінки по лідам',
        to: { name: 'admin-lead-calls' },
        icon: 'mdi-file-document-edit',
        roleId: [ADMIN],
      },
      {
        title: 'Перезакріплення',
        to: { name: 'change-responsible' },
        icon: 'mdi-account-switch-outline',
        roleId: [ADMIN],
      },
      {
        title: 'Довідники',
        to: { name: 'administration-directories' },
        icon: 'mdi-playlist-edit',
        roleId: [ADMIN],
      },
      {
        title: 'Логування',
        to: { name: 'logging' },
        icon: 'mdi-file-document-edit',
        roleId: [ADMIN],
      },
      {
        title: 'Шаблони',
        to: { name: 'tiny-templates' },
        icon: 'mdi-file-document-edit',
        roleId: [ADMIN],
      },
      {
        title: 'Шаблони ДКП Бест Лізинг',
        to: { name: 'best-leasing-templates' },
        icon: 'mdi-file-document-edit',
        roleId: [ADMIN],
      },
      {
        title: 'Тiкети',
        to: { name: 'administration-tickets' },
        icon: 'mdi-file-document-edit',
        roleId: [ADMIN],
      },
      {
        title: 'FAQ',
        to: { name: 'admin-faq' },
        icon: 'mdi-file-document-edit',
        roleId: [ADMIN],
      },
    ],
  },
  // {
  //   title: 'Планування',
  //   to: '/crm/planning',
  //   icon: 'mdi-clock-outline',
  //   roleId: [SALES_DIRECTOR],
  // },
  {
    title: 'Підтримка',
    to: { name: 'support' },
    icon: 'mdi-face-agent',
    // icon: 'mdi-file-check-outline',
    roleId: [],
  },
  {
    title: 'Часті питання',
    to: { name: 'help' },
    icon: 'mdi-frequently-asked-questions',
    roleId: [],
  },
  // {
  //   title: 'Проекти на погодження',
  //   to: '/crm/sliders',
  //   icon: 'mdi-text-box-check',
  //   roleId: [SALES_DIRECTOR],
  // },
]
