<template>
  <div>
    <div v-if="providerRoute">
      <div v-if="provider">
        <component
          :is="providerContractor ? 'ContractorCard' : 'ContactCard'"
          :contact="provider"
          :contractor="provider"
          legalDocs
          finDocs />
      </div>
    </div>

    <RouterView v-else :calculation="calculation" />
  </div>
</template>

<script>
import ContactCard from '@/components/ContactCard.vue'
import ContractorCard from '@/components/ContractorCard.vue'
import { useSelect } from '@/utils/mixins/useSelect'
export default {
  name: 'Provider',
  components: {
    ContactCard,
    ContractorCard,
  },
  setup() {
    return {
      ...useSelect(),
    }
  },
  props: {
    provider: { type: Object },
    calculation: { type: Object },
    scoring: { type: Object },
  },
  computed: {
    providerRoute() {
      return this.$route.name.includes('sc-provider-verification')
    },
    providerContractor() {
      return this.provider.providerTypeId === 2
    },
    providerContact() {
      return this.provider.providerTypeId !== 2
    },
  },
}
</script>

<style scoped></style>
