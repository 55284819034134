<template>
  <svg id="Layer_1" enable-background="new 0 0 512 512" :height="height" viewBox="0 0 512 512" :width="width" :fill="color" xmlns="http://www.w3.org/2000/svg">
    <path d="m273.318 261.626c1.726 4.465 2.682 9.308 2.682 14.374 0 22.056-17.944 40-40 40s-40-17.944-40-40 17.944-40 40-40c2.111 0 4.184.167 6.208.484l62.233-77.051c6.94-8.593 19.533-9.933 28.125-2.992 8.593 6.94 9.932 19.532 2.992 28.125zm-25.504 210.024c-3.907.232-7.882.35-11.814.35-108.075 0-196-87.925-196-196s87.925-196 196-196c106.13 0 194.041 86.294 195.967 192.363.201 11.043 9.323 19.832 20.36 19.633 11.044-.201 19.834-9.316 19.633-20.36-1.128-62.122-26.174-120.344-70.523-163.942-39.619-38.947-90.663-62.266-145.437-66.844v-20.85c0-11.046-8.954-20-20-20s-20 8.954-20 20v20.847c-55.494 4.627-107.051 28.449-146.877 68.275-44.575 44.575-69.123 103.84-69.123 166.878s24.548 122.303 69.123 166.877c44.574 44.575 103.839 69.123 166.877 69.123 4.72 0 9.493-.141 14.186-.42 11.026-.655 19.434-10.124 18.779-21.15s-10.12-19.435-21.151-18.78zm124.41-422.262 53.954 44.796c3.735 3.101 8.263 4.613 12.765 4.613 5.743 0 11.443-2.461 15.398-7.225 7.056-8.499 5.887-21.107-2.612-28.164l-53.954-44.796c-8.499-7.056-21.108-5.887-28.164 2.612-7.054 8.499-5.885 21.108 2.613 28.164zm-339.166 49.409c4.442 0 8.913-1.473 12.625-4.499l54.954-44.796c8.562-6.979 9.844-19.577 2.865-28.139s-19.577-9.845-28.139-2.865l-54.954 44.796c-8.562 6.979-9.844 19.577-2.865 28.139 3.953 4.85 9.709 7.364 15.514 7.364zm478.942 293.203c0 12.547-6.401 23.93-17.124 30.449l-138.721 84.339c-5.672 3.449-12.093 5.18-18.518 5.18-6.021 0-12.045-1.519-17.468-4.568-11.207-6.302-18.169-18.204-18.169-31.061v-168.678c0-12.857 6.962-24.759 18.169-31.061 11.208-6.303 24.999-6.069 35.986.612l138.721 84.339c10.723 6.519 17.124 17.902 17.124 30.449zm-44.038 0-125.962-76.583v153.165z"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 25
    },
    height: {
      type: Number,
      default: 25
    },
    color: {
      type: String,
      default: 'grey'
    }
  }
}
</script>