import Loader from '@/components/Loader.vue'
import Item from './Item.vue'
import { cloneDeep, pick, isMatchWith, capitalize } from 'lodash'
import { urlGetCarData } from '@/pages/request'
import {
  standardOrder,
  specialOrder,
  getItemsKey,
  topLeasingObjects,
  getTopMarks,
  getTopBodies,
} from './helpers'
import { setErrHandler, sortItemsByText } from '@/utils/helperFunc'
import {
  CAR,
  SPECIAL,
  TRUCK,
  BUS,
  AGRICULTURAL,
  MOTO,
  COMMERCIAL_PROPERTY,
} from '@/pages/calculations/object-types'
import { mapState } from 'vuex'
import { useSelect } from '@/utils/mixins/useSelect'

const isMatchCustomizer = (value1, value2) => {
  if (value1?.constructor.name !== value2?.constructor.name) {
    return false
  }
  if (typeof value1 === 'object') {
    return isMatchWith(value1, value2, isMatchCustomizer)
  }
  return value1 === value2
}

export default {
  name: 'CarApi',
  components: { Loader, Item },
  emits: ['update:model-value'],
  setup() {
    return {
      ...useSelect(),
    }
  },
  props: {
    modelValue: {
      type: Object,
    },
    v: {
      type: Object,
    },
    displayMode: {
      type: String,
    },
  },
  data: () => {
    return {
      carData: {
        leasingObjectType: null,
        leasedAssertMark: null,
        leasedAssertModel: null,
        leasingObjectYear: null,
        generation: null,
        body: null,
        modification: null,
        specification: null,
        weight: null,
        engine: null,
        transmission: null,
        tires: [],
      },
      items: {
        leasingObjectTypes: [],
        leasedAssertMarks: [],
        leasedAssertModels: [],
        leasingObjectYears: [],
        generations: [],
        bodies: [],
        modifications: [],
        specifications: [],
      },
    }
  },
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    valueSynced() {
      return isMatchWith(
        this.carData,
        pick(cloneDeep(this.modelValue), standardOrder),
        isMatchCustomizer
      )
    },
    standardType() {
      return this.car || this.truck || this.moto || this.bus
    },
    carSelectOrder() {
      return this.standardType ? standardOrder : specialOrder
    },
    car() {
      return this.carData.leasingObjectType?.id === CAR
    },
    moto() {
      return this.carData.leasingObjectType?.id === MOTO
    },
    truck() {
      return this.carData.leasingObjectType?.id === TRUCK
    },
    special() {
      return this.carData.leasingObjectType?.id === SPECIAL
    },
    agricultural() {
      return this.carData.leasingObjectType?.id === AGRICULTURAL
    },
    bus() {
      return this.carData.leasingObjectType?.id === BUS
    },
    commercialProperty() {
      return this.carData?.leasingObjectType?.id === COMMERCIAL_PROPERTY
    },
    leasingObjectTypeErr() {
      return setErrHandler(this.v?.leasingObjectType)
    },
    leasedAssertMarkErr() {
      return setErrHandler(this.v?.leasedAssertMark)
    },
    leasedAssertModelErr() {
      return setErrHandler(this.v?.leasedAssertModel)
    },
    leasingObjectYearErr() {
      return setErrHandler(this.v?.leasingObjectYear)
    },
    generationErr() {
      return setErrHandler(this.v?.generation)
    },
    bodyErr() {
      return setErrHandler(this.v?.body)
    },
    modificationErr() {
      return setErrHandler(this.v?.modification)
    },
    specificationErr() {
      return setErrHandler(this.v?.specification)
    },
  },
  methods: {
    getTopMarks,
    getTopBodies,
    clearFields(fields) {
      fields.forEach(f => {
        if (this.carData[f]) {
          this.carData[f] = null
        }
        if (getItemsKey(f) in this.items) {
          if (!this.standardType && f === 'leasingObjectYear') {
            return
          }
          this.items[getItemsKey(f)] = []
        }
      })
    },
    getCarData(fieldName) {
      const itemsKey = getItemsKey(fieldName)
      const orderIdx = this.carSelectOrder.findIndex(n => n === fieldName)

      /* make request if previous value is not empty */
      const previousFieldKey = this.carSelectOrder[orderIdx - 1]
      if (previousFieldKey && !this.carData[previousFieldKey]) {
        return
      }

      return this.asyncAction(`get${capitalize(itemsKey)}`, null, () => {
        const query = {}
        let hasAllQuery = true
        for (const idx in this.carSelectOrder) {
          const key = this.carSelectOrder[idx]
          if (idx < orderIdx) {
            if (!this.carData[key]) {
              hasAllQuery = false
              break
            }
            if (
              typeof this.carData[key] === 'object' &&
              !this.carData[key].id
            ) {
              hasAllQuery = false
              break
            }
            query[key] =
              typeof this.carData[key] === 'object'
                ? this.carData[key].id
                : this.carData[key]
          }
        }

        if (!hasAllQuery) return

        return this.$axios
          .get(urlGetCarData(query), {
            headers: { 'X-API-SECRET': import.meta.env.VITE_ERM_API_SECRET },
          })
          .then(res => {
            const key = getItemsKey(fieldName)
            if (key === 'leasingObjectTypes') {
              this.items[key] = sortItemsByText(
                res.data,
                topLeasingObjects,
                'name',
                false
              )
            }
            if (key === 'leasedAssertMarks') {
              this.items[key] = sortItemsByText(
                res.data,
                this.getTopMarks(),
                'name'
              )
              return
            }
            if (key === 'leasingObjectYears') {
              this.items[key] = res.data.map(v => v.year)
              return
            }
            if (key === 'generations' && res.data.length === 1) {
              this.carData.generation = res.data[0]
            }
            if (key === 'bodies') {
              this.items[key] = sortItemsByText(res.data)
              return
            }
            if (key === 'specifications') {
              this.items[key] = res.data.specifications || []
              this.carData.tires = res.data.tires || []
              this.carData.weight = res.data.modification_weight
              this.carData.engine = res.data.modification_engine
              this.carData.transmission = res.data.modification_transmission
              return
            }
            this.items[key] = res.data || []
          })
      })
    },
    carSelectHandler(fieldName) {
      const curOrderIdx = this.carSelectOrder.findIndex(n => n === fieldName)

      const order =
        fieldName === 'leasingObjectType' ? standardOrder : this.carSelectOrder

      if (fieldName !== 'specification') {
        this.clearFields(order.filter((n, i) => i > curOrderIdx))
      }

      if (fieldName === 'specification') {
        return
      }

      const nextFieldName = this.carSelectOrder[curOrderIdx + 1]
      nextFieldName && this.getCarData(nextFieldName)
    },
  },
  watch: {
    'carData.leasingObjectType': {
      handler() {
        if (!this.standardType) {
          if (this.agricultural || this.special) {
            this.items.leasingObjectYears = this.selectItems.itemYearsExpanded
          } else {
            this.items.leasingObjectYears = this.selectItems.itemYears
          }
        }
      },
      deep: true,
      immediate: true,
    },
    carData: {
      handler() {
        if (!this.valueSynced) {
          const updatedValue = cloneDeep(this.modelValue)
          Object.assign(updatedValue, this.carData)
          this.$emit('update:model-value', updatedValue)
        }
      },
      deep: true,
    },
    modelValue: {
      handler(v) {
        if (!this.valueSynced) {
          Object.assign(this.carData, pick(cloneDeep(v), standardOrder))
        }
      },
      deep: true,
      immediate: true,
    },
  },
}
