<template>
  <div>
    <div v-if="projectsRoute">
      <div class="projects-wrapper">
        <v-fade-transition hide-on-leave>
          <div class="projects-table-wrapper">
            <div class="crm-section-title title-absolute">Проекти:</div>
            <div class="d-flex align-center">
              <Filters :filters="filters" class="ml-2">
                <template #header-action>
                  <ExportPfForm
                    :url="urlGetProjectsPrintedForm"
                    exportFileName="Список проектів"
                    :filters-query-obj="filtersQueryObj" />
                </template>
              </Filters>
            </div>
            <v-col
              v-show="!asyncLoading('getProjects')"
              style="padding: 3px"
              class="pa-0 pt-3"
              :cols="12">
              <v-fab-transition>
                <v-row>
                  <v-col :cols="12" class="pt-1">
                    <div class="parties-content">
                      <v-card-text ref="card" class="pl-0 pr-0 pt-0">
                        <v-data-table
                          :headers="projectHeaders"
                          :items="projects"
                          mobileBreakpoint="1200"
                          dense
                          hide-default-footer
                          :hide-default-header="!projects.length"
                          :custom-sort="tableDateSort('projectDate')"
                          :items-per-page="20">
                          <!-- eslint-disable-next-line -->
                          <template #item.status="{ item }">
                            <span>{{ item.status?.name || '---' }}</span>
                          </template>
                          <template #item.lessee="{ item }">
                            <ALink
                              :text="
                                item.lessee.lesseeTypeId === 3
                                  ? `ФОП ${item.lessee.lesseeShortName}`
                                  : item.lessee.lesseeShortName
                              "
                              :route="getEntityRoute(item)">
                            </ALink>
                          </template>
                          <!-- eslint-disable-next-line -->
                          <template #item.projectNumber="{ item }">
                            <ALink
                              :text="item.projectNumber"
                              :route="{
                                name: 'project',
                                params: { projectId: item.id },
                              }">
                            </ALink>
                          </template>
                          <template #item.analysisType="{ item }">
                            <div class="d-flex align-center">
                              <Benefit v-if="item.bestBenefit" class="mr-1" />
                              <ProgramFSN v-if="item.programFSN" class="mr-1" />
                              <span>{{ item.analysisType?.name }}</span>
                            </div>
                          </template>
                          <!-- eslint-disable-next-line -->
                          <template #item.projectDate="{ item }">
                            {{ toFormatDate(item.projectDate, true) }}
                          </template>
                          <!-- eslint-disable-next-line -->
                          <template #item.actions="{ item }">
                            <TableCrud
                              data-readonly="hide"
                              :actionsArray="[
                                {
                                  action: () => pushToProject(item),
                                  text: 'Відкрити',
                                  icon: 'mdi-folder-open-outline',
                                },
                                {
                                  action: () => openMiddleManagerDialog(item),
                                  text: 'Призначити відповідального мiдл менеджера',
                                  icon: 'mdi-account-check',
                                  disabled: ![1, 6].includes(
                                    user.roleId
                                  ) /* MidlHead */,
                                },
                              ]">
                            </TableCrud>
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </div>
                  </v-col>
                </v-row>
              </v-fab-transition>
              <Pagination
                :getItems="getProjects"
                :trigger="paginationTrigger" />
            </v-col>
          </div>
        </v-fade-transition>
        <SectionLoader
          v-if="asyncLoading('getProjects') && projectsRoute"></SectionLoader>
      </div>
    </div>
    <router-view v-if="!projectsRoute"></router-view>
  </div>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'
import TableCrud from '@/components/table-crud.vue'
import ALink from '@/components/Link.vue'
import Pagination from '@/components/Pagination.vue'
import { formatDate, humanDate } from '@/utils/formatFunc'
import {
  urlGetProjects,
  urlGetFilteredProjects,
  urlGetProjectsPrintedForm,
} from '@/pages/request'
import {
  backDate,
  openDocument,
  tableDateSort,
  toFormatDate,
} from '@/utils/helperFunc'
import { mapState } from 'vuex'
import { getContactInitials } from '@/utils/helperFunc'
import Filters from '@/components/Filters.vue'
import Benefit from '@/assets/svg/benefit.vue'
import { useSelect } from '@/utils/mixins/useSelect'
import { useFilter } from '@/utils/mixins/useFilter'
import { reactive } from 'vue'
import { setSnackbar } from 'best-modules/plugins/index'
import { projectHeaders } from '@/utils/tableHeaders'
import ProgramFSN from '@/assets/png/programFSN.vue'
import ExportPfForm from '@/components/ExportPfForm.vue'

export default {
  components: {
    ExportPfForm,
    ProgramFSN,
    Benefit,
    Filters,
    TableCrud,
    SectionLoader,
    ALink,
    Pagination,
  },
  setup() {
    const filters = reactive({
      statusId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { label: 'Статус', items: 'projectStatuses' },
      },
      analysisTypeId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { label: 'Тип', items: 'analysisTypes' },
      },
      startDate: {
        value: null,
        component: 'DatePicker',
        attrs: { label: 'Період', range: true },
      },
      leasingProgramId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Програма',
          items: 'leasingPrograms',
          itemValue: 'programId',
        },
      },
      frontManagerResponsibleId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Відповідальний фронт',
          itemTitle: u => getContactInitials(u, { isResponsible: true }),
          items: 'frontManagers',
        },
      },
      middleManagerResponsibleId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Відповідальний мідл',
          itemTitle: u => getContactInitials(u, { isResponsible: true }),
          items: 'middleManagersAll',
        },
      },
      search: { value: null },
    })
    return {
      ...useSelect(),
      ...useFilter(filters),
      filters,
    }
  },
  data: () => {
    return {
      projects: [],
      exportParam: false,
      projectHeaders,
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    validRole() {
      const validRoles = [5, 6, 2, 1, 3, 4 /* temporary */]

      return validRoles.includes(this.roleId)
    },
    roleId() {
      return this.$store.state?.user?.roleId
    },
    projectsRoute() {
      return this.$route.name === 'projects'
    },
  },
  methods: {
    urlGetProjectsPrintedForm,
    formatDate,
    humanDate,
    backDate,
    urlGetProjects,
    urlGetFilteredProjects,
    tableDateSort,
    toFormatDate,
    getContactInitials,
    getEntityRoute(item) {
      const typeContractor = item.lessee?.lesseeTypeId === 2
      const name = typeContractor ? 'contractors-form' : 'individuals-form'
      const id = item.lessee?.lesseeId
      return { name, params: { id } }
    },
    openMiddleManagerDialog(item) {
      this.$store.commit('setDialog', {
        title: 'Закріплення мідл менеджера',
        dialogItem: { middleManagerResponsibleId: null },
        params: {
          projectId: item.id,
          cb: () => this.responsibleCallback(),
        },
        action: 'assignProjectMiddleManager',
        disabled: false,
      })
    },
    responsibleCallback() {
      setSnackbar({ text: 'Призначено мiдл менеджера' })
      this.getProjects()
    },
    checkAccess() {
      if (!this.roleId) return
      if (!this.validRole) {
        setSnackbar({ text: 'Відмовлено в доступі', color: 'error' })
        this.$router.push({ name: 'crm' })
      }
    },
    pushToProject(item) {
      this.$router.push({ name: 'project', params: { projectId: item.id } })
    },
    getProjects(page = 1) {
      return this.asyncAction('getProjects', null, () => {
        return this.$axios
          .get(this.urlGetFilteredProjects(page, this.filtersQueryObj))
          .then(res => {
            if (res.data.message) {
              return setSnackbar({
                text: res.data.message,
                color: 'warning',
              })
            }
            if (res?.data?.message && res?.data?.status !== 200) {
              return setSnackbar({
                text: res.data.message,
                color: 'warning',
              })
            }
            if (res?.data?.url) {
              openDocument({ url: res.data.url, text: 'export projects' })
              this.$store.commit('refreshPage')

              return
            }
            this.projects = []
            this.projects.push(...res.data.data)

            return res
          })
          .catch(this.$err)
      })
    },
  },
  watch: {
    roleId() {
      this.checkAccess()
    },
  },
  mounted() {
    this.checkAccess()
  },
}
</script>

<style>
.title-absolute {
  position: absolute;
  top: -50px;
  left: 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #08487a;
}
</style>
