<template>
  <div>
    <v-tabs
      v-model="currentTab"
      class="mb-5"
      slider-color="#FC7247"
      :height="38">
      <v-tab replace :to="{ name: 'delayList' }">Прострочені платежі</v-tab>
      <v-tab replace :to="{ name: 'scheduledList' }">Планові платежі</v-tab>
    </v-tabs>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data: () => {
    return {
      currentTab: 0,
    }
  },
}
</script>
