<template>
  <div>
    <v-text-field
      v-model="departments.department"
      placeholder="Введіть текст"
      hide-details
      :error-messages="departmentErr"
      :readonly="dialog.dialogItem.currentMode === 'removeMode'"
      @blur="v$.departments.department.$touch()"
      @keyup.enter="submit()">
    </v-text-field>
  </div>
</template>

<script>
import { setErrHandler } from '@/utils/helperFunc'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

export default {
  setup() {
    return { v$: useVuelidate() }
  },
  props: { dialog: { type: Object } },
  validations() {
    return {
      departments: {
        department: { required },
      },
    }
  },
  data: () => ({
    departments: {
      department: null,
    },
  }),
  computed: {
    validation() {
      return { v$: this.v$.departments, name: 'departments' }
    },
    departmentErr() {
      return setErrHandler(this.v$?.departments?.department)
    },
  },
  methods: {
    submit() {
      switch (this.dialog.dialogItem.currentMode) {
        case 'editMode':
          return this.editDepartment()
        case 'addMode':
          return this.addDepartment()
        case 'removeMode':
          return this.deleteDepartment()
      }
    },
    addDepartment() {
      return this.$axios
        .post('/json/admin/department/create', {
          name: this.departments?.department,
        })
        .then(res => {
          this.$setSnackbar({ text: 'Департамент успішно створено' })
          this.$store.commit('refreshPage')

          return res
        })
        .catch(err => this.$err(err, () => (this.pageLoad = false)))
    },
    deleteDepartment() {
      return this.$axios
        .delete(
          `/json/admin/department/delete/${this.dialog.dialogItem.departmentItem?.id}`
        )
        .then(res => {
          this.$setSnackbar({ text: 'Департамент успішно видалено' })
          this.$store.commit('refreshPage')
          return res
        })
        .catch(err => this.$err(err, () => (this.pageLoad = false)))
    },

    editDepartment() {
      return this.$axios
        .post(
          `/json/admin/department/update/${this.dialog.dialogItem.departmentItem?.id}`,
          {
            name: this.departments?.department,
          }
        )
        .then(res => {
          this.$setSnackbar({ text: 'Департамент успішно оновлено' })
          this.$store.commit('refreshPage')

          return res
        })
        .catch(err => this.$err(err))
    },
  },
  mounted() {
    if (this.dialog.dialogItem.department) {
      this.departments.department = this.dialog.dialogItem.department
    }
  },
}
</script>

<style scoped></style>
