<template>
  <div>
    <b-select
      v-model="employeeId"
      :items="
        dialog.dialogItem.employees.filter(e => {
          return !e.isExecutiveAuthority || e.id === employeeId
        })
      "
      :item-title="
        e => `${e.contact.fullName} (${e.post?.name || 'Посаду не вказано'})`
      "
      :error-messages="getErrorMessages(v$.employeeId)"
      :hide-details="!v$.employeeId.$error"
      @blur="v$.employeeId.$touch()">
    </b-select>
  </div>
</template>

<script lang="ts">
import { ref } from 'vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { getErrorMessages } from 'best-modules/utils'
import {
  urlExecutiveAuthoritySet,
  urlExecutiveAuthorityUnset,
} from '@/pages/request.js'
import axios from '@/plugins/axios.js'

export default {
  name: 'ChangeExecutiveAuthority',
  props: {
    dialog: {
      type: Object,
    },
  },
  methods: { getErrorMessages },
  setup(props) {
    const employeeId = ref(props.dialog.dialogItem.employeeId)

    const rules = {
      employeeId: { required: required },
    }
    const v$ = useVuelidate(rules, { employeeId })

    const submit = async () => {
      if (employeeId.value !== props.dialog.dialogItem.employeeId) {
        const executiveAuthorities = props.dialog.dialogItem.employees
          .filter(e => {
            return (
              e.isExecutiveAuthority &&
              e.id !== props.dialog.dialogItem.employeeId
            )
          })
          .map(e => e.id)
        executiveAuthorities.push(employeeId.value)

        await axios.post(urlExecutiveAuthoritySet(), {
          executiveAuthorityId: props.dialog.dialogItem.executiveAuthorityId,
          contractorId: props.dialog.dialogItem.contractorId,
          employeeId: executiveAuthorities,
        })

        await axios.post(urlExecutiveAuthorityUnset(), {
          contractorId: props.dialog.dialogItem.contractorId,
          employeeId: [props.dialog.dialogItem.employeeId],
        })
      } else {
        return Promise.resolve()
      }
    }

    return { employeeId, v$, submit }
  },
}
</script>

<style scoped></style>
