<template>
  <div>
    <div v-if="guarantors.length">
      <v-tabs
        v-model="currentTab"
        style="align-items: center"
        hide-slider
        :show-arrows="guarantors.length > 1"
        :disabled="guarantors.length === 1">
        <div
          class="d-flex align-center"
          style="position: relative; max-width: 90%">
          <v-tab
            v-for="(guarantor, idx) in guarantors"
            :key="
              guarantor
                ? `${guarantor.entityId || null}${guarantor.entityTypeId}`
                : null
            "
            :value="idx">
            <div class="d-flex align-center">
              <span>{{ getGuarantorName(guarantor) }}</span>
            </div>
          </v-tab>
        </div>
      </v-tabs>
      <v-tabs-window v-model="currentTab">
        <v-tabs-window-item
          v-for="(guarantor, idx) in guarantors"
          :key="
            guarantor ? `${guarantor.entityId}${guarantor.entityTypeId}` : null
          "
          :value="idx">
          <Guarantor :guarantor="guarantor" :project="project" :idx="idx" />
        </v-tabs-window-item>
      </v-tabs-window>
    </div>
    <div v-else style="font-size: 1.25rem">Поручителі відсутні</div>
  </div>
</template>

<script>
import Guarantor from './Guarantor.vue'
import { mapState } from 'vuex'

export default {
  components: {
    Guarantor,
  },
  emits: ['dataChanged'],
  props: {
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      currentTab: 0,
    }
  },
  computed: {
    ...mapState({
      project: state => state.projects.project,
      slider: state => state.projects.slider,
    }),
    projectData() {
      return this.project
    },
    guarantors() {
      const project = { ...(this.project || {}), slider: this.slider }
      const guarantors = []

      if (!project || !project.slider || !project.slider.guarantors) {
        return guarantors
      }

      const sliderGuarantors = project.slider.guarantors

      for (const guarantor of sliderGuarantors) {
        if (guarantor.contact) {
          guarantors.push({
            id: guarantor.id,
            entityId: guarantor.entityId,
            entityTypeId: guarantor.entityTypeId,
            fullName: guarantor.contact.fullName,
            contact: guarantor.contact,
            guarantorApplicationForm: guarantor.guarantorApplicationForm,
            guarantorApplicationFormSigned:
              guarantor.guarantorApplicationFormSigned,
          })
        } else if (guarantor.contractor) {
          guarantors.push({
            id: guarantor.id,
            entityId: guarantor.entityId,
            entityTypeId: guarantor.entityTypeId,
            shortName: guarantor.contractor.shortName,
            guarantorApplicationForm: guarantor.guarantorApplicationForm,
            guarantorApplicationFormSigned:
              guarantor.guarantorApplicationFormSigned,
          })
        }
      }

      return guarantors
    },
  },
  methods: {
    emitDataChanged() {
      return this.$emit('dataChanged')
    },
    getGuarantorName(guarantor) {
      return guarantor.entityTypeId === 2
        ? guarantor.shortName
        : guarantor.fullName
    },
  },
}
</script>

<style scoped>
.v-tab {
  padding: 10px 10px !important;
  margin-right: 15px;
  border: 1px solid #eaeaea;
  background: #eaeaea;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  font-size: 13px !important;
}
.v-tab.v-tab-item--selected {
  background: #f2f5fa;
  border: 1px solid #1c1d21;
  box-shadow: 0 2px 8px rgb(0 0 0 / 4%);
  border-radius: 4px;
}

.v-tabs-slider-wrapper {
  display: none !important;
}
</style>
