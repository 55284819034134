<template>
  <v-card-text>
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <div class="projectCard justify-space-between">
          <div class="d-flex align-content-center">
            <GeneralInfoIcon style="margin-right: 5px" />
            <span class="projectTitle">Загальні дані</span>
          </div>
          <div>
            <TableCrud
              class="mr-3"
              customIcon="Printer"
              :actionsArray="printedForms" />
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <span class="label mb-2">Тип запиту</span>
        <v-radio-group
          v-model="project.analysisTypeId"
          style="margin-top: -10px"
          class="size-18 sm-label"
          row
          inline
          dense
          hide-details
          :disabled="!!project.readOnly">
          <v-radio
            v-for="type in $directory.get('analysisTypes')"
            :key="type.id"
            :disabled="type.id === 3 && isPerson"
            :label="type.name"
            color="red"
            :value="type.id">
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12" sm="12" class="align-center pt-0">
        <div>
          <span class="label">Джерело</span>
          <div class="d-flex align-center">
            <ALink
              v-if="sourceType === 'Агент'"
              :text="agentData.fullName + '(Агент)'"
              :route="{
                name: 'individuals-form',
                params: { id: agentData.id },
              }">
            </ALink>
            <span v-else class="mr-2">{{ sourceType }}</span>
          </div>
        </div>
        <div>
          <v-tooltip bottom text="Мiсце роботи">
            <template #activator="{ props }">
              <div
                class="text-center d-inline-flex align-center"
                v-bind="props">
                <v-menu
                  v-if="sourceType === 'Агент'"
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-width="300"
                  offset-x>
                  <template #activator="{ props }">
                    <v-icon v-bind="props" size="17" class="pb-3"> </v-icon>
                    <div v-bind="props">
                      <InformIcon />
                    </div>
                  </template>
                  <div class="popup">
                    <div class="popup-wrapper">
                      <div class="menu-header mb-2">
                        <span class="menu-title">Місце роботи:</span>
                      </div>
                      <div>
                        <div>
                          <div class="data-card-work">
                            <span class="area-label">Мiсце</span>
                            <p class="title-label">
                              {{ agentData.placeOfWork || 'Безробітний' }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <div class="data-card-work">
                            <span class="area-label d-flex align-center">
                              <PhoneIcon class="mr-1" />
                              Телефон
                            </span>
                            <p class="title-label">
                              {{ agentData.phones || 'Відсутній' }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <div class="data-card-work">
                            <span class="area-label d-flex align-center">
                              <EmailIcon class="mr-1" />
                              Email
                            </span>
                            <p class="title-label">
                              {{ agentData.emails || 'Відсутній' }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-menu>
              </div>
            </template>
            <span>Мiсце роботи</span>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>

    <OpenableSection
      title="Документи по запиту"
      justifySpaceBetween
      class="mb-5 mt-2"
      style="margin-left: -5px !important">
      <FilesUploader
        v-model:documents="project.applicationForm"
        label="Заявка анкета"
        :disabled="!!project.readOnly"
        acceptedExt="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        required
        single
        :maxSize="50000000"
        list />
      <FilesUploader
        v-model:documents="project.questionnaire"
        :label="benefit ? 'Опитувальник Фінансовий моніторинг' : 'Опитувальник'"
        :disabled="!!project.readOnly"
        required
        single
        acceptedExt="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        :maxSize="50000000"
        list />
      <FilesUploader
        v-if="benefit"
        label="Опитувальник BEST BENEFITS"
        :documents="
          project.benefits_questionnaire
            ? project.benefits_questionnaire.questionnaire
            : null
        "
        :disabled="!!project.readOnly"
        disable-delete-action
        required
        single
        acceptedExt="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        :maxSize="50000000"
        list
        disable-default-listener
        @select-file="benefitsQuestionnaireDialog('create')"
        @update-file="benefitsQuestionnaireDialog('update')" />
      <FilesUploader
        v-if="benefit"
        v-model:documents="project.environmentalQuestionnaire"
        label="Анкета екологічної обачності"
        :disabled="!!project.readOnly"
        required
        single
        :maxSize="50000000" />
      <FilesUploader
        v-if="contractor"
        v-model:documents="project.ownershipStructure"
        label="Структура власності у вигляді окремої схеми"
        :disabled="!!project.readOnly"
        required
        :maxSize="50000000"
        list />
      <FilesUploader
        v-model:documents="project.otherDocuments"
        label="Iншi документи"
        :disabled="!!project.readOnly"
        :maxSize="50000000"
        list />
      <template #append-title>
        <div v-if="benefit">
          <v-tooltip text="Шаблон документів">
            <template #activator="{ props }">
              <div v-bind="props" class="d-flex align-center">
                <span class="area-label">Шаблон документів</span>
                <v-btn size="small" icon @click="questionnaireTemplateDialog">
                  <Printer />
                </v-btn>
              </div>
            </template>
          </v-tooltip>
        </div>
      </template>
    </OpenableSection>

    <v-row class="mt-3">
      <v-col cols="12" md="12" sm="12">
        <div class="projectCard">
          <PlanTimeIcon style="margin-right: 5px" />
          <span class="projectTitle">Планові терміни</span>
        </div>
      </v-col>
      <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
        <span class="label">Дата підписання договору</span>
        <date-picker
          v-model="project.dateSigningContract"
          :minDate="minDate"
          single
          placeholder="Оберіть дату"
          :disabled="!!project.readOnly"></date-picker>
      </v-col>
      <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
        <span class="label">Дата сплати авансу</span>
        <date-picker
          v-model="project.advanceDate"
          :minDate="minDate"
          single
          placeholder="Оберіть дату"
          :disabled="!!project.readOnly"></date-picker>
      </v-col>
      <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
        <span class="label">Дата відвантаження ОЛ</span>
        <date-picker
          v-model="project.dateOfLeasing"
          :minDate="minDate"
          single
          placeholder="Оберіть дату"
          :disabled="!!project.readOnly"></date-picker>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12" md="3" sm="12" class="pt-0 pb-0">
        <v-switch
          v-model="project.isAttorneyAbroad"
          color="red"
          :error-messages="isAttorneyAbroadErr"
          hide-details
          size="small"
          :disabled="!!project.readOnly">
          <template #label>
            <span>Додати довіреність закордон</span>
          </template>
        </v-switch>
      </v-col>
    </v-row>
    <v-row class="mb-3 mt-5">
      <v-col cols="12" md="12" sm="12">
        <div class="projectCard">
          <v-checkbox
            v-model="project.isDeviationsProjectMessage"
            style="margin-top: 0 !important; padding: 0 !important"
            class="sm-label"
            color="red"
            hide-details
            dense
            :disabled="!!project.readOnly"
            @update:model-value="
              () => (project.deviationsProjectMessage = null)
            ">
          </v-checkbox>
          <span>Відхилення по проекту:</span>
        </div>
      </v-col>
      <v-col
        v-if="project.isDeviationsProjectMessage"
        cols="12"
        md="12"
        sm="12">
        <span class="label">Опишіть відхилення</span>
        <v-textarea
          v-model="project.deviationsProjectMessage"
          class="comment"
          :error-messages="deviationsProjectMessageErr"
          placeholder="Введіть текст"
          :rows="3"
          solo
          hide-details
          dense
          :readonly="!!project.readOnly"
          @blur="
            project.isDeviationsProjectMessage &&
              v.project.deviationsProjectMessage.$touch()
          ">
        </v-textarea>
      </v-col>
    </v-row>
    <v-row class="mb-3">
      <v-col cols="12" md="12" sm="12">
        <div class="projectCard">
          <CommentIcon style="margin-right: 5px" />
          <span class="projectTitle">Коментар:</span>
        </div>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <v-textarea
          v-model="project.comment"
          class="comment"
          :error-messages="commentErr"
          placeholder="Введіть текст"
          :rows="3"
          hide-details
          solo
          dense
          :readonly="!!project.readOnly">
        </v-textarea>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import InformIcon from '@/assets/svg/information-icon.vue'
import ALink from '@/components/Link.vue'
import DatePicker from '@/components/DatePicker.vue'
import GeneralInfoIcon from '@/assets/svg/general-info.vue'
import CommentIcon from '@/assets/svg/comment-icon.vue'
import PlanTimeIcon from '@/assets/svg/planTime-icon.vue'
import EmailIcon from '@/assets/svg/email-icon.vue'
import PhoneIcon from '@/assets/svg/phone-icon.vue'
import OpenableSection from '@/components/OpenableSection.vue'
import FilesUploader from '@/components/FilesUploader.vue'
import { mapState } from 'vuex'
import {
  setErrHandler,
  contactFilter,
  contractorFilter,
  openDocument,
} from '@/utils/helperFunc'
import { maxNum } from '@/utils/formatFunc'
import { calculation } from '@/type-ids'
import Printer from '@/assets/svg/Printer-icon.vue'
import TableCrud from '@/components/table-crud.vue'
import { urlGetSliderApplicationForm } from '@/pages/request'
export default {
  components: {
    TableCrud,
    Printer,
    InformIcon,
    EmailIcon,
    PhoneIcon,
    PlanTimeIcon,
    CommentIcon,
    GeneralInfoIcon,
    OpenableSection,
    FilesUploader,
    DatePicker,
    ALink,
  },
  props: {
    projectData: { type: Object },
    leasingProgramId: { type: Number },
    benefit: { type: Boolean },
    v: { type: Object },
    isPerson: { type: Boolean },
  },
  data: () => ({
    fav: true,
    menu: false,
  }),
  computed: {
    calculation() {
      return calculation
    },
    ...mapState({ selectItems: state => state.selectItems }),
    agentData() {
      const agent = this.project.application?.agent?.contact
      const career = agent?.careers && agent?.careers[0]
      return {
        id: agent?.id,
        fullName: agent?.fullName,
        placeOfWork: agent?.contractor?.shortName || null,
        post: career?.post?.name || null,
        phones: agent?.phones?.map(p => p.phone).join(', ') || null,
        emails: agent?.emails?.map(p => p.email).join(', ') || null,
      }
    },
    printedForms() {
      return [
        {
          action: () =>
            this.downloadPf('Заявка анкета', urlGetSliderApplicationForm),
          text: 'Заявка анкета',
          icon: 'mdi-file',
        },
      ]
    },
    minDate() {
      const date = new Date()
      return new Date(date.setDate(date.getDate())).toISOString().substr(0, 10)
    },
    sourceType() {
      if (!this.project?.application?.source_type) return
      return this.project?.application?.source_type?.name
    },
    project() {
      return this.projectData
    },
    suplierContact() {
      if (!this.suplierEmployees.length) return
      if (!this.project.employeeId) return

      const contact = this.suplierEmployees.find(
        v => v.contact?.id === this.project.employeeId
      )

      if (!contact) return
      return contact.contact
    },
    deviationsProjectMessageErr() {
      return setErrHandler(this.v.project.deviationsProjectMessage)
    },
    isAttorneyAbroadErr() {
      return setErrHandler(this.v.project.isAttorneyAbroad)
    },
    productStatusTextErr() {
      return setErrHandler(this.v.project.productStatusText)
    },
    prepayToSupplierErr() {
      return setErrHandler(this.v.project.prepayToSupplier)
    },
    placeOfShipmentErr() {
      return setErrHandler(this.v.project.placeOfShipment)
    },
    leasingObjectCountryId() {
      return setErrHandler(this.v.project.leasingObjectCountryId)
    },
    commentErr() {
      return setErrHandler(this.v.project.comment)
    },
    contractor() {
      return this.project?.application.lesseeTypeId === 2
    },
  },
  methods: {
    contactFilter,
    contractorFilter,
    maxNum,
    questionnaireTemplateDialog() {
      this.$store.commit('setDialog', {
        title: 'Оберіть шаблон',
        action: 'questionnaireTemplateDialog',
        dialogItem: {
          projectId: this.projectData.id,
        },
        params: {
          dialogWidth: 700,
          hideActions: true,
        },
      })
    },
    downloadPf(title, url) {
      return this.$axios.get(url(this.project.id)).then(res => {
        return openDocument({
          text: title,
          url: res.data.url,
        })
      })
    },
    benefitsQuestionnaireDialog(action) {
      this.$store.commit('setDialog', {
        title: 'Опитувальник BEST BENEFITS',
        action: `${action}BenefitsQuestionnaire`,
        dialogItem: {
          lesseeTypeId: this.project.application.lesseeTypeId,
          sliderId: this.project.id,
          questionnaire: this.project.benefits_questionnaire,
        },
        params: {
          dialogWidth: 700,
          cb: questionnaire => {
            if (questionnaire && questionnaire.data) {
              this.project.benefits_questionnaire = questionnaire.data
            }
          },
        },
      })
    },
  },
}
</script>

<style scoped>
.title-label {
  font-size: 15px;
  color: #1c1d21;
  margin-top: 5px;
  font-weight: 400 !important;
}
.popup {
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  z-index: 1000;
  width: 500px;
}

.popup-wrapper {
  padding: 15px 25px;
}

.projectCard {
  display: flex;
  align-items: center;
}

.projectBlock {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
}
.projectTitle {
  display: block;
  color: #08487a;
  font-weight: 600;
  font-size: 16px;
}
.text-field {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.text-title {
  color: #1c1d21;
  font-weight: 600;
  font-size: 15px;
  margin-right: 6px;
  display: block;
}
.text-desc {
  font-weight: 400;
  font-size: 16px;
  color: #1c1d21;
  display: block;
}

.v-input--selection-controls {
  margin-top: 0 !important;
}
.data-card {
  display: flex;
  justify-content: unset;
  text-align: left;
  width: 45%;
  flex-direction: column;
}

.data-card-work {
  display: flex;
  justify-content: unset;
  text-align: left;
  flex-direction: column;
}

.menu-title {
  color: #08487a;
  font-size: 17px;
  font-weight: 700;
}
</style>
