<template>
  <div>
    <v-fade-transition hide-on-leave>
      <div>
        <Filters :filters="filters" class="ml-2"> </Filters>
        <v-row>
          <v-col v-show="!pageLoad" :cols="12" class="pr-0">
            <v-data-table
              :headers="tHead"
              :items="tBody"
              class="calc-table"
              mobileBreakpoint="1200"
              :hide-default-header="!tBody.length"
              hide-default-footer
              :items-per-page="50">
              <!-- eslint-disable-next-line -->
              <template #item.responsibleFrontFullName="{ item }">
                {{
                  getContactInitials(item.responsibleFrontFullName, {
                    byName: true,
                  })
                }}
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.clientName="{ item }">
                <ALink
                  :text="item.clientName"
                  :route="{
                    name: [1, 3].includes(item.lesseeTypeId)
                      ? 'individuals-form'
                      : 'contractors-form',
                    params: { id: item.clientId },
                  }">
                </ALink>
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.instalmentAmount="{ item }">
                <span
                  >{{
                    getBeautyNum(item.instalmentAmount, { float: 2 })
                  }}
                  грн</span
                >
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.contract="{ item }">
                <ALink
                  v-if="item.contractId"
                  style="font-size: 14px !important"
                  :text="item.contract"
                  :route="{
                    name: 'dfl-contract',
                    params: { id: item.contractId },
                  }">
                </ALink>
                <div v-if="!item.contractId">
                  <span
                    class="link"
                    style="
                      font-size: 14px !important;
                      display: flex;
                      align-items: center;
                    "
                    @click="getOneCInfo(item.contractOneCId)">
                    {{ `${item.contract}` }}
                    <span
                      style="
                        color: #09487a;
                        font-weight: 700;
                        margin-bottom: -4px;
                      "
                      ><OneCIcon
                        class="ml-1"
                        fill="#09487a"
                        width="19px"
                        height="19px" /></span
                  ></span>
                  <v-dialog
                    v-model="dialogInfo[item.contractOneCId]"
                    width="65%"
                    persistent>
                    <div>
                      <div
                        style="
                          background: white;
                          position: sticky;
                          right: 0;
                          top: 0;
                          height: 40px;
                          display: flex;
                          justify-content: end;
                        ">
                        <v-btn
                          style="margin-right: 10px; margin-top: 10px"
                          size="x-small"
                          icon
                          dense
                          @click="dialogInfo[item.contractOneCId] = false">
                          <CloseIcon />
                        </v-btn>
                      </div>
                      <div style="background: #fff">
                        <div class="pa-3" style="background: #fff">
                          <ModalInfo :info="oneCInfo" />
                        </div>
                      </div>
                    </div>
                  </v-dialog>
                </div>
              </template>
              <!-- eslint-disable-next-line -->
            </v-data-table>
            <Pagination
              :getItems="getScheduledList"
              :trigger="paginationTrigger" />
          </v-col>
        </v-row>
      </div>
    </v-fade-transition>
    <SectionLoader v-if="pageLoad && routeScheduledList"></SectionLoader>
    <RouterView :key="$route.params.id"></RouterView>
  </div>
</template>

<script>
import {
  urlGetLegalCaseScheduledList,
  urlGetOneCDflInfo,
} from '@/pages/request'
import ALink from '@/components/Link.vue'
import SectionLoader from '@/components/section-loader.vue'
import Filters from '@/components/Filters.vue'
import Pagination from '@/components/Pagination.vue'
import CloseIcon from '@/assets/svg/close-icon.vue'
import OneCIcon from '@/assets/svg/oneCIcon.vue'
import { getBeautyNum, getContactInitials } from '@/utils/helperFunc'
import ModalInfo from '@/components/OneC/Modal-info.vue'
import { useSelect } from '@/utils/mixins/useSelect'
import { useFilter } from '@/utils/mixins/useFilter'
import { reactive } from 'vue'
export default {
  name: 'ScheduledList',
  components: {
    ModalInfo,
    OneCIcon,
    CloseIcon,
    Pagination,
    Filters,
    SectionLoader,
    ALink,
  },
  setup() {
    const filters = reactive({
      search: { value: null },
      responsibleFrontId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Відповідальний фронт',
          itemTitle: u => getContactInitials(u, { isResponsible: true }),
          items: 'frontManagers',
        },
      },
      responsibleJuristId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Відповідальний юрист',
          itemTitle: u => getContactInitials(u, { isResponsible: true }),
          items: 'legalUsers',
        },
      },
    })
    return {
      ...useSelect(),
      ...useFilter(filters),
      filters,
    }
  },
  data: () => ({
    tBody: [],
    pageLoad: false,
    oneCInfo: null,
    dialogInfo: {},
    tHead: [
      {
        title: 'Клієнт',
        key: 'clientName',
        align: 'start',
        sortable: false,
        width: '500px',
      },
      {
        title: '№ ДФЛ',
        key: 'contract',
        align: 'start',
        sortable: false,
      },
      {
        title: 'Відповідальний фронт',
        key: 'responsibleFrontFullName',
        align: 'start',
        sortable: false,
      },
      {
        title: 'Відповідальний юрист',
        key: 'responsibleJuristFullName',
        align: 'start',
        sortable: false,
      },
      {
        title: 'Сума',
        key: 'instalmentAmount',
        align: 'start',
        sortable: false,
      },
      { title: 'Днiв', key: 'delayTerm', align: 'start', sortable: false },
    ],
  }),
  computed: {
    routeScheduledList() {
      return this.$route.name === 'scheduledList'
    },
    oneCInfoText() {
      return this.formatObject(this.oneCInfo)
    },
  },
  methods: {
    getBeautyNum,
    getContactInitials,
    formatObject(obj, indent = 0) {
      if (!obj) {
        return ''
      }

      const indentString = '  '.repeat(indent)
      const lines = []

      for (const [key, value] of Object.entries(obj)) {
        if (typeof value === 'object' && value !== null) {
          const nestedObj = this.formatObject(value, indent + 1)
          lines.push(`${indentString}${key}:`)
          lines.push(nestedObj)
        } else {
          lines.push(`${indentString}${key}: ${value}`)
        }
      }

      return lines.join('\n')
    },
    getOneCInfo(id) {
      const req = {
        oneCId: id,
      }

      return this.$axios.post(urlGetOneCDflInfo(), req).then(res => {
        this.oneCInfo = res.data

        this.dialogInfo = {
          ...this.dialogInfo,
          [id]: { id: id, isOpen: true },
        }
      })
    },
    getScheduledList(page = 1) {
      this.pageLoad = true
      return this.$axios
        .get(urlGetLegalCaseScheduledList(page, this.filtersQueryObj))
        .then(res => {
          this.tBody = res.data.data
          this.pageLoad = false

          return res
        })
        .catch(err => this.$err(err, () => (this.pageLoad = false)))
    },
  },
}
</script>

<style scoped></style>
