<template>
  <div>
    <div class="d-flex align-center">
      <v-btn class="mr-2" icon @click="downloadBenefitsQuestionnaireTemplate">
        <PrinterIcon style="cursor: pointer" />
      </v-btn>
      <span>Шаблон опитувальника BEST BENEFITS</span>
    </div>
    <div class="d-flex align-center">
      <v-btn
        class="mr-2"
        icon
        @click="downloadBenefitsEnvironmentalQuestionnaire">
        <PrinterIcon style="cursor: pointer" />
      </v-btn>
      <span>Анкета екологічної обачності BEST BENEFITS</span>
    </div>
  </div>
</template>

<script>
import PrinterIcon from '@/assets/svg/Printer-icon.vue'
import {
  urlBenefitsQuestionnaireTemplate,
  urlBenefitsEnvironmentalQuestionnaire,
} from '@/pages/request'
import { download } from '@/utils/helperFunc'

export default {
  components: {
    PrinterIcon,
  },
  props: {
    dialog: { type: Object },
  },
  methods: {
    downloadBenefitsQuestionnaireTemplate() {
      return this.$axios
        .get(urlBenefitsQuestionnaireTemplate(this.dialog.dialogItem.projectId))
        .then(res => {
          if (res.data && res.data.status === 400) {
            this.$setSnackbar({ text: res.data.message, color: 'warning' })
            return Promise.reject(res)
          }
          download(res.data.url, res.data.template)
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
            this.$setSnackbar({
              text: error.response.data.message,
              color: 'warning',
            })
          } else {
            console.error('Error:', error)
          }
        })
    },

    downloadBenefitsEnvironmentalQuestionnaire() {
      return this.$axios
        .get(urlBenefitsEnvironmentalQuestionnaire())
        .then(res => {
          if (res.data && res.data.status === 400) {
            this.$setSnackbar({ text: res.data.message, color: 'warning' })
            return Promise.reject(res)
          }
          download(res.data.url, res.data.template)
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
            this.$setSnackbar({
              text: error.response.data.message,
              color: 'warning',
            })
          } else {
            console.error('Error:', error)
          }
        })
    },
  },
}
</script>

<style scoped></style>
