<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    height="20"
    viewBox="0 0 18 19"
    fill="none">
    <rect y="0.5" width="18" height="18" rx="9" fill="#EA4646" />
    <path
      d="M9 14.75C8.2825 14.75 7.60437 14.6121 6.96562 14.3363C6.32687 14.0608 5.76915 13.6846 5.29245 13.2076C4.8154 12.7309 4.43915 12.1731 4.1637 11.5344C3.8879 10.8956 3.75 10.2175 3.75 9.5C3.75 8.77375 3.8879 8.09352 4.1637 7.45932C4.43915 6.82477 4.8154 6.26915 5.29245 5.79245C5.76915 5.3154 6.32687 4.93897 6.96562 4.66317C7.60437 4.38772 8.2825 4.25 9 4.25C9.72625 4.25 10.4065 4.38772 11.0407 4.66317C11.6752 4.93897 12.2309 5.3154 12.7076 5.79245C13.1846 6.26915 13.5608 6.82477 13.8363 7.45932C14.1121 8.09352 14.25 8.77375 14.25 9.5V10.2612C14.25 10.7775 14.0729 11.2171 13.7187 11.58C13.3642 11.9433 12.9288 12.125 12.4125 12.125C12.0975 12.125 11.8044 12.055 11.5331 11.915C11.2619 11.775 11.0387 11.5912 10.8637 11.3637C10.6275 11.6 10.3498 11.786 10.0306 11.9218C9.71102 12.0573 9.3675 12.125 9 12.125C8.27375 12.125 7.65477 11.869 7.14307 11.3569C6.63103 10.8452 6.375 10.2262 6.375 9.5C6.375 8.77375 6.63103 8.1546 7.14307 7.64255C7.65477 7.13085 8.27375 6.875 9 6.875C9.72625 6.875 10.3454 7.13085 10.8574 7.64255C11.3691 8.1546 11.625 8.77375 11.625 9.5V10.2612C11.625 10.515 11.7038 10.714 11.8612 10.8582C12.0188 11.0027 12.2025 11.075 12.4125 11.075C12.6225 11.075 12.8062 11.0027 12.9637 10.8582C13.1212 10.714 13.2 10.515 13.2 10.2612V9.5C13.2 8.35375 12.7865 7.3671 11.9594 6.54005C11.1327 5.71335 10.1462 5.3 9 5.3C7.85375 5.3 6.8671 5.71335 6.04005 6.54005C5.21335 7.3671 4.8 8.35375 4.8 9.5C4.8 10.6462 5.21335 11.6327 6.04005 12.4594C6.8671 13.2865 7.85375 13.7 9 13.7H11.625V14.75H9ZM9 11.075C9.4375 11.075 9.80937 10.9219 10.1156 10.6156C10.4219 10.3094 10.575 9.9375 10.575 9.5C10.575 9.0625 10.4219 8.69062 10.1156 8.38437C9.80937 8.07812 9.4375 7.925 9 7.925C8.5625 7.925 8.19062 8.07812 7.88437 8.38437C7.57812 8.69062 7.425 9.0625 7.425 9.5C7.425 9.9375 7.57812 10.3094 7.88437 10.6156C8.19062 10.9219 8.5625 11.075 9 11.075Z"
      fill="white" />
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: Number, default: 18 },
  },
}
</script>
