<template>
  <div>
    <v-fade-transition hide-on-leave>
      <SectionLoader v-if="initLoading && !hasProject"></SectionLoader>
    </v-fade-transition>
    <v-fade-transition hide-on-leave>
      <v-card-text
        v-if="!initLoading && hasProject"
        style="padding: 10px !important; padding-top: 0 !important">
        <MainCard @data-changed="getProject"> </MainCard>
        <v-row class="main-row">
          <SideBar class="side-bar" style="max-width: none">
            <div class="pl-1">
              <v-tabs
                v-model="currentTab"
                direction="vertical"
                slider-color="#fc7247"
                style="background: rgb(239, 239, 239); min-width: 100%">
                <v-tab
                  value="project-leasing-object"
                  class="justify-start"
                  replace
                  :to="{
                    name: 'project-leasing-object',
                    params: { projectId: $route.params.projectId },
                  }">
                  Об'єкти лізингу
                </v-tab>
                <v-tab
                  value=""
                  class="justify-start"
                  replace
                  :to="{
                    name: 'project-plan',
                    params: { projectId: $route.params.projectId },
                  }">
                  План реалізації
                </v-tab>
                <v-tab
                  value="project-plan"
                  class="justify-start"
                  replace
                  :to="{
                    name: 'project-guarantors',
                    params: { projectId: $route.params.projectId },
                  }">
                  Поручителі
                </v-tab>
                <v-tab
                  value="project-subtenants"
                  class="justify-start"
                  replace
                  :to="{
                    name: 'project-subtenants',
                    params: { projectId: $route.params.projectId },
                  }">
                  Суборендатори
                </v-tab>
                <v-tab
                  value="additional-support"
                  class="justify-start"
                  replace
                  :to="{
                    name: 'additional-support',
                    params: { projectId: $route.params.projectId },
                  }">
                  Додаткове забезпечення
                </v-tab>
                <v-tab
                  value="project-documents"
                  class="justify-start"
                  replace
                  :to="{
                    name: 'project-documents',
                    params: { projectId: $route.params.projectId },
                  }">
                  Документи
                </v-tab>
                <v-tab
                  value="project-analysis"
                  class="justify-start"
                  replace
                  :to="{
                    name: 'project-analysis',
                    params: { projectId: $route.params.projectId },
                  }">
                  Аналіз
                </v-tab>
                <v-tab
                  value="project-history"
                  class="justify-start"
                  replace
                  :to="{
                    name: 'project-history',
                    params: { projectId: $route.params.projectId },
                  }">
                  Історія
                </v-tab>
              </v-tabs>
            </div>
          </SideBar>
          <ColumnTabs class="column-tabs" :v-card="false">
            <v-card-text
              class="pt-0 pr-0"
              style="min-height: 260px; padding-left: 0">
              <keep-alive>
                <RouterView
                  :key="$route.fullPath"
                  :calculationSingle="calculations"
                  :readonly="readonly"
                  :statusId="project.status.id"
                  @data-changed="getProject"
                  @update-project="submit">
                </RouterView>
              </keep-alive>
            </v-card-text>
            <v-card-actions class="pt-0" style="margin-left: -5px !important">
              <ActionButtons
                style="padding-left: 0 !important"
                :confirm="submit"
                :confirmDisable="projectState === projectCache"
                :confirmLoading="loading"
                :cancel="getProject"
                :cancelDisable="projectState === projectCache || loading">
              </ActionButtons>
            </v-card-actions>
          </ColumnTabs>
        </v-row>
      </v-card-text>
    </v-fade-transition>
  </div>
</template>
<script>
import ColumnTabs from '@/components/ColumnTabs.vue'
import SideBar from '@/components/SideBar.vue'
import SectionLoader from '@/components/section-loader.vue'
import ActionButtons from '@/components/action-buttons.vue'
import MainCard from './MainCard.vue'
import { mapState, mapGetters } from 'vuex'
import {
  urlUpdateProject,
  urlProjectAddComment,
  urlProjectEditComment,
  urlProjectDeleteComment,
} from '@/pages/request'
import { setCache, v$Notify, toFormatDate, backDate } from '@/utils/helperFunc'
import { required, minLength } from '@vuelidate/validators'
import { leasingClient as LC } from '@/type-ids'
import { useVuelidate } from '@vuelidate/core'
export default {
  components: {
    SectionLoader,
    ActionButtons,
    MainCard,
    ColumnTabs,
    SideBar,
  },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    const deviationsProjectMessage = this.slider.isDeviationsProjectMessage
      ? { deviationsProjectMessage: { required } }
      : {}
    const overlappedDeviationsMessage = this.slider
      .isOverlappedDeviationsMessage
      ? { overlappedDeviationsMessage: { required } }
      : {}

    return {
      project: {
        projectNumber: { required },
        projectDate: { required, minLength: minLength(10) },
      },
      slider: {
        dateOfLeasing: { required, minLength: minLength(10) },
        dateSigningContract: { required, minLength: minLength(10) },
        advanceDate: { required, minLength: minLength(10) },
        ...deviationsProjectMessage,
        ...overlappedDeviationsMessage,
      },
    }
  },
  data() {
    return {
      currentTab: 'project-leasing-object',
      loading: false,
      initLoading: true,
      LC: LC, // leasing client
    }
  },
  computed: {
    ...mapState({
      project: state => state.projects.project,
      slider: state => state.projects.slider,
      calculations: state => state.projects.project.calculations,
      projectCache: state => state.projects.cache,
    }),
    ...mapGetters({
      hasProject: 'hasProject',
    }),
    readonly() {
      return ![1, 2].includes(this.project.statusId)
    },
    projectId() {
      return this.$route?.params?.projectId
    },
    projectState() {
      return setCache({ ...(this.project || {}), slider: this.slider }, [
        'slider.guarantors',
        'slider.subtenants',
      ])
    },
  },
  methods: {
    urlUpdateProject,
    toFormatDate,
    urlProjectAddComment,
    urlProjectEditComment,
    urlProjectDeleteComment,
    getProject() {
      this.$store.commit('clearProject')
      return this.$store
        .dispatch('getProject', this.projectId)
        .then(res => {
          this.v$?.$reset()
          return res
        })
        .then(this.setBreadScrumb)
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
    setBreadScrumb() {
      if (!this.project) return

      const name = `${this.project.lessee.shortName} - ${this.project.projectNumber}`

      this.$store.commit('setBreadScrumbPart', [null, name, null, null])
    },
    highlightErrors() {
      this.v$.$anyError
      this.v$.$touch()
      v$Notify({ ...this.v$.slider, ...this.v$.project }, 'project')
    },
    submit() {
      if (this.v$.$invalid) {
        this.highlightErrors()
        return
      }

      this.loading = true
      const project = Object.assign(
        {},
        this.project,
        { slider: JSON.parse(JSON.stringify(this.slider)) },
        { calculations: this.calculations }
      )

      project.slider.advanceDate = backDate(project.slider.advanceDate)
      project.slider.dateOfLeasing = backDate(project.slider.dateOfLeasing)
      project.slider.dateSigningContract = backDate(
        project.slider.dateSigningContract
      )
      project.projectDate = backDate(project.projectDate)

      return this.$axios
        .post(this.urlUpdateProject(this.projectId), project)
        .then(this.successHanlder)
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
    successHanlder(res) {
      this.$store.commit('setProject', res)
      this.$setSnackbar({ text: 'Проект оновлено' })
      this.loading = false
    },
  },
  watch: {
    '$route.name': function () {
      this.setBreadScrumb()
    },
    v$: {
      deep: true,
      handler(v) {
        this.$store.commit('projectValidity', v)
      },
    },
  },
  created() {
    Promise.all([this.getProject()]).finally(() => (this.initLoading = false))
  },
  beforeUnmount() {
    this.$store.commit('clearProject')
  },
}
</script>

<style>
.main-row {
  display: flex;
  flex-wrap: nowrap;
}
.side-bar {
  max-width: 268px;
}
.column-tabs {
  flex: 0 1 auto;
  max-width: calc(100% - 268px);
}
@media (max-width: 1331px) {
  .main-row {
    flex-direction: column;
  }
  .side-bar {
    max-width: 100%;
  }
  .column-tabs {
    max-width: 100%;
  }
}
.v-slide-group--vertical .v-tab__slider {
  width: 5px !important;
}
</style>
