<template>
  <div>
    <SectionLoader v-show="calculationsLoading" />
    <v-card-text v-show="!calculationsLoading">
      <v-row data-readonly="hide">
        <v-col :cols="12" :md="8" class="pb-1">
          <v-btn
            color="#fc7247"
            variant="flat"
            class="text-white no-transform no-border"
            :disabled="!!application.readOnly"
            @click="
              $router.push({
                name: 'application-create-calculation',
                params: { applicationId: $route.params.applicationId },
              })
            ">
            <PlusIcon class="mr-2" />
            ДОДАТИ РОЗРАХУНОК
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          :cols="12"
          class="pt-0"
          style="padding-left: 0; padding-right: 15px">
          <v-data-table
            :headers="applicationCalculationHeaders"
            :items="tBody"
            class="calc-table mt-4"
            mobileBreakpoint="1230"
            :search="search"
            :hide-default-header="!tBody.length"
            hide-default-footer
            :items-per-page="-1">
            <!-- eslint-disable-next-line -->
            <template #item.requestId="{ item }">
              <div :class="[item.isAgreed ? '' : '']">
                <v-tooltip
                  v-if="item.isAgreed || item.isSendOffer"
                  bottom
                  :text="
                    item.isAgreed ? 'Погоджена калькуляція' : 'Відправлено КП'
                  ">
                  <template #activator="{ props }">
                    <v-icon
                      style="position: absolute; left: 10px"
                      class="mr-1"
                      :size="20"
                      :color="
                        item.isAgreed
                          ? '#3cb371'
                          : item.isSendOffer
                          ? 'orange'
                          : ''
                      "
                      v-bind="props">
                      {{
                        item.isAgreed
                          ? 'mdi-check-circle'
                          : item.isSendOffer
                          ? 'mdi-email-check'
                          : ''
                      }}
                    </v-icon>
                  </template>
                  <span v-if="item.isAgreed">Погоджена калькуляція</span>
                  <span v-if="item.isSendOffer">Відправлено КП</span>
                </v-tooltip>
                <ALink
                  :text="item.requestId"
                  :route="{
                    name: 'application-edit-calculation',
                    params: {
                      calculationId: item.id,
                      applicationId: item.applicationId,
                    },
                  }">
                </ALink>
              </div>
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.created_at="{ item }">
              {{
                item.isAgreed
                  ? humanDate(formatDate(item.agreedDate, true))
                  : humanDate(formatDate(item.created_at, true))
              }}
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.advance="{ item }">
              <span v-if="tBody.length">
                {{
                  getAdvanceSum(
                    item.requestData.advance,
                    item.requestData.leasingAmount
                  )
                }}
              </span>
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.requestData.leasingAmount="{ item }">
              <span v-if="tBody.length">
                {{
                  formutSum(item.requestData.leasingAmount) +
                  ' ' +
                  getCurrencySign(item.requestData.leasingCurrency)
                }}
              </span>
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.actions="{ item }">
              <TableCrud
                data-readonly="hide"
                style="
                  padding-right: 0 !important;
                  margin-right: -15px !important;
                  padding-left: 15px !important;
                "
                :color="
                  item.isAgreed
                    ? 'green darken-2'
                    : item.isSendOffer
                    ? 'orange darken-1'
                    : ''
                "
                :actionsArray="[
                  {
                    action: () => showCalculation(item),
                    text: 'Переглянути',
                    icon: 'mdi-eye',
                  },
                  {
                    action: () =>
                      item.isAgreed
                        ? rejectCalculation(item)
                        : approveCalculation(item),
                    text: item.isAgreed ? 'Відмiнити погодження' : 'Погодити',
                    icon: item.isAgreed
                      ? 'mdi-close-circle-outline'
                      : 'mdi-checkbox-marked-circle-outline',
                    color: item.isAgreed ? 'red lighten-2' : '',
                    disabled: !!application.readOnly,
                  },
                  {
                    action: () =>
                      $router.push({
                        name: 'application-edit-calculation',
                        params: {
                          calculationId: item.id,
                          params: {
                            applicationId: $route.params.applicationId,
                          },
                        },
                      }),
                    text: 'Редагувати',
                    icon: 'mdi-pencil',
                    disabled: !!application.readOnly && !!item.readOnly,
                  },
                  {
                    action: () => openOfferDialog(item, 'downloadOffer'),
                    text: 'Зберегти',
                    icon: 'mdi-download',
                    disabled: !admin,
                  },
                  {
                    action: () => createCalculationCopy(item),
                    text: 'Створити копію',
                    icon: 'mdi-content-save',
                    disabled: !!application.readOnly,
                  },
                  {
                    action: () => openOfferDialog(item, 'sendOffer'),
                    text: item.isSendOffer ? 'Відправлено' : 'Відправити',
                    icon: 'mdi-email-fast',
                    iconColor: item.isSendOffer ? 'orange darken-1' : '',
                    disabled: !!application.readOnly,
                  },
                  {
                    action: () => opendDeleteDialog(item),
                    text: 'Видалити',
                    icon: 'mdi-delete',
                    statement: !item.isAgreed && !item.isSendOffer,
                    disabled: !!application.readOnly,
                  },
                ]">
              </TableCrud>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import PlusIcon from '@/assets/svg/whitePlus-icon.vue'
import TableCrud from '@/components/table-crud.vue'
import ALink from '@/components/Link.vue'
import SectionLoader from '@/components/section-loader.vue'
import { statusColor, getCurrencySign } from '@/utils/helperFunc'
import { formutSum, formatDate, humanDate } from '@/utils/formatFunc.js'
import { urlCopyCalculation } from '@/pages/request'
import { applicationCalculationHeaders } from '@/utils/tableHeaders'
export default {
  components: { TableCrud, ALink, SectionLoader, PlusIcon },
  emits: [
    'update:objectToSend',
    'update:sendOfferDialog',
    'update:dialogAction',
    'update:deleteDialog',
    'update:dialogItem',
  ],
  props: {
    tBody: { type: Array, default: () => [] },
    objectToSend: { type: Object },
    sendOfferDialog: { type: Boolean },
    dialogAction: { type: String },
    deleteDialog: { type: Boolean },
    dialogItem: { type: Object },
    cb: { type: Function },
    application: { type: Object },
    calculationsLoading: { type: Boolean, default: false },
    updateCalculations: { type: Function },
  },
  data() {
    return {
      applicationCalculationHeaders,
      search: null,
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    admin() {
      return this.user.roleId === 1
    },
  },
  methods: {
    statusColor,
    formutSum,
    formatDate,
    humanDate,
    getCurrencySign,
    urlCopyCalculation,
    createCalculationCopy(item) {
      if (!item.isAgreed) {
        this.$axios.get(urlCopyCalculation(item.id)).then(res => {
          this.updateCalculations()
          this.$setSnackbar({ text: 'Калькуляцiя успішно скопійована' })
          return res
        })
      }
    },
    getAdvanceSum(advPer, amount) {
      if (!advPer || !amount) return 0
      const am = parseInt(amount)
      const adv = parseInt(advPer)
      return this.formutSum((am / 100) * adv)
    },
    showCalculation(item) {
      this.$router.push({
        name: 'application-payment-plan',
        params: {
          calculationId: item.id,
          applicationId: this.$route.params.applicationId,
        },
      })
    },
    openOfferDialog(item, action) {
      if (action === 'sendOffer') {
        this.$store.commit('setDialog', {
          title: 'Відправити КП',
          action: 'sendOffer',
          dialogItem: {
            calculation: item,
            application: this.application.$object,
          },
          params: {
            hideToolbar: true,
            hideActions: true,
            dialogWidth: 850,
          },
        })
      } else {
        this.$emit('update:objectToSend', item)
        this.$emit('update:sendOfferDialog', true)
        this.$emit('update:dialogAction', action)
      }
    },
    opendDeleteDialog(item) {
      this.$emit('update:deleteDialog', !this.deleteDialog)
      this.$emit('update:dialogItem', item)
    },
    approveCalculation(calculation) {
      this.$store.commit('setDialog', {
        title: 'Погодити розрахунок',
        dialogItem: calculation,
        action: 'approveCalculation',
        params: {
          cb: () => this.cb(),
          hideActions: true,
        },
      })
    },
    rejectCalculation(calculation) {
      this.$store.commit('setDialog', {
        title: 'Відмiнити погодження',
        dialogItem: calculation,
        action: 'rejectCalculation',
        params: { cb: () => this.cb() },
      })
    },
  },
}
</script>

<style scoped>
td.text-end {
  padding-right: 0 !important;
  margin-right: -15px !important;
}

.v-sheet.v-list:not(.v-sheet--outlined) {
  padding-left: 15px !important;
}

.v-list-group.v-list-group--no-action {
  padding-left: 15px !important;
}

.v-list-item.theme--light {
  padding-left: 15px !important;
}
.v-list-group__header.v-list-item.v-list-item--link.theme--light {
  padding-left: 15px !important;
}
</style>
