<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    style="margin-top: 3px"
    viewBox="0 0 17 16"
    fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.4167 8.00004C15.4167 11.6819 12.4319 14.6667 8.75001 14.6667C5.06811 14.6667 2.08334 11.6819 2.08334 8.00004C2.08334 4.31814 5.06811 1.33337 8.75001 1.33337C12.4319 1.33337 15.4167 4.31814 15.4167 8.00004ZM11.4369 5.97982C11.6322 6.17508 11.6322 6.49167 11.4369 6.68693L8.10356 10.0203C7.9083 10.2155 7.59172 10.2155 7.39646 10.0203L6.06312 8.68693C5.86786 8.49167 5.86786 8.17508 6.06312 7.97982C6.25839 7.78456 6.57497 7.78456 6.77023 7.97982L7.75001 8.9596L9.2399 7.46971L10.7298 5.97982C10.9251 5.78456 11.2416 5.78456 11.4369 5.97982Z"
      fill="#4AC000" />
  </svg>
</template>

<style scoped></style>
