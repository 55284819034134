<template>
  <div style="position: relative">
    <SectionLoader v-if="loading"></SectionLoader>
    <v-fade-transition hide-on-leave>
      <div v-if="!loading" class="content-wrapper verification-card">
        <div>
          <div>
            <div class="white-block" style="padding-bottom: 0">
              <v-tabs
                v-model="currentTab"
                show-arrows
                :direction="$vuetify.breakpoint?.xs ? 'vertical' : 'horizontal'"
                :height="$vuetify.breakpoint?.xs ? null : 38"
                style="width: 90%"
                background-color="grey darken-2"
                color="white"
                dark
                slider-color="red"
                dense>
                <v-tab
                  repalce
                  :to="{
                    name: 'ld-client-verification',
                    params: { id: $route.params.id },
                  }">
                  Клієнт
                </v-tab>
                <v-tab
                  v-for="(provider, idx) in providers"
                  :key="provider.providerId"
                  repalce
                  :to="{
                    name: 'ld-provider-verification',
                    params: { providerIdx: idx + 1, id: $route.params.id },
                  }">
                  <span
                    style="
                      overflow: hidden;
                      display: block;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    ">
                    Постачальник {{ getEntityName(provider) }}
                  </span>
                </v-tab>
                <v-tab
                  v-for="(item, index) in guarantors"
                  :key="`guarantor_${item.id}`"
                  repalce
                  :to="{
                    name: 'ld-guarantor-verification',
                    params: { idx: index + 1, id: $route.params.id },
                  }">
                  <span
                    style="
                      overflow: hidden;
                      display: block;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    ">
                    Поручитель {{ getGuarantorName(guarantors[index]) }}
                  </span>
                </v-tab>
                <v-tab
                  v-for="(item, index) in subtenants"
                  :key="`subtenant_${item.id}`"
                  repalce
                  :to="{
                    name: 'ld-subtenant-verification',
                    params: { idx: index + 1, id: $route.params.id },
                  }">
                  <span
                    style="
                      overflow: hidden;
                      display: block;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    ">
                    Суборендатор {{ getSubtenantName(subtenants[index]) }}
                  </span>
                </v-tab>
                <v-tab
                  repalce
                  :to="{
                    name: 'ld-analyze',
                    params: { id: $route.params.id },
                  }">
                  Аналiз
                </v-tab>
              </v-tabs>
              <div
                style="position: absolute; top: 15px; right: 0"
                class="d-flex align-center">
                <span
                  v-if="showBenefit"
                  style="
                    display: inline-block;
                    margin-right: 10px;
                    margin-bottom: -5px;
                  ">
                  <Benefit width="26px" height="26px" v-bind="on" />
                </span>
                <span
                  v-if="showProgramFSN"
                  style="
                    display: inline-block;
                    margin-right: 10px;
                    margin-bottom: -5px;
                  ">
                  <ProgramFSN width="26px" height="26px" v-on="on" />
                </span>
                <span style="display: inline-block; margin-right: 8px">
                  <TableCrud
                    color="white"
                    customIcon="Printer"
                    :actionsArray="[
                      {
                        action: () =>
                          downloadPrintedForm(
                            urlCreateLegalDepartmentPrintedForm(LDAnalyze.id),
                            url => url.split('/').at(-1)
                          ),
                        text: 'Сформувати Юр висновок',
                        icon: 'Printer',
                        customIcon: true,
                        disabled: LDAnalyze.statusId !== 4,
                      },
                    ]">
                  </TableCrud>
                </span>
                <span style="display: inline-block; margin-right: 15px">
                  <TableCrud
                    data-readonly="hide"
                    color="white"
                    :actionsArray="[
                      {
                        action: takeToWork,
                        text: 'Взяти в роботу',
                        icon: 'mdi-timer-outline',
                        disabled: [2, 3, 4].includes(LDAnalyze.statusId),
                      },
                      {
                        action: revision,
                        text: 'Відправити на доопрацювання',
                        icon: 'mdi-timer-off-outline',
                        disabled: [1, 3, 4].includes(LDAnalyze.statusId),
                      },
                    ]">
                  </TableCrud>
                </span>
              </div>
            </div>
          </div>
          <div class="white-block" style="padding-bottom: 0">
            <v-tabs
              v-if="showLoTabs && calculations && calculations.length"
              slider-color="#ff000000"
              class="newForTab">
              <v-tab
                class="fortab"
                @click="
                  $router.replace({
                    name: 'ld-provider-verification',
                    params: { providerIdx: $route.params.providerIdx },
                  })
                "
                >Загальні дані</v-tab
              >
              <v-tab
                v-for="(calc, idx) in calculationsByProvider"
                :key="calc.id"
                class="fortab"
                @click="
                  $router.replace({
                    name: 'ld-leasing-object',
                    params: { calculationIdx: idx + 1 },
                  })
                ">
                <svg
                  class="mr-1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 19 18"
                  fill="none">
                  <path
                    d="M2.5 6.75L4 1.6875H15L16.5 6.75M15 12.375C14.6022 12.375 14.2206 12.1972 13.9393 11.8807C13.658 11.5643 13.5 11.1351 13.5 10.6875C13.5 10.2399 13.658 9.81072 13.9393 9.49426C14.2206 9.17779 14.6022 9 15 9C15.3978 9 15.7794 9.17779 16.0607 9.49426C16.342 9.81072 16.5 10.2399 16.5 10.6875C16.5 11.1351 16.342 11.5643 16.0607 11.8807C15.7794 12.1972 15.3978 12.375 15 12.375ZM4 12.375C3.60218 12.375 3.22064 12.1972 2.93934 11.8807C2.65804 11.5643 2.5 11.1351 2.5 10.6875C2.5 10.2399 2.65804 9.81072 2.93934 9.49426C3.22064 9.17779 3.60218 9 4 9C4.39782 9 4.77936 9.17779 5.06066 9.49426C5.34196 9.81072 5.5 10.2399 5.5 10.6875C5.5 11.1351 5.34196 11.5643 5.06066 11.8807C4.77936 12.1972 4.39782 12.375 4 12.375ZM16.42 1.125C16.22 0.4725 15.66 0 15 0H4C3.34 0 2.78 0.4725 2.58 1.125L0.5 7.875V16.875C0.5 17.1734 0.605357 17.4595 0.792893 17.6705C0.98043 17.8815 1.23478 18 1.5 18H2.5C2.76522 18 3.01957 17.8815 3.20711 17.6705C3.39464 17.4595 3.5 17.1734 3.5 16.875V15.75H15.5V16.875C15.5 17.1734 15.6054 17.4595 15.7929 17.6705C15.9804 17.8815 16.2348 18 16.5 18H17.5C17.7652 18 18.0196 17.8815 18.2071 17.6705C18.3946 17.4595 18.5 17.1734 18.5 16.875V7.875L16.42 1.125Z"
                    fill="#838282" />
                </svg>
                {{ calc.leasingObjectName }}</v-tab
              >
            </v-tabs>
          </div>
        </div>
        <router-view
          v-model:conclusion="conclusion"
          style="margin-top: 10px"
          :LDAnalyze="LDAnalyze"
          :isInWork="isInWork"
          :project="LDAnalyze.project"
          :guarantors="guarantors"
          :subtenants="subtenants"
          :calculation="
            calculationsByProvider[$route.params.calculationIdx - 1 || 0]
          "
          :provider="providers[$route.params.providerIdx - 1]"
          :loading="loading"
          :readonly="readonly" />
        <div
          v-if="$route.name !== 'ld-analyze'"
          class="white-block"
          style="margin-top: 10px">
          <div>
            <v-row>
              <v-col :cols="12" :sm="3" class="pt-2 pb-2">
                <div>
                  <svg
                    class="mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none">
                    <path
                      d="M3.34961 9.1805C3.23568 9.1805 3.1263 9.15771 3.02148 9.11214C2.91667 9.06657 2.82096 9.00277 2.73438 8.92074L0.25293 6.43929C0.0843099 6.27067 0 6.06559 0 5.82406C0 5.70101 0.0227865 5.58708 0.0683594 5.48226C0.113932 5.37744 0.175456 5.28629 0.25293 5.20882C0.330404 5.13135 0.423828 5.06982 0.533203 5.02425C0.642578 4.97868 0.75651 4.95589 0.875 4.95589C0.99349 4.95589 1.10514 4.9764 1.20996 5.01742C1.31478 5.05843 1.41048 5.12223 1.49707 5.20882L3.97168 7.68343C4.05371 7.76546 4.11523 7.85889 4.15625 7.9637C4.19727 8.06852 4.22005 8.18245 4.22461 8.3055C4.22461 8.42399 4.20182 8.53564 4.15625 8.64046C4.11068 8.74528 4.04915 8.8387 3.97168 8.92074C3.89421 9.00277 3.80078 9.06657 3.69141 9.11214C3.58203 9.15771 3.4681 9.1805 3.34961 9.1805ZM8.29883 4.23128C8.1849 4.23128 8.07552 4.2085 7.9707 4.16292C7.86589 4.11735 7.77018 4.05355 7.68359 3.97152L5.20898 1.49691C5.03581 1.32373 4.94922 1.11637 4.94922 0.874837C4.94922 0.75179 4.97201 0.637858 5.01758 0.53304C5.06315 0.428223 5.12467 0.337077 5.20215 0.259603C5.27962 0.182129 5.37305 0.118327 5.48242 0.0681966C5.5918 0.0180664 5.70573 -0.00472005 5.82422 -0.00016276C6.06576 -0.00016276 6.27083 0.0841471 6.43945 0.252767L8.9209 2.73421C9.00293 2.81624 9.06445 2.90967 9.10547 3.01449C9.14648 3.1193 9.16927 3.23324 9.17383 3.35628C9.17383 3.47477 9.15104 3.58643 9.10547 3.69124C9.0599 3.79606 8.99837 3.88949 8.9209 3.97152C8.84342 4.05355 8.75 4.11735 8.64062 4.16292C8.53125 4.2085 8.41732 4.23128 8.29883 4.23128ZM4.74414 6.90413L2.26953 4.42952L4.42969 2.26937L6.9043 4.74398L4.74414 6.90413ZM13.248 12.6326C13.3346 12.7192 13.3779 12.8218 13.3779 12.9403C13.3779 13.0588 13.3346 13.1613 13.248 13.2479C13.1615 13.3345 13.0589 13.3778 12.9404 13.3778C12.8219 13.3778 12.7194 13.3345 12.6328 13.2479L6.13184 6.75374L6.75391 6.13167L13.248 12.6326ZM0 13.1248H7V13.9998H0V13.1248ZM0.875 11.3748H6.125V12.2498H0.875V11.3748Z"
                      fill="#08487A" />
                  </svg>
                  <span
                    style="color: #08487a; font-weight: 500; font-size: 15px"
                    >Загальний висновок</span
                  >
                </div>
                <div
                  class="mt-2"
                  style="color: #838282; font-weight: 400; font-size: 13px">
                  Загальний висновок щодо укладення<br />
                  договорiв купівлi-продажу(поставки),<br />
                  предмету лізингу, фiнансового лізингу,<br />
                  поруки, застави
                </div>
              </v-col>
              <v-col :cols="12" :sm="9" class="pt-2 pb-2">
                <span class="label">Загальний висновок</span>
                <v-textarea
                  v-model="conclusion.generalConclusion"
                  :rows="5"
                  class="pt-0"
                  placeholder="Введіть текст"
                  :readonly="readonly"
                  hide-details>
                </v-textarea>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-card-actions class="d-flex justify-start pb-5 pt-5">
          <v-btn
            class="text-white"
            color="#df5c55c9"
            variant="flat"
            :loading="submitLoading"
            :disabled="readonly"
            @click="submit">
            Завершити аналіз
          </v-btn>
        </v-card-actions>
      </div>
    </v-fade-transition>
  </div>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'

import { mapState } from 'vuex'
import {
  urlGetLegalDepartmentAnalyzeById,
  urlTakeToWorkLegalConclusion,
  urlUpdateLegalConclusion,
  urlSetLegalDepartmentStatus,
  urlCreateLegalDepartmentPrintedForm,
} from '@/pages/request'

import {
  setErrHandler,
  v$Notify,
  getProjectGuarantors,
  getProjectSubtenants,
  getContactInitials,
  LODescription as getLoDescription,
  getEntityName,
  downloadPrintedForm,
} from '@/utils/helperFunc'
import { required } from '@vuelidate/validators'
import Benefit from '@/assets/svg/benefit.vue'
import { useVuelidate } from '@vuelidate/core'
import TableCrud from '@/components/table-crud.vue'
import ProgramFSN from '@/assets/png/programFSN.vue'

export default {
  emits: ['updateProjects'],
  components: { ProgramFSN, TableCrud, Benefit, SectionLoader },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    const guarantorValidator = val => {
      const guarantorsLength = this.guarantors.filter(
        g => g.guarantorTypeId === 2
      ).length
      let result = true
      if (guarantorsLength === 0) return true
      if (val.length < guarantorsLength) return false
      for (const g of val) {
        if (g.guarantorTypeId !== 2) continue
        if (!g.guarantorConclusion) {
          result = false
          break
        }
      }
      return result
    }
    const subtenantValidator = val => {
      const subtenantsLength = this.subtenants.filter(
        g => g.subtenantTypeId === 2
      ).length
      let result = true
      if (subtenantsLength === 0) return true
      if (val.length < subtenantsLength) return false
      for (const s of val) {
        if (s.subtenantTypeId !== 2) continue
        if (!s.subtenantConclusion) {
          result = false
          break
        }
      }
      return result
    }
    const providerValidator = val => {
      const providersLength = this.providers.length
      let result = true
      if (providersLength === 0) return true
      if (val.length < providersLength) return false
      for (const p of val) {
        if (!p.providerConclusion) {
          result = false
          break
        }
      }
      return result
    }
    return {
      conclusion: {
        subtenantResults: { subtenantValidator },
        guarantorResults: { guarantorValidator },
        providersResults: { providerValidator },
        clientConclusion: { required },
        generalConclusion: { required },
      },
    }
  },
  data: () => ({
    currentTab: 0,
    currentCalc: 0,
    LDAnalyze: {},
    loading: false,
    submitLoading: false,
    conclusion: {
      guarantorResults: [],
      subtenantResults: [],
      providersResults: [],
      clientConclusion: null,
      generalConclusion: null,
    },
  }),
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    readonly() {
      return !!this.LDAnalyze.isConfirmation || !this.isInWork
    },
    id() {
      return this.$route.params.id
    },
    isInWork() {
      return this.LDAnalyze?.statusId === 2
    },
    guarantors() {
      return this.getProjectGuarantors(this.LDAnalyze?.guarantors)
    },
    subtenants() {
      return this.getProjectSubtenants(this.LDAnalyze?.subtenants)
    },
    calculations() {
      return Object.values(this.LDAnalyze).length
        ? this.LDAnalyze.calculations
        : []
    },
    providers() {
      if (!this.LDAnalyze.providers) return []
      return this.LDAnalyze.providers.map(p => {
        const providerTypeId = p.contact ? 1 : 2
        const providerEntity = providerTypeId === 2 ? p.contractor : p.contact
        const providerId = `${providerTypeId}${providerEntity.id}`

        return { ...providerEntity, providerTypeId, providerId }
      })
    },
    calculationsByProvider() {
      if (!this.LDAnalyze.calculations || !this.LDAnalyze.calculations.length)
        return []
      return this.showLoTabs
        ? this.calculations.filter(
            c =>
              `${c.providerTypeId}${c.providerId}` ===
              this.providers[this.$route.params.providerIdx - 1].providerId
          )
        : []
    },
    showLoTabs() {
      return ['ld-leasing-object', 'ld-provider-verification'].includes(
        this.$route.name
      )
    },
    showBenefit() {
      if (this.LDAnalyze && this.LDAnalyze.calculations) {
        return this.LDAnalyze.calculations.some(t => t.leasingProgram === 34)
      }
      return false
    },
    showProgramFSN() {
      if (this.LDAnalyze && this.LDAnalyze.calculations) {
        return this.LDAnalyze.calculations.some(t => t.programFSN === true)
      }
      return false
    },
  },
  methods: {
    getEntityName,
    setErrHandler,

    urlGetLegalDepartmentAnalyzeById,
    urlTakeToWorkLegalConclusion,
    urlUpdateLegalConclusion,
    urlSetLegalDepartmentStatus,
    urlCreateLegalDepartmentPrintedForm,
    getProjectGuarantors,
    getProjectSubtenants,
    getContactInitials,
    getLoDescription,
    downloadPrintedForm,
    getGuarantorName(g) {
      if (!g) return
      return g.guarantorTypeId === 2
        ? g.contractor.shortName
        : this.getContactInitials(g.contact)
    },
    getSubtenantName(g) {
      if (!g) return
      return g.subtenantTypeId === 2
        ? g.contractor.shortName
        : this.getContactInitials(g.contact)
    },
    revision() {
      const serviceObj = {
        url: this.urlSetLegalDepartmentStatus(this.LDAnalyze.id),
        name: 'legal',
      }
      return this.$store.commit('setDialog', {
        title: 'Відправити на доопрацювання',
        dialogItem: { serviceObj },
        params: {
          cb: () =>
            this.getLegalDepartmentAnalyzeById()
              .then(() => this.$emit('updateProjects'))
              .finally(() => (this.loading = false)),
        },
        action: 'sendToRevision',
      })
    },
    getLegalDepartmentAnalyzeById() {
      this.loading = true
      return this.$axios
        .get(this.urlGetLegalDepartmentAnalyzeById(this.id))
        .then(res => {
          Object.assign(this.LDAnalyze, res.data)
          const fillResults = entity => {
            const entityData = res.data[`${entity}s`]
            if (entityData) {
              return entityData.map(e => {
                const typeId = e.contact ? 1 : 2
                const id = e.contact?.id || e.contractor?.id

                const results = res.data[`${entity}Results`]
                const result = results
                  ? results.find(
                      r =>
                        `${r[`${entity}TypeId`]}${r[`${entity}Id`]}` ===
                        `${typeId}${id}`
                    )
                  : null

                const localResult = {}
                localResult[`${entity}TypeId`] = typeId
                localResult[`${entity}Id`] = id
                const commentKey = (() => {
                  switch (entity) {
                    case 'guarantor':
                      return 'guarantorConclusion'
                    case 'subtenant':
                      return 'subtenantConclusion'
                    case 'provider':
                      return 'providerConclusion'
                  }
                })()
                localResult[commentKey] = null

                return result || localResult
              })
            } else {
              return []
            }
          }
          this.conclusion.clientConclusion = res.data.clientConclusion
          this.conclusion.providersResults = fillResults('provider')
          this.conclusion.generalConclusion = res.data.generalConclusion
          this.conclusion.guarantorResults = fillResults('guarantor')
          this.conclusion.subtenantResults = fillResults('subtenant')

          return res
        })
        .catch(this.$err)
    },
    takeToWork() {
      return this.$axios
        .get(this.urlTakeToWorkLegalConclusion(this.id))
        .then(res => {
          if (res.data.message) {
            this.$setSnackbar({ text: res.data.message, color: 'warning' })
          } else {
            this.$setSnackbar({ text: 'Статус: "В роботi"' })
            this.getLegalDepartmentAnalyzeById().finally(
              () => (this.loading = false)
            )
          }
          return res
        })
        .catch(this.$err)
    },
    async submit() {
      if (this.v$.$invalid) {
        this.v$.$anyError
        this.v$.$touch()
        v$Notify(this.v$.conclusion, 'legalDepartmentVerification')
        return
      }

      this.submitLoading = true
      return this.$axios
        .post(this.urlUpdateLegalConclusion(this.id), this.conclusion)
        .then(res => {
          if (res.data.status === 400) {
            return this.$setSnackbar({
              text: res.data.message,
              color: 'warning',
            })
          }
          this.$setSnackbar({ text: 'Аналіз завершено' })
          this.submitLoading = false
          this.getLegalDepartmentAnalyzeById().finally(
            () => (this.loading = false)
          )
        })
        .catch(this.$err)
    },
  },
  watch: {
    'conclusion.clientConclusion': function (val) {
      if (Array.isArray(this.calculations) && this.calculations.length > 0) {
        this.calculations.forEach((calc, idx) => {
          if (calc.leaseReverse && this.conclusion?.providersResults?.[idx]) {
            this.conclusion.providersResults[idx].providersConclusion = val
          }
        })
      }
    },
  },
  created() {
    this.getLegalDepartmentAnalyzeById().finally(() => (this.loading = false))
  },
}
</script>
<style lang="scss" scoped>
.verification-subheader {
  padding-top: 0 !important;
  font-size: 1rem !important;
}
.v-tab-item--selected.v-tab {
  font-weight: 700 !important;
  color: #08487a !important;
  font-size: 13px;
}
.v-tab {
  color: #838282 !important;
  font-size: 13px;
  letter-spacing: normal;
  text-transform: uppercase;
}
</style>
