<template>
  <div>
    <div class="mt-3">
      <div>
        <v-checkbox
          v-model="agentData.isPaymentThrough"
          class="mt-0"
          label="Виплата через"
          dense
          color="red"
          :readonly="readOnly"
          hide-details
          @update:model-value="handleChangePaymentThrough">
          <template #label>
            <span style="font-size: 12px">Виплата через </span>
          </template>
        </v-checkbox>
        <v-fab-transition hide-on-leave>
          <div v-if="agentData.isPaymentThrough">
            <div class="d-flex" style="justify-content: end">
              <v-btn
                style="
                  background: rgb(252 114 71);
                  color: white;
                  margin-right: 10px;
                "
                dense
                small
                @click="setThroughResponsible">
                <v-icon>{{ 'mdi-account' }}</v-icon>
                Прикріпити конт. особу
              </v-btn>
            </div>
            <v-data-table
              v-if="agentData.isPaymentThrough && tBody"
              :headers="tHead"
              :items="tBody"
              class="calc-table --cust-cell-pd"
              mobileBreakpoint="750"
              dense
              hide-default-footer
              :items-per-page="-1"
              :hide-default-header="!tBody">
              <template #no-data>
                <NoTableData />
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.actions="{ item }">
                <div style="margin-right: -10px">
                  <TableCrud
                    :actionsArray="[
                      {
                        action: () =>
                          item.isActive
                            ? deactivateThrough(item.id)
                            : activateThrough(item.id),
                        text: `${
                          item.isActive ? 'Деактивувати' : 'Активувати'
                        }`,
                        icon: 'mdi-key-arrow-right',
                      },
                      {
                        action: () => deleteThrough(item.id),
                        text: 'Видалити',
                        icon: 'mdi-delete',
                      },
                    ]">
                  </TableCrud>
                </div>
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.isActive="{ item }">
                {{ item.isActive ? 'Активний' : 'Неактивний' }}
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.lesseeTypeId="{ item }">
                {{
                  item.lesseeTypeId === 1 ? 'Фізична особа' : 'Юридична особа'
                }}
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.lessee="{ item }">
                <ALink
                  :text="item.lessee?.shortName || item.lessee?.fullName"
                  :route="getEntityRoute(item.lessee.id, item.entityTypeId)" />
              </template>
            </v-data-table>
          </div>
        </v-fab-transition>
      </div>
    </div>
    <div class="mt-3">
      <div>
        <v-checkbox
          v-model="agentData.isAgentContract"
          class="mt-0"
          label="Договір агентський (підписаний)"
          dense
          :true-value="true"
          :false-value="false"
          color="red"
          :readonly="readOnly"
          hide-details
          @update:model-value="handleAgentContract">
          <template #label>
            <span style="font-size: 12px">
              Договір агентський (підписаний)
            </span>
          </template>
        </v-checkbox>
        <v-fab-transition hide-on-leave>
          <div v-show="agentData.isAgentContract" class="mt-2">
            <v-row>
              <v-col :cols="12" :md="4">
                <v-text-field
                  v-model="agentData.signedAgencyAgreement.number"
                  label="Номер договору"
                  :arror-message="signedAgencyAgreementNumberErr"
                  hide-details>
                </v-text-field>
              </v-col>
              <v-col :cols="12" :md="4">
                <DatePicker
                  v-model="agentData.signedAgencyAgreement.date"
                  :arror-message="signedAgencyAgreementDateErr"
                  single
                  label="Дата договору" />
              </v-col>
            </v-row>
            <v-row>
              <v-col :md="12" :cols="12">
                <FilesUploader
                  v-model:documents="agentData.signedAgencyAgreement.doc"
                  single
                  :arror-message="signedAgencyAgreementDocumentErr"
                  label="Документ (підписаний)">
                </FilesUploader>
              </v-col>
            </v-row>
          </div>
        </v-fab-transition>
      </div>
    </div>
  </div>
</template>
<script>
import DatePicker from '@/components/DatePicker.vue'
import { maxNumLength } from '@/utils/masks'
import {
  trimExceededLength,
  getMfoFromIban,
  setErrHandler,
  getEntityRoute,
} from '@/utils/helperFunc'
import { keepDigit, maxNum } from '@/utils/formatFunc'

import {
  urlActivateThrough,
  urlDeactivateThrough,
  urlDeleteThrough,
  urlSetIsAgentContract,
  urlSetPaymentThrough,
} from '@/pages/request'
import ALink from '@/components/Link.vue'
import FilesUploader from '@/components/FilesUploader.vue'
import TableCrud from '@/components/table-crud.vue'
import NoTableData from '@/components/no-table-data.vue'

export default {
  components: {
    NoTableData,
    TableCrud,
    FilesUploader,
    ALink,
    DatePicker,
  },
  props: {
    agent: { type: Object },
    readOnly: { type: Boolean },
    v: { type: Object },
  },
  data() {
    return {
      isAgentContractCheck: false,
      isPaymentThroughCheck: false,
      tHead: [
        { title: 'Статус', key: 'isActive', align: 'start', sortable: false },
        {
          title: 'Тип',
          key: 'lesseeTypeId',
          align: 'start',
          sortable: false,
        },
        {
          title: 'Конт.особа',
          key: 'lessee',
          align: 'start',
          sortable: false,
        },
        { title: 'Дії', key: 'actions', align: 'end', sortable: false },
      ],
    }
  },
  computed: {
    agentData() {
      const agentData = this.agent
      if (!agentData.signedAgencyAgreement) {
        agentData.signedAgencyAgreement = {}
      }
      return agentData
    },
    $v() {
      return this.v
    },
    tBody() {
      return this.agentData.paymentThroughs
    },
    paymentThroughInnErr() {
      return setErrHandler(this.$v.agentData.paymentThroughInn)
    },
    paymentThroughIbanErr() {
      return setErrHandler(this.$v.agentData.paymentThroughIban)
    },
    paymentThroughBankNameErr() {
      return setErrHandler(this.$v.agentData.paymentThroughBankName)
    },
    paymentThroughMfoErr() {
      return setErrHandler(this.$v.agentData.paymentThroughMfo)
    },
    paymentThroughCardNumberErr() {
      return setErrHandler(this.$v.agentData.paymentThroughCardNumber)
    },
    signedAgencyAgreementDateErr() {
      return setErrHandler(this.$v.agentData.date)
    },
    signedAgencyAgreementNumberErr() {
      return setErrHandler(this.$v.agentData.number)
    },
    signedAgencyAgreementDocumentErr() {
      return setErrHandler(this.$v.agentData.doc)
    },
  },
  methods: {
    getEntityRoute,
    getMfoFromIban,
    maxNumLength,
    urlDeleteThrough,
    urlActivateThrough,
    urlDeactivateThrough,
    urlSetPaymentThrough,
    trimExceededLength,
    keepDigit,
    maxNum,
    async getBank(event) {
      this.trimExceededLength('agent-iban', 29)
      const { mfo = null, name = null } = await this.getMfoFromIban(event)
      this.agentData.paymentThroughBankName = name
      this.agentData.paymentThroughMfo = mfo
    },
    activateThrough(id) {
      return this.$axios.get(urlActivateThrough(id)).then(() => {
        this.$setSnackbar({ text: 'Активовано' })
        this.$store.commit('refreshPage')
      })
    },
    deactivateThrough(id) {
      return this.$axios.get(urlDeactivateThrough(id)).then(() => {
        this.$setSnackbar({ text: 'Деактивовано' })
        this.$store.commit('refreshPage')
      })
    },
    deleteThrough(id) {
      return this.$axios.delete(urlDeleteThrough(id)).then(() => {
        this.$setSnackbar({ text: 'Видалено' })
        this.$store.commit('refreshPage')
      })
    },
    isAgentContract(value) {
      const request = {
        isAgentContract: value,
      }
      this.isAgentContractCheck = !this.isAgentContractCheck

      return this.$axios
        .post(urlSetIsAgentContract(this.agentData.id), request)
        .then(() => {
          this.$store.commit('refreshPage')
        })
    },
    handleChangePaymentThrough() {
      this.isPaymentThrough(this.agentData.isPaymentThrough)
    },
    handleAgentContract() {
      this.isAgentContract(this.agentData.isAgentContract)
    },
    isPaymentThrough(value) {
      const request = {
        isPaymentThrough: value,
      }
      this.isPaymentThroughCheck = value

      return this.$axios
        .post(urlSetPaymentThrough(this.agentData.id), request)
        .then(() => {
          this.$store.commit('refreshPage')
        })
    },
    setThroughResponsible() {
      return this.$store.commit('setDialog', {
        title: 'Контактна особа',
        dialogItem: {
          id: this.agentData.id,
          paymentThroughs: this.agentData.paymentThroughs,
        },
        params: {
          cb: () => {
            this.$store.commit('refreshPage')
          },
          submitText: 'ПРИКРIПИТИ',
          rejectText: 'ВIДМIНИТИ',
        },
        action: 'addThroughResponsible',
      })
    },
  },
}
</script>
