<template>
  <v-menu @update:model-value="$store.commit('confirmNotificationQueue')">
    <template #activator="{ props }">
      <v-badge
        overlap
        color="#fc7247"
        :value="!!unreadNotificationsLength"
        :content="unreadNotificationsLength">
        <v-btn icon density="comfortable" v-bind="props">
          <v-icon size="20">mdi-bell-outline</v-icon>
        </v-btn>
      </v-badge>
    </template>

    <NotificationList />
  </v-menu>
</template>

<script>
import NotificationList from './NotificationList.vue'
import { mapState } from 'vuex'

export default {
  name: 'Index',
  components: { NotificationList },
  computed: {
    ...mapState({
      notifications: state => state.notifications.notificationList,
    }),
    unreadNotificationsLength() {
      return this.notifications.filter(n => !n.isRead).length
    },
  },
  async created() {
    await this.$store.dispatch('getNotifications')
    await this.$store.dispatch('listenNotificationEvents')
  },
}
</script>
