import axios from 'axios'
import { getItems } from '../helper.js'
import {
  urlgetBlDkps,
  urlDeleteBlDkp,
  urlCreateBlDkpTable,
} from '@/pages/request'
const blDkps = {
  state: () => ({
    dkps: {
      dkpLeasReverseNoVat: null,
      dkpLeasReverseVat: null,
      dkpNoVat: null,
      dkpVat: null,
    }
  }),
  mutations: {
    addDkps(state, dkps) {
      Object.assign(state.dkps, dkps)
    }
  },
  actions: {
    async loadBlDkps({ commit }) {
      const data = await getItems(urlgetBlDkps())
      const dkps = {}
      data.forEach(el => {
        el.url = el.text = el.fileName
        if (el.pdv) {
          el.leaseReverse ? dkps.dkpLeasReverseVat = el : dkps.dkpVat = el
        } else {
          el.leaseReverse ? dkps.dkpLeasReverseNoVat = el : dkps.dkpNoVat = el
        }
      })
      commit('addDkps', dkps)
    },
    async createBlDkpTable(_, file) {
      await axios.post(urlCreateBlDkpTable(), file)
    },
    async deleteDkp(_, id) {
      await axios.delete(urlDeleteBlDkp(id))
    }
  }
}

export { blDkps }
