<template>
  <div class="split-btn">
    <v-btn class="split-btn__button" :disabled="actions.main.disabled" readonly>
      {{ actions?.main?.label }}
    </v-btn>
    <v-menu bottom offset-y :close-on-content-click="false">
      <template #activator="{ props }">
        <v-btn
          v-if="Array.isArray(actions.list)"
          class="split-btn__icon"
          max-width="40px"
          min-width="40px"
          v-bind="props">
          <v-icon>{{ 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </template>
      <v-list elevation="1">
        <v-list-item
          v-for="(item, idx) in actions.list.filter(item => item.show)"
          :key="idx"
          :value="idx"
          :disabled="item.disabled">
          <!-- if has actions -->
          <template v-if="Array.isArray(item.actions)">
            <v-menu bottom offset-y>
              <template #activator="{ props }">
                <div v-bind="props" class="full-clickable">
                  <v-list-item-title style="color: #08487a">
                    <v-icon class="mr-2">{{ item.icon }}</v-icon>
                    <span>{{ item.label }}</span>
                  </v-list-item-title>
                  <v-icon class="ml-auto" style="color: #08487a"
                    >mdi-chevron-right</v-icon
                  >
                </div>
              </template>
              <v-list elevation="1">
                <v-list-item
                  v-for="(subItem, subIdx) in item.actions"
                  :key="subIdx"
                  :value="subIdx"
                  :disabled="subItem.disabled"
                  @click="() => subItem.action && subItem.action()">
                  <v-list-item-title>
                    <v-icon class="mr-2">{{ subItem.icon }}</v-icon>
                    <span>{{ subItem.label }}</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

          <!-- if not has actions -->
          <template v-else>
            <v-list-item-title
              style="color: #08487a"
              @click="() => item.action && item.action()">
              <v-icon class="mr-2">{{ item.icon }}</v-icon>
              <span>{{ item.label }}</span>
            </v-list-item-title>
          </template>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    actions: { type: Object, required: true },
    readonly: { type: Boolean, default: false },
  },
}
</script>

<style scoped>
.split-btn {
  display: flex;
  align-items: center;
}
.split-btn__button {
  letter-spacing: 1px;
  text-transform: none !important;
  background: #fc7247 !important;
  border-radius: 8px !important;
  color: #fff !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: 1px solid white;
}
.split-btn__icon {
  background: #fc7247 !important;
  border-radius: 4px !important;
  color: #fff !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.full-clickable {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
</style>
