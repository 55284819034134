<template>
  <div>
    <div>
      <b-select
        v-model="executiveAuthorityData.executiveAuthorityId"
        label="Виконавчий орган"
        :items="
          $directory.get(
            'executiveAuthorities',
            dialog.dialogItem.contractor.executiveauthority
          )
        "
        :error-messages="getErrorMessages(v$.executiveAuthorityId)"
        :loading="$loading.isLoading('executiveAuthorities')"
        @update:model-value="handleExecutiveAuthorityId"
        @focus="$directory.fill('executiveAuthorities')"
        @blur="v$.executiveAuthorityId.$touch()">
      </b-select>
    </div>
    <div>
      <div>
        <b-select
          v-model="newEmployee"
          :items="employees.filter(e => !e.isExecutiveAuthority)"
          :item-title="
            e =>
              `${e.contact.fullName} (${e.post?.name || 'Посаду не вказано'})`
          "
          :disabled="
            executiveAuthorityData.executiveAuthorityId === 1 &&
            executiveAuthorities.length === 1
          "
          return-object>
          <template #append>
            <v-btn
              icon="mdi-plus"
              density="compact"
              color="red"
              :disabled="!newEmployee"
              @click="addExecutiveAuthority"></v-btn>
          </template>
        </b-select>
      </div>
    </div>
    <v-data-table
      :items="executiveAuthorities"
      :headers="executiveAuthoritiesHeaders"
      :hide-default-footer="executiveAuthorities.length <= 10">
      <template #item.actions="{ item }">
        <v-btn
          color="error"
          icon="mdi-delete"
          density="compact"
          @click="deleteExecutiveAuthority(item.id)"></v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { computed, reactive, ref } from 'vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import {
  urlExecutiveAuthoritySet,
  urlExecutiveAuthorityUnset,
} from '@/pages/request.js'
import { executiveAuthoritiesHeaders } from '@/utils/tableHeaders'
import axios from '@/plugins/axios.js'
import { getErrorMessages } from 'best-modules/utils'
import { Employee } from '@/utils/types'

export default {
  name: 'ExecutiveAuthorities',
  props: {
    dialog: {
      type: Object,
    },
  },
  data: () => {
    return { executiveAuthoritiesHeaders }
  },
  computed: {
    validation() {
      return { v$: this.v$, name: 'executiveAuthority' }
    },
  },
  methods: { getErrorMessages },
  setup(props) {
    const newEmployee = ref<Employee | null>(null)

    const employees = ref<Employee[]>(
      props.dialog.dialogItem.contractor.employees || []
    )
    const executiveAuthorities = computed<Employee[]>(() => {
      return employees.value.filter(e => e.isExecutiveAuthority)
    })

    const executiveAuthorityData = reactive<{
      employeesToSet: number[]
      employeesToUnset: number[]
      executiveAuthorityId: number
    }>({
      employeesToSet: executiveAuthorities.value.map(e => e.id),
      employeesToUnset: [],
      executiveAuthorityId:
        props.dialog.dialogItem.contractor.executiveAuthorityId,
    })

    const rules = {
      executiveAuthorityId: { required: required },
    }
    const v$ = useVuelidate(rules, executiveAuthorityData)

    const addExecutiveAuthority = () => {
      if (newEmployee.value) {
        const employee = {
          ...newEmployee.value,
          isExecutiveAuthority: true,
        }
        employees.value = employees.value.map(e =>
          e.id === employee.id ? employee : e
        )
        executiveAuthorityData.employeesToSet.push(employee.id)
        newEmployee.value = null
        if (executiveAuthorityData.employeesToUnset.includes(employee.id)) {
          executiveAuthorityData.employeesToUnset =
            executiveAuthorityData.employeesToUnset.filter(e => {
              return e !== employee.id
            })
        }
      }
    }
    const deleteExecutiveAuthority = (employeeId: number) => {
      employees.value = employees.value.map(e => {
        if (e.id === employeeId) {
          return {
            ...e,
            isExecutiveAuthority: false,
          }
        } else {
          return e
        }
      })
      executiveAuthorityData.employeesToSet =
        executiveAuthorityData.employeesToSet.filter(eId => {
          return eId !== employeeId
        })
      executiveAuthorityData.employeesToUnset.push(employeeId)
    }

    const handleExecutiveAuthorityId = (executiveAuthorityId: number) => {
      if (executiveAuthorityId === 1) {
        executiveAuthorities.value.forEach((e, idx) => {
          if (idx > 0) {
            deleteExecutiveAuthority(e.id)
          }
        })
      }
    }

    const submit = async () => {
      if (executiveAuthorityData.employeesToSet.length) {
        await axios.post(urlExecutiveAuthoritySet(), {
          contractorId: props.dialog.dialogItem.contractor.id,
          employeeId: executiveAuthorityData.employeesToSet,
          executiveAuthorityId: executiveAuthorityData.executiveAuthorityId,
        })
      }
      if (executiveAuthorityData.employeesToUnset.length) {
        await axios.post(urlExecutiveAuthorityUnset(), {
          contractorId: props.dialog.dialogItem.contractor.id,
          employeeId: executiveAuthorityData.employeesToUnset,
        })
      }
    }

    return {
      v$,
      submit,
      executiveAuthorityData,
      employees,
      newEmployee,
      addExecutiveAuthority,
      executiveAuthorities,
      deleteExecutiveAuthority,
      handleExecutiveAuthorityId,
    }
  },
}
</script>

<style scoped></style>
