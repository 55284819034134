<template>
  <v-btn
    v-if="oldName && newName && oldName !== newName"
    v-tooltip:top="`Стара назва: ${oldName}. Нова назва: ${newName}`"
    size="x-small"
    icon
    @click.stop="$emit('update:model-value', !modelValue)">
    <v-icon small>{{ 'mdi-rotate-left' }}</v-icon>
  </v-btn>
</template>

<script lang="ts">
export default {
  name: 'ToggleOldName',
  emits: ['update:model-value'],
  props: {
    modelValue: {
      type: Boolean,
    },
    newName: {
      type: String,
    },
    oldName: {
      type: String,
    },
  },
}
</script>

<style scoped></style>
