<template>
  <div>
    <div class="mb-5">
      <div class="section-title">
        <v-icon style="color: #fc7247" class="mr-2">{{
          'mdi-tooltip-account'
        }}</v-icon>
        <span>Загальна iнформацiя по логу</span>
        <v-chip class="ma-2" color="#09487a" style="color: white">
          №{{ $route.params.id }}
        </v-chip>
      </div>
    </div>
    <div class="section">
      <div class="history-block mt-2">
        <div class="wrapper" style="padding: 0px">
          <v-data-table
            :headers="logTHead"
            :items="logs"
            class="pb-2 custom-table head-line"
            dense>
            <!-- eslint-disable-next-line -->
            <template #item.created_at="{ item }">
              {{ toFormatDate(item.created_at) }}
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.user="{ item }">
              {{ getResponsibleShortName(item.user) }}
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.details="{ item }">
              <v-menu left :hudge-width="600" offset-y>
                <template #activator="{ props }">
                  <v-btn icon size="x-small" v-bind="props">
                    <EyeIcon />
                  </v-btn>
                </template>
                <div>
                  <v-data-table
                    style="background: white !important"
                    :headers="changesTHead"
                    :items="item.changes"
                    class="pb-2 custom-table head-line"
                    hide-default-footer
                    dense>
                  </v-data-table>
                </div>
              </v-menu>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
    <SectionLoader v-if="loading"></SectionLoader>
  </div>
</template>
<script>
import { urlGetAdminSingleLogs } from '@/pages/request'
import SectionLoader from '@/components/section-loader.vue'
import {
  getEntityRoute,
  toFormatDate,
  getResponsibleShortName,
  getContactInitials,
} from '@/utils/helperFunc'
import EyeIcon from '@/assets/svg/eye-icon.vue'
export default {
  components: { EyeIcon, SectionLoader },
  data: () => ({
    loading: false,
    logs: [],
    fav: true,
    menu: false,
    dateRange: {
      startDate: null,
      endDate: null,
    },
    historyBlock: false,
    logTHead: [
      { title: 'Дата', key: 'created_at', sortable: false, align: 'start' },
      {
        title: 'Відповідальний',
        key: 'user',
        sortable: false,
        align: 'start',
      },
      { title: 'Змiни', key: 'details', sortable: false, align: 'end' },
    ],
    changesTHead: [
      { title: 'Поле', key: 'name', sortable: false, width: '100px' },
      { title: 'Було', key: 'oldValue', sortable: false, width: '100px' },
      { title: 'Стало', key: 'newValue', sortable: false, width: '100px' },
    ],
  }),
  methods: {
    urlGetAdminSingleLogs,
    toFormatDate,
    getEntityRoute,
    getContactInitials,
    getResponsibleShortName,
    getSingleLogDetails() {
      this.loading = true
      return this.$axios
        .get(urlGetAdminSingleLogs(this.$route.params.id))
        .then(res => {
          this.loading = false
          this.logs = [res.data]
          return this.logs
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
    closePopup() {
      this.historyBlock = false
    },
  },
  mounted() {
    this.getSingleLogDetails()
  },
}
</script>

<style lang="scss" scoped>
.history-block {
  width: 100%;
  height: auto;
}

.section {
  margin-bottom: 40px;
}
.section-title {
  padding: 0;
}
.section-content {
  padding-left: 30px;
}
.section-row {
  margin-top: 25px !important;
  flex-direction: column !important;
}
.log-row {
  display: block;
  width: 290px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.section-table {
  display: block;
  padding-left: 15px;
}
</style>
