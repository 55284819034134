<template>
  <div>
    <v-btn
      class="text-white no-transform mb-2"
      color="grey darken-3"
      small
      @click="choosedTable = !choosedTable">
      {{ toggleBtnText }}
    </v-btn>
    <v-scroll-x-transition hide-on-leave>
      <v-card v-show="!choosedTable" :elevation="0">
        <v-card-text>
          <pre>{{ JSON.stringify(contractor.registry, null, 2) }}</pre>
          <pre>{{ JSON.stringify(contractor.factors, null, 2) }}</pre>
        </v-card-text>
      </v-card>
    </v-scroll-x-transition>
    <!-- common info -->
    <v-scroll-x-transition hide-on-leave>
      <div v-show="choosedTable">
        <list-section title="Загальнi данi" :mt="0" :mb="0" :elevation="0">
          <template #body>
            <common-table
              :tHead="[
                { title: 'Повна назва', key: 'fullName', sortable: false },
                { title: 'Коротка назва', key: 'shortName', sortable: false },
                {
                  title: 'Повна назва (Англ)',
                  key: 'fullNameEn',
                  sortable: false,
                },
                { title: 'ЄДРПОУ', key: 'code', sortable: false },
              ]"
              :tBody="[contractor.registry]">
            </common-table>
          </template>
        </list-section>
        <!-- address -->
        <list-section title="Адреса" :mt="0" :mb="0" :elevation="0">
          <template #body>
            <common-table
              :tHead="[
                { title: 'Країна', key: 'country', sortable: false },
                { title: 'Поштовий iндекс', key: 'zip', sortable: false },
                {
                  title: 'Адреса',
                  key: 'address',
                  sortable: false,
                  align: 'center',
                },
              ]"
              :tBody="[contractor.registry.address]">
            </common-table>
          </template>
        </list-section>
        <!-- legal data -->
        <list-section title="Юридичнi данi" :mt="0" :mb="0" :elevation="0">
          <template #body>
            <common-table
              :tHead="[
                {
                  title: 'Генеральний деректор',
                  key: 'ceoName',
                  sortable: false,
                },
                { title: 'Статус', key: 'status', sortable: false },
                { title: 'Email', key: 'email', sortable: false },
                {
                  title: 'Дата реєстрації',
                  key: 'registrationDate',
                  sortable: false,
                },
                {
                  title: 'Статутний капітал',
                  key: 'capital',
                  sortable: false,
                },
              ]"
              :tBody="[contractor.registry]">
            </common-table>
          </template>
        </list-section>
        <!-- headers -->
        <list-section title="Керiвники" :mt="0" :mb="0" :elevation="0">
          <template #body>
            <common-table
              :tHead="[
                { title: 'ПIБ', key: 'name', sortable: false },
                { title: 'Роль', key: 'role', sortable: false },
                {
                  title: 'Повноваження',
                  key: 'restriction',
                  sortable: false,
                  align: 'center',
                },
              ]"
              :tBody="contractor.registry.heads">
            </common-table>
          </template>
        </list-section>
        <!-- activities -->
        <list-section title="Види дiяльностi" :mt="0" :mb="0" :elevation="0">
          <template #body>
            <common-table
              :tHead="[
                { title: 'Назва дiяльностi', key: 'name', sortable: false },
                { title: 'Код дiяльностi', key: 'code', sortable: false },
              ]"
              :tBody="contractor.registry.activities">
            </common-table>
          </template>
        </list-section>
        <!-- primay activity -->
        <list-section
          title="Основний вид дiяльностi"
          :mt="0"
          :mb="0"
          :elevation="0">
          <template #body>
            <span class="pa-2 d-inline-block">
              {{ contractor.registry.primaryActivity }}
            </span>
          </template>
        </list-section>
        <!-- phones -->
        <list-section title="Телефони компанії" :mt="0" :mb="0" :elevation="0">
          <template #body>
            <div
              v-if="
                contractor.registry.phones && contractor.registry.phones.length
              ">
              <span
                v-for="(phone, key) in contractor.registry.phones"
                :key="key"
                class="pa-2 d-inline-block">
                {{ phone }}
              </span>
            </div>
          </template>
        </list-section>
        <!-- beneficiars -->
        <list-section
          title="Бенефiцiарнi власники"
          :mt="0"
          :mb="0"
          :elevation="0">
          <template #body>
            <common-table
              :tHead="[
                { title: 'ПIБ', key: 'name', sortable: false },
                { title: 'Роль', key: 'role', sortable: false },
                {
                  title: 'Сума в статутному капіталi',
                  key: 'amount',
                  sortable: false,
                  align: 'center',
                },
                {
                  title: 'Адреса',
                  key: 'location',
                  sortable: false,
                  align: 'center',
                },
              ]"
              :tBody="contractor.registry.beneficiaries">
            </common-table>
          </template>
        </list-section>
        <!-- registrations -->
        <list-section title="Реєстрації" :mt="0" :mb="0" :elevation="0">
          <template #body>
            <common-table
              :tHead="[
                { title: 'ЄДРПОУ', key: 'code', sortable: false },
                {
                  title: 'Мiсце реєстрації',
                  key: 'name',
                  sortable: false,
                  width: '40%',
                  align: 'center',
                },
                {
                  title: 'Тип реєстрації',
                  key: 'description',
                  sortable: false,
                  align: 'center',
                },
                { title: 'Дата старту', key: 'startDate', sortable: false },
                { title: 'Дата завершення', key: 'endDate', sortable: false },
              ]"
              :tBody="contractor.registry.registrations">
            </common-table>
          </template>
        </list-section>
        <!-- branches -->
        <list-section
          v-if="contractor.registry.branches"
          title="Філії"
          :mt="0"
          :mb="0"
          :elevation="0">
          <template #body>
            <common-table
              :tHead="[
                { title: 'Фiлiя', key: 'name', sortable: false },
                { title: 'ЄДРПОУ', key: 'code', sortable: false },
                {
                  title: 'Адреса',
                  key: 'addresses',
                  sortable: false,
                  align: 'center',
                },
              ]"
              :tBody="contractor.registry.branches.map(getAddress)">
            </common-table>
          </template>
        </list-section>
        <!-- organization structure -->
        <list-section
          title="Структура організації"
          :mt="0"
          :mb="0"
          :elevation="0">
          <template #body>
            <v-data-table
              :headers="[
                {
                  title: 'Тип',
                  key: 'olfName',
                  sortable: false,
                  width: '20%',
                },
                {
                  title: 'Статут',
                  key: 'statute',
                  sortable: false,
                  width: '40%',
                },
                {
                  title: 'Установчий документ',
                  key: 'foundingDocument',
                  sortable: false,
                  width: '20%',
                },
                {
                  title: 'Менеджмент',
                  key: 'management',
                  sortable: false,
                  width: '20%',
                },
              ]"
              :items="[contractor.registry]"
              :hide-default-footer="true"
              :hide-default-header="false"
              class="pb-2 pt-1 custom-table head-line"
              dense>
              <!-- eslint-disable-next-line -->
              <template #item.statute="{ item }">
                <span class="d-inline-block" style="max-width: 250px">
                  {{ item.statute }}
                </span>
              </template>
            </v-data-table>
          </template>
        </list-section>
        <!-- company registration -->
        <list-section title="Реєстрація юридичної особи" :mt="0" :elevation="0">
          <template #body>
            <common-table
              :tHead="[
                {
                  title: 'Дата реєстрації',
                  key: 'date',
                  sortable: false,
                  align: 'center',
                },
                {
                  title: 'Реєстраційний номер',
                  key: 'recordNumber',
                  sortable: false,
                  align: 'center',
                },
              ]"
              :tBody="[contractor.registry.registration]">
            </common-table>
          </template>
        </list-section>
        <!-- company changes -->
        <list-section title="Змiни" :mt="0" :mb="0" :elevation="0">
          <template #body>
            <v-data-table
              ref="dialogEdrTable"
              :headers="edrChangeTableHeaders"
              :items="contractor.factors"
              :hide-default-footer="true"
              :hide-default-header="false"
              class="pb-2 pt-1 custom-table head-line"
              dense>
              <!-- eslint-disable-next-line -->
              <template #item.factorGroup="{ item }">
                {{ getFactor(item.factorGroup) }}
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.indicator="{ item }">
                <v-icon :color="getIndicator(item.indicator).color" small>
                  {{ getIndicator(item.indicator).icon }}
                </v-icon>
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.json="{ item }">
                <v-menu :close-on-content-click="false" top offset-y>
                  <template #activator="{ props }">
                    <v-btn size="small" icon v-bind="props">
                      <v-icon size="small" color="grey darken-3">
                        {{ 'mdi-text-box-search' }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-text>
                      <pre>{{ JSON.stringify(item, null, 2) }}</pre>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </template>
            </v-data-table>
          </template>
        </list-section>
      </div>
    </v-scroll-x-transition>
    <v-row>
      <v-col :cols="12" :md="6">
        <v-alert prominent type="warning" elevation="2" dense>
          <v-row align="center">
            <v-col class="grow" style="font-size: 0.95rem">
              При підтвердженнi всi незбереженi данi в формі буде втрачено
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import commonTable from '@/components/common-table.vue'
import listSection from '@/components/list-section.vue'
import { formutSum } from '@/utils/formatFunc.js'
import { edrChangeTableHeaders } from '@/utils/tableHeaders'
export default {
  components: { commonTable, listSection },
  props: {
    contractor: { type: Object },
  },
  data() {
    return {
      choosedTable: true,
      edrChangeTableHeaders,
    }
  },
  computed: {
    toggleBtnText() {
      return this.choosedTable ? 'Переглянути в JSON' : 'Переглянути в таблицях'
    },
  },
  methods: {
    formutSum,
    getAddress(branch) {
      const addresses = branch.address.address
      branch.address
      branch.addresses = addresses
      return branch
    },
    getFactor(group) {
      switch (group) {
        case 'tax':
          return 'Податки'
        case 'court':
          return 'Суди'
        case 'sanction':
          return 'Cанкцiї'
        case 'edr':
          return 'ЄДР'
      }
    },
    getIndicator(indicator) {
      if (indicator === 'warning')
        return { icon: 'mdi-alert-circle', color: 'orange' }
      if (indicator === 'positive️')
        return { icon: 'mdi-check-circle', color: 'green' }
      else return { icon: 'mdi-check-circle', color: 'green' }
    },
  },
}
</script>

<style scoped>
pre {
  white-space: pre-wrap;
}
</style>
