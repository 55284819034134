<template>
  <PhoneCalls :readonly="readonly" :calls="lead.detail_calls" :leadId="lead.id" />
</template>

<script>
import PhoneCalls from '../LeadPhoneCalls.vue'
export default {
  components: { PhoneCalls },
  props: {
    lead: { type: Object },
    readonly: { type: Boolean },
  },
}
</script>

<style lang="scss" scoped></style>
