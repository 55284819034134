<template>
  <div class="hr"
    :style="`padding: ${padding}`">
    <div :style="`
        border-top: ${height} solid ${color}
        height: ${height}
      `">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'rgba(255, 255, 255, 0.315)'
    },
    padding: {
      type: String,
      default: '0'
    },
    height: {
      type: String,
      default: '1px'
    }
  }
}
</script>

<style lang="scss" scoped>
.hr {
  width: 100%;
}
</style>