<template>
  <div>
    <div>
      <div>
        <div class="d-flex justify-end pt-1 pb-1">
          <v-btn
            data-readonly="hide"
            style="
              background: rgb(252, 114, 71);
              border-radius: 4px;
              color: rgb(255, 255, 255);
            "
            :disabled="statusId !== 6"
            @click="createProjectPledge">
            <v-icon style="margin-right: 5px">
              {{ 'mdi-plus' }}
            </v-icon>
            Створити договір застави
          </v-btn>
        </div>
        <v-data-table
          v-if="project.pledgeContracts"
          :headers="pledgeTHead"
          :items="project.pledgeContracts"
          class="calc-table --cust-cell-pd"
          mobileBreakpoint="750"
          dense
          :hide-default-footer="!project.pledgeContracts.length"
          :hide-default-header="!project.pledgeContracts.length">
          <!-- eslint-disable-next-line -->
          <template #no-data>
            <NoData></NoData>
          </template>
          <template #item.date="{ item }">
            {{ toFormatDate(item.date) }}
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.mortgagor="{ item }">
            <ALink
              :text="getEntityName(item.mortgagor)"
              :route="getEntityRoute(item.mortgagorId, item.mortgagorTypeId)" />
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.number="{ item }">
            <ALink
              :text="item.number"
              :route="{
                name: 'mortgagor-agreement',
                params: { id: item.id },
              }" />
          </template>
        </v-data-table>
      </div>
    </div>
    <v-divider></v-divider>
  </div>
</template>
<script>
import ALink from '@/components/Link.vue'
import { getEntityName, getEntityRoute, toFormatDate } from '@/utils/helperFunc'
import { mapState } from 'vuex'
import { setSnackbar } from 'best-modules/plugins'
import NoData from '@/components/no-table-data.vue'
export default {
  emits: ['dataChanged'],
  components: { NoData, ALink },
  props: {
    statusId: { type: Number },
  },
  data: () => ({
    pledgeTHead: [
      {
        title: 'Статус',
        key: 'status.name',
        align: 'start',
        sortable: false,
      },
      { title: 'Номер', key: 'number', align: 'start', sortable: false },
      { title: 'Дата', key: 'date', align: 'start', sortable: false },
      {
        title: 'Тип застави',
        key: 'pledgeType.name',
        align: 'start',
        sortable: false,
      },
      {
        title: 'Заставодавець',
        key: 'mortgagor',
        align: 'start',
        sortable: false,
      },
    ],
  }),
  computed: {
    ...mapState({
      project: state => state.projects.project,
    }),
  },
  methods: {
    getEntityRoute,
    getEntityName,
    toFormatDate,
    createProjectPledge() {
      this.$store.commit('setDialog', {
        title: 'Договір застави',
        dialogItem: {
          projectId: this.project.id,
          mortgagor: null,
        },
        params: {
          cb: () => {
            setSnackbar({ text: 'Створено договір застави ' })
            this.$emit('dataChanged')
          },
        },
        action: 'addProjectPledge',
      })
    },
  },
}
</script>
<style scoped lang="scss">
.table-item {
  &:hover {
    background: #dcdcdc;
  }
}
.card-block-title {
  display: block;
  color: #08487a;
  font-weight: 600;
  font-size: 16px;
  margin-left: 9px;
}
</style>
