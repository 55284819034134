<template>
  <div>
    <div class="d-flex gap-1 align-center" style="height: 36px">
      <div class="d-flex align-center justify-space-between w-100">
        <span class="label d-block">Підписант</span>
        <InputPusher
          v-if="localSignatory.contactId"
          :routeObject="{
            name: 'individuals-form',
            params: { id: localSignatory.contactId },
          }"
          :simpleText="simpleText"
          text="Перейти до контакту">
        </InputPusher>
      </div>
      <v-btn
        v-if="showDelete"
        size="small"
        icon
        :loading="loading"
        @click="$emit('delete', localSignatory.contactId)">
        <v-icon size="small">{{ 'mdi-close' }}</v-icon>
      </v-btn>
    </div>
    <v-select
      v-model="localSignatory.contactId"
      class="mt-0 pt-0 mb-2"
      placeholder="оберіть підписанта"
      :items="signatoriesList"
      item-title="contact.fullName"
      item-value="contact.id"
      variant="outlined"
      :readonly="readonly"
      :error-messages="contactIdErr"
      :hide-details="!contactIdErr"
      @update:model-value="localSignatory.basisId = null"
      @blur="v$ && v$.contactId.$touch()">
    </v-select>
    <div>
      <span class="label">На основі документу</span>
      <v-select
        v-model="localSignatory.basisId"
        style="margin-top: 3px !important"
        class="mt-0 pt-0 mb-3"
        placeholder="оберіть документ"
        :items="basisList"
        variant="outlined"
        :item-title="getBasisDocumentText"
        item-value="id"
        :readonly="readonly"
        :error-messages="basisIdErr"
        :hide-details="!basisIdErr"
        @blur="v$ && v$.basisId.$touch()">
      </v-select>
    </div>
  </div>
</template>

<script>
import InputPusher from '@/components/InputPusher.vue'
import { setErrHandler } from '@/utils/helperFunc'

export default {
  components: { InputPusher },
  emits: ['delete', 'update'],
  props: {
    signatoriesList: { type: Array },
    signatory: {
      type: Object,
      default: () => ({ contactId: null, basisId: null }),
    },
    showDelete: { type: Boolean },
    v$: { type: Object },
    readonly: { type: Boolean },
    loading: { type: Boolean },
    simpleText: { type: Boolean },
  },
  data() {
    return {
      localSignatory: null,
    }
  },
  computed: {
    basisList() {
      const list = this.signatoriesList.find(s => {
        return s.contact.id === this.localSignatory.contactId
      })?.employeeSignatureBases
      switch (true) {
        case !list:
          return []
        case list && !Array.isArray(list):
          return [list].filter(Boolean)
        default:
          return list
      }
    },
    contactIdErr() {
      return setErrHandler(this.v$?.contactId)
    },
    basisIdErr() {
      return setErrHandler(this.v$?.basisId)
    },
  },
  methods: {
    getBasisDocumentText(item) {
      const name = item?.name || item?.basisDocument?.name
      const description = item?.documentComment
        ? ` (${item.documentComment})`
        : ''
      return name + description
    },
  },
  created() {
    Object.clone = function (obj) {
      return obj ? JSON.parse(JSON.stringify(obj)) : obj
    }
    this.localSignatory = Object.clone(this.signatory)
    this.$watch('localSignatory', val => this.$emit('update', val), {
      deep: true,
    })
    this.$watch(
      'localSignatory.contactId',
      () => {
        if (this.basisList.length === 1) {
          this.localSignatory.basisId = this.basisList[0].id
        }
      },
      { immediate: true }
    )
  },
}
</script>

<style lang="scss" scoped>
.label {
  display: block;
  color: #838282;
  font-size: 12px;
  margin-bottom: 0 !important;
}
</style>
