<template>
  <div
    v-if="Number.isInteger(currentStatus)"
    data-readonly="true"
    :class="`d-flex pa-3 pt-4 pb-4 ${spreadItems ? '' : ''} statusMobile`">
    <div
      v-for="(item, key) in statuses"
      :key="key"
      class="align-center tab-btn"
      :style="{
        background: item.id <= currentStatus ? color || '#FC7247' : '#FFF2E3',
        borderColor: `${'transparent '.repeat(3)}${
          item.id <= currentStatus ? color || '#FC7247' : '#FFF2E3'
        }`,
      }"
      :class="{
        active: item.id <= currentStatus,
        reject: item.id === 7 || (item.id === 6 && !hasReject),
      }">
      <v-tooltip bottom color="grey darken-3" :text="item.name">
        <template #activator="{ props }">
          <div
            class="mr-1 ml-4 mt-1 mb-1 status-bar-btn"
            :style="`color: ${
              item.id > currentStatus ? '#C0C1C7' : 'white'
            };   display: block;
            width: 140px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;`"
            v-bind="props"
            @click="onSelect(item)">
            <svg
              v-if="showCheckIcon(item)"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="9"
              viewBox="0 0 12 9"
              fill="none">
              <path
                d="M4.37891 7.84375C4.57422 8.03906 4.90625 8.03906 5.10156 7.84375L10.8438 2.10156C11.0391 1.90625 11.0391 1.57422 10.8438 1.37891L10.1406 0.675781C9.94531 0.480469 9.63281 0.480469 9.4375 0.675781L4.75 5.36328L2.54297 3.17578C2.34766 2.98047 2.03516 2.98047 1.83984 3.17578L1.13672 3.87891C0.941406 4.07422 0.941406 4.40625 1.13672 4.60156L4.37891 7.84375Z"
                fill="white" />
            </svg>
            &nbsp;
            <Loader v-if="asyncLoading(`submit_${item.id}`)" :size="20" />
            <span v-else>{{ item.name }}</span>
            &nbsp;
            <v-menu>
              <template #activator="{ props }">
                <v-btn
                  v-if="item.arrow"
                  icon
                  variant="text"
                  size="small"
                  class="sublistArrow"
                  v-bind="props"
                  @click.stop="toggleSubStatuses(item)">
                  <v-icon
                    size="small"
                    :color="item.id === currentStatus ? 'white' : 'black'">
                    {{ 'mdi-menu-down' }}
                  </v-icon>
                </v-btn>
              </template>
              <v-list v-if="subStatusesOpen === item.select">
                <v-list-item
                  v-for="(sub, k) in subStatuses[item.select]"
                  :key="k"
                  @click="onSubSelect(sub)">
                  <v-icon icon="mdi-label" color="#fc7247" start></v-icon>

                  {{ sub.name }}
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
        <span>{{ item.name }}</span>
      </v-tooltip>
    </div>
  </div>
</template>
<script>
import Loader from '@/components/Loader.vue'
import { useSelect } from '@/utils/mixins/useSelect'
export default {
  components: {
    Loader,
  },
  setup() {
    return {
      ...useSelect(),
    }
  },
  props: {
    currentStatus: { type: Number },
    statuses: { type: Array },
    submit: { type: Function },
    flexAlignment: { type: String },
    autostatus: { type: Boolean },
    small: { type: Boolean, default: false },
    spreadItems: { type: Boolean, default: false },
    subStatuses: { type: Object, default: null },
    color: { type: String, default: '#FC7247' },
    hasReject: { type: Boolean, default: false },
  },
  data: () => ({
    subStatusesOpen: false,
  }),
  methods: {
    onSelect(item) {
      if (this.loadingState.length) return
      if ('select' in item && !item.arrow) {
        this.toggleSubStatuses(item)
      } else if (this.currentStatus !== item.id && !this.autostatus) {
        this.asyncAction(`submit_${item.id}`, null, () => this.submit(item.id))
      }
    },
    onSubSelect(item) {
      this.currentStatus !== item.id && !this.autostatus
        ? this.submit(item.id)
        : false
      this.subStatusesOpen = false
    },
    toggleSubStatuses(item) {
      if (this.subStatusesOpen === item.select) this.subStatusesOpen = false
      else this.subStatusesOpen = item.select
    },
    showCheckIcon(item) {
      if (typeof item.showCheckIcon === 'boolean') {
        return item.showCheckIcon
      } else {
        return item.id <= this.currentStatus
      }
    },
  },
}
</script>
<style lang="scss">
.status-bar-btn {
  text-transform: none;
  position: relative;
}
.tab-btn {
  background-color: #fff2e3;
  position: relative;
  flex-grow: 1;
  height: 35px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}
.tab-btn.active {
  //background-color: #FC7247;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab-btn:first-child {
  border-radius: 4px 0 0 4px;
}
.tab-btn:first-child::before,
.tab-btn:last-child::after {
  display: none;
}
.tab-btn::after {
  content: '';
  position: absolute;
  right: -14px;
  z-index: 2;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 17px 0 17px 14px;
  border-color: inherit;
}
.tab-btn::before {
  content: '';
  position: absolute;
  width: 0;
  left: 1px;
  height: 0;
  border-style: solid;
  border-width: 17px 0 17px 14px;
  border-color: transparent transparent transparent #fff;
}
.align-center {
  display: flex;
  align-items: center;
}
.status-dash {
  flex: 1 1 auto;
  height: 1px;
  margin-top: 5px;
}
.status-negative-list {
  z-index: 999;
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  top: 32px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.sublistArrow {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  &:hover {
    background: #dedede;
  }
}
.active.reject {
  background: rgb(231 71 71) !important;
}
</style>
