<template>
  <v-card :elevation="0">
    <div v-if="verificationList">
      <Filters :filters="filters" class="ml-2" />
      <SectionLoader
        v-if="asyncLoading('getFinancialDepartmentAnalyzes')"></SectionLoader>
      <v-fade-transition hide-on-leave style="margin-top: 10px">
        <div v-show="!asyncLoading('getFinancialDepartmentAnalyzes')">
          <v-data-table
            :headers="financialListHeaders"
            :items="financialDepartmentAnalyzes"
            class="mb-2 calc-table application-table --cust-cell-pd"
            mobileBreakpoint="860"
            dense
            hide-default-footer
            :items-per-page="-1"
            :hide-default-header="!financialDepartmentAnalyzes.length"
            @dblclick:row="navigateToAnalyze">
            <!-- eslint-disable-next-line -->
            <template #item.lessee="{ item }">
              <div class="d-flex align-center">
                <ALink
                  :text="
                    item.lessee.lesseeTypeId === 3
                      ? `ФОП ${item.lesseeName}`
                      : item.lesseeName
                  "
                  :route="getEntityRoute(item)">
                </ALink>
              </div>
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.created_at="{ item }">
              {{ humanDate(formatDate(item.created_at, true)) }}
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.status="{ item }">
              <div
                class="status d-flex justify-start pa-0"
                :style="{
                  color: statusColors[getSafe(() => item.status.id)],
                  width: '165px',
                }">
                <span v-if="item.status && item.status.id === 1"
                  >Не розглянуто</span
                >
                <span v-if="item.status && item.status.id === 2"
                  >На розгляді</span
                >
                <span v-if="item.status && item.status.id === 3"
                  >Доопрацювання</span
                >
                <span v-if="item.status && item.status.id === 4"
                  >Завершено</span
                >
                <span v-if="!item.status">---</span>
              </div>
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.frontManager="{ item }">
              {{ getContactInitials(item.frontManagerFio, { byName: true }) }}
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.analysisType="{ item }">
              <div class="d-flex align-center">
                <Benefit v-if="item.bestBenefit" class="mr-1" />
                <ProgramFSN v-if="item.programFSN" class="mr-1" />
                <span>{{ item.analysisType?.name }}</span>
              </div>
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.isConfirmation="{ item }">
              {{
                item.isConfirmation
                  ? `Підтверджено (${humanDate(
                      formatDate(item.dateConfirmation, true)
                    )})`
                  : 'Ні'
              }}
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.financialRiskLevel="{ item }">
              {{
                (item.financialRiskLevel
                  ? item.financialRiskLevel.name
                  : '---') +
                ` (${humanDate(formatDate(item.finishDate, true))})`
              }}
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.actions="{ item }">
              <TableCrud
                style="margin-right: -10px !important"
                :actionsArray="[
                  {
                    action: () =>
                      $router.push({
                        name: 'fd-project-verification',
                        params: { id: item.id },
                      }),
                    text: 'Відкрити',
                    icon: 'mdi-folder-open-outline',
                  },
                ]">
              </TableCrud>
            </template>
          </v-data-table>
          <Pagination
            :getItems="getFinancialDepartmentAnalyzes"
            :trigger="paginationTrigger" />
        </div>
      </v-fade-transition>
    </div>
    <router-view v-if="!verificationList"> </router-view>
  </v-card>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'
import ALink from '@/components/Link.vue'
import Pagination from '@/components/Pagination.vue'
import Filters from '@/components/Filters.vue'
import { urlGetFinancialMonitoringAnalyzes } from '@/pages/request'
import { formatDate, humanDate } from '@/utils/formatFunc'
import {
  getContactInitials,
  getResponsibleShortName,
  getSafe,
} from '@/utils/helperFunc'
import Benefit from '@/assets/svg/benefit.vue'
import { useSelect } from '@/utils/mixins/useSelect'
import { useFilter } from '@/utils/mixins/useFilter'
import { reactive } from 'vue'
import TableCrud from '@/components/table-crud.vue'
import { financialListHeaders } from '@/utils/tableHeaders'
import { fillDirectory } from '@/plugins/directory'
import ProgramFSN from '@/assets/png/programFSN.vue'
export default {
  components: {
    ProgramFSN,
    TableCrud,
    Benefit,
    SectionLoader,
    ALink,
    Pagination,
    Filters,
  },
  setup() {
    const filters = reactive({
      statusId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { label: 'Статус', items: 'financingMonitoringStatuses' },
      },
      financialRiskLevelId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { label: 'Оберіть рівень ризику', items: 'financialRiskLevels' },
      },
      dates: {
        value: null,
        component: 'DatePicker',
        attrs: { label: 'Період', range: true },
      },
      datesAnalysis: {
        value: null,
        component: 'DatePicker',
        attrs: {
          label: 'Період фiн монiторингу',
          range: true,
          keys: { startDate: 'startDateAnalysis', endDate: 'endDateAnalysis' },
        },
      },
      datesConfirmation: {
        value: null,
        component: 'DatePicker',
        attrs: {
          label: 'Період підтвердження',
          range: true,
          keys: {
            startDate: 'startDateConfirmation',
            endDate: 'endDateConfirmation',
          },
        },
      },
      frontManagerId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Фронт менеджер',
          items: 'frontManagers',
          itemTitle: u => getContactInitials(u, { isResponsible: true }),
        },
      },
      responsibleId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Відповідальний',
          items: 'leadAccessUsers',
          itemTitle: u => getContactInitials(u, { isResponsible: true }),
        },
        focus: () => fillDirectory('allUsers'),
      },
      search: { value: null },
    })
    return {
      ...useSelect(),
      ...useFilter(filters),
      filters,
    }
  },
  data() {
    return {
      financialListHeaders,
      financialDepartmentAnalyzes: [],
      statusColors: {
        1: '#62C14A',
        2: '#0098EE',
        3: '#FC7247',
        4: '#D30000',
        5: '#D30000',
        6: '#D30000',
      },
    }
  },
  computed: {
    verificationList() {
      return this.$route.name === 'financial-department'
    },
  },
  methods: {
    getContactInitials,
    getSafe,
    formatDate,
    humanDate,
    urlGetFinancialMonitoringAnalyzes,
    getResponsibleShortName,
    getEntityRoute(item) {
      const typeContractor = item.lessee.lesseeTypeId === 2
      const name = typeContractor ? 'contractors-form' : 'individuals-form'
      const id = item.lessee.lesseeId
      return { name, params: { id } }
    },
    navigateToAnalyze(e, row) {
      this.$router.push({
        name: 'fd-project-verification',
        params: { id: row.item.id },
      })
    },
    getFinancialDepartmentAnalyzes(page = 1) {
      const params = {
        page,
        filters: this.filtersQueryObj,
      }
      return this.asyncAction('getFinancialDepartmentAnalyzes', null, () => {
        return this.$axios
          .get(this.urlGetFinancialMonitoringAnalyzes(params))
          .then(res => {
            if (res.data.data) {
              this.financialDepartmentAnalyzes.splice(0)
              this.financialDepartmentAnalyzes.push(...res.data.data)
            }
            return res
          })
          .catch(this.$err)
      })
    },
  },
}
</script>

<style lang="scss">
.status {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 15px;
  padding: 0 16px;
  color: #fff;
}
.analys {
  position: relative;
}
.analys:hover + .analysis-type {
  display: block;
}
.analysis-type {
  display: none;
  position: absolute;
  background: white;
  padding: 7px;
  border-radius: 4px;
  z-index: 10000;
}
</style>
