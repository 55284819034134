<template>
  <div class="main">
    <FaqPageIcon />
    <div class="text">
      <span>Часті питання [FAQ]</span>
      <v-btn
        variant="text"
        size="small"
        :ripple="false"
        style="
          background: rgb(252, 114, 71);
          border-radius: 4px;
          margin-top: 10px;
        "
        @click="$emit('toggleAccess')">
        Почати ознайомлення
      </v-btn>
      <span class="mt-2 rights">® All rights reserved BEST24</span>
    </div>
  </div>
</template>

<script>
import FaqPageIcon from '@/assets/svg/FaqPageIcon.vue'
export default {
  components: { FaqPageIcon },
  emits: ['toggleAccess'],
}
</script>

<style scoped>
.main {
  position: relative;
  z-index: 100000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 2px;
}
.text {
  text-align: center;
  position: absolute;
  display: flex;
  flex-direction: column;
  font-size: 27px;
  color: #fff;
  font-weight: 700;
  margin-top: -30%;
}
.rights {
  font-size: 12px;
  font-weight: 400;
  color: #f0f0f0;
}
</style>
