const benefitAccountingAdvantages = () => {
  return [
    [
      {
        text: [
          {
            text: 'Пакет',
            margin: [0, 4, 0, 0],
          },
          {
            text: ' "Стандарт" ',
            margin: [0, 4, 0, 0],
            bold: true,
          },
          {
            text: 'включає:',
            margin: [0, 4, 0, 0],
          },
        ],
        margin: [0, 20, 0, 6],
        fontSize: 15,
        bold: true,
      },
    ],
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Технiчний асистанс BEST 24/7',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Планове технічне обслуговування (ТО) та його адміністрування з гарантованими знижками',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    [
      {
        text: [
          {
            text: 'Пакет',
            margin: [0, 4, 0, 0],
          },
          {
            text: ' "Бiзнес" ',
            margin: [0, 4, 0, 0],
            bold: true,
          },
          {
            text: 'включає',
            margin: [0, 4, 0, 0],
          },
          {
            text: ' "Стандарт"+: ',
            margin: [0, 4, 0, 0],
          },
        ],
        margin: [0, 40, 0, 6],
        fontSize: 15,
        bold: true,
      },
    ],
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Додаткове ТО, знижки до 20%',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Заміна деталей природнього зносу (гальмівні колодки/гальмівні диски/ходова підвіска/щитки склоочисників/АКБ/рульова), знижки до 20%',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          lineHeight: 1.5,
          text: 'Долив технічних рідин до норми: моторна олива/охолоджувальна рiдина/гальмiвна рiдина (окрiм склоомивача), знижки до 20%',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    [
      {
        text: [
          {
            text: 'Пакет',
            margin: [0, 4, 0, 0],
          },
          {
            text: ' "Премiум" ',
            margin: [0, 4, 0, 0],
            bold: true,
          },
          {
            text: 'включає',
            margin: [0, 4, 0, 0],
          },
          {
            text: ' "Бізнес"+: ',
            margin: [0, 4, 0, 0],
          },
        ],
        margin: [0, 40, 0, 6],
        fontSize: 15,
        bold: true,
      },
    ],
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          lineHeight: 1.5,
          text: 'Агрегатнi ремонти внаслiдок природнього зносу: двигун, КПШ(коробка перемикання швидкостей), редуктори, роздавальна коробка зі знижкою до 20%',
          margin: [0, 2, 0, 0],
        },
      ],
    },
  ]
}

export { benefitAccountingAdvantages }
