<template>
  <div class="mt-2">
    <FieldsColumnBlock title="Прикріплення документів">
      <template #default>
        <FilesUploader
          v-model:documents="agreementData.signedExtraAgreement"
          label="Договір додаткової угоди (підписаний)"
          :iconSize="26"
          single
          acceptedExt=".pdf"
          :readonly="readonly">
        </FilesUploader>

        <FilesUploader
          v-model:documents="agreementData.otherDocuments"
          label="Необов`язкові документи"
          :readonly="readonly"
          icon="mdi-file-document-multiple-outline">
        </FilesUploader>
      </template>
    </FieldsColumnBlock>
  </div>
</template>
<script>
import FilesUploader from '@/components/FilesUploader.vue'
import { mapState } from 'vuex'
import FieldsColumnBlock from '@/pages/contracts/components/FieldsColumnBlock.vue'

export default {
  components: { FieldsColumnBlock, FilesUploader },
  props: { agreement: { type: Object } },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    readonly() {
      return ![1, 5, 6].includes(this.user.roleId)
    },
    agreementData() {
      return this.agreement
    },
  },
}
</script>
