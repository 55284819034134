/* colors */
const style = {
  colors: {
    mainRed: '#d7514f',
    mainGrey: '#575a5b',
    darkGrey: '#202020',
  },
}

export { style }
