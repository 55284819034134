<template>
  <div>
    <modal
      v-model:dialogState="dialogState"
      v-model:dialog="dialog"
      v-model:dialogItem="dialogItem"
      :ODContact="ODContact"
      :dialogParams="dialogParams"
      :contact="contactData"
      @contractor-added="$emit('contractorAdded')"
      @delete-contractor="contractorId = null"
      @contact-update="getContactById()"
      @open-data-sync="assignOpenData(ODContact.registry)"
      @table-change="getContactById()"
      @edit-bank-account="editBankAccount($event)"
      @disable-bank-account-statuses="disableBS($event)">
    </modal>
    <section-loader v-if="pageLoad"> </section-loader>
    <div
      v-if="!pageLoad && !contactData.message"
      class="pt-0 pb-0"
      :style="`
        position: relative;
        align-items: start;`">
      <v-fade-transition hide-on-leave>
        <v-row class="mr-0 ml-0">
          <column-common-card
            :callback="getContactById"
            :contact="contactData"
            :readonly="readonly"
            :v="v$"
            @open-dialog="openDialog(...$event)">
          </column-common-card>
          <column-tabs
            v-if="!pageLoad"
            :contact="contactData"
            :readonly="readonly"
            :v="v$"
            @contact-update="getContactById()"
            @sync-with-open-data="syncWithOpenData()"
            @table-change="getContactById()"
            @open-dialog="openDialog(...$event)">
            <template #action-buttons>
              <action-buttons
                v-if="!readonly"
                class="mt-4"
                :confirm="submit"
                :cancel="getContactById"
                :cancelDisable="contactCache === cache || loading || initLoader"
                :confirmDisable="
                  contactCache === cache || loading || initLoader
                ">
              </action-buttons>
            </template>
          </column-tabs>
        </v-row>
      </v-fade-transition>
    </div>
    <div v-if="contactData.message">
      <span class="empty-data pt-6">
        {{ contactData.message }}
      </span>
    </div>
  </div>
</template>
<script>
import ObjectMapper from 'object-mapper'
import columnCommonCard from './common-card.vue'
import columnTabs from './tabs/Index.vue'
import modal from './dialog.vue'
import actionButtons from '@/components/action-buttons.vue'
import sectionLoader from '@/components/section-loader.vue'
import {
  urlContractorsType,
  urlGetContact,
  urlGetNationalList,
  urlContactUpdate,
  urlEDRFOPByInn,
} from '@/pages/request'
import {
  syncOpendataQueds,
  syncOpenDataContact,
  setCache,
  v$Notify,
  toFormatDate,
  backDate,
  getBeautyNum,
} from '@/utils/helperFunc'
import { mapPerson } from '@/pages/objects-map'
import { phoneMask } from '@/utils/masks'
import { mapState } from 'vuex'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { fillDirectory, getDirectory } from '@/plugins/directory'
export default {
  emits: ['contractorAdded'],
  components: {
    columnCommonCard,
    columnTabs,
    modal,
    actionButtons,
    sectionLoader,
  },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    // const dataValidation = { required, minLength: minLength(10) }

    // const birthday = this.contactData?.birthday ? dataValidation : {}
    // const extractOrExcerpt = this.contactData.fop ? { required } : {}

    // const fop = {
    //   date: this.contactData?.fop ? dataValidation : {},
    //   number: this.contactData?.fop
    //     ? { required, minLength: minLength(8) }
    //     : {},
    //   mainQuedId: this.contactData?.fop ? { required } : {},
    //   queds: this.contactData?.fop ? { required, minLength: minLength(1) } : {},
    //   extractOrExcerpt: extractOrExcerpt,
    // }
    const nationalityId = { required }
    const marriedId = this.contactData.familyStatusId === 1 ? { required } : {}

    return {
      contactData: {
        birthday: {},
        placeOfBirth: {},
        unprovedIncome: {},
        provedIncome: {},
        dependentsNumber: {},
        // fop: {
        //   ...fop,
        // },
        nationalityId: nationalityId,
        responsibleId: { required },
        marriedId: marriedId,
      },
    }
  },
  data() {
    return {
      contactData: {
        genderId: null,
        nationalityId: null,
        riskLevel: null,
        resident: 1,
        fop: null,
        contractor: null,
        phones: [],
        emails: [],
        messangers: [],
        addresses: [],
        current_accounts: [],
        careers: [],
        educations: [],
        birthday: null,
        applications: [],
        queds: [],
        mainQuedId: null,
        unprovedIncome: null,
        provedIncome: null,
        dependentsNumber: null,
      },
      ODContact: {},
      mapedContactData: {},
      dialog: false,
      dialogState: null,
      dialogItem: {},
      dialogParams: {},
      initLoader: false,
      pageLoad: true,
      pageLoadTimeOut: 280,
      contactId: null,
      cache: null,
      loading: false,
    }
  },
  computed: {
    ...mapState({
      contact: state => state.contact,
      selectItems: state => state.selectItems,
    }),
    readonly() {
      return !!this.contact.readOnly
    },
    computedName() {
      return `${
        this.contactData.surname ? this.contactData.surname + ' ' : ''
      }${this.contactData.name ? this.contactData.name[0] + '. ' : ''}${
        this.contactData.surname ? this.contactData.surname[0] + '.' : ''
      }`
    },
    innSame() {
      return this.$store.state.common.uniqueStr === this.contactData.inn
    },
    contactCache() {
      const c = JSON.parse(JSON.stringify(this.contactData))
      return setCache([c])
    },
  },
  methods: {
    urlContractorsType,
    urlGetContact,
    urlGetNationalList,
    urlContactUpdate,
    ObjectMapper,
    urlEDRFOPByInn,
    syncOpendataQueds,
    syncOpenDataContact,
    phoneMask,
    toFormatDate,
    backDate,
    setContactCache() {
      const c = JSON.parse(JSON.stringify(this.contactData))
      this.cache = setCache([c])
    },
    syncWithOpenData() {
      if (!this.contactData.inn) {
        this.$setSnackbar({ text: 'Вкажіть IНН', color: 'warning' })
        return
      }
      return this.$axios
        .get(this.urlEDRFOPByInn(this.contactData.inn))
        .then(res => {
          this.mapedContactData = this.ObjectMapper(res?.data?.data, mapPerson)
          Object.assign(this.ODContact, res.data.data)
          this.openDialog('syncWithOpenData', {}, { dialogwidth: 1100 })
        })
        .catch(err => {
          if (err.response.data?.reason === 'Limit reached, Payment Required') {
            this.$setSnackbar({
              text: 'Помилка синхронiзацiї. Перевищено лiмiт запитiв',
              color: 'warning',
            })
          } else if (err.response.data?.reason === 'Not found') {
            this.$setSnackbar({
              text: 'За вказаним кодом нiчого не знайдено',
              color: 'warning',
            })
          } else {
            this.$err(err.response.data)
          }

          this.openDataLoading = false
        })
    },
    async assignOpenData(data) {
      this.$setSnackbar({ text: 'Синхронiзовано' })

      const [bYear, bMonth, bDay] = (
        this.mapedContactData.birthday || ''
      ).split('-')
      const [fYear, fMonth, fDay] = (
        this.mapedContactData?.fop?.date || ''
      ).split('-')

      this.mapedContactData.birthday = `${bDay}.${bMonth}.${bYear}`
      if (this.mapedContactData?.fop) {
        this.mapedContactData.fop.date = `${fDay}.${fMonth}.${fYear}`
      }

      Object.assign(this.contactData, this.mapedContactData)
      this.genderId = this.syncGender(data?.sex)

      data?.code && this.fop
      data?.location.includes('Україна') && (this.nationalityId = 1)

      await fillDirectory('quedList')
      this.syncOpendataQueds(
        data?.activities,
        this.contactData,
        getDirectory('quedList')
      )
      this.syncOpenDataContact(
        data.phones,
        this.contactData,
        'mainPhone',
        'phones',
        this.phoneMask
      )
      this.syncOpenDataContact(
        data.emails,
        this.contactData,
        'mainEmail',
        'emails'
      )

      this.submit()
    },
    synccontactId() {
      this.contactId = parseInt(this.$route?.params?.id)
    },
    setInitials() {
      this.name = this.computedName
    },
    getContactById() {
      this.initLoader = true
      return this.$axios
        .get(this.urlGetContact(this.contactId))
        .then(res => {
          if (res?.data?.message) {
            this.$setSnackbar({ text: this.contactData.message })
            return
          }

          this.mapQueds(res)
          this.$store.commit('assignContact', res.data)
          Object.assign(this.contactData, res.data)
          this.contactData.genderId = res.data?.gender?.id
          this.contactData.nationalityId = res.data?.nationality?.id
          this.contactData.riskLevel = res.data?.risk_level_fin_monitoring
          if (res.data?.unprovedIncome) {
            this.contactData.unprovedIncome = getBeautyNum(
              res.data?.unprovedIncome || 0,
              {
                float: 2,
              }
            )
          }
          if (res.data?.provedIncome) {
            this.contactData.provedIncome = getBeautyNum(
              res.data?.provedIncome || 0,
              {
                float: 2,
              }
            )
          }
          this.contactData.inn &&
            this.$store.commit('setUnique', this.contactData.inn)
          this.setInitials()
          this.setBreadscrumb()
          this.setContactCache()
          setTimeout(() => {
            this.pageLoad = false
          }, this.pageLoadTimeOut)
          this.initLoader = false
          return res
        })
        .catch(err => {
          if (err) {
            this.$router.push({ name: 'individuals' })
          }
        })
    },
    openDialog(state, dialogItem = {}, params = {}, cb) {
      this.dialogItem = dialogItem
      this.dialog = true
      this.dialogState = state
      this.dialogParams = params
      typeof cb === 'function' && cb()
    },
    mapQueds(res) {
      if (res.data.fop && res.data.fop.queds !== null) {
        const queds = res.data.fop.queds.map(v => v.id)
        this.contactData.fop?.queds.splice(0)
        this.contactData.fop?.queds.push(...queds)
      }
    },
    setBreadscrumb() {
      this.$store.commit('setBreadScrumbPart', [null, this.name, null])
    },
    editBankAccount(item) {
      const index = this.findIndex(item)
      this.contactData.current_accounts.splice(index, 1, item)
    },
    disableBS(id) {
      // disable bank account statuses
      this.contactData.current_accounts.forEach(
        v => v.accountId !== id && (v.status = false)
      )
    },
    findIndex(item) {
      const accountId = item.accountId
      let index
      this.contactData.current_accounts.find(
        (v, i) => v.accountId === accountId && (index = i)
      )
      return index
    },
    // checkPhone(phone) {
    //   return this.$axios
    //     .post(urlCheckPhoneExistence(), { phone: phone })
    //     .then(res => {
    //       if (
    //         res.data.status === true &&
    //         parseInt(this.$route.params.id) !== res.data.contactId
    //       ) {
    //         phone = null
    //         this.contactAlreadyInUse('телефон', res.data)
    //       }
    //
    //       return res.data.status
    //     })
    //     .catch(this.$err)
    // },
    // checkEmail(email) {
    //   return this.$axios
    //     .post(urlCheckEmailExistence(), { email: email })
    //     .then(res => {
    //       if (
    //         res.data.status === true &&
    //         parseInt(this.$route.params.id) !== res.data.contactId
    //       ) {
    //         email = null
    //         this.contactAlreadyInUse('email', res.data)
    //       }
    //
    //       return res.data.status
    //     })
    //     .catch(this.$err)
    // },
    contactAlreadyInUse(msgStartText, res) {
      const type = `Вказаний ${msgStartText} вже використовується.`
      const entity = `Сутність: ${res.name}.`
      const responsible = `Відповідальний: ${res.responsible}`

      const message = [type, entity, responsible].join(' ')
      this.$setSnackbar({ text: message, color: 'warning' })
    },
    submit() {
      if (
        this.contactData.resident &&
        this.contactData.inn &&
        this.contactData.inn?.length !== 10
      ) {
        this.$setSnackbar({
          text: 'Не вiрний iдентифiкацiйний номер',
          color: 'error',
        })
        return
      }
      if (this.v$.$invalid) {
        this.v$.$anyError
        this.v$.$touch()
        return v$Notify({ ...this.v$.contactData }, 'contact', { deep: true })
      }
      const req = Object.clone(this.contactData)

      if (this.contactData.fop && this.contactData.fop.queds !== null) {
        req.fop = req.fop || {}
        req.fop.queds = this.contactData.fop.queds.map(q =>
          typeof q === 'number' ? q : q.id
        )
      } else {
        req.fop = req.fop || {}
        req.fop.queds = []
      }

      if (req.unprovedIncome) {
        const unprovedIncomeFloat = parseFloat(
          req.unprovedIncome.replace(/\s/g, '').replace(',', '.')
        )
        req.unprovedIncome = unprovedIncomeFloat
      }
      if (req.provedIncome) {
        const provedIncomeFloat = parseFloat(
          req.provedIncome.replace(/\s/g, '').replace(',', '.')
        )
        req.provedIncome = provedIncomeFloat
      }

      this.loading = true

      this.innSame && delete req.inn
      delete req.responsible
      return this.$axios
        .post(this.urlContactUpdate(this.contactId), req)
        .then(res => {
          if (res?.data?.message) {
            return Promise.reject(res.data.message)
          }
          Object.assign(this.contactData, res.data)
          this.$setSnackbar({ text: 'Данi оновлено' })
          this.$store.commit('refreshPage')
          this.loading = false
          return res
        })
        .catch(err => {
          if (err?.response?.data?.errors?.inn[0]) {
            this.$setSnackbar({
              text: err.response.data.errors.inn[0],
              color: 'error',
            })
          }
          this.$err(err)
          this.loading = false
        })
    },
    syncGender(gender) {
      switch (gender) {
        case 'male':
          return 1
        case 'female':
          return 2
        default:
          return null
      }
    },
    syncPersonInfo(initials) {
      const [lastName, firstName, patronymic] = initials.split(' ')
      this.contactData.surname = lastName
      this.contactData.name = firstName
      this.contactData.patronymic = patronymic
    },
    getContact() {
      this.pageLoad = true
      this.setInitials()
      this.synccontactId()
      this.getContactById()
    },
  },
  watch: {
    'contact.addresses': function (addresses) {
      this.contactData.addresses = addresses
    },
    'contactData.fop': function (val) {
      if (!val) {
        this.contactData.declarationForInvoice = null
        this.contactData.copiesOfContracts = null
      }
    },
    'contactData.isInn': function (val) {
      if (!val) {
        this.contactData.inn = null
        this.contactData.identificationNumber = null
      }
    },
    loading(val) {
      if (!val) setTimeout(() => this.setContactCache(), 1000)
    },
    initLoader(val) {
      if (!val) setTimeout(() => this.setContactCache(), 500)
    },
    '$route.name': function () {
      this.setBreadscrumb()
    },
    name(initials) {
      initials &&
        initials !== this.computedName &&
        this.syncPersonInfo(initials)
    },
    computedName(val) {
      this.name = val
    },
    '$route.params.id': function (id) {
      if (id) {
        this.getContact()
      }
    },
  },
  created() {
    this.$store.commit('setCustomBreadScrumbState', true)
  },
  mounted() {
    this.getContact()
  },
}
</script>
