<template>
  <div>
    <v-fade-transition hide-on-leave>
      <div v-if="choosedList">
        <v-data-table
          :headers="tHead"
          :items="tasks"
          hide-default-footer
          :items-per-page="50"
          :hide-default-header="!tasks.length"
          class="pb-2 pt-1"
          :search="string"
          mobile-breakpoint="960"
          dense>
          <template #no-data>
            <no-data></no-data>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.isDone="{ item }">
            {{ item.isDone ? 'Завершено' : 'Не завершено' }}
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.from="{ item }">
            <ALink
              v-if="item.entityTypeId === 1"
              :text="item.name"
              :route="{
                name: 'individuals-form',
                params: { id: item.entityId },
              }">
            </ALink>
            <ALink
              v-if="item.entityTypeId === 2"
              :text="item.name"
              :route="{
                name: 'contractors-form',
                params: { id: item.entityId },
              }">
            </ALink>
            <ALink
              v-if="item.entityTypeId === 3"
              :text="item.name"
              :route="{
                name: 'edit-application',
                params: { applicationId: item.entityId },
              }">
            </ALink>
            <ALink
              v-if="item.entityTypeId === 4"
              :text="item.name"
              :route="{ name: 'edit-lead', params: { id: item.entityId } }">
            </ALink>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.date="{ item }">
            {{ toFormatDate(item.date, false, true) }}
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.user="{ item }">
            {{ getResponsibleShortName(item.user) }}
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.actions="{ item }">
            <TableCrud
              :actionsArray="[
                {
                  action: () => openDialog('full-task-plan-dialog', item),
                  text: 'Переглянути',
                  icon: 'mdi-eye',
                },
                {
                  action: () =>
                    $router.push(getRoute(item.entityId, item.entityTypeId)),
                  text: 'Перейти',
                  icon: 'mdi-folder-open-outline',
                },
              ]">
            </TableCrud>
          </template>
        </v-data-table>
      </div>
    </v-fade-transition>

    <v-fade-transition>
      <div v-if="choosedCalendar">
        <v-calendar
          ref="calendar"
          v-model="value"
          class="pr-5 pl-5"
          :events="formatedTasks"
          :event-color="t => t.color"
          locale="uk-UA"
          :weekdays="weekday"
          :view-mode="type">
          <template #event="{ event }">
            <v-menu
              v-model="menu[event.id]"
              :close-on-content-click="false"
              offset-x>
              <v-card
                color="grey lighten-4"
                min-width="350px"
                flat
                style="color: black !important">
                <v-toolbar :color="event.color" dark>
                  <v-toolbar-title>{{ event.title }}</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>

                <v-card-text>
                  <span>{{ event.details }}</span>

                  <div class="d-flex align-center">
                    <span style="margin-right: 5px; font-weight: bold"
                      >Тип:</span
                    >
                    <p>
                      {{
                        event.lead
                          ? 'Лiд'
                          : '' || event.application
                          ? 'Заявка'
                          : ''
                      }}
                    </p>
                  </div>

                  <div class="d-flex align-center">
                    <span style="margin-right: 5px; font-weight: bold"
                      >Статус:</span
                    >
                    <p>{{ event.isDone ? 'Завершено' : 'Не завершено' }}</p>
                  </div>
                  <div class="d-flex align-center">
                    <span style="margin-right: 5px; font-weight: bold"
                      >Відповідальний:</span
                    >
                    <p>{{ event.user }}</p>
                  </div>
                  <div class="d-flex align-center">
                    <span style="margin-right: 5px; font-weight: bold"
                      >Заплановано на:</span
                    >
                    <p>{{ event.date }}</p>
                  </div>
                  <div class="d-flex align-center">
                    <span style="margin-right: 5px; font-weight: bold"
                      >Створено:</span
                    >
                    <p>{{ event.created_at }}</p>
                  </div>
                  <div class="d-flex align-center">
                    <span style="margin-right: 5px; font-weight: bold"
                      >Остання редакцiя:</span
                    >
                    <p>{{ event.updated_at }}</p>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    variant="text"
                    color="secondary"
                    @click="menu[event.id] = false">
                    Скасувати
                  </v-btn>
                </v-card-actions>
              </v-card>

              <template #activator="{ props }">
                <v-chip
                  :color="event.color"
                  variant="flat"
                  size="small"
                  class="ml-1"
                  v-bind="props">
                  {{ event.title }}
                </v-chip>
              </template>
            </v-menu>
          </template>
        </v-calendar>
      </div>
    </v-fade-transition>
  </div>
</template>
<script>
import ALink from '@/components/Link.vue'
import noData from '@/components/no-table-data.vue'
import TableCrud from '@/components/table-crud.vue'
import {
  tableDateSort,
  toFormatDate,
  getContactInitials,
  getResponsibleShortName,
} from '@/utils/helperFunc'
export default {
  components: { TableCrud, noData, ALink },
  props: {
    tasksView: { type: Number },
    tHead: { type: Array, default: () => [] },
    tasks: { type: Array, default: () => [] },
  },
  data: () => ({
    selectedOpen: false,
    selectedElement: null,
    selectedEvent: {},
    string: null,
    // new
    menu: {},
    type: 'month',
    types: ['month', 'week', 'day'],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    weekdays: [
      { title: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
      { title: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
      { title: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
      { title: 'Mon, Wed, Fri', value: [1, 3, 5] },
    ],
    value: [new Date()],
    events: [],
    colors: [
      'blue',
      'indigo',
      'deep-purple',
      'cyan',
      'green',
      'orange',
      'grey darken-1',
    ],
    titles: [
      'Meeting',
      'Holiday',
      'PTO',
      'Travel',
      'Event',
      'Birthday',
      'Conference',
      'Party',
    ],
  }),
  computed: {
    choosedCalendar() {
      return this.tasksView === 2
    },
    choosedList() {
      return this.tasksView === 1
    },
    formatedTasks() {
      return this.tasks.map(t =>
        this.modifyTask(t, {
          start: new Date(t.date),
          end: new Date(t.date),
        })
      )
    },
  },
  methods: {
    getResponsibleShortName,
    tableDateSort,
    toFormatDate,
    getContactInitials,
    getRoute(id, typeId) {
      switch (typeId) {
        case 1:
          return { name: 'individuals-form', params: { id } }
        case 2:
          return { name: 'contractors-form', params: { id } }
        case 3:
          return { name: 'edit-application', params: { applicationId: id } }
        case 4:
          return { name: 'edit-lead', params: { id: id } }
        default:
          return { name: 'contractors-form', params: { id } }
      }
    },
    modifyTask(t, { start, end }) {
      return {
        id: t.id,
        title: t.taskMessage,
        start: start,
        end: end,
        color: this.colors[this.rnd(0, this.colors.length - 1)],
        allDay: false,
        timed: true,
        taskObj: t,
        isDone: t.isDone,
        user: getContactInitials(t.user, { isResponsible: true }),
        date: toFormatDate(t.date),
        created_at: toFormatDate(t.created_at),
        updated_at: toFormatDate(t.updated_at),
        lead: t.leadId,
        application: t.applicationId,
      }
    },
    openCard(item, e) {
      this.selectedElement = e.target
      this.selectedEvent = item
      setTimeout(() => {
        this.selectedOpen = true
      }, 0)
    },
    openDialogTask(action, item = {}, params = {}) {
      this.$store.commit('setDialog', {
        title: 'Подiя',
        dialogItem: item,
        params: params,
        action: action,
      })
    },
    openDialog(action, item = {}) {
      this.$store.commit('setDialog', {
        title: 'Подiя',
        dialogItem: item,
        action: action,
        params: { hideActionsButtons: true },
      })
    },
    viewDay({ date }) {
      this.value = date
      this.type = 'day'
    },
    getEventColor(event) {
      return event.color
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    setToday() {
      this.value = ''
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
  },
}
</script>
