<template>
  <list-section
    :title="'Представник'"
    :addActionTitle="'Додати представника'"
    :addAction="addRepresentative"
    :addActionDisabled="readonly">
    <template #body>
      <div>
        <v-data-table
          :headers="representativeHeaders"
          :items="localRepresentatives"
          dense
          hide-default-footer
          class="custom-table head-line"
          :hide-default-header="!localRepresentatives.length">
          <!-- eslint-disable-next-line -->
          <template #item.name="{ item }">
            <ALink
              style="font-size: 13px"
              :text="
                getContactInitials(item.representative.fullName, {
                  byName: true,
                })
              "
              :route="{
                name: 'individuals-form',
                params: { id: item.representativeId },
              }">
            </ALink>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.date="{ item }">
            {{ toFormatDate(item.date) }}
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.endDate="{ item }">
            {{ toFormatDate(item.endDate) }}
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.actions="{ item }">
            <div style="margin-right: -10px">
              <TableCrud
                :actionsArray="[
                  {
                    action: () => editRepresentative(item),
                    text: 'Редагувати',
                    icon: 'mdi-pencil',
                    disabled: readonly,
                  },
                  {
                    action: () => deleteRepresentative(item.id),
                    text: 'Видалити',
                    icon: 'mdi-delete',
                    disabled: readonly,
                  },
                ]">
              </TableCrud>
            </div>
          </template>
        </v-data-table>
      </div>
    </template>
  </list-section>
</template>

<script>
import listSection from '@/components/list-section.vue'

import ALink from '@/components/Link.vue'
import { urlRepresentativeDelete } from '@/pages/request'
import { getContactInitials, toFormatDate } from '@/utils/helperFunc'
import { useSelect } from '@/utils/mixins/useSelect'
import TableCrud from '@/components/table-crud.vue'

export default {
  components: {
    TableCrud,
    listSection,
    ALink,
  },
  setup() {
    return {
      ...useSelect(),
    }
  },
  props: {
    representatives: { type: Array },
    contactId: { type: Number },
    readonly: { type: Boolean },
  },
  data() {
    return {
      localRepresentatives: [],
      representativeHeaders: [
        { title: 'ПІБ', key: 'name' },
        { title: 'Тип документу', key: 'documentType.name' },
        { title: 'Номер', key: 'documentInfo' },
        { title: 'Дата', key: 'date' },
        { title: 'Дата закінчення', key: 'endDate' },
        { title: 'Дії', key: 'actions', align: 'end' },
      ],
    }
  },
  methods: {
    getContactInitials,
    toFormatDate,
    addRepresentative() {
      this.$store.commit('setDialog', {
        title: 'Додати представника',
        action: 'addRepresentative',
        params: {
          contactId: this.contactId,
          cb: val => {
            this.localRepresentatives = [...this.localRepresentatives, val]
          },
        },
      })
    },
    editRepresentative(item) {
      this.$store.commit('setDialog', {
        title: 'Редагувати представника',
        action: 'editRepresentative',
        dialogItem: item,
        params: {
          contactId: this.contactId,
          cb: val => {
            this.localRepresentatives = this.localRepresentatives.map(r =>
              r.id === val.id ? val : r
            )
          },
        },
      })
    },
    deleteRepresentative(id) {
      return this.asyncAction('deleteRepresentative', null, async () => {
        await this.$axios.delete(urlRepresentativeDelete(id)).then(() => {
          this.localRepresentatives = this.localRepresentatives.filter(
            r => r.id !== id
          )
        })
      })
    },
  },
  created() {
    this.localRepresentatives = Object.clone(this.representatives || [])
  },
}
</script>

<style lang="scss" scoped></style>
