<template>
  <div>
    <v-autocomplete
      v-model="curAccount"
      hide-details
      placeholder="Оберіть зі списку"
      :item-title="formatAccount"
      item-value="id"
      :items="dialog.dialogItem.accounts || []"
      :error-messages="curAccountErr"
      @blur="v$.curAccount.$touch()">
    </v-autocomplete>
  </div>
</template>

<script>
import { required } from '@vuelidate/validators'
import { openDocument, setErrHandler } from '@/utils/helperFunc'
import { urlBankDetailsPrintedForm } from '@/pages/request'
import { useVuelidate } from '@vuelidate/core'

export default {
  props: {
    dialog: { type: Object },
  },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      curAccount: { required },
    }
  },
  data: () => ({
    curAccount: null,
  }),
  computed: {
    validation() {
      return { v$: this.v$, name: 'selectPrintedForm' }
    },
    curAccountErr() {
      return setErrHandler(this.v$?.curAccount)
    },
  },
  methods: {
    formatAccount(item) {
      return `${item.bankName} (${item.accountNumber})`
    },
    submit() {
      return this.$axios
        .get(
          urlBankDetailsPrintedForm(
            this.dialog.dialogItem.contractId,
            this.curAccount
          )
        )
        .then(res => {
          if (res.data.message)
            return this.$setSnackbar({
              text: res.data.message,
              color: 'warning',
            })
          if (res.data) {
            openDocument({
              url: res.data.url,
              text: 'Додаткова угода до договору(реквізити)',
            })
          }
        })
    },
  },
}
</script>

<style scoped></style>
