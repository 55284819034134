<template>
  <div>
    <div v-if="subtenants.length">
      <v-tabs
        v-model="currentTab"
        hide-slider
        :show-arrows="subtenants.length > 1"
        :disabled="subtenants.length === 1">
        <div
          class="d-flex align-center"
          style="position: relative; max-width: 90%">
          <v-tab
            v-for="(subtenant, idx) in subtenants"
            :key="
              subtenant
                ? `${subtenant.entityId || null}${subtenant.entityTypeId}`
                : null
            "
            :value="idx">
            <div class="d-flex align-center p-2">
              <span>{{ getSubtenantName(subtenant) }}</span>
            </div>
          </v-tab>
        </div>
      </v-tabs>
      <v-tabs-window v-model="currentTab">
        <v-tabs-window-item
          v-for="(subtenant, idx) in subtenants"
          :key="
            subtenant ? `${subtenant.entityId}${subtenant.entityTypeId}` : null
          "
          :value="idx">
          <Subtenant :subtenant="subtenant" :project="project" :value="idx" />
        </v-tabs-window-item>
      </v-tabs-window>
    </div>
    <div v-else style="font-size: 1.25rem">Суборендатори відсутні</div>
  </div>
</template>

<script>
import Subtenant from './Subtenant.vue'
import { mapState } from 'vuex'

export default {
  emits: ['dataChanged'],
  components: {
    Subtenant,
  },
  props: {
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      currentTab: 0,
    }
  },
  computed: {
    ...mapState({
      project: state => state.projects.project,
      slider: state => state.projects.slider,
    }),
    projectData() {
      return this.project
    },
    subtenants() {
      const project = { ...(this.project || {}), slider: this.slider }
      const subtenants = []

      if (!project || !project.slider || !project.slider.subtenants) {
        return subtenants
      }

      // const sliderGuarantors = project.slider.guarantors

      const sliderSubtenants = project.slider.subtenants

      for (const subtenant of sliderSubtenants) {
        if (subtenant.contact) {
          subtenants.push({
            id: subtenant.id,
            entityId: subtenant.entityId,
            entityTypeId: subtenant.entityTypeId,
            fullName: subtenant.contact.fullName,
            subtenantApplicationForm: subtenant.subtenantApplicationForm,
            subtenantApplicationFormSigned:
              subtenant.subtenantApplicationFormSigned,
          })
        } else if (subtenant.contractor) {
          subtenants.push({
            id: subtenant.id,
            entityId: subtenant.entityId,
            entityTypeId: subtenant.entityTypeId,
            shortName: subtenant.contractor.shortName,
            subtenantApplicationForm: subtenant.subtenantApplicationForm,
            subtenantApplicationFormSigned:
              subtenant.subtenantApplicationFormSigned,
          })
        }
      }

      return subtenants
    },
  },
  methods: {
    emitDataChanged() {
      return this.$emit('dataChanged')
    },
    getSubtenantName(subtenant) {
      return subtenant.entityTypeId === 2
        ? subtenant.shortName
        : subtenant.fullName
    },
  },
}
</script>

<style scoped>
.v-tab {
  padding: 10px 10px !important;
  margin-right: 15px;
  border: 1px solid #eaeaea;
  background: #eaeaea;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  font-size: 13px !important;
}
.v-tab.v-tab-item--selected {
  background: #f2f5fa;
  border: 1px solid #1c1d21;
  box-shadow: 0 2px 8px rgb(0 0 0 / 4%);
  border-radius: 4px;
}

.v-tabs-slider-wrapper {
  display: none !important;
}
</style>
