<template>
  <div>
    <v-row class="pr-3">
      <v-col :cols="12" :md="6">
        <LeasingParticipant
          v-model:signatoryId="serviceContract.lessorSignatoryId"
          v-model:basisId="serviceContract.lessorBasisId"
          label="Лізингодавець"
          :typeId="2"
          :participant="lessor"
          :v$="{
            contactId: $v.serviceContract.lessorSignatoryId,
            basisId: $v.serviceContract.lessorBasisId,
          }"
          :readonly="readonly"
          single
          details />
      </v-col>
      <v-col :cols="12" :md="6" :class="`${mobile ? '' : 'pl-0'}`">
        <LeasingParticipant
          v-model:signatories="serviceContract.lesseeSignatories"
          label="Лізингоотримувач"
          :typeId="serviceContract.lesseeTypeId"
          :participant="lessee"
          :v$="$v.serviceContract.lesseeSignatories"
          :readonly="readonly"
          details />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import LeasingParticipant from '@/pages/contracts/components/leasingParticipant/LeasingParticipant.vue'
import { mapState } from 'vuex'
export default {
  components: { LeasingParticipant },
  props: {
    readonly: { type: Boolean },
    mobile: { type: Boolean },
  },
  computed: {
    ...mapState({
      serviceContract: state => state.contracts.serviceContract,
      lessee: state => state.contracts.serviceContract.lessee,
      lessor: state => state.contracts.serviceContract.lessor,
      $v: state => state.contracts.$vService,
    }),
  },
}
</script>
