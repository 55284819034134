<template>
  <div class="notification">
    <div class="d-flex justify-space-between align-center">
      <span class="d-block"
        >{{ dialogData?.detail_call?.type_call?.name }} дзвінок</span
      >
      <div>
        <v-btn variant="text" size="x-small" icon @click="openDialog">
          <v-icon size="large">mdi-arrow-collapse</v-icon>
        </v-btn>
        <v-btn variant="text" size="x-small" icon @click="closeDialog">
          <v-icon size="large">mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
    <div class="data d-flex align-center">
      <span>{{ dialogData?.detail_call?.phone }}</span>
      <span
        v-if="dialogData?.detail_call && dialogData.detail_call.telephony"
        class="ml-1"
        >({{ dialogData?.detail_call?.telephony?.name }})</span
      >
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'CollapseNotification',
  emits: ['open-dialog', 'close'],
  props: {
    dialogData: { type: Object, required: true },
  },
  methods: {
    closeDialog() {
      this.$emit('close', true)
    },
    openDialog() {
      this.$emit('open-dialog', true)
    },
  },
}
</script>

<style scoped lang="scss">
.notification {
  background: #093a61;
  border: 1px solid grey;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10000000;
  padding: 9px 15px;
  color: white;
  border-radius: 7px;
  width: 300px;
  font-size: 13px;
  .data {
    border-top: 1px solid #ffffff42;
    margin-top: 7px;
    padding-top: 5px;
  }
}
</style>
