<template>
  <v-sheet class="mb-10">
    <div data-readonly-wrapper="true">
      <v-calendar
        ref="calendar"
        v-model="value"
        class="pr-5 pl-5"
        :weekdays="weekday"
        :view-mode="type"
        :events="formatedTasks"
        locale="uk-UA">
        <template #event="{ event }">
          <v-menu
            v-model="menu[event.id]"
            :close-on-content-click="false"
            offset-x>
            <event-card
              :selectedEvent="event"
              :selectedOpen="event"
              @event-edit="$emit('eventEdit', $event)"
              @event-delete="$emit('eventDelete', $event)"
              @change-selected-open="selectedOpen = $event"
              @close="menu[event.id] = false" />
            <template #activator="{ props }">
              <v-chip
                :color="getEventColor(event)"
                variant="flat"
                size="small"
                class="ml-1"
                v-bind="props">
                {{ event.title }}
              </v-chip>
            </template>
          </v-menu>
        </template>
      </v-calendar>
    </div>
  </v-sheet>
</template>
<script>
import eventCard from '@/pages/activities/event-card.vue'
import { getContactInitials, toFormatDate } from '@/utils/helperFunc'
import { parse } from 'date-fns'

export default {
  components: { eventCard },
  emits: ['eventEdit', 'eventDelete'],
  props: {
    tasks: { type: Array, default: () => [] },
  },
  data: () => ({
    menu: {},
    value: [new Date()],
    type: 'month',
    types: [
      { text: 'Мiсяць', value: 'month' },
      { text: 'Недiля', value: 'week' },
      { text: 'День', value: 'day' },
    ],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    weekdays: [
      { title: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
      { title: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
      { title: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
      { title: 'Mon, Wed, Fri', value: [1, 3, 5] },
    ],
    today: '2019-01-08',
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    calendarMounted: false,
    paginationState: { month: null, year: null },
  }),
  computed: {
    formatedTasks() {
      return this.tasks.map(t => this.modifyTask(t))
    },
  },
  methods: {
    modifyTask(t) {
      const parseDate = dateString =>
        parse(dateString, 'dd.MM.yyyy HH:mm', new Date())
      return {
        id: t.id,
        title: t.title,
        start: parseDate(t.startDate),
        end: parseDate(t.endDate),
        color: this.getEventColor(t),
        allDay: false,
        timed: true,
        taskObj: t,
        isDone: t.isDone,
        user: getContactInitials(t.user, { isResponsible: true }),
        date: toFormatDate(t.startDate),
        created_at: toFormatDate(t.created_at),
        updated_at: toFormatDate(t.updated_at),
        lead: t.leadId,
        application: t.applicationId,
        ...t,
      }
    },
    getEvents() {
      return null
    },
    getEventColor(event) {
      return this.switchEventColor(event.status.id)
    },
    switchEventColor(id) {
      if (id === 1) return '#6495ed'
      if (id === 2) return '#4faeb3'
      if (id === 3) return '#ca6e6e'
    },
    getBtnName() {
      return this.types.find(v => v.value === this.type).text
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => {
          this.selectedOpen = true
        }, 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    viewDay({ date }) {
      this.focus = date
      this.type = 'day'
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
  },
}
</script>
