// import store from '@/store/index.js'
import { getPropByPath } from '@/utils/helperFunc'
import { ref } from 'vue'
import { useStore } from 'vuex'

export function useSelect() {
  const loadingState = ref([])
  const store = useStore()

  const asyncAction = async (action, payload = null, func = null) => {
    if (!(action in store._actions || Array.isArray(action)) && !func) return

    const setLoading = a => {
      if (!loadingState.value.includes(a)) loadingState.value.push(a)
    }

    const removeLoading = a => {
      loadingState.value = loadingState.value.filter(act => act !== a)
    }

    if (Array.isArray(action)) {
      const promises = action.map(async a => {
        setLoading(a)
        try {
          await store.dispatch(a)
        } catch (e) {
          console.error(`Error dispatching action ${a}:`, e)
        } finally {
          removeLoading(a)
        }
      })
      await Promise.all(promises)
    } else {
      setLoading(action)
      try {
        const f = func || (() => store.dispatch(action, payload))
        const res = await f(payload)
        return res
      } catch (e) {
        console.error(`Error dispatching action ${action}:`, e)
      } finally {
        removeLoading(action)
      }
    }
  }

  const directoryItems = (items, def = null) => {
    const arr =
      typeof items === 'string'
        ? getPropByPath(store.state.selectItems, items) ||
          getPropByPath(store.getters, items)
        : items
    if (arr?.length) return arr
    else if (def) return Array.isArray(def) ? def : [def]
    else return []
  }

  const asyncLoading = action => {
    return loadingState.value.includes(action)
  }

  const setLoading = action => {
    loadingState.value = [...loadingState.value, action]
  }

  const removeLoading = action => {
    loadingState.value = loadingState.value.filter(l => l !== action)
  }

  return {
    loadingState,
    asyncAction,
    directoryItems,
    asyncLoading,
    setLoading,
    removeLoading,
  }
}
