<template>
  <v-card-text class="sales-plan">
    <SectionLoader v-if="loading"></SectionLoader>
    <v-fade-transition hide-on-leave>
      <div
        v-if="Object.keys(managersPlan).length && !loading"
        style="min-height: 230px">
        <v-row>
          <v-col :cols="12" :sm="9" class="pt-0 pb-0">
            <v-tabs
              v-model="currentTab"
              class="application-tabs"
              show-arrows
              :height="38"
              slider-color="red"
              @change="slide = true">
              <v-tab :href="'#' + currentMonthNum" :ripple="false">{{
                currentMonth
              }}</v-tab>
              <v-tab :href="'#' + nextMonthNum" :ripple="false">{{
                nextMonth
              }}</v-tab>
            </v-tabs>
          </v-col>
        </v-row>
        <v-scroll-x-transition hide-on-leave>
          <div
            v-if="currentTab && selectItems.frontManagers.length && !slide"
            v-show="!loading"
            class="pa-3">
            <v-row class="row-padding">
              <v-col :cols="12" :sm="4" class="pt-0 pb-0 flex-start">
                <span class="card-label">Менеджер</span>
              </v-col>
              <v-col :cols="12" :sm="3" class="pt-0 pb-0">
                <span class="card-label">План</span>
              </v-col>
              <v-col :cols="12" :sm="3" class="pt-0 pb-0">
                <span class="card-label">Факт</span>
              </v-col>
              <v-col :cols="12" :sm="2" class="pt-0 pb-0 text-right">
                <span class="card-label pr-3">Дії</span>
              </v-col>
            </v-row>
            <div v-for="(manager, key) in selectItems.frontManagers" :key="key">
              <v-row class="pt-0 amount-funding-row">
                <v-col :cols="12" :sm="4" class="pt-4 pb-0 flex-start">
                  <span class="switch-label calc">
                    {{ getManager(manager.id) }}
                  </span>
                </v-col>
                <v-col :cols="12" :sm="3" class="pt-0 pb-0">
                  <v-text-field
                    :id="`row-${currentTab}-${manager.id}-${key}`"
                    v-model="managersPlan[currentTab][manager.id].plan"
                    class="border-bottom-none mb-0 mt-2"
                    placeholder="Вкажіть значення"
                    dense
                    hide-details
                    :disabled="!!managersPlan[currentTab][manager.id].id"
                    :readonly="!!managersPlan[currentTab][manager.id].id"
                    @input="
                      changeInputVal(
                        `row-${currentTab}-${manager.id}-${key}`,
                        'input',
                        [keepDigit, formutSum]
                      )
                    ">
                  </v-text-field>
                </v-col>
                <v-col :cols="12" :sm="3" class="pt-4 pb-0">
                  <span>---</span>
                </v-col>
                <v-col :cols="12" :sm="2" class="pt-0 pb-0 text-right">
                  <TableCrud
                    :actionsArray="[
                      {
                        action: () =>
                          openFrontActivityDialog(
                            managersPlan[currentTab][manager.id],
                            'editFrontActivitiesPlan',
                            'План активностi оновлено'
                          ),
                        text: 'Редагувати план',
                        icon: 'mdi-folder-open-outline',
                        disabled:
                          !!!managersPlan[currentTab][manager.id].plan ||
                          !!!managersPlan[currentTab][manager.id].id,
                      },
                      {
                        action: () =>
                          openFrontActivityDialog(
                            managersPlan[currentTab][manager.id],
                            'deleteFrontActivitiesPlan',
                            'План активностi видалено'
                          ),
                        text: 'Видалити план',
                        icon: 'mdi-folder-open-outline',
                        disabled:
                          !!!managersPlan[currentTab][manager.id].plan ||
                          !!!managersPlan[currentTab][manager.id].id,
                      },
                    ]">
                  </TableCrud>
                </v-col>
              </v-row>
            </div>
            <v-row class="ml-3 mr-3">
              <v-col :cols="12" :sm="12" class="pb-0 text-right">
                <v-btn
                  class="text-white no-transform mt-3 mb-9"
                  :disabled="
                    getFilledPlansSum(currentTab) ===
                    getCurrentPlansSum(currentTab)
                  "
                  color="grey darken-3"
                  size="small"
                  :loading="loading"
                  @click="createProgramsPlan(managersPlan[currentTab])">
                  <v-icon color="white" size="small">
                    {{ 'mdi-check-underline' }}
                  </v-icon>
                  &nbsp;Встановити план
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-scroll-x-transition>
      </div>
    </v-fade-transition>
  </v-card-text>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'

import { mapState } from 'vuex'

import {
  ulrPostCreateActivityPlan,
  urlGetFrontActivitiesPlan,
} from '@/pages/request'
import {
  formutSum,
  keepDigit,
  changeInputVal,
  inputNumber,
} from '@/utils/formatFunc'
import { getBeautyNum } from '@/utils/helperFunc'
import TableCrud from '@/components/table-crud.vue'
export default {
  components: { TableCrud, SectionLoader },
  data: () => ({
    managersPlan: {},
    currentTab: 0,
    loading: false,
    slide: false,
    plans: [],
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    currentMonth() {
      return this.ukrMonths[new Date().getMonth()]
    },
    nextMonth() {
      return this.ukrMonths[new Date().getMonth() + 1]
    },
    currentMonthNum() {
      return (new Date().getMonth() + 1).toString()
    },
    nextMonthNum() {
      return (new Date().getMonth() + 2).toString()
    },
    months() {
      return [this.currentMonthNum, this.nextMonthNum]
    },
    ukrMonths() {
      return this.selectItems.ukrMonths
    },
  },
  methods: {
    inputNumber,
    formutSum,
    keepDigit,
    changeInputVal,
    initManagersPlan() {
      const managersPlan = {}
      const buildManagerPlan = month => {
        const o = {}
        this.selectItems.frontManagers.forEach(manager => {
          const existingPlan = this.plans.find(
            p => p.month + '' === month && p.managerId === manager.id
          )
          o[manager.id] = existingPlan || {
            month: month,
            plan: null,
            managerId: manager.id,
            year: '' + new Date().getFullYear(),
          }
          if (o[manager.id].plan !== null) {
            o[manager.id].plan = getBeautyNum(o[manager.id].plan)
          }
        })

        return o
      }

      this.months.forEach(
        month => (managersPlan[month] = { ...buildManagerPlan(month) })
      )

      return Object.assign(this.managersPlan, managersPlan)
    },
    createActivityPlan(plan) {
      const req = plan
      req.month = parseInt(req.month)
      req.year = parseInt(req.year)

      const curPlan = req.plan
      const NumberPlan = curPlan.replace(/\s/g, '')

      const currNumberPlan = Number(NumberPlan)
      req.plan = currNumberPlan
      this.loading = true
      return this.$axios
        .post(ulrPostCreateActivityPlan(), plan)
        .then(res => {
          this.loading = false
          this.getPlans()
          return res
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
    openFrontActivityDialog(item, action, message) {
      return this.$store.commit('setDialog', {
        title: 'План активностi',
        dialogItem: item,
        params: {
          cb: () => {
            this.getPlans()
            this.$setSnackbar({ text: message })
          },
        },
        action: action,
        disabled: false,
      })
    },
    createProgramsPlan(plans) {
      this.loading = true
      const monthPlans = Object.values(plans).filter(p => p.plan && !p.id)

      return Promise.all(monthPlans.map(this.createActivityPlan))
        .then(res => {
          this.$setSnackbar({ text: 'Плани встановлено' })
          this.loading = false
          return res
        })
        .catch(this.$err)
    },
    getFilledPlansSum(month) {
      const monthPlans = Object.values(this.managersPlan?.[month] || {})
        .filter(p => p.plan)
        .map(p => keepDigit(p?.plan || 0))
        .reduce((acc, val) => acc + val, 0)

      return monthPlans
    },
    getCurrentPlansSum(month) {
      const monthPlans = this.plans
        .filter(p => p.month === month)
        .map(p => keepDigit(p?.plan || 0))
        .reduce((acc, val) => acc + val, 0)

      return monthPlans
    },
    getPlans() {
      this.loading = true
      return this.$axios
        .get(urlGetFrontActivitiesPlan())
        .then(res => {
          this.plans.splice(0)
          this.plans.push(...res.data)
          this.loading = false
          return res
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
    getManager(id) {
      const manager = (this.selectItems.frontManagers || []).find(
        m => m.id === id
      )
      return `${manager?.surname || '-'} ${manager?.name || '-'} ${
        manager?.patronymic || '-'
      }`
    },
  },
  watch: {
    slide() {
      this.$nextTick(() => (this.slide = false))
    },
    plans() {
      this.initManagersPlan()
    },
  },
  created() {
    Promise.all([
      this.getPlans(),
      this.$store.dispatch('addFrontManagers'),
    ]).then(this.initManagersPlan)
  },
}
</script>
<style>
.switch-label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.95rem;
  margin: 0 5px;
}
.row-padding {
  border-bottom: 1px solid gainsboro;
  padding: 10px 0;
}
</style>
