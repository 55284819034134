<template>
  <div>
    <b-server-autocomplete
      v-if="dialog.dialogItem.from === 'contractor'"
      v-model="employee.contactId"
      :default-item="employee.contact"
      :url="urlContactsGetAll()"
      :search-min-length="3"
      :error-messages="getErrorMessages(v$.contactId)"
      label="Контакт"
      item-title="fullName"
      hide-details
      placeholder="введіть імʼя контакта (мін. 3 символи)"
      :disabled="dialog.action !== 'createEmployee'"
      @blur="v$.contactId.$touch()"></b-server-autocomplete>
    <b-server-autocomplete
      v-if="dialog.dialogItem.from === 'contact'"
      v-model="employee.contractorId"
      :default-item="employee.contractor"
      :url="urlGetAllContractors()"
      :search-min-length="3"
      :error-messages="getErrorMessages(v$.contactId)"
      label="Контрагент"
      :item-title="c => `${c.shortName} (${c.edrpou})`"
      hide-details
      placeholder="введіть назву контрагента (мін. 3 символи)"
      :disabled="dialog.action !== 'createEmployee'"
      @blur="v$.contactId.$touch()"></b-server-autocomplete>
    <b-form hide-action-buttons :readonly="deleteEmployee">
      <b-select
        v-model="employee.postId"
        label="Посада"
        :items="$directory.get('employeePosts', employee.post)"
        :loading="$loading.isLoading('employeePosts')"
        :error-messages="getErrorMessages(v$.postId)"
        placeholder="оберіть посаду"
        hide-details
        @blur="v$.postId.$touch()"
        @focus="$directory.fill('employeePosts')"
        @update:model-value="
          employee.fullPostName =
            $directory
              .get('employeePosts', null, { type: 'collection' })
              .getItem($event)?.name || null
        "></b-select>
      <b-input
        v-model="employee.fullPostName"
        label="Повна назва посади"
        placeholder="введіть значення"
        :error-messages="getErrorMessages(v$.fullPostName)"
        hide-details
        @blur="v$.fullPostName.$touch()"></b-input>
      <b-select
        v-model="employee.roleId"
        label="Роль"
        :items="$directory.get('employeeRoles', employee.role)"
        :loading="$loading.isLoading('employeeRoles')"
        :error-messages="getErrorMessages(v$.roleId)"
        placeholder="оберіть роль"
        hide-details
        @blur="v$.roleId.$touch()"
        @focus="$directory.fill('employeeRoles')"></b-select>
      <b-select
        v-model="employee.departmentId"
        label="Департамент"
        :items="$directory.get('employeeDepartments', employee.department)"
        :loading="$loading.isLoading('employeeDepartments')"
        :error-messages="getErrorMessages(v$.departmentId)"
        placeholder="оберіть департамент"
        hide-details
        @blur="v$.departmentId.$touch()"
        @focus="$directory.fill('employeeDepartments')"></b-select>
    </b-form>

    <div v-if="deleteEmployee" class="font-weight-bold text-center mt-3">
      {{
        dialog.dialogItem.from === 'contractor' ? 'Співробітника' : 'Карʼєру'
      }}
      буде видалено!
    </div>
  </div>
</template>

<script lang="ts">
import {
  urlContactsGetAll,
  urlGetAllContractors,
  urlEmployeeDelete,
} from '@/pages/request.js'
import { BServerAutocomplete, BForm } from 'best-modules/components'
import { reactive } from 'vue'
import { minLength, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { getErrorMessages } from 'best-modules/utils'
import { urlEmployeeUpdate, urlEmployeeCreate } from '@/pages/request.js'
import axios from '@/plugins/axios.js'
import { Employee } from '@/utils/types'

export default {
  name: 'Employee',
  components: {
    BServerAutocomplete,
    BForm,
  },
  props: {
    dialog: {
      type: Object,
    },
  },
  methods: { getErrorMessages, urlContactsGetAll, urlGetAllContractors },
  setup(props) {
    const employee = reactive<Employee>({
      contactId: null,
      contractorId: null,
      postId: null,
      fullPostName: null,
      roleId: null,
      departmentId: null,
    })
    Object.assign(employee, props.dialog.dialogItem.employee)

    const rules = {
      contactId: { required: required },
      postId: { required: required },
      fullPostName: { required: required, minLength: minLength(2) },
      roleId: { required: required },
      departmentId: { required: required },
    }
    const v$ = useVuelidate(rules, employee)

    const deleteEmployee = props.dialog.action === 'deleteEmployee'

    const getUrl = () => {
      switch (props.dialog.action) {
        case 'createEmployee':
          return urlEmployeeCreate()
        case 'updateEmployee':
          return urlEmployeeUpdate(employee.id)
        case 'deleteEmployee':
          return urlEmployeeDelete(employee.id)
        default:
          throw new Error('[Employee.vue] getUrl() - invalid dialog.action')
      }
    }
    const submit = () => {
      if (props.dialog.action === 'deleteEmployee') {
        return axios.delete(getUrl()).then(res => res.data)
      } else {
        return axios.post(getUrl(), employee).then(res => res.data)
      }
    }

    return {
      employee,
      v$,
      submit,
      deleteEmployee,
    }
  },
}
</script>

<style scoped></style>
