<template>
  <div>
    <v-fade-transition v-if="routeEmployees && !loading" hide-on-leave>
      <div>
        <Filters :filters="filters" class="pr-2 ml-2">
          <template #header-action>
            <v-tooltip bottom text="Додати співробітника">
              <template #activator="{ props }">
                <v-btn
                  v-bind="props"
                  class="text-white mr-2"
                  style="background: rgb(252, 114, 71); border-radius: 4px"
                  @click="
                    $router.push({
                      name: 'employee-create',
                      params: { action: 'add' },
                    })
                  ">
                  Додати співробітника
                </v-btn>
              </template>
            </v-tooltip>
          </template>
        </Filters>
        <v-row>
          <v-col :cols="12" class="pt-5">
            <v-data-table
              :headers="usersHeader"
              :items="users"
              :custom-sort="$sortByDate"
              mobileBreakpoint="1200"
              dense
              hide-default-footer
              :items-per-page="-1"
              class="header-upper-case remove-borders"
              :hide-default-header="!users.length">
              <!-- eslint-disable-next-line -->
              <template #item.phoneNum="{ item }">
                {{ item.phone }}
              </template>
              <template #item.name="{ item }">
                {{ getContactInitials(item, { isResponsible: true }) }}
              </template>
              <template #item.isActive="{ item }">
                <span>{{ item.isActive ? 'Активний' : 'Звільнений' }}</span>
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.action="{ item }">
                <TableCrud
                  :actionsArray="[
                    {
                      action: () =>
                        $router.push({
                          name: `employee-edit`,
                          params: { id: item.id },
                        }),
                      text: 'Редагувати',
                      icon: 'mdi-pencil',
                    },
                    {
                      action: () => toggleUserState(item),
                      text: 'Видалити',
                      icon: 'mdi-close',
                    },
                    {
                      action: () => changeUserState(item),
                      text: item.isActive ? 'Деактивувати' : 'Активувати',
                      icon: item.isActive
                        ? 'mdi-account-minus'
                        : 'mdi-account-plus',
                    },
                  ]">
                </TableCrud>
              </template>
            </v-data-table>
            <Pagination :getItems="getAllUsers" :trigger="paginationTrigger" />
          </v-col>
        </v-row>
      </div>
    </v-fade-transition>
    <SectionLoader v-if="loading" />
    <RouterView v-if="!routeEmployees && !loading"></RouterView>
  </div>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'
import {
  urlGetAdminAllUsers,
  urlGetAdminDeactivateUser,
  urlGetAdminActivateUser,
} from '@/pages/request'
import Filters from '@/components/Filters.vue'
import Pagination from '@/components/Pagination.vue'
import { getContactInitials } from '@/utils/helperFunc'
import { useSelect } from '@/utils/mixins/useSelect'
import { useFilter } from '@/utils/mixins/useFilter'
import { reactive } from 'vue'
import TableCrud from '@/components/table-crud.vue'
export default {
  components: { TableCrud, Pagination, Filters, SectionLoader },
  setup() {
    const filters = reactive({
      search: { value: null },
    })
    return {
      ...useSelect(),
      ...useFilter(filters),
      filters,
    }
  },
  data: () => ({
    usersHeader: [
      { title: 'Статус', key: 'isActive', sortable: false, align: 'start' },
      { title: 'ПIБ', key: 'name', sortable: false, align: 'start' },
      { title: 'Email', key: 'email', sortable: false, align: 'center' },
      { title: 'Тел', key: 'phoneNum', sortable: false, align: 'center' },
      { title: 'Дії', key: 'action', sortable: false, align: 'center' },
    ],
    users: [],
    loading: false,
    userStateLoad: false,
  }),
  computed: {
    routeEmployees() {
      return this.$route.name === 'employees'
    },
  },
  methods: {
    getContactInitials,
    getAllUsers(page = 1) {
      return this.$axios
        .get(urlGetAdminAllUsers(page, this.filtersQueryObj))
        .then(res => {
          const data = res.data.data
          this.users = data
          return res
        })
        .catch(this.$err)
    },
    changeUserState(user) {
      this.userStateLoad = true
      const url = user.isActive
        ? urlGetAdminDeactivateUser(user.id)
        : urlGetAdminActivateUser(user.id)

      return this.$axios
        .get(url)
        .then(res => {
          this.userStateLoad = false
          this.$setSnackbar(
            `${
              user.isActive
                ? 'Користувача деактивовано'
                : 'Користувача активовано'
            }`
          )
          this.getAllUsers()
          return res
        })
        .catch(err =>
          this.$err(err, () => {
            this.userStateLoad = false
          })
        )
    },
    toggleUserState(item) {
      this.$store.commit('setDialog', {
        title: 'Видалення',
        dialogItem: item,
        params: { cb: this.getAllUsers, submitText: 'Видалити' },
        action: 'adminDeleteUser',
      })
    },
  },
}
</script>
