<template>
  <div>
    <v-row>
      <v-col v-if="!isReject" cols="12" md="12" sm="12" class="pt-0">
        <span class="area-label">Категорія</span>
        <v-select
          v-model="riskResult.categoryId"
          :items="$directory.get('clientCategories', riskResult.category)"
          placeholder="A"
          item-title="name"
          item-value="id"
          dense
          hide-details
          :error-messages="categoryErr"
          :loading="$loading.isLoading('clientCategories')"
          @focus="$directory.fill('clientCategories')">
        </v-select>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pt-0">
        <span class="label">{{ commentLabel }}</span>
        <v-textarea
          v-model="riskResult.resultComment"
          placeholder="Введіть текст"
          :error-messages="commentErr"
          solo
          hide-details
          @blur="v$.riskResult.resultComment.$touch()">
        </v-textarea>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { minLength, required } from '@vuelidate/validators'
import { setErrHandler } from '@/utils/helperFunc'
import { urlRiskAnalyzeSetResult } from '@/pages/request'
import { setSnackbar } from 'best-modules/plugins'
import { useSelect } from '@/utils/mixins/useSelect'

export default {
  name: 'RiskResult',
  props: {
    dialog: { type: Object },
  },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  validations() {
    return {
      riskResult: {
        categoryId: !this.isReject ? { required } : {},
        resultComment: {
          required,
          minLength: this.isReject ? minLength(20) : minLength(1),
        },
      },
    }
  },
  data: () => ({
    riskResult: {
      resultId: null,
      resultComment: null,
      categoryId: null,
    },
  }),
  computed: {
    isReject() {
      return this.riskResult.resultId !== 1
    },
    validation() {
      return { v$: this.v$.riskResult, name: 'riskResult' }
    },
    commentErr() {
      return this.setErrHandler(this.v$.riskResult.resultComment)
    },
    categoryErr() {
      return this.setErrHandler(this.v$.riskResult.categoryId)
    },
    commentLabel() {
      return this.isReject ? 'Причина відмови' : 'Коментар'
    },
  },
  methods: {
    setErrHandler,
    submit() {
      const req = this.riskResult
      if (this.isReject) {
        delete req.categoryId
      }
      return this.$axios
        .post(urlRiskAnalyzeSetResult(this.dialog.dialogItem.riskId), req)
        .then(res => {
          if (res.data?.message) {
            setSnackbar({ text: res?.data?.message })
          }
          setSnackbar({ text: 'Аналіз завершено' })
        })
    },
  },
  created() {
    this.riskResult.resultId = this.dialog.dialogItem.resultId
    if (!this.isReject) {
      this.riskResult.resultComment = 'Погоджено'
      this.riskResult.category = this.dialog.dialogItem?.category
      this.riskResult.categoryId = this.dialog.dialogItem?.category?.id
    }
  },
}
</script>

<style scoped></style>
