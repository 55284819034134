<template>
  <div>
    <SectionLoader v-if="loading"></SectionLoader>
    <div v-if="!loading" class="pr-2">
      <FrontScreen
        v-if="frontManager || supervisor"
        :graphs="graphs"
        @year-counter="yearCounter" />
      <MiddleScreen
        v-if="middleManager"
        :graphs="graphs"
        @year-counter="yearCounter" />
    </div>
  </div>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'
import { urlGetDashboardGraphs } from '@/pages/request'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import FrontScreen from '@/pages/dashboard/screen/FrontScreen.vue'
import { mapState } from 'vuex'
import MiddleScreen from '@/pages/dashboard/screen/MiddleScreen.vue'
export default {
  components: { MiddleScreen, FrontScreen, SectionLoader },
  data: () => ({
    ChartDataLabels,
    showCarousel: true,
    loading: false,
    applicationColor: '#0099ff',
    projectColor: '#6ab982',
    graphs: {},
  }),
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    supervisor() {
      return this.user.roleId === 3
    },
    frontManager() {
      return this.user.roleId === 2
    },
    middleManager() {
      return [5, 6].includes(this.user.roleId)
    },
  },
  methods: {
    getValue(v) {
      if (v !== undefined && v !== null) {
        const value = parseFloat(v.toFixed()).toLocaleString('ru-RU')
        if (value == 0) return ' '
        return value
      }
      return ' '
    },
    getMaxValue(graphs) {
      const arrSum = Object.values(graphs.applicationConversion).map(o =>
        Math.max(...Object.values(o))
      )
      const maxSum = Math.max(...arrSum)
      const yAxisValue = maxSum + maxSum / 10
      if (yAxisValue < 5) return 5
      return yAxisValue
    },
    getDashboardGraphs(year) {
      this.loading = true
      let curYear

      if (!year) {
        curYear =
          localStorage.getItem('currentDashboardYear') ||
          new Date().getFullYear()
      }

      return this.$axios
        .get(urlGetDashboardGraphs(year ? year : curYear))
        .then(res => {
          if (res.data) {
            Object.assign(this.graphs, res.data)
          }
          this.loading = false
          return res
        })
        .catch(err => {
          this.loading = false
          this.graphs = {}
          if (err?.response && err?.response?.status) {
            return
          }
        })
    },
    yearCounter(e) {
      let year =
        parseInt(localStorage.getItem('currentDashboardYear')) ||
        new Date().getFullYear()

      if (e === 'plus') {
        year += 1
        this.getDashboardGraphs(year)

        localStorage.setItem('currentDashboardYear', year)
      }

      if (e === 'minus') {
        year -= 1
        this.getDashboardGraphs(year)

        localStorage.setItem('currentDashboardYear', year)
      }
    },
  },
  created() {
    this.getDashboardGraphs()
  },
}
</script>
<style lang="scss"></style>
