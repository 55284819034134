<template>
  <div>
    <v-tabs
      v-model="currentTab"
      class="contact-tab"
      background-color="grey darken-2"
      show-arrows
      :elevation="0"
      color="white"
      dark
      slider-color="red"
      dense>
      <v-tab @click="$router.replace({ name: 'contact-history-phone' })"
        >Історія дзвінків</v-tab
      >
      <v-tab @click="$router.replace({ name: 'contact-history-meetings' })"
        >Зустрічі</v-tab
      >
      <v-tab @click="$router.replace({ name: 'contact-history-scheduler' })"
        >Нагадування</v-tab
      >
      <v-tab @click="$router.replace({ name: 'contact-history-comment' })"
        >Коментар</v-tab
      >
    </v-tabs>
    <div class="pb-0 pt-1 pl-0 pr-0">
      <RouterView :contact="contact"> </RouterView>
    </div>
  </div>
</template>
<script>
import { useSelect } from '@/utils/mixins/useSelect'

export default {
  props: { contact: { type: Object } },
  setup() {
    return {
      ...useSelect(),
    }
  },
  data: () => ({
    callingHistoryItems: null,
    activities: [],
    filter: false,
    statusId: null,
    startDate: null,
    endDate: null,
    requestDone: false,
    currentTab: 0,
  }),
}
</script>
