<template>
  <div>
    <ProjectHistoryTable :project="project" />
  </div>
</template>

<script>
import ProjectHistoryTable from '@/components/ProjectHistoryTable.vue'
import { mapState } from 'vuex'
export default {
  components: { ProjectHistoryTable },
  computed: {
    ...mapState({
      project: state => state.projects.project,
    }),
  },
}
</script>

<style scoped></style>
