import { setSnackbar } from 'best-modules/plugins'

function resHandler(res) {
  const method = `Method: "${
    res && res.config && res.config.method ? res.config.method : '--'
  }". `
  const url = `Url: "${
    res && res.config && res.config.url ? res.config.url : '--'
  }". `
  const data = `Data: "${res && res.data ? res.data : '--'}". `

  function errStr(method, url, data) {
    return method + url + data
  }

  // check if response is html string (Laravel blade)
  if (typeof res.data === 'string' && res.data.includes('<meta')) {
    const error = errStr(method, url, data)
    setSnackbar({ text: 'Помилка', color: 'error' })
    throw new Error(error)
  } else return res
}

export default resHandler
