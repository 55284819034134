<template>
  <div>
    <SectionLoader v-if="loading"></SectionLoader>
    <v-fade-transition hide-on-leave>
      <div v-if="!loading">
        <v-row v-if="!loading">
          <v-col cols="12" md="3" sm="12">
            <FieldsColumnBlock title="Основні дані">
              <template #general-fields>
                <Fields :fields="fields" :type="2" direction="column">
                  <template #number_field>
                    <EditableField
                      v-model="agreement.number"
                      label="№ Договору"
                      style="margin-top: -5px"
                      readonly
                      @blur="v$.agreement.number.$touch()" />
                  </template>
                </Fields>
                <v-checkbox
                  v-model="agreement.tripartiteAgreement"
                  style="margin-left: -8px"
                  class="mt-2"
                  hide-details
                  :true-value="false"
                  :false-value="true"
                  :readonly="readonly"
                  color="#fc7247"
                  @update:model-value="
                    !$event && (agreement.debtorSignatories = null)
                  ">
                  <template #label>
                    <span style="font-size: 12px">Двосторонній договір</span>
                  </template>
                </v-checkbox>
              </template>
              <template #default>
                <div class="mb-2">
                  <span class="label">Вид підпису</span>
                  <v-select
                    v-model="agreement.signingTypeId"
                    :items="
                      $directory.get('signingTypes', agreement.signingType)
                    "
                    item-title="name"
                    item-value="id"
                    hide-details
                    :readonly="readonly"
                    :disabled="readonly"
                    placeholder="Оберіть вид"
                    :error-messages="
                      getErrorMessages(v$.agreement.signingTypeId)
                    "
                    :loading="$loading.isLoading('signingTypes')"
                    @focus="$directory.fill('signingTypes')"
                    @blur="v$.agreement.signingTypeId.$touch()">
                  </v-select>
                </div>
              </template>
            </FieldsColumnBlock>
            <FieldsColumnBlock title="Візування" :fullHeight="!mobile">
              <template #agreements>
                <Agreements :agreements="agreement.agreements" />
              </template>
            </FieldsColumnBlock>
          </v-col>
          <v-col cols="12" md="9" sm="12" :class="`${mobile ? '' : 'pl-0'}`">
            <div class="white-block" style="padding-left: 7px">
              <ContractStatusBar
                class="mb-3"
                :contract="agreement"
                :mobile="mobile"
                :isCancelContract="agreement.isCancelContract"
                :isTerminated="agreement.isTerminated"
                :currentStatus="agreement.statusId"
                :submit="setGCStatus"
                :statuses="filteredStatuses"
                spreadItems
                size="small">
                <template #actions>
                  <ContractActionButton :actions="btnActions" />
                </template>
              </ContractStatusBar>
              <v-tabs
                v-model="currentTab"
                class="dkpTabs"
                show-arrows
                color="white"
                dark
                dense
                slider-color="#fc7247"
                :height="38">
                <v-tab
                  replace
                  :to="{
                    name: 'surety-parties-details',
                    params: { id: $route.params.id },
                  }">
                  Реквізити сторін
                </v-tab>
                <v-tab
                  replace
                  :to="{
                    name: 'surety-subject',
                    params: { id: $route.params.id },
                  }">
                  Предмет поруки
                </v-tab>
                <v-tab
                  replace
                  :to="{
                    name: 'surety-documents',
                    params: { id: $route.params.id },
                  }">
                  Документи
                </v-tab>
                <v-tab
                  replace
                  :to="{
                    name: 'surety-history',
                    params: { id: $route.params.id },
                  }">
                  Історія
                </v-tab>
              </v-tabs>
            </div>
            <router-view
              :agreement="agreement"
              :readonly="readonly"
              :mobile="mobile"
              :v="v$">
            </router-view>
            <ActionButtons
              class="mt-10"
              :confirm="submit"
              :cancel="getSuretyAgreement"
              :confirmLoading="$loading.isLoading('submit')"
              :cancelDisable="
                $loading.isLoading('submit') || agreementState === cache
              "
              :confirmDisable="agreementState === cache">
            </ActionButtons>
          </v-col>
        </v-row>
      </div>
    </v-fade-transition>
  </div>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'
import ActionButtons from '@/components/action-buttons.vue'
import generateSuretyAgreement from './pdf-forms/surety-agreement'
import {
  LODescription as getLoDescription,
  setCache,
  setErrHandler,
  getEntityName,
  v$Notify,
  toFormatDate,
  backDate,
  openDocument,
} from '@/utils/helperFunc'
import {
  urlUpdateSuretyAgreement,
  urlGetSuretyAgreement,
  urlGetGuaranteeContractPrintForm,
  urlSetCancelContractGuarantee,
  urlSetGCStatus,
  urlGetEntityPrintedForm,
} from '@/pages/request'
import { mapState } from 'vuex'
import { required, minLength } from '@vuelidate/validators'
import EditableField from '@/components/EditableField.vue'
import Fields from '@/components/Fields.vue'
import { useVuelidate } from '@vuelidate/core'
import { confirm } from 'best-modules/plugins'
import { fillDirectory, getDirectory } from '@/plugins/directory/index.ts'
import FieldsColumnBlock from '@/pages/contracts/components/FieldsColumnBlock.vue'
import ContractStatusBar from '@/components/contracts/ContractStatusBar.vue'
import Agreements from '@/components/Agreements.vue'
import ContractActionButton from '@/pages/contracts/components/ContractActionButton.vue'
import { useDisplay } from 'vuetify'
import { useSelect } from '@/utils/mixins/useSelect'
import { computed } from 'vue'
import { getErrorMessages } from 'best-modules/utils'
import { handleAsync } from 'best-modules/plugins/index'

export default {
  components: {
    ContractActionButton,
    Agreements,
    ContractStatusBar,
    FieldsColumnBlock,
    Fields,
    EditableField,
    SectionLoader,
    ActionButtons,
  },
  setup() {
    const { xs, sm } = useDisplay()
    const mobile = computed(() => xs.value || sm.value)

    return { v$: useVuelidate(), ...useSelect(), mobile }
  },
  validations() {
    return {
      agreement: {
        number: { required },
        date: { required, minLength: minLength(10) },
        typeId: { required },
        creditorId: { required },
        creditorSignatoryId: { required },
        creditorBasisId: { required },
        guarantorId: { required },
        signingTypeId: { required },
        guarantorSignatories: {
          required,
          minLength: minLength(1),
          $each: {
            contactId: { required },
            basisId: { required },
          },
        },
      },
    }
  },
  data: () => ({
    agreement: {},
    agreedCalculations: [],
    currentTab: 0,
    loading: false,
    cache: null,
    creditorEmployees: [],
    guarantorEmployees: [],
    printedForm: {},
  }),
  computed: {
    ...mapState({
      user: state => state.user,
      selectItems: state => state.selectItems,
    }),
    fields() {
      return [
        {
          label: 'Дата договору',
          value: toFormatDate(this.agreement.date, true),
        },
        { label: '№ Договору', slot: 'number' },
      ]
    },
    pfForms() {
      return [
        {
          action: this.getGuaranteePrintedForm,
          label: 'Договір поруки',
          icon: 'mdi-file-word',
        },
      ]
    },
    btnActions() {
      return {
        main: { label: 'Дії', disabled: false },
        list: [
          {
            show: this.agreement.statusId !== 5,
            label: 'Анулювати договір',
            icon: 'mdi-file-document-remove-outline',
            action: () => this.setCancel(),
            disabled:
              (!this.isAdmin && this.readonly) ||
              this.agreement.isCancelContract,
          },
          {
            show: true,
            label: 'Друковані форми',
            icon: 'mdi-printer',
            actions: this.pfForms,
            disabled: false,
          },
        ],
      }
    },
    agreementId() {
      return this.$route?.params?.id
    },
    debtor() {
      if (this.agreement.debtor) {
        const debtor =
          this.agreement?.debtor?.contact || this.agreement?.debtor?.contractor
        return debtor?.shortName || debtor?.fullName
      }
      return '---'
    },
    admin() {
      return this.user.roleId === 1
    },
    agreementState() {
      return this.setCache([this.agreement])
    },
    typeIdErr() {
      return this.setErrHandler(this.v$?.agreement?.typeId)
    },
    route() {
      return this.$route.name
    },
    readonly() {
      return this.agreement.readOnly
    },
    filteredStatuses() {
      const filteredStatuses = getDirectory('guaranteeStatuses').filter(item =>
        this.agreement.isCancelContract ? true : item.id !== 6
      )
      return filteredStatuses.map(s => {
        let showCheckIcon
        if (this.agreement.statusId === 6) {
          showCheckIcon = s.id === 6
        }

        return { ...s, showCheckIcon }
      })
    },
  },
  methods: {
    getErrorMessages,
    setCache,
    getEntityName,
    setErrHandler,

    getLoDescription,
    urlUpdateSuretyAgreement,
    urlGetSuretyAgreement,
    urlGetEntityPrintedForm,
    urlSetCancelContractGuarantee,
    urlSetGCStatus,
    backDate,
    generateSuretyAgreement,
    async setGCStatus(statusId) {
      if ([2, 3].includes(statusId)) {
        try {
          const statusNames = {
            2: 'Підписано Поручителем',
            3: 'Візування',
          }
          await confirm({
            text: `Ви впевнені що хочете перевести договір в статус "${statusNames[statusId]}"?`,
          })
        } catch (e) {
          return
        }
      }

      this.loading = true
      const currentStatus = this.agreement.statusId

      if (currentStatus === 5) {
        return this.$setSnackbar({
          text: 'Договір з статусу підписаного не може бути змiнено',
          color: 'warning',
        })
      }
      if (
        [4, 5].includes(statusId) &&
        this.agreement.agreements?.some(a => !a.isAgreed)
      ) {
        return this.$setSnackbar({
          text: 'Договір повинен бути завiзований всiма учасниками',
          color: 'warning',
        })
      }
      if (statusId < currentStatus || statusId > currentStatus + 1) {
        return this.$setSnackbar({ text: 'Дія неможлива', color: 'error' })
      }

      return this.$axios
        .post(this.urlSetGCStatus(this.agreement.id), { statusId: statusId })
        .then(res => {
          if (res.data.message) {
            this.$setSnackbar({ text: res.data.message, color: 'warning' })
          } else this.$setSnackbar({ text: 'Статус успішно змiнено' })
          this.getSuretyAgreement()
          return res
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },

    getGuaranteePrintedForm() {
      return this.$axios
        .get(urlGetGuaranteeContractPrintForm(this.agreement.id))
        .then(res => {
          if (res.data.message) {
            return this.$setSnackbar({
              text: res.data.message,
              color: 'warning',
            })
          }
          openDocument({
            url: res.data.url,
            text: `Договір поруки_${this.agreement.number}_${
              this.agreement.guarantor.contact?.fullName ||
              this.agreement.guarantor.contractor?.shortName
            }`,
          })
        })
        .catch(this.$err)
    },
    setBreadScrumb() {
      const name =
        this.getEntityName(this.agreement.guarantor) +
        ' ' +
        this.agreement.number
      this.$store.commit('setBreadScrumbPart', [null, null, name, null])
    },
    getSuretyAgreement() {
      this.loading = true
      return this.$axios
        .get(this.urlGetSuretyAgreement(this.agreementId || this.agreement.id))
        .then(res => {
          if (res?.data?.message) {
            const err = res?.data?.message
            return this.$setSnackbar({ text: err, color: 'error' })
          }
          if (!res.data.guarantorSignatories)
            res.data.guarantorSignatories = [{ contactId: null, basisId: null }]
          if (!res.data.debtorSignatories)
            res.data.debtorSignatories = [{ contactId: null, basisId: null }]
          Object.assign(this.agreement, res.data)
          this.cache = this.setCache([this.agreement])
          this.loading = false
          this.v$.$reset()
          return res
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
    setCancel() {
      confirm({
        text: 'Ви дійсно бажаєте анулювати договір?',
      }).then(() => {
        return this.$axios
          .post(urlSetCancelContractGuarantee(this.agreement.id), {
            isCancelContract: true,
          })
          .then(res => {
            this.$setSnackbar({ text: 'Оновлено' })
            Object.assign(this.agreement, res.data)
            this.setCache([this.agreement])

            return res
          })
      })
    },
    submit() {
      if (this.v$.$invalid) {
        this.v$.$anyError
        this.v$.$touch()
        v$Notify(this.v$.agreement, 'suretyAgreement')
        return Promise.resolve()
      }
      const requestObj = Object.clone(this.agreement)
      requestObj.date = backDate(this.agreement.date)

      if (requestObj.guarantorSignatories)
        requestObj.guarantorSignatories = requestObj.guarantorSignatories.map(
          s => ({
            contactId: s.contactId,
            basisId: s.basisId,
          })
        )
      if (requestObj.debtorSignatories)
        requestObj.debtorSignatories = requestObj.debtorSignatories.map(s => ({
          contactId: s.contactId,
          basisId: s.basisId,
        }))

      !requestObj.tripartiteAgreement

      if (!requestObj.tripartiteAgreement) {
        requestObj.debtorSignatories = null
      }

      return handleAsync('submit', () => {
        return this.$axios
          .post(this.urlUpdateSuretyAgreement(this.agreementId), requestObj)
          .then(res => {
            if (res?.data?.message) throw new Error(res.data.message)
            res.data.date = toFormatDate(res.data.date, true)
            Object.assign(this.agreement, res.data)
            this.cache = this.setCache([this.agreement])
            this.$setSnackbar({ text: 'Дані оновлено' })
            return res
          })
      })
    },
  },
  watch: {
    '$route.name': function () {
      this.setBreadScrumb()
    },
  },
  created() {
    fillDirectory('guaranteeStatuses')
    this.$store.dispatch('addSuretyAgreementTypes')
    this.getSuretyAgreement().then(res => {
      this.setBreadScrumb()
      return res
    })
  },
}
</script>
<style scoped>
a.v-tab-item--selected.v-tab {
  color: #08487a !important;
  font-weight: 700 !important;
  font-size: 14px;
  letter-spacing: normal;
  padding-left: 0;
  padding-right: 0;
}
a.v-tab {
  margin-right: 20px;
  padding-left: 0;
  padding-right: 0;
  color: #838282 !important;
  letter-spacing: normal !important;
}
.v-slide-group__wrapper {
  background: #fff !important;
}
</style>
