<template>
  <div>
    <SectionLoader v-if="asyncLoading('getScoring')" />
    <div v-else>
      <div
        v-if="!calculations.length"
        class="mt-10 d-flex justify-center align-center">
        Калькуляція відсутня
      </div>
      <div v-else style="background: white">
        <v-tabs
          show-arrows
          dark
          dense
          :height="38"
          class="generalTab"
          slider-color="#fc7247"
          style="background: #fff !important">
          <v-tab
            replace
            :to="{
              name: `${service}-sc-client-verification`,
              params: {
                id: $route.params.id,
                tabState: $route.params.tabState,
              },
            }">
            Клієнт
          </v-tab>
          <v-tab
            v-for="(provider, idx) in hasProvider ? providers : []"
            :key="provider.providerId"
            replace
            :to="{
              name: `${service}-sc-provider-verification`,
              params: {
                providerIdx: idx + 1,
                id: $route.params.id,
                tabState: $route.params.tabState,
              },
            }">
            <span
              style="
                overflow: hidden;
                display: block;
                white-space: nowrap;
                text-overflow: ellipsis;
              ">
              Постачальник {{ getEntityName(provider) }}
            </span>
          </v-tab>
          <v-tab
            v-for="(calc, idx) in !hasProvider ? calculations : []"
            :key="calc.id"
            replace
            :to="{
              name: `${service}-general-sc-leasing-object`,
              params: {
                calculationIdx: idx + 1,
                id: $route.params.id,
                tabState: $route.params.tabState,
              },
            }">
            <svg
              class="mr-1"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 19 18"
              fill="none">
              <path
                d="M2.5 6.75L4 1.6875H15L16.5 6.75M15 12.375C14.6022 12.375 14.2206 12.1972 13.9393 11.8807C13.658 11.5643 13.5 11.1351 13.5 10.6875C13.5 10.2399 13.658 9.81072 13.9393 9.49426C14.2206 9.17779 14.6022 9 15 9C15.3978 9 15.7794 9.17779 16.0607 9.49426C16.342 9.81072 16.5 10.2399 16.5 10.6875C16.5 11.1351 16.342 11.5643 16.0607 11.8807C15.7794 12.1972 15.3978 12.375 15 12.375ZM4 12.375C3.60218 12.375 3.22064 12.1972 2.93934 11.8807C2.65804 11.5643 2.5 11.1351 2.5 10.6875C2.5 10.2399 2.65804 9.81072 2.93934 9.49426C3.22064 9.17779 3.60218 9 4 9C4.39782 9 4.77936 9.17779 5.06066 9.49426C5.34196 9.81072 5.5 10.2399 5.5 10.6875C5.5 11.1351 5.34196 11.5643 5.06066 11.8807C4.77936 12.1972 4.39782 12.375 4 12.375ZM16.42 1.125C16.22 0.4725 15.66 0 15 0H4C3.34 0 2.78 0.4725 2.58 1.125L0.5 7.875V16.875C0.5 17.1734 0.605357 17.4595 0.792893 17.6705C0.98043 17.8815 1.23478 18 1.5 18H2.5C2.76522 18 3.01957 17.8815 3.20711 17.6705C3.39464 17.4595 3.5 17.1734 3.5 16.875V15.75H15.5V16.875C15.5 17.1734 15.6054 17.4595 15.7929 17.6705C15.9804 17.8815 16.2348 18 16.5 18H17.5C17.7652 18 18.0196 17.8815 18.2071 17.6705C18.3946 17.4595 18.5 17.1734 18.5 16.875V7.875L16.42 1.125Z"
                fill="#838282" />
            </svg>
            {{ `${getLoDescription(calc).name} (Зворотній лізинг)` }}</v-tab
          >
        </v-tabs>
        <v-tabs
          v-if="loTabs && calculations.length"
          show-arrows
          dark
          dense
          :height="38"
          hide-slider
          class="mt-4 mb-3"
          style="background: #fff !important">
          <v-tab
            class="fortab"
            replace
            :to="{
              name: `${service}-sc-provider-verification`,
              params: {
                providerIdx: $route.params.providerIdx,
                id: $route.params.id,
                tabState: $route.params.tabState,
              },
            }">
            Загальні дані
          </v-tab>
          <v-tab
            v-for="(calc, idx) in calculationsByProvider"
            :key="calc.id"
            class="fortab"
            replace
            :to="{
              name: `${service}-sc-leasing-object`,
              params: {
                calculationIdx: idx + 1,
                id: $route.params.id,
                tabState: $route.params.tabState,
              },
            }">
            <svg
              class="mr-1"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 19 18"
              fill="none">
              <path
                d="M2.5 6.75L4 1.6875H15L16.5 6.75M15 12.375C14.6022 12.375 14.2206 12.1972 13.9393 11.8807C13.658 11.5643 13.5 11.1351 13.5 10.6875C13.5 10.2399 13.658 9.81072 13.9393 9.49426C14.2206 9.17779 14.6022 9 15 9C15.3978 9 15.7794 9.17779 16.0607 9.49426C16.342 9.81072 16.5 10.2399 16.5 10.6875C16.5 11.1351 16.342 11.5643 16.0607 11.8807C15.7794 12.1972 15.3978 12.375 15 12.375ZM4 12.375C3.60218 12.375 3.22064 12.1972 2.93934 11.8807C2.65804 11.5643 2.5 11.1351 2.5 10.6875C2.5 10.2399 2.65804 9.81072 2.93934 9.49426C3.22064 9.17779 3.60218 9 4 9C4.39782 9 4.77936 9.17779 5.06066 9.49426C5.34196 9.81072 5.5 10.2399 5.5 10.6875C5.5 11.1351 5.34196 11.5643 5.06066 11.8807C4.77936 12.1972 4.39782 12.375 4 12.375ZM16.42 1.125C16.22 0.4725 15.66 0 15 0H4C3.34 0 2.78 0.4725 2.58 1.125L0.5 7.875V16.875C0.5 17.1734 0.605357 17.4595 0.792893 17.6705C0.98043 17.8815 1.23478 18 1.5 18H2.5C2.76522 18 3.01957 17.8815 3.20711 17.6705C3.39464 17.4595 3.5 17.1734 3.5 16.875V15.75H15.5V16.875C15.5 17.1734 15.6054 17.4595 15.7929 17.6705C15.9804 17.8815 16.2348 18 16.5 18H17.5C17.7652 18 18.0196 17.8815 18.2071 17.6705C18.3946 17.4595 18.5 17.1734 18.5 16.875V7.875L16.42 1.125Z"
                fill="#838282" />
            </svg>
            {{ getLoDescription(calc).name }}
          </v-tab>
        </v-tabs>
        <div class="pt-3">
          <RouterView
            :id="id"
            :key="$route.fullPath"
            :calculation="
              !hasProvider
                ? calculations[0]
                : calculationsByProvider[$route.params.calculationIdx - 1 || 0]
            "
            :provider="providers[$route.params.providerIdx - 1]"
            :scoring="scoring" />
          <div style="margin-top: 10px" class="white-block">
            <v-row>
              <v-col :cols="12" :md="3" class="pt-0">
                <span>Коментар:</span>
              </v-col>
              <v-col :cols="12" :md="9" class="pt-0">
                <v-textarea
                  v-model="scoring.scoringServiceComment"
                  class="pt-0"
                  :rows="4"
                  hide-details
                  placeholder="Напишіть коментар"
                  :readonly="!!scoring.endTime">
                </v-textarea>
              </v-col>
            </v-row>
          </div>
          <ActionButtons
            v-if="
              !scoring.endTime &&
              ($route.name === 'security-sc-client-verification' ||
                $route.name === 'fin-sc-client-verification' ||
                $route.name === 'risk-sc-client-verification')
            "
            class="mt-5"
            :confirm="() => setResult(true)"
            :cancel="() => setResult(false)"
            :cancelDisable="disabled"
            :confirmDisable="disabled"
            :confirmLoading="asyncLoading('setResult')"
            :cancelLoading="asyncLoading('setResult')"
            confirmBtnText="Погодити"
            cancelBtnText="Не погодити">
          </ActionButtons>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionLoader from '@/components/section-loader.vue'
import {
  getEntityName,
  LODescription as getLoDescription,
} from '@/utils/helperFunc'
import { urlScoringSenResult, urlScoringSingle } from '@/pages/request'
import ActionButtons from '@/components/action-buttons.vue'
import { mapState } from 'vuex'
import { useSelect } from '@/utils/mixins/useSelect'
export default {
  name: 'ScoringSingle',
  components: {
    ActionButtons,
    SectionLoader,
  },
  setup() {
    return {
      ...useSelect(),
    }
  },
  data() {
    return {
      scoring: {},
      currentTab: 0,
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    service() {
      return this.$route.name.split('-')[0]
    },
    hasProvider() {
      return (
        !this.calculations[0].leaseReverse && !!this.calculations[0].providerId
      )
    },
    disabled() {
      if (this.user.roleId === 2) {
        return true
      }
      if (this.user.roleId === 1) {
        return false
      }

      switch (this.$route.name) {
        case 'security-sc-client-verification':
          return this.user.roleId !== 9
        case 'fin-sc-client-verification':
          return ![11, 12].includes(this.user.roleId)
        case 'risk-sc-client-verification':
          return ![7, 8].includes(this.user.roleId)
        default:
          return true
      }
    },
    loTabs() {
      return [
        `${this.service}-sc-provider-verification`,
        `${this.service}-sc-leasing-object`,
      ].includes(this.$route.name)
    },
    calculations() {
      return this.scoring.calculations || []
    },
    providers() {
      if (!this.scoring.providers) return []
      return this.scoring.providers.map(p => {
        const getTypeId = () => {
          switch (true) {
            case !!p.contractor:
              return 2
            case !!p.contact && !!p.contact.fop:
              return 3
            case !!p.contact && !p.contact.fop:
              return 1
          }
        }

        const providerTypeId = getTypeId()
        const providerEntity = providerTypeId === 2 ? p.contractor : p.contact
        const providerId = providerEntity
          ? `${providerTypeId}${providerEntity.id}`
          : ''

        return { ...providerEntity, providerTypeId, providerId }
      })
    },
    calculationsByProvider() {
      return this.loTabs
        ? this.calculations.filter(
            c =>
              `${c.providerTypeId}${c.providerId}` ===
              this.providers[this.$route.params.providerIdx - 1].providerId
          )
        : []
    },
    id() {
      return +this.$route.params.id
    },
  },
  methods: {
    getLoDescription,
    getEntityName,
    getScoring() {
      return this.asyncAction('getScoring', null, () => {
        return this.$axios.get(urlScoringSingle(this.id)).then(res => {
          this.scoring = res.data

          return res
        })
      })
    },
    setResult(result) {
      return this.asyncAction('setResult', null, () => {
        return this.$axios
          .post(urlScoringSenResult(this.id), {
            isAgree: result,
            scoringServiceComment: this.scoring.scoringServiceComment,
          })
          .then(res => {
            this.scoring = res.data

            return res
          })
      })
    },
  },
  created() {
    this.getScoring()
  },
}
</script>

<style>
.generalTab .v-slide-group__content.v-tabs-bar__content {
  background: #fff !important;
}
.newForTab .v-slide-group__content.v-tabs-bar__content {
  background: #fff !important;
  padding-left: 13px;
}
.newForTab {
  background: #fff !important;
}
</style>

<style lang="scss" scoped>
.block-title {
  display: block;
  color: #08487a;
  font-weight: 600;
  font-size: 16px;
  margin-left: 9px;
  margin-bottom: 20px;
}
.info-fields {
  padding: 0 10px !important;
}
.label-bottom {
  font-size: 14px;
}
.fortab {
  background: #f4f6f9 !important;
  border: 1px solid #c0c1c7 !important;
  border-radius: 4px 0 0 4px !important;
  letter-spacing: normal;
  margin-right: 15px;
}
</style>
