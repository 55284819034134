import { io } from 'socket.io-client'

const getUrl = () => {
  switch (location.hostname) {
    case 'localhost':
      return 'http://localhost:4000'
    case 'erm.bestleasing.com.ua':
      return 'https://socket.io.bestleasing.com.ua'
    case 'dev.erm.bestleasing.com.ua':
      return 'https://io.erm.bestleasing.com.ua'
    default:
      throw new Error('socket.js getUrl() -> invalid host name')
  }
}

export const socket = io(getUrl(), {
  extraHeaders: {
    apikey: 'IrA1VqblYy',
    'x-api-secret': 'G5Euomjeln',
  },
  reconnectionAttempts: 1,
})
