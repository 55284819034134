<template>
  <div>
    <span
      v-if="!calculations"
      style="
        color: #000;
        font-size: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50vh;
      ">
      <svg
        class="mr-1"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none">
        <path
          d="M7.5 15H9V11H7.5V12.25H6V13.75H7.5V15ZM10 13.75H18V12.25H10V13.75ZM15 11H16.5V9.75H18V8.25H16.5V7H15V11ZM6 9.75H14V8.25H6V9.75ZM8 21V19H4C3.45 19 2.979 18.804 2.587 18.412C2.195 18.02 1.99934 17.5493 2 17V5C2 4.45 2.196 3.979 2.588 3.587C2.98 3.195 3.45067 2.99934 4 3H20C20.55 3 21.021 3.196 21.413 3.588C21.805 3.98 22.0007 4.45067 22 5V17C22 17.55 21.804 18.021 21.412 18.413C21.02 18.805 20.5493 19.0007 20 19H16V21H8ZM4 17H20V5H4V17Z"
          fill="#09487a" />
      </svg>
      Калькуляцiя на доопрацюваннi
    </span>
    <v-tabs v-else v-model="curTab" show-arrows hide-slider>
      <v-tab
        v-for="calc in calculations"
        :key="calc.id"
        :value="calc.id"
        size="small"
        density="compact">
        <svg
          class="mr-2"
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          viewBox="0 0 18 18"
          fill="none">
          <path
            d="M2 6.75L3.5 1.6875H14.5L16 6.75M14.5 12.375C14.1022 12.375 13.7206 12.1972 13.4393 11.8807C13.158 11.5643 13 11.1351 13 10.6875C13 10.2399 13.158 9.81072 13.4393 9.49426C13.7206 9.17779 14.1022 9 14.5 9C14.8978 9 15.2794 9.17779 15.5607 9.49426C15.842 9.81072 16 10.2399 16 10.6875C16 11.1351 15.842 11.5643 15.5607 11.8807C15.2794 12.1972 14.8978 12.375 14.5 12.375ZM3.5 12.375C3.10218 12.375 2.72064 12.1972 2.43934 11.8807C2.15804 11.5643 2 11.1351 2 10.6875C2 10.2399 2.15804 9.81072 2.43934 9.49426C2.72064 9.17779 3.10218 9 3.5 9C3.89782 9 4.27936 9.17779 4.56066 9.49426C4.84196 9.81072 5 10.2399 5 10.6875C5 11.1351 4.84196 11.5643 4.56066 11.8807C4.27936 12.1972 3.89782 12.375 3.5 12.375ZM15.92 1.125C15.72 0.4725 15.16 0 14.5 0H3.5C2.84 0 2.28 0.4725 2.08 1.125L0 7.875V16.875C0 17.1734 0.105357 17.4595 0.292893 17.6705C0.48043 17.8815 0.734784 18 1 18H2C2.26522 18 2.51957 17.8815 2.70711 17.6705C2.89464 17.4595 3 17.1734 3 16.875V15.75H15V16.875C15 17.1734 15.1054 17.4595 15.2929 17.6705C15.4804 17.8815 15.7348 18 16 18H17C17.2652 18 17.5196 17.8815 17.7071 17.6705C17.8946 17.4595 18 17.1734 18 16.875V7.875L15.92 1.125Z"
            fill="#FC7247" />
        </svg>
        {{ calc.leasingObjectName }}
      </v-tab>
    </v-tabs>
    <v-window v-model="curTab">
      <v-window-item
        v-for="calc in calculations"
        :key="calc.id"
        :value="calc.id">
        <LO
          :calculationSingle="calculationSingle"
          :calculationId="calc.id"
          :readonly="readonly"
          @data-changed="$emit('dataChanged')" />
      </v-window-item>
    </v-window>
  </div>
</template>
<script>
import LO from './LO.vue'
import { mapState } from 'vuex'
export default {
  emits: ['dataChanged'],
  components: {
    LO,
  },
  props: {
    readonly: { type: Boolean },
    calculationSingle: { type: Array },
  },
  data() {
    return {
      curTab: 0,
    }
  },
  computed: {
    ...mapState({
      calculations: state => state.projects.project.calculations,
    }),
  },
}
</script>

<style scoped>
.v-tab {
  height: 33px !important;
  border: 1px solid #c0c1c7;
  letter-spacing: normal;
  font-weight: 400 !important;
  text-transform: uppercase !important;
}
.v-tab.v-tab-item--selected {
  background: #f4f6f9;
  border: 1px solid #c0c1c7;
}

.v-tab.v-tab-item--selected svg path {
  fill: #fc7247;
}
.v-tab svg path {
  fill: gray;
}
</style>
