/**
 * Three step to leasing - section (Static)
 *
 */

const threeSteps = obj => [
  {
    margin: [0, 10, 0, 0],
    columns: [
      {
        width: '3.3%',
        text: '3',
        fontSize: 23,
        margin: [0, 8, 0, 5],
        bold: true,
      },
      {
        text: ' швидких кроки для здiйснення мрiї:'.toUpperCase(),
        fontSize: 15,
        margin: [0, 16, 20, 5],
        bold: true,
      },
    ],
  },
  {
    columns: [
      {
        width: '33.333%',
        columns: [
          {
            absolutePosition: { x: 100, y: 430 },
            width: '0%',
            text: '01',
            fontSize: 65,
            color: '#d9dbd9',
            bold: true,
            font: 'FuturaPT',
          },
          {
            fontSize: 11,
            width: '100%',
            text: 'Оберіть зручний графік платежів'.toUpperCase(),
            margin: [0, -10, 30, 0],
            alignment: 'center',
          },
        ],
        columnGap: 10,
        margin: [0, 40, 0, 0],
      },
      {
        width: '33.333%',
        margin: [0, 40, 0, 0],
        columns: [
          {
            absolutePosition: { x: 265, y: 430 },
            width: '0%',
            text: '02',
            fontSize: 65,
            color: '#d9dbd9',
            bold: true,
            font: 'FuturaPT',
          },
          {
            margin: [0, -10, 0, 0],
            fontSize: 11,
            width: '100%',
            alignment: 'center',
            text: `${
              obj.resultData['oper-leasing']
                ? 'Підпишiть договiр та внесiть нульовий лізинговий платiж'
                : 'Підпишiть договiр та внесiть аванс'
            }`.toUpperCase(),
          },
        ],
        columnGap: 10,
      },
      {
        width: '33.333%',
        margin: [0, 40, 0, 0],
        columns: [
          {
            absolutePosition: { x: 438, y: 430 },
            width: '0%',
            text: '03',
            fontSize: 65,
            color: '#d9dbd9',
            bold: true,
            font: 'FuturaPT',
          },
          {
            margin: [0, -10, 0, 0],
            fontSize: 11,
            width: '100%',
            alignment: 'center',
            text: [
              {
                width: '80%',
                text: 'Керуйте своїм'.toUpperCase(),
              },
              {
                width: '20%',
                text: ' авто'.toUpperCase(),
              },
            ],
          },
        ],
        columnGap: 10,
      },
    ],
  },
]

const fsnThreeSteps = () => [
  {
    margin: [0, 0, 0, 0],
    columns: [
      {
        width: '3.3%',
        text: '3',
        fontSize: 25,
        margin: [0, 8, 0, 5],
        bold: true,
      },
      {
        text: ' швидких кроки для здiйснення мрiї'.toUpperCase(),
        fontSize: 14,
        margin: [0, 16, 0, 5],
        bold: true,
      },
    ],
  },
  {
    columns: [
      {
        width: '33.333%',
        columns: [
          {
            absolutePosition: { x: 50, y: 627 },
            width: '0%',
            text: '01',
            fontSize: 85,
            color: '#d9dbd9',
            bold: true,
            font: 'FuturaPT',
          },
          {
            fontSize: 12,
            width: '100%',
            text: 'Оберіть зручний графік платежів'.toUpperCase(),
            margin: [0, 0, 30, 0],
          },
        ],
        columnGap: 10,
        margin: [0, 50, 0, 5],
      },
      {
        width: '33.333%',
        margin: [0, 50, 0, 5],
        columns: [
          {
            absolutePosition: { x: 265, y: 627 },
            width: '0%',
            text: '02',
            fontSize: 85,
            color: '#d9dbd9',
            bold: true,
            font: 'FuturaPT',
          },
          {
            margin: [30, 0, 0, 0],
            fontSize: 12,
            width: '100%',
            text: 'Підпишiть договiр та внесiть аванс'.toUpperCase(),
          },
        ],
        columnGap: 10,
      },
      {
        width: '33.333%',
        margin: [0, 50, 0, 5],
        columns: [
          {
            absolutePosition: { x: 438, y: 627 },
            width: '0%',
            text: '03',
            fontSize: 85,
            color: '#d9dbd9',
            bold: true,
            font: 'FuturaPT',
          },
          {
            margin: [30, 0, 0, 0],
            fontSize: 12,
            width: '100%',
            text: 'Керуйте своїм авто'.toUpperCase(),
          },
        ],
        columnGap: 10,
      },
    ],
    pageBreak: 'after',
  },
]

export { threeSteps, fsnThreeSteps }
