<template>
  <v-row>
    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
      <span class="label">Місяць</span>
      <DatePicker
        v-model="expenseMarket.date"
        class="task-plan-date-range-fixed mb-3"
        single
        hide-details
        placeholder="Оберіть дату" />
    </v-col>
    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
      <span class="label">Витрата</span>
      <v-text-field
        id="amount"
        v-model="expenseMarket.amount"
        :error-messages="amountErr"
        class="label-top"
        hide-details
        placeholder="Вкажіть витрату"
        dense
        @blur="inputNumber($event, { float: 2, saveFloat: true })"
        @focusout="Input.restoreData('amount')"
        @focusin="Input.hideData('amount')"
        @input="changeInputVal('amount', 'input', [keepDigit, formutSum])">
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { Input, setErrHandler } from '@/utils/helperFunc'
import { minLength, required } from '@vuelidate/validators'
import DatePicker from '@/components/DatePicker.vue'
import { urlCreateExpensesMarketingCompany } from '@/pages/request'
import { useVuelidate } from '@vuelidate/core'
import { maxNumLength } from '@/utils/masks'
import {
  changeInputVal,
  formutSum,
  inputNumber,
  keepDigit,
} from '@/utils/formatFunc'
export default {
  components: {
    DatePicker,
  },
  props: {
    dialog: { type: Object },
  },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      expenseMarket: {
        amount: { required, minLength: minLength(2) },
      },
    }
  },
  data: () => {
    return {
      expenseMarket: {
        date: null,
        amount: null,
      },
      Input: new Input(),
    }
  },
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    validation() {
      return { v$: this.v$.expenseMarket, name: 'expensesMarket' }
    },
    amountErr() {
      return setErrHandler(this.v$.expenseMarket?.amount)
    },
  },
  methods: {
    formutSum,
    keepDigit,
    changeInputVal,
    inputNumber,
    maxNumLength,
    submit() {
      return this.addExpenses()
    },
    addExpenses() {
      const req = { ...this.expenseMarket }
      req.advertisingCampaignId = this.dialog.params.companyId
      req.amount = parseFloat(this.expenseMarket.amount.replace(/\s/g, ''))
      return this.$axios
        .post(urlCreateExpensesMarketingCompany(), req)
        .then(res => {
          this.$setSnackbar({ text: 'Витрату додано' })

          return res
        })
        .catch(err => {
          if (err.response.data.errors) {
            this.$setSnackbar({
              text: JSON.stringify(err.response.data.errors).replace(
                /[{}[\]]/g,
                ''
              ),
              color: 'error',
            })
          }
        })
    },
  },
}
</script>
