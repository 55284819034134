<template>
  <v-card-text>
    <SectionLoader v-if="pageLoad" />
    <div v-if="!pageLoad && faqDocumentListRoute">
      <v-row>
        <v-col :cols="12" :sm="4">
          <v-text-field
            v-show="documents.length"
            v-model="search"
            hide-details
            placeholder="Пошук"
            dense>
          </v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tHead"
        :items="documents"
        class="calc-table --cust-cell-pd mt-3"
        mobileBreakpoint="750"
        dense
        :hide-default-footer="!documents.length"
        :hide-default-header="!documents.length"
        :search="search">
        <!-- eslint-disable-next-line -->
        <template #item.name="{ item }">
          <ALink
            :text="item.name"
            :route="origin + '/' + item.file"
            :params="item"
            download>
          </ALink>
        </template>
        <!-- eslint-disable-next-line -->
        <template #no-data>
          <EmptySection
            :icon="EmptyFile"
            title="Роздiл тимчасово порожнiй"
            :iconWidth="38"
            :iconHeight="38"
            :hideAction="true"
            @click="openDialog" />
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.created_at="{ item }">
          {{ formatDate(item.created_at) }}
        </template>
      </v-data-table>
    </div>
  </v-card-text>
</template>
<script>
import ALink from '@/components/Link.vue'
import EmptySection from '@/components/empty-section.vue'
import EmptyFile from '@/assets/svg/empty-file.vue'
import SectionLoader from '@/components/section-loader.vue'
import { formatDate } from '@/utils/formatFunc'
import {
  urlFaqGetAllDocuments,
  urlFaqDeleteDocumentById,
} from '@/pages/request'
export default {
  components: {
    EmptySection,
    // eslint-disable-next-line
    EmptyFile,
    SectionLoader,
    ALink,
  },
  data: () => ({
    search: null,
    pageLoad: false,
    documents: [],
    tHead: [
      {
        title: 'Назва',
        key: 'name',
        align: 'start',
        sortable: false,
        width: '80%',
      },
      { title: 'Дата', key: 'created_at', align: 'center', sortable: true },
    ],
    EmptyFile,
  }),
  computed: {
    faqDocumentListRoute() {
      return this.$route.name === 'faq-documents'
    },
    origin() {
      return location.origin
    },
  },
  methods: {
    formatDate,
    openDialog() {
      this.$store.commit('setDialog', {
        title: 'Документ',
        dialogItem: {},
        params: {
          cb: this.getDocuments,
        },
        action: 'faqCreateDocument',
      })
    },
    openEditDialog(item) {
      const text = (item.file || '').split('/').pop()
      const url = item.file
      const fileObject = { url, text }
      const document = { ...item, file: fileObject }
      this.$store.commit('setDialog', {
        title: 'Документ',
        dialogItem: document,
        params: {
          cb: this.getDocuments,
        },
        action: 'faqEditDocument',
      })
    },
    openDeleteDialog(item) {
      this.$store.commit('setDialog', {
        title: 'Відео',
        dialogItem: item,
        params: {
          cb: this.getDocuments,
          deleteDocument: this.deleteDocument,
        },
        action: 'faqDeleteDocument',
      })
    },
    getDocuments() {
      this.pageLoad = true

      return this.$axios
        .get(urlFaqGetAllDocuments())
        .then(res => {
          this.documents.splice(0)
          this.documents.push(...res.data)
          this.pageLoad = false
        })
        .catch(err => this.$err(err, () => (this.pageLoad = false)))
    },
    deleteDocument(id) {
      return this.$axios.delete(urlFaqDeleteDocumentById(id)).then(res => {
        this.getDocuments()
        return res
      })
    },
  },
  created() {
    this.getDocuments()
  },
}
</script>
