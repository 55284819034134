<template>
  <VueTimepicker
    :id="id"
    :modelValue="value"
    :disabled="disabled"
    :readonly="readonly"
    placeholder="Оберіть час"
    @update:model-value="updateValue" />
</template>

<script lang="ts">
import VueTimepicker from 'vue3-timepicker/src/VueTimepicker.vue'
import { generateId } from '@/utils/helperFunc'
import { PropType, ref, watch } from 'vue'

export default {
  name: 'TimePicker',
  components: { VueTimepicker },
  emits: ['update:modelValue'],
  props: {
    modelValue: { type: String, default: '' },
    readonly: { type: Boolean as PropType<boolean> },
    disabled: { type: Boolean as PropType<boolean> },
  },
  setup(props, { emit }) {
    const value = ref(null)
    const id = generateId()

    const parseTimeString = timeString => {
      const [HH, mm] = timeString.split(':')
      return { HH, mm }
    }

    const formatTime = timeObj => {
      const hours = timeObj.HH || '00'
      const minutes = timeObj.mm || '00'
      return `${hours}:${minutes}`
    }

    watch(
      () => props.modelValue,
      newVal => {
        if (newVal) {
          value.value = parseTimeString(newVal)
        }
      },
      { immediate: true }
    )

    const updateValue = newValue => {
      const formattedValue = formatTime(newValue)
      emit('update:modelValue', formattedValue)
    }

    return {
      value,
      id,
      updateValue,
    }
  },
}
</script>

<style scoped></style>
