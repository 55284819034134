<template>
  <div>
    <div v-if="dublicateDialog" class="dublicateModal">
      <div class="modal-wrapper">
        <div>
          <div class="pa-3" style="border-bottom: 1px solid #80808040">
            <span style="font-size: 16px; font-weight: 600"
              >Звернiть увагу!</span
            >
          </div>
          <div class="pa-3">
            <span class="d-block"
              >В системi вже iснує контакт
              <ALink
                :route="{
                  name: 'individuals-form',
                  params: { id: duplicateId },
                }"
                :text="contactName" />
            </span>
            <span class="d-block">Продовжити створення нового контакту?</span>
          </div>
          <div class="pa-3 pt-2 d-flex" style="justify-content: end">
            <v-btn id="duplicate-reject" class="cancel-btn mr-2" dense
              >Відмiнити</v-btn
            >
            <v-btn id="duplicate-confirm" class="agree-btn" dense
              >Продовжити</v-btn
            >
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        addContactFromApplication ||
        addContact ||
        createContactToCareer ||
        addContactToCareer ||
        addContractorContact ||
        addApplicationContact ||
        chooseContractorContact
      "
      class="mb-3">
      <v-row>
        <v-col
          v-if="
            !addContactToCareer &&
            !addApplicationContact &&
            !chooseContractorContact
          "
          :cols="12"
          :md="12"
          class="pb-0 pt-0">
          <div>
            <span class="label">Прізвище</span>
            <v-text-field
              v-model="newContact.surname"
              :error-messages="
                createContactToCareer || addContactFromApplication
                  ? surnameErr
                  : ''
              "
              placeholder="Введіть текст"
              required
              hide-details
              :loading="duplicatesLoading.surname"
              dense
              @blur="
                () => {
                  v$.newContact.surname && v$.newContact.surname.$touch()
                  checkNameSurname()
                }
              "
              @keyup.enter="$emit('submit')">
            </v-text-field>
          </div>
        </v-col>
        <v-col
          v-if="
            !addContactToCareer &&
            !addApplicationContact &&
            !chooseContractorContact
          "
          :cols="12"
          :md="12"
          class="pb-0">
          <div>
            <span class="label">Iм`я</span>
            <v-text-field
              v-model="newContact.name"
              hide-details
              :error-messages="nameErr"
              placeholder="Введіть текст"
              dense
              @blur="
                () => {
                  v$.newContact.name && v$.newContact.name.$touch()
                  checkNameSurname()
                }
              "
              @keyup.enter="$emit('submit')">
            </v-text-field>
          </div>
        </v-col>
        <v-col
          v-if="
            !addContactFromApplication &&
            !addContactToCareer &&
            !addApplicationContact &&
            !chooseContractorContact
          "
          :cols="12"
          :md="12"
          class="pb-0">
          <div>
            <span class="label">По батькові</span>
            <v-text-field
              v-model="newContact.patronymic"
              :error-messages="createContactToCareer ? patronymicErr : ''"
              placeholder="Введіть текст"
              hide-details
              dense
              @blur="
                v$.newContact.patronymic && v$.newContact.patronymic.$touch()
              "
              @keyup.enter="submit()">
            </v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row
        v-if="
          newContact.inn &&
          !addContactToCareer &&
          !addApplicationContact &&
          !chooseContractorContact
        ">
        <v-col cols="12" md="12" sm="12" class="pb-0">
          <v-text-field
            id="c-c-d-inn"
            v-model="newContact.inn"
            :error-messages="innErr"
            label="IНН"
            dense
            hide-details
            @input="maxNumLength('c-c-d-inn', 10)"
            @blur="v$.newContact.inn.$touch()"
            @keyup.enter="submit()">
          </v-text-field>
        </v-col>
      </v-row>
      <v-row
        v-if="
          addContactToCareer || addApplicationContact || chooseContractorContact
        ">
        <v-col cols="12" md="12" sm="12" class="pb-0">
          <span class="label">Контакт</span>
          <b-server-autocomplete
            v-model="newContact.contactId"
            :url="urlContactsGetAll()"
            :search-min-length="3"
            :error-messages="contactIdErr"
            item-title="fullName"
            item-value="id"
            :menu-props="{ offset: '5px', zIndex: 1000000000 }"
            placeholder="Введіть текст (мін. 3 символи)"
            hide-details
            @update:model-value="(_, obj) => mergeAvailableFields(obj)"
            @keyup.enter="submit()"
            @blur="v$.newContact.contactId && v$.newContact.contactId.$touch()">
          </b-server-autocomplete>
        </v-col>
      </v-row>
      <!-- chooseContractorContact || createContactToCareer || addContactToCareer && !addApplicationContact -->
      <v-row v-if="chooseContractorContact || createContactToCareer">
        <v-col cols="12" md="12" sm="12" class="pb-0">
          <span class="label">Посада</span>
          <v-autocomplete
            v-model="newContact.postId"
            placeholder="Оберіть зi списку"
            :error-messages="postIdErr"
            :items="$directory.get('careerPosts')"
            item-title="name"
            item-value="id"
            hide-details
            dense
            :loading="$loading.isLoading('careerPosts')"
            @update:model-value="
              newContact.fullPostName = $directory
                .get('careerPosts', null, { type: 'collection' })
                .getItem($event)?.name
            "
            @blur="v$.newContact.postId && v$.newContact.postId.$touch()"
            @focus="$directory.fill('careerPosts')">
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row
        v-if="
          !addContactToCareer &&
          !addApplicationContact &&
          !chooseContractorContact &&
          agentType
        ">
        <v-col cols="12" md="12" sm="12" class="pb-0">
          <span class="label">Дилерська точка</span>
          <b-server-autocomplete
            v-model="newContact.dealerPointId"
            :url="urlGetDealerOutlets()"
            :search-min-length="3"
            :default-item="newContact.dealerPoint"
            class="w-100"
            item-value="id"
            item-title="name"
            return-object
            :menu-props="{ zIndex: 1000000000, maxWidth: 300 }"
            placeholder="Введіть текст (мін. 3 символи)"
            hide-details
            @keyup.enter="submit()">
          </b-server-autocomplete>
        </v-col>
      </v-row>
      <div
        v-if="!dialog.params.hideContractor || !dialog.params.hideConnections"
        class="mt-3 d-flex align-center">
        <span style="color: #09487a">Зв'язки</span>
        <v-tooltip bottom text="Додати контрагента">
          <template #activator="{ props }">
            <div v-bind="props">
              <v-btn icon size="small" @click="openContractorList">
                <v-icon>{{
                  contractorDialogList ? 'mdi-close' : 'mdi-plus'
                }}</v-icon>
              </v-btn>
            </div>
          </template>
        </v-tooltip>
      </div>
      <div
        v-if="
          !dialog.params.hideConnections ||
          (!dialog.params.hideContractor &&
            !addApplicationContact &&
            !chooseContractorContact)
        ">
        <div v-if="contractorDialogList">
          <span class="label mt-2">Контрагент</span>
          <div class="d-flex align-center">
            <b-server-autocomplete
              v-model="newContact.contractorId"
              :url="{
                url: urlGetAllContractors(),
                query: {
                  byAccess: false,
                },
              }"
              :search-min-length="3"
              class="w-100"
              item-title="shortName"
              item-value="id"
              :menu-props="{ zIndex: 1000000000, maxWidth: 300 }"
              placeholder="Введіть текст (мін. 3 символи)"
              hide-details
              :disabled="!!newContact.contractorId"
              :readonly="
                addContactFromApplication ||
                addContractorContact ||
                createContactToCareer ||
                addContactToCareer
              "
              :filter="contractorFilter"
              @keyup.enter="submit()">
              <template #append>
                <v-btn
                  v-if="
                    !!!newContact.contractorId &&
                    newContact.contractorId &&
                    !addContractorContact &&
                    !chooseContractorContact &&
                    !createContactToCareer &&
                    !addContactToCareer
                  "
                  size="small"
                  icon
                  @click="newContact.contractorId = null">
                  <v-icon size="small">{{ 'mdi-close' }}</v-icon>
                </v-btn>
              </template>
            </b-server-autocomplete>
            <v-btn
              v-if="newContact.contractorId"
              size="small"
              icon
              @click="closeContractorBlock">
              <v-icon size="small">{{ 'mdi-close' }}</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <v-row
        v-if="
          !addContactFromApplication &&
          !createContactToCareer &&
          !addContactToCareer &&
          !addContractorContact &&
          !(addContact && newContact.contractorId) &&
          !addApplicationContact &&
          !chooseContractorContact
        "
        v-show="newContact.contractorId"
        class="mt-0">
        <v-col cols="12" md="12" sm="12" class="pb-0">
          <span class="label mt-2">Посада</span>
          <v-text-field
            v-model="newContact.contactorPost"
            hide-details
            :error-messages="newContactContactorPostErr"
            placeholder="Введіть текст"
            dense
            @blur="v$.newContact.contactorPost.$touch()">
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-if="
            addContactFromApplication ||
            createContactToCareer ||
            addContactToCareer ||
            addContractorContact ||
            (addContact && newContact.contractorId)
          "
          :cols="12"
          :md="12"
          class="pb-0">
          <span class="label mt-2">Посада</span>
          <v-autocomplete
            v-model="newContact.postId"
            placeholder="Оберіть зi списку"
            :error-messages="postIdErr"
            :items="$directory.get('careerPosts')"
            item-title="name"
            hide-details
            item-value="id"
            dense
            :loading="$loading.isLoading('careerPosts')"
            @update:model-value="
              newContact.fullPostName = $directory
                .get('careerPosts', null, { type: 'collection' })
                .getItem($event)?.name
            "
            @blur="v$.newContact.postId && v$.newContact.postId.$touch()"
            @focus="$directory.fill('careerPosts')">
          </v-autocomplete>
        </v-col>
        <v-col
          v-if="
            addContactFromApplication ||
            createContactToCareer ||
            addContactToCareer ||
            addContractorContact ||
            (addContact && newContact.contractorId) ||
            chooseContractorContact
          "
          :cols="12"
          :md="12"
          class="pb-0">
          <span class="label">Повна назва посади</span>
          <v-text-field
            v-model="newContact.fullPostName"
            :error-messages="fullPostNameErr"
            placeholder="Введіть текст"
            hide-details
            dense
            @blur="
              v$.newContact.fullPostName && v$.newContact.fullPostName.$touch()
            ">
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-if="
            addContactFromApplication ||
            createContactToCareer ||
            addContactToCareer ||
            addContractorContact ||
            (addContact && newContact.contractorId) ||
            chooseContractorContact
          "
          :cols="12"
          :md="12"
          class="pb-0">
          <span class="label">Департамент</span>
          <v-autocomplete
            v-model="newContact.departmentId"
            :error-messages="departmentIdErr"
            placeholder="Оберіть зi списку"
            :items="$directory.get('careerDepartments')"
            item-title="name"
            hide-details
            item-value="id"
            dense
            :loading="$loading.isLoading('careerDepartments')"
            @focus="$directory.fill('careerDepartments')"
            @blur="
              v$.newContact.departmentId && v$.newContact.departmentId.$touch()
            ">
          </v-autocomplete>
        </v-col>
        <v-col
          v-if="
            addContactFromApplication ||
            createContactToCareer ||
            addContactToCareer ||
            addContractorContact ||
            (addContact && newContact.contractorId) ||
            chooseContractorContact
          "
          :cols="12"
          :md="12"
          class="pb-0">
          <span class="label">Роль</span>
          <v-autocomplete
            v-model="newContact.roleId"
            :error-messages="roleIdErr"
            hide-details
            placeholder="Оберіть зi списку"
            :items="$directory.get('careerRoles')"
            item-title="name"
            item-value="id"
            dense
            :loading="$loading.isLoading('careerRoles')"
            @focus="$directory.fill('careerRoles')"
            @blur="v$.newContact.roleId && v$.newContact.roleId.$touch()">
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-if="agentType || addContractorContact"
          :cols="12"
          :md="12"
          class="pb-0">
          <span class="label mt-2">Телефон</span>
          <v-text-field
            id="c-c-d-phone"
            v-model="newContact.mainPhone"
            :error-messages="mainPhoneErr"
            placeholder="Введіть номер"
            :loading="duplicatesLoading.phone"
            :readonly="chooseContractorContact"
            dense
            hide-details
            @input="phoneMask($event, 'c-c-d-phone')"
            @blur="
              () => {
                v$.newContact.mainPhone && v$.newContact.mainPhone.$touch()
                duplicatesLoading.phone = true

                checkDuplicates(newContact.mainPhone, 'phone')
                  .then(res =>
                    res.hasDuplicates
                      ? (createErrorMsg.phone =
                          'Контакт з таким телефоном вже існує')
                      : (createErrorMsg.phone = null)
                  )
                  .finally(() => (duplicatesLoading.phone = false))
              }
            "
            @keyup.enter="submit()">
          </v-text-field>
        </v-col>
        <v-col
          v-if="agentType || addContractorContact"
          :cols="12"
          :md="12"
          class="pb-0">
          <span class="label mt-2">Email</span>
          <v-text-field
            v-model="newContact.mainEmail"
            v-mask:[emailMask]
            :error-messages="mainEmailErr"
            placeholder="Введіть email"
            dense
            hide-details
            :loading="duplicatesLoading.email"
            :readonly="chooseContractorContact"
            @blur="
              () => {
                v$.newContact.mainEmail && v$.newContact.mainEmail.$touch()
                duplicatesLoading.email = true
                checkDuplicates(newContact.mainEmail, 'email')
                  .then(res =>
                    res.hasDuplicates
                      ? (createErrorMsg.email =
                          'Контакт з такою поштою вже існує')
                      : (createErrorMsg.email = null)
                  )
                  .finally(() => (duplicatesLoading.email = false))
              }
            "
            @keyup.enter="submit()">
          </v-text-field>
        </v-col>
      </v-row>
      <v-text-field
        v-if="addContactFromApplication"
        id="c-c-d-phone"
        v-model="newContact.phone"
        :error-messages="phoneErr"
        label="Телефон"
        required
        :readonly="chooseContractorContact"
        dense
        @input="phoneMask($event, 'c-c-d-phone')"
        @blur="
          () => {
            v$.newContact.phone && v$.newContact.phone.$touch()
          }
        "
        @keyup.enter="$emit('submit')">
      </v-text-field>
      <v-text-field
        v-if="addContactFromApplication"
        v-model="newContact.email"
        v-mask:[emailMask]
        :error-messages="emailErr"
        label="Email"
        dense
        required
        :readonly="chooseContractorContact"
        @blur="v$.newContact.email && v$.newContact.email.$touch()"
        @keyup.enter="$emit('submit')">
      </v-text-field>
      <div v-if="createContactToCareer">
        <v-checkbox
          v-if="signatureActionType === 'edit'"
          v-model="newContact.isSignatureRight"
          :label="'Підписант'"></v-checkbox>
      </div>

      <div v-if="newContact.isSignatureRight && createContactToCareer">
        На підставі
        <v-btn
          color="grey darken-3"
          dark
          icon
          style="transition: 0.3s all"
          :style="{ transform: openReason ? 'rotate(45deg)' : 'rotate(0deg)' }"
          @click="openReason = !openReason">
          <v-icon :size="22">
            {{ 'mdi-plus-circle-outline' }}
          </v-icon>
        </v-btn>
        <div v-if="openReason" style="margin-bottom: 15px">
          <v-select
            v-model="newSignatureType"
            :items="
              $directory
                .get('basisDocuments')
                .filter(b => [1, 2, 8].includes(b.id))
            "
            :loading="$loading.isLoading('basisDocuments')"
            item-title="name"
            :error-messages="signatoryBasesErr"
            item-value="id"
            :label="'На підставі'"
            dense
            @blur="v$.newContact.signatoryBases.$touch()">
          </v-select>
          <v-text-field
            v-if="[2, 8].includes(newSignatureType)"
            v-model="newSignatureName"
            :label="newSignatureType === 2 ? 'Довіреність' : 'Наказ'">
          </v-text-field>
          <v-btn size="small" @click="changeSignatureReason">
            {{ signatureActionType === 'add' ? 'Додати' : 'Зберегти' }}
          </v-btn>
        </div>
        <v-data-table
          v-if="
            newContact.signatoryBases && newContact.signatoryBases.length !== 0
          "
          :headers="careerTHead"
          :items="newContact.signatoryBases"
          hide-default-footer
          hide-default-header>
          <!-- eslint-disable-next-line -->
          <template #item.basisDocumentId="{ item }">
            {{
              $directory
                .get('basisDocuments', null, { type: 'collection' })
                .getItem(item.basisDocumentId)?.name || '---'
            }}
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.documentComment="{ item }">
            {{ item.documentComment }}
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.actions="{ item }">
            <TableCrud
              :actionsArray="[
                // { action: () => deleteSignature(item), text: 'Видалити', icon: 'mdi-close'},
                {
                  action: () => editSignature(item),
                  text: 'Редагувати',
                  icon: 'mdi-pencil',
                },
              ]">
            </TableCrud>
          </template>
        </v-data-table>
        <no-table-data v-else></no-table-data>
      </div>
    </div>

    <div v-if="deleteMainContact">
      <p>
        Контакт <strong>{{ dialogItem.fullName }}</strong> буде відкрiплено
      </p>
    </div>
    <div v-if="deleteContact">
      <span style="font-size: 1rem">
        Контакт -
        <i style="text-decoration: underline">
          {{ `${dialogItem.fullName}` }}
        </i>
        буде видалено
      </span>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import {
  urlCreateNewContact,
  urlContactCreateCareer,
  urlDeleteContractorContact,
  urlContractorAddContact,
  urlCreateContactFromApplication,
  urlGetAllContractors,
  urlContactsGetAll,
  urlGetDealerOutlets,
} from '@/pages/request'
import { phoneMask, maxNumLength } from '@/utils/masks'
import { emailMask } from 'best-modules/utils'
import { required, minLength, email } from '@vuelidate/validators'
import {
  setErrHandler,
  contractorFilter,
  contactFilter,
  getContactInitials,
  contactDetailsWithType,
  checkDuplicates,
  getSafe,
} from '@/utils/helperFunc'
import ALink from '@/components/Link.vue'
import NoTableData from '@/components/no-table-data.vue'
import TableCrud from '@/components/table-crud.vue'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
import { fillDirectory } from '@/plugins/directory'
import { BServerAutocomplete } from 'best-modules/components'

export default {
  components: { TableCrud, NoTableData, ALink, BServerAutocomplete },
  emits: ['submit'],
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  props: { dialog: { type: Object } },
  validations() {
    const addContactFromApplication = this.addContactFromApplication
      ? {
          contractorId: { required },
          fullPostName: { required },
          postId: { required },
          departmentId: { required },
        }
      : {}
    const createContactCareer = this.createContactToCareer
      ? {
          contractorId: { required },
          fullPostName: { required },
          postId: { required },
          signatoryBases: this.newContact.isSignatureRight ? { required } : {},
        }
      : {}
    const addContractorContact = this.addContractorContact
      ? {
          contractorId: { required },
          fullPostName: { required },
          postId: { required },
        }
      : {}
    const addContactCareer =
      this.addContactToCareer ||
      this.createContactToCareer ||
      this.addApplicationContact
        ? {
            contractorId: (() => {
              return this.addApplicationContact ? {} : { required }
            })(),
            contactId: (() => {
              return this.addContactToCareer || this.addApplicationContact
                ? { required }
                : {}
            })(),
            fullPostName: (() => {
              return this.addContactToCareer ? { required } : {}
            })(),
            departmentId: (() => {
              return this.addContactToCareer ? { required } : {}
            })(),
            postId: (() => {
              return this.addContactToCareer ? { required } : {}
            })(),
            roleId: (() => {
              return this.addContactToCareer ? { required } : {}
            })(),
          }
        : {}
    const chooseContractorContact = this.chooseContractorContact
      ? {
          contactId: { required },
          fullPostName: { required },
          departmentId: { required },
          postId: { required },
        }
      : {}
    const addContact =
      this.addContractorContact ||
      (this.addContact && this.newContact.contractorId)
        ? {
            contractorId: { required },
            fullPostName: this.newContact.contractorId ? { required } : {},
            postId: this.newContact.contractorId ? { required } : {},
            departmentId: this.newContact.contractorId ? { required } : {},
          }
        : {}
    const mainPhone =
      this.agentType || this.newContact.mainPhone
        ? { required, minLength: minLength(23) }
        : {}
    const mainEmail =
      this.agentType || this.newContact.mainEmail
        ? { required, minLength: email }
        : {}
    const phoneEmail = this.addContactFromApplication
      ? {
          phone: { required, minLength: minLength(23) },
          email: { required, email },
        }
      : {}

    return this.addContactFromApplication ||
      this.addContact ||
      this.createContactToCareer ||
      this.addContactToCareer ||
      this.addContractorContact ||
      this.addApplicationContact ||
      this.chooseContractorContact
      ? {
          newContact: {
            name: (() => {
              if (
                !this.addContactToCareer &&
                !this.addApplicationContact &&
                !this.chooseContractorContact
              ) {
                return { required }
              } else return true
            })(),
            surname: (() => {
              if (this.addContactFromApplication) {
                return { required }
              } else return true
            })(),
            contactorPost: (() => {
              if (
                this.newContact.contractorId &&
                !this.addContactFromApplication &&
                !this.createContactToCareer &&
                !this.addContactToCareer &&
                !this.addContractorContact &&
                !(this.addContact && this.newContact.contractorId) &&
                !this.chooseContractorContact
              ) {
                return { required }
              } else return true
            })(),
            inn: (() => {
              if (this.inn) {
                return {
                  required,
                  minLength: minLength(10),
                }
              } else return true
            })(),
            mainPhone,
            mainEmail,
            ...addContactFromApplication,
            ...createContactCareer,
            ...addContactCareer,
            ...addContact,
            ...addContractorContact,
            ...chooseContractorContact,
            ...phoneEmail,
          },
        }
      : {}
  },
  data: () => ({
    contractorDialogList: false,
    newContact: {
      isSignatureRight: 0,
      signatoryBases: [],
    },
    openReason: false,
    dublicateDialog: false,
    hasDuplicates: false,
    newSignatureName: null,
    duplicateId: null,
    newSignatureType: 1,
    signatureActionType: 'add',
    duplicatesLoading: {
      surname: false,
      phone: false,
      email: false,
    },
    createErrorMsg: {
      email: null,
      phone: null,
    },
    careerTHead: [
      {
        title: 'Тип',
        key: 'basisDocumentId',
        align: 'start',
        sortable: false,
      },
      {
        title: 'Довіреність',
        key: 'documentComment',
        align: 'start',
        sortable: false,
      },
      { title: 'Дiї', key: 'actions', align: 'start', sortable: false },
    ],
    emailMask,
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    validation() {
      return { v$: this.v$.newContact, name: 'contactCrud' }
    },
    dialogItem() {
      return this.dialog.dialogItem
    },
    addContact() {
      return this.dialog.action === 'addContact'
    },
    addContactFromApplication() {
      return this.dialog.action === 'addContactFromApplication'
    },
    deleteContact() {
      return this.dialog.action === 'deleteContact'
    },
    deleteMainContact() {
      return this.dialog.action === 'deleteMainContact'
    },
    createContactToCareer() {
      return this.dialog.action === 'createContactToCareer'
    },
    addContactToCareer() {
      return this.dialog.action === 'addContactToCareer'
    },
    addContractorContact() {
      return this.dialog.action === 'addContractorContact'
    },
    addApplicationContact() {
      return this.dialog.action === 'addApplicationContact'
    },
    chooseContractorContact() {
      return this.dialog.action === 'chooseContractorContact'
    },
    agentType() {
      return this.$array(() => this.newContact.contactTypeId).includes(2)
    },
    hasNameSurname() {
      return !!this.newContact.surname && !!this.newContact.name
    },
    innErr() {
      const errors = []
      if (!this.v$.newContact.inn.$error) return errors
      !this.v$.newContact.inn.required && errors.push('Обов`язкове поле')
      !this.v$.newContact.inn.minLength && errors.push('10 цифр')
      return errors
    },
    contactName() {
      return `${this.newContact?.surname || ''} ${
        this.newContact?.name || ''
      } ${this.newContact?.patronymic || ''}`
    },
    nameErr() {
      return setErrHandler(this.v$.newContact?.name)
    },
    counterpartyErr() {
      return setErrHandler(this.v$.newContact?.counterparty)
    },
    newContactContactorPostErr() {
      return setErrHandler(this.v$.newContact?.contactorPost)
    },
    signatoryBasesErr() {
      return setErrHandler(this.v$.newContact.signatoryBases)
    },
    surnameErr() {
      return setErrHandler(this.v$.newContact?.surname)
    },
    patronymicErr() {
      return setErrHandler(this.v$.newContact?.patronymic)
    },
    contractorIdErr() {
      return setErrHandler(this.v$.newContact?.contractorId)
    },
    mainPhoneErr() {
      return setErrHandler(this.v$.newContact?.mainPhone)
    },
    phoneErr() {
      return setErrHandler(this.v$.newContact?.phone)
    },
    mainEmailErr() {
      return setErrHandler(this.v$.newContact?.mainEmail)
    },
    emailErr() {
      return setErrHandler(this.v$.newContact?.email)
    },
    fullPostNameErr() {
      return setErrHandler(this.v$.newContact?.fullPostName)
    },
    postIdErr() {
      return setErrHandler(this.v$.newContact?.postId)
    },
    contactIdErr() {
      return setErrHandler(this.v$.newContact?.contactId)
    },
    departmentIdErr() {
      return setErrHandler(this.v$.newContact?.departmentId)
    },
    roleIdErr() {
      return setErrHandler(this.v$.newContact?.roleId)
    },
  },
  methods: {
    urlGetDealerOutlets,
    urlContactsGetAll,
    urlGetAllContractors,
    getSafe,
    contactFilter,
    contractorFilter,
    phoneMask,
    maxNumLength,
    urlCreateContactFromApplication,
    checkDuplicates,
    contactDetailsWithType,
    getContactInitials,
    editSignature(item) {
      this.openReason = true
      this.newSignatureType = item.basisDocumentId
      this.newSignatureName = item.documentComment
      this.editedSignatureReason = item.id
      this.signatureActionType = 'edit'
    },
    changeSignatureReason() {
      if (this.newContact.signatoryBases === null) {
        this.newContact.signatoryBases = []
      }
      if (this.signatureActionType === 'add') {
        if (
          this.newSignatureType === 1 &&
          this.newContact.signatoryBases.findIndex(
            e => e.basisDocumentId === 1
          ) > -1
        ) {
          return this.$setSnackbar({
            text: 'Статут вже доданий',
            color: 'warning',
          })
        }
        this.newContact.signatoryBases.push({
          basisDocumentId: this.newSignatureType,
          documentComment: this.newSignatureName,
          id: this.generateSignatoryId(),
        })
      } else if (this.signatureActionType === 'edit') {
        this.newContact.signatoryBases = this.newContact.signatoryBases.map(
          el => {
            if (el.id === this.editedSignatureReason) {
              return {
                basisDocumentId: this.newSignatureType,
                documentComment: this.newSignatureName,
                id: el.id,
              }
            } else {
              return el
            }
          }
        )
        this.openReason = false
        this.editedSignatureReason = null
        this.signatureActionType = 'add'
      }
      this.openReason = false
      this.newSignatureType = 1
      this.newSignatureName = null
    },
    generateSignatoryId() {
      const l = this.newContact.signatoryBases.length
      return l > 0 ? this.newContact.signatoryBases[l - 1].id + 1 : 1
    },
    mergeAvailableFields(contact) {
      if (contact) {
        this.newContact.fullPostName = contact?.contactorPost
        this.newContact.mainPhone = contact?.mainPhone
        this.newContact.mainEmail = contact?.mainEmail
        this.newContact.departmentId = contact?.departmentId
        // application mainContact
        this.newContact.inn = contact?.inn
        this.newContact.id = contact?.id
        this.newContact.fullName = contact?.fullName
        this.newContact.phones = contact?.phones || []
        this.newContact.emails = contact?.emails || []
      }
    },
    checkNameSurname() {
      if (this.hasNameSurname) {
        this.duplicatesLoading.surname = true
        checkDuplicates(`${this.newContact.surname} ${this.newContact.name}`)
          .then(({ hasDuplicates, duplicate }) => {
            this.duplicateId = duplicate[0].id || null
            this.duplicatesLoading.surname = false
            this.hasDuplicates = hasDuplicates
          })
          .catch(error => {
            this.duplicatesLoading.surname = false
            this.hasDuplicates = false

            console.log(error)
          })
      }
    },
    openContractorList() {
      this.contractorDialogList = !this.contractorDialogList
    },
    closeContractorBlock() {
      this.newContact.contractorId = null
      this.contractorDialogList = false
    },
    checkContactDublicate() {
      return new Promise((resolve, reject) => {
        console.log(reject)
        if (this.hasDuplicates) {
          this.dublicateDialog = true
          this.$nextTick(() => {
            document
              .getElementById('duplicate-confirm')
              .addEventListener('click', () => {
                resolve(true)
              })
            document
              .getElementById('duplicate-reject')
              .addEventListener('click', () => {
                resolve(false)
              })
          })
        } else {
          resolve()
        }
      })
    },
    createContact() {
      this.newContact.fullName = this.contactName
      return this.checkContactDublicate().then(isContinue => {
        this.dublicateDialog = false
        if (isContinue !== false) {
          return this.$axios
            .post(urlCreateNewContact(), this.newContact)
            .then(res => {
              return res?.data?.message ? Promise.reject(res.data.message) : res
            })
            .catch(this.$err)
        } else {
          return Promise.resolve()
        }
      })
    },
    createContactFromApplication() {
      const url = this.newContact.applicationId
        ? urlCreateContactFromApplication(this.newContact.applicationId)
        : urlCreateNewContact()
      return this.$axios.post(url, this.newContact).catch(this.$err)
    },
    handleSubmit() {
      switch (true) {
        case this.addContactFromApplication:
          return this.createContactFromApplication()
        case this.addContact:
          return this.createContact()
        case this.deleteMainContact:
          return this.deleteContractorContact()
        case this.createContactToCareer:
          return this.createContactCareer()
        case this.addContactToCareer:
          return this.createContactCareer()
        case this.addContractorContact:
          return this.addMainContact()
        case this.addApplicationContact:
          return Promise.resolve(this.newContact)
        case this.chooseContractorContact:
          return this.addMainContact()
        default:
          return Promise.reject('No handler')
      }
    },
    createContactCareer() {
      this.newContact.employeeSignatureBases = this.newContact.signatoryBases
      return this.$axios
        .post(urlContactCreateCareer(), this.newContact)
        .then(res => {
          this.$setSnackbar({ text: "Додано кар'єру" })
          this.$store.commit('refreshPage')
          return res
        })
        .catch(this.$err)
    },
    deleteContractorContact() {
      return this.$axios
        .get(urlDeleteContractorContact(this.contractorId))
        .then(() => {
          this.$setSnackbar({ text: 'Контакт відкрiплено' })
          this.$store.commit('refreshPage')
        })
        .catch(this.$err)
    },
    addMainContact() {
      return this.$axios
        .post(
          urlContractorAddContact(this.newContact.contractorId),
          this.newContact
        )
        .then(res => {
          this.$setSnackbar({ text: 'Контакт закрiплено' })
          this.$store.commit('refreshPage')
          return res
        })
        .catch(this.$err)
    },
    submit() {
      return this.createErrorMsg.email || this.createErrorMsg.phone
        ? { message: this.createErrorMsg.email || this.createErrorMsg.phone }
        : this.handleSubmit()
    },
  },
  created() {
    fillDirectory('basisDocuments')
    Object.assign(this.newContact, this.dialogItem)
  },
}
</script>

<style scoped>
.dublicateModal {
  width: 100%;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background: #00000033;
}
.modal-wrapper {
  width: 400px;
  background: white;
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}
.cancel-btn {
  background: transparent !important;
  color: #13558c;
  border: 1px solid #6b6c6c29;
  height: 30px !important;
  border-radius: 5px;
  width: 140px;
  font-size: 12px !important;
}
.agree-btn {
  background: #13558c !important;
  color: #ffffff;
  height: 30px !important;
  border-radius: 5px;
  width: 140px;
  font-size: 12px !important;
}
</style>
