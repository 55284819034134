<template>
  <div>
    <component
      :is="isContractor ? 'ContractorCard' : 'ContactCard'"
      :contact="guarantor.contact"
      finDocs
      :isNew="rdAnalyze.status?.id === 1 && roleAccess"
      :contractor="guarantor.contractor">
      <template #main-info>
        <FilesUploader
          label="Заявка анкета поручителя"
          :documents="guarantor.guarantorApplicationForm"
          readonly />
      </template>
    </component>
  </div>
</template>

<script>
import ContractorCard from '@/components/ContractorCard.vue'
import ContactCard from '@/components/ContactCard.vue'
import { mapState } from 'vuex'
import FilesUploader from '@/components/FilesUploader.vue'

export default {
  components: {
    FilesUploader,
    ContractorCard,
    ContactCard,
  },
  props: {
    guarantors: { type: Array },
    rdAnalyze: { type: Object },
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    roleAccess() {
      return [2, 7, 8].includes(this.user?.role?.id)
    },
    guarantor() {
      return this.guarantors[this.$route.params.idx - 1]
    },
    isContractor() {
      return this.guarantor.guarantorTypeId === 2
    },
  },
}
</script>

<style scoped></style>
