<template>
  <div>
    <div>
      <div>
        <span class="label">Тип застави</span>
        <v-select
          v-model="project.pledgeTypeId"
          item-title="name"
          item-value="id"
          placeholder="Оберіть зі списку"
          :error-messages="getErrorMessages(v$.project.pledgeTypeId)"
          :hide-details="!v$.project.pledgeTypeId.$error"
          :loading="$loading.isLoading('pledgeTypes')"
          :items="$directory.get('pledgeTypes').filter(t => t.id !== 3)"
          @blur="v$.project.pledgeTypeId.$touch()">
        </v-select>
      </div>
      <div>
        <span class="label">Заставодавець</span>
        <b-server-autocomplete
          v-model="project.mortgagor"
          :url="urlGetMortgagorList()"
          :search-min-length="3"
          :error-messages="getErrorMessages(v$.project.mortgagor)"
          :item-value="
            deleteProjectContactMortgagor || deleteProjectContractorMortgagor
              ? 'name'
              : 'uuid'
          "
          placeholder="введіть імя/назву заставодався (мін. 3 символи)"
          :hide-details="!v$.project.mortgagor.$error"
          item-title="name"
          return-object
          :disabled="
            deleteProjectContactMortgagor || deleteProjectContractorMortgagor
          "
          :readonly="
            deleteProjectContactMortgagor || deleteProjectContractorMortgagor
          "
          @blur="v$.project.mortgagor.$touch()">
          <template #item="{ item, props }">
            <v-list-item
              :title="item.title"
              :value="item.value"
              :prepend-icon="
                item.raw.mortgagorTypeId === 1
                  ? 'mdi-account-box-outline'
                  : 'mdi-domain'
              "
              v-bind="props"></v-list-item>
          </template>
          <template #selection="{ item }">
            <div class="d-flex align-center gap-3">
              <v-icon>{{
                item.raw.mortgagorTypeId === 1
                  ? 'mdi-account-box-outline'
                  : 'mdi-domain'
              }}</v-icon>
              {{ item.title }}
            </div>
          </template>
        </b-server-autocomplete>
      </div>
    </div>
    <h3
      v-if="deleteProjectContactMortgagor || deleteProjectContractorMortgagor">
      {{
        project.pledgeTypeId === 1
          ? 'Заставодавця буде видалено!'
          : 'Заставу майнових прав буде видалено'
      }}
    </h3>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { required } from '@vuelidate/validators'
import { BServerAutocomplete } from 'best-modules/components'
import {
  urlCreateContactMortgagor,
  urlCreateContractorMortgagor,
  urlDeleteContactMortgagor,
  urlDeleteContractorMortgagor,
  ulrCreatePledgeContract,
  urlGetMortgagorList,
} from '@/pages/request'
import { useVuelidate } from '@vuelidate/core'
import { getErrorMessages } from 'best-modules/utils'
import { fillDirectory } from '@/plugins/directory/index.ts'

export default {
  components: { BServerAutocomplete },
  setup() {
    return { v$: useVuelidate() }
  },
  props: { dialog: { type: Object } },
  validations() {
    const projectId = this.addProjectPledge ? { required } : {}
    // const pledgeObject = this.addProjectPledge && this.project.pledgeTypeId !== 2 ? { required } : {}
    return {
      project: {
        pledgeTypeId: { required },
        mortgagor: { required },
        projectId: projectId,
        // pledgeObject: pledgeObject,
      },
    }
  },
  data: () => ({ project: {} }),
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    validation() {
      return { v$: this.v$.project, name: 'projectMortgagors' }
    },
    addProjectPledge() {
      return this.dialog.action === 'addProjectPledge'
    },
    deleteProjectContractorMortgagor() {
      return this.dialog.action === 'deleteProjectContractorMortgagor'
    },
    deleteProjectContactMortgagor() {
      return this.dialog.action === 'deleteProjectContactMortgagor'
    },
    sendObject() {
      if (this.project.mortgagor) {
        const pledgeObject =
          this.project.pledgeTypeId === 1
            ? { pledgeObject: this.project.pledgeObject }
            : {}
        return {
          mortgagorTypeId: this.project.mortgagor.mortgagorTypeId,
          mortgagorId: this.project.mortgagor.mortgagorId,
          projectId: this.project.projectId,
          typeId: this.project.mortgagor.mortgagorTypeId,
          ...pledgeObject,
          pledgeTypeId: this.project.pledgeTypeId,
        }
      } else {
        return null
      }
    },
  },
  methods: {
    getErrorMessages,
    urlGetMortgagorList,
    submit() {
      switch (true) {
        case this.addProjectPledge:
          return this.createProjectPledge()
        case [1, 3].includes(this.project?.mortgagor?.mortgagorTypeId):
          return this.addContactGuarantor()
        case this.project?.mortgagor?.mortgagorTypeId === 2:
          return this.addContractorGuarantor()
        case this.deleteProjectContractorMortgagor:
          return this.deleteContractorMortgagor()
        case this.deleteProjectContactMortgagor:
          return this.deleteContactMortgagor()
        default:
          return Promise.reject('No handler')
      }
    },
    createProjectPledge() {
      return this.$axios.post(ulrCreatePledgeContract(), this.sendObject)
    },
    addContactGuarantor() {
      return this.$axios.post(urlCreateContactMortgagor(), this.sendObject)
    },
    addContractorGuarantor() {
      return this.$axios.post(urlCreateContractorMortgagor(), this.sendObject)
    },
    deleteContractorMortgagor() {
      return this.$axios.delete(
        urlDeleteContractorMortgagor(this.project.mortgagor?.mortgagorId)
      )
    },
    deleteContactMortgagor() {
      return this.$axios.delete(
        urlDeleteContactMortgagor(this.project.mortgagor?.mortgagorId)
      )
    },
  },
  created() {
    fillDirectory('pledgeTypes')
    Object.assign(this.project, this.dialog.dialogItem)
  },
}
</script>
