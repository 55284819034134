<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
        <v-autocomplete
          v-model="tableName"
          :error-messages="tableNameErr"
          :items="$directory.get('directoryTableNames')"
          item-title="table"
          placeholder="Оберіть таблицю"
          dense
          hide-details
          :loading="$loading.isLoading('directoryTableNames')"
          @focus="$directory.fill('directoryTableNames')"
          @update:model-value="fetchFieldItems"
          @blur="getSafe(() => v$.tableName.$touch())">
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
        <v-autocomplete
          v-if="fieldItems.length"
          v-model="field"
          :error-messages="fieldErr"
          :items="fieldItems"
          hide-details
          item-title="column"
          placeholder="Оберіть поле"
          dense
          @blur="getSafe(() => v$.field.$touch())">
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
        <v-text-field
          v-if="field"
          v-model="name"
          placeholder="Введіть назву"
          dense
          hide-details
          :error-messages="nameErr"
          @blur="getSafe(() => v$.name.$touch())">
        </v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getSafe, setErrHandler } from '@/utils/helperFunc'
import { required } from '@vuelidate/validators'
import {
  urlCreateAdminDirectory,
  urlGetDirectoriesField,
} from '@/pages/request'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'

export default {
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  data: () => ({
    tableName: null,
    field: null,
    name: null,
    fieldItems: [],
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    validation() {
      return { v$: this.v$, name: 'adminDirectory' }
    },
    tableNameErr() {
      return setErrHandler(this.v$.tableName)
    },
    fieldErr() {
      return setErrHandler(this.v$.field)
    },
    nameErr() {
      return setErrHandler(this.v$.name)
    },
  },
  validations() {
    return {
      tableName: { required },
      field: { required },
      name: { required },
    }
  },
  methods: {
    getSafe,
    fetchFieldItems() {
      if (this.tableName) {
        return this.$axios
          .get(urlGetDirectoriesField(this.tableName))
          .then(res => {
            if (res.data.message) {
              this.fieldItems.splice(0)
              return this.$setSnackbar({
                text: res.data.message,
                color: 'warning',
              })
            }
            this.fieldItems.splice(0)
            this.fieldItems.push(...res.data.columns)
          })
      }
    },
    submit() {
      const req = {
        tableName: this.tableName,
        field: this.field,
        name: this.name,
      }
      return this.$axios.post(urlCreateAdminDirectory(), req).then(() => {
        return (
          this.$setSnackbar({ text: 'Довідник створено' }),
          this.$store.commit('refreshPage')
        )
      })
    },
  },
}
</script>

<style scoped></style>
