<template>
  <div>
    <v-autocomplete
      v-model="solutionId"
      class="icon-contact mt-0"
      :items="$directory.get('klpSolutions')"
      :loading="$loading.isLoading('klpSolutions')"
      item-title="name"
      item-value="id"
      placeholder="Оберіть зі списку"
      :error-messages="klpSolutionIdErr"
      dense
      @blur="v$.solutionId.$touch()">
    </v-autocomplete>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { urlSetKlpSolution } from '@/pages/request'
import { required } from '@vuelidate/validators'
import { setErrHandler } from '@/utils/helperFunc'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
import { fillDirectory } from '@/plugins/directory/index.ts'

export default {
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  props: {
    dialog: { type: Object },
  },
  validations() {
    return {
      solutionId: { required },
    }
  },
  data: () => {
    return {
      solutionId: null,
    }
  },
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    validation() {
      return { v$: this.v$, name: 'klp' }
    },
    klpSolutionIdErr() {
      return setErrHandler(this.v$.solutionId)
    },
  },
  methods: {
    urlSetKlpSolution,
    submit() {
      return this.sendSolution()
    },
    sendSolution() {
      const req = {
        solutionId: this.solutionId,
      }
      return this.$axios
        .post(urlSetKlpSolution(this.dialog.params.item.id), req)
        .then(res => {
          this.$setSnackbar({ text: 'Рiшення успішно проставлено' })
          this.$store.commit('refreshPage')
          return res
        })
    },
  },
  created() {
    fillDirectory('klpSolutions')
  },
}
</script>

<style scoped></style>
