<template>
  <div>
    <PlanList v-if="$route.name === 'plan-list'" />
    <RouterView v-else />
  </div>
</template>

<script>
import PlanList from './PlanList.vue'
export default {
  name: 'Index',
  components: { PlanList },
}
</script>
