<template>
  <div class="d-flex align-center gap-2 mt-10 mb-3">
    <v-btn
      v-if="!hideCancel"
      variant="text"
      class="cancel"
      :loading="cancelLoading"
      :disabled="cancelDisable"
      :small="small"
      @click="cancel()">
      {{ cancelBtnText }}
    </v-btn>
    <v-btn
      class="confirm"
      :loading="confirmLoading"
      :disabled="confirmDisable"
      :small="small"
      @click="confirm()">
      {{ confirmBtnText }}
    </v-btn>
  </div>
</template>
<script>
export default {
  props: {
    confirm: { type: Function, default: () => false },
    cancel: { type: Function, default: () => false },
    confirmBtnText: { type: String, default: 'Зберегти' },
    cancelBtnText: { type: String, default: 'Відхилити' },
    confirmLoading: { type: Boolean, default: false },
    cancelLoading: { type: Boolean, default: false },
    cancelDisable: { type: Boolean, default: false },
    confirmDisable: { type: Boolean, default: false },
    hideCancel: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
  },
}
</script>

<style scoped lang="scss">
.cancel {
  text-transform: none;
  background: transparent;
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  color: #08487a;
  border: 1px solid #08487a;
}
.confirm {
  text-transform: none;
  background: #08487a;
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
}
</style>
