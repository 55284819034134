import {
  urlUserByRole,
  urlGetMFO,
  urlContactsGetAll,
  urlGetAllContractors,
  urlContractorIndastries,
  urlGetFounderRoles,
  urlGetExtraAgreementTypes,
  urlApplicationSourcesBySourceLinkId,
  urlGetUsersByRoleRiskManager,
  urlGetRiskAnalyzeStatuses,
  urlGetFinancingMonitoringStatuses,
  urlGetActivityStatuses,
  urlGetFrontManagers,
  urlGetMiddleManagers,
  urlGetLegalUsers,
  urlGetLegalStatuses,
  urlGetProjects,
  urlGetDflContracts,
  urlGetSuretyAgreementTypes,
  urlGetAllUsers,
  urlGetProjectGuarantors,
  urlGetMortgagorList,
  urlGetKlpStatuses,
  urlGetExtraAgreementStatuses,
  urlGetContractorDealerTypes,
  urlGetProviderList,
  urlGetDealerList,
  urlGetAgentList,
  urlGetAllAgents,
  urlGetDealerOutlets,
  urlGetPhoneTypes,
  urlGetEmailTypes,
  urlGetContactsByApplication,
  urlGetCarData,
  urlServiceApplicationSourceList,
  urlGetTruckMass,
  urlGetLiftingCapacityTypes,
  urlGetPlanObjectTypes,
  urlGetAverageMarks,
  urlGetAverageModels,
  urlGetMiddleManagersAll,
} from '@/pages/request'
import { filterUsers } from '@/utils/helperFunc'
import { getItems } from '../helper'
import axios from 'axios'

const selectItems = {
  getters: {
    activeUsers: state => {
      return filterUsers(state.allUsers)
    },
    leadAccessUsers: state => {
      return filterUsers(state.allUsers).filter(u => u.leadAccess)
    },
  },
  state: () => ({
    planObjectTypes: [],
    userRoles: {}, // TODO
    mfoList: [], // TODO
    contacts: [], // TODO
    agentContacts: [], // TODO
    contractors: [], // TODO
    contractorIndastries: [], // TODO
    leasingObjectTypes: [], // TODO
    allProviders: [], // TODO
    founderRoles: [], // TODO maybe not used
    agreementTypes: [], // TODO maybe not used
    applicationSourceLinks: [], // TODO
    riskManagers: [], // TODO
    riskStatuses: [], // TODO
    financingMonitoringStatuses: [], // TODO
    activityStatuses: [], // define
    frontManagers: [], // TODO
    legalUsers: [], // TODO
    middleManagers: [], // TODO
    middleManagersAll: [], // TODO
    legalStatuses: [], // TODO
    projects: [], // TODO
    dfls: [], // TODO
    suretyAgreementTypes: [], // TODO
    applicationContacts: [], // TODO
    allUsers: [], // define
    projectGuarantors: [], // TODO
    mortgagorsList: [], // TODO
    klpStatuses: [], // TODO
    extraAgreementStatuses: [], // TODO
    contractorDealerTypes: [], // TODO
    dealerOutletsList: [], // TODO
    agents: [], // TODO
    agentsList: [], // TODO
    dealerOutlets: [], // TODO
    phoneTypes: [], // TODO
    emailTypes: [], // TODO
    carModels: [], // TODO
    carMarks: [], // TODO
    averageCarMarks: [], // TODO
    averageCarModels: [], // TODO
    carModifications: [], // TODO
    serviceApplicationSourceList: [], // TODO
    newTruckId: [1, 2, 7, 8, 9, 11, 15, 17, 21],
    newAgriculturalId: [1, 21], // TODO maybe remove
    newSpecialId: [1, 38], // TODO maybe remove
    placeOfRegItems: [], // TODO
    itemYears: Array.from(new Array(12), (v, index) => index).map(
      (v, i) => new Date().getFullYear() - i
    ), // TODO
    itemYearsExpanded: Array.from(new Array(15), (v, index) => index).map(
      (v, i) => new Date().getFullYear() - i
    ), // TODO
    ukrMonths: [
      'Січень',
      'Лютий',
      'Березень',
      'Квітень',
      'Травень',
      'Червень',
      'Липень',
      'Серпень',
      'Вересень',
      'Жовтень',
      'Листопад',
      'Грудень',
    ], // TODO maybe move to backend or add IDs
    truckMass: [], // TODO
    liftingCapacityTypes: [], // TODO
    projectValidationKeys: {
      lesseeName: 'Повна назва компанії',
      codeEdrpou: 'ЄДРПОУ',
      stateRegistrationDate: 'Дата реєстрації',
      stateRegistrationNumber: 'Номер реєстрації',
      basicQued: 'Основний квед',
      listQueds: 'Кведи',
      phones: 'Телефон',
      emails: 'Пошта',
      urAddress: 'Адреса реєстрації',
      factAddress: 'Фактична адреса',
      currentAccounts: 'Банківський рахунок',
      director: 'Диерктор',
      executiveAuthority: 'Виконавчий орган',
      founders: 'Засновники',
      name: 'Імʼя',
      surname: 'Прізвище',
      patronymic: 'По батькові',
      nationality: 'Громадянство',
      resident: 'Резидент',
      marriedTo: 'Чоловік/дружина',
      birthday: 'Дата народження',
      placeOfBirth: 'Місце народження',
      identity_document: 'Паспорт',
      inn: 'ІНН',
    }, // TODO maybe move to error-keys
    passportLabels: {
      identityDocFirst: ['Серія', 'Номер запису', 'Запис №', 'Назва'],
      identityDocSecond: ['Номер', 'ID паспорт', 'Посвідка №', 'Номер'],
      issueDate: ['Дата видачі', 'Дата видачі', 'Дата видачі', 'Дата видачі'],
      sellBy: [null, 'Дійсний, до', 'Дійсний, до', 'Дійсний, до'],
      issuedBy: [
        'Виданий',
        'Орган що видав',
        'Орган що видав',
        'Орган що видав',
      ],
      scan: [true, true, false, false],
    }, // TODO
  }),
  mutations: {
    addPlanObjectTypes(state, objectTypes) {
      if (objectTypes) {
        state.planObjectTypes = objectTypes
      }
    },
    addPhoneTypes(state, arr) {
      if (arr) {
        state.phoneTypes.splice(0)
        state.phoneTypes.push(...arr)
      }
    },
    addDealerOutlets(state, arr) {
      if (arr) {
        state.dealerOutlets.splice(0)
        state.dealerOutlets.push(...arr)
      }
    },
    addKlpStatuses(state, arr) {
      if (arr) {
        state.klpStatuses.splice(0)
        state.klpStatuses.push(...arr)
      }
    },
    addDfls(state, arr) {
      if (arr) {
        state.dfls.splice(0)
        state.dfls.push(...arr)
      }
    },
    addAllUsers(state, arr) {
      if (arr) {
        state.allUsers.splice(0)
        state.allUsers.push(...arr)
      }
    },
    addTruckMassTypes(state, arr) {
      if (arr) {
        state.truckMass.splice(0)
        state.truckMass.push(...arr)
      }
    },
    addLiftingCapacityTypes(state, arr) {
      if (arr) {
        state.liftingCapacityTypes.splice(0)
        state.liftingCapacityTypes.push(...arr)
      }
    },
    addProjects(state, arr) {
      if (arr) {
        state.projects.splice(0)
        state.projects.push(...arr)
      }
    },
    addActivityStatuses(state, arr) {
      if (arr) {
        state.activityStatuses.push(...arr)
      }
    },
    addFinancingMonitoringStatuses(state, arr) {
      if (arr) {
        state.financingMonitoringStatuses.push(...arr)
      }
    },
    addUserRoles(state, object) {
      const { userRoles, role } = object
      if (userRoles && state.userRoles[role]) {
        state.userRoles[role].push(...userRoles)
      }
    },
    addMfo(state, mfoList) {
      if (mfoList) {
        state.mfoList.splice(0)
        state.mfoList.push(...mfoList)
      }
    },
    addContacts(state, contacts) {
      if (contacts) {
        state.contacts.splice(0)
        state.contacts.push(...contacts)
      }
    },
    addAgentContacts(state, contacts = []) {
      if (contacts) {
        state.agentContacts.splice(0)
        const agents = contacts.filter(c => c?.type?.id === 2)
        state.agentContacts.push(...agents)
      }
    },
    addContractors(state, contractors) {
      if (contractors) {
        state.contractors.splice(0)
        state.contractors.push(...contractors)
      }
    },
    addContractorIndastries(state, contractorIndastries) {
      if (contractorIndastries) {
        state.contractorIndastries.push(...contractorIndastries)
      }
    },
    addLeasingObjectTypes(state, leasingObjectTypes) {
      window.leasingObjectTypes = leasingObjectTypes
      state.leasingObjectTypes.push(...leasingObjectTypes)
    },
    addFounderRoles(state, arr) {
      if (arr) {
        state.founderRoles.splice(0)
        state.founderRoles.push(...arr)
      }
    },
    addAgreementTypes(state, arr) {
      if (arr) {
        state.agreementTypes.push(...arr)
      }
    },
    addApplicationSourceLinks(state, arr) {
      if (Array.isArray(arr)) {
        state.applicationSourceLinks.splice(0)
        state.applicationSourceLinks.push(...arr)
      }
    },
    addRiskManagers(state, arr) {
      if (arr) {
        state.riskManagers.push(...arr)
      }
    },
    addRiskStatuses(state, arr) {
      if (arr) {
        state.riskStatuses.push(...arr)
      }
    },
    addFrontManagers(state, arr) {
      if (arr) {
        state.frontManagers.splice(0)
        state.frontManagers.push(...arr)
      }
    },
    addLegalUsers(state, arr) {
      if (arr) {
        state.legalUsers.splice(0)
        state.legalUsers.push(...arr)
      }
    },
    addMiddleManagers(state, arr) {
      if (arr) {
        state.middleManagers.splice(0)
        state.middleManagers.push(...arr)
      }
    },
    addLegalStatuses(state, arr) {
      if (arr) {
        state.legalStatuses.splice(0)
        state.legalStatuses.push(...arr)
      }
    },
    addMiddleManagersAll(state, arr) {
      if (arr) {
        state.middleManagersAll.splice(0)
        state.middleManagersAll.push(...arr)
      }
    },
    addSuretyAgreementTypes(state, arr) {
      if (arr) {
        state.suretyAgreementTypes.splice(0)
        state.suretyAgreementTypes.push(...arr)
      }
    },
    addProjectGuarantors(state, arr) {
      if (arr) {
        state.projectGuarantors.splice(0)
        state.projectGuarantors.push(...arr)
      }
    },
    addMortgagorsList(state, arr) {
      if (arr) {
        state.mortgagorsList.splice(0)
        state.mortgagorsList.push(...arr)
      }
    },
    addExtraAgreementStatuses(state, arr) {
      if (arr) {
        state.extraAgreementStatuses.splice(0)
        state.extraAgreementStatuses.push(...arr)
      }
    },
    addContractorDealerTypes(state, arr) {
      state.contractorDealerTypes.splice(0)
      state.contractorDealerTypes.push(...(arr || []))
    },
    addAllProviders(state, arr) {
      if (arr) {
        state.allProviders.splice(0)
        state.allProviders.push(...arr)
      }
    },
    addDealerOutletList(state, arr) {
      if (arr) {
        state.dealerOutletsList.splice(0)
        state.dealerOutletsList.push(...arr)
      }
    },
    addAgents(state, arr) {
      if (arr) {
        state.agents.splice(0)
        state.agents.push(...arr)
      }
    },
    addAgentsList(state, arr) {
      if (arr) {
        state.agentsList.splice(0)
        state.agentsList.push(...arr)
      }
    },
    addEmailTypes(state, arr) {
      if (arr) {
        state.emailTypes.splice(0)
        state.emailTypes.push(...arr)
      }
    },
    addApplicationContacts(state, arr) {
      if (arr) {
        state.applicationContacts.splice(0)
        state.applicationContacts.push(...arr)
      }
    },
    clearApplicationContacts(state) {
      state.applicationContacts.splice(0)
    },
    addCarModels(state, items) {
      if (items) {
        state.carModels.splice(0)
        state.carModels.push(...items)
      }
    },
    addCarMarks(state, items) {
      if (items) {
        state.carMarks.splice(0)
        state.carMarks.push(...items)
      }
    },
    addAverageCarMarks(state, items) {
      if (items) {
        state.averageCarMarks.splice(0)
        state.averageCarMarks.push(...items)
      }
    },
    addAverageCarModels(state, items) {
      if (items) {
        state.averageCarModels.splice(0)
        state.averageCarModels.push(...items)
      }
    },
    clearCarModels(state) {
      state.carModels.splice(0)
    },
    clearCarMarks(state) {
      state.carMarks.splice(0)
    },
    clearAverageCarMarks(state) {
      state.averageCarMarks.splice(0)
    },
    clearAverageCarModels(state) {
      state.averageCarModels.splice(0)
    },
    addCarModifications(state) {
      state.carModifications.splice(0)
    },
    addServiceApplicationSourceList(state, items) {
      if (items) {
        state.serviceApplicationSourceList.splice(0)
        state.serviceApplicationSourceList.push(...items)
      }
    },
  },
  actions: {
    async addPlanObjectTypes({ state, commit }, planTypeId) {
      if (state.planObjectTypes.length === 0) {
        const items = await getItems(urlGetPlanObjectTypes(planTypeId))
        commit('addPlanObjectTypes', items)
      }
    },
    /* contact/contractor */
    async addUserRoles({ commit, state }, payload) {
      const { key, roles } =
        typeof payload === 'string'
          ? { key: payload, roles: [payload] }
          : payload

      if (state.userRoles[key]) return
      state.userRoles[key] = []
      const items = await getItems(urlUserByRole(roles.join(',')))
      return commit('addUserRoles', { userRoles: items, role: key })
    },
    async addMfo({ commit, state }, mfo = null) {
      if (!mfo || state.mfoList.findIndex(el => el.mfo === mfo) >= 0) return
      const items = await getItems(urlGetMFO(mfo), {
        headers: { 'X-API-SECRET': import.meta.env.VITE_ERM_API_SECRET },
      })
      return commit('addMfo', items)
    },
    async addContacts({ commit }, byAccess = false) {
      const items = await getItems(urlContactsGetAll(byAccess))
      commit('addAgentContacts', items)
      return commit('addContacts', items)
    },
    async addContractors({ commit }, params = {}) {
      const items = await getItems(urlGetAllContractors(params))
      commit('addContractors', items)
      return Promise.resolve(items)
    },
    async addContractorIndastries({ commit, state }) {
      if (state.contractorIndastries.length) return
      const items = await getItems(urlContractorIndastries())
      return commit('addContractorIndastries', items)
    },
    async addLeasingObjectTypes({ commit, state }) {
      if (state.leasingObjectTypes.length) return
      const items = await getItems(urlGetCarData(), {
        headers: { 'X-API-SECRET': import.meta.env.VITE_ERM_API_SECRET },
      })
      return commit('addLeasingObjectTypes', items)
    },
    /* project */
    async addTruckMassTypes({ commit, state }) {
      if (state.truckMass.length) return
      const items = await getItems(urlGetTruckMass())
      return commit('addTruckMassTypes', items)
    },
    async addLiftingCapacityTypes({ commit, state }) {
      if (state.liftingCapacityTypes.length) return
      const items = await getItems(urlGetLiftingCapacityTypes())
      return commit('addLiftingCapacityTypes', items)
    },
    async addFounderRoles({ commit, state }) {
      if (state.founderRoles.length) return
      const items = await getItems(urlGetFounderRoles())
      return commit('addFounderRoles', items)
    },
    async addAgreementTypes({ commit, state }) {
      if (state.agreementTypes.length) return
      const items = await getItems(urlGetExtraAgreementTypes())
      return commit('addAgreementTypes', items)
    },
    async addApplicationSourceLinks({ commit }, sourceId = null) {
      const items = await getItems(
        urlApplicationSourcesBySourceLinkId(sourceId)
      )
      if (Array.isArray(items)) {
        return commit('addApplicationSourceLinks', items)
      } else return commit('addApplicationSourceLinks', [])
    },
    async addRiskManagers({ commit, state }) {
      if (state.riskManagers.length) return
      const items = await getItems(urlGetUsersByRoleRiskManager())
      return commit('addRiskManagers', items)
    },
    async addRiskStatuses({ commit, state }) {
      if (state.riskStatuses.length) return
      const items = await getItems(urlGetRiskAnalyzeStatuses())
      return commit('addRiskStatuses', items)
    },
    async addFinancingMonitoringStatuses({ commit, state }) {
      if (state.financingMonitoringStatuses.length) return
      const items = await getItems(urlGetFinancingMonitoringStatuses())
      return commit('addFinancingMonitoringStatuses', items)
    },
    async addActivityStatuses({ commit, state }) {
      if (state.activityStatuses.length) return
      const items = await getItems(urlGetActivityStatuses())
      return commit('addActivityStatuses', items)
    },
    async addFrontManagers({ commit, state }) {
      if (state.frontManagers.length) return
      const items = await getItems(urlGetFrontManagers())
      return commit('addFrontManagers', items)
    },
    async addLegalUsers({ commit, state }) {
      if (state.legalUsers.length) return
      const items = await getItems(urlGetLegalUsers())
      return commit('addLegalUsers', items)
    },
    async addMiddleManagers({ commit, state }) {
      if (state.middleManagers.length) return
      const items = await getItems(urlGetMiddleManagers())
      return commit('addMiddleManagers', items)
    },
    async addMiddleManagersAll({ commit, state }) {
      if (state.middleManagersAll.length) return
      const items = await getItems(urlGetMiddleManagersAll())
      return commit('addMiddleManagersAll', items)
    },
    async addLegalStatuses({ commit, state }) {
      if (state.legalStatuses.length) return
      const items = await getItems(urlGetLegalStatuses())
      return commit('addLegalStatuses', items)
    },
    async addProjects({ commit }) {
      const { data } = await getItems(urlGetProjects())
      return commit('addProjects', data)
    },
    async addDfls({ commit }) {
      const items = await getItems(urlGetDflContracts())
      return commit('addDfls', items)
    },
    async addSuretyAgreementTypes({ commit, state }) {
      if (state.suretyAgreementTypes.length) return
      const items = await getItems(urlGetSuretyAgreementTypes())
      return commit('addSuretyAgreementTypes', items)
    },
    async addAllUsers({ commit, state }) {
      if (state.allUsers.length) return
      const items = await getItems(urlGetAllUsers())
      return commit('addAllUsers', items)
    },
    async addProjectGuarantors({ commit }) {
      const items = await getItems(urlGetProjectGuarantors())
      return commit('addProjectGuarantors', items)
    },
    async addMortgagorsList({ commit, state }) {
      if (state.mortgagorsList.length) return
      const items = await getItems(urlGetMortgagorList())
      return commit('addMortgagorsList', items)
    },
    async addKlpStatuses({ commit, state }) {
      if (state.klpStatuses.length) return
      const items = await getItems(urlGetKlpStatuses())
      return commit('addKlpStatuses', items)
    },
    async addExtraAgreementStatuses({ commit, state }) {
      if (state.extraAgreementStatuses.length) return
      const items = await getItems(urlGetExtraAgreementStatuses())
      return commit('addExtraAgreementStatuses', items)
    },
    // !Deprecated use "addDealerTypes" action
    async addContractorDealerTypes({ commit, state }) {
      if (state.contractorDealerTypes.length) return
      const items = await getItems(urlGetContractorDealerTypes())
      return commit('addContractorDealerTypes', items)
    },
    async addAllProviders({ commit }) {
      const items = await getItems(urlGetProviderList())
      return commit('addAllProviders', items)
    },
    async addDealerOutletList({ commit }, params = {}) {
      const { page = 1, pagination = false, filters = {} } = params
      if (pagination) {
        return axios.get(urlGetDealerList(page, filters)).then(res => {
          commit('addDealerOutletList', res.data.data)

          return res
        })
      } else {
        // const items = await getItems(urlGetDealerList())
        const items = await getItems(urlGetDealerOutlets())
        return commit('addDealerOutletList', items)
      }
    },
    async addDealerOutlets({ commit }) {
      const items = await getItems(urlGetDealerOutlets())
      return commit('addDealerOutlets', items)
    },
    async addAgents({ state, commit }, params) {
      if (params && params.noRepeat && state.agentsList.length) return

      const res = await getItems(urlGetAgentList(params))
      commit('addAgents', res.data)
      return res
    },
    async addAgentsList({ commit }) {
      const items = await getItems(urlGetAllAgents())
      return commit('addAgentsList', items)
    },
    async addPhoneTypes({ commit, state }) {
      if (state.phoneTypes.length) return
      const items = await getItems(urlGetPhoneTypes())
      return commit('addPhoneTypes', items)
    },
    async addEmailTypes({ commit, state }) {
      if (state.emailTypes.length) return
      const items = await getItems(urlGetEmailTypes())
      return commit('addEmailTypes', items)
    },
    async addApplicationContacts({ commit }, applicationId) {
      const items = await getItems(urlGetContactsByApplication(applicationId))
      return commit('addApplicationContacts', items || [])
    },
    async addCarMarks({ commit }, leasingObjectTypeId) {
      if (!leasingObjectTypeId) return
      const items = await getItems(
        urlGetCarData({ leasingObjectType: leasingObjectTypeId }),
        {
          headers: { 'X-API-SECRET': import.meta.env.VITE_ERM_API_SECRET },
        }
      )
      return commit('addCarMarks', items || [])
    },
    async addAverageCarMarks({ commit }, categoryId = 1) {
      if (!categoryId) return
      const items = await getItems(urlGetAverageMarks(categoryId))
      return commit('addAverageCarMarks', items || [])
    },
    async addAverageCarModels({ commit }, payload = {}) {
      const { categoryId: categoryId, markId: markId } = payload
      if (!categoryId || !markId) return
      const items = await getItems(urlGetAverageModels(categoryId, markId))
      return commit('addAverageCarModels', items || [])
    },
    async addCarModels({ commit }, payload = {}) {
      const {
        leasingObjectTypeId: leasingObjectType,
        markId: leasedAssertMark,
      } = payload
      if (!leasingObjectType || !leasedAssertMark) return
      const items = await getItems(
        urlGetCarData({ leasingObjectType, leasedAssertMark }),
        {
          headers: { 'X-API-SECRET': import.meta.env.VITE_ERM_API_SECRET },
        }
      )
      return commit('addCarModels', items || [])
    },
    async addCarModifications() {
      /* temporary empty */
    },
    async addServiceApplicationSourceList({ state, commit }) {
      if (state.serviceApplicationSourceList.length) return
      const items = await getItems(urlServiceApplicationSourceList())
      return commit('addServiceApplicationSourceList', items)
    },
  },
}

export { selectItems }
