import pdfMake from 'pdfmake'
import { pageMargins, content, styles, images } from './pdf-layout-parts'
import { humanDate, keepDigit, formutSum } from '../helper'

function createPdf(obj) {
  const baseUrl = location.origin
  const fonts = {
    Helvetica: {
      normal: `${baseUrl}/fonts/Helvetica-normal.ttf`,
      italics: `${baseUrl}/fonts/Helvetica-italics.ttf`,
      bolditalics: `${baseUrl}/fonts/Helvetica-bolditalics.ttf`,
      bold: `${baseUrl}/fonts/Helvetica-bold.ttf`,
    },
    FuturaPT: {
      normal: `${baseUrl}/fonts/FuturaPT-normal.ttf`,
      italics: `${baseUrl}/fonts/FuturaPT-italics.ttf`,
      bolditalics: `${baseUrl}/fonts/FuturaPT-bolditalic.ttf`,
      bold: `${baseUrl}/fonts/FuturaPT-bold.ttf`,
    },
  }
  return new Promise((resolve, reject) => {
    docDefinition(prepareObject(obj))
      .then(pdfObject => {
        return pdfMake.createPdf(pdfObject, null, fonts).getBlob(resolve)
      })
      .catch(reject)
  })
}

async function getAsBase64(obj) {
  // eslint-disable-next-line no-useless-catch
  try {
    const doc = await docDefinition(prepareObject(obj)).catch(err => {
      console.log(err)
    })
    // @ts-ignore
    const pdfDocGenerator = pdfMake.createPdf(doc)
    /** @type { string } */
    const base64 = await new Promise(resolve => {
      pdfDocGenerator.getBase64(v => resolve(v))
    })
    return base64
  } catch (err) {
    throw err
  }
}

const getName = obj => {
  const req = obj?.requestData
  const mark = req?.leasedAssertMark?.name
  const model = req?.leasedAssertModel?.name
  const advance = req.advance + '%'
  const term = req.leasingTerm + 'мiс'
  const currency = req.currency
  const id = obj.requestId
  return `${mark}_${model}_${advance}_${term}_${currency}_${id}.pdf`.replace(
    /\s/g,
    '_'
  )
}

/** PDF content */
function docDefinition(obj) {
  const photo = obj?.user?.photo
    ? {
        width: '33%',
        image: obj?.user?.photo,
        fit: [75, 75],
      }
    : { text: ' ', width: '1%' }
  return new Promise(resolve => {
    images(obj)
      .then(img => {
        const images = {
          pageMargins: pageMargins,
          footer: function () {
            return {
              columns: [
                {
                  margin: [0, 10, 0, 0],
                  columns: [
                    {
                      width: '50%',
                      columnGap: 10,
                      margin: [40, 0, 15, 0],
                      columns: [
                        photo,
                        {
                          width: '*',
                          stack: [
                            {
                              text: `${obj.user?.surname} ${obj.user?.name} ${obj.user?.patronymic}`,
                              color: '#d7514f',
                              bold: true,
                            },
                            {
                              text: 'ваш персональний менеджер',
                              color: '#575a5b',
                              fontSize: 9,
                              margin: [5, 0],
                            },
                            { text: ' ', fontSize: 11 },
                            { text: `${obj.user?.phone}`, fontSize: 10 },
                            { text: `${obj.user?.email}`, fontSize: 10 },
                          ],
                          fontSize: 11,
                          color: '#323636',
                        },
                      ],
                    },
                    {
                      width: '50%',
                      margin: [15, 0, 40, 0],
                      stack: [
                        {
                          text: [
                            {
                              text: 'bestleasing.com.ua',
                              link: 'https://www.bestleasing.com.ua',
                              decoration: 'underline',
                            },
                            '     ',
                            { text: '+38 044 359 0 399' },
                          ],
                          color: '#575a5b',
                          bold: true,
                        },
                        { text: ' ', fontSize: 3 },
                        {
                          text: 'Підпишися на соц. мережі, щоб швидко дізнаватися про вигідні пропозиції фінансування транспорту:',
                          fontSize: 9,
                          color: '#575a5b',
                        },
                        { text: ' ', fontSize: 6 },
                        {
                          text: [
                            {
                              text: 'Facebook',
                              link: 'https://www.facebook.com/bestleasingua/',
                              decoration: 'underline',
                            },
                            '     ',
                            {
                              text: 'Instagram',
                              link: 'https://www.instagram.com/bestleasing.ua/?hl=ua',
                              decoration: 'underline',
                            },
                          ],
                          bold: true,
                          color: '#575a5b',
                        },
                      ],
                      fontSize: 11.5,
                    },
                  ],
                },
              ],
            }
          },
          content: content(obj),
          defaultStyle: {
            font: 'Helvetica',
          },
          styles: styles,
          images: img,
        }

        resolve(images)
      })
      .catch(err => {
        console.log(err)
        throw err
      })
  })
}

function prepareObject(obj) {
  if (Object.keys(obj.resultData).includes('oper-leasing')) return obj

  const destrObj = JSON.parse(JSON.stringify(obj))
  delete destrObj.resultData.requestId

  /** Format advance */
  const advanceSum = (obj, advance) => {
    if (advance) {
      obj['total-payment-principal'] = formutSum(
        parseFloat(keepDigit(obj['total-payment-principal'] || '0'))
      )
      obj['total-commission-additional-services'] = formutSum(
        parseFloat(
          keepDigit(obj['total-commission-additional-services'] || '0')
        )
      )
      obj['total-interest'] = formutSum(
        parseFloat(keepDigit(obj['total-interest'] || '0'))
      )
      obj['total-payment'] = formutSum(
        parseFloat(keepDigit(obj['total-payment'] || '0'))
      )
      obj['total-amount-rewardUSAID'] = formutSum(
        parseFloat(keepDigit(obj['total-amount-rewardUSAID'] || '0'))
      )
      obj['total-promotion-paymentUSAID'] = formutSum(
        parseFloat(keepDigit(obj['total-promotion-paymentUSAID'] || '0'))
      )
      // Program FSN
      obj['totalServicePayment'] = formutSum(
        parseFloat(keepDigit(obj['totalServicePayment'] || '0'))
      )
      obj['totalLeasingObjectCompensationFSN'] = formutSum(
        parseFloat(keepDigit(obj['totalLeasingObjectCompensationFSN'] || '0'))
      )
      obj['totalProgramCompensationRewardFSN'] = formutSum(
        parseFloat(keepDigit(obj['totalProgramCompensationRewardFSN'] || '0'))
      )
      obj['totalAdditionalServicesCommissionFSN'] = formutSum(
        parseFloat(
          keepDigit(obj['totalAdditionalServicesCommissionFSN'] || '0')
        )
      )
      obj['totalBaseRewardUirdIndexFSN'] = formutSum(
        parseFloat(keepDigit(obj['totalBaseRewardUirdIndexFSN'] || '0'))
      )
      obj['paymentFSN'] = formutSum(
        parseFloat(keepDigit(obj['paymentFSN'] || '0'))
      )
      return obj
    } else {
      const advance = obj.graph[0]
      obj['total-payment-principal'] = formutSum(
        parseFloat(keepDigit(obj['total-payment-principal'] || '0')) -
          parseFloat(keepDigit(advance['payment-principal'] || '0'))
      )
      obj['total-commission-additional-services'] = formutSum(
        parseFloat(
          keepDigit(obj['total-commission-additional-services'] || '0')
        ) -
          parseFloat(
            keepDigit(advance['commission-additional-services'] || '0')
          )
      )
      obj['total-interest'] = formutSum(
        parseFloat(keepDigit(obj['total-interest'] || '0')) -
          parseFloat(keepDigit(advance['interest'] || '0'))
      )
      obj['total-payment'] = formutSum(
        parseFloat(keepDigit(obj['total-payment'] || '0')) -
          parseFloat(keepDigit(advance['payment'] || '0'))
      )
      obj['total-amount-rewardUSAID'] = formutSum(
        parseFloat(keepDigit(obj['total-amount-rewardUSAID'] || '0')) -
          parseFloat(keepDigit(advance['amountRewardUSAID'] || '0'))
      )
      obj['total-promotion-paymentUSAID'] = formutSum(
        parseFloat(keepDigit(obj['total-promotion-paymentUSAID'] || '0')) -
          parseFloat(keepDigit(advance['promotionPaymentUSAID'] || '0'))
      )
      // program fsn
      obj['totalServicePayment'] = formutSum(
        parseFloat(keepDigit(obj['totalServicePayment'] || '0')) -
          parseFloat(keepDigit(advance['servicePayment'] || '0'))
      )
      obj['totalLeasingObjectCompensationFSN'] = formutSum(
        parseFloat(keepDigit(obj['totalLeasingObjectCompensationFSN'] || '0')) -
          parseFloat(keepDigit(advance['leasingObjectCompensationFSN'] || '0'))
      )
      obj['totalProgramCompensationRewardFSN'] = formutSum(
        parseFloat(keepDigit(obj['totalProgramCompensationRewardFSN'] || '0')) -
          parseFloat(keepDigit(advance['programCompensationRewardFSN'] || '0'))
      )
      obj['totalAdditionalServicesCommissionFSN'] = formutSum(
        parseFloat(
          keepDigit(obj['totalAdditionalServicesCommissionFSN'] || '0')
        ) -
          parseFloat(
            keepDigit(advance['additionalServicesCommissionFSN'] || '0')
          )
      )
      obj['totalBaseRewardUirdIndexFSN'] = formutSum(
        parseFloat(keepDigit(obj['totalBaseRewardUirdIndexFSN'] || '0')) -
          parseFloat(keepDigit(advance['baseRewardUirdIndexFSN'] || '0'))
      )
      obj['paymentFSN'] = formutSum(
        parseFloat(keepDigit(obj['paymentFSN'] || '0')) -
          parseFloat(keepDigit(advance['paymentFSN'] || '0'))
      )

      return obj
    }
  }

  Object.keys(destrObj.resultData).forEach(key => {
    console.log('key', key)
    destrObj.resultData[key] = advanceSum(
      destrObj.resultData[key],
      obj.showAdvance
    )
    destrObj.resultData[key].graph = toggleAdvance(
      destrObj.resultData[key].graph,
      obj.showAdvance
    ).map(obj => {
      obj.date = humanDate(obj.date)
      return obj
    })
  })

  return destrObj
}

function toggleAdvance(graph, advance) {
  if (advance && graph && graph[0]) {
    graph[0].n = 'Аванс'
    return graph
  } else if (graph && graph?.length) {
    return graph.filter((v, i) => i > 0)
  } else {
    return []
  }
}

export { getAsBase64, createPdf, getName }
