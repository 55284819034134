import { socket } from '@/socket'
import axios from 'axios'
import { urlGetNotifications, urlReadNotification } from '@/pages/request'
import { playSound } from '@/utils/helperFunc'

const notifications = {
  state() {
    return {
      notificationList: [],
      notificationsReadQueue: [],
      notificationSoundUrl: null,
    }
  },
  mutations: {
    setNotificationSoundUrl(state, blob) {
      state.notificationSoundUrl = URL.createObjectURL(blob)
    },
    setNotifications(state, notificationList) {
      state.notificationList = notificationList
    },
    addNotification(state, notification) {
      state.notificationList.unshift(notification)
    },
    addNotificationToQueue(state, notificationId) {
      state.notificationsReadQueue.push(notificationId)
    },
    removeNotificationFromQueue(state, notificationId) {
      state.notificationsReadQueue = state.notificationsReadQueue.filter(
        n => n !== notificationId
      )
    },
    confirmNotificationQueue(state) {
      state.notificationsReadQueue.forEach(notificationId => {
        const notification = state.notificationList.find(
          n => n.id === notificationId
        )
        notification.isRead = true
      })
    },
  },
  actions: {
    getNotifications({ commit }) {
      return axios.get(urlGetNotifications()).then(res => {
        commit('setNotifications', res.data)
      })
    },
    readNotification({ state, commit }, notificationId) {
      if (state.notificationsReadQueue.includes(notificationId)) {
        return
      }
      commit('addNotificationToQueue', notificationId)
      return axios.get(urlReadNotification(notificationId)).catch(() => {
        commit('removeNotificationFromQueue', notificationId)
      })
    },
    listenNotificationEvents({ state, commit, rootState }) {
      // get notification sound
      return axios
        .get('/notification.mp3', { responseType: 'blob' })
        .then(res => {
          commit('setNotificationSoundUrl', res.data)

          socket.on(
            // @ts-ignore
            'erm_notification',
            res => {
              if (rootState?.user?.id === res?.messages?.userId) {
                commit('addNotification', res.message)

                playSound(state.notificationSoundUrl)
              }
            }
          )
        })
    },
  },
}

export { notifications }
