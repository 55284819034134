<template>
  <v-row class="align-center mt-3">
    <v-col :cols="12" :md="6">
      <v-select
        ref="select"
        v-model="selectValue"
        :label="label"
        :items="selectItems"
        item-title="label"
        item-value="key">
      </v-select>
    </v-col>
    <v-col :cols="12" :md="4">
      <v-btn
        style="
          margin-top: -10px;
          text-transform: none;
          background: rgb(8, 72, 122);
          border-radius: 4px;
          font-weight: 600;
          color: rgb(255, 255, 255);
        "
        :disabled="!selectValue"
        @click="addDoc">
        {{ title }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    documents: { type: Object, default: () => {} }, // { passport: { key: 'passport', show: false} }
    label: { type: String, default: 'Обрати додатковий документ' },
    title: { type: String, default: 'Додати документ' },
  },
  emits: ['update:documents', 'add'],
  data() {
    return {
      selectValue: null,
      selectItems: [],
    }
  },
  computed: {
    // selectItems() {
    //   const docs = []
    //   for (const doc in this.documents) {
    //     docs.push({ ...this.documents[doc], key: doc })
    //   }
    //   return docs.filter(d => !d.show)
    // },
  },
  methods: {
    addDoc() {
      const docs = Object.clone(this.documents)
      docs[this.selectValue].show = true
      this.$emit('update:documents', docs)
      this.$emit('add', this.selectValue)
      this.selectValue = null
      this.$refs.select.blur()
    },
  },
  watch: {
    documents: {
      handler(val) {
        const docs = []
        for (const doc in val) {
          docs.push({ ...val[doc], key: doc })
        }
        this.selectItems = docs.filter(d => !d.show)
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style></style>
