<template>
  <div>
    <h3>Provider (temporary empty)</h3>
  </div>
</template>
<script>
export default {
  props: { contact: { type: Object } },
  data: () => ({
    /*  */
  }),
  computed: {
    contactData() {
      return this.contact
    },
  },
  methods: {
    /*  */
  },
}
</script>