<template>
  <div>
    <div class="d-flex justify-space-between pb-1 cursor-default">
      <h5>{{ title }}</h5>
      <slot name="table_crud"></slot>
    </div>
    <v-data-table
      v-if="items"
      :headers="klpInvitedTHeaders"
      :items="items"
      class="calc-table"
      dense
      hide-default-footer
      hide-default-header
      :items-per-page="100">
      <!-- eslint-disable-next-line -->
      <template #item.invited="{ item }">
        <div class="d-flex align-center cursor-default">
          <span style="width: 150px" class="text-start">{{
            getContactInitials(item.invited?.fullName, { byName: true })
          }}</span>
          <v-divider vertical></v-divider>
          <span class="ml-5 d-block">{{
            '👨‍💻 ' + item.invited?.post || '---'
          }}</span>
          <v-divider v-if="item.solutionId" class="mx-3" vertical></v-divider>
          <v-chip v-if="item.solutionId === 1" size="small" color="green">
            Затверджено
          </v-chip>
          <v-chip v-if="item.solutionId === 2" size="small" color="red">
            Відмовлено
          </v-chip>
          <v-chip v-if="item.solutionId === 3" size="small" color="orange">
            Утрималися
          </v-chip>
        </div>
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.actions="{ item }">
        <TableCrud
          data-readonly="hide"
          style="margin-right: -10px"
          :actionsArray="[
            {
              action: () => invitedSolution(item),
              text: 'Проставити рішення',
              icon: 'mdi-check',
              disabled: item.solutionId,
            },
            {
              action: () => deleteInvited(item),
              text: 'Видалити',
              icon: 'mdi-delete',
            },
          ]">
        </TableCrud>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import TableCrud from '@/components/table-crud.vue'
import { klpInvitedTHeaders } from '@/utils/tableHeaders'
import { getContactInitials } from '@/utils/helperFunc'
import { urlKlpInvationsDelete } from '@/pages/request'
import { fillDirectory } from '@/plugins/directory'
export default {
  components: {
    TableCrud,
  },
  props: {
    title: { type: String },
    items: { type: Array, required: true },
  },
  data: () => {
    return {
      klpInvitedTHeaders,
    }
  },
  methods: {
    getContactInitials,
    invitedSolution(item) {
      this.$store.commit('setDialog', {
        title: 'Проставлення рішення',
        action: 'klp-solution',
        params: {
          item: item,
        },
      })
    },
    deleteInvited(item) {
      this.$axios.delete(urlKlpInvationsDelete(item.id))
      this.$setSnackbar({ text: 'Видалено' })
      this.$store.commit('refreshPage')
    },
  },
  created() {
    fillDirectory('allUsers')
  },
}
</script>
