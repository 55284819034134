<template>
  <div
    class="d-flex align-center justify-space-between dashboard-block"
    style="background: #09487a">
    <div class="dashboard-title">
      📈
      <span>{{ getContactInitials(user, { isResponsible: true }) }}</span>
    </div>
    <div class="navigation">
      <v-btn icon size="x-small" @click="$emit('yearCounter', 'minus')">
        <v-icon color="white">{{ 'mdi-minus' }}</v-icon>
      </v-btn>
      <div class="navigation-text ml-1 mr-1 cursor-default">
        <span>{{ currentDashboardYear || new Date().getFullYear() }}</span>
      </div>
      <v-btn icon size="x-small" @click="$emit('yearCounter', 'plus')">
        <v-icon color="white">{{ 'mdi-plus' }}</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getContactInitials } from '@/utils/helperFunc'

export default {
  emits: ['yearCounter'],
  data: () => ({
    currentDashboardYear: null,
  }),
  computed: {
    ...mapState({
      user: state => state.user,
    }),
  },
  methods: {
    getContactInitials,
    year() {
      this.currentDashboardYear = localStorage.getItem('currentDashboardYear')
    },
  },
  created() {
    this.year()
  },
}
</script>

<style scoped>
.dashboard-title {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  display: block;
}

.dashboard-block {
  background: #fff;
  border-radius: 4px;
  padding: 10px;
}
.navigation {
  display: flex;
  align-items: center;
  .navigation-text {
    border-radius: 4px;
    background: #fff;
    width: 50px;
    text-align: center;
    span {
      font-size: 14px;
      font-weight: 600;
    }
  }
}
</style>
