<template>
  <div>
    <EntityAgreements :entityTypeId="2" :entityData="contractor" />
  </div>
</template>
<script>
import EntityAgreements from '@/components/EntityAgreements.vue'

export default {
  components: { EntityAgreements },
  props: { contractor: { type: Object } },
}
</script>
