<template>
  <div v-if="$route.name !== 'main'" class="d-inline-block pl-4">
    <div class="breadScrumb">
      <v-fade-transition>
        <div
          v-show="breadScrumb.state && !breadScrumb.customState"
          class="breadScrumb-mobile">
          <span v-for="(item, key) in customBredscrumbData" :key="key">
            <span v-if="key !== 0" class="slash"> / </span>
            <router-link
              :to="item.path"
              :class="item.disabled ? 'not-active' : ''">
              {{ item.text }}
            </router-link>
          </span>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <section-loader
          v-show="!breadScrumb.state"
          class="pt-1"
          :size="15"
          :width="1"
          height="15px">
        </section-loader>
      </v-fade-transition>
    </div>
  </div>
</template>
<script>
import sectionLoader from '@/components/section-loader.vue'
import { mapState } from 'vuex'
export default {
  components: {
    sectionLoader,
  },
  data: () => ({
    breadScrumbTimeout: 250,
  }),
  computed: {
    ...mapState({
      breadScrumb: state => state.breadScrumb,
    }),
    hasLinks() {
      return Object.keys(this.breadScrumb.links).length > 1
    },
    breadscrumbData() {
      return this.breadScrumb.links
    },
    currentRoute() {
      return this.$route.matched
    },
    customBreadScrumbPart() {
      return this.breadScrumb.customLinks
    },
    customBredscrumbData() {
      if (!this.customBreadScrumbPart.length) return this.breadscrumbData

      return Object.keys(this.breadscrumbData).map((v, i) => {
        return {
          text: this.customBreadScrumbPart[i] || this.breadscrumbData[i].text,
          path: this.breadscrumbData[i].path,
          disabled: this.breadscrumbData[i].disabled,
        }
      })
    },
  },
  methods: {
    getCurrentPath(path) {
      const params = this.$route.params
      for (const param in params) {
        path = path.replace(`:${param}`, params[param])
      }
      return path
    },
    getBreadsCrumbData() {
      this.$store.commit('setBreadScrumb', { links: [], state: false })
      const routeData = this.$route.matched
      const paths = this.$route.matched.length
      let i = 0
      const breadsData = routeData
        .filter((route, key) => key !== 0)
        .map(route => {
          i++
          if (i + 1 < paths) {
            return {
              path: this.getCurrentPath(route.path),
              text: route.meta.title,
              disabled: false,
            }
          } else {
            return {
              path: this.getCurrentPath(route.path),
              text: route.meta.title,
              disabled: true,
            }
          }
        })

      setTimeout(() => {
        this.$store.commit('setBreadScrumb', {
          links: breadsData,
          state: true,
        })
      }, this.breadScrumbTimeout)
    },
  },
  watch: {
    currentRoute() {
      setTimeout(() => {
        this.getBreadsCrumbData()
      }, 0)
    },
  },
  mounted() {
    setTimeout(() => this.getBreadsCrumbData(), 0)
  },
}
</script>
<style lang="scss">
.breadScrumb {
  display: block;
  font-size: 15px;
  a {
    color: #424242 !important;
    text-decoration: none;
  }
  .not-active {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    color: slategrey !important;
  }
  .slash {
    /** */
  }
}
</style>
