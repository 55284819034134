<template>
  <div>
    <div v-html="formattedOtherData"></div>
  </div>
</template>

<script>
export default {
  name: 'OtherTable',
  props: {
    otherInfo: { type: Object },
    source: { type: String },
  },
  computed: {
    formattedOtherData() {
      let output = ''
      // if autoria
      if (this.source === 'autoRia') {
        for (const field in this.otherInfo) {
          // eslint-disable-next-line no-prototype-builtins
          if (this.otherInfo.hasOwnProperty(field)) {
            const value = this.otherInfo[field]
            if (value !== null) {
              output += `<span>${field}: ${value}</span><br>`
            }
          }
        }
        //   if another parthers
      } else {
        if (this.otherInfo && this.otherInfo.comments) {
          for (const field in this.otherInfo.comments) {
            // eslint-disable-next-line no-prototype-builtins
            if (this.otherInfo.comments.hasOwnProperty(field)) {
              const value = this.otherInfo.comments[field]
              if (value !== null) {
                output += `<span>${field}: ${value}</span><br>`
              }
            }
          }
        }

        if (this.otherInfo) {
          for (const field in this.otherInfo) {
            // eslint-disable-next-line no-prototype-builtins
            if (this.otherInfo.hasOwnProperty(field) && field !== 'comments') {
              const value = this.otherInfo[field]
              if (value !== null) {
                output += `<span>${field}: ${value}</span><br>`
              }
            }
          }
        }
      }

      return output
    },
  },
}
</script>
