<template>
  <div>
    <InfoFields
      v-for="(a, idx) in assistanceCards"
      :key="idx"
      style="padding-bottom: 1rem; margin-bottom: 1rem; padding-left: 0"
      :style="{
        borderBottom: idx + 1 < assistanceCards.length ? '1px solid #ccc' : 'none',
      }"
      :fields="a" />
    <div v-if="!assistanceCards.length" class="text-center disabled">
      Немає даних для відображення
    </div>
  </div>
</template>

<script>
import InfoFields from '@/components/info-fields/InfoFields.vue'
import { toFormatDate } from '@/utils/helperFunc'

export default {
  components: { InfoFields },
  props: {
    LO: { type: Object },
  },
  computed: {
    assistanceCards() {
      if (!this.LO || !this.LO.КартыАвтоассистанса) {
        return []
      }
      return this.LO.КартыАвтоассистанса.map(assistance => {
        return [
          { label: 'Дата початку', value: toFormatDate(assistance.ДатаНачала, true) },
          { label: 'Дата кінця', value: toFormatDate(assistance.ДатаОкончания, true) },
          { label: 'Джерело карти', value: assistance.ИсточникКарты },
          { label: 'Пакет', value: assistance.Пакет },
          { label: 'Коментар', value: assistance.Комментарий },
        ]
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
