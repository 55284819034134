<template>
  <div class="text-center login-card">
    <div class="auth-text">
      <div style="display: flex; align-items: start; flex-direction: column">
        <span
          class="d-block"
          style="color: #ffffff; font-weight: 700; font-size: 40px"
          >Вітаємо у BEST24!</span
        >
        <span class="d-block" style="color: #ffffff"
          >Введіть логін та пароль, щоб увійти в свій акаунт.</span
        >
      </div>
    </div>
    <div class="login-wrapper">
      <div
        class="new-login-form"
        :style="{
          width:
            $route.name === 'auth-confirm' && !$store.state.auth.token
              ? '50%'
              : '50%',
        }">
        <BLlogo
          class="start-app__bl-logo-mb logoBl"
          :addProps="'position: absolute; right: 20px; top: 25px;'"
          :width="170"
          :height="50"
          color="black" />
        <div class="input-wrapper" style="padding: 40px 25px">
          <div v-if="$route.name === 'login'" class="text-left auth-title">
            УВІЙДІТЬ У СВІЙ ОБЛІКОВИЙ ЗАПИС
          </div>
          <router-view></router-view>
          <v-divider class="pt-4"></v-divider>
        </div>
      </div>
      <div class="new-footer-txt text-left">
        <v-divider class="pt-4"></v-divider>
        <span class="auth-company-info" style="color: #ffffff">
          © {{ new Date().getUTCFullYear() }} ©BEST24. All Rights Reserved.
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import BLlogo from '@/assets/svg/best-leasing-logo.vue'
import { phoneMask } from '@/utils/masks'
import { useVuelidate } from '@vuelidate/core'
export default {
  components: { BLlogo },
  setup() {
    return { v$: useVuelidate() }
  },
  methods: {
    phoneMask,
  },
}
</script>

<style scoped>
.auth-title {
  color: #000000;
  font-size: 18px;
  font-weight: 700;
  line-height: 41px;
  margin-bottom: 20px;
}
.login-card {
  display: flex;
  justify-content: right;
  align-items: center;
  height: 100vh;
  width: 96%;
}
.new-login-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 50%;
  background: #ffffff;
  border-radius: 30px;
}
.login-wrapper {
  height: 100vh;
  width: 70%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}
</style>
