<template>
  <div class="white-block mt-2">
    <span>Тимчасово недоступно</span>
  </div>
</template>
<script>
import { LODescription as getLoDescription } from '@/utils/helperFunc'
import { mapState } from 'vuex'
export default {
  components: {},
  props: {
    readonly: { type: Boolean },
  },
  computed: {
    ...mapState({
      calculation: state => state.contracts.serviceContract.calculation,
      serviceContract: state => state.contracts.serviceContract,
      $v: state => state.contracts.$vService,
    }),
    LO() {
      return getLoDescription(this.calculation)
    },
    fields() {
      return [
        [
          {
            label: "Об'єкт лізингу",
            value: this.LO.description({ detailed: true }),
            slot: 'leasingObject',
          },
        ],
      ]
    },
  },
}
</script>
