/**
 * operativeMoreAdvances (Static)
 *
 */

const operativeMoreAdvances = () => {
  return [
    {
      image: 'moreAdvanceBackground',
      absolutePosition: { x: 16, y: 32 },
      opacity: 0.4,
      pageBreak: 'before',
    },
    {
      image: 'moreAdvanceFigure',
      absolutePosition: { x: 300, y: 0 },
    },
    [
      {
        text: [
          {
            text: 'ПЕРЕВАГИ ОПЕРАТИВНОГО',
            margin: [0, 12, 0, 0],
            width: '100%',
            bold: true,
            color: '#d7514f',
          },
        ],
        margin: [0, 5, 0, 3],
        fontSize: 18,
        bold: true,
      },
    ],
    [
      {
        text: [
          {
            text: 'лізингу ВIД BEST LEASING',
            margin: [0, 3, 0, 0],
            width: '100%',
            bold: true,
            color: '#d7514f',
          },
        ],
        margin: [0, 0, 0, 20],
        fontSize: 18,
        bold: true,
      },
    ],
    [
      {
        text: [
          {
            text: 'Ви користуєтесь - ми обслуговуємо',
            margin: [0, 12, 0, 0],
            bold: true,
          },
        ],
        margin: [0, 30, 0, 13],
        fontSize: 13,
        bold: true,
      },
    ],
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Вигiдно для всiх, хто економить свiй час для розвитку бiзнесу',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: "Ви не переймаєтесь бухгалтерським облiком та амортизацiєю об'єкта лізингу - це все робить лізингова компанiя",
          margin: [0, 2, 0, 0],
        },
      ],
    },
    [
      {
        text: [
          {
            text: "Всi турботи по об'єкту лізингу на лізинговiй компанiї",
            margin: [0, 12, 0, 0],
            bold: true,
          },
        ],
        margin: [0, 30, 0, 13],
        fontSize: 13,
        bold: true,
      },
    ],
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Вся вiдповiдальнiсть та витрати з оформлення, страхування, сервісного обслуговування, та оподаткування на лізинговій компанії',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Просте бухгалтерське адміністрування угоди',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    [
      {
        text: [
          {
            text: 'Відсутні витрати поза бюджетом',
            margin: [0, 12, 0, 0],
            bold: true,
          },
        ],
        margin: [0, 30, 0, 13],
        fontSize: 13,
        bold: true,
      },
    ],
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Оптимально збюджетовані рівні лізингові платежі',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Можливість отримати весь спектр сервісних послуг в одному платежі',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Мінімальні початкові витрати для отримання авто',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    [
      {
        text: [
          {
            text: 'Вигідне оподаткування',
            margin: [0, 12, 0, 0],
            bold: true,
          },
        ],
        margin: [0, 30, 0, 13],
        fontSize: 13,
        bold: true,
      },
    ],
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Щомісячний платіж повністю відноситься на валові витрати та зменшує базу оподаткування',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Щомісячне отримання податкового кредиту по ПДВ',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    [
      {
        text: [
          {
            text: 'Наявність права на тримання власності',
            margin: [0, 12, 0, 0],
            bold: true,
          },
        ],
        margin: [0, 30, 0, 13],
        fontSize: 13,
        bold: true,
      },
    ],
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: "Можливість стати власником об'єкту лізингу по залишковій вартості",
          margin: [0, 2, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Регулювання залишкової вартості дозволяє оптимізувати навантаження на бюджет',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Можливість включити автомобіль у мотиваційну систему співробітника',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    [
      {
        text: [
          {
            text: "Можливість повернути об'єкт лізингу",
            margin: [0, 12, 0, 0],
            bold: true,
          },
        ],
        margin: [0, 30, 0, 13],
        fontSize: 13,
        bold: true,
      },
    ],
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Відсутня необхідність займатися продажем авто в кінці строку лізингу',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: "Можливість замінити об'єкт оренди на вищий клас",
          margin: [0, 2, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Можливість швидко змінювати напрямки розвитку бізнесу',
          margin: [0, 2, 0, 0],
        },
      ],
    },
  ]
}

export { operativeMoreAdvances }
