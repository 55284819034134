<template>
  <div>
    <span class="label">Індекс</span>
    <v-text-field
      v-model="houseIndex"
      v-mask:[addressIndexMask]
      :error-messages="errorMessages"
      placeholder="введіть індекс"
      dense
      hide-details
      @blur="$emit('blur')">
    </v-text-field>
  </div>
</template>

<script lang="ts">
import { computed, PropType } from 'vue'
import { addressIndexMask } from '@/utils/masks.js'

export default {
  name: 'HouseIndex',
  props: {
    modelValue: {
      type: String,
    },
    errorMessages: {
      type: Array as PropType<string[]>,
    },
  },
  emits: ['update:model-value', 'blur'],
  data: () => {
    return { addressIndexMask }
  },
  setup(props, { emit }) {
    const houseIndex = computed({
      get() {
        return props.modelValue
      },
      set(v) {
        return emit('update:model-value', v)
      },
    })

    return { houseIndex }
  },
}
</script>

<style scoped></style>
