import { cachedObject } from 'best-modules/utils'
import {
  minValue,
  required,
  requiredIf,
  minLength,
} from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { pick } from 'lodash'
import { CAR, TRUCK, MOTO, BUS } from '@/pages/calculations/object-types.js'
import { computed } from 'vue'
import { Car } from './types'

export function useCar() {
  const getRequestObject = () => {
    return {
      category: car.leasingObjectType,
      mark: car.leasedAssertMark,
      model: car.leasedAssertModel,
      year: car.leasingObjectYear,
      generation: car.generation,
      body: car.body,
      modification: car.modification,
      specification: car.specification,
      engine: car.engine,
      transmission: car.transmission,
      price: car.price,
      carNumber: car.carNumber,
      photos: car.photos,
      mileage: car.mileage,
      description: car.description,
      vinCode: car.vinCode,
      link: car.link,
    }
  }
  const car = cachedObject<Car>({
    leasingObjectType: null,
    leasedAssertMark: null,
    leasedAssertModel: null,
    leasingObjectYear: null,
    generation: null,
    body: null,
    modification: null,
    specification: null,
    engine: null,
    transmission: null,
    price: null,
    carNumber: null,
    photos: null,
    mileage: null,
    description: null,
    vinCode: null,
    link: null,
  } as unknown as Car)

  const standardType = computed(() => {
    return [CAR, TRUCK, MOTO, BUS].includes(car.leasingObjectType?.id)
  })

  const rules = {
    leasingObjectType: { required: required },
    leasedAssertMark: { required: required },
    leasedAssertModel: { required: required },
    leasingObjectYear: { required: required },
    body: { required: required },
    generation: { required: requiredIf(standardType) },
    engine: { required: requiredIf(standardType) },
    modification: { required: requiredIf(standardType) },
    specification: { required: requiredIf(standardType) },
    transmission: { required: requiredIf(standardType) },
    price: { required: required, minValue: minValue(1) },
    carNumber: { required: required },
    // photos: { required: required },
    mileage: { required: required },
    vinCode: { minLength: minLength(17) },
  }

  const v$ = useVuelidate(rules, car)

  const handleCarApi = (carApiData: Record<string, any>) => {
    Object.assign(
      car.$object,
      pick(carApiData, [
        'leasingObjectType',
        'leasedAssertMark',
        'leasedAssertModel',
        'leasingObjectYear',
        'generation',
        'body',
        'engine',
        'modification',
        'specification',
        'transmission',
      ])
    )
  }

  return { v$, car, getRequestObject, handleCarApi }
}
