const pagination = {
  state: () => ({
    pagination: {
      curPage: 1,
      totalPage: 0,
      count: 0,
    },
  }),
  mutations: {
    setCurPage: (state, page) => (state.pagination.curPage = page),
    setCount: (state, count) => (state.pagination.count = count),
    setPagination: (state, pagination) =>
      (state.pagination = { ...state.pagination, ...pagination }),
  },
}
export { pagination }
