<template>
  <div>
    <v-tabs
      v-if="LOs.length"
      v-model="currentTab"
      :show-arrows="LOs.length > 1"
      class="ml-3"
      slider-color="#ffffff00"
      :disabled="LOs.length === 1">
      <v-tab
        v-for="(lo, idx) in LOs"
        :key="idx"
        style="
          background-color: white;
          border-radius: 8px;
          border: 1px solid #0000001f;
        ">
        <div style="padding: 7px; display: flex; align-items: center">
          <div style="margin-right: 10px">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none">
              <path
                d="M2 6.75L3.5 1.6875H14.5L16 6.75M14.5 12.375C14.1022 12.375 13.7206 12.1972 13.4393 11.8807C13.158 11.5643 13 11.1351 13 10.6875C13 10.2399 13.158 9.81072 13.4393 9.49426C13.7206 9.17779 14.1022 9 14.5 9C14.8978 9 15.2794 9.17779 15.5607 9.49426C15.842 9.81072 16 10.2399 16 10.6875C16 11.1351 15.842 11.5643 15.5607 11.8807C15.2794 12.1972 14.8978 12.375 14.5 12.375ZM3.5 12.375C3.10218 12.375 2.72064 12.1972 2.43934 11.8807C2.15804 11.5643 2 11.1351 2 10.6875C2 10.2399 2.15804 9.81072 2.43934 9.49426C2.72064 9.17779 3.10218 9 3.5 9C3.89782 9 4.27936 9.17779 4.56066 9.49426C4.84196 9.81072 5 10.2399 5 10.6875C5 11.1351 4.84196 11.5643 4.56066 11.8807C4.27936 12.1972 3.89782 12.375 3.5 12.375ZM15.92 1.125C15.72 0.4725 15.16 0 14.5 0H3.5C2.84 0 2.28 0.4725 2.08 1.125L0 7.875V16.875C0 17.1734 0.105357 17.4595 0.292893 17.6705C0.48043 17.8815 0.734784 18 1 18H2C2.26522 18 2.51957 17.8815 2.70711 17.6705C2.89464 17.4595 3 17.1734 3 16.875V15.75H15V16.875C15 17.1734 15.1054 17.4595 15.2929 17.6705C15.4804 17.8815 15.7348 18 16 18H17C17.2652 18 17.5196 17.8815 17.7071 17.6705C17.8946 17.4595 18 17.1734 18 16.875V7.875L15.92 1.125Z"
                fill="#838282" />
            </svg>
          </div>
          <span style="display: block">
            {{ lo.name }}
          </span>
        </div>
      </v-tab>
    </v-tabs>
    <v-window v-model="currentTab">
      <v-window-item v-for="(lo, idx) in LOs" :key="idx" :value="idx">
        <LO
          v-model:calculation="calculationsData[idx]"
          :projectData="project"
          :LOData="lo"
          :calcIdx="idx"
          :v="v"></LO>
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
import LO from './LO.vue'
import { LODescription as getDescription } from '@/utils/helperFunc'
export default {
  components: {
    LO,
  },
  props: {
    projectData: { type: Object },
    calculations: { type: Array },
    v: { type: Object },
  },
  data() {
    return {
      currentTab: 0,
    }
  },
  computed: {
    LOs() {
      return Array.isArray(this.calculationsData)
        ? this.calculationsData.map(c => this.getDescription(c))
        : []
    },
    project() {
      return this.projectData
    },
    calculationsData() {
      return this.calculations
    },
  },
  methods: {
    getDescription,
  },
}
</script>

<style scoped>
.v-tab.v-tab-item--selected svg path {
  fill: #fc7247 !important;
}
</style>
