<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 15 16"
    fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.6223 2.88136C11.2564 1.51461 9.44099 0.761567 7.50866 0.761108C3.51932 0.761108 0.274783 4.00427 0.27295 7.99086C0.272491 9.35715 0.654741 10.6872 1.37891 11.839L1.55124 12.1126L0.820199 14.7806L3.55782 14.0629L3.82228 14.2196C4.93237 14.8787 6.20562 15.227 7.50362 15.2275H7.50637C11.4929 15.2275 14.7375 11.9839 14.7389 7.99682C14.7393 6.06494 13.9881 4.24811 12.6223 2.88136Z"
      fill="#40C351" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.33112 4.35398C5.16841 3.9919 4.99699 3.98457 4.84162 3.97815C4.71466 3.97265 4.56982 3.97311 4.42499 3.97311C4.28016 3.97311 4.04457 4.02765 3.8452 4.24536C3.64582 4.46307 3.08391 4.98878 3.08391 6.05853C3.08391 7.12827 3.86307 8.16228 3.9717 8.30711C4.08032 8.45194 5.47595 10.7175 7.68603 11.5892C9.52257 12.3134 9.89657 12.1695 10.2953 12.1333C10.6941 12.0971 11.5819 11.6076 11.7629 11.0997C11.9439 10.5919 11.9439 10.1569 11.8899 10.0662C11.8353 9.97544 11.6905 9.92136 11.4732 9.81227C11.256 9.70319 10.1867 9.17748 9.98732 9.10507C9.78795 9.03265 9.64312 8.99644 9.49782 9.21415C9.35299 9.4314 8.93637 9.92136 8.80941 10.0662C8.68245 10.2115 8.55549 10.2298 8.33824 10.1207C8.12099 10.0117 7.42066 9.78248 6.5897 9.04182C5.94345 8.46569 5.50712 7.7539 5.38016 7.53619C5.2532 7.31894 5.36641 7.20115 5.47549 7.09253C5.57312 6.9949 5.69274 6.83861 5.80182 6.71165C5.91045 6.58469 5.94666 6.49394 6.01907 6.34911C6.09149 6.20382 6.05528 6.07686 6.00074 5.96823C5.94712 5.85915 5.52453 4.7839 5.33112 4.35398Z"
      fill="white" />
  </svg>
</template>

<script>
export default {
  name: 'TellIcon',
}
</script>
