<template>
  <div>
    <div v-if="!(isSendSms || leadData.isSendMessageService)">
      <v-dialog v-model="dialog" persistent width="450">
        <v-card>
          <v-card-actions
            style="
              justify-content: space-between !important;
              height: 60px !important;
            ">
            <v-card-title style="color: #08487a !important">
              Деталі повідомлення
            </v-card-title>
            <v-btn icon @click="dialog = false">
              <CloseIcon />
            </v-btn>
          </v-card-actions>
          <v-card-text style="padding: 0 !important">
            <div class="doc-modal-statut">
              <div class="d-flex align-center ml-5 mr-5">
                <v-select
                  v-model="messageSms.sendType"
                  label="Оберіть тип відправки"
                  :items="
                    leadSendTypes.filter(
                      t => +leadData.telegramChatId || t.value !== 'telegram'
                    )
                  "
                  class="mb-5 mt-5"
                  item-title="label"
                  item-value="value"
                  dense
                  :readonly="readonly"
                  hide-details>
                </v-select>
              </div>
              <div class="pl-5 pr-5 mb-5">
                <v-textarea
                  v-model="messageSms.message"
                  label="Введіть текст"
                  placeholer="смс"
                  :rows="3"
                  dense
                  hide-details
                  :readonly="readonly">
                </v-textarea>
              </div>
            </div>
          </v-card-text>
          <v-card-actions style="justify-content: space-around !important">
            <v-btn class="btnCancel" @click="() => (dialog = false)">
              ВІДХИЛИТИ
            </v-btn>
            <v-btn
              class="btnSendModal"
              :loading="loading"
              :disabled="
                !messageSms.message || !messageSms.sendType || readonly
              "
              @click="sendSms">
              ВІДПРАВИТИ
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-tabs v-model="currentTab" class="mb-5 mt-5" show-arrows :height="38">
        <v-tab>Нагадування</v-tab>
        <v-tab>Повідомлення</v-tab>
        <v-tab>Історія дзвінків</v-tab>
      </v-tabs>
      <v-tabs-window v-model="currentTab">
        <v-tabs-window-item>
          <TaskPlan
            :readonly="!!lead.responsibleId"
            :tasks="lead.task_schedulers"
            :leadId="lead.id"
            @update="$emit('updateLead')" />
        </v-tabs-window-item>
        <v-tabs-window-item>
          <span class="section-title">Повідомлення</span>
          <PhoneMessages
            :readonly="readonly"
            :messages="leadData.sends"
            :leadId="leadData.id" />
        </v-tabs-window-item>
        <v-tabs-window-item>
          <PhoneCalls
            :readonly="readonly"
            :calls="lead.detail_calls"
            :leadId="lead.id" />
        </v-tabs-window-item>
      </v-tabs-window>
    </div>
  </div>
</template>

<script>
import { urlGetLeadSendSms, urlSendTelegramMsg } from '@/pages/request'
import { toFormatDate } from '@/utils/helperFunc'
import { urlTaskPlanTaskDelete } from '@/pages/request'
import PhoneMessages from '@/components/PhoneMessages.vue'
import PhoneCalls from '../LeadPhoneCalls.vue'
import TaskPlan from '@/components/TaskPlan.vue'
import CloseIcon from '@/assets/svg/close-icon.vue'
export default {
  components: {
    PhoneMessages,
    CloseIcon,
    PhoneCalls,
    TaskPlan,
  },
  emits: ['updateLead'],
  props: {
    lead: { type: Object },
    readonly: { type: Boolean, default: false },
  },
  data: () => ({
    currentTab: 0,
    loading: false,
    leadPlanTask: {
      date: null,
      message: null,
      leadId: null,
      applicationId: null,
      typeId: 2,
    },
    messageSms: {
      message: null,
      sendType: null,
      typeId: 1,
      latitude: 12144.21214,
      longitude: 2323.23135,
    },
    isSendSms: false,
    dialog: false,
    leadSendTypes: [
      { label: 'СМС', value: 'sms' },
      { label: 'Telegram', value: 'telegram' },
      { label: 'Viber', value: 'viber' },
    ],
  }),
  computed: {
    leadData() {
      return this.lead
    },
  },
  methods: {
    toFormatDate,
    sendSms() {
      this.loading = true
      if (this.messageSms.sendType === 'telegram') {
        const { typeId, message, latitude, longitude } = this.messageSms
        this.$axios.post(urlSendTelegramMsg(this.leadData.id), {
          typeId,
          message,
          latitude,
          longitude,
        })
      } else {
        const { message, sendType } = this.messageSms
        this.$axios.post(urlGetLeadSendSms(), {
          leadId: this.leadData.id,
          message,
          sendType,
        })
      }

      this.$setSnackbar({ text: 'Повідомлення надіслано' })
      this.clearSendSmsData()
      this.loading = false
      this.isSendSms = false
      this.dialog = false
    },
    clearCoords() {
      this.messageSms.latitude = null
      this.messageSms.longitude = null
    },
    clearSendSmsData() {
      for (const field in this.messageSms) {
        this.messageSms[field] = null
      }
    },
    deleteTask(item) {
      this.$axios
        .delete(urlTaskPlanTaskDelete(item.id))
        .then(() => {
          this.$setSnackbar({ text: 'Нагадування видалено' })
          this.$emit('updateLead')
        })
        .catch(this.$err)
    },
    openEditDialog(item) {
      this.$store.commit('setDialog', {
        title: 'Редагування нагадування',
        dialogItem: item,
        params: {
          cb: () => this.$emit('updateLead'),
        },
        action: 'task-plan-edit',
      })
    },
  },
}
</script>

<style scoped>
.section-title {
  color: #08487a;
  font-weight: 700;
  font-size: 16px;
}
.btnSend {
  color: #ffffff !important;
  background: #fe6d01 !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04) !important;
  border-radius: 4px !important;
}

.btnCancel {
  background: transparent !important;
  color: #08487a;
  width: 150px;
  border: 1px solid #08487a;
  border-radius: 4px;
}

.btnSendModal {
  background: #08487a !important;
  color: #ffffff;
  width: 150px;
  border-radius: 4px;
}

.crm-layout .crm .crm-content .v-data-table-header {
  background: transparent !important;
  border-bottom: none !important;
}

.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
