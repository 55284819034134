<script setup></script>

<template>
  <svg
    style="background: #083457"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="904"
    viewBox="0 0 1307 904"
    fill="none">
    <ellipse cx="194" cy="204" rx="76" ry="16" fill="#064170" />
    <ellipse cx="288" cy="584" rx="64" ry="13" fill="#064170" />
    <ellipse cx="876.5" cy="228" rx="37.5" ry="13" fill="#064170" />
    <ellipse cx="1140.5" cy="651" rx="37.5" ry="13" fill="#064170" />
    <ellipse cx="755.5" cy="676" rx="24.5" ry="8" fill="#064170" />
    <ellipse cx="64" cy="888" rx="30" ry="10" fill="#064170" />
    <ellipse cx="929" cy="234" rx="13" ry="6" fill="#064170" />
    <path
      d="M316.783 75.442L305.938 122.038L303.069 134.49H298.478L301.519 121.464L312.422 74.3518L316.783 75.442Z"
      fill="#1E3861" />
    <path
      d="M260.432 92.8871H310.414C314.545 92.8871 318.161 89.7883 319.194 85.3698L325.85 56.5055C327.285 50.1933 322.981 43.9958 317.07 43.9958H267.089C262.957 43.9958 259.342 47.0946 258.309 51.5132L251.652 80.3774C250.218 86.6896 254.522 92.8871 260.432 92.8871Z"
      fill="#FFD05C" />
    <path
      d="M317.242 197.097C318.505 196.925 319.308 195.777 319.136 194.514L307.372 123.301L302.954 123.932L314.775 195.203C314.89 195.777 315.177 196.293 315.636 196.638C316.037 197.039 316.611 197.211 317.242 197.097Z"
      fill="#1E3861" />
    <path
      d="M272.483 197.097C273.745 196.925 274.549 195.777 274.376 194.514L262.613 123.301L258.194 123.932L270.015 195.203C270.13 195.834 270.474 196.408 270.991 196.695C271.392 197.039 271.909 197.154 272.483 197.097Z"
      fill="#1E3861" />
    <path
      d="M257.907 197.097C257.276 196.982 256.76 196.695 256.415 196.179C256.071 195.719 255.899 195.088 256.014 194.514L267.777 123.301L272.196 123.932L260.432 195.26C260.203 196.465 259.112 197.269 257.907 197.097Z"
      fill="#1E3861" />
    <path
      d="M213.148 197.097C212.402 196.982 211.828 196.58 211.484 195.949C211.254 195.547 211.139 195.031 211.254 194.514L223.018 123.301L227.436 123.932L215.673 195.26C215.443 196.465 214.353 197.269 213.148 197.097Z"
      fill="#1E3861" />
    <path
      d="M309.782 128.006C309.782 131.622 306.856 134.491 303.298 134.491H211.426C209.647 134.491 207.983 133.745 206.835 132.597C205.688 131.449 204.942 129.785 204.942 128.006C204.942 124.391 207.868 121.522 211.426 121.522H303.298C304.216 121.522 305.134 121.752 305.938 122.096C306.684 122.44 307.315 122.899 307.889 123.416C309.094 124.563 309.782 126.17 309.782 128.006Z"
      fill="#FFD05C" />
    <path
      d="M129.998 198.13L123.973 196.58L151.288 87.8948L163.453 90.9935L129.998 198.13Z"
      fill="#1E3861" />
    <path
      d="M340.253 198.13L346.279 196.58L318.964 87.8948L306.799 90.9935L340.253 198.13Z"
      fill="#1E3861" />
    <path
      d="M190.768 182.521C190.768 182.521 187.44 188.718 182.906 189.636C178.373 190.555 171.946 188.489 172.348 196.982H198.572C198.572 196.982 200.466 190.268 198.572 183.783C196.621 177.299 190.768 182.521 190.768 182.521Z"
      fill="#C0C1C7" />
    <path
      d="M209.992 182.521C209.992 182.521 206.663 188.718 202.13 189.636C197.539 190.612 191.17 188.489 191.571 196.982H217.796C217.796 196.982 219.689 190.268 217.796 183.783C215.902 177.299 209.992 182.521 209.992 182.521Z"
      fill="#C0C1C7" />
    <path
      d="M245.225 97.707C245.225 97.707 205.114 98.9694 199.949 113.029C194.842 127.088 187.497 184.242 187.497 184.242C187.497 184.242 192.891 191.014 201.269 187.398L220.091 131.219C220.091 131.219 290.157 133.802 294.633 104.708L257.276 97.6497H245.225V97.707Z"
      fill="#DAF2FF" />
    <path
      d="M264.449 97.707C264.449 97.707 224.338 98.9694 219.173 113.029C214.066 127.088 206.721 184.242 206.721 184.242C206.721 184.242 212.115 191.014 220.493 187.398L239.315 131.219C239.315 131.219 290.157 133.802 294.633 104.708L276.5 97.6497H264.449V97.707Z"
      fill="#DAF2FF" />
    <path
      d="M242.069 97.7073C242.069 97.7073 268.351 112.111 294.633 104.766C294.633 104.766 296.527 45.1434 289.354 37.7983C285.337 33.724 278.738 32.4042 273.516 32.1172C269.212 31.8303 265.826 32.232 265.826 32.232C263.359 32.2894 260.375 32.6337 257.391 33.3224C250.734 34.757 244.02 37.6835 242.815 41.643C241.094 47.3241 242.069 97.7073 242.069 97.7073Z"
      fill="white" />
    <path
      d="M257.391 82.5577C257.391 86.3451 237.077 86.3451 237.077 82.5577C237.077 78.7704 241.61 75.6716 247.234 75.6716C252.857 75.729 257.391 78.7704 257.391 82.5577Z"
      fill="#B6C0CA" />
    <path
      d="M232.658 55.8169L220.837 65.8017L192.317 63.6785C192.317 63.6785 178.545 54.8413 174.93 61.3831C168.962 72.2287 190.481 72.3435 190.481 72.3435C190.481 72.3435 219.288 79.9756 226.977 78.5983C232.658 77.5654 243.906 67.2363 243.906 67.2363L232.658 55.8169Z"
      fill="#FFF2E3" />
    <path
      d="M243.791 39.9792L228.928 57.3092C228.928 57.3092 230.879 68.3844 242.528 70.0485L254.12 56.6206L243.791 39.9792Z"
      fill="white" />
    <path
      d="M278.795 52.2593L280.976 80.32L298.248 72.9749L292.166 55.8171L278.795 52.2593Z"
      fill="#B6C0CA" />
    <path
      d="M285.853 63.9651L289.182 73.7204L262.498 74.9829C262.498 74.9829 249.988 67.8099 246.316 74.6386C243.217 80.4344 254.177 84.5661 261.695 82.9593C261.695 82.9593 295.953 89.2715 302.954 83.8774C306.971 80.8361 298.937 59.7761 298.937 59.7761L285.853 63.9651Z"
      fill="#FFF2E3" />
    <path
      d="M289.354 37.7986C289.354 37.7986 296.412 46.1193 301.175 61.7852C301.175 61.7852 295.723 65.9168 285.165 66.7202L278.795 52.2594L289.354 37.7986Z"
      fill="white" />
    <path
      d="M260.432 25.1165V34.1258C260.432 36.995 262.785 39.3478 265.654 39.3478C268.695 39.3478 271.106 36.7655 270.876 33.7241L270.13 23.8541L269.958 21.2144L260.432 25.1165Z"
      fill="#FFF2E3" />
    <path
      d="M260.432 34.1259V25.5183L270.187 23.9116C270.187 23.9116 269.327 34.4703 261.235 35.2163C261.006 35.2163 260.776 35.2736 260.547 35.2736L260.432 34.1259Z"
      fill="#FFF2E3" />
    <path
      d="M257.85 13.123C257.85 13.123 253.89 31.6581 262.096 31.6581C270.933 31.6581 270.417 15.7627 270.417 15.7627L262.728 10.885L257.85 13.123Z"
      fill="#FFF2E3" />
    <path
      d="M263.646 16.2795C263.646 16.2795 256.071 18.288 254.751 13.2956C253.431 8.30315 256.186 4.57318 259.858 4.40103C263.531 4.28626 260.203 8.41792 266.63 8.131C273.057 7.84407 277.188 16.4516 269.901 21.7884C269.958 21.8457 269.671 15.1318 263.646 16.2795Z"
      fill="#1E3861" />
    <path
      d="M269.843 25.2889C271.016 25.2889 271.966 24.3383 271.966 23.1657C271.966 21.993 271.016 21.0425 269.843 21.0425C268.671 21.0425 267.72 21.993 267.72 23.1657C267.72 24.3383 268.671 25.2889 269.843 25.2889Z"
      fill="#FFF2E3" />
    <path
      d="M134.13 91.8546H336.179C338.475 91.8546 340.311 90.0183 340.311 87.7229C340.311 85.4276 338.475 83.5913 336.179 83.5913H134.13C132.753 83.5913 131.49 84.2799 130.744 85.3128C130.228 86.0014 129.941 86.8048 129.941 87.7229C129.941 90.0183 131.834 91.8546 134.13 91.8546Z"
      fill="#623C3E" />
    <path
      d="M167.757 70.8516H246.201L239.889 17.0254H161.445L167.757 70.8516Z"
      fill="#9593A0" />
    <path
      d="M164.314 70.8516H242.758L236.446 17.0254H158.002L164.314 70.8516Z"
      fill="#B6C0CA" />
    <path
      d="M193.981 83.5911H206.778L203.622 42.6189H190.825L193.981 83.5911Z"
      fill="#B6C0CA" />
    <path d="M220.091 78.8281H180.611V83.591H220.091V78.8281Z" fill="#B6C0CA" />
    <path
      d="M262.383 32.2895C261.981 32.2895 261.58 32.2322 261.121 32.1748C255.21 31.1993 256.3 20.7553 256.3 20.2963C256.358 19.9519 256.645 19.7224 256.989 19.7224C257.333 19.7798 257.563 20.0668 257.563 20.4111C257.563 20.5258 256.587 30.1089 261.293 30.9123C266.113 31.7157 267.835 26.838 267.892 26.6085C268.007 26.2642 268.351 26.0921 268.695 26.2068C269.04 26.3216 269.212 26.6659 269.097 27.0102C269.097 27.125 267.318 32.2895 262.383 32.2895Z"
      fill="#FFF2E3" />
    <path
      d="M208.04 180.34L206.491 180.168C206.491 179.996 208.5 163.469 211.254 146.484C216.82 111.938 220.034 109.528 221.124 108.725C227.608 103.847 236.503 101.035 236.905 100.921L237.364 102.413C237.249 102.413 228.297 105.282 222.1 109.987C217.509 113.373 211.197 153.944 208.04 180.34Z"
      fill="#B6C0CA" />
    <path
      d="M330.667 449.632C330.667 453.295 313.028 453.295 313.028 449.632C313.028 445.97 317.662 442.981 323.94 442.981C330.218 442.981 330.667 445.97 330.667 449.632Z"
      fill="#1E3861" />
    <path
      d="M361.459 583.34C361.309 583.34 361.085 583.34 360.935 583.265C359.665 582.966 358.917 581.696 359.216 580.5L374.388 517.72C374.687 516.449 375.958 515.702 377.154 516.001C378.424 516.3 379.172 517.57 378.873 518.766L363.701 581.546C363.477 582.593 362.505 583.34 361.459 583.34Z"
      fill="#1E3861" />
    <path
      d="M408.619 583.34C407.572 583.34 406.601 582.593 406.377 581.546L391.205 518.766C390.906 517.496 391.653 516.3 392.924 516.001C394.194 515.702 395.39 516.449 395.689 517.72L410.861 580.5C411.16 581.771 410.412 582.966 409.142 583.265C408.992 583.265 408.768 583.34 408.619 583.34Z"
      fill="#1E3861" />
    <path
      d="M436.496 583.34C436.346 583.34 436.122 583.34 435.973 583.265C434.702 582.966 433.955 581.696 434.254 580.5L449.426 517.72C449.725 516.449 450.995 515.702 452.191 516.001C453.461 516.3 454.209 517.57 453.91 518.766L438.738 581.546C438.514 582.593 437.542 583.34 436.496 583.34Z"
      fill="#1E3861" />
    <path
      d="M483.656 583.34C482.61 583.34 481.638 582.593 481.414 581.546L466.242 518.766C465.943 517.496 466.69 516.3 467.961 516.001C469.231 515.702 470.427 516.449 470.726 517.72L485.898 580.5C486.197 581.771 485.45 582.966 484.179 583.265C484.029 583.265 483.805 583.34 483.656 583.34Z"
      fill="#1E3861" />
    <path
      d="M490.382 497.391C475.36 540.067 385.749 551.277 361.832 510.919C360.188 508.154 358.918 505.164 357.871 501.95C350.547 477.586 373.267 458.901 377.303 435.583C384.852 392.085 415.121 379.156 441.802 381.323C482.161 384.537 507.123 449.783 490.382 497.391Z"
      fill="#C0C1C7" />
    <path
      d="M468.559 464.208C456.6 528.183 362.879 511.143 361.832 510.919C360.188 508.154 358.918 505.164 357.871 501.95C350.547 477.586 373.267 458.901 377.303 435.583C384.852 392.085 415.121 379.156 441.802 381.323C441.877 381.248 480.591 399.933 468.559 464.208Z"
      fill="#C4C4C4" />
    <path
      d="M306.601 564.655C306.601 564.655 305.33 572.577 295.988 573.175C285.301 573.923 285.3 580.873 285.3 580.873H319.456C319.456 580.873 321.025 567.869 319.456 562.861C317.886 557.779 306.601 564.655 306.601 564.655Z"
      fill="#C0C1C7" />
    <path
      d="M268.26 562.637C268.26 562.637 265.495 570.186 256.227 568.99C245.615 567.645 244.269 574.52 244.269 574.52L275.286 583.115C275.286 583.115 281.788 568.541 281.19 563.31C280.592 558.078 268.26 562.637 268.26 562.637Z"
      fill="#C0C1C7" />
    <path
      d="M378.873 453.819C378.873 453.819 314.075 462.713 304.359 478.632C294.643 494.476 266.093 565.478 266.093 565.478C266.093 565.478 274.688 574.297 282.311 568.168C282.311 568.168 317.886 524.073 322.52 497.615C322.52 497.615 385.674 491.861 387.243 482.145L378.873 453.819Z"
      fill="#FC7247" />
    <path
      d="M389.859 463.46C389.859 463.46 332.161 466.375 322.445 482.219C312.729 498.064 305.48 565.552 305.48 565.552C305.48 565.552 313.626 571.681 321.25 565.552C321.25 565.552 339.486 524.222 344.119 497.765C344.119 497.765 420.726 508.602 426.63 482.294L389.859 463.46Z"
      fill="#FC7247" />
    <path
      d="M449.276 447.914C451.743 451.203 454.358 452.698 456.899 451.352C469.904 444.476 465.046 416.898 465.046 416.898L449.276 412.414V447.914Z"
      fill="#FFF2E3" />
    <path
      d="M390.607 404.118L368.783 427.585L337.916 443.654C337.916 443.654 320.278 440.291 320.427 447.989C320.502 453.594 339.859 450.455 339.859 450.455C339.859 450.455 371.399 441.113 377.378 438.198C383.357 435.283 402.415 412.787 402.415 412.787L390.607 404.118Z"
      fill="#FFF2E3" />
    <path
      d="M465.046 418.318C465.046 418.318 459.44 424.97 448.304 423.7C448.304 423.7 442.251 470.56 426.63 482.145C426.63 482.145 378.723 482.518 374.762 454.193C374.762 454.193 387.169 439.992 397.109 419.738C397.109 419.738 394.941 419.664 392.326 418.244C389.486 416.749 386.048 413.759 384.104 407.631C384.104 407.631 398.006 379.529 431.488 385.06C431.488 385.209 461.458 385.359 465.046 418.318Z"
      fill="#FFD05C" />
    <path
      d="M433.432 375.119L434.852 388.049C434.852 388.049 429.172 398.363 424.837 397.092C420.502 395.822 421.698 389.768 421.698 389.768L422.669 379.529L433.432 375.119Z"
      fill="#FFF2E3" />
    <path
      d="M422.146 386.927C422.146 386.927 422.595 393.28 427.154 392.084C431.713 390.888 434.852 378.482 434.852 378.482L419.979 379.229L422.146 386.927Z"
      fill="#FFF2E3" />
    <path
      d="M456.6 423.998L449.351 447.989C439.336 434.76 430.89 392.458 430.89 392.458C430.89 392.458 422.595 381.696 430.89 377.66C440.382 373.101 440.681 390.814 440.681 390.814L456.6 423.998Z"
      fill="#FFF2E3" />
    <path
      d="M418.26 363.984C418.26 363.984 414.598 383.939 424.164 384.985C435.001 386.181 437.468 366.375 437.468 366.375L430.891 359.126L421.997 358.752L418.26 363.984Z"
      fill="#FFF2E3" />
    <path
      d="M436.346 371.531C436.645 371.232 436.047 369.364 435.898 368.916C435.674 368.168 435.375 367.346 434.926 366.673C433.955 365.253 432.46 364.581 430.816 364.73C427.079 365.179 423.192 365.851 419.53 365.104C414.747 364.132 410.637 357.406 416.317 354.491C419.605 352.772 424.089 353.594 427.602 354.043C430.666 354.416 434.104 354.117 437.019 355.388C443.596 358.452 439.485 367.645 436.346 371.531Z"
      fill="#FFD05C" />
    <path
      d="M435.225 375.568C436.629 375.568 437.766 374.43 437.766 373.026C437.766 371.623 436.629 370.485 435.225 370.485C433.822 370.485 432.684 371.623 432.684 373.026C432.684 374.43 433.822 375.568 435.225 375.568Z"
      fill="#FFF2E3" />
    <path
      d="M238.215 458.527L203.537 580.051C203.089 581.546 203.911 582.667 204.957 583.041C206.003 583.489 207.349 583.19 208.021 581.845L267.513 457.331L238.215 458.527Z"
      fill="#1E3861" />
    <path
      d="M488.813 458.528L523.491 580.052C523.94 581.547 523.117 582.668 522.071 583.041C521.025 583.49 519.68 583.191 519.007 581.846L459.515 457.332L488.813 458.528Z"
      fill="#1E3861" />
    <path
      d="M406.974 399.633L397.109 419.812C397.109 419.812 394.941 419.737 392.326 418.317L406.974 399.633Z"
      fill="#FFD05C" />
    <path
      d="M508.842 464.954H218.111C214.523 464.954 211.609 462.04 211.609 458.452C211.609 454.865 214.523 451.95 218.111 451.95H508.842C512.43 451.95 515.344 454.865 515.344 458.452C515.344 460.022 514.747 461.516 513.775 462.638C513.102 463.385 512.28 464.058 511.383 464.431C510.636 464.805 509.739 464.954 508.842 464.954Z"
      fill="#1E3861" />
    <path
      d="M298.529 436.33H394.867C396.212 436.33 397.258 435.059 396.959 433.714L386.87 382.892C386.646 381.845 385.749 381.173 384.777 381.173H288.44C287.094 381.173 286.048 382.443 286.347 383.789L296.437 434.611C296.586 435.582 297.483 436.33 298.529 436.33Z"
      fill="#192942" />
    <path
      d="M294.568 436.33H390.906C392.251 436.33 393.297 435.059 392.998 433.714L382.909 382.892C382.684 381.845 381.788 381.173 380.816 381.173H284.478C283.133 381.173 282.087 382.443 282.386 383.789L292.475 434.611C292.7 435.582 293.596 436.33 294.568 436.33Z"
      fill="#1E3861" />
    <path
      d="M352.191 423.699H326.033L321.847 399.708H347.931L352.191 423.699Z"
      fill="#1E3861" />
    <path
      d="M335.45 448.886H352.49L344.269 404.043H327.229L335.45 448.886Z"
      fill="#FC7247" />
    <path
      d="M355.704 448.138H331.788C329.546 448.138 327.752 449.932 327.752 452.174H359.815C359.815 451.426 359.59 450.754 359.291 450.156C358.469 448.96 357.199 448.138 355.704 448.138Z"
      fill="#1E3861" />
    <path
      d="M425.808 385.507C425.509 385.507 425.285 385.283 425.285 385.059C425.285 384.76 425.435 384.536 425.734 384.461C431.04 383.789 433.731 377.436 433.731 377.361C433.805 377.062 434.179 376.987 434.403 377.062C434.702 377.137 434.777 377.511 434.702 377.735C434.627 378.034 431.787 384.835 425.808 385.507Z"
      fill="#FFF2E3" />
    <path
      d="M453.91 452.548C450.098 452.548 445.763 445.597 440.681 431.397C436.944 420.784 434.179 409.947 434.179 409.873C434.104 409.574 434.254 409.275 434.553 409.2C434.852 409.125 435.15 409.275 435.225 409.574C438.215 421.457 447.482 452.249 454.134 451.427C463.252 450.381 464.971 435.508 464.971 435.358C464.971 435.059 465.27 434.835 465.569 434.91C465.868 434.91 466.092 435.209 466.017 435.508C465.943 436.18 464.149 451.427 454.209 452.548C454.134 452.548 453.984 452.548 453.91 452.548Z"
      fill="#FFF2E3" />
    <path
      d="M1085 559.312H1063.92C1052.21 559.312 1042.65 549.808 1042.65 538.047V478.648C1042.65 466.946 1052.16 457.383 1063.92 457.383H1085C1096.7 457.383 1106.27 466.887 1106.27 478.648V538.106C1106.21 549.808 1096.7 559.312 1085 559.312Z"
      fill="#FC7247" />
    <path
      d="M1133.89 523.91L1136.38 534.72L1165.31 525.929C1165.31 525.929 1176.36 517.435 1182.83 523.316C1188.36 528.305 1176.77 533.295 1168.16 534.483C1168.16 534.483 1134.07 554.263 1124.56 551.233C1119.16 549.511 1116.31 525.216 1116.31 525.216L1133.89 523.91Z"
      fill="#FFF2E3" />
    <path
      d="M1136.68 531.453C1136.68 531.453 1131.39 535.968 1123.02 536.324C1120.46 536.443 1117.61 536.205 1114.52 535.314C1113.1 534.898 1111.61 534.364 1110.07 533.651C1107.4 532.404 1105.91 530.681 1105.32 528.662C1101.87 517.079 1126.7 495.458 1126.7 495.458C1126.7 495.458 1135.02 517.732 1136.68 531.453Z"
      fill="#1E3861" />
    <path d="M1114.17 568.281H1096.41V600.237H1114.17V568.281Z" fill="#1E3861" />
    <path
      d="M1148.38 584.259H1062.25C1057.09 584.259 1052.93 580.102 1052.93 574.934C1052.93 569.766 1057.09 565.608 1062.25 565.608H1148.38C1150.94 565.608 1153.31 566.677 1154.98 568.341C1156.64 570.004 1157.71 572.38 1157.71 574.934C1157.71 580.102 1153.49 584.259 1148.38 584.259Z"
      fill="#FC7247" />
    <path d="M1109.53 600.238H1101.1V634.392H1109.53V600.238Z" fill="#1E3861" />
    <path
      d="M1114.17 650.133H1096.41L1100.8 634.392H1109.77L1114.17 650.133Z"
      fill="#1E3861" />
    <path
      d="M1137.45 652.806C1137.45 654.647 1073.06 654.647 1073.06 652.806C1073.06 650.964 1087.5 649.479 1105.26 649.479C1123.02 649.479 1137.45 650.964 1137.45 652.806Z"
      fill="#1E3861" />
    <path
      d="M1127.59 499.259L1122.96 536.324C1120.4 536.443 1117.55 536.205 1114.46 535.314L1105.26 528.602L1127.59 499.259Z"
      fill="#1E3861" />
    <path
      d="M1149.39 640.57C1149.39 640.57 1151.23 646.807 1158.36 646.569C1165.25 646.391 1167.33 649.836 1165.84 653.994H1140.3C1140.3 653.994 1137.39 649.183 1140.72 641.223C1144.1 633.323 1149.39 640.57 1149.39 640.57Z"
      fill="#C0C1C7" />
    <path
      d="M1120.52 640.57C1120.52 640.57 1122.36 646.807 1129.49 646.569C1136.38 646.391 1138.46 649.836 1136.98 653.994H1111.44C1111.44 653.994 1108.52 649.183 1111.85 641.223C1115.18 633.323 1120.52 640.57 1120.52 640.57Z"
      fill="#C0C1C7" />
    <path
      d="M1114.7 549.273C1114.7 549.273 1155.39 551.174 1159.49 561.331C1163.59 571.488 1154.86 644.312 1154.86 644.312C1154.86 644.312 1145.59 648.173 1137.33 644.312L1137.51 577.963C1137.51 577.963 1077.7 587.11 1068.07 565.192L1084.47 546.125L1114.7 549.273Z"
      fill="#FFD05C" />
    <path
      d="M1114.7 549.273C1114.7 549.273 1134.42 554.797 1133.95 565.192C1133.47 575.587 1126.17 643.361 1126.17 643.361C1126.17 643.361 1117.49 646.51 1108.58 643.361L1109.3 578.498C1109.3 578.498 1071.4 581.883 1068.07 565.252L1075.8 546.185L1114.7 549.273Z"
      fill="#FFD05C" />
    <path
      d="M1111.14 481.321C1111.14 481.321 1127.95 491.062 1127.59 499.259C1127.24 507.397 1114.7 549.274 1114.7 549.274C1114.7 549.274 1088.09 552.184 1068.07 565.192C1068.07 565.192 1062.55 496.171 1071.04 486.251C1079.48 476.331 1111.14 481.321 1111.14 481.321Z"
      fill="#1E3861" />
    <path
      d="M1128.3 628.452L1126.88 628.274C1129.55 607.068 1134.07 565.667 1132.05 560.737C1129.55 554.738 1118.74 552.303 1118.62 552.243L1118.92 550.877C1119.4 550.996 1130.62 553.55 1133.35 560.203C1136.03 566.677 1128.66 625.898 1128.3 628.452Z"
      fill="#8D8BC4" />
    <path
      d="M1105.38 482.212L1105.32 482.568C1105.32 482.568 1106.15 487.023 1100.39 486.667C1094.63 486.31 1093.14 480.252 1093.14 480.252L1096.94 467.956L1098.01 468.312L1106.74 470.926L1105.38 482.212Z"
      fill="#FFF2E3" />
    <path
      d="M1106.8 470.926L1105.38 482.212C1097.71 476.094 1097.06 468.312 1097.06 468.312H1098.01L1106.8 470.926Z"
      fill="#FFF2E3" />
    <path
      d="M1115.3 456.789C1115.3 456.789 1114.52 476.213 1106.68 475.619C1098.84 475.025 1096.76 467.659 1096.76 467.659L1098.01 451.384L1104.72 449.839L1115.3 456.789Z"
      fill="#FFF2E3" />
    <path
      d="M1129.97 546.125C1129.97 550.758 1107.57 550.758 1107.57 546.125C1107.57 541.492 1110.6 537.75 1117.49 537.75C1124.38 537.75 1129.97 541.492 1129.97 546.125Z"
      fill="#1E3861" />
    <path
      d="M1077.46 499.675C1077.46 499.675 1086.73 518.326 1078.17 542.086L1064.99 541.373V525.692L1077.46 499.675Z"
      fill="#1E3861" />
    <path
      d="M1115.12 459.165C1115.12 459.165 1120.76 460.353 1118.56 451.562C1116.37 442.711 1112.27 449.483 1108.58 446.81C1104.84 444.137 1099.2 442.533 1097.65 445.919C1096.11 449.305 1095.93 447.642 1091.95 449.186C1088.03 450.73 1091.83 454.948 1092.37 458.036C1092.84 461.125 1089.75 470.154 1096.82 467.956C1096.82 467.956 1100.86 460.412 1101.99 453.997C1101.99 453.997 1113.04 454.057 1115.12 459.165Z"
      fill="#1E3861" />
    <path
      d="M1073.48 526.464L1072.17 537.512L1102.41 538.938C1102.41 538.938 1116.84 530.206 1119.63 538.582C1121.83 545.234 1110.72 549.808 1102.23 547.967C1102.23 547.967 1063.5 555.095 1055.54 549.036C1051.03 545.591 1056.49 521.772 1056.49 521.772L1073.48 526.464Z"
      fill="#FFF2E3" />
    <path
      d="M1071.04 486.251C1071.04 486.251 1058.27 495.636 1053.64 531.572C1053.64 531.572 1069.5 536.681 1075.91 532.166C1075.91 532.107 1083.58 496.171 1071.04 486.251Z"
      fill="#1E3861" />
    <path
      d="M1097.65 466.59C1099.07 466.59 1100.21 465.446 1100.21 464.036C1100.21 462.625 1099.07 461.481 1097.65 461.481C1096.24 461.481 1095.1 462.625 1095.1 464.036C1095.1 465.446 1096.24 466.59 1097.65 466.59Z"
      fill="#FFF2E3" />
    <path
      d="M1038.67 554.085L1032.85 652.568C1032.67 654.35 1035.4 654.885 1036 653.162L1064.33 554.679C1064.69 553.728 1063.92 552.719 1062.79 552.719H1040.28C1039.44 552.719 1038.73 553.313 1038.67 554.085Z"
      fill="#95A0A9" />
    <path
      d="M1222.69 554.085L1228.51 652.568C1228.69 654.35 1225.96 654.885 1225.36 653.162L1197.03 554.679C1196.67 553.728 1197.45 552.719 1198.57 552.719H1221.09C1221.92 552.719 1222.63 553.313 1222.69 554.085Z"
      fill="#95A0A9" />
    <path
      d="M1229.05 557.886H1029.17C1026.32 557.886 1024 555.569 1024 552.718C1024 549.867 1026.32 547.551 1029.17 547.551H1229.05C1230.47 547.551 1231.78 548.145 1232.67 549.036C1233.62 549.986 1234.15 551.233 1234.15 552.659C1234.21 555.569 1231.9 557.886 1229.05 557.886Z"
      fill="#B6C0CA" />
    <path
      d="M1247.7 532.047H1131.51L1138.7 450.255H1254.82L1247.7 532.047Z"
      fill="#1E3861" />
    <path
      d="M1251.62 532.047H1135.49L1142.62 450.255H1258.75L1251.62 532.047Z"
      fill="#122A4F" />
    <path
      d="M1199.64 547.61H1178.2L1184.79 497.655H1206.24L1199.64 547.61Z"
      fill="#434273" />
    <path d="M1210.63 542.858H1165.79V547.55H1210.63V542.858Z" fill="#1E3861" />
    <path
      d="M1098.19 539.294L1071.52 538.047L1071.99 533.948C1072.05 533.651 1072.29 533.414 1072.59 533.473C1072.89 533.533 1073.06 533.77 1073.06 534.067L1072.71 537.037L1098.19 538.285C1098.49 538.285 1098.72 538.522 1098.72 538.819C1098.72 539.057 1098.49 539.294 1098.19 539.294Z"
      fill="#FFF2E3" />
    <path
      d="M1105.97 476.331C1104.13 476.331 1102.05 475.44 1099.85 473.718C1099.62 473.539 1099.56 473.183 1099.73 472.945C1099.91 472.708 1100.27 472.648 1100.51 472.827C1103.24 474.965 1105.67 475.678 1107.69 474.846C1112.5 472.886 1114.05 463.204 1114.23 462.075C1114.29 461.66 1114.64 461.541 1114.82 461.6C1115.12 461.66 1115.3 461.897 1115.3 462.194V462.254C1115 464.214 1113.4 473.777 1108.11 475.915C1107.46 476.212 1106.74 476.331 1105.97 476.331Z"
      fill="#FFF2E3" />
    <path
      d="M732.155 625.754L727.684 674.543H729.901L738.558 620.531L732.155 625.754Z"
      fill="#FFF2E3" />
    <path
      d="M734.122 625.754L738.558 674.543H736.34L727.684 620.531L734.122 625.754Z"
      fill="#FFF2E3" />
    <path
      d="M764.276 625.754L768.711 674.543H766.494L757.837 620.531L764.276 625.754Z"
      fill="#FFF2E3" />
    <path
      d="M696.421 625.754L691.95 674.543H694.168L702.824 620.531L696.421 625.754Z"
      fill="#FFF2E3" />
    <path d="M765.743 610.373V625.754H696.421L693.595 610.373H765.743Z" fill="#FFF2E3" />
    <path
      d="M738.737 625.753H696.421L693.595 610.372L687.05 574.674C686.513 571.777 687.443 568.987 689.303 566.948C691.163 564.909 693.917 563.586 697.065 563.586H718.813C723.749 563.586 727.97 566.912 728.793 571.491L735.947 610.444L738.737 625.753Z"
      fill="#FFF2E3" />
    <path
      d="M745.032 573.065L747.751 584.046L763.811 579.182C763.811 579.182 778.012 576.642 777.189 583.08C776.509 588.303 766.172 584.69 766.172 584.69C766.172 584.69 746.713 596.816 742.314 593.275C735.804 588.052 734.838 576.57 734.838 576.57L745.032 573.065Z"
      fill="#FFF2E3" />
    <path
      d="M741.849 559.151C741.849 559.151 747.607 563.693 749.038 580.827C749.038 580.827 742.814 583.438 731.869 581.399L740.454 559.938L741.849 559.151Z"
      fill="#1E3861" />
    <path
      d="M756.764 591.486C756.764 592.845 752.723 593.454 749.002 593.382C745.783 593.31 742.814 592.667 742.814 591.486C742.814 588.947 745.926 586.908 749.789 586.908C753.652 586.908 756.764 588.947 756.764 591.486Z"
      fill="#FFF2E3" />
    <path
      d="M745.962 581.685C746.284 569.631 742.063 559.401 742.063 559.401L734.802 575.64L737.055 582.15L745.962 581.685Z"
      fill="#1E3861" />
    <path
      d="M760.735 665.672C760.735 665.672 762.702 669.857 768.64 670.144C774.578 670.465 773.218 674.615 773.218 674.615H757.623C757.623 674.615 756.371 670.036 758.338 666.388C760.306 662.739 760.735 665.672 760.735 665.672Z"
      fill="#C0C1C7" />
    <path
      d="M741.67 665.887C741.67 665.887 743.637 670.072 749.575 670.358C755.512 670.68 754.153 674.83 754.153 674.83H738.522C738.522 674.83 737.27 670.251 739.237 666.603C741.24 662.954 741.67 665.887 741.67 665.887Z"
      fill="#C0C1C7" />
    <path
      d="M737.878 599.642C737.878 599.642 770.428 603.684 770.107 614.45C769.785 625.217 764.026 669.213 764.026 669.213C764.026 669.213 760.413 670.143 757.158 669.213C757.158 669.213 751.006 636.484 755.763 620.853C755.763 620.853 717.06 628.15 707.081 614.45L712.231 596.53L737.878 599.642Z"
      fill="#FC7247" />
    <path
      d="M718.813 599.856C718.813 599.856 752.508 602.825 752.186 613.592C751.864 624.359 744.961 669.428 744.961 669.428C744.961 669.428 741.348 670.358 738.093 669.428C738.093 669.428 731.94 636.699 736.698 621.068C736.698 621.068 717.06 628.365 707.081 614.665L712.088 601.251L718.813 599.856Z"
      fill="#FC7247" />
    <path
      d="M743.172 579.932C743.065 581.113 742.958 582.293 742.85 583.474C742.421 587.73 741.956 591.736 741.598 594.705C741.205 597.817 740.954 599.856 740.954 599.856C740.954 599.856 721.138 603.898 707.081 614.665C707.081 614.665 705.686 611.159 704.684 603.898C704.684 603.862 704.684 603.826 704.684 603.755C704.648 603.433 704.613 603.111 704.541 602.789C704.362 601.251 704.148 599.534 704.04 597.674C703.861 595.242 703.718 592.595 703.683 589.626C703.683 589.34 703.683 589.089 703.683 588.803C703.683 587.158 703.683 585.405 703.718 583.581C703.718 583.509 703.718 583.438 703.718 583.366C703.897 576.856 704.505 569.273 705.757 560.581C706.902 552.891 717.812 551.782 725.109 551.889C729.294 551.961 733.407 553.105 737.02 555.216C737.056 555.216 737.091 555.251 737.091 555.251C739.381 556.575 740.883 557.827 741.992 559.293C743.923 561.797 743.53 576.033 743.172 579.932Z"
      fill="#1E3861" />
    <path
      d="M714.306 563.05L718.813 579.074C718.813 579.074 716.703 583.402 712.017 584.726L708.654 568.522L714.306 563.05Z"
      fill="#1E3861" />
    <path
      d="M713.984 571.849L716.81 583.939C716.81 583.939 733.765 583.653 737.592 583.796C740.525 583.903 749.468 581.435 749.825 587.373C750.147 592.631 738.701 591.2 738.701 591.2C738.701 591.2 710.443 593.918 708.69 591.558C703.718 584.869 703.396 574.066 703.396 574.066L713.984 571.849Z"
      fill="#FFF2E3" />
    <path
      d="M709.942 554.787C709.942 554.787 697.459 558.364 702.001 583.295C702.001 583.295 712.839 583.939 716.309 578.466L714.342 563.014L709.942 554.787Z"
      fill="#1E3861" />
    <path
      d="M726.325 556.718C721.674 556.647 722.39 550.959 722.39 550.959L723.213 543.841L723.356 542.661L729.937 544.628L730.009 545.844L730.259 551.532V551.675C730.223 551.675 731.261 556.826 726.325 556.718Z"
      fill="#FFF2E3" />
    <path
      d="M730.224 551.567C723.356 551.746 723.213 543.877 723.213 543.877L727.648 544.986C727.648 544.986 728.685 545.343 729.973 545.88L730.224 551.567Z"
      fill="#FFF2E3" />
    <path
      d="M731.654 533.003C731.654 533.003 734.945 547.418 728.435 548.098C722.14 548.742 721.997 542.267 721.997 542.267V533.611L725.323 532.216L731.654 533.003Z"
      fill="#FFF2E3" />
    <path
      d="M731.869 537.331C731.869 537.331 735.231 536.115 734.623 532.502C734.015 528.89 728.042 532.252 726.503 530.893C724.965 529.569 720.816 529.784 719.85 531.68C718.885 533.575 719.886 533.718 719.564 534.899C719.207 536.115 718.312 537.617 718.67 538.798C719.028 540.014 719.528 540.622 719.636 541.302C719.743 542.017 720.602 544.02 721.567 544.557C722.533 545.093 722.748 541.302 722.748 541.302C722.748 541.302 723.034 536.938 725.216 535.829C727.326 534.72 729.258 537.903 731.869 537.331Z"
      fill="#542844" />
    <path
      d="M723.678 541.588C723.678 542.446 722.962 543.161 722.104 543.161C721.245 543.161 720.53 542.446 720.53 541.588C720.53 540.729 721.245 540.014 722.104 540.014C722.962 540.014 723.678 540.729 723.678 541.588Z"
      fill="#FFF2E3" />
    <path d="M698.711 675.688H696.564V594.384H704.577L698.711 675.688Z" fill="#1E3861" />
    <path d="M828.268 675.688H830.414V594.384H822.402L828.268 675.688Z" fill="#1E3861" />
    <path d="M848.943 592.488H678V599.892H848.943V592.488Z" fill="#1F4558" />
    <path
      d="M805.733 584.547H753.402L756.908 548.634H809.238L805.733 584.547Z"
      fill="#FFF2E3" />
    <path
      d="M807.486 584.547H755.155L758.66 548.634H810.991L807.486 584.547Z"
      fill="#FFF2E3" />
    <path
      d="M786.596 592.416H774.327L777.582 563.908H789.851L786.596 592.416Z"
      fill="#FFF2E3" />
    <path d="M792.391 590.771H770.142V592.416H792.391V590.771Z" fill="#FFF2E3" />
    <path
      d="M797.179 137.677L804.557 169.663L806.564 178.162H809.693L807.638 169.29L800.167 136.929L797.179 137.677Z"
      fill="#1E3861" />
    <path
      d="M835.796 149.631H801.521C798.673 149.631 796.245 147.483 795.544 144.448L790.968 124.649C789.987 120.306 792.929 116.103 796.945 116.103H831.22C834.068 116.103 836.497 118.251 837.197 121.287L841.773 141.086C842.801 145.428 839.812 149.631 835.796 149.631Z"
      fill="#C0C1C7" />
    <path
      d="M793.35 229.107C792.509 228.967 791.902 228.174 792.042 227.333L803.576 170.504L806.611 170.924L795.031 227.8C794.984 228.22 794.75 228.547 794.424 228.781C794.19 229.061 793.77 229.154 793.35 229.107Z"
      fill="#1E3861" />
    <path
      d="M824.075 229.107C823.235 228.967 822.628 228.174 822.768 227.333L834.302 170.504L837.337 170.924L825.757 227.8C825.663 228.267 825.43 228.594 825.056 228.827C824.823 229.061 824.449 229.154 824.075 229.107Z"
      fill="#1E3861" />
    <path
      d="M841.026 229.107C841.447 229.061 841.82 228.827 842.054 228.5C842.287 228.174 842.381 227.753 842.334 227.333L830.8 170.504L827.765 170.924L839.345 227.8C839.392 228.64 840.186 229.201 841.026 229.107Z"
      fill="#1E3861" />
    <path
      d="M871.706 229.107C872.219 229.014 872.593 228.734 872.826 228.314C872.966 228.033 873.06 227.66 873.013 227.333L861.479 170.504L858.444 170.924L870.024 227.8C870.118 228.64 870.865 229.201 871.706 229.107Z"
      fill="#1E3861" />
    <path
      d="M801.942 173.726C801.942 176.201 803.95 178.162 806.378 178.162H869.371C870.585 178.162 871.706 177.648 872.499 176.855C873.293 176.061 873.807 174.94 873.807 173.726C873.807 171.251 871.799 169.29 869.371 169.29H806.378C805.724 169.29 805.117 169.43 804.557 169.663C804.043 169.897 803.623 170.177 803.249 170.597C802.455 171.391 801.942 172.512 801.942 173.726Z"
      fill="#C0C1C7" />
    <path
      d="M895.754 216.453C895.754 216.453 897.809 220.562 905.093 221.636C912.378 222.71 911.21 228.78 911.21 228.78H887.676C887.676 228.78 886.835 223.457 887.676 217.713C888.469 212.016 895.754 216.453 895.754 216.453Z"
      fill="#C0C1C7" />
    <path
      d="M882.212 211.036C882.212 211.036 880.904 214.351 885.341 220.282C889.73 226.165 884.407 230.695 884.407 230.695L867.736 214.024C867.736 214.024 870.912 209.682 875.534 206.226C880.204 202.771 882.212 211.036 882.212 211.036Z"
      fill="#C0C1C7" />
    <path
      d="M854.008 140.198C854.008 140.198 891.972 142.626 896.641 148.65C905.28 159.764 882.913 212.624 882.913 212.624C882.913 212.624 875.815 213.464 873.947 206.6L883.286 159.904L842.24 145.708L854.008 140.198Z"
      fill="#1E3861" />
    <path
      d="M897.342 217.153C897.342 217.153 895.988 218.554 893.139 219.021C892.812 219.068 892.485 219.114 892.112 219.114C890.711 219.208 889.03 219.068 887.022 218.461C887.022 218.461 870.585 185.306 870.678 171.251C870.678 171.251 822.955 176.434 819.313 153.273L831.454 150.658L832.761 150.378L854.054 145.802C854.054 145.802 881.138 151.639 884.64 154.814C888.189 157.942 897.342 217.153 897.342 217.153Z"
      fill="#1E3861" />
    <path
      d="M856.202 89.3463C856.202 89.3463 853.821 95.0432 847.33 95.6969C840.793 96.304 841.446 91.5877 844.342 89.4397C847.237 87.2916 850.739 84.3498 851.766 79.4934C852.887 74.3568 857.977 70.341 863.113 74.9639C863.113 74.9639 869.931 74.5436 869.651 81.7815C869.371 89.0194 868.483 91.3542 871.098 94.4828C873.76 97.6115 873.76 106.997 867.503 105.596C861.246 104.196 859.284 95.7903 859.284 95.7903L861.853 84.63C861.806 84.63 863.207 86.4044 856.202 89.3463Z"
      fill="#1E3861" />
    <path
      d="M891.598 219.208C891.551 219.068 889.216 205.806 886.228 192.311C880.998 168.776 878.336 164.106 877.169 163.406C868.39 158.129 835.236 152.012 834.909 151.965L835.096 150.938C836.45 151.171 868.764 157.149 877.683 162.519C878.523 163.032 879.924 164.667 882.399 172.885C883.846 177.695 885.434 184.139 887.208 192.077C890.197 205.572 892.579 218.881 892.579 219.021L891.598 219.208Z"
      fill="#1E3861" />
    <path
      d="M870.305 111.48L872.733 114.375C872.733 114.375 866.195 96.0237 865.028 92.5215C863.861 89.0193 865.822 82.3884 869.931 83.6025C874.04 84.8166 873.013 89.3928 870.491 91.0739C870.491 91.0739 884.594 118.298 881.231 125.069C878.523 130.579 864.047 120.913 863.767 120.119C862.32 115.636 870.305 111.48 870.305 111.48Z"
      fill="#FFF2E3" />
    <path
      d="M872.733 112.928C872.733 112.928 871.005 121.24 864.328 121.193C863.487 121.193 862.553 121.053 861.526 120.726L858.164 109.612L856.763 104.896L858.911 92.7551C858.864 92.7551 869.137 98.2186 872.733 112.928Z"
      fill="#FFF2E3" />
    <path
      d="M864.421 120.866C864.374 121.006 864.374 121.099 864.328 121.192C863.487 121.192 862.553 121.052 861.526 120.819L858.164 111.433L861.012 101.72C860.965 101.72 866.709 110.499 864.421 120.866Z"
      fill="#FFF2E3" />
    <path
      d="M858.864 92.7547C858.864 92.7547 870.445 102.934 854.101 145.708C854.101 145.708 834.162 161.305 817.491 153.88C817.445 153.927 816.744 83.8825 858.864 92.7547Z"
      fill="#FFD05C" />
    <path
      d="M859.938 93.642L859.845 93.9689C859.845 93.9689 860.779 96.1636 860.218 97.0975C859.658 98.0314 856.062 97.7979 854.475 95.6032C852.887 93.4085 853.868 92.101 853.868 92.101L855.455 87.0112L855.502 86.8244L855.876 85.657L861.339 87.9451L861.292 88.1319L859.938 93.642Z"
      fill="#FFF2E3" />
    <path
      d="M861.339 88.1321L859.938 93.6889C854.988 91.7744 855.362 87.8987 855.502 87.0581C855.502 86.9647 855.549 86.8713 855.549 86.8713L861.339 88.1321Z"
      fill="#FFF2E3" />
    <path
      d="M835.002 104.335C835.002 104.335 836.917 113.068 837.851 121.706L840.513 121.333C840.513 121.333 845.602 130.439 852.14 130.952C860.732 131.699 835.002 104.335 835.002 104.335Z"
      fill="#FFF2E3" />
    <path
      d="M863.954 77.6252C863.954 77.6252 867.503 87.5247 864.328 90.3265C861.152 93.1283 855.035 89.5793 855.502 86.4507C855.969 83.3221 855.689 78.6992 855.689 78.6992L860.919 76.4578L863.954 77.6252Z"
      fill="#FFF2E3" />
    <path
      d="M848.451 99.9463L854.942 113.348L853.401 116.337L847.33 103.915L848.451 99.9463Z"
      fill="#FFF2E3" />
    <path
      d="M840.559 115.216C840.559 115.216 846.816 130.812 852.186 130.999C861.339 131.279 879.784 131.559 879.784 131.559C879.784 131.559 891.504 133.707 891.504 128.617C891.504 121.846 879.69 126.002 879.69 126.002L857.837 123.481L852 112.134L840.559 115.216Z"
      fill="#FFF2E3" />
    <path
      d="M861.246 78.1857C861.246 78.1857 862.039 83.8359 855.502 86.8711L853.587 80.8007L858.164 75.991L861.246 78.1857Z"
      fill="#1E3861" />
    <path
      d="M861.245 78.1857C861.245 78.1857 864.841 79.2597 865.682 83.0888L866.709 77.7654L865.308 76.6914L863.814 76.5046H858.117L861.245 78.1857Z"
      fill="#1E3861" />
    <path
      d="M848.451 99.9462L853.774 113.581C853.774 113.581 848.824 119.558 839.906 119.232L835.002 104.336C835.002 104.336 840.793 96.0704 848.451 99.9462Z"
      fill="#FFD05C" />
    <path
      d="M861.152 91.6808C858.817 91.6808 857.557 90.4667 857.463 90.3733C857.323 90.2333 857.323 89.9998 857.463 89.8597C857.603 89.7196 857.837 89.7196 857.977 89.8597C858.07 89.9531 859.938 91.7275 863.394 90.5134C866.756 89.346 864.701 81.5945 864.701 81.5011C864.654 81.3143 864.748 81.0808 864.981 81.0341C865.168 80.9874 865.402 81.0808 865.448 81.3143C865.542 81.6412 867.69 89.813 863.674 91.2139C862.693 91.5874 861.853 91.6808 861.152 91.6808Z"
      fill="#FFF2E3" />
    <path
      d="M879.083 126.376H879.037L857.51 123.901L854.428 117.877C854.288 117.644 854.381 117.364 854.615 117.224C854.848 117.083 855.128 117.177 855.268 117.41L858.117 122.967L879.13 125.395C879.41 125.442 879.597 125.676 879.55 125.909C879.55 126.236 879.317 126.376 879.083 126.376Z"
      fill="#FFF2E3" />
    <path
      d="M943.291 133.848L957.393 228.22C957.486 229.014 956.879 229.668 956.085 229.668C955.805 229.668 955.525 229.575 955.291 229.434C955.011 229.248 954.824 228.921 954.778 228.594L938.014 133.848H943.291Z"
      fill="#1E3861" />
    <path
      d="M828.698 133.848L814.596 228.22C814.503 229.014 815.11 229.668 815.904 229.668C816.184 229.668 816.464 229.575 816.697 229.434C816.978 229.248 817.164 228.921 817.211 228.594L833.975 133.848H828.698Z"
      fill="#1E3861" />
    <path
      d="M961.876 136.696H810.113C808.526 136.696 807.218 135.435 807.218 133.847C807.218 132.26 808.526 130.999 810.113 130.999H961.876C963.463 130.999 964.771 132.26 964.771 133.847C964.771 134.641 964.444 135.342 963.93 135.855C963.416 136.369 962.669 136.696 961.876 136.696Z"
      fill="#1E3861" />
    <path
      d="M939.648 120.119H882.959L887.162 86.0774H943.851L939.648 120.119Z"
      fill="#1E3861" />
    <path
      d="M942.45 120.119H885.761L889.917 86.0774H946.606L942.45 120.119Z"
      fill="#1E3861" />
    <path
      d="M918.075 129.411H906.307L909.809 103.215H921.53L918.075 129.411Z"
      fill="#C0C1C7" />
    <path
      d="M899.817 129.224H924.425C924.846 129.224 925.266 129.364 925.546 129.598C926.013 129.925 926.293 130.485 926.293 131.092H897.949C897.949 130.112 898.789 129.224 899.817 129.224Z"
      fill="#1E3861" />
    <path
      d="M895.1 207.16C895.1 207.02 893.279 194.645 890.991 182.084C886.928 159.343 884.92 155.794 884.267 155.281C880.251 152.339 859.238 147.576 859.004 147.529L859.284 146.222C860.172 146.408 880.764 151.078 885.014 154.207C885.714 154.72 886.788 156.308 888.609 163.919C889.683 168.402 890.898 174.426 892.252 181.804C894.493 194.412 896.314 206.786 896.361 206.927L895.1 207.16Z"
      fill="#FFF2E3" />
    <path
      d="M797.179 137.677L804.557 169.663L806.564 178.162H809.693L807.638 169.29L800.167 136.929L797.179 137.677Z"
      fill="#1E3861" />
    <path
      d="M835.796 149.631H801.521C798.673 149.631 796.245 147.483 795.544 144.448L790.968 124.649C789.987 120.306 792.929 116.103 796.945 116.103H831.22C834.068 116.103 836.497 118.251 837.197 121.287L841.773 141.086C842.801 145.428 839.812 149.631 835.796 149.631Z"
      fill="#C0C1C7" />
    <path
      d="M793.35 229.107C792.509 228.967 791.902 228.174 792.042 227.333L803.576 170.504L806.611 170.924L795.031 227.8C794.984 228.22 794.75 228.547 794.424 228.781C794.19 229.061 793.77 229.154 793.35 229.107Z"
      fill="#1E3861" />
    <path
      d="M824.075 229.107C823.235 228.967 822.628 228.174 822.768 227.333L834.302 170.504L837.337 170.924L825.757 227.8C825.663 228.267 825.43 228.594 825.056 228.827C824.823 229.061 824.449 229.154 824.075 229.107Z"
      fill="#1E3861" />
    <path
      d="M841.026 229.107C841.447 229.061 841.82 228.827 842.054 228.5C842.287 228.174 842.381 227.753 842.334 227.333L830.8 170.504L827.765 170.924L839.345 227.8C839.392 228.64 840.186 229.201 841.026 229.107Z"
      fill="#1E3861" />
    <path
      d="M871.706 229.107C872.219 229.014 872.593 228.734 872.826 228.314C872.966 228.033 873.06 227.66 873.013 227.333L861.479 170.504L858.444 170.924L870.024 227.8C870.118 228.64 870.865 229.201 871.706 229.107Z"
      fill="#1E3861" />
    <path
      d="M801.942 173.726C801.942 176.201 803.95 178.162 806.378 178.162H869.371C870.585 178.162 871.706 177.648 872.499 176.855C873.293 176.061 873.807 174.94 873.807 173.726C873.807 171.251 871.799 169.29 869.371 169.29H806.378C805.724 169.29 805.117 169.43 804.557 169.663C804.043 169.897 803.623 170.177 803.249 170.597C802.455 171.391 801.942 172.512 801.942 173.726Z"
      fill="#C0C1C7" />
    <path
      d="M895.754 216.453C895.754 216.453 897.809 220.562 905.093 221.636C912.378 222.71 911.21 228.78 911.21 228.78H887.676C887.676 228.78 886.835 223.457 887.676 217.713C888.469 212.016 895.754 216.453 895.754 216.453Z"
      fill="#C0C1C7" />
    <path
      d="M882.212 211.036C882.212 211.036 880.904 214.351 885.341 220.282C889.73 226.165 884.407 230.695 884.407 230.695L867.736 214.024C867.736 214.024 870.912 209.682 875.534 206.226C880.204 202.771 882.212 211.036 882.212 211.036Z"
      fill="#C0C1C7" />
    <path
      d="M854.008 140.198C854.008 140.198 891.972 142.626 896.641 148.65C905.28 159.764 882.913 212.624 882.913 212.624C882.913 212.624 875.815 213.464 873.947 206.6L883.286 159.904L842.24 145.708L854.008 140.198Z"
      fill="#1E3861" />
    <path
      d="M897.342 217.153C897.342 217.153 895.988 218.554 893.139 219.021C892.812 219.068 892.485 219.114 892.112 219.114C890.711 219.208 889.03 219.068 887.022 218.461C887.022 218.461 870.585 185.306 870.678 171.251C870.678 171.251 822.955 176.434 819.313 153.273L831.454 150.658L832.761 150.378L854.054 145.802C854.054 145.802 881.138 151.639 884.64 154.814C888.189 157.942 897.342 217.153 897.342 217.153Z"
      fill="#1E3861" />
    <path
      d="M856.202 89.3463C856.202 89.3463 853.821 95.0432 847.33 95.6969C840.793 96.304 841.446 91.5877 844.342 89.4397C847.237 87.2916 850.739 84.3498 851.766 79.4934C852.887 74.3568 857.977 70.341 863.113 74.9639C863.113 74.9639 869.931 74.5436 869.651 81.7815C869.371 89.0194 868.483 91.3542 871.098 94.4828C873.76 97.6115 873.76 106.997 867.503 105.596C861.246 104.196 859.284 95.7903 859.284 95.7903L861.853 84.63C861.806 84.63 863.207 86.4044 856.202 89.3463Z"
      fill="#1E3861" />
    <path
      d="M891.598 219.208C891.551 219.068 889.216 205.806 886.228 192.311C880.998 168.776 878.336 164.106 877.169 163.406C868.39 158.129 835.236 152.012 834.909 151.965L835.096 150.938C836.45 151.171 868.764 157.149 877.683 162.519C878.523 163.032 879.924 164.667 882.399 172.885C883.846 177.695 885.434 184.139 887.208 192.077C890.197 205.572 892.579 218.881 892.579 219.021L891.598 219.208Z"
      fill="#1E3861" />
    <path
      d="M870.305 111.48L872.733 114.375C872.733 114.375 866.195 96.0237 865.028 92.5215C863.861 89.0193 865.822 82.3884 869.931 83.6025C874.04 84.8166 873.013 89.3928 870.491 91.0739C870.491 91.0739 884.594 118.298 881.231 125.069C878.523 130.579 864.047 120.913 863.767 120.119C862.32 115.636 870.305 111.48 870.305 111.48Z"
      fill="#FFF2E3" />
    <path
      d="M872.733 112.928C872.733 112.928 871.005 121.24 864.328 121.193C863.487 121.193 862.553 121.053 861.526 120.726L858.164 109.612L856.763 104.896L858.911 92.7551C858.864 92.7551 869.137 98.2186 872.733 112.928Z"
      fill="#FFF2E3" />
    <path
      d="M864.421 120.866C864.374 121.006 864.374 121.099 864.328 121.192C863.487 121.192 862.553 121.052 861.526 120.819L858.164 111.433L861.012 101.72C860.965 101.72 866.709 110.499 864.421 120.866Z"
      fill="#FFF2E3" />
    <path
      d="M858.864 92.7547C858.864 92.7547 870.445 102.934 854.101 145.708C854.101 145.708 834.162 161.305 817.491 153.88C817.445 153.927 816.744 83.8825 858.864 92.7547Z"
      fill="#FFD05C" />
    <path
      d="M859.938 93.642L859.845 93.9689C859.845 93.9689 860.779 96.1636 860.218 97.0975C859.658 98.0314 856.062 97.7979 854.475 95.6032C852.887 93.4085 853.868 92.101 853.868 92.101L855.455 87.0112L855.502 86.8244L855.876 85.657L861.339 87.9451L861.292 88.1319L859.938 93.642Z"
      fill="#FFF2E3" />
    <path
      d="M861.339 88.1321L859.938 93.6889C854.988 91.7744 855.362 87.8987 855.502 87.0581C855.502 86.9647 855.549 86.8713 855.549 86.8713L861.339 88.1321Z"
      fill="#FFF2E3" />
    <path
      d="M835.002 104.335C835.002 104.335 836.917 113.068 837.851 121.706L840.513 121.333C840.513 121.333 845.602 130.439 852.14 130.952C860.732 131.699 835.002 104.335 835.002 104.335Z"
      fill="#FFF2E3" />
    <path
      d="M863.954 77.6252C863.954 77.6252 867.503 87.5247 864.328 90.3265C861.152 93.1283 855.035 89.5793 855.502 86.4507C855.969 83.3221 855.689 78.6992 855.689 78.6992L860.919 76.4578L863.954 77.6252Z"
      fill="#FFF2E3" />
    <path
      d="M848.451 99.9463L854.942 113.348L853.401 116.337L847.33 103.915L848.451 99.9463Z"
      fill="#FFF2E3" />
    <path
      d="M840.559 115.216C840.559 115.216 846.816 130.812 852.186 130.999C861.339 131.279 879.784 131.559 879.784 131.559C879.784 131.559 891.504 133.707 891.504 128.617C891.504 121.846 879.69 126.002 879.69 126.002L857.837 123.481L852 112.134L840.559 115.216Z"
      fill="#FFF2E3" />
    <path
      d="M861.246 78.1857C861.246 78.1857 862.039 83.8359 855.502 86.8711L853.587 80.8007L858.164 75.991L861.246 78.1857Z"
      fill="#1E3861" />
    <path
      d="M861.245 78.1857C861.245 78.1857 864.841 79.2597 865.682 83.0888L866.709 77.7654L865.308 76.6914L863.814 76.5046H858.117L861.245 78.1857Z"
      fill="#1E3861" />
    <path
      d="M848.451 99.9462L853.774 113.581C853.774 113.581 848.824 119.558 839.906 119.232L835.002 104.336C835.002 104.336 840.793 96.0704 848.451 99.9462Z"
      fill="#FFD05C" />
    <path
      d="M861.152 91.6808C858.817 91.6808 857.557 90.4667 857.463 90.3733C857.323 90.2333 857.323 89.9998 857.463 89.8597C857.603 89.7196 857.837 89.7196 857.977 89.8597C858.07 89.9531 859.938 91.7275 863.394 90.5134C866.756 89.346 864.701 81.5945 864.701 81.5011C864.654 81.3143 864.748 81.0808 864.981 81.0341C865.168 80.9874 865.402 81.0808 865.448 81.3143C865.542 81.6412 867.69 89.813 863.674 91.2139C862.693 91.5874 861.853 91.6808 861.152 91.6808Z"
      fill="#FFF2E3" />
    <path
      d="M879.083 126.376H879.037L857.51 123.901L854.428 117.877C854.288 117.644 854.381 117.364 854.615 117.224C854.848 117.083 855.128 117.177 855.268 117.41L858.117 122.967L879.13 125.395C879.41 125.442 879.597 125.676 879.55 125.909C879.55 126.236 879.317 126.376 879.083 126.376Z"
      fill="#FFF2E3" />
    <path
      d="M943.291 133.848L957.393 228.22C957.486 229.014 956.879 229.668 956.085 229.668C955.805 229.668 955.525 229.575 955.291 229.434C955.011 229.248 954.824 228.921 954.778 228.594L938.014 133.848H943.291Z"
      fill="#1E3861" />
    <path
      d="M828.698 133.848L814.596 228.22C814.503 229.014 815.11 229.668 815.904 229.668C816.184 229.668 816.464 229.575 816.697 229.434C816.978 229.248 817.164 228.921 817.211 228.594L833.975 133.848H828.698Z"
      fill="#1E3861" />
    <path
      d="M961.876 136.696H810.113C808.526 136.696 807.218 135.435 807.218 133.847C807.218 132.26 808.526 130.999 810.113 130.999H961.876C963.463 130.999 964.771 132.26 964.771 133.847C964.771 134.641 964.444 135.342 963.93 135.855C963.416 136.369 962.669 136.696 961.876 136.696Z"
      fill="#1E3861" />
    <path
      d="M939.648 120.119H882.959L887.162 86.0774H943.851L939.648 120.119Z"
      fill="#1E3861" />
    <path
      d="M942.45 120.119H885.761L889.917 86.0774H946.606L942.45 120.119Z"
      fill="#1E3861" />
    <path
      d="M918.075 129.411H906.307L909.809 103.215H921.53L918.075 129.411Z"
      fill="#C0C1C7" />
    <path
      d="M899.817 129.224H924.425C924.846 129.224 925.266 129.364 925.546 129.598C926.013 129.925 926.293 130.485 926.293 131.092H897.949C897.949 130.112 898.789 129.224 899.817 129.224Z"
      fill="#1E3861" />
    <path
      d="M895.1 207.16C895.1 207.02 893.279 194.645 890.991 182.084C886.928 159.343 884.92 155.794 884.267 155.281C880.251 152.339 859.238 147.576 859.004 147.529L859.284 146.222C860.172 146.408 880.764 151.078 885.014 154.207C885.714 154.72 886.788 156.308 888.609 163.919C889.683 168.402 890.898 174.426 892.252 181.804C894.493 194.412 896.314 206.786 896.361 206.927L895.1 207.16Z"
      fill="#FFF2E3" />
    <path
      d="M69.8099 868.598C197.394 961.277 339.541 618.847 472.421 739.209C638.52 889.662 355.67 983.544 472.421 804.205C589.172 624.865 359.882 576.651 525.982 514.063C692.081 451.475 849.756 204.801 1005.62 295.675C1026.09 307.604 1051.36 394.371 959.887 370.901C868.412 347.43 723.375 233.62 822.072 137.33C901.03 60.2984 1257.18 -22.2818 1330 -19.6739"
      stroke="white"
      stroke-width="2"
      stroke-dasharray="2 6" />
    <path
      d="M729.756 598.587C729.555 663.381 770.799 795.619 937.38 806.211C1145.61 819.451 1204.58 842.921 1207.59 720.754C1210.6 598.586 1231.06 546.831 1280.41 546.831C1329.76 546.831 1430.86 626.872 1345.41 624.464C1259.95 622.057 1142.6 582.338 1059.55 520.351C993.108 470.762 1014.41 584.545 986.729 520.352"
      stroke="white"
      stroke-width="2"
      stroke-dasharray="2 6" />
    <path
      d="M219.059 161.471C243.733 193.166 302.47 259.446 340.023 271C386.964 285.444 449.552 295.073 477.837 271C506.122 246.928 530.203 216.64 489.271 202.996C442.33 187.349 461.588 555.055 451.959 394.371"
      stroke="white"
      stroke-width="2"
      stroke-dasharray="2 6" />
    <path
      d="M80.0378 790.295H53.9894V789.449C53.9894 787.805 55.3342 786.46 56.9778 786.46H76.9996C78.6432 786.46 79.988 787.805 79.988 789.449V790.295H80.0378Z"
      fill="#B6C0CA" />
    <path
      d="M54.1389 764.297C54.1389 766.04 52.7443 767.435 51.0011 767.435C49.2579 767.435 47.8633 766.04 47.8633 764.297C47.8633 762.554 49.2579 761.159 51.0011 761.159C52.7443 761.159 54.1389 762.554 54.1389 764.297Z"
      fill="#B6C0CA" />
    <path
      d="M64.6478 788.901L66.391 787.855L52.296 764.546L71.1226 750.052C71.8697 749.505 72.7661 749.206 73.7125 749.206C74.858 749.256 75.9537 749.754 76.651 750.65L78.2448 749.355C77.149 748.01 75.5054 747.213 73.7622 747.164C72.3677 747.114 70.9732 747.562 69.8774 748.409L49.6065 763.998L64.6478 788.901Z"
      fill="#B6C0CA" />
    <path
      d="M90.9451 750.401L71.1723 762.106C70.7241 761.01 70.4252 759.815 70.2758 758.569C69.7279 752.194 74.4595 746.566 80.8346 746.018C84.8689 745.67 88.6043 747.413 90.9451 750.401Z"
      fill="#B6C0CA" />
    <path d="M127.253 836.167H131.387V890.654H127.253V836.167Z" fill="#1E3861" />
    <path d="M123.667 879.747H135.023V890.256H123.667V879.747Z" fill="#1E3861" />
    <path
      d="M112.013 898.922H146.628C146.628 893.792 142.494 889.658 137.364 889.658H121.277C116.147 889.658 112.013 893.792 112.013 898.922Z"
      fill="#1E3861" />
    <path
      d="M129.345 851.756C132.483 851.756 135.023 849.216 135.023 846.078V838.657H123.667V846.078C123.667 849.166 126.208 851.756 129.345 851.756Z"
      fill="#1E3861" />
    <path
      d="M159.058 810.868L163.186 811.075L161.797 838.682L157.669 838.474L159.058 810.868Z"
      fill="#1E3861" />
    <path
      d="M160.623 821.723C164.608 821.922 167.994 818.834 168.194 814.85L171.232 749.654C171.431 745.67 168.343 742.283 164.359 742.084C160.374 741.885 156.987 744.973 156.788 748.957L153.75 814.153C153.551 818.187 156.639 821.524 160.623 821.723Z"
      fill="#1E3861" />
    <path
      d="M96.0751 840.848H162.566C165.155 840.848 167.247 838.756 167.247 836.167C167.247 833.577 165.155 831.485 162.566 831.485H96.0751C93.4852 831.485 91.3933 833.577 91.3933 836.167C91.3933 838.756 93.4852 840.848 96.0751 840.848Z"
      fill="#1E3861" />
    <path
      d="M69.3793 875.065C69.3793 875.065 67.6859 878.252 60.1652 878.8C53.7403 879.298 51.6983 880.593 52.4453 886.072H79.5894C79.5894 886.072 81.482 878.352 79.5894 873.322C77.7466 868.242 69.3793 875.065 69.3793 875.065Z"
      fill="#C0C1C7" />
    <path
      d="M122.173 802.797C122.173 802.797 78.5435 808.325 73.1147 817.041C68.5824 824.313 68.433 874.268 68.433 874.268C68.433 874.268 73.3637 879.647 80.9342 876.957C80.9342 876.957 91.2439 854.346 88.3054 831.037C88.3054 831.037 141.448 838.059 145.233 813.605L121.376 806.433L122.173 802.797Z"
      fill="#C0C1C7" />
    <path
      d="M75.2065 875.065C75.2065 875.065 73.5131 878.252 65.9925 878.8C59.5675 879.298 57.5255 880.593 58.2726 886.072H85.4167C85.4167 886.072 87.3093 878.352 85.4167 873.322C83.524 868.242 75.2065 875.065 75.2065 875.065Z"
      fill="#C0C1C7" />
    <path
      d="M118.338 804.242C118.338 804.242 83.3746 809.62 78.9917 816.992C74.559 824.363 74.3101 874.218 74.3101 874.218C74.3101 874.218 78.7427 879.597 86.363 876.908C86.363 876.908 91.0447 856.886 95.4276 831.485C95.4276 831.485 147.325 838.01 151.11 813.555L125.709 801.751L118.338 804.242Z"
      fill="#C0C1C7" />
    <path
      d="M95.1288 762.504C95.1288 762.504 86.2634 756.279 83.4743 761.309C80.9342 765.991 88.3553 768.879 95.0292 769.377L97.4697 765.194L95.1288 762.504Z"
      fill="#FFF2E3" />
    <path
      d="M94.4814 781.729C94.4814 781.729 83.8727 779.488 83.3249 785.216C82.8268 790.545 90.7957 790.246 97.121 788.055L97.7187 783.224L94.4814 781.729Z"
      fill="#FFF2E3" />
    <path
      d="M136.667 739.444L119.185 762.405L94.5312 762.006C94.5312 762.006 91.0946 764.197 92.7382 769.178C92.7382 769.178 116.097 778.691 122.97 777.147C129.794 775.603 143.191 762.753 143.191 762.753C143.191 762.753 146.727 751.646 136.667 739.444Z"
      fill="#FC7247" />
    <path
      d="M117.94 794.728C117.99 797.717 118.139 800.904 118.338 804.241C118.338 804.241 131.337 812.858 151.11 813.555C151.11 813.555 164.11 767.933 147.624 743.279C146.379 741.436 144.138 740.54 141.996 741.038L132.732 743.13C132.732 743.13 120.43 746.815 118.239 779.936C117.94 784.369 117.79 789.25 117.94 794.728Z"
      fill="#FC7247" />
    <path
      d="M117.94 794.729C123.369 794.181 129.196 793.284 131.437 790.943C140.153 781.929 145.333 754.386 145.333 754.386L129.295 777.297L118.189 779.986C117.94 784.369 117.79 789.25 117.94 794.729Z"
      fill="#FC7247" />
    <path
      d="M140.452 748.16C144.885 750.152 146.777 755.481 144.536 759.864C139.306 770.174 130.989 785.415 127.104 787.357C120.53 790.644 94.4814 789.299 94.4814 789.299C91.5428 784.717 94.4814 781.58 94.4814 781.58L119.534 773.362L129.046 752.443C131.088 748.11 136.169 746.218 140.452 748.16Z"
      fill="#FC7247" />
    <path
      d="M74.1606 855.193L75.2065 855.143C75.2065 854.794 73.9116 821.076 81.7809 815.298C87.1599 811.364 104.343 807.429 104.492 807.379L104.243 806.383C103.546 806.533 86.6618 810.418 81.1334 814.502C72.8657 820.578 74.1108 853.798 74.1606 855.193Z"
      fill="#FFF2E3" />
    <path
      d="M133.429 734.464L133.629 738.199L133.828 742.034L133.878 743.279C133.878 743.279 140.751 744.823 143.042 741.187C143.042 741.187 141.697 739.145 141.348 735.261C141.299 734.663 141.249 733.966 141.249 733.268L133.429 734.464Z"
      fill="#FFF2E3" />
    <path
      d="M133.579 738.199L133.778 742.034C140.203 741.486 141.299 735.41 141.348 735.261L139.456 734.016L133.579 738.199Z"
      fill="#FFF2E3" />
    <path
      d="M129.345 734.663C129.495 735.659 129.744 736.605 130.341 737.303C131.985 739.145 135.272 738.548 137.314 737.9C139.506 737.203 141.747 735.709 142.245 733.318C143.241 728.686 143.39 723.407 143.39 723.407L137.065 721.614L130.292 722.759C129.794 725.25 129.594 727.79 129.395 730.33C129.345 731.525 129.196 733.169 129.345 734.663Z"
      fill="#FFF2E3" />
    <path
      d="M139.755 729.931C139.755 729.931 139.207 724.951 136.219 724.951C133.131 724.951 127.154 727.79 127.104 722.311C127.104 719.621 129.296 715.637 131.537 715.338C132.981 715.139 133.629 716.982 134.625 717.679C136.268 718.825 138.261 718.725 140.153 718.874C144.237 719.173 146.927 723.158 146.429 727.092C146.03 730.38 141.896 734.563 141.847 734.613L139.755 729.931Z"
      fill="#1E3861" />
    <path
      d="M140.552 731.973C139.561 731.973 138.759 731.17 138.759 730.18C138.759 729.19 139.561 728.387 140.552 728.387C141.542 728.387 142.345 729.19 142.345 730.18C142.345 731.17 141.542 731.973 140.552 731.973Z"
      fill="#FFF2E3" />
    <path
      d="M97.0712 898.972L101.006 899.072L83.5241 796.273L71.9692 798.315L97.0712 898.972Z"
      fill="#1E3861" />
    <path
      d="M3.98449 898.972L3.62396e-05 899.072L17.4818 796.273L29.0367 798.315L3.98449 898.972Z"
      fill="#1E3861" />
    <path
      d="M3.78529 798.464H97.1708C99.0136 798.464 100.558 796.97 100.558 795.077V793.683C100.558 791.84 99.0635 790.296 97.1708 790.296H3.78529C1.94247 790.296 0.398537 791.79 0.398537 793.683V795.077C0.448341 796.97 1.94247 798.464 3.78529 798.464Z"
      fill="#FFD05C" />
    <path
      d="M31.2282 785.763H35.5115V770.423C35.5115 766.439 36.0593 762.504 37.155 758.669C37.8523 756.328 46.2196 742.433 51.3496 734.264L47.7138 731.973C45.4228 735.609 34.0671 753.838 33.0212 757.474C31.8258 761.658 31.2282 766.04 31.2282 770.374V785.763Z"
      fill="#1E3861" />
    <path
      d="M16.3363 790.296H56.0812C56.0812 787.805 54.0392 785.763 51.5489 785.763H20.8686C19.6235 785.763 18.4779 786.261 17.681 787.108C16.8343 787.905 16.3363 789.051 16.3363 790.296Z"
      fill="#1E3861" />
    <path
      d="M59.966 781.131L60.3644 781.081C62.7053 780.882 64.4983 778.79 64.2492 776.4L56.9278 694.37C56.7286 692.029 54.6368 690.236 52.2461 690.485L51.8476 690.535C49.5068 690.734 47.7138 692.826 47.9628 695.217L55.2842 777.246C55.4834 779.587 57.5753 781.331 59.966 781.131Z"
      fill="#1E3861" />
    <path
      d="M119.633 789.549C119.683 789.549 119.683 789.549 119.733 789.549C119.882 789.549 123.717 789.051 128.1 787.357C132.483 785.664 141.299 767.684 142.295 765.642C142.444 765.343 142.295 764.945 141.996 764.795C141.697 764.646 141.299 764.795 141.149 765.094C138.51 770.523 130.989 784.867 127.652 786.162C123.418 787.805 119.633 788.303 119.583 788.303C119.235 788.353 118.986 788.652 119.036 789.001C119.036 789.349 119.334 789.549 119.633 789.549Z"
      fill="#FC7247" />
    <path
      d="M93.3358 843.289C93.535 843.289 93.6844 843.14 93.7342 842.99L95.7265 831.933C101.305 832.979 112.262 832.631 112.71 832.631C112.909 832.631 113.109 832.431 113.059 832.232C113.059 832.033 112.86 831.834 112.66 831.884C112.561 831.884 100.807 832.282 95.4774 831.136L95.079 831.037L92.9373 842.841C92.8875 843.04 93.037 843.239 93.2362 843.289C93.286 843.289 93.3358 843.289 93.3358 843.289Z"
      fill="#1E3861" />
    <path
      d="M133.778 738.797C136.567 738.797 139.456 737.253 139.655 737.153C139.755 737.103 139.804 736.904 139.755 736.805C139.705 736.705 139.506 736.655 139.406 736.705C139.356 736.755 133.429 739.942 130.59 737.153C128.748 735.31 129.843 728.537 129.893 728.487C129.893 728.338 129.843 728.238 129.694 728.188C129.544 728.188 129.445 728.238 129.395 728.387C129.345 728.686 128.2 735.51 130.242 737.502C131.188 738.448 132.483 738.797 133.778 738.797Z"
      fill="#FFF2E3" />
  </svg>
</template>

<style scoped></style>
