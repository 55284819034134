<template>
  <div>
    <v-fade-transition hide-on-leave>
      <div v-if="routeContracts" class="contracts-wrapper">
        <Filters v-model:filters="filters" class="ml-2" />
        <div v-show="!loading" class="pt-5">
          <v-data-table
            :headers="dkpListHeaders"
            :items="dkpContractList"
            class="calc-table --cust-cell-pd"
            mobileBreakpoint="750"
            dense
            :items-per-page="20"
            hide-default-footer
            :hide-default-header="!dkpContractList.length">
            <!-- eslint-disable-next-line -->
            <template #item.provider="{ item }">
              <ALink
                v-if="item.provider"
                :text="item.provider.name"
                :route="{
                  name: 'contractors-form',
                  params: { id: item.provider.id },
                }">
              </ALink>
              <span v-else>---</span>
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.number="{ item }">
              <ALink
                :text="item.number"
                :route="{ name: 'dkp-contract', params: { id: item.id } }">
              </ALink>
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.amount="{ item }">
              {{ `${formutSum(item.amount)} грн` }}
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.date="{ item }">
              {{ humanDate(formatDate(item.date, true)) }}
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.actions="{ item }">
              <TableCrud
                :actionsArray="[
                  {
                    action: () => pushToDkpContract(item),
                    text: 'Відкрити',
                    icon: 'mdi-folder-open-outline',
                  },
                ]">
              </TableCrud>
            </template>
          </v-data-table>
          <Pagination :getItems="getDKPList" :trigger="paginationTrigger" />
        </div>
      </div>
    </v-fade-transition>
    <SectionLoader v-if="loading"> </SectionLoader>
    <v-fade-transition hide-on-leave>
      <router-view v-show="!routeContracts && !loading"> </router-view>
    </v-fade-transition>
  </div>
</template>
<script>
import TableCrud from '@/components/table-crud.vue'
import SectionLoader from '@/components/section-loader.vue'
import ALink from '@/components/Link.vue'
import Filters from '@/components/Filters.vue'
import Pagination from '@/components/Pagination.vue'
import { backDate, toFormatDate } from '@/utils/helperFunc'
import { formatDate, humanDate, formutSum } from '@/utils/formatFunc'
import { urlGetDkpContracts } from '@/pages/request'
import { useSelect } from '@/utils/mixins/useSelect'
import { useFilter } from '@/utils/mixins/useFilter'
import { reactive } from 'vue'
import { dkpListHeaders } from '@/utils/tableHeaders'
export default {
  components: {
    TableCrud,
    SectionLoader,
    ALink,
    Filters,
    Pagination,
  },
  setup() {
    const filters = reactive({
      statusId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { items: 'dkpStatuses', label: 'Статус' },
      },
      startDate: {
        value: null,
        component: 'DatePicker',
        attrs: { label: 'Період', range: true },
      },
      search: { value: null },
    })
    return {
      ...useSelect(),
      ...useFilter(filters),
      filters,
    }
  },
  data() {
    return {
      dkpContractList: [],
      loading: false,
      dkpListHeaders,
    }
  },
  computed: {
    routeContracts() {
      return this.$route.name === 'dkp-contracts'
    },
  },
  methods: {
    backDate,
    formatDate,
    humanDate,
    formutSum,
    toFormatDate,
    pushToDkpContract(item) {
      this.$router.push({ name: 'dkp-contract', params: { id: item.id } })
    },
    getDKPList(page = 1) {
      this.loading = true
      const filters = this.filtersQueryObj

      if (filters.sort) filters.sort = `${filters.sort},${filters.direction}`
      delete filters.direction
      return this.$axios.get(urlGetDkpContracts(page, filters)).then(res => {
        this.dkpContractList = res.data.data
        this.loading = false
        return res
      })
    },
  },
}
</script>
