<template>
  <div class="d-flex show-empty-msg" :style="`height: ${height}`">
    <div class="msg-logo">
      <component :is="icon" :width="iconWidth" :height="iconHeight">
      </component>
    </div>
    <div class="msg-title">
      {{ title }}
    </div>
    <div class="msg-text">
      {{ message }}
    </div>
    <div class="msg-action mt-3">
      <v-btn
        v-if="!hideAction"
        class="text-white"
        style="background: rgb(252, 114, 71); border-radius: 4px"
        variant="text"
        size="small"
        @click="$emit('action', true)">
        {{ actionText }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import activity from '@/assets/svg/activity.vue'
export default {
  components: { activity },
  emits: ['action'],
  props: {
    height: { type: Number, default: 400 },
    icon: { type: Object, default: () => activity },
    message: { type: String, default: '' },
    actionText: { type: String, default: 'Додати данi' },
    iconHeight: { type: Number, default: 46 },
    iconWidth: { type: Number, default: 46 },
    hideAction: { type: Boolean, default: false },
    title: {
      type: String,
      default: 'В даному роздiлi не було зафiксовано вашої активностi',
    },
  },
}
</script>
<style lang="scss" scoped>
.show-empty-msg {
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0.6;
  text-align: center;
  min-height: 290px;
  .msg-logo {
    width: 75px;
    height: 75px;
    margin: 13px auto;
    background: grey;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
  }
  .msg-title {
    font-size: 0.9rem;
  }
  .msg-text {
    font-size: 0.7rem;
  }
}
</style>
