/** Vuex helpers */
import axios from 'axios'

/** @type {import('./modules/type-defs').makeRequest}  */
function makeRequest(url, config) {
  switch (config.method.toUpperCase()) {
    case 'DELETE':
    case 'UPDATE':
    case 'POST':
      return axios[config.method](url, config.payload)
        .then(res => {
          if (typeof config.cb === 'function') {
            res.data = res.data.map(config.cb)
            return res
          } else return res
        })
        .catch(err => console.log(err))

    case 'GET':
      return axios[config.method](url)
        .then(res => {
          if (typeof config.cb === 'function') {
            res.data.map(config.cb)
            return res
          } else return res
        })
        .catch(err => console.log(err))
  }
}

function getItems(url, config) {
  return axios
    .get(url, config)
    .then(res => res.data)
    .catch(err => console.log(err))
}

function clear(o, source) {
  const target = source || o
  Object.keys(o).forEach(prop => {
    delete target[prop]
  })
}

export { makeRequest, getItems, clear }
