<template>
  <div>
    <SectionLoader v-if="asyncLoading('getApplication')" />
    <v-row v-else>
      <v-col :cols="12" :md="3" class="pr-0">
        <v-card
          :elevation="0"
          class="mb-3"
          style="
            background: rgb(255, 255, 255) !important;
            border-radius: 4px !important;
          ">
          <v-card-text>
            <div>
              <span class="subtitle">Назва</span>
              <span class="text-desc d-block mb-4">{{
                application.serviceApplicationName
              }}</span>
            </div>
            <div>
              <span class="subtitle">Джерело</span>
              <span class="text-desc d-block mb-4">{{
                application.source.name
              }}</span>
            </div>
            <div>
              <span class="subtitle">Тип</span>
              <v-select
                v-model="application.typeId"
                :items="
                  $directory.get('serviceApplicationTypes', application.type)
                "
                item-title="name"
                item-value="id"
                :loading="$loading.isLoading('serviceApplicationTypes')"
                @focus="$directory.fill('serviceApplicationTypes')">
                <template
                  v-if="$loading.isLoading('serviceApplicationTypes')"
                  #no-data>
                  <loader></loader>
                </template>
              </v-select>
            </div>
            <div>
              <ChangeResponsible
                v-model:responsibleId="application.responsibleId"
                :read-only="application.readOnly"
                :responsible="application.responsible" />
            </div>
            <div>
              <span class="subtitle">Дата створення</span>
              <span class="text-desc d-block mb-4">{{
                toFormatDate(application.created_at)
              }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="12" :md="9" class="pt-0">
        <div>
          <StatusBar
            v-if="editApplication"
            :statuses="$directory.get('serviceApplicationStatuses')"
            :currentStatus="application.statusId"
            :submit="setStatus"
            spread-items
            size="small" />
          <v-tabs
            slider-color="#FC7247"
            class="application-tabs mb-5"
            show-arrows
            :height="38">
            <v-tab
              @click="
                $router.replace({ name: 'service-application-general-info' })
              ">
              Загальна інформація
            </v-tab>
            <v-tab
              @click="
                $router.replace({ name: 'service-application-leasing-object' })
              ">
              Об'єкт лізингу
            </v-tab>
            <v-tab
              v-if="application.duplicates.length"
              @click="$router.replace({ name: 'service-application-history' })">
              Історія
            </v-tab>
          </v-tabs>
          <RouterView
            v-model:application="application"
            :cache="cache"
            :v="v$" />
          <div>
            <div class="d-flex align-center mt-5">
              <CommentIcon class="mr-2" />
              <span class="projectTitle">Коментар</span>
            </div>
            <Comments
              :comments="application.comments"
              :label="false"
              :entityId="applicationId"
              :entityTypeId="8" />
          </div>
          <ActionButtons
            class="mt-5"
            :confirm="updateApplication"
            :cancel="() => setApplication(cache)"
            :cancelDisable="!hasChange"
            :confirmDisable="!hasChange"
            :confirmLoading="asyncLoading('updateApplication')" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import StatusBar from '@/components/StatusBar.vue'
import SectionLoader from '@/components/section-loader.vue'
import ActionButtons from '@/components/action-buttons.vue'
import Comments from '@/components/comments/Index.vue'
import { minLength, email } from '@vuelidate/validators'
import {
  getContactInitials,
  setCache,
  toFormatDate,
  getResponsibleShortName,
} from '@/utils/helperFunc'
import {
  urlGetServiceApplicationSingle,
  urlUpdateServiceApplication,
  urlServiceApplicationSetStatus,
} from '@/pages/request'
import { mapGetters, mapState } from 'vuex'
import ChangeResponsible from '@/components/ChangeResponsible.vue'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
import CommentIcon from '@/assets/svg/comment-icon.vue'
import { fillDirectory } from '@/plugins/directory/index.ts'

export default {
  components: {
    CommentIcon,
    ChangeResponsible,
    Loader,
    StatusBar,
    SectionLoader,
    ActionButtons,
    Comments,
  },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  validations() {
    return {
      application: {
        phone: { minLength: minLength(23) },
        email: { email },
      },
    }
  },
  data() {
    return {
      application: null,
      cache: null,
    }
  },
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
      user: state => state.user,
    }),
    ...mapGetters({
      activeUsers: 'activeUsers',
    }),
    applicationId() {
      return +this.$route.params.id
    },
    createApplication() {
      return this.$route.name === 'service-application-create'
    },
    editApplication() {
      return [
        'service-application-general-info',
        'service-application-leasing-object',
        'service-application-history',
      ].includes(this.$route.name)
    },
    hasChange() {
      return setCache([this.cache]) !== setCache([this.application])
    },
  },
  methods: {
    getContactInitials,
    getResponsibleShortName,
    toFormatDate,
    setApplication(obj) {
      this.application = Object.clone(obj)
      this.cache = Object.clone(obj)
    },
    getApplication() {
      this.asyncAction('getApplication', null, () => {
        return this.$axios
          .get(urlGetServiceApplicationSingle(this.applicationId))
          .then(res => this.setApplication(res.data))
      })
    },
    updateApplication() {
      if (this.v$.$invalid) return
      this.asyncAction('updateApplication', null, () => {
        return this.$axios
          .post(
            urlUpdateServiceApplication(this.applicationId),
            this.application
          )
          .then(res => {
            this.setApplication(res.data)
            this.$store.commit('refreshPage')
            this.$setSnackbar({ text: 'Данi оновлено' })
          })
      })
    },
    setStatus(statusId) {
      this.setApplication({ ...this.application, statusId })
      return this.$axios.post(
        urlServiceApplicationSetStatus(this.applicationId),
        {
          statusId,
        }
      )
    },
  },
  created() {
    this.getApplication()
    this.editApplication && fillDirectory('serviceApplicationStatuses')
  },
}
</script>

<style lang="scss" scoped>
.v-tab {
  padding: 0 !important;
  margin-right: 25px;
}
.v-tabs.application-tabs .v-tab,
.v-tabs.application-tabs .v-tab-item--selected {
  background: transparent !important;
  text-transform: uppercase !important;
}
.v-tab-item--selected.v-tab {
  color: #08487a !important;
  font-weight: 700 !important;
  font-size: 14px;
}
.v-tab {
  color: #838282 !important;
}
.text-desc {
  font-weight: 400;
  font-size: 13px;
  color: #1c1d21;
  display: block;
}

.subtitle {
  color: #838282;
  font-weight: 500;
  font-size: 13px;
  display: block;
  margin-bottom: 5px;
}
.projectTitle {
  display: block;
  color: #08487a;
  font-weight: 600;
  font-size: 16px;
}
</style>
