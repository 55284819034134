const breadScrumb = {
  state: () => ({
    customState: false,
    state: false,
    links: [],
    customLinks: [],
  }),
  mutations: {
    setBreadScrumb(state, breadScrumb) {
      state.links = breadScrumb.links
      state.state = breadScrumb.state
    },
    setBreadScrumbPart(state, val) {
      state.state = false
      state.customLinks.splice(0)
      state.customLinks.push(...val)
      state.state = true
      state.customState = false
    },
    setCustomBreadScrumbState(state, val) {
      state.customState = val
    },
  },
  actions: {
    //...
  },
}

export { breadScrumb }
