<template>
  <v-card-text>
    <div v-show="routeTicketList">
      <SectionLoader v-if="loading"></SectionLoader>
      <div v-if="!loading">
        <v-row class="d-flex justify-end">
          <v-col :cols="12" :md="4" class="pt-0 pb-0 d-flex">
            <v-text-field
              v-if="tickets.length"
              v-model="string"
              style="display: flex; flex: 1 1 auto"
              class="pt-0 mt-0"
              prepend-inner-icon="mdi-magnify"
              label="Пошук"
              hide-details>
            </v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :headers="tHead"
          :items="tickets"
          class="calc-table --cust-cell-pd"
          mobileBreakpoint="750"
          :search="string"
          dense
          :hide-default-footer="!tickets.length"
          :hide-default-header="!tickets.length">
          <!-- eslint-disable-next-line -->
          <template #item.title="{ item }">
            <ALink
              :text="item.title"
              :route="{ name: 'admin-ticket', params: { id: item.id } }">
            </ALink>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.actions="{ item }">
            <TableCrud
              :actionsArray="[
                {
                  action: () =>
                    $router.push({
                      name: 'admin-ticket',
                      params: { id: item.id },
                    }),
                  text: 'Відкрити',
                  icon: 'mdi-folder-open-outline',
                },
              ]">
            </TableCrud>
          </template>
        </v-data-table>
      </div>
    </div>
    <RouterView v-show="!routeTicketList" @data-changed="getAdminTickets">
    </RouterView>
  </v-card-text>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'

import ALink from '@/components/Link.vue'
import { urlGetAdminTickets } from '@/pages/request'
import TableCrud from '@/components/table-crud.vue'
export default {
  components: { TableCrud, SectionLoader, ALink },
  data: () => ({
    tickets: [],
    loading: false,
    tHead: [
      // { text: 'Тiкет', value: 'theme.name', align: 'start', sortable: false },
      { title: 'id', key: 'id', align: 'center', sortable: false },
      {
        title: 'Статус',
        key: 'status.name',
        align: 'start',
        sortable: false,
      },
      { title: 'Назва', key: 'title', align: 'start', sortable: false },
      { title: 'Дата', key: 'created_at', sortable: false, align: 'center' },
      { title: 'Дії', key: 'actions', align: 'end', sortable: false },
    ],
    string: '',
  }),
  computed: {
    routeTicketList() {
      return this.$route.name === 'administration-tickets'
    },
  },
  methods: {
    getAdminTickets() {
      this.loading = true
      return this.$axios
        .get(urlGetAdminTickets())
        .then(res => {
          this.tickets.splice(0)
          this.tickets.push(...res.data)
          this.loading = false
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
  },
  created() {
    this.getAdminTickets()
  },
}
</script>
