<template>
  <div>
    <list-section :title="'Перевiрка СБ'" :mt="0">
      <template #body>
        <v-row v-if="contractorProvider">
          <v-col :cols="12" :sm="9" class="pt-0 pb-0">
            <v-text-field
              :model-value="
                getSafe(
                  () =>
                    contractorData.provider_security_result_latest
                      .provider_result.name,
                  '---'
                )
              "
              label="Результат останньої перевірки"
              readonly
              disabled>
            </v-text-field>
          </v-col>
          <v-col :cols="12" :sm="3" class="pt-0 pb-0">
            <v-text-field
              :model-value="
                getSafe(
                  () =>
                    contractorData.provider_security_result_latest
                      .providerFinishDate,
                  '---'
                )
              "
              label="Дата останньої перевірки"
              readonly
              disabled>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="contractorClient">
          <v-col :cols="12" :sm="9" class="pt-0 pb-0">
            <v-text-field
              :model-value="
                getSafe(
                  () =>
                    contractorData.client_security_result_latest.client_result
                      .name,
                  '---'
                )
              "
              label="Результат останньої перевірки"
              readonly
              disabled>
            </v-text-field>
          </v-col>
          <v-col :cols="12" :sm="3" class="pt-0 pb-0">
            <v-text-field
              :model-value="
                getSafe(
                  () =>
                    contractorData.client_security_result_latest
                      .clientFinishDate,
                  '---'
                )
              "
              label="Дата останньої перевірки"
              readonly
              disabled>
            </v-text-field>
          </v-col>
        </v-row>
      </template>
    </list-section>
    <list-section :title="'Юридичний висновок'" :mt="0">
      <template #body>
        <v-row v-if="contractorClient">
          <v-col :cols="12" :sm="9" class="pt-0 pb-0">
            <v-text-field
              :model-value="
                getSafe(
                  () =>
                    contractorData.client_legal_result_latest.clientConclusion,
                  '---'
                )
              "
              label="Результат останньої перевірки"
              readonly
              disabled>
            </v-text-field>
          </v-col>
          <v-col :cols="12" :sm="3" class="pt-0 pb-0">
            <v-text-field
              :model-value="
                getSafe(
                  () => contractorData.client_legal_result_latest.finishDate,
                  '---'
                )
              "
              label="Дата останньої перевірки"
              readonly
              disabled>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="contractorProvider">
          <v-col :cols="12" :sm="9" class="pt-0 pb-0">
            <v-text-field
              :model-value="
                getSafe(
                  () =>
                    contractorData.provider_legal_result_latest
                      .providerConclusion,
                  '---'
                )
              "
              label="Результат останньої перевірки"
              readonly
              disabled>
            </v-text-field>
          </v-col>
          <v-col :cols="12" :sm="3" class="pt-0 pb-0">
            <v-text-field
              :model-value="
                getSafe(
                  () => contractorData.provider_legal_result_latest.finishDate,
                  '---'
                )
              "
              label="Дата останньої перевірки"
              readonly
              disabled>
            </v-text-field>
          </v-col>
        </v-row>
      </template>
    </list-section>
  </div>
</template>
<script>
import listSection from '@/components/list-section.vue'
import { getSafe } from '@/utils/helperFunc'
export default {
  components: { listSection },
  props: { contractor: { type: Object } },
  computed: {
    contractorData() {
      return this.contractor
    },
    contractorClient() {
      return this.$array(() => this.contractor.contractorTypeId).includes(2)
    },
    contractorProvider() {
      return this.$array(() => this.contractor.contractorTypeId).includes(1)
    },
  },
  methods: { getSafe },
}
</script>
