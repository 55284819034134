<template>
  <v-row>
    <v-col cols="12" md="12" sm="12">
      <span class="label">Назва</span>
      <v-text-field
        v-model="faqPresentation.name"
        placeholder="Введіть текст"
        :error-messages="nameErr"
        hide-details
        :readonly="readonly"
        @blur="v$.faqPresentation.name.$touch()">
      </v-text-field>
    </v-col>
    <v-col cols="12" md="12" sm="12">
      <FilesUploader
        v-model:documents="faqPresentation.document"
        label="Документ"
        single
        :readonly="readonly"
        :error-messages="documentErr"
        @blur="v$.faqPresentation.document.$touch()">
      </FilesUploader>
    </v-col>
  </v-row>
</template>

<script>
import FilesUploader from '@/components/FilesUploader.vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { setErrHandler } from '@/utils/helperFunc'
import {
  urlFaqCreatePresentation,
  urlFaqUpdatePresentation,
} from '@/pages/request'

export default {
  components: { FilesUploader },
  props: {
    dialog: { type: Object },
  },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      faqPresentation: {
        name: { required },
        document: { required },
      },
    }
  },
  data: () => {
    return {
      faqPresentation: {},
    }
  },
  computed: {
    validation() {
      return { v$: this.v$.faqPresentation, name: 'faqPresentation' }
    },
    nameErr() {
      return setErrHandler(this.v$?.faqPresentation?.name)
    },
    documentErr() {
      return setErrHandler(this.v$?.faqPresentation?.name)
    },
    isCreate() {
      return this.dialog.params?.action === 'create'
    },
    readonly() {
      return this.dialog.params?.readonly
    },
  },
  methods: {
    submit() {
      const url = this.isCreate
        ? urlFaqCreatePresentation()
        : urlFaqUpdatePresentation(this.faqPresentation?.id)
      return this.$axios
        .post(url, {
          name: this.faqPresentation?.name,
          document: this.faqPresentation.document[0].url,
        })
        .then(res => {
          this.$setSnackbar({
            text: this.isCreate ? 'Презентацію додано' : 'Презентацію оновлено',
          })
          return res
        })
    },
  },
  created() {
    if (!this.isCreate) {
      this.faqPresentation = {
        id: this.dialog?.dialogItem?.id,
        name: this.dialog?.dialogItem?.name,
        document: [{ url: this.dialog?.dialogItem?.document }],
      }
    }
  },
}
</script>

<style scoped></style>
