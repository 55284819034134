<template>
  <v-card-text
    v-if="choosedLegal || choosedPerson"
    class="entity-wpapper pt-2 pb-4">
    <!-- legal -->
    <v-fade-transition hide-on-leave>
      <div v-if="choosedLegal">
        <v-row class="mt-3">
          <div class="projectBlock">
            <div
              class="projectRow"
              :class="{ 'flex-column align-start': $vuetify.breakpoint?.xs }">
              <span class="projectTitle">Юридична особа:</span>
              <div style="margin-left: 5px">
                <ALink
                  v-if="project.lessee.contractorId"
                  style="font-size: 16px"
                  :text="project.lessee.contractor.shortName"
                  :route="getEntityRoute(project.lessee.contractorId, 2)" />
                <span v-else class="projectName">{{
                  project.companyName
                }}</span>
              </div>
            </div>
            <div class="d-flex align-center">
              <v-checkbox
                v-model="project.isClientAgraria"
                style="margin-top: 0; padding-top: 0"
                class="mr-5"
                label="Клієнт-Аграрій"
                hide-details
                dense
                color="red"
                :disabled="!!project.readOnly"
                @update:model-value="event => !event">
              </v-checkbox>
              <v-checkbox
                v-model="project.isNewCompany"
                style="margin-top: 0; padding-top: 0"
                label="Новостворена компанія"
                hide-details
                dense
                color="red"
                :disabled="!!project.readOnly">
              </v-checkbox>
            </div>
          </div>
        </v-row>
        <v-row
          v-if="project.lessee && project.lessee?.contractor"
          class="pl-3 mt-2">
          <div class="text-field" style="margin-bottom: 12px !important">
            <span class="text-title">Повна назва компанії:</span>
            <span class="text-desc">{{
              getSafe(() => project.lessee.contractor.companyFullName) ||
              'Не вказано'
            }}</span>
          </div>
        </v-row>
        <div v-if="project.lessee && project.lessee?.contractor">
          <v-row class="pl-3 mt-2">
            <div class="text-field" style="margin-bottom: 12px !important">
              <span class="text-title">Юридична адреса:</span>
              <span class="text-desc">{{
                getSafe(() =>
                  getAddress(
                    project.lessee.contractor.addresses.find(
                      a => a.type.id === 1
                    )
                  )
                ) || 'Не вказано'
              }}</span>
            </div>
          </v-row>
          <v-row class="pl-3 mt-2">
            <div class="text-field" style="margin-bottom: 12px !important">
              <span class="text-title">Фактична адреса:</span>
              <span class="text-desc">{{
                getSafe(() =>
                  getAddress(
                    project.lessee.contractor.addresses.find(
                      a => a.type.id === 2
                    )
                  )
                ) || 'Не вказано'
              }}</span>
            </div>
          </v-row>
          <v-row class="pl-3 mt-2">
            <div class="text-field" style="margin-bottom: 12px !important">
              <span class="text-title">Вид діяльності:</span>
              <span class="text-desc">{{
                getSafe(() => project.lessee.contractor.main_qued.title) ||
                'Не вказано'
              }}</span>
            </div>
          </v-row>
        </div>
        <div class="d-flex align-center">
          <div
            v-if="project.lessee && project.lessee.contractor"
            class="d-flex flex-column mr-10">
            <v-row class="mb-5 mt-5" style="margin-bottom: 10px !important">
              <div class="projectRow">
                <DateRegisterIcon />
                <span class="card-block-title">Дата реєстрації:</span>
              </div>
            </v-row>
            <span>{{
              getSafe(() =>
                toFormatDate(
                  project.lessee.contractor.stateRegistrationDate,
                  true
                )
              ) || 'Не вказано'
            }}</span>
          </div>
          <div class="d-flex flex-column">
            <v-row class="mb-5 mt-5" style="margin-bottom: 10px !important">
              <div class="projectRow">
                <EdrpoIcon />
                <span class="card-block-title">ЄДРПОУ:</span>
              </div>
            </v-row>
            <span>{{ project?.application?.edrpou || 'Не вказано' }}</span>
          </div>
        </div>
      </div>
    </v-fade-transition>
    <!-- person -->
    <v-fade-transition hide-on-leave>
      <div v-if="choosedPerson">
        <v-row class="mt-3">
          <div class="projectBlock">
            <div
              class="projectRow"
              :class="{ 'flex-column align-start': $vuetify.breakpoint?.xs }">
              <span class="projectTitle" style="margin-right: 5px"
                >{{ fop ? 'ФОП' : 'Фiзична особа' }}:</span
              >
              <div style="margin-left: 5px">
                <ALink
                  v-if="project.lessee.contactId"
                  style="font-size: 16px"
                  :text="project.lessee.contact.fullName"
                  :route="getEntityRoute(project.lessee.contactId, 1)">
                </ALink>
                <span v-else class="projectName">{{ project.fio }}</span>
              </div>
            </div>
            <div v-if="fop">
              <v-checkbox
                v-model="project.isClientAgraria"
                label="Клієнт-Аграрій"
                hide-details
                dense
                color="red"
                :disabled="!!project.readOnly"
                @update:model-value="event => !event">
              </v-checkbox>
            </div>
          </div>
        </v-row>
        <div class="text-field mt-4" style="margin-bottom: 12px !important">
          <span class="text-title">IПН:</span>
          <span class="text-desc">{{ getInn }}</span>
        </div>
        <div v-if="fop">
          <v-row class="pl-3">
            <div class="text-field" style="margin-bottom: 12px !important">
              <span class="text-title">Вид діяльності:</span>
              <span class="text-desc">{{
                getSafe(() => project.lessee.contact.main_qued.title) ||
                'Не вказано'
              }}</span>
            </div>
          </v-row>
        </div>
        <v-row class="mb-5 mt-5">
          <div class="projectRow">
            <ContactIcon />
            <span class="card-block-title">Контактні дані:</span>
          </div>
        </v-row>
        <div class="mb-3">
          <div>
            <span class="subtitle">Адреса реєстрації:</span>
            <div class="d-flex align-center">
              <AdressIcon />
              <span class="text-desc ml-2">{{
                getSafe(
                  () =>
                    getAddress(
                      project?.lessee?.contact?.addresses.find(
                        a => a.type.id === 4
                      )
                    ),
                  'Не вказано'
                )
              }}</span>
            </div>
          </div>
        </div>
        <v-row>
          <v-col :cols="12" :md="4" class="mr-4">
            <span class="subtitle">Основний телефон:</span>
            <div class="d-flex align-center">
              <PhoneIcon />
              <span class="ml-2">{{
                getSafe(() =>
                  getContacts(project.lessee.contact.phones, 'phone')
                ) ||
                getSafe(() => getContacts(application.phones, 'phone')) ||
                'Відсутній'
              }}</span>
            </div>
          </v-col>
          <v-col :cols="12" :md="4">
            <span class="subtitle">Email:</span>
            <div class="d-flex align-center">
              <EmailIcon />
              <span class="ml-2">{{
                getSafe(() =>
                  getContacts(project.lessee.contact.emails, 'email')
                ) ||
                getSafe(() => getContacts(application.emails, 'email')) ||
                'Відсутня'
              }}</span>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-fade-transition>

    <v-row v-if="choosedLegal || fop">
      <v-col cols="12" md="4" sm="12">
        <div>
          <span class="label">Регіон ведення підприємницької діяльності</span>
          <v-autocomplete
            v-model="project.business_regions"
            :items="filteredItems"
            multiple
            small-chips
            return-object
            item-value="id"
            item-title="name"
            :error-messages="isBusinessRegions"
            placeholder="Оберіть зі списку"
            :loading="$loading.isLoading('businessRegions')"
            @blur="v.project.business_regions.$touch()"
            @focus="$directory.fill('businessRegions')">
            <!--            <template #item="{ item, props }">-->
            <!--              <v-list-item v-bind="props" :disabled="isItemDisabled(item)">-->
            <!--                <v-list-item-title>{{ item.name }}</v-list-item-title>-->
            <!--              </v-list-item>-->
            <!--            </template>-->
          </v-autocomplete>
        </div>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import EmailIcon from '@/assets/svg/email-icon.vue'
import PhoneIcon from '@/assets/svg/phone-icon.vue'
import EdrpoIcon from '@/assets/svg/edrpo-icon.vue'
import DateRegisterIcon from '@/assets/svg/date-register.vue'
import ContactIcon from '@/assets/svg/contact-icon.vue'
import AdressIcon from '@/assets/svg/adress-icon.vue'
import ALink from '@/components/Link.vue'
import { mapState, mapGetters } from 'vuex'
import {
  toFormatDate,
  getBeautyNum,
  getAddress,
  getEntityRoute,
  setErrHandler,
  getSafe,
} from '@/utils/helperFunc'
import { useSelect } from '@/utils/mixins/useSelect'
import { useAdditionalDocs } from '@/utils/mixins/useAdditionalDocs'

export default {
  components: {
    ALink,
    AdressIcon,
    ContactIcon,
    DateRegisterIcon,
    EdrpoIcon,
    PhoneIcon,
    EmailIcon,
  },
  setup() {
    return {
      ...useSelect(),
      ...useAdditionalDocs(),
    }
  },
  props: {
    projectData: { type: Object },
    v: { type: Object },
    choosedLegal: { type: Boolean, default: false },
    choosedPerson: { type: Boolean, default: false },
    fop: { type: Boolean, default: false },
    isPerson: { type: Boolean },
  },
  data() {
    return {
      generalObject: 'project',

      contactLegalDocs: {
        missingMarriageCertificate: {
          show: false,
          label: 'Свідоцтво про укладення/розірвання шлюбу',
        },
      },
      dialog: false,
    }
  },
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    ...mapGetters({
      activeUsers: 'activeUsers',
    }),
    application() {
      return this.project.application
    },
    project() {
      return this.projectData
    },
    isBusinessRegions() {
      return setErrHandler(this.v.project.isAttorneyAbroad)
    },
    getInn() {
      if (this.project.lessee?.contact) {
        return this.project.lessee?.contact?.inn || '---'
      } else return this.project.lessee?.contractor?.inn || '---'
    },
    standartLeasing() {
      return this.project.analysisTypeId === 1
    },
    notStandartLeasing() {
      return this.project.analysisTypeId === 2
    },
    isFastLeasing() {
      return this.project.analysisTypeId === 3
    },
    filteredItems() {
      const items = this.$directory.get(
        'businessRegions',
        this.project.business_regions
      )

      return items.map(item => ({
        ...item,
        props: {
          disabled: this.isItemDisabled(item),
        },
      }))
    },
  },
  methods: {
    getSafe,
    getAddress,
    toFormatDate,
    getBeautyNum,
    getEntityRoute,
    openDialog() {
      if (this.project.isModelCharter) return (this.dialog = true)
      if (!this.project.isModelCharter) return (this.dialog = false)
    },
    getContacts(contacts, type) {
      return contacts.map(c => c[type]).join(', ')
    },
    isItemDisabled(item) {
      const hasItemWithIdOne = this.project.business_regions.some(
        selectedItem => selectedItem.id === 1
      )

      if (hasItemWithIdOne) {
        if (item.id !== 1) {
          if (this.project.business_regions.length > 1) {
            this.project.business_regions =
              this.project.business_regions.filter(
                selectedItem => selectedItem.id === 1
              )
          }
          return true
        }
      }

      return false
    },
  },
  watch: {
    'project.analysisTypeId': {
      handler() {
        if (this.isFastLeasing) {
          this.project.isNewCompany = false
        } else if (this.standartLeasing || this.notStandartLeasing) {
          // contact
        }
      },
      immediate: true,
    },
    'project.isNewCompany': {
      handler(val) {
        if (val) {
          if (this.project.analysisTypeId === 3) {
            this.project.analysisTypeId = 1
          }
        }
      },
    },
  },
}
</script>

<style scoped>
.title-label {
  font-weight: 600;
  font-size: 15px;
  color: #1c1d21;
  margin-top: 5px;
}

.data-card {
  display: flex;
  flex-direction: column;
}

.v-dialog {
  width: 540px !important;
}

.projectRow {
  display: flex;
  align-items: center;
  padding: 0 12px;
}

.projectBlock {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
}

.projectTitle {
  display: block;
  color: #08487a;
  font-weight: 600;
  font-size: 16px;
}

.projectName {
  display: block;
  text-transform: uppercase;
  color: #08487a;
  font-size: 16px;
}

.card-block-title {
  display: block;
  color: #08487a;
  font-weight: 600;
  font-size: 16px;
  margin-left: 9px;
}

.doc-modal-statut {
  background: #f2f5fa;
  border: 1px solid #c0c1c7;
  box-shadow: 0 2px 8px rgb(0 0 0 / 4%);
  border-radius: 4px;
  padding: 10px;
  display: flex;
  font-weight: 400;
  font-size: 14px;
  width: 540px;
}

.text-field {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.text-title {
  color: #1c1d21;
  font-weight: 600;
  font-size: 17px;
  margin-right: 6px;
  display: block;
}
.text-desc {
  font-weight: 400;
  font-size: 16px;
  color: #1c1d21;
  display: block;
}

.subtitle {
  color: #838282;
  font-weight: 500;
  font-size: 13px;
  display: block;
  margin-bottom: 2px;
}
</style>
