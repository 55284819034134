<template>
  <div style="position: relative">
    <div class="white-block">
      <v-tabs
        show-arrows
        dark
        dense
        :height="38"
        class="generalTab"
        slider-color="#fc7247"
        style="background: #fff !important">
        <v-tab
          replace
          :to="{ name: 'e-client', params: { id: $route.params.id } }">
          Клієнт
        </v-tab>
        <v-tab
          v-if="DocData.contract"
          replace
          :to="{ name: 'e-contract', params: { id: $route.params.id } }">
          Договір № {{ DocData.contract.number }}
        </v-tab>
        <v-tab
          replace
          :to="{ name: 'e-leasing-object', params: { id: $route.params.id } }">
          Обʼєкт лізингу
        </v-tab>
        <v-tab
          replace
          :to="{ name: 'e-analysis', params: { id: $route.params.id } }">
          Аналіз
        </v-tab>
      </v-tabs>
    </div>
    <SectionLoader v-if="loading" />

    <RouterView
      v-else
      :id="id"
      v-model:DocData="DocData"
      :loading="loading"
      :submitLoading="submitLoading"
      :acceptDocumentsLoading="acceptDocumentsLoading"
      :project="DocData.project"
      :documentsAccepted="documentsAccepted"
      @get="getFinancialAnalyzesById()"
      @accept-documents="acceptDocuments" />
  </div>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'

import {
  urlEDocs,
  urlFinancialMonitoringConfirmationLevel,
  urlSetFinancingMonitoringStatus,
} from '@/pages/request'
import { getContactInitials } from '@/utils/helperFunc'

export default {
  components: {
    SectionLoader,
  },
  data: () => ({
    DocData: {},
    loading: false,
    submitLoading: false,
    acceptDocumentsLoading: false,
    documentsAccepted: false,
  }),
  computed: {
    id() {
      return this.$route.params.id
    },
    client() {
      const entity =
        this.DocData.client.lesseeTypeId === 2 ? 'contractor' : 'contact'
      return this.DocData.client[entity]
    },
    typeContractor() {
      return this.DocData.client.lesseeTypeId === 2
    },
    typeContact() {
      return [1, 3].includes(this.DocData.client.lesseeTypeId)
    },
  },
  methods: {
    getContactInitials,
    urlSetFinancingMonitoringStatus,
    getFinancialAnalyzesById() {
      this.loading = true
      return this.$axios
        .get(urlEDocs(this.id))
        .then(res => {
          Object.assign(this.DocData, res.data)
          this.financialRiskLevelCache = res.data.financialRiskLevelId
          const {
            isSignedQuestionnaire,
            isSignedPassport,
            isSignedOwnershipStructure,
          } = res.data
          if (
            !!isSignedQuestionnaire &&
            !!isSignedPassport &&
            ((res.data.client && !!res.data.client.contact) ||
              !!isSignedOwnershipStructure)
          )
            this.documentsAccepted = true
          if (res.data.clientConclusion && res.data.providerConclusion) {
            this.conclusion.clientConclusion = res.data.clientConclusion
            this.conclusion.providerConclusion = res.data.providerConclusion
          }
          this.loading = false
          return res
        })
        .catch(this.$err)
    },
    async submit() {
      this.submitLoading = true

      return this.$axios
        .post(this.urlSetFinancingMonitoringStatus(this.id), {
          financialRiskLevelId: this.DocData.financialRiskLevelId,
          statusId: 4,
          clientComment: this.DocData.clientComment,
        })
        .then(async res => {
          this.$setSnackbar({ text: 'Статус змiнено' })
          this.submitLoading = false
          await this.getFinancialAnalyzesById()
          return res
        })
        .catch(this.$err)
    },
    async acceptDocuments() {
      this.acceptDocumentsLoading = true

      if (this.financialRiskLevelCache !== this.DocData.financialRiskLevelId) {
        await this.$axios.post(this.urlSetFinancingMonitoringStatus(this.id), {
          financialRiskLevelId: this.DocData.financialRiskLevelId,
          statusId: 4,
          clientComment: this.DocData.clientComment,
        })
      }

      const {
        isSignedQuestionnaire,
        isSignedPassport,
        isSignedOwnershipStructure,
      } = this.DocData
      const requestObj = {
        isSignedQuestionnaire,
        isSignedPassport,
        isSignedOwnershipStructure,
      }
      return this.$axios
        .post(urlFinancialMonitoringConfirmationLevel(this.id), requestObj)
        .then(() => {
          this.$setSnackbar({ text: 'Документи підтверджено' })
          this.documentsAccepted = true
        })
        .finally(() => (this.acceptDocumentsLoading = false))
    },
  },
  created() {
    this.getFinancialAnalyzesById()
  },
}
</script>
<style>
.generalTab .v-slide-group__content.v-tabs-bar__content {
  background: #fff !important;
}
.newForTab .v-slide-group__content.v-tabs-bar__content {
  background: #fff !important;
}
</style>
<style scoped>
a.fortab.v-tab-item--selected.v-tab {
  background: #f4f6f9;
  border: 1px solid #c0c1c7;
  border-radius: 4px 0 0 4px;
}
a.fortab.v-tab-item--selected.v-tab svg path {
  color: #fc7247;
  fill: #fc7247;
}
a.fortab.v-tab {
  margin-right: 25px;
  background: #fff;
  border: 1px solid #c0c1c7;
  border-radius: 4px 0 0 4px;
  height: 40px;
}
a.v-tab-item--selected.v-tab {
  font-weight: 700 !important;
  color: #08487a !important;
  font-size: 13px;
}
a.v-tab {
  color: #838282 !important;
  font-size: 13px;
  letter-spacing: normal;
}
</style>
