<template>
  <div>
    <div style="font-size: 14px" class="white-block">
      <div class="mb-3" style="color: #000 !important; font-size: 0.9rem">
        Фізична особа-підприємець:
        <span
          style="position: absolute;
    right: 15px;
    color: grey;
}"
          >Було створено: {{ toFormatDate(scoringData.created_at) }}</span
        >
      </div>
      <v-row>
        <v-col :cols="12" :sm="3" class="pt-1 pb-1">
          <div class="card-label">ПIБ</div>
        </v-col>
        <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
          <ALink
            v-if="!fop.fullName"
            :text="scoringData.leasingClient.fullName"
            :route="{
              name: 'individuals-form',
              params: { id: scoringData.leasingClient.id },
            }">
          </ALink>
          <span v-if="fop.fullName" class="card-label">
            {{ fop.fullName || '---' }}
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="12" :sm="3" class="pt-1 pb-1">
          <div class="card-label">Дата реєстрації</div>
        </v-col>
        <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
          <span class="card-label">
            {{ toFormatDate(fop.registrationDate, true) || '---' }}
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="12" :sm="3" class="pt-1 pb-1">
          <div class="card-label">Номер реєстрації</div>
        </v-col>
        <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
          <span class="card-label">
            {{ fop.registrationNumber || '---' }}
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="12" :sm="3" class="pt-1">
          <div class="card-label">
            Дані про реєстраційний номер платника єдиного внеску
          </div>
        </v-col>
        <v-col :cols="12" :sm="9" class="pt-1 text-start">
          <span class="card-label">
            {{ fop.code || scoringData.leasingClient.inn || '---' }}
          </span>
        </v-col>
      </v-row>
      <v-col :cols="12" class="pa-0">
        <v-row>
          <v-col :cols="12" :md="3" class="pt-1">
            <span class="card-label">Місцезнаходження</span>
          </v-col>
          <v-col :cols="12" :md="9" class="pt-1">
            <div class="d-flex align-center">
              <div class="card-sublable mr-1 align-center">
                <AddressIcon class="mr-1" />
                Адреса:
              </div>
              <span class="card-label">
                {{
                  fop.location ||
                  scoringData.leasingClient.addresses
                    .map(e => e.addresses)
                    .join(', ') ||
                  '---'
                }}
              </span>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <div>
        <v-col :cols="12" class="pt-0 pb-0 pl-0">
          <v-row>
            <v-col :cols="12" :md="3" class="pt-0 pb-0">
              <span class="card-label">Контактні дані</span>
            </v-col>
            <v-col :cols="12" :md="9" class="pt-0 pb-0">
              <div class="pl-1 d-flex align-center">
                <div class="card-sublable mr-1 align-center">
                  <EmailIcon :width="16" class="mr-1" />
                  Телефон:
                </div>
                <span class="card-label">{{
                  fop.phones?.join(', ') ||
                  scoringData.leasingClient.phones
                    .map(e => e.phone)
                    .join(', ') ||
                  '---'
                }}</span>
              </div>

              <div class="pl-1 d-flex align-center">
                <div class="card-sublable mr-1 align-center">
                  <PhoneIcon :width="16" class="mr-1" />
                  Email:
                </div>
                <span class="card-label">{{
                  fop.email ||
                  scoringData.leasingClient.emails
                    .map(e => e.email)
                    .join(', ') ||
                  '---'
                }}</span>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </div>
      <div class="mt-2">
        <v-row>
          <v-col :cols="12" :sm="3" class="pb-1">
            <div class="card-label">Основний вид діяльності</div>
          </v-col>
          <v-col :cols="12" :sm="9" class="pb-1 text-start">
            <span class="card-label">
              {{
                fop.primaryActivity ||
                scoringData.main_qued?.code +
                  ' ' +
                  scoringData.main_qued?.division?.name ||
                '---'
              }}
            </span>
          </v-col>
        </v-row>
      </div>
      <div v-if="scoringData.incoming && scoringData.cost">
        <v-row>
          <v-col :cols="12" :sm="3" class="pb-1">
            <div class="card-label">Інформація по ФОП</div>
          </v-col>
          <v-col :cols="12" :sm="9" class="pb-1 text-start">
            <div class="d-flex mb-1">
              <div class="card-sublable mr-1">
                Термін (активної) роботи ФОП, міс:
              </div>
              <span class="card-label">
                {{ scoringData.termWork || '0' }}
              </span>
            </div>
            <div v-if="scoringData.lesseeTypeId === 1" class="d-flex mb-1">
              <div class="card-sublable mr-1">Роботодавець/Замовник:</div>
              <span class="card-label">
                {{ scoringData.employerOrCustomer || '0' }}
              </span>
            </div>
            <div v-if="scoringData.lesseeTypeId === 1" class="d-flex mb-1">
              <div class="card-sublable mr-1">Посада/вид робіт:</div>
              <span class="card-label">
                {{ scoringData.position || '0' }}
              </span>
            </div>
            <div v-if="scoringData.lesseeTypeId === 1" class="d-flex mb-1">
              <div class="card-sublable mr-1">
                Трудовий стаж на поточному місці роботи, міс:
              </div>
              <span class="card-label">
                {{ scoringData.workExperience || '0' }}
              </span>
            </div>
            <div class="d-flex">
              <IncomeIcon class="mr-1" />
              <span>Доходи:</span>
            </div>
            <div class="d-flex mb-1">
              <div class="card-sublable mr-1">
                Від підприємницької діяльності згідно декларації:
              </div>
              <span class="card-label">
                {{ scoringData.incoming.quedPayment || '0' }} грн
              </span>
            </div>
            <div class="d-flex mb-1">
              <div class="card-sublable mr-1">
                Заробітна плата, оплата по цивільно-правовим договорам другого
                із подружжя:
              </div>
              <span class="card-label">
                {{ scoringData.incoming.lawIncomeFop || '0' }} грн
              </span>
            </div>
            <div v-if="scoringData.lesseeTypeId === 1" class="d-flex mb-1">
              <div class="card-sublable mr-1">
                Заробітна плата, оплата по цивільно-правовим договорам:
              </div>
              <span class="card-label">
                {{ scoringData.incoming.targetIncome || '0' }} грн
              </span>
            </div>
            <div class="d-flex mb-1">
              <div class="card-sublable mr-1">Інші доходи:</div>
              <span class="card-label">
                {{ scoringData.incoming.moreIncomeFop || '0' }} грн
              </span>
            </div>
            <div class="d-flex mt-3">
              <IncomeIcon color="#09487a" class="mr-1" />
              <span>Витрати:</span>
            </div>
            <div v-if="scoringData.lesseeTypeId === 1" class="d-flex mb-1">
              <div class="card-sublable mr-1">
                Погашення кредитів/ лізингу/ розстрочки/ % за кредитними
                картками:
              </div>
              <span class="card-label">
                {{ scoringData.cost.credit || '0' }} грн
              </span>
            </div>
            <div v-if="scoringData.lesseeTypeId === 1" class="d-flex mb-1">
              <div class="card-sublable mr-1">
                Сумарні щомісячні витрати (включаючи сім'ю):
              </div>
              <span class="card-label">
                {{ scoringData.cost.monthCost || '0' }} грн
              </span>
            </div>
            <div class="d-flex mb-1">
              <div class="card-sublable mr-1">
                Пов'язані з підприємницькою діяльністю:
              </div>
              <span class="card-label">
                {{ scoringData.cost.businessIncome || '0' }} грн
              </span>
            </div>
            <div class="d-flex mb-1">
              <div class="card-sublable mr-1">
                Виплати по кредитам/ лізингу/ розстрочки:
              </div>
              <span class="card-label">
                {{ scoringData.cost.payout || '0' }} грн
              </span>
            </div>
            <div class="d-flex mb-1">
              <div class="card-sublable mr-1">
                Ведення домашнього господарства (включаючи витрати на сім'ю):
              </div>
              <span class="card-label">
                {{ scoringData.cost.homeIncome || '0' }} грн
              </span>
            </div>
          </v-col>
        </v-row>
      </div>
      <OpenableList>
        <FilesUploader
          label="Паспорт"
          :documents="scoringData.passportDocument"
          readonly />
        <FilesUploader
          label="Індивiдуальний податковий номер"
          :documents="scoringData.innDocument"
          readonly />
        <FilesUploader
          label="Іншi фiнансовi документи"
          :documents="scoringData.financialDocument"
          readonly />
      </OpenableList>
    </div>
  </div>
</template>

<script>
import OpenableList from '@/components/OpenableList.vue'
import FilesUploader from '@/components/FilesUploader.vue'
import AddressIcon from '@/assets/svg/Address-icon.vue'
import {
  getAddress,
  getResponsibleShortName,
  toFormatDate,
} from '@/utils/helperFunc'
import EmailIcon from '@/assets/svg/social/email-icon.vue'
import PhoneIcon from '@/assets/svg/phone-icon.vue'
import IncomeIcon from '@/assets/svg/incomeIcon.vue'
import ALink from '@/components/Link.vue'
export default {
  name: 'Fop',
  components: {
    ALink,
    IncomeIcon,
    PhoneIcon,
    EmailIcon,
    AddressIcon,
    OpenableList,
    FilesUploader,
  },
  props: {
    fop: { type: Object },
    scoringData: { type: Object },
  },
  methods: {
    toFormatDate,
    getAddress,
    getResponsibleShortName,
  },
}
</script>

<style scoped></style>
