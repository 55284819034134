<template>
  <a
    :id="linkId"
    class="hyper-link cursor-pointer"
    :href="isObject(route) ? getSafeRoute() : route"
    @click="toggle">
    {{ text }}
  </a>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  props: {
    route: { type: [Object, String] },
    text: { type: String },
    download: { type: Boolean, default: false },
    params: { type: Object },
  },
  setup(props) {
    const linkId = ref(`link-${Math.random().toString(36).substr(2, 9)}`)
    const router = useRouter()
    const store = useStore()

    const toggle = e => {
      if (!props.route || props.download) {
        const el = document.getElementById(linkId.value)
        el.setAttribute('download', (props.params?.file || '').split('/').pop())
        return
      }

      e.preventDefault()
      store.commit('closeDialog')
      router.push(props.route)
    }

    const isObject = entity => {
      return (
        typeof entity === 'object' && !Array.isArray(entity) && entity !== null
      )
    }

    const getSafeRoute = () => {
      try {
        return router.resolve(props.route).href
      } catch (error) {
        return '#'
      }
    }

    return {
      linkId,
      toggle,
      isObject,
      getSafeRoute,
    }
  },
}
</script>

<style lang="scss">
.v-application {
  a {
    &.hyper-link {
      text-decoration: none;
      color: #08487a;
      font-weight: 400;
      font-size: 13px !important;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
