<template>
  <v-card class="dialog-card">
    <v-toolbar
      color="rgb(210, 74, 67)"
      class="mb-6"
      :elevation="2"
      :height="42"
      dark>
      <v-toolbar-title style="font-size: 1rem">
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        small
        icon
        @click="
          () => {
            closeDialog
            $emit('update:state', false)
            reject()
          }
        ">
      </v-btn>
    </v-toolbar>
    <v-card-text class="pb-0">
      <slot name="body">
        <!-- Fields here -->
      </slot>
    </v-card-text>
    <v-scroll-x-transition hide-on-leave>
      <v-card-actions v-if="inititialized" class="justify-space-around pb-2">
        <v-btn
          class="text-white"
          color="grey darken-3"
          style="width: 45%; text-transform: none"
          :height="33"
          small
          :loading="loading"
          @click="submit">
          ЗБЕРЕГТИ
        </v-btn>
        <v-btn
          class="text-white"
          color="grey darken-3"
          style="width: 45%; text-transform: none"
          :height="33"
          small
          :disabled="loading"
          @click="
            () => {
              reject()
              $emit('update:state', false)
            }
          ">
          ВІДХИЛИТИ
        </v-btn>
      </v-card-actions>
    </v-scroll-x-transition>
  </v-card>
</template>
<script>
export default {
  emits: ['update:state'],
  props: {
    title: {
      type: String,
      default: 'Дiя',
    },
    closeDialog: {
      type: Function,
      default: () => false,
    },
    submit: {
      type: Function,
      default: () => false,
    },
    reject: {
      type: Function,
      default: () => false,
    },
    inititialized: {
      type: Boolean,
      default: true,
    },
    state: { type: Boolean },
    loading: { type: Boolean },
  },
}
</script>
