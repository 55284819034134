<template>
  <div>
    <!-- Project documents -->
    <OpenableSection title="Основні документи">
      <template #append-title>
        <AllDocsBtn :id="project.id" project />
      </template>
      <FilesUploader
        v-model:documents="slider.applicationForm"
        readonly
        label="Заявка на лізинг"
        single
        acceptedExt="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        :disableDeleteAction="!userAdmin" />
      <FilesUploader
        v-if="isContractorLessee"
        v-model:documents="slider.ownershipStructure"
        readonly
        label="Структура власності у вигляді окремої схеми"
        :disableDeleteAction="!userAdmin" />
      <FilesUploader
        v-model:documents="slider.questionnaire"
        readonly
        label="Опитувальник"
        single
        :disableDeleteAction="!userAdmin" />
      <FilesUploader
        v-if="showDoc(projectDocuments, 'visualAndWrittenExamples')"
        v-model:documents="project.visualAndWrittenExamples"
        showDelete
        :readonly="projectDocsReadonly"
        label="Наочні та письмові приклади"
        :disableDeleteAction="!userAdmin"
        @delete-field="hideDoc(projectDocuments, 'visualAndWrittenExamples')" />
      <FilesUploader
        v-if="
          getSafe(
            () =>
              projectDocuments.variousWritingsExplanations.show ||
              project.variousWritingsExplanations[
                docIdx('variousWritingsExplanations')
              ].doc
          )
        "
        showDelete
        :readonly="projectDocsReadonly"
        label="Різні листи - пояснення"
        :documents="
          project.variousWritingsExplanations &&
          project.variousWritingsExplanations[
            docIdx('variousWritingsExplanations')
          ]
            ? project.variousWritingsExplanations[
                docIdx('variousWritingsExplanations')
              ].doc
            : []
        "
        createProp
        :disableDeleteAction="!userAdmin"
        @delete-field="hideDoc(projectDocuments, 'variousWritingsExplanations')"
        @loaded="addFile('variousWritingsExplanations', $event)"
        @delete="
          deleteFile(
            'variousWritingsExplanations',
            docIdx('variousWritingsExplanations')
          )
        " />
      <FilesUploader
        v-if="showDoc(projectDocuments, 'businessNote')"
        v-model:documents="project.businessNote"
        showDelete
        :readonly="projectDocsReadonly"
        label="Службова записка"
        :disableDeleteAction="!userAdmin"
        @delete-field="hideDoc(projectDocuments, 'businessNote')" />
      <FilesUploader
        v-if="showDoc(projectDocuments, 'otherDocuments')"
        v-model:documents="project.otherDocuments"
        showDelete
        :readonly="projectDocsReadonly"
        label="Інші документи/погодження"
        :disableDeleteAction="!userAdmin"
        @delete-field="hideDoc(projectDocuments, 'otherDocuments')" />
      <FilesUploader
        v-if="
          getSafe(
            () =>
              projectDocuments.protocolPPE.show ||
              project.protocolPPE[docIdx('protocolPPE')].doc
          )
        "
        :readonly="projectDocsReadonly"
        showDelete
        label="Рішення/Протокол ЗЗУ"
        :documents="
          project.protocolPPE && project.protocolPPE[docIdx('protocolPPE')]
            ? project.protocolPPE[docIdx('protocolPPE')].doc
            : []
        "
        createProp
        :disableDeleteAction="!userAdmin"
        @delete-field="hideDoc(projectDocuments, 'protocolPPE')"
        @loaded="addFile('protocolPPE', $event)"
        @delete="deleteFile('protocolPPE', docIdx('protocolPPE'))" />
      <FilesUploader
        v-if="benefit"
        v-model:documents="slider.environmentalQuestionnaire"
        readonly
        label="Анкета екологічної обачності"
        single
        :disableDeleteAction="!userAdmin"
        :maxSize="50000000" />
      <AdditionalDocs
        v-if="!projectDocsReadonly"
        :key="updateKey"
        v-model:documents="projectDocuments" />
      <div v-if="benefit" class="mt-4">
        <v-divider class="mb-3"></v-divider>
        <span class="card-block-title mb-2">Опитувальники BEST BENEFITS</span>
        <BenefitsQuestionnaire
          :questionnaires="slider.benefitsQuestionnaires"
          :sliderId="slider.id"
          :lesseeTypeId="project.lesseeTypeId" />
      </div>
    </OpenableSection>

    <!-- Signed documents -->
    <OpenableSection title="Підписані документи">
      <template #append-title>
        <AllDocsBtn :id="project.id" project />
      </template>
      <FilesUploader
        v-if="isContractorLessee"
        v-model:documents="project.ownershipStructureSigned"
        :readonly="projectDocsReadonly"
        label="Структура власності (Підписаний)"
        single
        :disableDeleteAction="!userAdmin" />
      <FilesUploader
        :readonly="projectDocsReadonly"
        label="Заявка на лізинг (Підписаний)"
        :documents="
          project.applicationFormSigned &&
          project.applicationFormSigned[docIdx('applicationFormSigned')]
            ? project.applicationFormSigned[docIdx('applicationFormSigned')].doc
            : []
        "
        createProp
        single
        :disableDeleteAction="!userAdmin"
        @loaded="addFile('applicationFormSigned', $event)"
        @delete="
          deleteFile('applicationFormSigned', docIdx('applicationFormSigned'))
        " />
      <FilesUploader
        v-model:documents="project.consentToProcessingOfPersonalData"
        :readonly="projectDocsReadonly"
        label="Згода на обробку персональних даних (Підписаний)"
        single
        :disableDeleteAction="!userAdmin" />
      <FilesUploader
        v-model:documents="project.clientProtocolDocuments"
        :readonly="projectDocsReadonly"
        label="Протокол клієнта про отримання лізингу (Підписаний)"
        single
        :disableDeleteAction="!userAdmin" />
      <FilesUploader
        v-model:documents="project.questionnaireSigned"
        :readonly="projectDocsReadonly"
        label="Опитувальник (Підписаний)"
        single
        :disableDeleteAction="!userAdmin" />
      <FilesUploader
        v-if="benefit"
        v-model:documents="project.environmentalQuestionnaireSigned"
        :readonly="projectDocsReadonly"
        label="Анкета екологічної обачності (Підписаний)"
        single
        :disableDeleteAction="!userAdmin"
        :maxSize="50000000" />
    </OpenableSection>
  </div>
</template>
<script>
import BenefitsQuestionnaire from './BenefitsQuestionnaire.vue'
import { useAdditionalDocs } from '@/utils/mixins/useAdditionalDocs'
import AdditionalDocs from '@/components/AdditionalDocs.vue'
import OpenableSection from '@/components/OpenableSection.vue'
import AllDocsBtn from '@/components/AllDocsBtn.vue'
import FilesUploader from '@/components/FilesUploader.vue'
import { mapState } from 'vuex'
import { getSafe } from '@/utils/helperFunc'
export default {
  components: {
    FilesUploader,
    AllDocsBtn,
    OpenableSection,
    AdditionalDocs,
    BenefitsQuestionnaire,
  },
  setup() {
    return {
      ...useAdditionalDocs(),
    }
  },
  props: {
    readonly: { type: Boolean },
  },
  data: () => ({
    showClientDocuments: false,
    generalObject: 'project',
    projectDocuments: {
      protocolPPE: { label: 'Рішення/Протокол ЗЗУ' },
      otherDocuments: { label: 'Інші документи/погодження' },
      businessNote: { label: 'Службова записка' },
      variousWritingsExplanations: { label: 'Різні листи - пояснення' },
      visualAndWrittenExamples: { label: 'Наочні та письмові приклади' },
    },
  }),
  computed: {
    ...mapState({
      project: state => state.projects.project,
      slider: state => state.projects.slider,
      roleId: state => state.user.roleId,
    }),
    isContactLessee() {
      return [1, 3].includes(this.project.lesseeTypeId)
    },
    isContractorLessee() {
      return this.project.lesseeTypeId === 2
    },
    userAdmin() {
      return this.roleId === 1
    },
    projectDocsReadonly() {
      return this.project.statusId < 3 || ![1, 5, 6].includes(this.roleId)
    },
    benefit() {
      return this.project?.calculations?.reduce((acc, calc) => {
        return acc || calc.leasingProgram === 34
      }, false)
    },
  },
  methods: {
    getSafe,
    addFile(key, doc, guarantor = null) {
      const guarantorDoc = {
        doc: doc,
        guarantorId: null,
        guarantorTypeId: null,
      }
      if (guarantor) {
        guarantorDoc.guarantorId = guarantor.id
        guarantorDoc.guarantorTypeId = guarantor.guarantorTypeId
      }
      const idx = this.docIdx(
        key,
        guarantorDoc.guarantorId,
        guarantorDoc.guarantorTypeId
      )
      if (idx !== -1) {
        this.deleteFile(key, idx)
      }
      if (!this.project[key]) {
        this.project[key] = []
      }
      this.project[key].push(guarantorDoc)
    },
    docIdx(key, id = null, typeId = null) {
      if (key in this.project && !this.project[key]) this.project[key] = []
      return this.project[key]
        ? this.project[key].findIndex(
            el => el.guarantorId === id && el.guarantorTypeId === typeId
          )
        : -1
    },
    deleteFile(key, idx) {
      this.project[key] = this.project[key].filter((_, i) => i !== idx)
    },
  },
  mounted() {
    this.setAdditionalDocsState([this.projectDocuments])
    if (this.isContactLessee) delete this.projectDocuments.protocolPPE
  },
}
</script>
