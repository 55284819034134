<template>
<svg :width="width" :height="height" :fill="color" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 496 496" style="enable-background:new 0 0 496 496;" xml:space="preserve">
  <g>
    <g>
      <g>
        <path d="M428,24H100V8c0-4.418-3.582-8-8-8H68c-4.418,0-8,3.582-8,8v480c0,4.418,3.582,8,8,8h24c4.418,0,8-3.582,8-8V296h328
          c4.418,0,8-3.582,8-8V32C436,27.582,432.418,24,428,24z M84,480h-8V16h8V480z M420,120h-80v80h80v80h-80v-80h-80v80h-80v-80h-80
          v-80h80V40h80v80h80V40h80V120z"/>
        <rect x="180" y="120" width="80" height="80"/>
      </g>
    </g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
</svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 25
    },
    height: {
      type: Number,
      default: 25
    },
    color: {
      type: String,
      default: 'grey'
    }
  }
}
</script>