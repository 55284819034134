<template>
  <div class="sms-view">
    <span class="d-block mb-1">Повідомлення:</span>
    <span class="d-block" style="color: #000; font-size: 16px">{{
      dialog.dialogItem.message
    }}</span>
  </div>
</template>

<script>
export default {
  props: {
    dialog: { type: Object },
  },
}
</script>
