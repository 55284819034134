<template>
  <div>
    <div class="crm-section-title title-absolute">Архівні дані:</div>
    <Filters :filters="filters" class="ml-2" />
    <SectionLoader v-if="asyncLoading('getArchiveList')" />
    <div v-show="!asyncLoading('getArchiveList')">
      <v-data-table
        :items="archiveList"
        :headers="archiveListHeaders"
        class="application-table calc-table"
        hide-default-footer
        :hide-default-header="!archiveList.length"
        :items-per-page="-1"
        @dblclick:row="navigateToArchive">
        <!-- eslint-disable-next-line -->
        <template #item.archiveCode="{ item }">
          <span>{{ item.archiveCode || '---' }}</span>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.contract="{ item }">
          <ALink
            :text="item.contract.number"
            :route="{
              name: 'dfl-contract',
              params: { id: item.contract.id },
            }" />
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.lessee="{ item }">
          <ALink
            :text="item.lessee.name"
            :route="
              getEntityRoute(item.lessee.lesseeId, item.lessee.lesseeTypeId)
            " />
        </template>
      </v-data-table>
      <Pagination :getItems="getArchiveList" :trigger="paginationTrigger" />
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue'
import SectionLoader from '@/components/section-loader.vue'
import ALink from '@/components/Link.vue'
import { urlGetArchiveList } from '@/pages/request'
import { getEntityRoute } from '@/utils/helperFunc'
import Filters from '@/components/Filters.vue'
import { useSelect } from '@/utils/mixins/useSelect'
import { useFilter } from '@/utils/mixins/useFilter'
import { reactive } from 'vue'
import { archiveListHeaders } from '@/utils/tableHeaders'

export default {
  components: { Filters, Pagination, SectionLoader, ALink },
  setup() {
    const filters = reactive({
      startDate: {
        value: null,
        component: 'DatePicker',
        attrs: { label: 'Період', range: true },
      },
      search: { value: null },
    })
    return {
      ...useSelect(),
      ...useFilter(filters),
      filters,
    }
  },
  data() {
    return {
      archiveList: [],
      archiveListHeaders,
    }
  },
  methods: {
    getEntityRoute,
    navigateToArchive(e, row) {
      this.$router.push({ name: 'archive-single', params: { id: row.item.id } })
    },
    getArchiveList(page = 1) {
      return this.asyncAction('getArchiveList', null, () => {
        return this.$axios
          .get(urlGetArchiveList(page, this.filtersQueryObj))
          .then(res => {
            this.archiveList = res.data.data
            return res
          })
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
