import initUser from '@/layout/initialize.vue'
import RecoveryPassword from '@/pages/auth/RecoveryPassword.vue'
import AuthLayout from '@/layout/AuthLayout.vue'
import G2Auth from '@/pages/auth/G2Auth.vue'
import Login from '@/pages/auth/Login.vue'

export default [
  {
    path: '/',
    component: initUser,
    name: 'initUser',
    meta: {
      title: 'Авторизація',
    },
    redirect: { name: 'authLayout' },
    children: [
      {
        path: '/authLayout',
        component: AuthLayout,
        name: 'authLayout',
        meta: {
          title: 'Авторизація',
        },
        redirect: { name: 'login' },
        children: [
          {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
              title: 'Авторизація',
            },
          },
          {
            path: '/recovery-password',
            component: RecoveryPassword,
            name: 'recovery-password',
            meta: {
              title: 'Відновлення доступу',
            },
          },
          {
            path: '/auth-confirm',
            name: 'auth-confirm',
            component: G2Auth,
            meta: {
              title: 'Авторизація',
            },
          },
        ],
      },
    ],
  },
]
