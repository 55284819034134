const yesOrNo = [
  { text: 'Так', value: 1 },
  { text: 'Нi', value: 2 },
]
const klpReworkTypes = [
  { name: 'Розблокування проекта', id: 1 },
  { name: 'Розблокування калькуляції', id: 2 },
] // TODO maybe move to backend

const meetStatuses = [
  { name: 'Не завершено', id: false },
  { name: 'Завершено', id: true },
]

export { yesOrNo, klpReworkTypes, meetStatuses }
