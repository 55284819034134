<template>
  <div v-if="$route.name === 'average-price'">
    <div>
      <div class="crm-section-title title-absolute">
        Оцінка середньої вартості:
      </div>
      <Filters :filters="filters" class="ml-2">
        <template #header-action>
          <v-btn
            data-readonly="hide"
            class="mr-3"
            color="#fc7247"
            variant="flat"
            @click="
              $router.push({
                name: 'average-price-create-calculation',
              })
            ">
            <v-icon class="mr-2">
              {{ 'mdi-calculator' }}
            </v-icon>
            Розрахувати вартість&nbsp;
          </v-btn>
        </template>
      </Filters>
    </div>
    <v-fade-transition hide-on-leave>
      <div>
        <v-row class="pr-3 mt-3">
          <v-col
            v-show="!asyncLoading('getAverageList')"
            cols="12"
            md="12"
            sm="12">
            <div>
              <v-data-table
                :headers="averagePriceListHeaders"
                :items="averageBody"
                class="application-table calc-table"
                mobile-breakpoint="1200"
                hide-default-footer
                :hide-default-header="!averageBody.length"
                :items-per-page="-1"
                @dblclick:row="navigateToSingle">
                <!-- eslint-disable-next-line -->
                <template #item.years="{ item }">
                  <span>{{
                    item.yearFrom && item.yearTo
                      ? `${item.yearFrom} - ${item.yearTo}`
                      : `${item.yearFrom || item.yearTo}`
                  }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template #item.responsible="{ item }">
                  <span>{{
                    getContactInitials(item.responsible, { byName: true })
                  }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template #item.date="{ item }">
                  <span>{{ toFormatDate(item.created_at) }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template #item.actions="{ item }">
                  <TableCrud
                    data-readonly="hide"
                    :actionsArray="[
                      {
                        action: () =>
                          $router.push({
                            name: 'average-price-edit-calculation',
                            params: { id: item.id },
                          }),
                        text: 'Редагувати',
                        icon: 'mdi-pencil',
                      },
                    ]">
                  </TableCrud>
                </template>
              </v-data-table>
            </div>
            <Pagination
              :getItems="getAverageList"
              :trigger="paginationTrigger" />
          </v-col>
        </v-row>
        <SectionLoader
          v-if="
            asyncLoading('getAverageList') && $route.name === 'average-price'
          "></SectionLoader>
      </div>
    </v-fade-transition>
  </div>
  <RouterView v-else />
</template>

<script>
import Filters from '@/components/Filters.vue'
import TableCrud from '@/components/table-crud.vue'
import Pagination from '@/components/Pagination.vue'
import { urlGetAverageList } from '@/pages/request'
import SectionLoader from '@/components/section-loader.vue'
import { getContactInitials, toFormatDate } from '@/utils/helperFunc'
import { useSelect } from '@/utils/mixins/useSelect'
import { useFilter } from '@/utils/mixins/useFilter'
import { reactive } from 'vue'
import { averagePriceListHeaders } from '@/utils/tableHeaders'
import { fillDirectory } from '@/plugins/directory'

export default {
  components: {
    SectionLoader,
    Pagination,
    TableCrud,
    Filters,
  },
  setup() {
    const filters = reactive({
      search: { value: null },
      markId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Марка',
          items: 'averageCarMarks',
          itemValue: u => u.value,
        },
        focus: { action: 'addAverageCarMarks' },
      },
      modelId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Модель',
          items: 'averageCarModels',
          itemValue: u => u.value,
        },
      },
      responsibleId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Відповідальний',
          itemTitle: u => getContactInitials(u, { isResponsible: true }),
          items: 'activeUsers',
        },
        focus: fillDirectory('allUsers'),
      },
      startDate: {
        value: null,
        component: 'DatePicker',
        attrs: { label: 'Період', range: true },
      },
    })
    return {
      ...useSelect(),
      ...useFilter(filters),
      filters,
    }
  },
  data() {
    return {
      averageBody: [],
      pageLoad: false,
      averagePriceListHeaders,
    }
  },
  methods: {
    toFormatDate,
    getContactInitials,
    addAverageCarModels(categoryId = 1, markId) {
      this.$store.commit('clearAverageCarModels')
      return this.$store.dispatch('addAverageCarModels', { categoryId, markId })
    },
    navigateToSingle(e, row) {
      this.$router.push({
        name: 'average-price-edit-calculation',
        params: { id: row.item.id },
      })
    },
    getAverageList(page = 1) {
      return this.asyncAction('getAverageList', null, () => {
        return this.$axios
          .get(urlGetAverageList(page, this.filtersQueryObj))
          .then(res => {
            this.averageBody = []
            this.averageBody = res?.data?.data

            return res
          })
      })
    },
  },
  watch: {
    'filters.markId.value': function (value) {
      if (value) {
        this.addAverageCarModels(1, value)
      }
    },
  },
}
</script>
