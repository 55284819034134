<template>
  <div>
    <div class="back-block">
      <div class="d-flex align-center" style="justify-content: space-between">
        <div>
          <span style="text-decoration: underline">Номер договору -</span>
          <span class="link">{{ info?.Наименование }}</span>
        </div>
        <div
          style="background: white; border-radius: 7px"
          class="d-flex align-center">
          <div>
            <v-tooltip bottom text="Графік платежів">
              <template #activator="{ props }">
                <v-btn icon dense v-bind="props" @click="graphicDialog = true">
                  <span style="font-size: 16px">📊</span>
                </v-btn>
              </template>
            </v-tooltip>
          </div>
          <div>
            <v-dialog v-model="paymentsDialog" width="600px" persistent>
              <template #activator="{ props }">
                <v-tooltip v-bind="props" bottom text="Додаткові реквізити">
                  <template #activator="{ propsTooltip }">
                    <v-btn
                      icon
                      dense
                      v-bind="propsTooltip"
                      @click="paymentsDialog = true">
                      <span style="font-size: 16px">📜</span>
                    </v-btn>
                  </template>
                </v-tooltip>
              </template>
              <v-card>
                <div class="d-flex justify-end">
                  <v-btn
                    style="margin-right: 10px; margin-top: 10px"
                    size="x-small"
                    icon
                    dense
                    @click="paymentsDialog = false">
                    <CloseIcon />
                  </v-btn>
                </div>
                <div style="background: #fff">
                  <div class="pa-2 pt-0" style="background: #fff">
                    <OpenableList
                      v-for="(p, idx) in paymentRequits"
                      :key="idx + 1"
                      :title="`додаткові реквiзити №${idx + 1}`">
                      <div style="margin-left: 5px" class="mb-2">
                        <div>
                          <span>Властивість -</span>
                          <span>{{ p.Свойство }}</span>
                        </div>
                        <div>
                          <span>Значення -</span>
                          <span>{{ p.Значение }}</span>
                        </div>
                      </div>
                    </OpenableList>
                  </div>
                </div>
              </v-card>
            </v-dialog>
          </div>
        </div>
      </div>
      <div>
        <span>Кінець договору -</span>
        <span>{{ toFormatDate(info?.doДатаОкончанияДоговора) }}</span>
      </div>
      <div>
        <span>Дата відвантаження -</span>
        <span>{{ toFormatDate(info?.doДатаОтгрузкиДоговора) }}</span>
      </div>
      <div>
        <span>Валюта договору -</span>
        <span>{{ info?.ВалютаВзаиморасчетов }}</span>
      </div>
      <div>
        <span>Коментар -</span>
        <span>{{ info?.Комментарий }}</span>
      </div>
    </div>
    <div>
      <v-dialog v-model="graphicDialog" width="800px" persistent>
        <div>
          <div
            style="
              background: white;
              position: sticky;
              right: 0;
              top: 0;
              height: 40px;
              display: flex;
              justify-content: end;
            ">
            <v-btn
              style="margin-right: 10px; margin-top: 10px"
              size="x-small"
              icon
              dense
              @click="graphicDialog = false">
              <CloseIcon />
            </v-btn>
          </div>
          <div style="background: #fff; overflow: scroll; height: 100vh">
            <div class="pa-2 pt-0" style="background: #fff">
              <v-data-table
                :headers="paymentHeaders"
                :items="paymentGraphics"
                class="calc-table --cust-cell-pd"
                hide-default-footer
                :items-per-page="-1"
                :hide-default-header="!paymentGraphics.length">
                <!-- eslint-disable-next-line -->
                <template #item.date="{ item }">
                  {{ toFormatDate(item.Дата, true, false) }}
                </template>
                <!-- eslint-disable-next-line -->
                <template #item.Тело="{ item }">
                  <span>{{ getBeautyNum(item.Тело) || 0 }} грн</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template #item.СумаПлатежа="{ item }">
                  <span>{{ getBeautyNum(item.СуммаПлатежа) || 0 }} грн</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template #item.avans="{ item }">
                  <span>{{
                    item.Аванс ? 'Авансовий платiж' : 'Черговий платіж'
                  }}</span>
                </template>
              </v-data-table>
            </div>
          </div>
        </div>
      </v-dialog>
      <!--      <v-dialog v-model="leasingObjectDialog" width="90%" persistent>-->
      <!--        <div>-->
      <!--          <div-->
      <!--            style="-->
      <!--              background: white;-->
      <!--              position: sticky;-->
      <!--              right: 0;-->
      <!--              top: 0;-->
      <!--              height: 40px;-->
      <!--              display: flex;-->
      <!--              justify-content: end;-->
      <!--            ">-->
      <!--            <v-btn-->
      <!--              style="margin-right: 10px; margin-top: 10px"-->
      <!--              x-small-->
      <!--              icon-->
      <!--              dense-->
      <!--              @click="leasingObjectDialog = false">-->
      <!--              <CloseIcon />-->
      <!--            </v-btn>-->
      <!--          </div>-->
      <!--          <div style="background: #fff">-->
      <!--            <div class="pa-2 pt-0" style="background: #fff">-->
      <!--              <LeasingObjectSingle modal :oneCLeasingObjectId="curOneCId" />-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </v-dialog>-->
    </div>
    <div
      v-if="leasingObjects"
      class="back-block"
      style="margin-top: 10px; padding-bottom: 3px">
      <OpenableList
        v-for="(l, idx) in leasingObjects"
        :key="idx + 1"
        :title="`Об'єкт лізингу №${idx + 1}`"
        leasingObjectIcon
        hide-text>
        <div>
          <span>Об'єкт лізингу -</span>
          <span class="link" @click="getLeasingObject(l.ОбъектЛизинга_1C_ID)">{{
            l.ОбъектЛизинга
          }}</span>
        </div>
        <div>
          <span>Об'єкт лізингу_1C_ID -</span>
          <span>{{ l.ОбъектЛизинга_1C_ID }}</span>
        </div>
      </OpenableList>
    </div>
    <div class="mt-3 back-block">
      <span style="font-weight: 700">Відповідальні:</span>
      <div class="d-flex flex-column">
        <span>Відповідальний Фронт менеджер - </span>
        <span style="text-decoration: underline">{{
          `${info?.ОтветственныйФронт || '---'}`
        }}</span>
      </div>
      <div class="d-flex flex-column">
        <span>Відповідальний Мідл менеджер - </span>
        <span style="text-decoration: underline">{{
          `${info?.ОтветственныйМидл || '---'}`
        }}</span>
      </div>
      <div class="d-flex flex-column">
        <span>Відповідальний Ризик менеджер - </span>
        <span style="text-decoration: underline">{{
          `${info?.ОтветственныйРиск || '---'}`
        }}</span>
      </div>
      <div class="d-flex flex-column">
        <span>Відповідальний Юрист - </span>
        <span style="text-decoration: underline">{{
          `${info?.ОтветственныйЮр || '---'}`
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/assets/svg/close-icon.vue'
import OpenableList from '@/components/OpenableList.vue'
import { getBeautyNum, toFormatDate } from '@/utils/helperFunc'
import { urlGetOneCLeasingObjectByOneCId } from '@/pages/request'

export default {
  name: 'ModalInfo',
  components: { OpenableList, CloseIcon },
  props: {
    info: { type: Object },
    oneCId: { type: Number },
  },
  data: () => ({
    graphicDialog: false,
    paymentsDialog: false,
    // leasingObjectDialog: false,
    oneCLeasingObjectInfo: null,
    oneCLeasingObjectId: null,
    paymentHeaders: [
      {
        title: 'Період лізингу',
        key: 'date',
        sortable: false,
        align: 'start',
      },
      {
        title: 'Лізинговий платіж',
        key: 'avans',
        sortable: false,
        align: 'start',
        width: '140px',
      },
      {
        title: "Відшкодування вартості Об'єкта лізингу",
        key: 'Тело',
        sortable: false,
        align: 'start',
        width: '250px',
      },
      {
        title: 'Загальний лізинговий платіж',
        key: 'СумаПлатежа',
        sortable: false,
        align: 'start',
      },
    ],
  }),
  computed: {
    curOneCId() {
      return this.oneCId || this.oneCLeasingObjectId
    },
    paymentGraphics() {
      return this.info?.График || []
    },
    leasingObjects() {
      return this.info?.ОбъектыЛизинга || []
    },
    paymentRequits() {
      return this.info?.ДополнительныеРеквизиты || []
    },
    oneCInfoText() {
      return this.formatObject(this.oneCLeasingObjectInfo)
    },
  },
  methods: {
    getBeautyNum,
    toFormatDate,
    getLeasingObject(cid) {
      const req = {
        oneCId: cid,
      }
      return this.$axios
        .post(urlGetOneCLeasingObjectByOneCId(), req)
        .then(res => {
          this.oneCLeasingObjectInfo = res.data
          this.oneCLeasingObjectId = cid
          this.$router.push({
            name: 'leasing-object-single',
            params: { id: this.oneCLeasingObjectInfo._1C_ID },
          })
        })
    },
    formatObject(obj, indent = 0) {
      if (!obj) {
        return ''
      }

      const indentString = '  '.repeat(indent)
      const lines = []

      for (const [key, value] of Object.entries(obj)) {
        if (typeof value === 'object' && value !== null) {
          const nestedObj = this.formatObject(value, indent + 1)
          lines.push(`${indentString}${key}:`)
          lines.push(nestedObj)
        } else {
          lines.push(`${indentString}${key}: ${value}`)
        }
      }

      return lines.join('\n')
    },
  },
}
</script>

<style scoped>
.back-block {
  background: #a0bfff26;
  padding: 10px;
}
.inter-block {
  background: #a0bfff26;
}
</style>
