<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 12 13"
    fill="none">
    <rect y="0.5" width="12" height="12" rx="6" fill="#6AC9FF" />
    <path
      d="M9.58342 9.1457H8.98994V6.02734C8.9898 5.96323 8.97622 5.89985 8.9501 5.84131C8.92398 5.78277 8.88588 5.73036 8.83827 5.68746L6.20056 3.28517C6.11532 3.20887 6.00498 3.16669 5.89062 3.16669C5.77627 3.16669 5.66593 3.20887 5.58069 3.28517L2.94298 5.68746C2.89537 5.73036 2.85727 5.78277 2.83115 5.84131C2.80502 5.89985 2.79145 5.96323 2.79131 6.02734V9.1457H2.19783C2.14536 9.1457 2.09504 9.16656 2.05794 9.20369C2.02084 9.24083 2 9.29119 2 9.3437C2 9.39621 2.02084 9.44657 2.05794 9.4837C2.09504 9.52083 2.14536 9.54169 2.19783 9.54169H9.58342C9.63589 9.54169 9.68621 9.52083 9.72331 9.4837C9.76041 9.44657 9.78125 9.39621 9.78125 9.3437C9.78125 9.29119 9.76041 9.24083 9.72331 9.20369C9.68621 9.16656 9.63589 9.1457 9.58342 9.1457ZM3.18697 6.02734C3.18669 6.01867 3.18832 6.01003 3.19174 6.00205C3.19516 5.99407 3.20028 5.98693 3.20675 5.98115L5.84446 3.57885C5.85662 3.56772 5.8725 3.56155 5.88898 3.56155C5.90545 3.56155 5.92133 3.56772 5.93349 3.57885L8.5712 5.98115C8.57868 5.98622 8.58473 5.99314 8.58877 6.00123C8.59281 6.00932 8.59471 6.01831 8.59428 6.02734V9.1457H6.87977V7.49578C6.87977 7.37325 6.83113 7.25575 6.74457 7.16911C6.658 7.08247 6.54059 7.0338 6.41817 7.0338H5.36308C5.24066 7.0338 5.12325 7.08247 5.03668 7.16911C4.95012 7.25575 4.90148 7.37325 4.90148 7.49578V9.1457H3.18697V6.02734ZM6.48411 9.1457H5.29714V7.49578C5.29714 7.47828 5.30409 7.46149 5.31645 7.44911C5.32882 7.43673 5.34559 7.42978 5.36308 7.42978H6.41817C6.43566 7.42978 6.45243 7.43673 6.4648 7.44911C6.47716 7.46149 6.48411 7.47828 6.48411 7.49578V9.1457Z"
      fill="white" />
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: Number, default: 16 },
  },
}
</script>

<style scoped></style>
