<template>
  <div>
    <InfoFields style="padding-left: 0" :fields="fields" />
  </div>
</template>

<script>
import InfoFields from '@/components/info-fields/InfoFields.vue'
import { toFormatDate } from '@/utils/helperFunc'

export default {
  components: { InfoFields },
  props: {
    LO: { type: Object },
  },
  computed: {
    fields() {
      return [
        { label: 'Дата реєстрації', value: toFormatDate(this.LO?.ДатаРегистрации, true) },
        {
          label: 'Дата зняття з обліку',
          value: toFormatDate(this.LO?.ДатаСнятияСУчета, true),
        },
        {
          label: 'Місце реєстрації',
          value: this.LO?.Регистрации?.[0]?.РегистрирующийОрган,
        },
        { label: 'Свідоцтво реєстрації', value: this.LO?.СвидетельствоОРегистрацииТС },
        { label: 'Тип реєстрації', value: this.LO?.Регистрации?.[0]?.ПричинаРегистрации },
      ]
    },
  },
}
</script>

<style lang="scss" scoped></style>
