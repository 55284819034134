import { computed, reactive } from 'vue'
import { Address, City, Street, House } from '@/utils/types'
import { Nullable } from 'best-modules/utils/types'
import { requiredIf } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

const getCityTemplate = (): Nullable<City> => {
  return {
    useOldCity: false,
    City: null,
    CityOld: null,
    KATO: null,
    KOATUU: null,
    Lat: null,
    Long: null,
    SettlementType: null,
    SettlementTypeOld: null,
    st_moniker: null,
    Id: null,
    useOldRegion: null,
    Region: null,
    RegionOld: null,
    useOldArea: null,
    Area: null,
    AreaOld: null,
  }
}
const getStreetTemplate = (): Nullable<Street> => {
  return {
    useOldStreet: false,
    Street: null,
    StreetId: null,
    StreetType: null,
    StreetTypeOld: null,
    house_moniker: null,
    StreetOld: null,
  }
}
const getHouseTemplate = (): Nullable<House> => {
  return {
    Index_: null,
    HouseNumAdd: null,
    HouseNum: null,
    apartmentNum: null,
    HouseId: null,
  }
}

function useAddress() {
  const address = reactive<Address>({
    canBeEmpty: false,
    typeId: null,
    countryId: 1,
    apartmentTypeId: null,
    city: getCityTemplate(),
    street: getStreetTemplate(),
    house: getHouseTemplate(),
  } as unknown as Address)

  const fullAddress = computed(() => {
    return address.countryId === 1 && !address.canBeEmpty
  })

  const rules = {
    typeId: { required: requiredIf(() => fullAddress.value) },
    city: {
      City: { required: requiredIf(() => fullAddress.value) },
    },
    house: {
      Index_: { required: requiredIf(() => fullAddress.value) },
    },
  }

  const v$ = useVuelidate(rules, address)

  return { address, v$, fullAddress }
}

export { useAddress, getHouseTemplate, getStreetTemplate, getCityTemplate }
