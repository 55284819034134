<template>
  <v-row>
    <v-col cols="12" md="12" sm="12">
      <span class="label">Коментар</span>
      <v-textarea
        v-model="inactivation.deactivatedComment"
        placeholder="Напишіть коментар"
        :error-messages="inactiveCommentErr"
        rows="3"
        @blur="getSafe(() => v$.inactivation.deactivatedComment.$touch())">
      </v-textarea>
    </v-col>
  </v-row>
</template>

<script>
import { urlDealerPointInactivation } from '@/pages/request'
import { getSafe, setErrHandler } from '@/utils/helperFunc'
import { minLength, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

export default {
  props: {
    dialog: { type: Object },
  },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      inactivation: {
        deactivatedComment:
          !this.dialog.dialogItem.statusId === 2
            ? { required, minLength: minLength(5) }
            : {},
      },
    }
  },
  data: () => ({
    inactivation: {
      deactivatedComment: null,
      deactivatedDate: null,
      statusId: null,
    },
  }),
  computed: {
    validation() {
      return { v$: this.v$.inactivation, name: 'dealerInactivation' }
    },
    inactiveCommentErr() {
      return setErrHandler(this.v$?.inactivation?.deactivatedComment)
    },
  },
  methods: {
    getSafe,
    submit() {
      const obj = Object.clone(this.inactivation)
      if (this.dialog.dialogItem.statusId === 2) {
        const currentDate = new Date()
        const year = currentDate.getFullYear()
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
        const day = currentDate.getDate().toString().padStart(2, '0')
        obj.deactivatedDate = `${year}-${month}-${day}`
        obj.statusId = this.dialog.dialogItem.statusId
      } else {
        obj.statusId = 1
      }
      return this.$axios
        .post(urlDealerPointInactivation(this.$route.params.id), obj)
        .then(res => {
          this.$setSnackbar({ text: 'Дилера оновлено' })
          return res
        })
    },
  },
}
</script>
<style scoped></style>
