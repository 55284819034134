<template>
  <v-dialog v-model="dialogState" :max-width="540" :value="dialog" persistent>
    <v-card class="dialog-card" style="background-color: #fff !important">
      <v-toolbar
        style="
          color: #08487a;
          font-weight: 700;
          font-size: 16px;
          height: 49px;
          background-color: rgb(255 255 255);
          border-color: rgb(0 0 0);
          box-shadow: none;
        "
        :height="49"
        dark>
        <v-toolbar-title style="font-size: 1.15rem">
          <span>{{ title }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn size="small" icon @click="dialogState = false">
          <v-icon>
            {{ 'mdi-close' }}
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pb-2">
        <v-row>
          <v-col cols="12" md="12" sm="12" class="pb-0">
            <span class="label">Тип</span>
            <v-select
              v-model="entityData.typeId"
              style="margin-top: 5px"
              :items="guarantorTypes"
              :error-messages="typeIdErr"
              item-value="id"
              item-title="text"
              @update:model-value="() => (entityData.id = null)"
              @blur="v$.entityData.typeId.$touch()">
            </v-select>
          </v-col>
          <v-col cols="12" md="12" sm="12" class="pt-0">
            <span class="label">{{
              typeContractor ? 'Контрагент' : 'Контакт'
            }}</span>
            <b-server-autocomplete
              v-model="entityData.id"
              :url="
                typeContractor ? urlGetAllContractors() : urlContactsGetAll()
              "
              class="icon-contact"
              :error-messages="guarantorIdErr"
              item-value="id"
              placeholder="Введіть текст (мін. 3 символи)"
              :item-title="item => getEntityName(item)"
              @blur="v$.entityData.id.$touch()">
            </b-server-autocomplete>
            <v-btn
              variant="outlined"
              class="mb-2"
              style="width: 270px"
              @click="openCreateDialog">
              {{
                typeContractor
                  ? 'Створити нову компанію'
                  : 'Створити новий контакт'
              }}
              <createEntity />
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-space-around pb-2">
        <v-btn
          class="text-white no-transform"
          style="
            width: 45%;
            background: #08487a;
            border-radius: 4px;
            font-weight: 700;
            font-size: 12px;
          "
          :height="33"
          size="small"
          :loading="loading"
          @click="submit">
          Додати
        </v-btn>
        <v-btn
          class="no-transform"
          variant="text"
          style="
            width: 45%;
            color: #08487a;
            font-weight: 700;
            font-size: 12px;
            background-color: #fff;
            border: 2px solid #08487a;
          "
          :height="33"
          size="small"
          @click="dialogState = false">
          Скасувати
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import createEntity from '@/assets/svg/create-entity.vue'
import { required } from '@vuelidate/validators'
import { mapState } from 'vuex'
import {
  setErrHandler,
  v$Notify,
  getEntityName,
  getEntityRoute,
} from '@/utils/helperFunc'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
import { urlContactsGetAll, urlGetAllContractors } from '@/pages/request'
import { BServerAutocomplete } from 'best-modules/components'

export default {
  components: {
    createEntity,
    BServerAutocomplete,
  },
  emits: ['submit', 'update:dialog'],
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  props: {
    dialog: { type: Boolean },
    title: { type: String },
    exclude: {
      type: Object,
      default: () => ({ contacts: [], contractors: [] }),
    },
  },
  validations() {
    return {
      entityData: {
        id: { required },
        typeId: { required },
      },
    }
  },
  data() {
    return {
      loading: false,
      guarantorTypes: [
        { id: 1, text: 'Фізична особа' },
        { id: 2, text: 'Юридична особа' },
      ],
      entityData: {
        id: null,
        typeId: 1,
      },
    }
  },
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    dialogState: {
      get() {
        return this.dialog
      },
      set(v) {
        this.$emit('update:dialog', v)
      },
    },
    filteredItems() {
      const entity = this.typeContractor ? 'contractors' : 'contacts'

      const itemsArr = this.directoryItems(entity)

      return itemsArr.filter(e => {
        return !this.exclude[entity].includes(e.id)
      })
    },
    typeContact() {
      return this.entityData.typeId === 1
    },
    typeContractor() {
      return this.entityData.typeId === 2
    },
    typeFop() {
      return this.entityData.typeId === 3
    },
    typeIdErr() {
      return setErrHandler(this.v$.entityData.typeId)
    },
    guarantorIdErr() {
      return setErrHandler(this.v$.entityData.id)
    },
  },
  methods: {
    urlContactsGetAll,
    urlGetAllContractors,
    getEntityName,
    getEntityRoute,
    submit() {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return v$Notify(this.v$.entityData, 'entityData')
      }
      this.loading = true
      this.$emit('submit', this.entityData)
      this.dialogState = false
      this.entityData.id = null
      this.loading = false
    },
    openCreateDialog() {
      const action = this.typeContractor ? 'newContractor' : 'addContact'
      const title = this.typeContractor ? 'Контрагент' : 'Контакт'
      const dialogItem = this.typeContractor
        ? null
        : {
            surname: null,
            name: null,
            patronymic: null,
            contractorId: null,
          }
      const params = {
        hideContractor: true,
        hideConnections: true,
        cb: res => {
          if (!res) return
          const entityObj = this.typeContractor ? res : res.data
          const entityName = this.typeContractor ? 'Contractors' : 'Contacts'
          const entityArr = JSON.parse(
            JSON.stringify(this.selectItems[entityName.toLowerCase()])
          )

          entityArr.push(entityObj)
          this.$store.commit(`add${entityName}`, entityArr)
          this.entityData.id = entityObj.id
        },
      }

      return this.$store.commit('setDialog', {
        title,
        dialogItem,
        params,
        action,
      })
    },
  },
}
</script>
