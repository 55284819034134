import { style } from '../styles'

const benefitSales = () => [
  {
    margin: [0, 5, 0, 0],
    layout: {
      hLineWidth: () => 0,
      vLineWidth: () => 0,
      minWidth: 595,
    },
    table: {
      widths: ['100%'],
      body: [
        [
          {
            text: 'КОМПЕНСАЦІЯ ВАРТОСТІ ЛІЗИНГОВОЇ ПОСЛУГИ (ВИНАГОРОДИ)',
            fillColor: style.colors.mainRed,
            color: 'white',
            fontSize: 14,
            alignment: 'center',
            bold: true,
            margin: [0, 3, 0, 3],
          },
        ],
      ],
      borderColor: 'white',
    },
  },
  {
    margin: [0, 0, 0, 0],
    layout: {
      hLineWidth: () => 0,
      vLineWidth: () => 0,
      minWidth: 595,
    },
    table: {
      widths: ['77%', '23%'],
      body: [
        [
          {
            text: 'Вартість послуги лізингу (винагороди) включає в себе всі витрати пов`язані з наданням послуг лізингу (вартість реєстрації, ПФ, страхування, вартість фінансування та інші).',
            alignment: 'start',
            lineHeight: 1.2,
            fillColor: '#e1e1e1',
            margin: [10, 10, 10, 0],
          },
          {
            text: '-30%',
            fontSize: 44,
            bold: true,
            color: style.colors.mainRed,
            fillColor: '#e1e1e1',
            alignment: 'end',
            margin: [0, 10, 10, 0],
          },
        ],
      ],
      borderColor: 'white',
    },
  },
  {
    margin: [0, -5, 0, 0],
    layout: {
      hLineWidth: () => 0,
      vLineWidth: () => 0,
      minWidth: 595,
    },
    table: {
      widths: ['100%'],
      body: [
        [
          {
            text: 'Пропозиція дійсна для мікро-, малих та середніх юридичних підприємств, в тому числі ФОП',
            alignment: 'start',
            fillColor: '#e1e1e1',
            margin: [10, 0, 10, 10],
          },
        ],
      ],
      borderColor: 'white',
    },
  },
  {
    margin: [0, 0, 0, 0],
    layout: {
      hLineWidth: () => 0,
      vLineWidth: () => 0,
      minWidth: 595,
    },
    table: {
      widths: ['100%'],
      body: [
        [
          {
            text: 'Ця діяльність стала можливою завдяки підтримці, наданій Агентством США з міжнародного',
            alignment: 'start',
            lineHeight: 1.2,
            fillColor: '#e1e1e1',
            fontSize: 11,
            margin: [10, 0, 10, 0],
          },
        ],
      ],
      borderColor: 'white',
    },
  },
  {
    margin: [0, -4, 0, 0],
    layout: {
      hLineWidth: () => 0,
      vLineWidth: () => 0,
      minWidth: 595,
    },
    table: {
      widths: ['100%'],
      body: [
        [
          {
            text: 'розвитку згідно з умовами гранту BEST LEASING в рамках діяльності USAID з реформування фінансового сектору.',
            alignment: 'start',
            lineHeight: 1.2,
            fillColor: '#e1e1e1',
            fontSize: 11,

            margin: [10, 0, 10, 10],
          },
        ],
      ],
      borderColor: 'white',
    },
  },
]

export { benefitSales }
