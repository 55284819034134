<template>
  <div>
    <LogHistory v-if="contractor" :entityId="contractor.id" :entityTypeId="2" />
  </div>
</template>

<script>
import LogHistory from '@/components/LogHistory.vue'
export default {
  components: { LogHistory },
  props: {
    contractor: { type: Object },
  },
}
</script>

<style scoped></style>
