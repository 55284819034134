const user = {
  state: () => ({}),
  mutations: {
    setUser(state, user) {
      Object.assign(state, user)
    },
    removeUser(state) {
      Object.keys(state).forEach(key => {
        delete state[key]
      })
    },
  },
  actions: {
    //
  },
}

export { user }
