import { InjectionKey, Ref } from 'vue'
import { DkpContract } from '@/utils/types/contract/dkp'
import { CachedObjectProxy } from 'best-modules/utils/cachedObject'
import {
  ValidationRuleWithoutParams,
  ValidationRuleWithParams,
  Validation,
} from '@vuelidate/core'

const contractKey: InjectionKey<CachedObjectProxy<DkpContract>> =
  Symbol('DkpContract')
const updateContractKey: InjectionKey<
  (_contract: Partial<DkpContract>) => void
> = Symbol('updateDkpContract')

const v$Key: InjectionKey<
  Ref<
    Validation<{
      quantityMonthGuarantees: {
        requiredIf: ValidationRuleWithParams<any>
      }
      typeId: {
        required: ValidationRuleWithoutParams<any>
      }
      number: {
        required: ValidationRuleWithoutParams<any>
      }
      date: {
        required: ValidationRuleWithoutParams<any>
        minLength: ValidationRuleWithoutParams<any>
      }
      orderCalculationId: {
        required: ValidationRuleWithoutParams<any>
      }
      dkpBuyerBasisId: {
        required: ValidationRuleWithoutParams<any>
      }
      signatoryId: {
        required: ValidationRuleWithoutParams<any>
      }
      providerSignatories: {
        required: ValidationRuleWithoutParams<any>
        minLength: ValidationRuleWithoutParams<any>
        $each: {
          contactId: {
            required: ValidationRuleWithoutParams<any>
          }
          basisId: {
            required: ValidationRuleWithoutParams<any>
          }
        }
      }
    }>
  >
> = Symbol('v$Dkp')

export { contractKey, updateContractKey, v$Key }
