<template>
  <div>
    <NoData v-if="!contactProjects" />
    <ProjectCard
      v-for="project in filteredProjects"
      :key="project.id"
      :project="project"
      :entityTypeId="1" />
  </div>
</template>
<script>
import ProjectCard from '@/components/ProjectCard.vue'
import NoData from '@/components/no-table-data.vue'
import { mapState } from 'vuex'
export default {
  components: {
    ProjectCard,
    NoData,
  },
  props: { contact: { type: Object } },
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    id() {
      return this.$route?.params?.id
    },
    contactProjects() {
      return this.contact?.projects
    },
    filteredProjects() {
      if (!this.filterDate) {
        return this.contact.projects
      }
      return this.contact.projects.filter(project => {
        const createdAt = new Date(project.created_at)
        return createdAt >= this.filterDate
      })
    },
  },
}
</script>
