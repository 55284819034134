<template>
  <div>
    <span v-if="deleteFrontActivitiesPlan">
      План активностi за менеджером "<span class="underline">{{ manager }}</span
      >" буде видалено!
    </span>
    <div v-if="editFrontActivitiesPlan">
      <div>{{ manager || '---' }}</div>
      <v-row class="pt-1">
        <v-col :cols="12" :sm="3" class="pt-4 pb-0 flex-start">
          <span class="card-label calc">
            {{ ukrMonths[managerPlan.month - 1] }}:
          </span>
        </v-col>
        <v-col :cols="12" :sm="9" class="pt-0 pb-0">
          <v-text-field
            :id="`front-activities-plan`"
            v-model="managerPlan.plan"
            :error-messages="managerPlanErr"
            dense
            hide-details
            @input="inputNumber($event, { float: 2, minValue: 1 })"
            @blur="
              $event => {
                inputNumber($event, { float: 2, saveFloat: true })
                v$.managerPlan.plan.$touch()
              }
            ">
            <template #append>
              <span class="append-text pb-2">грн</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { required } from '@vuelidate/validators'
import { mapState } from 'vuex'

import { setErrHandler } from '@/utils/helperFunc'
import {
  formutSum,
  keepDigit,
  changeInputVal,
  inputNumber,
} from '@/utils/formatFunc'
import {
  ulrPostUpdateFrontActivityPlan,
  urlDeleteFrontActivityPlan,
} from '@/pages/request'
import { useVuelidate } from '@vuelidate/core'
export default {
  props: { dialog: { type: Object } },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      managerPlan: {
        plan: { required },
      },
    }
  },
  data: () => ({ managerPlan: {} }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    deleteFrontActivitiesPlan() {
      return this.dialog.action === 'deleteFrontActivitiesPlan'
    },
    editFrontActivitiesPlan() {
      return this.dialog.action === 'editFrontActivitiesPlan'
    },
    validation() {
      return { v$: this.v$.managerPlan, name: 'frontActivitiesPlan' }
    },
    managerPlanErr() {
      return this.setErrHandler(this.v$.managerPlan.plan)
    },
    manager() {
      const m = this.managerPlan?.manager
      if (!m) return
      return `${m.surname || '-'} ${m.name || '-'} ${m.patronymic || '-'}`
    },
    ukrMonths() {
      return this.selectItems.ukrMonths
    },
  },
  methods: {
    inputNumber,
    formutSum,
    keepDigit,
    changeInputVal,

    setErrHandler,
    submit() {
      switch (true) {
        case this.deleteFrontActivitiesPlan:
          return this.deleteActivitiesPlan()
        case this.editFrontActivitiesPlan:
          return this.editActivitiesPlan()
      }
    },
    editActivitiesPlan() {
      const req = this.managerPlan.plan
      const curPlan = req
      const NumberPlan = curPlan.replace(/\s/g, '')

      const currNumberPlan = Number(NumberPlan)
      return this.$axios.post(
        ulrPostUpdateFrontActivityPlan(this.managerPlan.id),
        {
          plan: currNumberPlan,
          month: this.managerPlan.month,
          year: this.managerPlan.year,
        }
      )
    },
    deleteActivitiesPlan() {
      return this.$axios.delete(urlDeleteFrontActivityPlan(this.managerPlan.id))
    },
  },
  mounted() {
    Object.assign(this.managerPlan, this.dialog.dialogItem)
  },
}
</script>
