<template>
  <span
    class="text-short"
    :style="{ maxWidth: width, fontSize: textBold ? 14 : 12 }">
    <slot name="default"></slot>
  </span>
</template>

<script>
export default {
  props: {
    width: { type: Number, default: 300 },
    textBold: { type: Number, default: 300 },
  },
}
</script>

<style lang="scss" scoped>
.text-short {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  margin-bottom: 5px;
  margin-top: -5px;
  color: #09487a;
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
}
</style>
