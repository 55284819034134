<template>
  <v-row>
    <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
      <span class="label">Назва</span>
      <v-text-field
        v-model="video.name"
        :error-messages="nameErr"
        dense
        hide-details
        placeholder="Введіть текст"
        :readonly="faqDeleteVideo"
        :disabled="faqDeleteVideo"
        @blur="v$.video.name.$touch()">
      </v-text-field>
    </v-col>
    <v-col cols="12" md="12" sm="12" class="pb-0">
      <span class="label">Тип</span>
      <v-select
        v-if="faqCreateVideo"
        v-model="video.typeId"
        :error-messages="typeIdErr"
        :items="types"
        hide-details
        item-title="name"
        placeholder="Оберіть зі списку"
        item-value="id"
        @blur="v$.video.typeId.$touch()">
      </v-select>
    </v-col>
    <v-col
      v-if="video.typeId === 2 && faqCreateVideo"
      cols="12"
      md="12"
      class="pb-0"
      sm="12">
      <span class="label">Посилання на youtube</span>
      <v-textarea
        v-model="video.url"
        :error-messages="urlErr"
        :rows="2"
        hide-details
        placeholder="http://..."
        variant="outlined"
        dense
        @blur="v$.video.url.$touch()">
      </v-textarea>
    </v-col>
    <v-col cols="12" md="12" sm="12" class="pb-0">
      <FilesUploader
        v-if="video.typeId === 1 && faqCreateVideo"
        v-model:documents="video.url"
        label="Відео"
        acceptedExt="video/*"
        single
        @blur="v$.video.url.$touch()">
      </FilesUploader>
    </v-col>
    <h4 v-if="faqDeleteVideo" class="pt-2">Відео буде видалено!</h4>
  </v-row>
</template>
<script>
import FilesUploader from '@/components/FilesUploader.vue'
import { required } from '@vuelidate/validators'

import { setErrHandler } from '@/utils/helperFunc'
import { urlFaqVideoCreate } from '@/pages/request'
import { useVuelidate } from '@vuelidate/core'
export default {
  components: { FilesUploader },
  props: { dialog: { type: Object } },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    const typeId = this.faqCreateVideo ? { required } : {}
    const url = this.faqCreateVideo ? { required } : {}
    const name = this.faqCreateVideo ? { required } : {}

    return {
      video: {
        typeId: typeId,
        url: url,
        name: name,
      },
    }
  },
  data: () => ({
    video: {
      typeId: null,
      url: null,
      name: null,
    },
    types: [
      { name: 'Файл', id: 1 },
      { name: 'Посилання', id: 2 },
    ],
  }),
  computed: {
    faqCreateVideo() {
      return this.dialog.action === 'faqCreateVideo'
    },
    faqDeleteVideo() {
      return this.dialog.action === 'faqDeleteVideo'
    },
    validation() {
      return { v$: this.v$.video, name: 'faqVideo' }
    },
    typeIdErr() {
      return setErrHandler(this.v$?.video?.typeId)
    },
    nameErr() {
      return setErrHandler(this.v$?.video?.name)
    },
    urlErr() {
      return setErrHandler(this.v$?.video?.url)
    },
  },
  methods: {
    submit() {
      const create = () =>
        this.$axios.post(urlFaqVideoCreate(), {
          ...this.video,
          url: this.video.url.url || this.video.url,
        })

      switch (true) {
        case this.faqCreateVideo:
          return create()
        case this.faqDeleteVideo:
          return this.dialog.params.deleteVideo(this.video.id)
      }
    },
  },
  mounted() {
    Object.assign(this.video, this.dialog.dialogItem)
  },
}
</script>
