import { ref } from 'vue'

export function useAdditionalDocs() {
  const updateKey = ref(0)
  const generalObject = {} // Используем пустой объект для хранения состояния скрытия документов

  const updateComponent = () => {
    updateKey.value += 1
  }

  const showDoc = (list, doc) => {
    return list[doc]?.show || !!generalObject[doc]?.length
  }

  const hideDoc = (list, doc) => {
    if (list[doc]) {
      list[doc].show = false
    } else {
      console.error(`document ${doc} not found in list ${list}`)
    }
    generalObject[doc] = null
    updateComponent()
  }

  const setAdditionalDocsState = (lists, exclude = []) => {
    lists.forEach(docs => {
      for (const doc in docs) {
        if (generalObject[doc] && !exclude.includes(doc)) {
          docs[doc].show = true
        }
      }
    })
    updateComponent()
  }

  return {
    updateKey,
    updateComponent,
    showDoc,
    hideDoc,
    setAdditionalDocsState,
    generalObject,
  }
}
