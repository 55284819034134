<template>
  <div>
    <div>Оберіть метод відправки:</div>
    <v-btn
      class="mt-2 d-flex justify-start"
      variant="text"
      block
      @click="$emit('update:model-value', 'viber')">
      <div class="d-flex gap-2">
        <PhoneIcon />
        <div>Соц. мережі</div>
      </div>
    </v-btn>
    <v-btn
      class="mt-2 d-flex justify-start"
      variant="text"
      block
      @click="$emit('update:model-value', 'email')">
      <div class="d-flex gap-2">
        <EmailIcon />
        <div>Email</div>
      </div>
    </v-btn>
  </div>
</template>

<script lang="ts">
import EmailIcon from '@/assets/svg/email-icon.vue'
import PhoneIcon from '@/assets/svg/phone-icon.vue'

export default {
  name: 'SendOfferSelect',
  components: { PhoneIcon, EmailIcon },
  emits: ['update:model-value'],
  props: {
    modelValue: {
      type: String,
    },
  },
}
</script>

<style scoped></style>
