<template>
  <div class="mt-2">
    <log-history
      :entity-id="+$route.params.id"
      :entity-type-id="19"></log-history>
  </div>
</template>

<script>
import LogHistory from '@/components/LogHistory.vue'
export default {
  name: 'DkpHistory',
  components: { LogHistory },
}
</script>

<style scoped></style>
