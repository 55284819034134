<template>
  <div>
    <strong> Калькуляцію буде видалено! </strong>
  </div>
</template>

<script>
import { urlDeleteCalculation } from '@/pages/request'

export default {
  props: {
    dialog: { type: Object },
  },
  methods: {
    urlDeleteCalculation,
    deleteCalculation() {
      return this.$axios
        .delete(
          this.urlDeleteCalculation(this.dialog.dialogItem.calculation.id)
        )
        .then(res => {
          this.$setSnackbar({ text: 'Розрахунок видалено' })
          return res
        })
        .catch(err => this.$err(err))
    },
    submit() {
      return this.deleteCalculation()
    },
  },
}
</script>

<style scoped></style>
