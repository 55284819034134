<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <span class="label">Мідл менеджер</span>
        <v-autocomplete
          v-model="projectMiddleManager.middleManagerResponsibleId"
          :items="selectItems.userRoles.MidlManager.filter(m => m.isActive)"
          :error-messages="responsibleIdErr"
          item-value="id"
          :item-title="item => `${item.surname} ${item.name}`"
          placeholder="Оберіть зі списку"
          @blur="v$.projectMiddleManager.middleManagerResponsibleId.$touch()">
        </v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { required } from '@vuelidate/validators'
import { mapState } from 'vuex'

import { setErrHandler } from '@/utils/helperFunc'
import { urlSetMiddleManager } from '@/pages/request'
import { useVuelidate } from '@vuelidate/core'
import { setSnackbar } from 'best-modules/plugins'
export default {
  props: { dialog: { type: Object } },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      projectMiddleManager: { middleManagerResponsibleId: { required } },
    }
  },
  data: () => ({ projectMiddleManager: {} }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    validation() {
      return { v$: this.v$.projectMiddleManager, name: 'projectMiddleManager' }
    },
    responsibleIdErr() {
      return setErrHandler(
        this.v$.projectMiddleManager.middleManagerResponsibleId
      )
    },
  },
  methods: {
    setErrHandler,
    urlSetMiddleManager,
    submit() {
      return this.$axios
        .post(
          urlSetMiddleManager(this.dialog.params.projectId),
          this.projectMiddleManager
        )
        .then(res => {
          if (res && res.data && res.data.message) {
            setSnackbar({ text: res.data.message, color: 'warning' })
          }
        })
    },
  },
  created() {
    this.$store.dispatch('addUserRoles', {
      key: 'MidlManager',
      roles: ['MidlManager', 'MidlHead'],
    })
    Object.assign(this.projectMiddleManager, this.dialog.dialogItem)
  },
}
</script>
