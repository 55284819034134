import { urlGetAverageMarks, urlGetAverageModels } from '@/pages/request'

const getCarItemsKey = name => {
  if (!name) return null

  if (name.endsWith('y')) {
    return `${name.slice(0, -1)}ies`
  } else {
    return `${name}s`
  }
}

function getCarTopMarks() {
  switch (true) {
    case this.car:
      return [
        'toyota',
        'renault',
        'peugeot',
        'opel',
        'fiat',
        'nissan',
        'mitsubishi',
        'mercedes-benz',
        'lexus',
        'citroen',
        'infiniti',
        'jac',
      ]
    case this.truck:
      return [
        'mercedes-benz',
        'renault',
        'toyota',
        'citroen',
        'peugeot',
        'opel',
        'fiat',
        'nissan',
        'jac',
      ]
    case this.special:
      return ['bobcat', 'caterpillar', 'jcb', 'man', 'mercedes-benz']
    case this.agricultural:
      return ['case ih', 'claas', 'john deere', 'new holland']
    case this.bus:
      return ['ford', 'iveco', 'man', 'mercedes-benz', 'neoplan']
  }
}

function getCarTopBodies() {
  switch (true) {
    case this.special:
      return [
        'Автокран',
        'Бетонозмішувач (Міксер)',
        'Вилочний навантажувач',
        'Гусеничний екскаватор',
        'Колісний екскаватор',
        'Екскаватор навантажувач',
        'Телескопічні навантажувачі',
        'Трактор',
      ]
    case this.agricultural:
      return ['Борона', 'Комбайн', 'Обприскувач', 'Плуг', 'Сівалка', 'Трактори']
    default:
      return []
  }
}

const carSelectOrder = [
  {
    name: 'category',
    order: 1,
  },
  {
    name: 'mark',
    order: 2,
  },
  {
    name: 'gear',
    order: 2,
  },
  {
    name: 'driverType',
    order: 2,
  },
  {
    name: 'model',
    order: 3,
  },
  {
    name: 'body',
    order: 4,
  },
  {
    name: 'year',
    order: 4,
  },
]

const getUrl = (queryName, data) => {
  switch (queryName) {
    case 'category':
      return '/json/averagePrice/list/categories'
    case 'mark':
      if (!data.category?.value) return null
      return urlGetAverageMarks(data?.category?.value)
    case 'model':
      if (!data.category?.value && data.mark?.id) return null
      return urlGetAverageModels(data.category.value, data.mark.id)
    case 'body':
      if (!data.model?.value) return null
      return `/json/averagePrice/list/bodies?model=${data?.model?.value}`
    case 'year':
      if (!data.model?.value) return null
      return `/json/averagePrice/list/years?model=${data?.model?.value}`
    case 'gear':
      if (!data.category?.value) return null
      return `/json/averagePrice/list/gears?category=${data?.category?.value}`
    case 'driverType':
      if (!data.category?.value) return null
      return `/json/averagePrice/list/driverTypes?category=${data?.category?.value}`
    default:
      return ''
  }
}

export {
  getCarItemsKey,
  carSelectOrder,
  getUrl,
  getCarTopBodies,
  getCarTopMarks,
}
