<template>
<svg :width="width" :height="height" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.76483 5.15292L0.721273 0.12331C0.556027 -0.0413831 0.288489 -0.0411062 0.12352 0.124161C-0.0413217 0.289408 -0.0408957 0.557095 0.124372 0.721915L4.86772 5.45217L0.124201 10.1824C-0.0410449 10.3472 -0.0414709 10.6148 0.12335 10.78C0.206047 10.8629 0.314387 10.9043 0.422727 10.9043C0.53079 10.9043 0.638704 10.8632 0.721252 10.7809L5.76483 5.7514C5.84442 5.67221 5.88908 5.56445 5.88908 5.45217C5.88908 5.33989 5.84429 5.23225 5.76483 5.15292Z" 
    :fill="color || 'white'"/>
</svg>
</template>

<script>
export default {
  props: ['width', 'height', 'color']
}
</script>