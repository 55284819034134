<template>
  <div>
    <b-input
      v-model="search"
      placeholder="введіть марку/модель автомобіля"
      prepend-inner-icon="mdi-magnify"></b-input>
    <v-data-table
      v-if="!$loading.isLoading('carForSaleList')"
      v-model="selectedCar"
      :items="carList"
      :headers="carForSaleSelectHeaders"
      hover
      show-select
      return-object
      select-strategy="single"
      item-value="id">
      <template #item.modification="{ item }">
        {{ item.modification?.name || '---' }}
      </template>
    </v-data-table>
    <section-loader v-else></section-loader>
  </div>
</template>

<script lang="ts">
import { computed, ref } from 'vue'
import SectionLoader from '@/components/section-loader.vue'
import { carForSaleSelectHeaders } from '@/utils/tableHeaders'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { fillDirectory, getDirectory } from '@/plugins/directory'

export default {
  name: 'CarForSaleSelect',
  components: { SectionLoader },
  props: {
    dialog: {
      type: Object,
    },
  },
  data: () => {
    return { carForSaleSelectHeaders }
  },
  setup(props) {
    const search = ref('')
    const carList = computed(() => {
      if (!search.value) {
        return getDirectory('carForSaleList')
      } else {
        return getDirectory('carForSaleList').filter(car => {
          return search.value
            .split(' ')
            .filter(Boolean)
            .reduce((acc, cur) => {
              return (
                acc &&
                (car.mark.name.toUpperCase().startsWith(cur.toUpperCase()) ||
                  car.model.name.toUpperCase().startsWith(cur.toUpperCase()))
              )
            }, true)
        })
      }
    })

    const selectedCar = ref(
      [props.dialog.dialogItem.carForSale].filter(Boolean)
    )

    const rules = {
      selectedCar: { required: required },
    }
    const v$ = useVuelidate(rules, { selectedCar })

    fillDirectory('carForSaleList')

    const submit = () => {
      return Promise.resolve(selectedCar.value[0])
    }

    return { carList, selectedCar, submit, search, v$ }
  },
  computed: {
    validation() {
      return { v$: this.v$, name: 'carForSaleSelect' }
    },
  },
}
</script>

<style scoped></style>
