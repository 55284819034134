<template>
  <div>
    <div
      v-for="(contract, idx) in insuranceContracts"
      :key="idx"
      class="d-flex"
      style="padding-bottom: 1rem; margin-bottom: 1rem"
      :style="{
        borderBottom: idx + 1 < insuranceContracts.length ? '1px solid #ccc' : 'none',
      }">
      <InfoField
        v-for="field in contract"
        :key="field.label"
        style="padding-left: 0"
        class="mr-6"
        :field="field" />
    </div>
    <div v-if="!insuranceContracts.length" class="text-center disabled">
      Немає даних для відображення
    </div>
  </div>
</template>

<script>
import InfoField from '@/components/info-fields/Field.vue'
import { toFormatDate } from '@/utils/helperFunc'

export default {
  components: { InfoField },
  props: {
    LO: { type: Object },
  },
  computed: {
    insuranceContracts() {
      return this.LO.ДоговорыСтрахования.map(d => {
        return [
          { label: 'Дата', value: toFormatDate(d.Дата, true) },
          { label: '№ Договору', value: d.Номер },
        ]
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
