<template>
  <div>
    <div class="d-flex align-center justify-end">
      <v-btn size="small" icon @click="toggleBonus('minus')">
        <v-icon>{{ 'mdi-chevron-left' }}</v-icon>
      </v-btn>
      <span class="font-weight-bold">{{ currentFrontBonusYear }}</span>
      <v-btn size="small" icon @click="toggleBonus('plus')">
        <v-icon>{{ 'mdi-chevron-right' }}</v-icon>
      </v-btn>
    </div>
    <FrontBonus :loading="pageLoad" :stats="bonus?.statistics" />
  </div>
</template>

<script>
import { urlGetFrontManagerBonus } from '@/pages/request.js'
import FrontBonus from '@/components/front-bonus/FrontBonus.vue'

export default {
  components: { FrontBonus },
  props: {
    dialog: { type: Object },
  },
  data: () => ({
    currentFrontBonusYear: null,
    bonus: {
      statistics: {},
    },
    pageLoad: false,
  }),
  methods: {
    getBonus(year) {
      this.pageLoad = true

      return this.$axios
        .get(urlGetFrontManagerBonus(this.dialog?.dialogItem?.userId, year))
        .then(res => {
          Object.assign(this.bonus, res.data)
          this.pageLoad = false
          return res
        })
    },
    toggleBonus(action) {
      if (action === 'plus') {
        this.currentFrontBonusYear += 1
        this.getBonus(this.currentFrontBonusYear)
      }

      if (action === 'minus') {
        this.currentFrontBonusYear -= 1
        this.getBonus(this.currentFrontBonusYear)
      }
    },
  },
  created() {
    this.currentFrontBonusYear = new Date().getFullYear()
    this.bonus.statistics = this.dialog?.dialogItem?.bonus?.statistics || {}
  },
}
</script>

<style scoped></style>
