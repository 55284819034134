import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'

const router = createRouter({
  routes,
  history: createWebHistory(),
  linkActiveClass: 'active',
})

router.beforeEach((to, from, next) => {
  // console.groupCollapsed(`[beforeEach] ${from?.name} -> ${to.name}`)
  // console.log('from', from)
  // console.log('to', to)
  // console.groupEnd()
  setTimeout(() => {
    document.title = to.meta.title
  }, 0)
  next()
})

export default router
