<template>
  <div class="background d-flex align-center justify-center">
    <div class="pa-10 d-flex align-center justify-center flex-column">
      <span class="text-white font-weight-bold" style="font-size: 2.5rem">
        {{ pageNotFound }}
      </span>
      <span
        class="text-white cursor-pointer text-decoration-underline"
        style="font-size: 15px"
        @click="$router.replace({ name: 'main' })"
        >Перейти на головну</span
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      pageNotFound: '404 сторінку не знайдено',
      otherError: 'Виникла помилка',
    }
  },
}
</script>
<style scoped>
.background {
  height: 100vh;
  width: 100%;
  background-image: url('../assets/images/background.jpg');
}
</style>
