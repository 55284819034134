import axios from '@/plugins/axios.js'
import { handleAsync } from 'best-modules/plugins'
import { urlCarForSaleCreate } from '@/pages/request'
import { useRouter } from 'vue-router'
import { useCar } from './car'

export function useCarCreate() {
  const router = useRouter()
  const { car, getRequestObject, v$, handleCarApi } = useCar()

  const createCar = () => {
    return handleAsync('carForSaleSubmit', () => {
      return axios.post(urlCarForSaleCreate(), getRequestObject())
    }).then(res => {
      router.push({ name: 'car-for-sale-single', params: { id: res.data.id } })
    })
  }

  const submit = () => {
    v$.value.$validate()
    if (!v$.value.$invalid) {
      return createCar()
    }
  }

  const cancel = () => {
    router.push({ name: 'car-for-sale-list' })
  }

  return { car, v$, submit, cancel, handleCarApi }
}
