<template>
  <div v-if="calculation" style="font-size: 14px">
    <v-row>
      <v-col :cols="12" :sm="4" class="pt-0 pb-1">
        <span class="card-label">Об'єкт лізингу</span>
      </v-col>
      <v-col :cols="12" :sm="8" class="pt-0 pb-1">
        <span>{{ vehicleInfo }}</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col :cols="12" :sm="4" class="pt-0 pb-1">
        <span class="card-label">Умови лізингу</span>
      </v-col>
      <v-col :cols="12" :sm="8" class="pt-0 pb-1">
        <div>
          <span class="card-sublable">Вартість предмета лізингу:</span>
          <span>{{ LOAmountDkp }}</span>
        </div>
        <div>
          <span class="card-sublable">Сума фiнансування:</span>
          <span>{{ LOAmountOfFinancing }}</span>
        </div>
        <div>
          <span class="card-sublable">Аванс:</span>
          <span
            >{{ `${calculation.advance}%` }}
            {{ `(${getBeautyNum(calculation.offerAdvance)} грн)` }}</span
          >
        </div>
        <div>
          <span class="card-sublable">Термін лізингу:</span>
          <span>{{ calculation.leasingTerm }} мiс.</span>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col :cols="12" :sm="4" class="pt-0 pb-1">
        <span>Страхування</span>
      </v-col>
      <v-col :cols="12" :sm="8" class="pt-0 pb-1">
        <div class="d-flex align-center">
          <div class="mr-2">
            <span class="card-sublable">Програма КАСКО:</span>
            <span>
              {{
                (calculation?.insuranceProgramType?.name || '---') +
                ` (${calculation?.insuranceFranchiseType?.name || '0%'})`
              }}
            </span>
          </div>
          <div>
            <span class="card-sublable">Індивідуальні умови страхування:</span>
            <span>{{ calculation?.stockType?.name || '---' }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="d-flex mt-5 mb-5">
      <v-tooltip bottom text="Перейти в калькуляцію">
        <template #activator="{ props }">
          <v-btn
            icon
            class="mb-2 mr-2"
            v-bind="props"
            @click="pushToCalculation">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none">
              <path
                d="M7.5 3H4.5V0H3V3H0V4.5H3V7.5H4.5V4.5H7.5V3ZM0 11.25H7.5V12.75H0V11.25ZM0 15H7.5V16.5H0V15ZM10.5 3H18V4.5H10.5V3ZM15.3075 13.5L18 10.8075L16.9425 9.75L14.25 12.4425L11.5575 9.75L10.5 10.8075L13.1925 13.5L10.5 16.1925L11.5575 17.25L14.25 14.5575L16.9425 17.25L18 16.1925L15.3075 13.5Z"
                fill="#FC7247" />
            </svg>
          </v-btn>
        </template>
        <span>Перейти в калькуляцію</span>
      </v-tooltip>
    </div>
  </div>
</template>
<script>
import {
  getBeautyNum,
  LODescription as getLoDescription,
} from '@/utils/helperFunc'
import { formutSum } from '@/utils/formatFunc'
import { urlKlpReworkCalculation } from '@/pages/request'
import { getDirectory } from '@/plugins/directory/index.ts'
export default {
  props: {
    applicationId: { type: Number },
    projectId: { type: Number },
    calculation: { type: Object },
    readonly: { type: Boolean, default: false },
  },
  computed: {
    LO() {
      return getLoDescription(this.calculation)
    },
    vehicleInfo() {
      const newVeh = this.calculation?.isNew ? 'Новий' : 'Вживаний'
      const vehType =
        this.calculation?.leasingObjectType?.name ||
        this.calculation?.car_type?.name ||
        'Невідомий тип'
      const vehName =
        this.calculation?.leasingObjectName ||
        this.calculation?.requestData?.leasingObjectName ||
        'Невідома назва'
      const vehYear =
        this.calculation?.leasingObjectYear ||
        this.calculation?.requestData?.leasingObjectYear ||
        'невідомого'
      return (
        newVeh +
        ' ' +
        vehType.toLowerCase() +
        ' ' +
        vehName +
        ' ' +
        vehYear +
        ' року'
      )
    },
    LOAmountOfFinancing() {
      const amount = this.calculation?.amountFinancing

      const formattedAmount = formutSum(amount?.toFixed())
      const formattedFundRaising = formutSum(
        (amount / this.fundRaising)?.toFixed()
      )

      return `${formattedAmount} (${formattedFundRaising} $)`
    },
    fundRaising() {
      if (!getDirectory('nbuCurrencyCourses').length) return
      const currency = getDirectory('nbuCurrencyCourses').find(cur => {
        return cur.currency.name === 'USD'
      })
      if (currency) return currency.rate
      return 0
    },
    LOAmountDkp() {
      const amount = this.calculation?.totalAmount
      if (!amount || !Number.isInteger(amount)) return
      return `${formutSum(amount?.toFixed())} (${formutSum(
        (amount / this.fundRaising)?.toFixed()
      )} $)`
    },
  },
  methods: {
    getBeautyNum,
    urlKlpReworkCalculation,
    pushToCalculation() {
      const route = this.$router.resolve({
        name: 'application-edit-calculation',
        params: {
          calculationId: this.calculation.id,
          applicationId: this.applicationId,
        },
        query: { frame: true },
      })

      window.open(
        route.href,
        'Калькуляцiя',
        '`menubar=no,location=no,resizable=yes,scrollbars=yes,status=yes,width=900,height=550,left=100,top=20`'
      )
    },
    reworkCalculation() {
      return this.$setSnackbar({
        text: 'Функтіонал тимчасово не доступний',
        color: 'warning',
      })
      // const calculationObj = {
      //   url: this.urlKlpReworkCalculation(this.projectId),
      //   calculationId: this.calculation.id,
      //   name: 'calculation',
      // }
      // return this.$store.commit('setDialog', {
      //   title: `Відправити на доопрацювання`,
      //   dialogItem: {calculationObj},
      //   params: {
      //     cb: () => this.$emit('dataChanged'),
      //   },
      //   action: 'sendToRevision',
      // })
    },
  },
  mounted() {
    if (!this.calculation)
      this.$router.push({
        name: 'leasing-committee-meeting',
        params: { id: this.$route.params.id },
      })
  },
}
</script>

<style scoped>
.card-label {
  color: #000 !important;
}
.card-sublable {
  color: #838282;
  font-weight: 500;
  font-size: 13px;
}
</style>
