<template>
  <div style="display: inline-block">
    <v-menu
      v-if="menu"
      transition="slide-y-transition"
      offset-y
      bottom
      :close-on-content-click="false">
      <template #activator="{ props }">
        <v-btn
          :elevation="elevation"
          icon
          density="comfortable"
          class="rounded-circle"
          :disabled="disabled"
          :size="small ? 'small' : 'default'"
          v-bind="props">
          <component :is="customIcon" v-if="customIcon"></component>
          <v-icon v-else-if="icon" style="color: #fc7247">
            {{ icon }}
          </v-icon>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none">
            <path
              d="M6 8C6 8.53043 6.21071 9.03914 6.58579 9.41421C6.96086 9.78929 7.46957 10 8 10C8.53043 10 9.03914 9.78929 9.41421 9.41421C9.78929 9.03914 10 8.53043 10 8C10 7.46957 9.78929 6.96086 9.41421 6.58579C9.03914 6.21071 8.53043 6 8 6C7.46957 6 6.96086 6.21071 6.58579 6.58579C6.21071 6.96086 6 7.46957 6 8ZM6 2C6 2.53043 6.21071 3.03914 6.58579 3.41421C6.96086 3.78929 7.46957 4 8 4C8.53043 4 9.03914 3.78929 9.41421 3.41421C9.78929 3.03914 10 2.53043 10 2C10 1.46957 9.78929 0.960859 9.41421 0.585786C9.03914 0.210714 8.53043 0 8 0C7.46957 0 6.96086 0.210714 6.58579 0.585786C6.21071 0.960859 6 1.46957 6 2ZM6 14C6 14.5304 6.21071 15.0391 6.58579 15.4142C6.96086 15.7893 7.46957 16 8 16C8.53043 16 9.03914 15.7893 9.41421 15.4142C9.78929 15.0391 10 14.5304 10 14C10 13.4696 9.78929 12.9609 9.41421 12.5858C9.03914 12.2107 8.53043 12 8 12C7.46957 12 6.96086 12.2107 6.58579 12.5858C6.21071 12.9609 6 13.4696 6 14Z"
              fill="#FC7247" />
          </svg>
        </v-btn>
      </template>
      <v-card>
        <v-card-text class="pa-0">
          <v-list dense>
            <v-list-group
              v-for="(item, key) in actionsArray.filter(
                item => item.statement || !item.hasOwnProperty('statement')
              )"
              :key="key"
              :append-icon="item.actions ? '$expand' : ''"
              :disabled="item.disabled || asyncLoading(item.text)">
              <template #activator="{ props }">
                <v-list-item
                  style="padding: 0 15px; min-height: 32px !important"
                  v-bind="props"
                  density="compact"
                  class="cursor-pointer"
                  :append-icon="item.actions ? '$expand' : ''"
                  :disabled="item.disabled || asyncLoading(item.text)"
                  @click="event => handleItemAction(item, event)">
                  <div class="mt-0 mr-0">
                    <Loader v-if="asyncLoading(item.text)" :size="20" />
                    <component
                      :is="item.icon"
                      v-else-if="item.customIcon"
                      :color="item.color" />
                    <v-icon v-else :color="item.color || 'grey'" size="small">
                      {{ item.icon }}
                    </v-icon>
                  </div>
                  <div>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </div>
                </v-list-item>
              </template>
              <v-list-item
                v-for="(subItem, idx) in item.actions"
                :key="`sub_${idx}`"
                class="cursor-pointer"
                density="compact"
                style="padding: 0 15px; min-height: 32px !important"
                :append-icon="item.actions ? '$expand' : ''"
                :disabled="subItem.disabled || asyncLoading(subItem.text)"
                @click="event => handleItemAction(subItem, event)">
                <div class="mt-0 mr-0">
                  <Loader v-if="asyncLoading(subItem.text)" :size="20" />
                  <component
                    :is="subItem.icon"
                    v-else-if="subItem.customIcon"
                    size="x-small"
                    :color="item.color" />
                  <v-icon
                    v-else
                    :color="item.color"
                    :x-small="small"
                    :small="!small">
                    {{ subItem.icon }}
                  </v-icon>
                </div>
                <div>
                  <v-list-item-title>{{ subItem.text }}</v-list-item-title>
                </div>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>
    <div v-if="!menu">
      <div
        v-for="(item, key) in actionsArray"
        :key="key"
        class="d-inline-block pl-1">
        <v-tooltip color="grey darken-3" bottom :text="item.text">
          <template #activator="{ props }">
            <v-btn
              size="x-small"
              icon
              class="rounded-circle"
              density="comfortable"
              :width="25"
              :height="25"
              :disabled="disabled || item.disabled"
              :small="small"
              v-bind="props"
              @click="item.action(item, $event)">
              <v-icon size="18" icon :small="small" :color="item.color">
                {{ item.icon }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ item.text }}</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>
<script>
import ServiceIcon from '@/assets/svg/serviceIcon.vue'
import AgreementIcon from '@/assets/svg/agreementIcon.vue'
import DeleteIcon from '@/assets/svg/delete-icon.vue'
import EditPencil from '@/assets/svg/edit-pencil.vue'
import AddIcon from '@/assets/svg/add-circle.vue'
import Loader from '@/components/Loader.vue'
import Printer from '@/assets/svg/Printer-icon.vue'
import EyeIcon from '@/assets/svg/eye-icon.vue'
import ReminderIcon from '@/assets/svg/reminder-icon.vue'
import SendSmsIcon from '@/assets/svg/send-sms-icon.vue'
import { useSelect } from '@/utils/mixins/useSelect'
export default {
  name: 'TableCrud',
  components: {
    Loader,
    EyeIcon,
    SendSmsIcon,
    ReminderIcon,
    Printer,
    AddIcon,
    EditPencil,
    DeleteIcon,
    AgreementIcon,
    ServiceIcon,
  },
  setup() {
    return {
      ...useSelect(),
    }
  },
  props: {
    actionsArray: {
      type: Array,
      default: () => [
        { action: () => false, text: 'Переглянути', icon: 'mdi-eye' },
      ],
    },
    addList: { type: Boolean, default: true },
    customIcon: { type: String },
    menu: { type: Boolean, default: true },
    color: { type: String },
    disabled: { type: Boolean },
    fab: { type: Boolean },
    small: { type: Boolean, default: false },
    icon: { type: String, default: 'mdi-dots-vertical' },
    elevation: { type: [String, Number], default: 0 },
  },
  methods: {
    handleItemAction(item, event) {
      if (item.disabled) return
      if (!item.actions) {
        this.asyncAction(item.text, null, () => item.action(item, event))
      }
    },
  },
}
</script>
