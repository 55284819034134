<template>
  <div>
    <div class="white-block" style="margin-bottom: 10px; margin-top: 10px">
      <v-row v-if="certificateOfRegistrationDocument">
        <v-col :cols="12" class="pt-0 pb-0">
          <span class="card-label">Свідоцтво про реєстрацію</span>
          <div v-if="Object.keys(certificateOfRegistrationDocument).length > 0">
            <FilesUploader
              v-for="doc in certificateOfRegistrationDocument"
              :key="doc.id"
              :label="doc.text"
              readonly
              :documents="doc" />
          </div>

          <span v-else style="color: #989898" class="card-label d-block"
            >Документи відсутні</span
          >
        </v-col>
      </v-row>
      <v-divider style="margin: 10px 0"></v-divider>
      <v-row v-if="deedOfTransferUnderContractDocument">
        <v-col :cols="12" class="pt-0 pb-0">
          <span class="card-label">Акт прийому-передачі договору</span>
          <div
            v-if="Object.keys(deedOfTransferUnderContractDocument).length > 0">
            <FilesUploader
              v-for="doc in deedOfTransferUnderContractDocument"
              :key="doc.id"
              :label="doc.text"
              readonly
              :documents="doc" />
          </div>
          <span v-else style="color: #989898" class="card-label d-block"
            >Документи відсутні</span
          >
        </v-col>
      </v-row>
      <v-divider style="margin: 10px 0"></v-divider>
      <v-row v-if="deedOfTransferUnderDkpDocument">
        <v-col :cols="12" class="pt-0 pb-0">
          <span class="card-label">Акт прийому-передачі ДКП</span>
          <div v-if="Object.keys(deedOfTransferUnderDkpDocument).length > 0">
            <FilesUploader
              v-for="doc in deedOfTransferUnderDkpDocument"
              :key="doc.id"
              :label="doc.text"
              readonly
              :documents="doc" />
          </div>
          <span v-else style="color: #989898" class="card-label d-block"
            >Документи відсутні</span
          >
        </v-col>
      </v-row>
      <v-divider style="margin: 10px 0"></v-divider>
      <v-row v-if="expenditureOnDkpDocument">
        <v-col :cols="12" class="pt-0 pb-0">
          <span class="card-label">Витрати на ДКП</span>
          <div v-if="Object.keys(expenditureOnDkpDocument).length > 0">
            <FilesUploader
              v-for="doc in expenditureOnDkpDocument"
              :key="doc.id"
              :label="doc.text"
              readonly
              :documents="doc" />
          </div>
          <span v-else style="color: #989898" class="card-label d-block"
            >Документи відсутні</span
          >
        </v-col>
      </v-row>
      <v-divider style="margin: 10px 0"></v-divider>
      <v-row v-if="photoOlDocument">
        <v-col :cols="12" class="pt-0 pb-0">
          <span class="card-label">Фото обʼєкта лізинга</span>
          <OpenableList
            v-if="photoOlDocument.length"
            :title="`фото (${photoOlDocument?.length || 0}шт.)`"
            style="margin-left: -5px">
            <FilesUploader
              v-for="doc in photoOlDocument"
              :key="doc.id"
              :label="doc.text"
              readonly
              :documents="doc" />
          </OpenableList>
          <span v-else style="color: #989898" class="card-label d-block"
            >Документи відсутні</span
          >
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import FilesUploader from '@/components/FilesUploader.vue'
import OpenableList from '@/components/OpenableList.vue'

export default {
  components: { OpenableList, FilesUploader },
  props: {
    DocData: { type: Object },
  },
  computed: {
    oneC() {
      return this.DocData?.OneCDocuments
    },
    certificateOfRegistrationDocument() {
      return this.oneC?.certificateOfRegistrationDocument || {}
    },
    deedOfTransferUnderContractDocument() {
      return this.oneC?.deedOfTransferUnderContractDocument || {}
    },
    deedOfTransferUnderDkpDocument() {
      return this.oneC?.deedOfTransferUnderDkpDocument || {}
    },
    expenditureOnDkpDocument() {
      return this.oneC?.expenditureOnDkpDocument || {}
    },
    photoOlDocument() {
      return this.oneC?.photoOlDocument?.doc || []
    },
  },
}
</script>

<style scoped></style>
