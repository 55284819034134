<template>
  <div>
    <v-row class="pr-3">
      <v-col :cols="12" :sm="6">
        <LeasingParticipant
          v-model:signatoryId="agreementData.creditorSignatoryId"
          v-model:basisId="agreementData.creditorBasisId"
          label="Заставоутримувач (Кредитор)"
          :typeId="2"
          :participant="agreementData.creditor"
          :readonly="readonly"
          :v$="{
            contactId: $v.agreement.creditorSignatoryId,
            basisId: $v.agreement.creditorBasisId,
          }"
          single />
      </v-col>

      <v-col :cols="12" :sm="6" :class="`${mobile ? '' : 'pl-0'}`">
        <LeasingParticipant
          v-model:signatories="agreementData.mortgagorSignatories"
          label="Заставодавець"
          :typeId="agreementData.mortgagorTypeId"
          :participant="agreementData.mortgagor"
          :readonly="readonly"
          :v$="$v.agreement.mortgagorSignatories" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import LeasingParticipant from '@/pages/contracts/components/leasingParticipant/LeasingParticipant.vue'
import { mapState } from 'vuex'
export default {
  components: { LeasingParticipant },
  props: {
    agreement: { type: Object },
    v: { type: Object },
    readonly: { type: Boolean },
    mobile: { type: Boolean },
  },
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    agreementData() {
      return this.agreement
    },
    $v() {
      return this.v
    },
  },
}
</script>
