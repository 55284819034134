<template>
  <div>
    <SectionLoader v-if="!calculation" />
    <div v-else>
      <LeasingObjectHeader
        :calculation="calculation"
        :slider="slider"
        :project="project"
        :readonly="readonly" />
      <LeasingObjectCard
        :showFields="[
          'leasingObjectPhotos',
          'agreedMarketValuePL',
          'specificationDocuments',
        ]"
        :calculation="calculation"
        :readonly="readonly">
      </LeasingObjectCard>
    </div>
  </div>
</template>

<script>
import LeasingObjectCard from '@/components/LeasingObjectCard.vue'
import SectionLoader from '@/components/section-loader.vue'
import { mapState } from 'vuex'
import {
  getEntityRoute,
  getBeautyNum,
  getResponsibleShortName,
  openDocument,
  getSafe,
} from '@/utils/helperFunc'
import { urlGetProjectCalculation } from '@/pages/request'
import LeasingObjectHeader from '@/pages/projects/tabs/leasing-objects/LeasingObjectHeader.vue'

export default {
  components: {
    LeasingObjectHeader,
    LeasingObjectCard,
    SectionLoader,
  },
  props: {
    calculationId: { type: Number },
    readonly: { type: Boolean },
    calculationSingle: { type: Array },
  },
  data() {
    return {
      leasingObjectDialog: null,
      calculation: null,
      docsLoading: false,
      fav: true,
      menu: false,
      message: false,
      hints: true,
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      slider: state => state.projects.slider,
      project: state => state.projects.project,
    }),
    contracts() {
      return {
        contractId:
          this.project.contracts.find(
            d => d.calculationId === this.calculation?.id
          )?.id || null,
        dkpId:
          this.project.dkps.find(d => d.calculationId === this.calculation?.id)
            ?.id || null,
      }
    },
    contractId() {
      if (this.project.contracts) {
        return this.project.contracts.some(
          d => d.calculationId === this.calculationId
        )
      } else return null
    },
  },
  methods: {
    getSafe,
    getEntityRoute,
    getBeautyNum,
    openDocument,
    getResponsibleShortName,
    getCalculationById() {
      if (!this.calculation) {
        return this.$axios(urlGetProjectCalculation(this.calculationId)).then(
          res => {
            const obj = res.data
            obj.exportData = {
              export: {
                'offer-advance': obj.offerAdvance,
                'offer-advance-per': obj.offerAdvancePer,
                'leasing-amount-dkp': obj.leasingAmountDkp,
                'offer-price-netto': obj.offerPriceNetto,
                'total-amount': obj.totalAmount,
              },
            }
            obj.calcRequestData = {
              'insurance-program': obj.insuranceProgram,
              'pre-financing-days': obj.preFinancingDays,
              'pre-financing-amount': obj.preFinancingAmount,
              'assist-service': obj.assistService,
            }
            this.calculation = obj
          }
        )
      }
    },
  },
  created() {
    this.getCalculationById()
  },
}
</script>

<style scoped lang="scss">
.leasing-object {
  &__cell {
    position: relative;
  }
  &__dialog {
    min-width: 600px;
    position: absolute;
    cursor: default;
    right: 0;
    top: -35px;
    z-index: 10000;
  }
}
.conclusion {
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.result-block {
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  z-index: 10000;
  width: 65%;
  height: auto;
  -webkit-box-shadow: 0 0 10px -8px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0 0 10px -8px rgba(0, 0, 0, 1);
  box-shadow: 0 0 10px -8px rgba(0, 0, 0, 1);
}
</style>
