import axios from 'axios'
import store from '@/store/index.js'
import check from '@/utils/httpHandler'
import { setSnackbar } from 'best-modules/plugins'

function setInterceptor() {
  axios.interceptors.request.use(
    // before request is sent
    req => {
      const token = store.state.auth.token

      if (token && !req.headers.Authorization) {
        req.headers['Authorization'] = `Bearer ${token}`
      }

      return req
    },
    // request err
    err => {
      return Promise.reject(err)
    }
  )

  axios.interceptors.response.use(
    // any status in range 200 trigger res
    res => {
      return check(res)
    },
    // any status outside range 200 trigger res error
    err => {
      // let e = catchErr(err)
      // if (![401, 244, 422].includes(err.response.status)) {
      //   snotify.simple(e.message, e.title)
      // }
      if (
        err.response?.data?.message &&
        !(location.pathname === '/' && err.config.url === '/json/getUserData')
      ) {
        setSnackbar({ text: err.response.data.message, color: 'error' })
      }

      const host = new URL(location.href).origin
      const status = err.response?.status
      if (
        (status === 401 || status === 419) &&
        location.href !== host + '/' &&
        location.href !== host &&
        location.href !== host + '/recovery-password'
      ) {
        store.commit('removeToken')
        store.commit('removeQrCode')
        store.commit('removeAuthUser')
        location.replace(new URL(location.href).origin)
      }

      return Promise.reject({ ...err, isAxiosError: true })
    }
  )
  return axios
}

export default setInterceptor()
