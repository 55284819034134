<template>
  <v-col :cols="12" :md="12" class="pa-0 pr-3 common-data">
    <v-card v-if="vCard" class="mb-3" :elevation="0" style="position: relative">
      <slot></slot>
    </v-card>
    <div v-else>
      <slot></slot>
    </div>
  </v-col>
</template>

<script>
export default {
  props: {
    vCard: { type: Boolean, default: true }
  }
}
</script>

<style scoped>

</style>