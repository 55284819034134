<template>
  <div>
    <div v-if="changePrice">
      <v-row class="mt-2 align-center">
        <v-col :cols="12" :sm="5" class="pt-0 pb-0 flex-center">
          <span
            class="card-label calc"
            :class="{ 'required-value': !!!calculation.leasingAmount }">
            Цiна об'єкту за ДФЛ
          </span>
        </v-col>
        <v-col :cols="12" :sm="7" class="pt-0 pb-0">
          <v-row class="row">
            <v-col :cols="12" :sm="7" class="pt-0 pb-0 pr-0">
              <v-text-field
                id="dfl-obj-price"
                v-model="calculation.leasingAmount"
                :error-messages="leasingAmountErr"
                class="pt-0 mt-0"
                placeholder="Вкажіть вартiсть"
                hide-details
                @input="
                  $event => {
                    inputNumber($event, { float: 2, minValue: 1 })
                    calculation.leasingAmountDkp = dkpWithDiscountSum
                  }
                "
                @blur="
                  $event => {
                    inputNumber($event, { float: 2, saveFloat: true })
                    v$.calculation.leasingAmount.$touch()
                  }
                "
                @focusin="Input.hideData('dfl-obj-price')"
                @focusout="Input.restoreData('dfl-obj-price')">
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="calculation.leasingAmount" class="mt-2 align-center">
        <v-col :cols="12" :sm="5" class="pt-0 pb-0 flex-center">
          <span class="card-label calc">Ціна об'єкту за ДКП</span>
        </v-col>
        <v-col :cols="12" :sm="7" class="pt-0 pb-0">
          <v-row class="row">
            <v-col :cols="12" :sm="7" class="pt-0 pb-0 pr-0">
              <v-text-field
                id="dflDkpLeasAmDkp"
                v-model="calculation.leasingAmountDkp"
                class="pt-0 mt-0"
                placeholder="цiна"
                :disabled="!calculation.leasingAmount"
                dense
                hide-details
                @input="
                  $event => {
                    inputNumber($event, {
                      float: 2,
                      minValue: 1,
                      maxValue: dflAmountNum,
                    })
                    discountNum <= 15 && (calculation.dkpDiscount = discountNum)
                  }
                "
                @blur="
                  inputNumber($event, {
                    minValue: dkpMinNum,
                    float: 2,
                    saveFloat: true,
                  })
                "
                @focusin="Input.hideData('dflDkpLeasAmDkp')"
                @focusout="Input.restoreData('dflDkpLeasAmDkp')">
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div v-if="changeRate">
      <v-row class="align-center">
        <v-col :cols="12" :sm="5" class="pt-0 pb-0 flex-end">
          <span
            class="card-label calc"
            :class="{ 'required-value': !!!calculation.leasingCurrencyCourse }">
            Старий курс
          </span>
        </v-col>
        <v-col :cols="12" :sm="7" class="pt-0 pb-0">
          <v-text-field
            :model-value="oldRate"
            placeholder="курс"
            disabled
            dense
            hide-details>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col :cols="12" :sm="5" class="pt-0 pb-0 flex-end">
          <span
            class="card-label calc"
            :class="{ 'required-value': !!!calculation.leasingCurrencyCourse }">
            Новий курс
          </span>
        </v-col>
        <v-col :cols="12" :sm="7" class="pt-0 pb-0">
          <v-text-field
            v-model="calculation.leasingCurrencyCourse"
            :error-messages="leasingCurrencyCourseErr"
            class="pt-0 mt-0"
            placeholder="курс"
            dense
            hide-details
            @input="
              inputNumber($event, { float: 4, minValue: 1, rankSpacing: false })
            "
            @blur="
              $event => {
                inputNumber($event, { float: 4, saveFloat: true })
                v$.calculation.leasingCurrencyCourse.$touch()
              }
            ">
          </v-text-field>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { urlDuChangeCalculation } from '@/pages/request'
import { keepDigit, humanDate, inputNumber } from '@/utils/formatFunc'
import {
  Input,
  setErrHandler,
  objClearEpmtyField,
  getBeautyNum,
} from '@/utils/helperFunc'
import { required } from '@vuelidate/validators'
import { toClientInt, toInt } from '@/pages/calculations/object-properties'
import { useVuelidate } from '@vuelidate/core'

export default {
  setup() {
    return { v$: useVuelidate() }
  },
  props: {
    dialog: { type: Object },
  },
  validations() {
    const leasingAmount = this.changePrice ? { required } : {}
    const leasingCurrencyCourse = this.changeRate ? { required } : {}
    return {
      calculation: { leasingAmount, leasingCurrencyCourse },
    }
  },
  data() {
    return {
      calculation: {},
      Input: new Input(),
      oldRate: null,
      calculationId: null,
    }
  },
  computed: {
    validation() {
      return { v$: this.v$.calculation, name: 'calculation' }
    },
    changeRate() {
      return this.dialog.action === 'calculationChangeRate'
    },
    changePrice() {
      return this.dialog.action === 'calculationChangePrice'
    },
    dflAmountNum() {
      return parseFloat(this.keepDigit(this.calculation.leasingAmount || 0))
    },
    dkpWithDiscountNum() {
      const dkpDiscountNum = parseFloat(this.calculation.dkpDiscount)
      return (
        this.dflAmountNum -
        (dkpDiscountNum
          ? ((this.dflAmountNum / 100) * dkpDiscountNum).toFixed(2)
          : 0)
      )
    },
    dkpWithDiscountSum() {
      return getBeautyNum(this.dkpWithDiscountNum, { float: 2 })
    },
    dkpMinNum() {
      return this.dflAmountNum - ((this.dflAmountNum / 100) * 15).toFixed(2)
    },
    dkpAmountNum() {
      return parseFloat(this.keepDigit(this.calculation.leasingAmountDkp || 0))
    },
    discountNum() {
      return 100 - ((this.dkpAmountNum || 0) / (this.dflAmountNum || 0)) * 100
    },
    leasingAmountErr() {
      return setErrHandler(this.v$.calculation.leasingAmount)
    },
    leasingCurrencyCourseErr() {
      return setErrHandler(this.v$.calculation?.leasingCurrencyCourse)
    },

    // vat calculation
    vatValue() {
      if (!this.calculation.isVat) return 0
      else return parseFloat(keepDigit(this.totalPriceSum) / 6)
    },
    totalPriceSum() {
      return (
        this.dflPriceNum +
        // parseFloat(keepDigit(this.calculation.trailersSum || 0)) +
        parseFloat(keepDigit(this.calculation.equipSumWithDiscount || 0))
      )
        .toLocaleString('ru-RU')
        .split(',')
        .join('.')
    },
    dflPriceNum() {
      return this.dflAmountNum * this.leasingCurrencyCourseNum
    },
    leasingCurrencyCourseNum() {
      return parseFloat(this.calculation.leasingCurrencyCourse)
    },
  },
  methods: {
    keepDigit,
    humanDate,
    objClearEpmtyField,
    inputNumber,
    submit() {
      const request = this.parseObject(this.calculation)
      request.calculationId = this.dialog.dialogItem.id

      return this.$axios
        .post(urlDuChangeCalculation(this.calculationId), request)
        .then(res => {
          const notifyMsg = this.changeRate
            ? 'Курс валют змінено'
            : "Вартість об'єкта змінено"
          this.$setSnackbar({ text: notifyMsg })
          return res.data
        })
    },
    parseObject(dataObj, init) {
      const obj = this.objClearEpmtyField(dataObj)
      this.handleProperties(
        obj,
        init ? this.parseToString : this.parseToInt,
        init ? toClientInt : toInt
      )
      if (init) {
        this.oldRate = obj.leasingCurrencyCourse
      }
      return obj
    },
    handleProperties(o, cb, propsToType) {
      if (typeof cb !== 'function') return
      for (const prop of propsToType) {
        const propName = prop.name || prop
        if (o[propName] || o[propName] === 0 || o[propName] === '0') {
          o[propName] = cb(o[propName], prop.float)
        }
      }
      return o
    },
    parseToString(prop, float) {
      return getBeautyNum(prop, { float: float || 'auto' })
    },
    parseToInt(prop) {
      return parseFloat(this.keepDigit(prop))
    },
  },
  watch: {
    vatValue: function (val) {
      this.calculation.vat = this.calculation.isVat ? val : null
    },
  },
  created() {
    this.calculationId = this.dialog.dialogItem.id
    this.calculation = this.parseObject(
      this.dialog.dialogItem.requestData,
      true
    )
  },
}
</script>

<style lang="scss" scoped></style>
