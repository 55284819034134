<template>
  <div>
    <div class="white-block" style="margin-bottom: 10px; margin-top: 10px">
      <v-row v-if="contract">
        <v-col :cols="12" :sm="12" class="pt-1 pb-1 text-start">
          <span class="card-value">{{
            contract?.type?.id === 1
              ? 'Договір фінансового лізингу'
              : 'Договір оперативного лізингу'
          }}</span>

          <div class="d-flex flex-column">
            <div>
              <span class="card-value">
                <ALink
                  :route="{ name: 'dfl-contract', params: { id: contract.id } }"
                  :text="contract.number" />
              </span>
              <span class="card-label"
                >({{ contract?.status?.name || '---' }})</span
              >
            </div>
            <div></div>
          </div>
          <div style="margin-left: -5px">
            <div v-if="contract.signedContract">
              <div v-for="(doc, idx) in contract.signedContract" :key="idx">
                <FilesUploader :label="doc.text" :documents="doc" readonly />
              </div>
            </div>
            <span v-else style="padding-left: 6px" class="card-label"
              >Документи відсутні</span
            >
          </div>
        </v-col>
      </v-row>
      <v-divider style="margin: 10px 0"></v-divider>
      <v-row v-if="dkp">
        <v-col :cols="12" :sm="12" class="pt-1 pb-1 text-start">
          <span class="card-value">Договір купівлі-продажу</span>

          <div class="d-flex flex-column">
            <div>
              <span class="card-value">
                <ALink
                  :route="{ name: 'dkp-contract', params: { id: dkp.id } }"
                  :text="`№${dkp.number}`" />
              </span>
              <span class="card-label">({{ dkp?.status?.name || '---' }})</span>
            </div>
            <div></div>
          </div>
          <div style="margin-left: -5px">
            <div v-if="dkp.signedDkp">
              <div v-for="(doc, idx) in dkp.signedDkp" :key="idx">
                <FilesUploader :label="doc.text" :documents="doc" readonly />
              </div>
            </div>
            <span v-else style="padding-left: 6px" class="card-label"
              >Документи відсутні</span
            >
          </div>
        </v-col>
      </v-row>
      <v-divider style="margin: 10px 0"></v-divider>
      <v-row v-if="extraAgreements.length">
        <div class="d-flex flex-column" style="width: 100%">
          <span style="padding-left: 12px" class="card-value"
            >Додаткова угода</span
          >
          <v-col :cols="12" :sm="12" class="pt-1 pb-1 text-start">
            <div
              v-for="(ex, idx) in extraAgreements"
              :key="ex.id"
              class="d-flex">
              <div style="width: 100%">
                <div class="d-flex align-center">
                  <span style="margin-right: 10px" class="card-label"
                    >№ {{ idx + 1 }}</span
                  >
                  <div>
                    <span class="card-value">
                      <ALink
                        :route="{
                          name: 'du-dfl-agreement',
                          params: { id: ex.id },
                        }"
                        :text="ex.number" />
                    </span>
                    <span class="card-label"
                      >({{ ex?.status?.name || '---' }})</span
                    >
                  </div>
                </div>
                <div style="margin-left: -5px; width: 100%">
                  <div v-if="ex.signedExtraAgreement">
                    <div
                      v-for="(doc, idx) in ex.signedExtraAgreement"
                      :key="idx">
                      <FilesUploader
                        :label="doc.text"
                        :documents="doc"
                        readonly />
                    </div>
                  </div>
                  <span v-else class="card-label" style="padding-left: 6px"
                    >Документи відсутні</span
                  >
                </div>
              </div>
            </div>
          </v-col>
        </div>
      </v-row>
      <v-divider style="margin: 10px 0"></v-divider>
      <v-row v-if="guaranteeContracts.length">
        <div class="d-flex flex-column" style="width: 100%">
          <span style="padding-left: 12px" class="card-value">
            Договір поруки
          </span>
          <v-col
            v-for="(gc, idx) in guaranteeContracts"
            :key="gc.id"
            :cols="12"
            :sm="12"
            class="pt-1 pb-1 text-start">
            <div style="width: 100%">
              <div class="d-flex align-center">
                <span style="margin-right: 10px" class="card-label"
                  >№ {{ idx + 1 }}</span
                >
                <div>
                  <span class="card-value">
                    <ALink
                      :route="{
                        name: 'surety-agreement',
                        params: { id: gc.id },
                      }"
                      :text="gc.number" />
                  </span>
                  <span class="card-label"
                    >({{ gc?.status?.name || '---' }})</span
                  >
                </div>
                <div></div>
              </div>
              <div style="margin-left: -5px; width: 100%">
                <div v-if="gc.signedGuarantorContract">
                  <div
                    v-for="(doc, idx) in gc.signedGuarantorContract"
                    :key="idx">
                    <FilesUploader
                      :label="doc.text"
                      :documents="doc"
                      readonly />
                  </div>
                </div>
                <span v-else class="card-label" style="padding-left: 6px"
                  >Документи відсутні</span
                >
              </div>
            </div>
          </v-col>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
import ALink from '@/components/Link.vue'
import FilesUploader from '@/components/FilesUploader.vue'
import { openDocument } from '@/utils/helperFunc'

export default {
  components: { FilesUploader, ALink },
  props: {
    DocData: { type: Object },
  },
  computed: {
    dkp() {
      return this.DocData?.contract?.dkp
    },
    extraAgreements() {
      return this.DocData?.contract?.extraAgreements || []
    },
    guaranteeContracts() {
      return this.DocData?.project?.guaranteeContracts || []
    },
    contract() {
      return this.DocData?.contract
    },
  },
  methods: { openDocument },
}
</script>

<style scoped></style>
