<template>
  <v-card-text
    style="
      width: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;
    ">
    <div v-if="subtenant.id">
      <div class="data-card">
        <span class="area-label">Поручитель</span>
        <p class="title-label">
          <ALink
            :text="subtenant.shortName || subtenant.fullName"
            :route="getEntityRoute(subtenant.entityId, subtenant.entityTypeId)">
          </ALink>
        </p>
      </div>
      <OpenableSection title="Документи">
        <template #body>
          <FilesUploader
            readonly
            label="Заявка анкета Суборендатора"
            :documents="subtenantApplicationForm" />
          <FilesUploader
            v-model:documents="subtenantApplicationFormSigned"
            :readonly="readonly"
            label="Заявка анкета Суборендатора (підписаний)"
            :disableDeleteAction="!userAdmin" />
          <div v-if="subtenant.entityTypeId === 2">
            <FilesUploader
              v-if="projecContractortDocs.variousWritingsExplanations.show"
              showDelete
              :readonly="readonly"
              label="Різні листи - пояснення"
              :documents="
                $getSafe(
                  () =>
                    projectData.variousWritingsExplanations[
                      docIdx('variousWritingsExplanations')
                    ].doc
                )
              "
              createProp
              :disableDeleteAction="!userAdmin"
              @delete-field="
                deleteFile(
                  'variousWritingsExplanations',
                  docIdx('variousWritingsExplanations')
                )
              "
              @loaded="addFile('variousWritingsExplanations', $event)"
              @delete="
                deleteFile(
                  'variousWritingsExplanations',
                  docIdx('variousWritingsExplanations')
                )
              " />
            <FilesUploader
              v-if="projecContractortDocs.protocolPPE.show"
              showDelete
              :readonly="readonly"
              label="Рішення/Протокол ЗЗУ"
              :documents="
                $getSafe(
                  () => projectData.protocolPPE[docIdx('protocolPPE')].doc
                )
              "
              createProp
              :disableDeleteAction="!userAdmin"
              @delete-field="deleteFile('protocolPPE', docIdx('protocolPPE'))"
              @loaded="addFile('protocolPPE', $event)"
              @delete="deleteFile('protocolPPE', docIdx('protocolPPE'))" />
            <FilesUploader
              v-if="projecContractortDocs.certificateInstaller.show"
              showDelete
              :readonly="readonly"
              label="Довідка про установчі"
              :documents="
                $getSafe(
                  () =>
                    projectData.certificateInstaller[
                      docIdx('certificateInstaller')
                    ].doc
                )
              "
              createProp
              :disableDeleteAction="!userAdmin"
              @delete-field="
                deleteFile(
                  'certificateInstaller',
                  docIdx('certificateInstaller')
                )
              "
              @loaded="addFile('certificateInstaller', $event)"
              @delete="
                deleteFile(
                  'certificateInstaller',
                  docIdx('certificateInstaller')
                )
              " />
            <AdditionalDocs
              :key="updateKey"
              v-model:documents="projecContractortDocs" />
          </div>
          <div v-else>
            <FilesUploader
              v-if="projecContactDocs.consentOfSpouses.show"
              showDelete
              :readonly="readonly"
              label="Згода подружжя"
              :documents="
                $getSafe(
                  () =>
                    projectData.consentOfSpouses[docIdx('consentOfSpouses')].doc
                )
              "
              createProp
              :disableDeleteAction="!userAdmin"
              @delete-field="
                deleteFile('consentOfSpouses', docIdx('consentOfSpouses'))
              "
              @loaded="addFile('consentOfSpouses', $event)"
              @delete="
                deleteFile('consentOfSpouses', docIdx('consentOfSpouses'))
              " />
            <FilesUploader
              v-if="projecContactDocs.certificateOfMaritalStatus.show"
              showDelete
              :readonly="readonly"
              label="Довідка про сімейний стан"
              :documents="
                $getSafe(
                  () =>
                    projectData.certificateOfMaritalStatus[
                      docIdx('certificateOfMaritalStatus')
                    ].doc
                )
              "
              createProp
              :disableDeleteAction="!userAdmin"
              @delete-field="
                deleteFile(
                  'certificateOfMaritalStatus',
                  docIdx('certificateOfMaritalStatus')
                )
              "
              @loaded="addFile('certificateOfMaritalStatus', $event)"
              @delete="
                deleteFile(
                  'certificateOfMaritalStatus',
                  docIdx('certificateOfMaritalStatus')
                )
              " />
            <FilesUploader
              v-if="projecContactDocs.variousWritingsExplanations.show"
              showDelete
              :readonly="readonly"
              label="Різні листи - пояснення"
              :documents="
                $getSafe(
                  () =>
                    projectData.variousWritingsExplanations[
                      docIdx('variousWritingsExplanations')
                    ].doc
                )
              "
              createProp
              :disableDeleteAction="!userAdmin"
              @delete-field="
                deleteFile(
                  'variousWritingsExplanations',
                  docIdx('variousWritingsExplanations')
                )
              "
              @loaded="addFile('variousWritingsExplanations', $event)"
              @delete="
                deleteFile(
                  'variousWritingsExplanations',
                  docIdx('variousWritingsExplanations')
                )
              " />
            <AdditionalDocs
              :key="updateKey"
              v-model:documents="projecContactDocs" />
          </div>
        </template>
      </OpenableSection>
    </div>
  </v-card-text>
</template>

<script>
import AdditionalDocs from '@/components/AdditionalDocs.vue'
import { useAdditionalDocs } from '@/utils/mixins/useAdditionalDocs'
import ALink from '@/components/Link.vue'
import OpenableSection from '@/components/OpenableSection.vue'
import FilesUploader from '@/components/FilesUploader.vue'
import { mapState } from 'vuex'
import { getEntityRoute, getEntityName, getSafe } from '@/utils/helperFunc'
import { urlUpdateSubtenant } from '@/pages/request'
export default {
  components: {
    OpenableSection,
    FilesUploader,
    ALink,
    AdditionalDocs,
  },
  setup() {
    return {
      ...useAdditionalDocs(),
    }
  },
  props: {
    project: { type: Object },
    subtenant: { type: Object },
    readonly: { type: Boolean, default: false },
  },
  data: () => ({
    generalObject: 'project',
    projecContactDocs: {
      variousWritingsExplanations: { label: 'Різні листи - пояснення' },
      certificateOfMaritalStatus: { label: 'Довідка про сімейний стан' },
      consentOfSpouses: { label: 'Згода подружжя' },
    },
    projecContractortDocs: {
      variousWritingsExplanations: { label: 'Різні листи - пояснення' },
      protocolPPE: { label: 'Рішення/Протокол ЗЗУ' },
      certificateInstaller: { label: 'Довідка про установчі' },
    },
    documents: {
      contractor: [
        {
          label: 'Заявка анкета поручителя (підписаний)',
          key: 'applicationFormSigned',
        },
        { label: 'Довідка про установчі', key: 'certificateInstaller' },
        { label: 'Рішення/Протокол ЗЗУ', key: 'protocolPPE' },
        {
          label: 'Різні листи - пояснення',
          key: 'variousWritingsExplanations',
        },
      ],
      contact: [
        {
          label: 'Заявка анкета поручителя (підписаний)',
          key: 'applicationFormSigned',
        },
        { label: 'Згода подружжя', key: 'consentOfSpouses' },
        {
          label: 'Довідка про сімейний стан',
          key: 'certificateOfMaritalStatus',
        },
        {
          label: 'Різні листи - пояснення',
          key: 'variousWritingsExplanations',
        },
      ],
    },
  }),
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    userAdmin() {
      return this.user.roleId === 1
    },
    projectData() {
      return this.project
    },
    entity() {
      return this.subtenant.entityTypeId === 2 ? 'contractor' : 'contact'
    },
    subtenantApplicationForm() {
      return this.subtenant.subtenantApplicationForm
    },
    subtenantApplicationFormSigned: {
      get() {
        return this.subtenant.subtenantApplicationFormSigned
      },
      set(val) {
        return this.$axios
          .post(urlUpdateSubtenant(this.subtenant.id), {
            subtenantApplicationForm: this.subtenant.subtenantApplicationForm,
            subtenantApplicationFormSigned: val,
          })
          .then(res => {
            this.$store.commit('setAssociatedPartyApplicationFormSigned', {
              doc: res.data.subtenantApplicationFormSigned,
              associatedParty: this.subtenant,
              associatedPartyType: 'subtenant',
            })
          })
      },
    },
  },
  methods: {
    getSafe,
    getEntityRoute,
    getEntityName,
    addFile(key, doc) {
      const subtenantDoc = {
        doc: doc,
        subtenantId: this.subtenant.entityId,
        subtenantTypeId: this.subtenant.entityTypeId,
        id: this.subtenant.id,
      }
      const idx = this.docIdx(key)

      idx !== -1 && this.deleteFile(key, idx)

      if (!this.projectData[key]) {
        this.projectData[key] = []
      }
      this.projectData[key].push(subtenantDoc)
    },
    docIdx(key) {
      return this.$getSafe(
        () =>
          this.projectData[key].findIndex(el => {
            const id = `${this.subtenant.entityId}${this.subtenant.entityTypeId}`
            return `${el.subtenantId}${el.subtenantTypeId}` === id
          }),
        -1
      )
    },
    deleteFile(key, idx) {
      this.projectData[key] = this.projectData[key].filter((_, i) => i !== idx)
      const docs =
        this.subtenant.entityTypeId === 2
          ? this.projecContractortDocs
          : this.projecContactDocs
      docs[key].show = false
    },
  },
  created() {
    const docs =
      this.subtenant.entityTypeId === 2
        ? this.projecContractortDocs
        : this.projecContactDocs

    Object.keys(docs).forEach(d => {
      if (this.$getSafe(() => this.projectData[d][this.docIdx(d)].doc))
        docs[d].show = true
    })
  },
}
</script>

<style scoped></style>
