<template>
  <v-card-text>
    <SectionLoader v-if="pageLoad" />
    <div v-if="!pageLoad">
      <v-row>
        <v-col :cols="12" :sm="4">
          <v-text-field v-model="search" hide-details placeholder="Пошук" dense>
          </v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="faqPresentationHeaders"
        :items="presentations"
        class="calc-table --cust-cell-pd mt-3"
        mobileBreakpoint="750"
        dense
        :hide-default-footer="!presentations.length"
        :hide-default-header="!presentations.length"
        :search="search">
        <!-- eslint-disable-next-line -->
        <template #item.actions="{ item }">
          <TableCrud
            :actionsArray="[
              {
                action: () => handlePresentationDialog(item),
                text: 'Переглянути',
                icon: 'mdi-pencil',
              },
            ]">
          </TableCrud>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.created_at="{ item }">
          {{ toFormatDate(item.created_at) }}
        </template>
      </v-data-table>
    </div>
  </v-card-text>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'
import { faqPresentationHeaders } from '@/utils/tableHeaders'
import { toFormatDate } from '@/utils/helperFunc'
import { urlFaqGetPresentations } from '@/pages/request'
import TableCrud from '@/components/table-crud.vue'
export default {
  components: {
    TableCrud,
    SectionLoader,
  },
  data: () => ({
    search: null,
    pageLoad: false,
    presentations: [],
    faqPresentationHeaders,
  }),
  methods: {
    toFormatDate,
    handlePresentationDialog(item) {
      this.$store.commit('setDialog', {
        title: 'Презентація',
        dialogItem: item,
        params: {
          readonly: true,
          hideActions: true,
        },
        action: 'faq-presentation',
      })
    },
    getPresentations() {
      this.pageLoad = true
      return this.$axios.get(urlFaqGetPresentations()).then(res => {
        this.presentations = res.data
        this.pageLoad = false
      })
    },
  },
  created() {
    this.getPresentations()
  },
}
</script>
