<template>
  <div>
    <div class="d-flex align-center" style="position: relative">
      <v-menu :close-on-content-click="false">
        <template #activator="{ props }">
          <span class="text__link link" v-bind="props">
            Історія змін реєстраційної інформації
          </span>
        </template>
        <v-card>
          <v-data-table
            :headers="otherTHead"
            :items="changes"
            hide-default-footer
            class="pb-2 custom-table head-line"
            dense>
            <!-- eslint-disable-next-line -->
            <template #item.date="{ item }">
              {{ toFormatDate(item.date) }}
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.changes="{ item }">
              <div>
                <v-menu bottom :hudge-width="450" offset-y>
                  <template #activator="{ props }">
                    <v-btn icon size="x-small" v-bind="props">
                      <EyeIcon />
                    </v-btn>
                  </template>
                  <v-card>
                    <v-data-table
                      style="background: white !important"
                      :headers="scoringChangesHeaders"
                      :items="item.changes"
                      class="pb-2 custom-table head-line"
                      hide-default-footer
                      dense>
                    </v-data-table>
                  </v-card>
                </v-menu>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script>
import EyeIcon from '@/assets/svg/eye-icon.vue'
import { toFormatDate } from '@/utils/helperFunc'
import { scoringChangesHeaders } from '@/utils/tableHeaders'

export default {
  name: 'ChangesTable',
  components: { EyeIcon },
  props: {
    changes: { type: Array },
  },
  data() {
    return {
      scoringChangesHeaders,
      otherTHead: [
        {
          title: 'Дата',
          key: 'date',
          sortable: false,
          align: 'start',
          width: '40%',
        },
        {
          title: 'Зміни',
          key: 'changes',
          sortable: false,
          align: 'end',
          width: '20%',
        },
      ],
    }
  },
  methods: { toFormatDate },
}
</script>
