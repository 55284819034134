const switchObjType = id => {
  if (id === 1 || id === 6) return 'Автомобіль та його вартість'
  if (id === 7) return 'Автобус та його вартість'
  if (id === 4) return 'Спецтехніка та її вартість'
  if (id === 10) return 'C.г. технiка та її вартiсть'
  if (id === 11) return 'Вагони та їх вартiсть'
  if (id === 12) return 'Комерцiйна нерухомiсть та її вартiсть'
  if (id === 8) return 'Автобудинок та його вартiсть'
  if (id === 5) return 'Причеп та його вартiсть' // temporary value
  if (id === 13) return 'Обладнання та його вартiсть'
  if (id === 9) return 'Дрон та його вартiсть'
  if (id === 3) return 'Водний транспорт та його вартiсть'
  if (id === 2) return 'Мототранспорт та його вартiсть'
}

const switchInsurance = id => {
  switch (id) {
    case 1:
      return 'Нi'
    case 2:
      return 'Зниження страхової суми'
    case 3:
      return 'Страховка на останнiй рiк не включена'
    case 4:
      return 'Зниження страхової суми + строк на остннiй рiк не вкл'
  }
}

const switchFranchise = id => {
  switch (id) {
    case 1:
      return '0%'
    case 2:
      return '0.3%'
    case 3:
      return '0.5%'
    case 4:
      return '1%'
    case 5:
      return '2%'
  }
}

const getGraphCount = obj => {
  const keys = Object.keys(obj?.resultData)

  if (keys.length === 1) {
    return ['30%', '70%']
  }
  if (keys.length === 2) {
    return ['30.2%', '34.8%', '35%']
  }
  if (keys.length === 3) {
    return ['30.6%', '23.13%', '23.13%%', '23.13%%']
  }
}

const getGraphName = engName => {
  const chartTypes = [
    { title: 'Класичний', key: 'even' },
    { title: 'Ануїтет', key: 'annuity' },
    { title: 'Індивідуальний', key: 'irregular' },
    { title: 'Класичний з посиленням', key: 'even-plus' },
    { title: 'Ануїтет з посиленням', key: 'annuity-plus' },
    { title: 'Оперативний лізинг', key: 'oper-leasing' },
  ]

  const graphName = chartTypes.find(obj => engName === obj.key)

  return graphName?.title
}

export {
  switchObjType,
  switchInsurance,
  switchFranchise,
  getGraphCount,
  getGraphName,
}
