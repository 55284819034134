<template>
  <div>
    <LogHistory :entityId="contact.id" :entityTypeId="1" />
  </div>
</template>

<script>
import LogHistory from '@/components/LogHistory.vue'
export default {
  components: { LogHistory },
  props: {
    contact: { type: Object },
  }
}
</script>

<style scoped>

</style>
