<template>
  <div>
    <div v-if="setRiskManagerResponsible">
      <v-select
        v-model="riskResponsibleId"
        :error-messages="riskResponsibleIdErr"
        :loading="asyncLoading('addRiskManagers')"
        :items="riskManagers.filter(m => m.isActive)"
        item-title="fullName"
        item-value="id"
        label="Ризик менеджер"
        @blur="v$.riskResponsibleId.$touch()">
      </v-select>
    </div>
  </div>
</template>
<script>
import { required } from '@vuelidate/validators'
import { setErrHandler, joinStr } from '@/utils/helperFunc'
import { urlRiskAnalyzeSetResponsible } from '@/pages/request'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
export default {
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  props: {
    dialog: { type: Object },
    callBack: { type: Function },
  },
  validations() {
    return {
      riskResponsibleId: { required },
    }
  },
  data: () => ({
    riskResponsibleId: null,
  }),
  computed: {
    validation() {
      return { v$: this.v$, name: 'setRiskResponsible' }
    },
    setRiskManagerResponsible() {
      return this.dialog.action === 'setRiskManagerResponsible'
    },
    riskResponsibleIdErr() {
      return this.setErrHandler(this.v$.riskResponsibleId)
    },
    riskManagers() {
      const riskManagers = this.$store.state.selectItems?.riskManagers
      if (riskManagers?.length) {
        return riskManagers.map(v => {
          v.fullName = this.joinStr(v.surname, v.name, v.patronymic)
          return v
        })
      }
      return []
    },
    analyzeId() {
      return this.dialog?.params?.analyzeId
    },
  },
  methods: {
    joinStr,
    setErrHandler,
    urlRiskAnalyzeSetResponsible,
    submit() {
      return this.$axios
        .post(this.urlRiskAnalyzeSetResponsible(this.analyzeId), {
          responsibleId: this.riskResponsibleId,
        })
        .then(res => {
          this.$setSnackbar({
            text: 'Призначено відповідального ризик менеджера',
          })
          return res
        })
        .catch(this.$err)
    },
  },
  created() {
    this.asyncAction('addRiskManagers')
  },
}
</script>
