<template>
  <div class="d-block w-100 cursor-pointer">
    <v-icon :size="iconSize" class="mr-3">
      {{ type === 1 ? 'mdi-account-box-outline' : 'mdi-domain' }}
    </v-icon>
    <span>{{ name }}</span>
  </div>
</template>
<script>
export default {
  props: {
    name: { type: String },
    type: { type: [String, Number] },
    iconSize: { type: [String, Number], default: 25 },
  },
}
</script>
