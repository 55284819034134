<template>
  <!-- v-if="validRole"  -->
  <div :class="{ 'pa-0': faqRoute }">
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    validRole() {
      return this.roleId === 1
    },
    roleId() {
      return this.$store.state?.user?.roleId
    },
    faqRoute() {
      return this.$route.path.includes('/administration/faq')
    },
  },
  methods: {
    checkAccess() {
      if (!this.roleId) return
      if (!this.validRole) {
        this.$setSnackbar({ text: 'Відмовлено в доступі', color: 'error' })
        this.$router.push({ name: 'crm' })
      }
    },
  },
  watch: {
    roleId() {
      this.checkAccess()
    },
  },
  mounted() {
    this.checkAccess()
  },
}
</script>
