<template>
  <div
    v-if="Number.isInteger(currentStatus)"
    data-readonly="true"
    :class="`d-flex ${spreadItems ? '' : ''} statusMobile ${
      !isCancelContract || !isTerminated ? 'justify-space-between' : ''
    }`">
    <v-stepper
      v-if="isCancelContract === false && isTerminated === false"
      :model-value="currentStatus"
      complete-icon="mdi-check-bold"
      elevation="0"
      class="d-flex position-relative">
      <v-stepper-header :class="`${mobile ? 'mobile-header' : ''}`">
        <template v-for="(item, index) in statuses" :key="index">
          <v-stepper-item
            class="step-item"
            :complete="item.id <= currentStatus"
            :value="index + 1"
            :color="item.id <= currentStatus ? color : 'red'">
            <template #icon>
              <v-icon
                :style="
                  item.id <= currentStatus
                    ? { fontSize: '16px' }
                    : { fontSize: '20px' }
                "
                :color="item.id <= currentStatus ? '' : 'white'"
                :size="item.id <= currentStatus ? '' : 'large'"
                @click="onSelect(item)">
                {{ item.id <= currentStatus ? 'mdi-check-bold' : 'mdi-circle' }}
              </v-icon>
            </template>
            <template #default>
              <Loader v-if="asyncLoading(`submit_${item.id}`)" :size="20" />
              <span
                v-else
                style="font-size: 14px; font-weight: 600; color: #1c1d21"
                :style="
                  item.id <= currentStatus
                    ? { fontWeight: 'bold' }
                    : { fontWeight: 'normal' }
                "
                @click="onSelect(item)"
                >{{ item.name }}</span
              >
            </template>
          </v-stepper-item>

          <v-divider v-if="index < statuses.length - 1">
            <v-icon
              size="x-small"
              color="grey"
              class="mx-1"
              :class="`${mobile ? 'mobile-divider' : ''}`">
              mdi-chevron-right
            </v-icon>
          </v-divider>
        </template>
      </v-stepper-header>
    </v-stepper>
    <div
      class="d-flex align-center justify-space-between"
      :class="`${isCancelContract || isTerminated ? 'w-100' : ''}`">
      <div class="d-flex align-center">
        <v-chip
          v-if="isCancelContract || isTerminated"
          color="red"
          class="cursor-default"
          prepend-icon="mdi-timer-cancel-outline">
          {{ isTerminated ? 'Договір розірвано' : 'Договір анульовано' }}
        </v-chip>
        <v-menu v-if="contract.isTerminated">
          <template #activator="{ props }">
            <div v-bind="props">
              <v-btn
                v-tooltip:bottom="'Деталі розривання'"
                color="#fd7149"
                density="comfortable"
                class="rounded-circle"
                icon>
                <v-icon>mdi-information</v-icon>
              </v-btn>
            </div>
          </template>

          <v-card>
            <v-card-text>
              <div class="d-flex align-center mb-3">
                <div class="text-disabled">Додаткова угода:</div>
                <div>
                  <InputPusher
                    :routeObject="{
                      name: `du-${
                        $route.path.includes('dfl') ? 'dfl' : 'dkp'
                      }-agreement`,
                      params: {
                        id: contract.terminate?.terminateAgreement.id,
                      },
                    }"
                    text="Перейти до Додаткової угоди">
                  </InputPusher>
                  <ALink
                    :text="contract.terminate?.terminateAgreement.number"
                    :route="{
                      name: `du-${
                        $route.path.includes('dfl') ? 'dfl' : 'dkp'
                      }-agreement`,
                      params: {
                        id: contract.terminate?.terminateAgreement.id,
                      },
                    }" />
                </div>
              </div>
              <div>
                <span class="text-disabled">Дата розривання:</span>
                {{ toFormatDate(contract.terminate?.terminateDate) }}
              </div>
            </v-card-text>
          </v-card>
        </v-menu>
      </div>
      <slot class="d-block" name="actions"></slot>
    </div>
  </div>
</template>

<script>
import ALink from '@/components/Link.vue'
import Loader from '@/components/Loader.vue'
import { useSelect } from '@/utils/mixins/useSelect'
import InputPusher from '@/components/InputPusher.vue'
import { toFormatDate } from '@/utils/helperFunc'
export default {
  components: {
    InputPusher,
    Loader,
    ALink,
  },
  setup() {
    return {
      ...useSelect(),
    }
  },
  props: {
    contract: { type: Object },
    currentStatus: { type: Number },
    statuses: { type: Array },
    submit: { type: Function },
    autostatus: { type: Boolean },
    spreadItems: { type: Boolean, default: false },
    subStatuses: { type: Object, default: null },
    color: { type: String, default: '#FC7247' },
    hasReject: { type: Boolean, default: false },
    isCancelContract: { type: Boolean, default: false },
    isTerminated: { type: Boolean, default: false },
    mobile: { type: Boolean, default: false },
  },
  data: () => ({
    subStatusesOpen: false,
  }),
  methods: {
    toFormatDate,
    onSelect(item) {
      if (this.loadingState.length) return
      if ('select' in item && !item.arrow) {
        this.toggleSubStatuses(item)
      } else if (this.currentStatus !== item.id && !this.autostatus) {
        this.asyncAction(`submit_${item.id}`, null, () => this.submit(item.id))
      }
    },
    toggleSubStatuses(item) {
      if (this.subStatusesOpen === item.select) this.subStatusesOpen = false
      else this.subStatusesOpen = item.select
    },
    showCheckIcon(item) {
      if (typeof item.showCheckIcon === 'boolean') {
        return item.showCheckIcon
      } else {
        return item.id <= this.currentStatus
      }
    },
  },
}
</script>

<style scoped lang="scss">
.step-item {
  cursor: pointer !important;
  transition: 0.3s;
  &:hover {
    background: rgb(169 169 169 / 19%) !important;
    border-radius: 50px;
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
}
.v-stepper-header {
  overflow-x: initial !important;
}
.v-stepper-item {
  padding: 1px !important;
  opacity: 1 !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
}
.v-stepper-item__avatar.v-avatar {
  border: solid #878787 !important;
  background: rgb(255 255 255 / 60%) !important;
}
.mobile-header {
  display: flex !important;
  flex-direction: column !important;
  box-shadow: none !important;
}
.mobile-divider {
  transform: rotate(90deg) !important;
  display: block;
  left: -70px;
}
</style>
