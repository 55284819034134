<template>
  <v-fade-transition hide-on-leave>
    <div v-if="!loading" class="content-wrapper verification-card">
      <div
        v-if="DocData.histories"
        class="d-flex align-center justify-space-between white-block"
        style="margin-bottom: 10px">
        <div class="d-flex align-center">
          <div class="mr-4">
            <v-tooltip left text="Попередні рішення по клієнту">
              <template #activator="{ props }">
                <v-btn
                  icon
                  size="small"
                  v-bind="props"
                  @click="toggleHistoryDialog">
                  <v-icon style="color: rgb(252, 114, 71)">{{
                    'mdi-badge-account-horizontal'
                  }}</v-icon>
                </v-btn>
              </template>
              <span>Попередні рішення по клієнту</span>
            </v-tooltip>

            <v-dialog v-model="historyDialog" width="450">
              <v-card>
                <div class="d-flex justify-end">
                  <v-btn size="small" icon @click="toggleHistoryDialog">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 18 19"
                      fill="none">
                      <path
                        d="M11.7693 12.2692L6.23084 6.73078M6.23084 12.2692L11.7693 6.73078M17.3078 9.50001C17.3078 4.91348 13.5866 1.19232 9.00007 1.19232C4.41354 1.19232 0.692383 4.91348 0.692383 9.50001C0.692383 14.0866 4.41354 17.8077 9.00007 17.8077C13.5866 17.8077 17.3078 14.0866 17.3078 9.50001Z"
                        stroke="#C0C1C7"
                        stroke-linecap="round" />
                    </svg>
                  </v-btn>
                </div>
                <v-card-text>
                  <div
                    v-for="(h, idx) in DocData.histories"
                    :key="h.id"
                    style="cursor: pointer"
                    class="d-flex align-center mb-3">
                    <LinkedIcon class="mr-2" />
                    <span class="hyper-link" @click="handleHistoryClick(h)">
                      {{
                        `Перевірка клієнта за ${toFormatDate(h.created_at, {
                          dateOnly: true,
                        })}`
                      }}
                    </span>
                  </div>
                  <span v-if="!DocData.histories.length"
                    >Попередніх рішень немає</span
                  >
                </v-card-text>
              </v-card>
            </v-dialog>
          </div>
        </div>
      </div>
      <component
        :is="contractorType ? 'ContractorCard' : 'ContactCard'"
        :contact="contact"
        :contractor="contractor"
        legalDocs
        marriedDocs
        finDocs
        contactDetails
        showKbvDocs
        showSignatories
        :showEmployees="false"
        :showAuthorizedCapitalFull="false">
      </component>
      <div class="white-block" style="margin-top: 10px">
        <span class="card-label d-block" style="margin-bottom: -20px"
          >Відповідальні:</span
        >
        <div class="section-card">
          <div>
            <v-row>
              <v-col :cols="12" :sm="3" class="pt-0 pb-0"> </v-col>
              <v-col :cols="12" :sm="9" class="pt-0 pb-0 text-start">
                <span class="card-sublable mr-1">Фронт менеджер:</span>
                <span class="card-value">{{ frontManager }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="12" :sm="3" class="pt-0 pb-0"> </v-col>
              <v-col :cols="12" :sm="9" class="pt-0 pb-0 text-start">
                <span class="card-sublable mr-1">Мiдл менеджер:</span>
                <span class="card-value">{{ middleManager }}</span>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </v-fade-transition>
</template>

<script>
import ContactCard from '@/components/ContactCard.vue'
import ContractorCard from '@/components/ContractorCard.vue'
import FilesUploader from '@/components/FilesUploader.vue'

import {
  urlTakeToWorkFinancialMonitoring,
  urlSetFinancingMonitoringStatus,
} from '@/pages/request'
import { setErrHandler, toFormatDate } from '@/utils/helperFunc'

import { formatDate, humanDate } from '@/utils/formatFunc'
import oneCIcon from '@/assets/svg/oneCIcon.vue'
import OpenableList from '@/components/OpenableList.vue'
import ALink from '@/components/Link.vue'
import LinkedIcon from '@/assets/svg/linked-icon.vue'

export default {
  components: {
    LinkedIcon,
    ALink,
    OpenableList,
    oneCIcon,
    FilesUploader,
    ContactCard,
    ContractorCard,
  },
  props: {
    loading: { type: Boolean, default: false },
    submitLoading: { type: Boolean, default: false },
    acceptDocumentsLoading: { type: Boolean, default: false },
    documentsAccepted: { type: Boolean, default: false },
    DocData: { type: Object },
    clientComment: { type: [Object, String] },
    financialRiskLevelCache: { type: Number },
    id: { type: String },
    v: { type: Object },
  },
  data: () => ({
    historyDialog: false,
  }),
  computed: {
    project() {
      return this.DocData.project
    },
    slider() {
      return this.DocData.project.slider
    },
    contact() {
      return this.client
    },
    isInWork() {
      return this.DocData?.statusId === 2
    },
    DocDataData() {
      return this.DocData
    },
    applicationDate() {
      const date = this.DocData?.created_at
      if (!date) return
      return this.humanDate(this.formatDate(date, true))
    },
    selectItems() {
      return this.$store.state.selectItems
    },
    sourceTypeId() {
      return this.DocData?.project?.slider?.calculations[0]?.application
        ?.sourceTypeId
    },
    client() {
      return this.DocData?.lessee
    },
    contractor() {
      return {
        ...(this.client || {}),
      }
    },
    contractorType() {
      return this.DocData?.project?.lesseeTypeId === 2
    },
    frontManager() {
      const m = this.project?.frontManagerResponsible?.fullName || '---'
      if (!m) return
      return m
    },
    middleManager() {
      const m = this.project?.middleManagerResponsible?.fullName || '---'
      if (!m) return
      return m
    },
  },
  methods: {
    toFormatDate,
    formatDate,
    humanDate,
    setErrHandler,
    urlTakeToWorkFinancialMonitoring,
    urlSetFinancingMonitoringStatus,
    toggleHistoryDialog() {
      this.historyDialog = !this.historyDialog
    },
    handleHistoryClick(h) {
      this.$store.commit('refreshPage')

      this.$router.push({
        name: 'fd-project-verification',
        params: { id: `${h.financialMonitoringId}` },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.accept-documents {
  &__item {
    display: flex;
  }
}
.data-card {
  display: flex;
  flex-direction: column;
  margin-right: 25px;
}
.card-label {
  color: #000 !important;
}
.card-sublable {
  color: #838282;
  font-weight: 500;
  font-size: 13px;
}
.subtitle {
  color: #000;
}
.hyper-link {
  text-decoration: none;
  color: #08487a;
  font-weight: 400;
  font-size: 13px !important;
  &:hover {
    text-decoration: underline;
  }
}
</style>
