<template>
  <div>
    <InfoFields
      v-for="(f, idx) in fields"
      :key="idx"
      style="padding-bottom: 1rem; margin-bottom: 1rem; padding-left: 0"
      :style="{ borderBottom: idx + 1 < fields.length ? '1px solid #ccc' : 'none' }"
      :fields="f" />
    <div v-if="!fields.length" class="text-center disabled">
      Немає даних для відображення
    </div>
  </div>
</template>

<script>
import InfoFields from '@/components/info-fields/InfoFields.vue'
import { toFormatDate } from '@/utils/helperFunc'

export default {
  components: { InfoFields },
  props: {
    LO: { type: Object },
  },
  computed: {
    fields() {
      return this.LO.СтраховыеСобытия.map(event => {
        return [
          { label: 'Дата події', value: toFormatDate(event.ДатаСобытия, true) },
          { label: 'Дата оповіщення', value: toFormatDate(event.ДатаОповещения, true) },
          { label: 'Коментар', value: event.Комментарий },
          { label: 'Місце події', value: event.МестоСобытия },
          { label: 'Опис події', value: event.ОписаниеСобытия },
        ]
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
