<template>
  <div>
    <v-card-title
      class="subtitle-1 pb-0 mb-2"
      :class="{
        'd-flex align-center justify-space-between': justifySpaceBetween,
      }"
      style="padding-left: 5px">
      <div
        class="d-flex align-center"
        :class="{ 'w-100 justify-space-between': justifySpaceBetween }">
        <div>
          <ObjectLeasingIcon v-if="leasingObjectIcon" />
          <v-icon v-if="faqDoc" color="red">
            {{ 'mdi-youtube' }}
          </v-icon>
          <DocumentsIcon v-else-if="!leasingObjectIcon && !faqDoc" />
          <v-btn
            variant="text"
            size="x-small"
            class="btn-panel text-uppercase"
            :ripple="false"
            @click="showSection = !showSection">
            {{ title }}
            &nbsp;
            <div v-if="showSection">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="8"
                viewBox="0 0 13 8"
                fill="none">
                <path
                  d="M2.21924 7.60948C1.71156 8.13017 0.888446 8.13017 0.380763 7.60947C-0.126918 7.08877 -0.126918 6.24456 0.380763 5.72386L5.58076 0.390524C6.08844 -0.130174 6.91156 -0.130174 7.41924 0.390524L12.6192 5.72386C13.1269 6.24456 13.1269 7.08878 12.6192 7.60948C12.1116 8.13017 11.2884 8.13017 10.7808 7.60948L6.5 3.21895L2.21924 7.60948Z"
                  fill="#FC7247" />
              </svg>
            </div>
            <div v-else>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none">
                <path
                  d="M9.95147 0.390524C10.4201 -0.130175 11.1799 -0.130175 11.6485 0.390524C12.1172 0.911224 12.1172 1.75544 11.6485 2.27614L6.84853 7.60948C6.3799 8.13017 5.6201 8.13017 5.15147 7.60948L0.351472 2.27614C-0.117157 1.75544 -0.117157 0.911224 0.351472 0.390524C0.820102 -0.130175 1.5799 -0.130175 2.04853 0.390524L6 4.78105L9.95147 0.390524Z"
                  fill="#FC7247" />
              </svg>
            </div>
          </v-btn>
        </div>
        <slot name="append-title"></slot>
      </div>
    </v-card-title>
    <v-fade-transition hide-on-leave>
      <div v-show="showSection">
        <slot name="default"></slot>
        <slot name="body">
          <!-- Additional content -->
        </slot>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
import ObjectLeasingIcon from '@/assets/svg/objectLeasing.vue'
import DocumentsIcon from '@/assets/svg/documents.vue'
export default {
  components: {
    ObjectLeasingIcon,
    DocumentsIcon,
  },
  props: {
    title: { type: String, default: 'Документи' },
    justifySpaceBetween: { type: Boolean, default: false },
    leasingObjectIcon: { type: Boolean, default: false },
    faqDoc: { type: Boolean, default: false },
  },
  data() {
    return {
      showSection: false,
    }
  },
}
</script>

<style scoped>
.btn-panel {
  padding-left: 5px !important;
  letter-spacing: normal;
  border: none !important;
  color: #08487a !important;
  font-style: normal;
  font-weight: 500;
  font-size: 13px !important;
  padding-right: 5px !important;
}
</style>
