import axios from 'axios'
import { getItems } from '../helper.js'
import {
  urlGetLeads,
  urlGetLeadById,
  urlUpdateLeadStatus,
  urlUpdateLead,
  urlCreateApplicationFromLead,
} from '@/pages/request.js'

const leads = {
  state: () => ({
    leads: [],
    curLead: null,
  }),
  mutations: {
    setLeads(state, leads) {
      if (leads) {
        state.leads.splice(0)
        state.leads.push(...leads)
      }
    },
    setLeadComments(state, comments) {
      state.curLead.comments = comments
    },
    setCurLead(state, lead) {
      state.curLead = lead
    },
  },
  actions: {
    async getLeads({ commit }, payload = {}) {
      const { page = 1, filters = {} } = payload
      const data = await getItems(urlGetLeads(page, filters))

      if (data && data.data) {
        commit('setLeads', data.data)
      }

      return { data: data?.data || [] }
    },
    async getSingleLead({ commit }, id) {
      const data = await getItems(urlGetLeadById(id))
      commit('setCurLead', data)
      return Promise.resolve()
    },
    async updateLeadStatus({ commit, dispatch }, req) {
      const { data } = await axios.post(urlUpdateLeadStatus(req.id), {
        statusId: req.statusId,
      })
      if (data.message) return Promise.resolve({ data })
      commit('setCurLead', data)
      await dispatch('getLeads')

      return Promise.resolve({ data })
    },
    async updateLead({ commit }, req) {
      const { data } = await axios.post(urlUpdateLead(req.id), req)
      if (data?.message) {
        return Promise.resolve(data)
      }
      commit('setCurLead', data)
      // if (req.statusId !== 2) {
      //   await dispatch('updateLeadStatus', {id: req.id, statusId: 2})
      // }
      return Promise.resolve(data)
    },
    async createApplicationFromLead(_, id) {
      return await axios.get(urlCreateApplicationFromLead(id))
    },
  },
}

export { leads }
