<template>
  <div>
    <car-for-sale-list
      v-if="$route.name === 'car-for-sale-list'"></car-for-sale-list>
    <router-view v-else :key="+$route.params.id || 0"></router-view>
  </div>
</template>

<script lang="ts">
import CarForSaleList from './CarForSaleList.vue'

export default {
  name: 'CarForSaleIndex',
  components: { CarForSaleList },
}
</script>

<style scoped></style>
