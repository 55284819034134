<template>
  <div>
    <SectionLoader v-if="statData.length" />
    <div v-else class="mb-5">
      <span class="mb-3 mt-3 d-block">
        🔍 Що нам вдалося знайти по вашому запиту
      </span>
      <v-data-table
        :headers="tHead"
        :items="statData"
        class="application-table calc-table"
        mobile-breakpoint="1200"
        hide-default-footer
        :hide-default-header="!statData.length"
        :items-per-page="-1">
        <!-- eslint-disable-next-line -->
        <template #item.arithmeticMean="{ item }">
          <span>{{ getBeautyNum(item.arithmeticMean) }}$</span>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.link="{ item }">
          <v-menu v-if="item.links && item.links.length" bottom>
            <template #activator="{ props }">
              <v-btn icon size="small" v-bind="props">
                <EyeIcon />
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(car, key) in sortedLinks(item)"
                :key="key"
                @click="openLink(car.link)">
                <v-list-item-title>
                  <span
                    >{{ averageResponseData?.mark?.name }}
                    {{ averageResponseData?.model?.name }}</span
                  >
                  <span>({{ item.year }}р.) - </span>
                  <span>{{ getBeautyNum(car.price) }}$</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <span v-else>---</span>
        </template>
      </v-data-table>
      <StatisticGraph class="mt-3" :averageResponseData="averageResponseData" />
    </div>
  </div>
</template>

<script>
import { getBeautyNum } from '@/utils/helperFunc'
import EyeIcon from '@/assets/svg/eye-icon.vue'
import SectionLoader from '@/components/section-loader.vue'
import StatisticGraph from '@/pages/average-price/components/statistics/StatisticGraph.vue'

export default {
  components: { SectionLoader, EyeIcon, StatisticGraph },
  props: {
    averageResponseData: { type: Object },
  },
  data: () => ({
    tHead: [
      {
        title: 'Кількість оголошеннь',
        key: 'total',
        align: 'start',
        sortable: false,
      },
      {
        title: 'Рік',
        key: 'year',
        align: 'start',
        sortable: false,
      },
      {
        title: 'Середня вартість',
        key: 'arithmeticMean',
        align: 'start',
        sortable: false,
      },
      {
        title: 'Оголошення',
        key: 'link',
        align: 'end',
        sortable: false,
      },
    ],
  }),
  computed: {
    statData() {
      return this.averageResponseData?.statistics || []
    },
  },
  methods: {
    getBeautyNum,
    sortedLinks(item) {
      if (!item.links || item.links.length === 0) {
        return []
      } else {
        return item.links.slice().sort((a, b) => b.price - a.price)
      }
    },
    openLink(link) {
      window.open(link, '_blanc')
    },
  },
}
</script>

<style scoped></style>
