<template>
  <div>
    <div class="d-flex align-center">
      <span class="section-title mr-2">Нагадування</span>
      <v-tooltip bottom text="Створити нагадування">
        <template #activator="{ props }">
          <v-btn
            data-readonly="hide"
            size="x-small"
            icon
            v-bind="props"
            @click="openCreateNotificationDialog()">
            <NotifIcon />
          </v-btn>
        </template>
        <span>Створити нагадування</span>
      </v-tooltip>
    </div>
    <TaskPlan
      :tasks="tasks"
      :contractorId="contractor.id"
      @update="$emit('updateContractor')" />
  </div>
</template>

<script>
import TaskPlan from '@/components/TaskPlan.vue'
import NotifIcon from '@/assets/svg/social/notif-icon.vue'
export default {
  components: {
    NotifIcon,
    TaskPlan,
  },
  props: { contractor: { type: Object } },
  computed: {
    tasks() {
      return this.contractor.taskSchedulers
    },
  },
  methods: {
    openCreateNotificationDialog() {
      this.$store.commit('setDialog', {
        title: 'Деталі по нагадуванню',
        dialogItem: {
          entityId: this.contractor.id,
          entityTypeId: 2,
        },
        params: {
          cb: () => this.$store.commit('refreshPage'),
          dialogHeight: 700,
          dialogWidth: 600,
          overflow: { y: 'hidden' },
        },
        action: 'task-plan-dialog',
      })
    },
  },
}
</script>

<style scoped>
.section-title {
  color: #08487a;
  font-weight: 700;
  font-size: 16px;
}
</style>
