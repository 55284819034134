<template>
  <div class="field">
    <span class="field__title">{{ field.label }}</span>
    <span
      v-if="field.value"
      class="field__value"
      :class="{ link: !!field.to }"
      @click="() => handleClick(field)">
      {{ field.value }}
    </span>
    <span v-else class="field__value disabled">Не вказано</span>
  </div>
</template>

<script>
export default {
  props: {
    field: { type: Object },
  },
  methods: {
    handleClick(field) {
      if (field.to) {
        this.$router.push(field.to)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.field {
  &__title {
    display: block;
    font-size: 14px;
    font-weight: 600;
    cursor: default;
  }
  &__value {
    display: inline-block;
    color: #616161;
    user-select: all;
    font-size: 14px;
  }
}
</style>
