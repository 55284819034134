<template>
  <div>
    <div>
      <span class="label">Тип {{ typePhone ? 'номеру' : 'пошти' }}</span>
      <v-select
        v-model="item.typeId"
        placeholder="оберіть опцію"
        :items="
          directoryItems(`${type}Types`, item.type).filter(
            t => edit || !dialog.params.excludeTypes.includes(t.id)
          )
        "
        item-title="name"
        item-value="id"
        :loading="asyncLoading(`add${type.capitalize()}Types`)"
        :error-messages="typeIdErr"
        :readonly="editEmail || editPhone"
        @blur="v$.item.typeId.$touch()">
      </v-select>
    </div>
    <div v-if="typePhone">
      <div>
        <span class="label">Номер</span>
        <v-text-field
          :id="`input_phone_${uid}`"
          v-model.trim="item.phone"
          placeholder="(097) 000 - 00 - 00"
          :error-messages="phoneErr"
          @input="
            $event => {
              phoneMask($event, `input_phone_${uid}`, {
                mask: '(0##) ### - ## - ##',
              })
              handleChange('phone')
            }
          "
          @paste="e => setPhone(e.clipboardData.getData('text'))"
          @keydown="preventLeadingZero"
          @blur="
            () => {
              v$.item.phone.$touch()
            }
          ">
          <template #prepend-inner>
            <div class="inline-flex align-center">
              <span
                class="inline-block d-flex justify-center mr-1 pr-1"
                style="border-right: 1px solid #c0c1c7"
                ><Flag
              /></span>
              <span class="inline-block" style="color: #1c1d21">+38</span>
            </div>
          </template>
        </v-text-field>
      </div>
      <div>
        <span class="label">Позначте месенджер для цього номеру:</span>
      </div>
      <div class="d-flex align-center justify-space-between">
        <div v-for="messenger in messengers" :key="messenger">
          <v-checkbox
            v-model="item[`is${messenger}`]"
            style="margin-top: 0; padding-top: 0"
            color="red"
            hide-details
            @change="handleChange(`is${messenger}`)">
            <template #label>
              <v-tooltip bottom :text="messenger">
                <template #activator="{ props }">
                  <v-btn size="small" icon v-bind="props">
                    <component :is="messenger.toLowerCase()" />
                  </v-btn>
                </template>
                <span>{{ messenger }}</span>
              </v-tooltip>
            </template>
          </v-checkbox>
        </div>
      </div>
    </div>
    <div v-else>
      <span class="label">Email</span>
      <v-text-field
        v-model.trim="item.email"
        v-mask:[emailMask]
        placeholder="введіть email"
        :error-messages="emailErr"
        @input="handleChange('email')"
        @blur="v$.item.email.$touch()">
      </v-text-field>
    </div>
    <div class="mt-2">
      <v-checkbox
        v-model="item.isNotify"
        color="red"
        :true-value="false"
        :false-value="true"
        style="margin-top: 0; padding-top: 0"
        hide-details>
        <template #label>
          <span class="label mb-0">Не приймати участь в розсилках</span>
        </template></v-checkbox
      >
    </div>
  </div>
</template>

<script>
import Flag from '@/assets/svg/flag-urk.vue'
import telegram from '@/assets/svg/telegram.vue'
import viber from '@/assets/svg/viber.vue'
import whatsapp from '@/assets/svg/whatsapp.vue'
import { minLength, required } from '@vuelidate/validators'
import { email } from '@vuelidate/validators'
import { mapState } from 'vuex'
import { setErrHandler } from '@/utils/helperFunc'
import { phoneMask } from '@/utils/masks'
import { emailMask } from 'best-modules/utils'
import {
  urlPhoneCreate,
  urlPhoneUpdate,
  urlEmailCreate,
  urlEmailUpdate,
} from '@/pages/request'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'

export default {
  components: {
    Flag,
    telegram,
    viber,
    whatsapp,
  },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  props: {
    dialog: { type: Object },
  },
  validations() {
    const item = {
      typeId: { required },
      phone: this.typePhone ? { required, minLength: minLength(19) } : {},
      email: this.typeEmail ? { required, email } : {},
    }
    return { item }
  },
  data() {
    return {
      emailMask,
      item: {},
      cachedItem: {},
      changes: {},
      uid: null,
      messengers: ['Viber', 'Telegram', 'Whatsapp'],
    }
  },
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    validation() {
      return { v$: this.v$.item, name: `${this.type}Item` }
    },
    type() {
      return this.dialog.params.type
    },
    typePhone() {
      return this.type === 'phone'
    },
    typeEmail() {
      return this.type === 'email'
    },
    requestObject() {
      const req = Object.clone(this.create ? this.item : this.changes)
      if (req.phone && this.create) req.phone = this.getPhone(req.phone)

      return {
        ...req,
        email: this.item.email,
        phone: this.getPhone(this.item.phone),
        typeId: this.item.typeId,
        isNotify: this.item.isNotify,
        entityId: this.dialog.params.entity.id,
        entityTypeId: this.dialog.params.entity.typeId,
      }
    },
    createPhone() {
      return this.dialog.action === 'createPhone'
    },
    editPhone() {
      return this.dialog.action === 'editPhone'
    },
    createEmail() {
      return this.dialog.action === 'createEmail'
    },
    editEmail() {
      return this.dialog.action === 'editEmail'
    },
    create() {
      return this.createPhone || this.createEmail
    },
    edit() {
      return this.editPhone || this.editEmail
    },
    url() {
      switch (true) {
        case this.editPhone:
          return urlPhoneUpdate(this.item.id)
        case this.editEmail:
          return urlEmailUpdate(this.item.id)
        case this.createPhone:
          return urlPhoneCreate()
        case this.createEmail:
          return urlEmailCreate()
        default:
          return 'invalidUrl'
      }
    },
    typeIdErr() {
      return setErrHandler(this.v$.item.typeId)
    },
    phoneErr() {
      return setErrHandler(this.v$.item.phone)
    },
    emailErr() {
      return setErrHandler(this.v$.item.email)
    },
  },
  methods: {
    phoneMask,
    handleChange(key) {
      let value = this.item[key]
      if (key === 'phone') value = this.getPhone(value)
      if (this.cachedItem[key] !== value) {
        const change = {}
        change[key] = value
        this.changes = { ...this.changes, ...change }
      } else {
        const change = Object.clone(this.changes)
        delete change[key]
        this.changes = change
      }
    },
    submit() {
      if (this.createEmail || this.editEmail) {
        delete this.requestObject.phone
      }
      return this.$axios.post(this.url, this.requestObject).then(res => {
        if (res.data.message) {
          return this.$setSnackbar({ text: res.data.message, color: 'warning' })
        }
        this.$store.commit('refreshPage')
      })
    },
    preventLeadingZero(event) {
      if (event.target.selectionStart === 0 && event.key === '0') {
        event.preventDefault()
      }
    },
    // set item.phone to format (99) 999 - 99 - 99
    setPhone(phone) {
      if (phone?.substring(0, 5) === '+38 (') {
        this.item.phone = `(${phone.substring(5)}`
      }
    },
    // return phone in format +38 (099) 999 - 99 - 99
    getPhone(phone) {
      if (!phone) return null
      return `+38 (${phone.substring(1)}`
    },
    checkItem() {
      return
      // this.$nextTick(() => {
      //   if (
      //     typeof this.dialog.params.checkItem === 'function' &&
      //     this.requestObject[this.type]
      //   ) {
      //     return this.asyncAction('checkItem', null, () => {
      //       return this.dialog.params.checkItem(this.requestObject)
      //     })
      //   }
      // })
    },
  },
  created() {
    const defaultPhone = {
      typeId: null,
      phone: null,
      isViber: false,
      isTelegram: false,
      isWhatsapp: false,
      isNotify: true,
      entityId: this.dialog.params.entity.id,
      entityTypeId: this.dialog.params.entity.typeId,
    }
    const defaultEmail = {
      typeId: null,
      email: null,
      isNotify: true,
      entityId: this.dialog.params.entity.id,
      entityTypeId: this.dialog.params.entity.typeId,
    }

    if (this.dialog.dialogItem) {
      this.item = Object.clone(this.dialog.dialogItem)
      this.cachedItem = Object.clone(this.dialog.dialogItem)
    } else if (this.typePhone) {
      this.item = defaultPhone
    } else if (this.typeEmail) {
      this.item = defaultEmail
    }

    if (this.typePhone) this.setPhone(this.item.phone)
    if (this.create) this.asyncAction(`add${this.type.capitalize()}Types`)

    this.uid = this._uid
    this.$watch(
      'requestObject',
      (newVal, oldVal) => {
        if (newVal[this.type] && newVal[this.type] !== oldVal[this.type]) {
          !this.v$.item[this.type].$invalid && this.checkItem()
        }
      },
      { deep: true }
    )
  },
}
</script>

<style lang="scss" scoped></style>
