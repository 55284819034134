<template>
  <div v-if="associatedParty">
    <div class="data-card" style="margin: 20px 0">
      <span class="area-label">{{
        typeContractor ? 'Контрагент' : 'Контакт'
      }}</span>
      <div class="d-flex align-center">
        <ALink
          :text="getEntityName(associatedParty)"
          :route="
            getEntityRoute(
              associatedParty.entityId,
              associatedParty.entityTypeId
            )
          " />
      </div>
      <FilesUploader
        v-model:documents="applicationForm"
        class="mb-1 mt-3"
        label="Заявка анкета"
        single
        :disabled="readonly"
        required
        :maxSize="50000000"
        list />
    </div>
  </div>
</template>

<script>
import FilesUploader from '@/components/FilesUploader.vue'
import ALink from '@/components/Link.vue'
import { mapState } from 'vuex'
import { getEntityName, getEntityRoute } from '@/utils/helperFunc'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  components: { FilesUploader, ALink },
  emits: ['updateAssociatedParty'],
  props: {
    associatedParty: { type: Object },
    associatedPartyType: { type: String },
    readonly: { type: Boolean },
    idx: { type: Number, default: 0 },
  },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    const validations = {}

    if (this.associatedPartyType === 'guarantor') {
      validations['guarantorApplicationForm'] = {
        required,
      }
    } else if (this.associatedPartyType === 'subtenant') {
      validations['subtenantApplicationForm'] = {
        required,
      }
    }

    return {
      associatedParty: validations,
    }
  },
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    applicationForm: {
      get() {
        return this.associatedParty[
          `${this.associatedPartyType}ApplicationForm`
        ]
      },
      set(val) {
        this.$emit('updateAssociatedParty', val)
      },
    },
    typeContractor() {
      return this.associatedParty.entityTypeId === 2
    },
  },
  methods: {
    getEntityName,
    getEntityRoute,
  },
}
</script>

<style scoped>
.data-card {
  display: flex;
  flex-direction: column;
}
</style>
