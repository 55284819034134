<template>
  <div class="pr-2">
    <div v-if="!$loading.isLoading('carForSaleSingle')">
      <div
        v-if="!isCreate"
        class="mb-6 d-flex align-center justify-space-between">
        <div>
          <span class="text-disabled">Статус:</span>
          {{ car.status.name }}
        </div>
        <v-btn
          v-show="car.statusId === 1"
          variant="flat"
          class="text-white"
          color="#fb7146"
          :loading="$loading.isLoading('carForSaleSetSale')"
          @click="setSale()">
          Продано
        </v-btn>
      </div>
      <b-form
        class="mt-3"
        hide-action-buttons
        :readonly="readonly || $loading.isLoading('carForSaleSubmit')">
        <v-row>
          <v-col :col="12" :md="6">
            <car-api
              :model-value="car.$object"
              :v="v$"
              @update:model-value="handleCarApi($event)"></car-api>
          </v-col>
          <v-col :col="12" :md="6">
            <v-row class="mb-3" align="center">
              <v-col :cols="12" :sm="5" class="pt-0 pb-0">
                <span>Ціна $</span>
              </v-col>
              <v-col :cols="12" :sm="7" class="pt-0 pb-0">
                <b-input-number
                  v-model="car.price"
                  rankSpacing
                  placeholder="введіть значення"
                  hide-details
                  :error-messages="getErrorMessages(v$.price)"
                  @blur="v$.price.$touch()"></b-input-number>
              </v-col>
            </v-row>
            <v-row class="mb-3" align="center">
              <v-col :cols="12" :sm="5" class="pt-0 pb-0">
                <span>Держ. номер</span>
              </v-col>
              <v-col :cols="12" :sm="7" class="pt-0 pb-0">
                <b-input
                  v-model="car.carNumber"
                  v-mask:[carNumberMask]
                  placeholder="введіть значення"
                  hide-details
                  :error-messages="getErrorMessages(v$.carNumber)"
                  @blur="v$.carNumber.$touch()"></b-input>
              </v-col>
            </v-row>
            <v-row class="mb-3" align="center">
              <v-col :cols="12" :sm="5" class="pt-0 pb-0">
                <span>VIN-код</span>
              </v-col>
              <v-col :cols="12" :sm="7" class="pt-0 pb-0">
                <b-input
                  v-model="car.vinCode"
                  :length="17"
                  placeholder="введіть значення"
                  hide-details
                  :error-messages="getErrorMessages(v$.vinCode)"
                  @blur="v$.vinCode.$touch()"></b-input>
              </v-col>
            </v-row>
            <v-row class="mb-3" align="center">
              <v-col :cols="12" :sm="5" class="pt-0 pb-0">
                <span>Пробіг</span>
              </v-col>
              <v-col :cols="12" :sm="7" class="pt-0 pb-0">
                <b-input-number
                  v-model="car.mileage"
                  placeholder="введіть значення"
                  rank-spacing
                  hide-details></b-input-number>
              </v-col>
            </v-row>
            <v-row class="mb-3">
              <v-col :cols="12" :sm="5" class="pt-0 pb-0">
                <span>Посилання на оголошення</span>
              </v-col>
              <v-col :cols="12" :sm="7" class="pt-0 pb-0">
                <link-editor v-model="car.link"></link-editor>
              </v-col>
            </v-row>
            <v-row class="mb-3">
              <v-col :cols="12" :sm="5" class="pt-0 pb-0">
                <span>Опис</span>
              </v-col>
              <v-col :cols="12" :sm="7" class="pt-0 pb-0">
                <v-textarea
                  v-model="car.description"
                  placeholder="введіть значення"
                  hide-details></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="12">
                <files-uploader
                  v-model:documents="car.photos"
                  label="Фото"></files-uploader>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </b-form>
    </div>
    <section-loader
      v-show="$loading.isLoading('carForSaleSingle')"></section-loader>

    <action-buttons
      :confirm="submit"
      :cancel="cancel"
      :confirm-disable="!car.$hasChanges"
      :cancel-disable="
        !car.$hasChanges || $loading.isLoading('carForSaleSubmit')
      "
      :confirm-loading="
        $loading.isLoading('carForSaleSubmit')
      "></action-buttons>
  </div>
</template>

<script lang="ts">
import CarApi from '@/components/car-api/index.vue'
import { useCarCreate } from './carCreate'
import { useCarUpdate } from './carUpdate'
import ActionButtons from '@/components/action-buttons.vue'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { getErrorMessages } from 'best-modules/utils'
import SectionLoader from '@/components/section-loader.vue'
import { BInputNumber, BForm } from 'best-modules/components'
import LinkEditor from './LinkEditor.vue'
import FilesUploader from '@/components/FilesUploader.vue'

export default {
  name: 'CarSaleSingle',
  components: {
    FilesUploader,
    SectionLoader,
    ActionButtons,
    CarApi,
    BInputNumber,
    BForm,
    LinkEditor,
  },
  methods: { getErrorMessages },
  setup() {
    const route = useRoute()
    const isCreate = computed(() => {
      return route.name === 'car-for-sale-create'
    })

    const {
      car,
      v$,
      submit,
      cancel,
      handleCarApi,
      setSale = () => false,
    } = isCreate.value ? useCarCreate() : useCarUpdate()

    const carNumberMask = {
      mask: '@@ #### @@',
    }

    const readonly = computed<boolean>(() => {
      return !isCreate.value && car.statusId === 2
    })

    return {
      car,
      v$,
      submit,
      cancel,
      handleCarApi,
      setSale,
      isCreate,
      carNumberMask,
      readonly,
    }
  },
}
</script>

<style scoped></style>
