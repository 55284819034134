<template>
  <PaymentHistory
    class="mt-2"
    :requestDataProp="calculation"
    :showHeader="false"
    :pageMinHeight="400"
    :float="2"
    :showTabs="false"
    showExport>
  </PaymentHistory>
</template>
<script>
import PaymentHistory from '@/components/payment-history.vue'
import { mapState } from 'vuex'
export default {
  components: { PaymentHistory },
  computed: {
    ...mapState({
      calculation: state => state.contracts.dflContract.calculation,
    }),
  },
}
</script>
