<template>
  <div class="mt-2">
    <div>
      <div>
        <v-row
          v-if="
            officialDealer ||
            notOfficialDealer ||
            [2, 3, 5].includes(dealerData.typeId)
          "
          style="background: #f2f5fa; border-radius: 4px">
          <div
            class="pt-1 pb-0"
            style="width: 100%; padding-left: 5px; padding-right: 5px">
            <div style="padding: 10px">
              <div
                class="d-flex"
                style="
                  width: 100%;
                  justify-content: space-between;
                  align-items: center;
                ">
                <span class="area-label d-block">Контрагент</span>
                <div v-if="dealerData.contractorId">
                  <span class="area-label">ЕДРПОУ:</span>
                  <span v-if="dealerData.contractorId" style="color: #000">{{
                    dealerData?.contractor?.edrpou || '---'
                  }}</span>
                </div>
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-top: 10px;
                ">
                <div v-if="dealerData.contractor">
                  <ALink
                    v-if="dealerData.contractor"
                    :text="dealerData.contractor.shortName"
                    :route="{
                      name: 'contractors-form',
                      params: { id: dealerData.contractor.id },
                    }">
                  </ALink>
                </div>
                <v-dialog v-model="contractorDialog.dialog" width="450">
                  <v-card>
                    <div class="pa-3">
                      <div
                        class="d-flex mb-3"
                        style="justify-content: space-between">
                        <span class="font-weight-bold">
                          Оберіть контрагента</span
                        >
                        <span
                          style="cursor: pointer"
                          @click="toggleContractorDialog(false)">
                          <v-icon>{{ 'mdi-close' }}</v-icon>
                        </span>
                      </div>
                      <b-server-autocomplete
                        v-model="contractorDialog.contractor"
                        :url="urlGetAllContractors()"
                        :search-min-length="3"
                        :default-item="dealerData.contractor"
                        item-value="id"
                        item-title="shortName"
                        return-object
                        hide-details
                        :readonly="readOnly"
                        :menu-props="{ zIndex: 1000000000, maxWidth: 300 }"
                        placeholder="Введіть текст (мін. 3 символи)">
                        <template #append>
                          <v-scroll-x-transition>
                            <v-btn
                              v-if="!readOnly"
                              v-show="dealerData.contractorId"
                              icon
                              size="x-small"
                              class="rounded-circle"
                              @click="removeContractorDialog">
                              <v-icon style="color: #fc7247" size="small">{{
                                'mdi-delete'
                              }}</v-icon>
                            </v-btn>
                          </v-scroll-x-transition>
                        </template>
                      </b-server-autocomplete>
                    </div>
                    <v-card-actions>
                      <v-btn
                        style="background: #09487a"
                        class="text-white"
                        block
                        :loading="loading"
                        @click="changeContractor"
                        >Підтвердити</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <span
                  data-readonly="hide"
                  style="
                    color: #fc7247;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                  "
                  @click="toggleContractorDialog(true)">
                  <svg
                    v-if="dealerData.contractor"
                    class="mr-1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="18"
                    viewBox="0 0 17 18"
                    fill="none">
                    <path
                      d="M0 17.5H16.5M8.4535 3.59233L11.0458 1L15.5833 5.5375L12.991 8.12983M8.4535 3.59233L3.31375 8.73208C3.14183 8.90395 3.04522 9.13707 3.04517 9.38017V13.5382H7.20317C7.44626 13.5381 7.67938 13.4415 7.85125 13.2696L12.991 8.12983M8.4535 3.59233L12.991 8.12983"
                      stroke="#FC7247"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                  {{ dealerData.contractor ? 'Змiнити' : '+ Додати' }}
                </span>
              </div>
            </div>
          </div>
        </v-row>
        <v-row v-if="commisionPlatform" class="white-block mt-2">
          <v-col :cols="12" :sm="12" class="pt-1 pb-2">
            <v-row class="align-center mb-3">
              <span class="card-block-title align-center" style="display: flex">
                <svg
                  style="margin-right: 9px"
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="20"
                  viewBox="0 0 30 20"
                  fill="none">
                  <path
                    d="M17 16.9V14.4C17 13.7 16.9 13 16.5 12.3C16.1 11.6 15.6 11 14.9 10.6C14.2 10.1 12.7 9.99998 12 9.99998L10.4 11.7L11 13V16L10 17.1L9 16V13L9.7 11.7L8 9.99998C7.2 9.99998 5.7 10.1 5 10.6C4.3 11 3.9 11.6 3.5 12.3C3.1 13 3 13.6 3 14.4V16.9C3 16.9 5.6 18 10 18C14.4 18 17 16.9 17 16.9ZM10 2.09998C8.1 2.09998 7 3.89998 7.3 5.89998C7.6 7.89998 8.6 9.29998 10 9.29998C11.4 9.29998 12.4 7.89998 12.7 5.89998C13 3.79998 11.9 2.09998 10 2.09998Z"
                    fill="#08487A" />
                  <path
                    d="M27 16.9V14.4C27 13.7 26.9 13 26.5 12.3C26.1 11.6 25.6 11 24.9 10.6C24.2 10.1 22.7 9.99998 22 9.99998L20.4 11.7L21 13V16L20 17.1L19 16V13L19.7 11.7L18 9.99998C17.2 9.99998 15.7 10.1 15 10.6C14.3 11 13.9 11.6 13.5 12.3C13.1 13 13 13.6 13 14.4V16.9C13 16.9 15.6 18 20 18C24.4 18 27 16.9 27 16.9ZM20 2.09998C18.1 2.09998 17 3.89998 17.3 5.89998C17.6 7.89998 18.6 9.29998 20 9.29998C21.4 9.29998 22.4 7.89998 22.7 5.89998C23 3.79998 21.9 2.09998 20 2.09998Z"
                    fill="#08487A" />
                </svg>
                Продавці
                <v-tooltip text="Додати продавця" bottom color="grey darken-4">
                  <template #activator="{ props }">
                    <v-btn
                      style="margin-left: 9px"
                      size="x-small"
                      icon
                      v-bind="props"
                      @click="addDealerOutletSeller">
                      <AddIcon />
                    </v-btn>
                  </template>
                  <span>Додати продавця</span>
                </v-tooltip>
              </span>
            </v-row>
            <v-data-table
              :headers="sellersTHead"
              :items="dealerData.sellers"
              mobileBreakpoint="750"
              dense
              hide-default-footer
              :hide-default-header="!dealerData.sellers.length"
              :items-per-page="150">
              <!-- eslint-disable-next-line -->
              <template #item.responsible="{ item }">
                {{ getResponsibleShortName(item.seller.responsible) }}
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.seller="{ item }">
                <ALink
                  :text="getEntityName(item.seller)"
                  :route="{
                    name: [1, 3].includes(item.providerTypeId)
                      ? 'individuals-form'
                      : 'contractors-form',
                    params: {
                      id: item.seller.id,
                    },
                  }">
                </ALink>
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.actions="{ item }">
                <TableCrud
                  data-readonly="hide"
                  :actionsArray="[
                    {
                      action: () =>
                        deleteDealerOutletSeller({
                          ...item,
                          fullName: item.seller?.fullName,
                          shortName: item.seller?.shortName,
                        }),
                      text: 'Видалити',
                      icon: 'mdi-delete',
                    },
                  ]">
                </TableCrud>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row v-if="dealerData.saleObjectTypes" class="mt-5 white-block">
          <v-fade-transition hide-on-leave>
            <v-col>
              <div class="mb-5 d-flex align-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="18"
                  viewBox="0 0 20 18"
                  fill="none">
                  <path
                    d="M3.5 15.5834V16.6667C3.5 16.9736 3.39636 17.2307 3.18908 17.438C2.98108 17.646 2.72361 17.75 2.41667 17.75H1.33333C1.02639 17.75 0.769278 17.646 0.562 17.438C0.354 17.2307 0.25 16.9736 0.25 16.6667V8.00002L2.525 1.50002C2.63333 1.17502 2.82761 0.913215 3.10783 0.714604C3.38733 0.515993 3.69861 0.416687 4.04167 0.416687H15.9583C16.3014 0.416687 16.613 0.515993 16.8933 0.714604C17.1728 0.913215 17.3667 1.17502 17.475 1.50002L19.75 8.00002V16.6667C19.75 16.9736 19.646 17.2307 19.438 17.438C19.2307 17.646 18.9736 17.75 18.6667 17.75H17.5833C17.2764 17.75 17.0193 17.646 16.812 17.438C16.604 17.2307 16.5 16.9736 16.5 16.6667V15.5834H3.5ZM3.28333 5.83335H16.7167L15.5792 2.58335H4.42083L3.28333 5.83335ZM5.125 12.3334C5.57639 12.3334 5.95989 12.1752 6.2755 11.8589C6.59183 11.5432 6.75 11.1597 6.75 10.7084C6.75 10.257 6.59183 9.87346 6.2755 9.55785C5.95989 9.24152 5.57639 9.08335 5.125 9.08335C4.67361 9.08335 4.29011 9.24152 3.9745 9.55785C3.65817 9.87346 3.5 10.257 3.5 10.7084C3.5 11.1597 3.65817 11.5432 3.9745 11.8589C4.29011 12.1752 4.67361 12.3334 5.125 12.3334ZM14.875 12.3334C15.3264 12.3334 15.7099 12.1752 16.0255 11.8589C16.3418 11.5432 16.5 11.1597 16.5 10.7084C16.5 10.257 16.3418 9.87346 16.0255 9.55785C15.7099 9.24152 15.3264 9.08335 14.875 9.08335C14.4236 9.08335 14.0401 9.24152 13.7245 9.55785C13.4082 9.87346 13.25 10.257 13.25 10.7084C13.25 11.1597 13.4082 11.5432 13.7245 11.8589C14.0401 12.1752 14.4236 12.3334 14.875 12.3334Z"
                    fill="#08487A" />
                </svg>
                <span class="card-block-title">Продавані об’єкти</span>
              </div>
              <v-row>
                <v-col :cols="12" :sm="3">
                  <span class="area-label">Об’єм продажів в місяць, грн</span>
                  <v-text-field
                    id="dill-sell-amount"
                    v-model="dealerData.salesVolumeLeasing"
                    placeholder="Введіть значення"
                    dense
                    :readonly="readOnly"
                    @input="
                      changeInputVal('dill-sell-amount', 'input', [
                        keepDigit,
                        formutSum,
                      ])
                    ">
                  </v-text-field>
                </v-col>
                <v-col :cols="12" :sm="3">
                  <span class="area-label">Об’єм продажів в кредит, грн</span>
                  <v-text-field
                    id="dill-sell-amount-cr"
                    v-model="dealerData.salesVolumeCredit"
                    placeholder="Введіть значення"
                    dense
                    :readonly="readOnly"
                    @input="
                      changeInputVal('dill-sell-amount-cr', 'input', [
                        keepDigit,
                        formutSum,
                      ])
                    ">
                  </v-text-field>
                </v-col>
              </v-row>
              <span class="area-label">Тип продаваних об'єктів</span>
              <v-autocomplete
                v-model="dealerData.saleObjectTypes"
                :error-messages="
                  getSafe(() => dealerData.saleObjectTypes.length)
                    ? ''
                    : selectedSaleObjectsErr
                "
                :items="saleObjectTypes"
                placeholder="Оберіть зi списку"
                item-title="name"
                item-value="id"
                class="pt-0"
                multiple
                small-chips
                return-object
                :list-props="{
                  density: 'compact',
                  slim: true,
                }"
                deletable-chips
                chips
                dense
                :readonly="readOnly"
                @blur="getSafe(() => v$.dealer.saleObjectTypes.$touch())">
              </v-autocomplete>
              <div v-for="item in dealerData.saleObjectTypes" :key="item.id">
                <v-scroll-x-transition hide-on-leave>
                  <v-row>
                    <v-col cols="12" class="pt-0 pb-0">
                      <MarkInput
                        v-if="[1, 2, 6, 7].includes(item.id)"
                        :dealerData="dealerData"
                        :readOnly="readOnly"
                        :objectType="item"
                        :v="v$">
                      </MarkInput>
                      <BodyTypeInput
                        v-else
                        :object-type="item"
                        :readOnly="readOnly"
                        :v="v$"></BodyTypeInput>
                    </v-col>
                  </v-row>
                </v-scroll-x-transition>
              </div>
            </v-col>
          </v-fade-transition>
        </v-row>
      </div>
    </div>
    <v-row class="mt-5 white-block">
      <div style="padding: 13px; width: 100%">
        <v-row>
          <v-col cols="12" md="6" sm="12">
            <div class="d-flex align-center">
              <ContactIcon style="width: 20px; height: 20px" />
              <span class="card-block-title"> Контактні особи </span>
              <TableCrud
                data-readonly="hide"
                :actionsArray="[
                  {
                    action: () =>
                      openDealerAgent(
                        'addDealerAgent',
                        'Додано контактну особу',
                        {
                          employeesIds: employeesIds,
                          dealerPointId: parseInt($route.params.id),
                        }
                      ),
                    text: 'Додати контактну особу',
                    icon: 'mdi-plus-circle-outline',
                  },
                  {
                    action: () =>
                      $store.commit('setDialog', {
                        title: 'Контактна особа',
                        dialogItem: {
                          dealerPointId: parseInt($route.params.id),
                        },
                        params: {
                          dealerOutletId: $route.params.id,
                          cb: () => {
                            $emit('refreshData')
                            $setSnackbar({
                              text: 'Створено контактну особу',
                            })
                          },
                        },
                        action: 'createDealerOutletContactPerson',
                      }),
                    text: 'Створити контактну особу',
                    icon: 'mdi-plus-circle-outline',
                  },
                ]"
                customIcon="AddIcon"
                :disabled="readOnly && ![2, 3].includes(user.roleId)">
              </TableCrud>
            </div>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-text-field
              v-if="dealer.employees.length"
              v-model="search"
              class="pt-0 mt-0"
              hide-details
              placeholder="Пошук"
              dense>
            </v-text-field>
          </v-col>
        </v-row>
        <v-card-text style="padding: 0">
          <v-data-table
            :headers="tHead"
            :items="getSafe(() => dealer.employees, [])"
            class="calc-table --cust-cell-pd"
            mobileBreakpoint="750"
            dense
            :search="search"
            hide-default-footer
            :hide-default-header="getSafe(() => !dealer.employees.length, true)"
            :items-per-page="50">
            <!-- eslint-disable-next-line -->
            <template #item.actions="{ item }">
              <TableCrud
                style="width: 25px"
                :actionsArray="[
                  {
                    action: () =>
                      openDealerAgent(
                        'deleteDealerAgent',
                        'Контактну особу вiдкрiплено',
                        item
                      ),
                    text: 'Видалити',
                    icon: 'mdi-delete',
                    disabled:
                      readOnly && item.contact.responsibleId !== user.id,
                  },
                  {
                    action: () =>
                      openDealerAgent(
                        'editDealerOutletContactPerson',
                        'Контактна особа вiдредактована',
                        { ...item, contact: item.contact }
                      ),
                    text: 'редагувати',
                    icon: 'mdi-pencil',
                    disabled:
                      readOnly && item.contact.responsibleId !== user.id,
                  },
                  ...(!item.isAgent
                    ? [
                        {
                          action: () => convertEmployeeToAgent(item.id),
                          text: 'конвертувати в агента',
                          icon: 'mdi-account-convert',
                          disabled: readOnly,
                        },
                      ]
                    : []),
                ]"
                :disabled="readOnly && ![2, 3].includes(user.roleId)"
                addList>
              </TableCrud>
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.contact.responsible="{ item }">
              {{
                getSafe(
                  () => getResponsibleShortName(item.contact.responsible),
                  '---'
                )
              }}
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.isAgent="{ item }">
              {{ item.isAgent ? 'Агент' : 'Співробiтник' }}
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.post="{ item }">
              {{ getSafe(() => item.post.name, '---') }}
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.contact.fullName="{ item }">
              <ALink
                v-if="item.isAgent"
                :text="getSafe(() => item.contact.fullName)"
                :route="{
                  name: 'agent-form',
                  params: { id: getSafe(() => item.agentId) },
                }">
              </ALink>
              <ALink
                v-if="!item.isAgent"
                :text="getSafe(() => item.contact.fullName)"
                :route="{
                  name: 'individuals-form',
                  params: { id: getSafe(() => item.contact.id) },
                }">
              </ALink>
            </template>
          </v-data-table>
        </v-card-text>
      </div>
    </v-row>
  </div>
</template>
<script>
import AddIcon from '@/assets/svg/add-circle.vue'
import ContactIcon from '@/assets/svg/contact-icon.vue'
import MarkInput from '../MarkInput.vue'
import BodyTypeInput from '../BodyTypeInput.vue'
import ALink from '@/components/Link.vue'
import {
  formutSum,
  keepDigit,
  changeInputVal,
  maxNum,
} from '@/utils/formatFunc'
import { contractorFilter, getResponsible, getSafe } from '@/utils/helperFunc'
import {
  urlConvertEmployeeToAgent,
  urlGetAllContractors,
} from '@/pages/request'
import { mapState } from 'vuex'
import {
  setErrHandler,
  getResponsibleShortName,
  getEntityName,
} from '@/utils/helperFunc'
import { dealer as D } from '@/type-ids'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
import TableCrud from '@/components/table-crud.vue'
import { BServerAutocomplete } from 'best-modules/components'

export default {
  components: {
    TableCrud,
    ALink,
    MarkInput,
    ContactIcon,
    AddIcon,
    BodyTypeInput,
    BServerAutocomplete,
  },
  emits: ['refreshData', 'setCache'],
  props: {
    dealer: { type: Object },
    readOnly: { type: Boolean },
    v: { type: Object },
    notOfficialDealer: { type: Boolean },
    commisionPlatform: { type: Boolean },
    officialDealer: { type: Boolean },
  },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  data: () => ({
    D: D, // dealer ids
    search: null,
    loading: false,
    tHead: [
      {
        title: 'ПIБ',
        key: 'contact.fullName',
        align: 'start',
        sortable: false,
      },
      { title: 'Тип', key: 'isAgent', align: 'start', sortable: false },
      { title: 'Посада', key: 'post', align: 'start', sortable: false },
      { title: 'Роль', key: 'role.name', align: 'start', sortable: false },
      {
        title: 'Відповідальний',
        key: 'contact.responsible',
        align: 'start',
        sortable: false,
      },
      { title: 'Дії', key: 'actions', align: 'end', sortable: false },
    ],
    sellersTHead: [
      { title: 'Назва/ПIБ', key: 'seller', align: 'start', sortable: false },
      {
        title: 'Відповідальний',
        key: 'responsible',
        align: 'start',
        sortable: true,
      },
      { title: 'Дії', key: 'actions', align: 'end', sortable: false },
    ],
    contractorDialog: {
      dialog: false,
      contractor: null,
    },
    validations() {
      return {
        dealer: {
          taskMessage: { required },
        },
      }
    },
  }),
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
      user: state => state.user,
    }),
    validation() {
      return { v$: this.v$.dealer, name: 'dealer' }
    },
    dealerData() {
      return this.dealer || {}
    },
    approvedDiscountCommentErr() {
      return setErrHandler(this.v$.dealer?.approvedDiscountComment)
    },
    individualProgramsCommentErr() {
      return setErrHandler(this.v$.dealer?.individualProgramsComment)
    },
    posTerminalsCommentErr() {
      return setErrHandler(this.v$.dealer?.posTerminalsComment)
    },
    generalProgramCommentErr() {
      return setErrHandler(this.v$.dealer?.generalProgramComment)
    },
    selectedSaleObjectsErr() {
      return setErrHandler(this.v$.dealer?.saleObjectTypes)
    },
    employeesIds() {
      return (this.dealer.employees || []).map(item => item?.contact?.id)
    },
    saleObjectTypes() {
      return this.selectItems.leasingObjectTypes.map(o => {
        o['marks'] = []
        o['types'] = []
        return o
      })
    },
  },
  methods: {
    urlGetAllContractors,
    getSafe,
    getResponsible,
    maxNum,
    formutSum,
    keepDigit,
    changeInputVal,
    contractorFilter,
    getResponsibleShortName,
    getEntityName,
    openDealerAgent(
      action,
      message,
      item = {
        contactId: null,
        postId: null,
        dealerPointId: parseInt(this.$route.params.id),
      }
    ) {
      this.$store.commit('setDialog', {
        title: 'Контактна особа диллера',
        dialogItem: item,
        params: {
          cb: () => {
            this.$emit('refreshData')
            this.$setSnackbar({ text: message })
          },
          dealerId: item.contractorId,
          agentId: item.id,
        },
        action: action,
      })
    },
    removeContractorDialog() {
      this.contractorDialog.contractor = null
      this.contractorDialog.contractorId = null
    },
    convertEmployeeToAgent(employeeId) {
      return this.$axios
        .get(urlConvertEmployeeToAgent(employeeId))
        .then(res => {
          this.$setSnackbar({ text: 'Cпівробiтника конвертовано в агента' })
          this.$emit('refreshData')
          return res
        })
        .catch(this.$err)
    },
    addDealerOutletSeller() {
      return this.$store.commit('setDialog', {
        title: 'Продавець диллера',
        dialogItem: {},
        params: {
          dealerOutlet: { id: this.dealerData.id, name: this.dealerData.name },
          cb: () => {
            this.$setSnackbar({ text: 'Продавця успішно додано' })
            this.$emit('refreshData')
          },
          existedSellers: this.dealerData.sellers.map(seller =>
            JSON.stringify({
              providerId: seller.providerId,
              providerTypeId: seller.providerTypeId,
            })
          ),
        },
        action: 'addDealerSeller',
      })
    },
    deleteDealerOutletSeller(item) {
      return this.$store.commit('setDialog', {
        title: 'Продавець диллера',
        dialogItem: item,
        params: {
          dealerOutlet: { id: this.dealerData.id, name: this.dealerData.name },
          cb: () => {
            this.$setSnackbar({ text: 'Продавця видалено' })
            this.$emit('refreshData')
          },
          submitText: 'Підтвердити',
        },
        action: 'deleteDealerSeller',
      })
    },
    toggleContractorDialog(value) {
      this.contractorDialog.dialog = value
    },
    changeContractor() {
      this.loading = true
      this.toggleContractorDialog(false)
      this.dealerData.contractor = this.contractorDialog.contractor
      if (this.contractorDialog.contractor) {
        this.dealerData.contractorId = this.contractorDialog.contractor.id
      } else {
        this.dealerData.contractorId = null
      }
      this.loading = false
    },
  },
  created() {
    const setCache = () => this.$emit('setCache')

    this.$store.dispatch('addLeasingObjectTypes').then(setCache)
  },
  mounted() {
    this.contractorDialog.contractor = this.dealerData.contractor
  },
}
</script>

<style lang="scss" scoped>
.text {
  &-field {
    margin-bottom: 15px;
  }
  &-title {
    color: #1c1d21;
    font-weight: 600;
    font-size: 15px;
    margin-right: 6px;
  }
  &-desc {
    font-weight: 400;
    font-size: 16px;
    color: #1c1d21;
  }
}
.card-block-title {
  display: block;
  color: #08487a;
  font-weight: 600;
  font-size: 16px;
  margin-left: 9px;
}
.title-label {
  font-size: 15px;
  color: #1c1d21;
  margin-top: 5px;
  font-weight: 400 !important;
}

.append-text {
  padding: 0 !important;
}
</style>
