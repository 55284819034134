<template>
<svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" :style="addProps" viewBox="0 0 185 60" fill="none">
<path d="M94.1043 20.002C102.501 20.002 110.899 20.002 119.296 20.002C119.282 21.4604 119.333 22.9154 119.412 24.3704C112.551 24.3944 105.687 24.3636 98.8224 24.3841C98.8121 26.4431 98.8155 28.5021 98.8224 30.5576C105.392 30.5542 111.958 30.5508 118.528 30.5611C118.535 31.9577 118.535 33.3579 118.531 34.758C111.958 34.7614 105.389 34.7545 98.819 34.758C98.8155 37.0743 98.8121 39.3872 98.8224 41.7001C105.707 41.7722 112.589 41.556 119.474 41.6212C119.474 43.0556 119.477 44.4866 119.464 45.921C110.991 45.9039 102.519 45.9107 94.0426 45.9176C93.8163 40.4853 93.9741 35.0462 93.9295 29.6105C93.9158 26.4054 93.9055 23.2002 94.1043 20.002Z" fill="white"/>
<path d="M125.015 21.4055C126.969 20.3074 129.266 20.0981 131.461 20.002C137.242 20.0054 143.023 19.9951 148.8 20.0088C148.879 21.4844 148.883 22.96 148.889 24.4391C144.23 24.6518 139.563 24.5077 134.903 24.5489C132.616 24.5866 130.226 24.3602 128.053 25.2112C127.137 25.5784 126.894 26.6593 126.918 27.5481C126.918 28.3786 126.87 29.4801 127.723 29.94C128.855 30.5783 130.202 30.4307 131.451 30.5234C135.116 30.6091 138.788 30.5062 142.454 30.5748C144.806 30.6984 147.418 31.093 149.092 32.9221C150.525 34.4011 150.642 36.6042 150.6 38.5499C150.587 40.506 150.117 42.6439 148.574 43.9788C146.688 45.5608 144.13 45.8868 141.754 45.9142C135.497 45.9176 129.239 45.8662 122.985 46C122.985 44.521 122.988 43.0385 122.981 41.5595C129.088 41.3296 135.205 41.5183 141.315 41.4359C142.532 41.357 143.88 41.4085 144.909 40.6432C145.732 39.9912 145.804 38.8313 145.797 37.8636C145.797 36.9954 145.546 35.9042 144.586 35.6468C142.786 35.0806 140.869 35.3242 139.014 35.2727C135.418 35.2075 131.793 35.4992 128.221 34.9502C126.517 34.6447 124.672 34.1575 123.537 32.7471C122.111 31.0175 122.039 28.6394 122.145 26.5049C122.248 24.5009 123.177 22.3835 125.015 21.4055Z" fill="white"/>
<path d="M151.561 20.002C160.373 20.0054 169.188 19.9917 178 20.0088C177.993 21.5188 177.997 23.0252 177.979 24.5352C174.352 24.566 170.721 24.542 167.093 24.5489C167.059 31.7004 167.114 38.8485 167.062 46C165.526 45.9966 163.993 46 162.464 45.9966C162.444 38.845 162.492 31.697 162.44 24.5455C158.813 24.5523 155.185 24.5523 151.557 24.5455C151.54 23.0321 151.543 21.5153 151.561 20.002Z" fill="white"/>
<path d="M61.949 20.3487C69.0157 20.3384 76.0859 20.3487 83.1527 20.3418C85.3025 20.3521 87.7061 20.8051 89.1154 22.5861C90.5177 24.3808 90.4149 26.8036 90.2709 28.9518C90.24 30.2215 89.3759 31.2063 88.5599 32.0814C89.4034 32.8158 90.4114 33.4918 90.8023 34.5934C91.3578 36.0758 91.2824 37.6852 91.2789 39.2398C91.2686 40.6845 91.0732 42.2802 90.0308 43.3749C88.8582 44.6961 87.1369 45.3961 85.4191 45.6192C82.0314 46.0207 78.6232 45.5849 75.2321 45.8011C70.8056 45.8971 66.3756 45.7805 61.949 45.7358C61.9558 37.2735 61.9558 28.8111 61.949 20.3487ZM66.6739 24.4323C66.667 26.5016 66.6704 28.5708 66.6704 30.6401C72.0536 30.6367 77.4403 30.647 82.8235 30.6367C83.763 30.6024 84.9905 30.4651 85.4225 29.4837C85.786 28.2208 85.8203 26.8413 85.4945 25.5682C84.9768 24.4392 83.5161 24.4392 82.4532 24.3808C77.1934 24.4083 71.9336 24.3156 66.6739 24.4323ZM66.6704 34.7718C66.6704 37.0847 66.6704 39.3942 66.6704 41.7037C72.2765 41.7071 77.8826 41.7105 83.4887 41.7071C84.4728 41.6797 85.6763 41.5493 86.2454 40.6262C86.6878 39.7717 86.5986 38.7628 86.6055 37.8328C86.5437 36.8617 86.5335 35.5611 85.4671 35.1321C83.7253 34.511 81.8394 34.8644 80.0325 34.6894C75.5785 34.6791 71.121 34.6105 66.6704 34.7718Z" fill="white"/>
<path d="M16.6415 9.56523C7.46 14.8706 4.30703 26.6473 9.608 35.8364C10.4235 37.2501 11.3901 38.5176 12.5078 39.6389L16.5248 37.3179C15.5855 36.5058 14.7531 35.5731 14.0277 34.5196L15.9258 33.4228L15.1867 32.1417L13.2445 33.2639C9.5914 26.4213 11.842 17.8755 18.4242 13.7777L19.571 15.7657L20.8511 15.026L19.7043 13.038C26.448 9.73033 34.7405 12.0662 38.7144 18.5467L37.2136 19.4139L37.9527 20.6951L39.4093 19.8534C39.9582 21.0089 40.3491 22.1967 40.5822 23.4168L44.6432 21.0703C44.2318 19.5409 43.6183 18.0693 42.8028 16.6556C37.5901 7.41546 25.8231 4.25989 16.6415 9.56523Z" fill="#FC7247"/>
<path d="M21.7532 45.1165V45.3665H22.0032H36.3522H36.6022V45.1165V42.2933V42.0433H36.3522H26.9696L31.0484 37.7705C31.0489 37.77 31.0493 37.7696 31.0497 37.7691C32.3794 36.417 33.422 35.2632 34.1747 34.309L34.1753 34.3082C34.9291 33.3455 35.4661 32.461 35.7761 31.6561C36.0922 30.8539 36.2509 30.0312 36.2509 29.1896C36.2509 27.9738 35.9447 26.8758 35.3289 25.9029C34.7146 24.9253 33.8605 24.1572 32.7736 23.5984C31.6894 23.0302 30.4424 22.75 29.0393 22.75C27.6492 22.75 26.399 23.0343 25.2947 23.6089C24.1909 24.1761 23.3191 24.9778 22.6843 26.012C22.0467 27.0509 21.7319 28.2587 21.7319 29.6264V29.8764H21.9819H25.1221H25.3721V29.6264C25.3721 28.8789 25.5213 28.2391 25.8108 27.6991C26.1001 27.1595 26.5123 26.7435 27.052 26.4471C27.5911 26.1511 28.2295 25.9986 28.9755 25.9986C29.6798 25.9986 30.3047 26.1373 30.8552 26.4095L30.8552 26.4095L30.8574 26.4105C31.4062 26.6752 31.835 27.0529 32.1498 27.5452L32.1507 27.5465C32.4611 28.0255 32.6214 28.6016 32.6214 29.2855C32.6214 29.8903 32.5008 30.459 32.2602 30.9941C32.016 31.537 31.6427 32.1185 31.1346 32.7385L31.1344 32.7388C30.6287 33.3574 29.984 34.0803 29.1992 34.9082C29.199 34.9085 29.1987 34.9087 29.1985 34.909L21.8233 42.5566L21.7532 42.6292V42.7301V45.1165ZM39.2104 40.8551V41.1051H39.4604H49.5463V45.1165V45.3665H49.7963H52.9365H53.1865V45.1165V41.1051H55.8212H56.0712V40.8551V38.0639V37.8139H55.8212H53.1865V23.2983V23.0483H52.9365H51.2279H50.9779H49.8176H49.5676H48.8809H48.7432L48.6696 23.1647L39.2491 38.0581L39.2104 38.1193V38.1918V40.8551ZM49.5676 37.8139H43.2551L49.5676 27.8134V37.8139Z" fill="#FC7247" stroke="#FC7247" stroke-width="0.5"/>
</svg>
</template>

<script>
export default {
  props: {
    addProps: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 143
    },
    height: {
      type: Number,
      default: 45
    },
    color: {
      type: String,
      default: 'white'
    },
    redColor: {
      type: String,
      default: '#e31e25'
    }
  },

  computed: {
    vBHeight() {
      return this.height.replace(/[^\d]/g, '')
    },
  }
}
</script>