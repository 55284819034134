<template>
  <div>
    <SectionLoader v-if="loading"></SectionLoader>
    <v-fade-transition hide-on-leave>
      <div v-if="!loading">
        <v-row v-if="!loading">
          <v-col cols="12" md="3" sm="12">
            <FieldsColumnBlock title="Основні дані">
              <template #general-fields>
                <Fields :fields="fields" :type="2" direction="column">
                  <template #number_field>
                    <EditableField
                      v-model="agreement.number"
                      label="№ Договору"
                      style="margin-top: -5px"
                      readonly
                      @blur="v$.agreement.number.$touch()" />
                  </template>
                </Fields>
              </template>
              <template #default>
                <div>
                  <span class="label">Тип договору</span>
                  <v-select
                    v-model="agreement.pledgeTypeId"
                    :error-messages="typeIdErr"
                    :items="$directory.get('pledgeTypes', agreement.pledgeType)"
                    item-title="name"
                    item-value="id"
                    hide-details
                    :readonly="readonly"
                    placeholder="Оберіть тип"
                    :loading="$loading.isLoading('pledgeTypes')"
                    @focus="$directory.fill('pledgeTypes')"
                    @blur="v$.agreement.pledgeTypeId.$touch()">
                  </v-select>
                </div>
                <div class="mb-1">
                  <span class="label">Вид підпису</span>
                  <v-select
                    v-model="agreement.signingTypeId"
                    :items="
                      $directory.get('signingTypes', agreement.signingType)
                    "
                    item-title="name"
                    item-value="id"
                    hide-details
                    :readonly="readonly"
                    :disabled="readonly"
                    placeholder="Оберіть вид"
                    :error-messages="
                      getErrorMessages(v$.agreement.signingTypeId)
                    "
                    :loading="$loading.isLoading('signingTypes')"
                    @focus="$directory.fill('signingTypes')"
                    @blur="v$.agreement.signingTypeId.$touch()">
                  </v-select>
                </div>

                <div class="my-1">
                  <v-checkbox
                    v-model="agreement.isNotarization"
                    hide-details
                    :readonly="readonly"
                    color="#fc7247">
                    <template #label>
                      <span style="font-size: 12px">Нотаріальне завірення</span>
                    </template>
                  </v-checkbox>
                </div>
              </template>
            </FieldsColumnBlock>
            <FieldsColumnBlock title="Візування" :fullHeight="!mobile">
              <template #agreements>
                <Agreements :agreements="agreement.agreements" />
              </template>
            </FieldsColumnBlock>
          </v-col>
          <v-col cols="12" md="9" sm="12" :class="`${mobile ? '' : 'pl-0'}`">
            <div class="white-block" style="padding-left: 7px">
              <ContractStatusBar
                class="mb-3"
                :contract="agreement"
                :mobile="mobile"
                :isCancelContract="agreement.isCancelContract"
                :isTerminated="agreement.isTerminated"
                :currentStatus="agreement.statusId"
                :submit="setMortStatus"
                :statuses="filteredStatuses"
                spreadItems
                small>
                <template #actions>
                  <ContractActionButton :actions="btnActions" />
                </template>
              </ContractStatusBar>
              <v-tabs
                v-model="currentTab"
                show-arrows
                class="dkpTabs"
                color="white"
                dark
                slider-color="#fc7247"
                dense
                :height="$vuetify.breakpoint?.xs ? null : 38">
                <v-tab
                  replace
                  :to="{
                    name: 'mortgagor-parties-details',
                    params: { id: $route.params.id },
                  }">
                  Реквізити сторін
                </v-tab>
                <v-tab
                  replace
                  :to="{
                    name: 'mortgagor-pledge-property',
                    params: { id: $route.params.id },
                  }">
                  Об'єкти застави майна
                </v-tab>
                <v-tab
                  replace
                  :to="{
                    name: 'mortgagor-documents',
                    params: { id: $route.params.id },
                  }">
                  Документи
                </v-tab>
                <v-tab
                  replace
                  :to="{
                    name: 'mortgagor-history',
                    params: { id: $route.params.id },
                  }">
                  Історія
                </v-tab>
              </v-tabs>
            </div>
            <router-view
              :agreement="agreement"
              :v="v$"
              :readonly="readonly"
              :mobile="mobile"
              :mortgagorContact="mortgagorContact"
              :mortgagorContractor="mortgagorContractor">
            </router-view>
            <ActionButtons
              :confirm="submit"
              :cancel="getMortgagorAgreement"
              :confirmLoading="submitLoading"
              :cancelDisable="submitLoading || agreementState === cache"
              :confirmDisable="agreementState === cache">
            </ActionButtons>
          </v-col>
        </v-row>
        <div></div>
      </div>
    </v-fade-transition>
  </div>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'
import ActionButtons from '@/components/action-buttons.vue'
import {
  LODescription as getLoDescription,
  getBeautyNum,
  setCache,
  setErrHandler,
  getEntityName,
  v$Notify,
  backDate,
  toFormatDate,
  getContactInitials,
  openDocument,
  getEntityRoute,
} from '@/utils/helperFunc'
import {
  urlUpdateMortgagorAgreement,
  urlGetMortgagorAgreement,
  urlSetCancelContractPledge,
  urlGetMortgagorPrintedForm,
  urlSetMortgragorStatus,
} from '@/pages/request'
import { mapState } from 'vuex'
import { required, minLength } from '@vuelidate/validators'
import EditableField from '@/components/EditableField.vue'
import Fields from '@/components/Fields.vue'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
import { confirm } from 'best-modules/plugins'
import { fillDirectory, getDirectory } from '@/plugins/directory/index.ts'
import FieldsColumnBlock from '@/pages/contracts/components/FieldsColumnBlock.vue'
import Agreements from '@/components/Agreements.vue'
import ContractStatusBar from '@/components/contracts/ContractStatusBar.vue'
import ContractActionButton from '@/pages/contracts/components/ContractActionButton.vue'
import { useDisplay } from 'vuetify'
import { computed } from 'vue'
import { getErrorMessages } from 'best-modules/utils'
export default {
  components: {
    ContractActionButton,
    ContractStatusBar,
    Agreements,
    FieldsColumnBlock,
    Fields,
    EditableField,
    SectionLoader,
    ActionButtons,
  },
  setup() {
    const { xs, sm } = useDisplay()
    const mobile = computed(() => xs.value || sm.value)

    return { v$: useVuelidate(), ...useSelect(), mobile }
  },
  validations() {
    return {
      agreement: {
        date: { required, minLength: minLength(10) },
        pledgeTypeId: { required },
        creditorSignatoryId: { required },
        creditorBasisId: { required },
        signingTypeId: { required },
        mortgagorSignatories: {
          required,
          minLength: minLength(1),
          $each: {
            contactId: { required },
            basisId: { required },
          },
        },
      },
    }
  },
  data: () => ({
    agreement: {},
    submitLoading: false,
    currentTab: 0,
    loading: false,
    cache: null,
    printedForm: {},
  }),
  computed: {
    ...mapState({
      user: state => state.user,
      selectItems: state => state.selectItems,
    }),
    fields() {
      return [
        {
          label: 'Дата договору',
          value: toFormatDate(this.agreement.date, true),
        },
        { label: '№ Договору', slot: 'number' },
        {
          label: 'Боржник',
          value: this.debtor?.name,
          class: { link: true },
          click: () => {
            this.$router.push(
              getEntityRoute(this.debtor.id, this.debtor.lesseeTypeId)
            )
          },
        },
      ]
    },
    pfForms() {
      return [
        {
          show: true,
          label: 'Договір застави',
          action: () => this.openPrintedForm(),
          icon: 'mdi-file',
          disabled: false,
        },
      ]
    },
    btnActions() {
      return {
        main: { label: 'Дії', disabled: false },
        list: [
          {
            show: this.agreement.statusId !== 5,
            label: 'Анулювати договір',
            icon: 'mdi-file-document-remove-outline',
            action: () => this.setCancel(),
            disabled:
              (!this.admin && this.readonly) || this.agreement.isCancelContract,
          },
          {
            show: true,
            label: 'Друковані форми',
            icon: 'mdi-printer',
            actions: this.pfForms,
            disabled: false,
          },
        ],
      }
    },
    agreementId() {
      return this.$route?.params?.id
    },
    debtor() {
      if (this.agreement.debtor) {
        const debtor =
          this.agreement?.debtor?.contact || this.agreement?.debtor?.contractor
        return {
          name:
            debtor?.shortName ||
            getContactInitials(debtor?.fullName, { byName: true }),
          id: debtor?.id,
          lesseeTypeId: this.agreement?.debtor?.lesseeTypeId,
        }
      }
      return '---'
    },
    readonly() {
      return this.agreement.readOnly
    },
    agreementState() {
      return this.setCache([this.agreement])
    },
    admin() {
      return this.user.roleId === 1
    },
    LO() {
      return this.getLoDescription(this.calculation)
    },
    typeIdErr() {
      return this.setErrHandler(this.v$?.agreement?.pledgeTypeId)
    },
    route() {
      return this.$route.name
    },
    mortgagorContact() {
      return [1, 3].includes(this.agreement.mortgagorTypeId)
    },
    mortgagorContractor() {
      return this.agreement.mortgagorTypeId === 2
    },
    filteredStatuses() {
      const filteredStatuses = getDirectory('gcStatuses').filter(item =>
        this.agreement.isCancelContract ? true : item.id !== 6
      )
      return filteredStatuses.map(s => {
        let showCheckIcon
        if (this.agreement.statusId === 6) {
          showCheckIcon = s.id === 6
        }

        return { ...s, showCheckIcon }
      })
    },
  },
  methods: {
    getErrorMessages,
    setCache,
    getEntityName,
    setErrHandler,
    getLoDescription,
    urlSetMortgragorStatus,
    urlSetCancelContractPledge,
    urlUpdateMortgagorAgreement,
    urlGetMortgagorAgreement,
    urlGetMortgagorPrintedForm,
    openPrintedForm() {
      return this.$axios
        .get(urlGetMortgagorPrintedForm(this.agreement.id))
        .then(res => {
          if (res.data.message) {
            return this.$setSnackbar({
              text: res.data.message,
              color: 'warning',
            })
          }
          return openDocument({
            text: `Договір застави ${this.agreement.number} ${
              this.agreement.mortgagor.contractor?.shortName ||
              this.agreement.mortgagor.contact?.fullName
            }`,
            url: res.data.url,
          })
        })
    },
    async setMortStatus(statusId) {
      if ([2, 3].includes(statusId)) {
        try {
          const statusNames = {
            2: 'Підписано Заставодавцем',
            3: 'Візування',
          }
          await confirm({
            text: `Ви впевнені що хочете перевести договір в статус "${statusNames[statusId]}"?`,
          })
        } catch (e) {
          return
        }
      }

      this.loading = true
      const currentStatus = this.agreement.statusId

      if (currentStatus === 5) {
        return this.$setSnackbar({
          text: 'Договiр з статусу підписаного не може бути змiнено',
          color: 'warning',
        })
      }
      if (
        [4, 5].includes(statusId) &&
        this.agreement.agreements?.some(a => !a.isAgreed)
      ) {
        return this.$setSnackbar({
          text: 'Договiр повинен бути завiзований всiма учасниками',
          color: 'warning',
        })
      }
      if (statusId < currentStatus || statusId > currentStatus + 1) {
        return this.$setSnackbar({ text: 'Дія неможлива', color: 'error' })
      }

      return this.$axios
        .post(this.urlSetMortgragorStatus(this.agreement.id), {
          statusId: statusId,
        })
        .then(res => {
          if (res.data.message) {
            this.$setSnackbar({ text: res.data.message, color: 'warning' })
          } else this.$setSnackbar({ text: 'Статус успішно змiнено' })
          this.getMortgagorAgreement()
          return res
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
    setCancel() {
      confirm({
        text: 'Ви дійсно бажаєте анулювати договір?',
      }).then(() => {
        return this.$axios
          .post(urlSetCancelContractPledge(this.agreement.id), {
            isCancelContract: true,
          })
          .then(res => {
            this.$setSnackbar({ text: 'Оновлено' })
            Object.assign(this.agreement, res.data)
            this.setCache([this.agreement])
            return res
          })
      })
    },
    setBreadScrumb() {
      const name = `${this.getEntityName(this.agreement.mortgagor)} ${
        this.agreement.number
      }`
      this.$store.commit('setBreadScrumbPart', [null, null, name, null])
    },
    getMortgagorAgreement() {
      this.loading = true
      return this.$axios
        .get(this.urlGetMortgagorAgreement(this.agreementId))
        .then(res => {
          this.setAgreement(res.data)
          this.setBreadScrumb()
          return res
        })
        .catch(this.$err)
        .finally(() => (this.loading = false))
    },
    setAgreement(obj) {
      obj.pledgeObjectPrice = obj.pledgeObjectPrice
        ? getBeautyNum(obj.pledgeObjectPrice, { float: 2 })
        : ''
      obj.mortgagorSignatories ??= [{ contactId: null, basisId: null }]
      Object.assign(this.agreement, obj)
      this.cache = this.setCache([this.agreement])
    },
    submit() {
      if (this.v$.$invalid) {
        this.v$.$anyError
        this.v$.$touch()
        v$Notify(this.v$.agreement, 'mortgagorAgreement')
        return Promise.resolve()
      }
      this.submitLoading = true
      const requestObj = Object.clone(this.agreement)
      requestObj.pledgeObjectPrice =
        parseFloat(requestObj.pledgeObjectPrice?.replace(/\s/g, '') || 0) ||
        null
      requestObj.date = backDate(requestObj.date)
      if (requestObj.mortgagorSignatories)
        requestObj.mortgagorSignatories = requestObj.mortgagorSignatories.map(
          s => ({
            contactId: s.contactId,
            basisId: s.basisId,
          })
        )

      return this.$axios
        .post(this.urlUpdateMortgagorAgreement(this.agreementId), requestObj)
        .then(res => {
          if (res?.data?.message) throw new Error(res.data.message)
          this.setAgreement(res.data)
          this.$setSnackbar({ text: 'Данi оновлено' })
          return res
        })
        .catch(this.$err)
        .finally(() => (this.submitLoading = false))
    },
  },
  watch: {
    '$route.name': function () {
      this.setBreadScrumb()
    },
  },
  created() {
    this.getMortgagorAgreement()
    fillDirectory('gcStatuses')
  },
}
</script>

<style scoped>
.dkpTabs .v-slide-group__wrapper {
  background: #fff !important;
}
a.v-tab-item--selected.v-tab {
  color: #08487a !important;
  font-weight: 700 !important;
  font-size: 14px;
  letter-spacing: normal;
  padding-left: 0;
  padding-right: 0;
}
a.v-tab {
  margin-right: 20px;
  padding-left: 0;
  padding-right: 0;
  color: #838282 !important;
  letter-spacing: normal !important;
}
.v-slide-group__wrapper {
  background: #fff !important;
}
</style>
