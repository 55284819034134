<template>
  <div>
    <NoData v-if="!contractorProjects" />
    <ProjectCard
      v-for="(project, idx) in filteredProjects"
      :key="project.id + '_' + idx"
      :project="project"
      :entityTypeId="2" />
  </div>
</template>
<script>
import ProjectCard from '@/components/ProjectCard.vue'
import NoData from '@/components/no-table-data.vue'
import { mapState } from 'vuex'
import { formatDate, humanDate } from '@/utils/formatFunc'
export default {
  components: {
    ProjectCard,
    NoData,
  },
  props: {
    contractor: { type: Object },
    pageLoad: { type: Boolean },
  },
  data() {
    return {
      filterDate: null,
    }
  },
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    id() {
      return this.$route?.params?.id
    },
    contractorProjects() {
      return this.contractor?.projects
    },
    filteredProjects() {
      if (!this.filterDate) {
        return this.contractor.projects
      }
      return this.contractor.projects.filter(project => {
        const createdAt = new Date(project.created_at)
        return createdAt >= this.filterDate
      })
    },
  },
  methods: {
    formatDate,
    humanDate,
  },
}
</script>
