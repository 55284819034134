<template>
  <div>
    <v-row>
      <v-col :cols="12" :md="4">
        <span class="subtitle">Прізвище</span>
        <v-text-field
          v-model="applicationData.surname"
          placeholder="Введіть текст">
        </v-text-field>
      </v-col>
      <v-col :cols="12" :md="4">
        <span class="subtitle">Ім'я</span>
        <v-text-field
          v-model="applicationData.name"
          placeholder="Введіть текст">
        </v-text-field>
      </v-col>
      <v-col :cols="12" :md="4">
        <span class="subtitle">По батькові</span>
        <v-text-field
          v-model="applicationData.patronymic"
          placeholder="Введіть текст">
        </v-text-field>
      </v-col>
    </v-row>
    <div>
      <span class="subtitle">ЄДРПОУ/ІНН</span>
      <v-text-field v-model="applicationData.code" placeholder="Введіть текст">
      </v-text-field>
    </div>
    <v-row>
      <v-col :cols="12" :md="4" class="pt-0 pb-0">
        <span class="subtitle">Телефон</span>
        <v-text-field
          id="phoneInput"
          v-model="applicationData.phone"
          placeholder="введіть телефон"
          :error-messages="phoneErr"
          @input="phoneMask($event, 'phoneInput')"
          @blur="
            () => {
              applicationData.phone &&
                applicationData.phone.length === 6 &&
                (applicationData.phone = null)
              $v.application.phone.$touch()
            }
          ">
        </v-text-field>
      </v-col>
      <v-col :cols="12" :md="4" class="pt-0 pb-0">
        <span class="subtitle">Email</span>
        <v-text-field
          v-model="applicationData.email"
          v-mask:[emailMask]
          placeholder="введіть email"
          :error-messages="emailErr"
          @blur="$v.application.email.$touch()">
        </v-text-field>
      </v-col>
      <v-col
        :cols="12"
        :md="4"
        class="d-flex align-center"
        style="position: relative">
        <v-menu>
          <template #activator="{ props }">
            <span class="text__link" v-bind="props"> Деталі </span>
          </template>
          <v-card>
            <v-card-text>
              <span>{{ otherInfo }}</span>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { phoneMask } from '@/utils/masks'
import { emailMask } from 'best-modules/utils'
import { setErrHandler } from '@/utils/helperFunc'
import { useSelect } from '@/utils/mixins/useSelect'

export default {
  emits: ['update:application'],
  props: {
    application: { type: Object },
    v: { type: Object },
  },
  setup() {
    return {
      ...useSelect(),
    }
  },
  data: () => ({
    emailMask,
  }),
  computed: {
    applicationData: {
      get() {
        return this.application
      },
      set(val) {
        this.$emit('update:application', val)
      },
    },
    $v() {
      return this.v
    },
    phoneErr() {
      return setErrHandler(
        this.$v.application.phone,
        'Введене значення не коректне'
      )
    },
    emailErr() {
      return setErrHandler(
        this.$v.application.email,
        'Введене значення не коректне'
      )
    },
    otherInfo() {
      if (this.applicationData && this.applicationData.other !== null) {
        const o = JSON.parse(JSON.stringify(this.applicationData.other))
        delete o.link
        Object.keys(o).forEach(el => o[el] === '' && delete o[el])
        return Object.values(o).join('\n')
      }
      return 'Деталі Відсутні'
    },
  },
  methods: {
    phoneMask,
  },
}
</script>

<style lang="scss" scoped>
.text__link {
  cursor: pointer;
  color: #08487a;
  text-decoration-line: underline;
  font-weight: 500;
  font-size: 12px;
}

.subtitle {
  color: #838282;
  font-weight: 500;
  font-size: 13px;
  display: block;
}
</style>
