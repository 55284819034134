<template>
  <div class="white-block mt-2 cursor-default">
    <Fields class="mb-3" :fields="fields[0]" direction="row" />
    <Fields class="mb-3" :fields="fields[1]" direction="row" />
    <v-row>
      <v-col :cols="12" :md="3">
        <EditableField
          v-model="calculation.vinCode"
          class="mb-3"
          :readonly="readonly"
          :minWidth="200">
          <template #label>
            <span style="font-weight: 500; font-size: 12px; color: #838282">
              VIN-код
            </span>
          </template>
        </EditableField>
      </v-col>

      <v-col :cols="12" :md="5">
        <EditableField
          v-model="contract.currencyCourse"
          :inputType="'number'"
          class="mb-3"
          :readonly="readonly"
          :minWidth="200">
          <template #label>
            <span style="font-weight: 500; font-size: 12px; color: #838282">
              Курс
            </span>
          </template>
        </EditableField>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12" class="pt-0 pb-0">
        <span class="label">Місце відвантаження (заповнюється вручну)</span>
        <div>
          <v-text-field
            v-model="calculation.placeOfShipment"
            placeholder="Введіть адресу"
            dense
            hide-details
            :readonly="readonly"
            :disabled="endorsement">
          </v-text-field>
        </div>
        <v-fade-transition hide-on-leave>
          <div>
            <v-checkbox
              v-model="contract.isGuarantees"
              class="mt-0"
              color="#fc7247"
              style="margin-left: -5px"
              :readonly="readonly"
              hide-details
              dense
              size="small"
              @update:model-value="handleGuarantees">
              <template #label>
                <span style="font-size: 12px">Наявність гарантії </span>
              </template>
            </v-checkbox>
            <div v-show="contract.isGuarantees">
              <span class="label">Деталі гарантії</span>
              <v-text-field
                id="guarantees-month"
                ref="quantityMonthGuarantees"
                v-model="contract.quantityMonthGuarantees"
                :readonly="!contract.isGuarantees"
                :disabled="readonly || !contract.isGuarantees"
                :error-messages="getErrorMessages(v$.quantityMonthGuarantees)"
                dense
                @blur="v$.quantityMonthGuarantees.$touch()">
              </v-text-field>
            </div>
          </div>
        </v-fade-transition>
      </v-col>
    </v-row>
    <v-row class="align-center mt-2">
      <v-col :cols="12" :md="6" class="pt-0 pb-0">
        <div class="mb-3">
          <span class="label">Порядок розрахунків за ДКП</span>
          <v-select
            v-model="contract.orderCalculationId"
            :items="
              $directory.get('dkpOrderCalculations', contract.orderCalculation)
            "
            :error-messages="getErrorMessages(v$.orderCalculationId)"
            item-value="id"
            hide-details
            item-title="name"
            placeholder="оберіть порядок"
            :disabled="readonly || endorsement"
            :loading="$loading.isLoading('dkpOrderCalculations')"
            @focus="$directory.fill('dkpOrderCalculations')"
            @blur="v$.orderCalculationId.$touch()">
          </v-select>
        </div>
      </v-col>
      <v-col :cols="12" :md="6" class="pt-0 pb-0">
        <div style="margin-top: 10px">
          <span class="label">Співвідношення авансу (залишок) у %</span>
          <div style="font-size: 14px">
            {{ `${calculation.prepayToSupplier || 0}` + '%' }}
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
  <FieldsColumnBlock title="Специфікація" class="mt-2">
    <template #default>
      <div>
        <v-hover>
          <template #default="{ isHovering, props }">
            <div v-bind="props" class="d-flex align-center gap-2 mt-2">
              <div>
                <TextShort
                  :textBold="true"
                  :class="{
                    disabled: !specification,
                    'cursor-pointer': readonly,
                  }"
                  @click="() => readonly && openSpecificationDialog()">
                  {{ specification || 'Не вказано' }}
                </TextShort>
              </div>
              <v-fade-transition hide-on-leave>
                <v-btn
                  v-show="isHovering && !readonly"
                  class="mr-3"
                  size="x-small"
                  density="compact"
                  icon
                  @click="openSpecificationDialog">
                  <v-icon color="red" size="small">
                    {{ 'mdi-pencil' }}
                  </v-icon>
                </v-btn>
              </v-fade-transition>
            </div>
          </template>
        </v-hover>
        <FilesUploader
          v-model:documents="calculation.specificationDocuments"
          class="mb-1"
          label="Специфікація"
          :list="false"
          :readonly="readonly">
        </FilesUploader>
      </div>
    </template>
  </FieldsColumnBlock>
</template>

<script lang="ts">
import TextShort from '@/components/TextShort.vue'
import Fields from '@/components/Fields.vue'
import EditableField from '@/components/EditableField.vue'
import FilesUploader from '@/components/FilesUploader.vue'
import {
  getBeautyNum,
  LODescription as getLoDescription,
} from '@/utils/helperFunc'
import { toFormatDate } from '@/utils/helperFunc'
import { maxNumLength } from '@/utils/masks'
import FieldsColumnBlock from '@/pages/contracts/components/FieldsColumnBlock.vue'
import { contractKey, updateContractKey, v$Key } from '../injectionKeys'
import { computed, inject } from 'vue'
import { getErrorMessages } from 'best-modules/utils'

export default {
  components: {
    FieldsColumnBlock,
    TextShort,
    Fields,
    FilesUploader,
    EditableField,
  },
  setup() {
    const injectedContract = inject(contractKey)
    const updateContract = inject(updateContractKey)
    const v$ = inject(v$Key)

    const contract = computed({
      get() {
        return injectedContract
      },
      set(v) {
        return updateContract(v)
      },
    })

    return {
      contract,
      v$,
    }
  },
  props: {
    endorsement: { type: Boolean },
    readonly: { type: Boolean },
  },
  computed: {
    calculation() {
      return this.contract.calculation
    },
    LO() {
      return this.getLoDescription(this.calculation)
    },
    specification() {
      const str = this.contract.specificationText || ''
      return str.length > 47 ? `${str.substring(0, 46)}...` : str
    },
    currency() {
      switch (this.calculation?.requestData?.leasingCurrency) {
        case 'UAH':
          return 'грн'
        case 'USD':
          return '$'
        case 'EUR':
          return '€'
        default:
          return console.warn(
            'calculation.requestData.leasingCurrency - invalid value'
          )
      }
    },
    amountDkp() {
      return (
        this.calculation.requestData.leasingAmountDkp *
        this.contract.currencyCourse
      )
    },
    generalAmountDkp() {
      return (
        this.calculation.requestData.leasingAmount *
        this.contract.currencyCourse
      )
    },
    fields() {
      return [
        [
          {
            label: "Об'єкт лізингу",
            value: this.LO.description({ detailed: true }),
          },
        ],
        [
          {
            label: 'Вартість',
            value: `${getBeautyNum(this.amountDkp, {
              float: 2,
            })} грн`,
          },
          {
            label: 'Загальна вартість',
            value: `${getBeautyNum(this.generalAmountDkp, {
              float: 2,
            })} грн`,
          },
          {
            label: 'Вартість додаткового обладнання',
            value: this.LO.additionalEquipSum,
          },
        ],
      ]
    },
  },
  methods: {
    getErrorMessages,
    getBeautyNum,
    getLoDescription,
    toFormatDate,
    maxNumLength,
    openSpecificationDialog() {
      this.$store.commit('setDialog', {
        title: 'Специфікація',
        dialogItem: {
          specification: this.contract.specificationText || '',
        },
        params: {
          cb: data => (this.contract.specificationText = data),
          dialogWidth: '700px',
          readonly: this.readonly,
          hideActions: this.readonly,
        },
        action: 'editSpecification',
      })
    },
    handleGuarantees(v) {
      if (v) {
        this.$nextTick(() => this.$refs.quantityMonthGuarantees.focus())
      } else {
        this.contract.quantityMonthGuarantees = null
      }
    },
  },
}
</script>

<style scoped>
.label {
  display: block;
  color: #838282;
  font-size: 12px;
}
</style>
