<template>
  <div>
    <form class="login" @submit.prevent="confirmAuth">
      <div class="login__header">
        <span class="link" @click="backToLogin">
          <i class="pi pi-arrow-left mr-2" style="font-size: 24px"></i>
        </span>
        <div class="d-flex justify-space-between w-100 align-center">
          <span class="login__header__text d-block">Аутентифікація</span>
          <span class="link d-block" @click="$router.go(-1)">Назад</span>
        </div>
      </div>
      <div class="login__main">
        <div
          :style="{
            'max-width': $store.state.auth?.qrCode ? '390px' : '100%',
            background: $store.state.auth?.qrCode ? '#f5f6f8' : 'transparent',
          }"
          style="padding: 10px"
          class="login__main__info">
          <div class="login__main__text">
            <div v-if="$store.state.auth.qrCode" class="text-start">
              <span class="login__main__text__step">
                <strong>1.</strong> Встановіть додаток Google Authenticator на
                ваш смартфон.
              </span>
              <span class="login__main__text__step">
                <strong>2.</strong> Натисність на значок “+” знизу зліва та
                оберіть <strong>Scan aбо QR code</strong>.
              </span>
              <span class="login__main__text__step">
                <strong>3.</strong> Наведіть сканер на екран комп’ютеру та
                впишіть згенерований код у поле нижче.
              </span>
            </div>

            <div v-else class="text-start">
              <span
                :style="{ 'font-size': '13px' }"
                class="login__main__text__step">
                <strong>1.</strong> Відкрийте додаток
                <strong>Google Authenticator</strong> на вашому смартфоні.
              </span>
              <span
                :style="{ 'font-size': '13px' }"
                class="login__main__text__step">
                <strong>2.</strong> Впишіть згенерований код (6 цифр) у поле
                нижче:
              </span>
            </div>
          </div>
          <span
            :style="{
              'font-size': $store.state.auth?.qrCode ? '12px' : '13px',
            }"
            class="login__footer__text"
            >Згенерований код:</span
          >
          <div class="login__main__buttons">
            <div class="login__main__buttons__code w-100">
              <v-otp-input
                v-model="code"
                max-width="100%"
                min-width="100%"
                height="75px"
                variant="outlined"
                :submit="confirmAuth"
                length="6"
                class="mb-3"
                type="number"></v-otp-input>
            </div>
            <v-btn
              :disabled="code.length < 6"
              :loading="$loading.isLoading('confirmAuth')"
              class="block w-100 text-white"
              color="#fc7146"
              variant="flat"
              size="large"
              type="submit">
              Підтвердити
            </v-btn>
          </div>
        </div>
        <div v-if="$store.state.auth.qrCode" class="login__main__qr">
          <v-img
            :src="$store.state.auth.qrCode"
            alt="qr code"
            style="width: 135px; height: 135px" />
          <div style="margin-top: 40px">
            <a
              :href="'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ru&gl=US'"
              target="_blank">
              <PlayMarket />
            </a>
            <a
              :href="'https://apps.apple.com/ru/app/google-authenticator/id388497605'"
              target="_blank">
              <AppStore />
            </a>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import PlayMarket from '@/assets/images/PlayMarket.vue'
import AppStore from '@/assets/images/AppStore.vue'
import { useVuelidate } from '@vuelidate/core'
import { ref } from 'vue'
import { maxLength, minLength, required } from '@vuelidate/validators'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { handleAsync } from 'best-modules/plugins'
import { routeHistory } from 'best-modules/utils'
export default {
  components: {
    PlayMarket,
    AppStore,
  },
  setup() {
    const router = useRouter()
    const store = useStore()

    const code = ref('')

    const v$ = useVuelidate(
      { code: { required, minLength: minLength(6), maxLength: maxLength(6) } },
      { code }
    )

    /** Authentication */
    const backToLogin = () => {
      store.commit('removeAuthUser')
      router.replace({ name: 'login' })
    }

    const confirmAuth = () => {
      v$.value.$validate()
      if (!v$.value.$invalid) {
        return handleAsync('confirmAuth', () => {
          return store.dispatch('loginSecond', code.value).then(async () => {
            if (routeHistory.length > 2) {
              return router.push(routeHistory.at(-3))
            } else {
              if (!store.state.auth.token) {
                return router.replace({ name: 'crm' })
              }
            }
          })
        })
      }
    }

    return {
      backToLogin,
      confirmAuth,
      code,
      v$,
    }
  },
}
</script>

<style lang="scss" scoped>
.login {
  cursor: default;
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    &__text {
      font-size: 34px;
      color: #1f1f1f;
      font-weight: 700;
    }
  }

  &__main {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__qr {
      max-width: 135px;
    }

    &__info {
      background: #f5f6f8;
      border-radius: 4px;
      width: 100%;
      max-width: 305px;
    }

    &__text {
      margin-bottom: 15px;

      &__step {
        font-size: 12px;
        display: block;
        margin-bottom: 8px;
      }
    }

    &__buttons {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }

  &__text {
    margin: 30px 0;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    max-width: 260px;

    &__welcome {
      font-size: 34px;
      font-weight: 700;
      color: #1f1f1f;
      margin-bottom: 20px;
    }

    &__description {
      font-size: 12px;
      font-weight: 400;
      color: #b5b7c0;
    }
  }

  &__footer {
    &__text {
      color: #323232;
      font-size: 12px;
      font-weight: 700;
      display: block;
    }
  }
}
</style>
