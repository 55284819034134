export const CAR = 1;
export const MOTO = 2;
export const WATER_TRANSPORT = 3;
export const SPECIAL = 4;
export const TRAILER = 5;
export const TRUCK = 6;
export const BUS = 7;
export const AUTO_HOUSE = 8;
export const AIR_TRANSPORT = 9;
export const AGRICULTURAL = 10;
export const RAILWAY_TRANSPORT = 11;
export const COMMERCIAL_PROPERTY = 12;
export const EQUIPMENT = 13;