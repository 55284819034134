<template>
  <v-app class="start-app">
    <BLlogo
      class="start-app__bl-logo"
      :addProps="'position: absolute; left: 20px; bottom: 28px;'"
      :width="220"
      :height="64"
      color="white" />
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>
<script>
import BLlogo from '@/assets/svg/best-leasing-logo.vue'
import initPage from '@/utils/initLoader.js'
export default {
  components: { BLlogo },
  computed: {
    location() {
      return location
    },
    user() {
      return this.$store.state.user
    },
  },
  methods: {
    initPage,
    checkLocation(path, user) {
      if (path === '/' && user?.id) {
        this.$router.push({ name: 'crm' })
      }
    },
  },
  watch: {
    'location.pathname': function (pathname) {
      this.checkLocation(pathname, this.user)
    },
    user: {
      handler: function (user) {
        if (user.id) {
          this.checkLocation(this.location?.pathname, user)
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.initPage('.start-app')
    this.checkLocation(location?.pathname, this.user)
  },
}
</script>
