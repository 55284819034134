<template>
  <div>
    <v-fade-transition hide-on-leave>
      <div v-if="routeContracts" class="contracts-wrapper">
        <div class="d-flex align-center">
          <Filters v-model:filters="filters" class="ml-2" />
          <v-tooltip bottom text="Експортувати список">
            <template #activator="{ props }">
              <div v-bind="props" @click="exportContractList">
                <ProjectExportButton />
              </div>
            </template>
            <span>Експортувати список</span>
          </v-tooltip>
        </div>
        <div v-show="!loading" class="pt-5">
          <v-data-table
            :headers="dflListHeaders"
            :items="dflContractList"
            class="calc-table --cust-cell-pd"
            mobileBreakpoint="750"
            dense
            :items-per-page="-1"
            hide-default-footer
            :hide-default-header="!dflContractList.length">
            <template #item.leasingObjectName="{ item }">
              {{ item.leasingObjectName }}
            </template>
            <template #item.lessee="{ item }">
              <ALink
                :text="item.lessee.name"
                :route="{
                  name: [1, 3].includes(item.lessee.typeId)
                    ? 'individuals-form'
                    : 'contractors-form',
                  params: { id: item.lessee.id },
                }">
              </ALink>
            </template>
            <template #item.agreementDate="{ item }">
              {{ humanDate(formatDate(item.agreementDate, true)) }}
            </template>
            <template #item.lesseeShortName="{ item }">
              <ALink
                :text="item.lessee.name"
                :route="getEntityRoute(item.lessee.id, item.lessee.typeId)">
              </ALink>
            </template>
            <template #item.number="{ item }">
              <ALink
                :text="item.number"
                :route="{ name: 'dfl-contract', params: { id: item.id } }">
              </ALink>
            </template>
            <template #item.actions="{ item }">
              <TableCrud
                :actionsArray="[
                  {
                    action: () => pushToDflContract(item),
                    text: 'Відкрити',
                    icon: 'mdi-folder-open-outline',
                  },
                ]">
              </TableCrud>
            </template>
          </v-data-table>
          <Pagination :getItems="getDflList" :trigger="paginationTrigger" />
        </div>
      </div>
    </v-fade-transition>
    <SectionLoader v-if="loading" />
    <v-fade-transition hide-on-leave>
      <RouterView v-if="!routeContracts && !loading" />
    </v-fade-transition>
  </div>
</template>
<script>
import TableCrud from '@/components/table-crud.vue'
import SectionLoader from '@/components/section-loader.vue'
import ALink from '@/components/Link.vue'
import Pagination from '@/components/Pagination.vue'
import { formatDate, humanDate } from '@/utils/formatFunc'
import { backDate, getEntityRoute, openDocument } from '@/utils/helperFunc'
import { mapState } from 'vuex'
import { urlGetDflContracts } from '@/pages/request'
import { useFilter } from '@/utils/mixins/useFilter'
import Filters from '@/components/Filters.vue'
import ProjectExportButton from '@/assets/svg/exportButton/ProjectExportButton.vue'
import { useSelect } from '@/utils/mixins/useSelect'
import { reactive } from 'vue'
import { dflListHeaders } from '@/utils/tableHeaders'
export default {
  components: {
    ProjectExportButton,
    Filters,
    TableCrud,
    SectionLoader,
    ALink,
    Pagination,
  },
  setup() {
    const filters = reactive({
      statusId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { items: 'dflStatuses', label: 'Статус' },
      },
      startDate: {
        value: null,
        component: 'DatePicker',
        attrs: { label: 'Період', range: true },
      },
      search: { value: null },
    })
    return {
      ...useSelect(),
      ...useFilter(filters),
      filters,
    }
  },
  data() {
    return {
      exportParam: false,
      dflContractList: [],
      loading: false,
      dflListHeaders,
    }
  },
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    routeContracts() {
      return this.$route.name === 'dfl-contracts'
    },
  },
  methods: {
    backDate,
    formatDate,
    humanDate,
    getEntityRoute,
    exportContractList() {
      setTimeout(() => {
        this.exportParam = true
        this.getDflList()
        this.exportParam = false
      }, 3700)
    },
    pushToDflContract(item) {
      this.$router.push({ name: 'dfl-contract', params: { id: item.id } })
    },
    getDflList(page = 1, cancelToken, typeId = 1) {
      this.loading = true
      return this.$axios
        .get(
          urlGetDflContracts(
            page,
            this.filtersQueryObj,
            typeId,
            this.exportParam
          ),
          {
            cancelToken,
          }
        )
        .then(res => {
          if (res?.data?.url) {
            openDocument({ url: res.data.url, text: 'export contracts' })
            return this.$store.commit('refreshPage')
          }
          this.dflContractList = res.data.data
          this.loading = false
          return res
        })
        .catch(this.$err)
    },
  },
}
</script>
