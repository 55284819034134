<template>
  <div>
    <div class="white-block" style="font-size: 14px">
      <div class="mb-3" style="color: #000 !important; font-size: 0.9rem">
        Фізична особа:
        <span
          style="position: absolute;
    right: 15px;
    color: grey;
}"
          >Було створено: {{ toFormatDate(scoringData.created_at) }}</span
        >
      </div>
      <v-row>
        <v-col :cols="12" :sm="3" class="pt-1 pb-1">
          <div class="card-label">ПIБ</div>
        </v-col>
        <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
          <ALink
            v-if="!person.fullName"
            :text="client.fullName"
            :route="{
              name: 'individuals-form',
              params: { id: client.id },
            }">
          </ALink>
          <span v-if="person.fullName" class="card-label">
            {{ person.fullName || '---' }}
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="12" :sm="3" class="pt-1 pb-1">
          <div class="card-label">IНН</div>
        </v-col>
        <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
          <span class="card-label">
            {{ person.code || client.inn }}
          </span>
        </v-col>
      </v-row>
      <div v-if="scoringData.incoming && scoringData.cost">
        <v-row>
          <v-col :cols="12" :sm="3" class="pb-1">
            <div class="card-label">Інформація</div>
          </v-col>
          <v-col :cols="12" :sm="9" class="pb-1 text-start">
            <div class="d-flex mb-1">
              <div class="card-sublable mr-1">Роботодавець/Замовник:</div>
              <span class="card-label">
                {{ scoringData.employerOrCustomer || '---' }}
              </span>
            </div>
            <div class="d-flex mb-1">
              <div class="card-sublable mr-1">Посада/вид робіт:</div>
              <span class="card-label">
                {{ scoringData.position || '---' }}
              </span>
            </div>
            <div class="d-flex mb-1">
              <div class="card-sublable mr-1">
                Трудовий стаж на поточному місці роботи, міс:
              </div>
              <span class="card-label">
                {{ scoringData.workExperience || '0' }}
              </span>
            </div>
            <div class="d-flex">
              <IncomeIcon class="mr-1" />
              <span>Доходи:</span>
            </div>
            <div class="d-flex mb-1">
              <div class="card-sublable mr-1">
                Заробітна плата, оплата по цивільно-правовим договорам іншого з
                подружжя:
              </div>
              <span class="card-label">
                {{ scoringData.incoming.lawIncome || '0' }} грн
              </span>
            </div>
            <div class="d-flex mb-1">
              <div class="card-sublable mr-1">
                Заробітна плата, оплата по цивільно-правовим договорам:
              </div>
              <span class="card-label">
                {{ scoringData.incoming.targetIncome || '0' }} грн
              </span>
            </div>
            <div class="d-flex mb-1">
              <div class="card-sublable mr-1">Інші доходи:</div>
              <span class="card-label">
                {{ scoringData.incoming.moreIncome || '0' }} грн
              </span>
            </div>
            <div class="d-flex mt-3">
              <IncomeIcon color="#09487a" class="mr-1" />
              <span>Витрати:</span>
            </div>
            <div class="d-flex mb-1">
              <div class="card-sublable mr-1">
                Погашення кредитів/ лізингу/ розстрочки/ % за кредитними
                картками:
              </div>
              <span class="card-label">
                {{ scoringData.cost.credit || '0' }} грн
              </span>
            </div>
            <div class="d-flex mb-1">
              <div class="card-sublable mr-1">
                Сумарні щомісячні витрати (включаючи сім'ю):
              </div>
              <span class="card-label">
                {{ scoringData.cost.monthCost || '0' }} грн
              </span>
            </div>
          </v-col>
        </v-row>
      </div>
      <OpenableList>
        <FilesUploader
          label="Паспорт"
          :documents="scoringData.passportDocument"
          readonly />
        <FilesUploader
          label="Індивiдуальний податковий номер"
          :documents="scoringData.innDocument"
          readonly />
        <FilesUploader
          label="Іншi фiнансовi документи"
          :documents="scoringData.financialDocument"
          readonly />
      </OpenableList>
    </div>
  </div>
</template>

<script>
import OpenableList from '@/components/OpenableList.vue'
import FilesUploader from '@/components/FilesUploader.vue'
import IncomeIcon from '@/assets/svg/incomeIcon.vue'
import ALink from '@/components/Link.vue'
import { toFormatDate } from '@/utils/helperFunc'
export default {
  name: 'Person',
  components: {
    ALink,
    IncomeIcon,
    OpenableList,
    FilesUploader,
  },
  props: {
    person: { type: Object },
    client: { type: Object },
    scoringData: { type: Object },
  },
  methods: { toFormatDate },
}
</script>

<style scoped></style>
