<template>
  <div class="comments" :class="{ 'pt-6': !readonly }">
    <v-card-title v-if="label" class="comments-title">
      {{ label }}
    </v-card-title>
    <div v-if="!readonly" class="new-comment-section">
      <v-textarea
        v-model="comment"
        class="mt-0 pt-0 sm"
        placeholder="Введіть текст"
        :rows="2"
        dense
        hide-details>
      </v-textarea>
      <div v-if="comment">
        <v-btn
          class="no-transform text-white mt-4"
          style="
            color: #08487a !important;
            font-weight: 400;
            font-size: 14px;
            border: 1px solid #08487a;
          "
          :loading="asyncLoading('createComment')"
          small
          text
          @click="createComment">
          Залишити коментар
        </v-btn>
      </div>
    </div>
    <div v-if="Array.isArray(localComments)" class="comments-section">
      <Item
        v-for="item in [...localComments].reverse()"
        :key="item.id"
        :comment="item"
        :readonly="readonly"
        :onEdit="onEditId === item.id"
        :loading="{
          update: asyncLoading('updateComment'),
          delete: asyncLoading('deleteComment'),
        }"
        @on-edit="onEditId = $event"
        @delete="deleteComment"
        @update="updateComment" />
    </div>
  </div>
</template>

<script>
import Item from './Item.vue'
import {
  urlCommentCreate,
  urlCommentUpdate,
  urlCommentDelete,
} from '@/pages/request'
import { useSelect } from '@/utils/mixins/useSelect'

export default {
  components: { Item },
  setup() {
    return {
      ...useSelect(),
    }
  },
  props: {
    entityTypeId: { type: Number },
    entityId: { type: Number },
    comments: { type: Array, required: true },
    label: { type: [String, Boolean], default: 'Коментарі' },
    readonly: { type: Boolean },
  },
  data() {
    return {
      comment: null,
      localComments: [],
      onEditId: null,
    }
  },
  methods: {
    createComment() {
      return this.asyncAction('createComment', null, () => {
        return this.$axios
          .post(urlCommentCreate(), {
            comment: this.comment,
            entityId: this.entityId,
            entityTypeId: this.entityTypeId,
          })
          .then(res => {
            this.localComments.push(res.data)
            this.comment = null
          })
      })
    },
    updateComment(comment) {
      return this.asyncAction('updateComment', null, () => {
        return this.$axios
          .post(urlCommentUpdate(comment.id), { comment: comment.comment })
          .then(res => {
            this.localComments = this.localComments.map(c =>
              c.id === res.data.id ? res.data : c
            )
            this.onEditId = null
          })
      })
    },
    deleteComment(id) {
      return this.asyncAction('deleteComment', null, () => {
        return this.$axios
          .delete(urlCommentDelete(id))
          .then(
            () =>
              (this.localComments = this.localComments.filter(c => c.id !== id))
          )
      })
    },
  },
  watch: {
    comments: {
      handler(val) {
        this.localComments = Object.clone(val)
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.comments {
  .sm {
    &.v-textarea {
      font-size: 0.85rem;
    }
  }
  .comments-title {
    padding: 0;
    font-size: 0.9rem;
  }
}
</style>
