<template>
  <div>
    <v-tooltip bottom text="Експортувати список">
      <template #activator="{ props }">
        <div v-bind="props" @click="handleExport">
          <ProjectExportBtn />
        </div>
      </template>
      <span>Експортувати список</span>
    </v-tooltip>
  </div>
</template>

<script lang="ts">
import ProjectExportBtn from '@/assets/svg/exportButton/ProjectExportButton.vue'
import { openDocument } from '@/utils/helperFunc'

export default {
  name: 'ExportPfForm',
  components: { ProjectExportBtn },
  props: {
    url: { type: Function, required: true },
    exportFileName: { type: String, required: true },
    filtersQueryObj: { type: Object, required: true },
  },
  methods: {
    handleExport() {
      return this.$axios.get(this.url(this.filtersQueryObj)).then(res => {
        if (res.data.message) {
          return this.$setSnackbar({
            text: res.data.message,
            color: 'warning',
          })
        }
        return openDocument({
          text: 'Список експортовано',
          url: res.data.url,
        })
      })
    },
  },
}
</script>

<style scoped></style>
