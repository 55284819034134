<template>
  <div>
    <SectionLoader v-if="loading"></SectionLoader>
    <v-fade-transition hide-on-leave>
      <div v-if="!loading" class="verification-card" style="position: relative">
        <div style="background: #fff">
          <div>
            <div class="white-block" style="padding-bottom: 0">
              <v-tabs
                v-model="currentTab"
                class="mb-5"
                show-arrows
                :direction="$vuetify.breakpoint?.xs ? 'vertical' : 'horizontal'"
                :height="$vuetify.breakpoint?.xs ? null : 38"
                background-color="grey darken-2"
                color="white"
                dark
                slider-color="red"
                dense>
                <v-tab
                  replace
                  :to="{
                    name: 'rd-client-verification',
                    params: {
                      id: $route.params.id,
                      tabState: $route.params.tabState,
                    },
                  }"
                  >Клієнт</v-tab
                >
                <v-tab
                  v-for="(calc, idx) in calculations"
                  :key="`calculation_${calc.id + idx}`"
                  replace
                  :to="{
                    name: 'rd-leasing-object',
                    params: {
                      idx: idx + 1,
                      id: $route.params.id,
                      tabState: $route.params.tabState,
                    },
                  }"
                  >{{ calc.leasingObjectName }}</v-tab
                >
                <v-tab
                  v-for="(item, index) in guarantors"
                  :key="`guarantor_${item.id}`"
                  replace
                  :to="{
                    name: 'rd-guarantor-verification',
                    params: {
                      idx: index + 1,
                      id: $route.params.id,
                      tabState: $route.params.tabState,
                    },
                  }">
                  <span
                    style="
                      overflow: hidden;
                      display: block;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    "
                    >Поручитель {{ getGuarantorName(guarantors[index]) }}</span
                  >
                </v-tab>
                <v-tab
                  v-for="(item, index) in subtenants"
                  :key="`subtenant_${item.id}`"
                  replace
                  :to="{
                    name: 'rd-subtenant-verification',
                    params: {
                      idx: index + 1,
                      id: $route.params.id,
                      tabState: $route.params.tabState,
                    },
                  }">
                  <span
                    style="
                      overflow: hidden;
                      display: block;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    "
                    >Суборендатор
                    {{ getSubtenantName(subtenants[index]) }}</span
                  >
                </v-tab>
                <v-tab
                  replace
                  :to="{
                    name: 'rd-history',
                    params: {
                      id: $route.params.id,
                      tabState: $route.params.tabState,
                    },
                  }"
                  >Історія</v-tab
                >
                <v-tab
                  replace
                  :to="{
                    name: 'rd-analyze',
                    params: {
                      id: $route.params.id,
                      tabState: $route.params.tabState,
                    },
                  }"
                  >Аналіз</v-tab
                >
              </v-tabs>
            </div>
          </div>
          <div style="padding: 0 13px">
            <v-tabs
              v-if="showLoTabs && calculations.length"
              slider-color="#ff000000"
              class="newForTabRisk">
              <v-tab
                class="fortab"
                replace
                :to="{
                  name: 'rd-leasing-object',
                  params: {
                    idx: $route.params.idx,
                    id: $route.params.id,
                    tabState: $route.params.tabState,
                  },
                }"
                >Обʼєкт лізингу</v-tab
              >
              <v-tab
                v-if="!calculations[$route.params.idx - 1].leaseReverse"
                class="fortab"
                replace
                :to="{
                  name: 'rd-provider-verification',
                  params: {
                    idx: $route.params.idx,
                    id: $route.params.id,
                    tabState: $route.params.tabState,
                  },
                }"
                >Постачальник</v-tab
              >
            </v-tabs>
          </div>
        </div>
        <router-view
          :rdAnalyze="rdAnalyze"
          :isInWork="isInWork"
          :project="project"
          :calculation="calculations[$route.params.idx - 1]"
          :guarantors="guarantors"
          :subtenants="subtenants"
          :loading="loading"
          :disabled="disabled"
          @update="
            () => getRiskAnalyzeById(getSafe(() => $event.updateProjects))
          " />
        <v-divider class="mt-2" dark></v-divider>
        <ActionButtons
          v-if="!loading"
          data-readonly="hide"
          class="pt-0 pb-4"
          :confirm="() => submit('accept')"
          :cancel="() => submit('reject')"
          confirmBtnText="Передати в КЛП"
          cancelBtnText="Відмова"
          :confirmLoading="submitLoading"
          :cancelLoading="submitLoading"
          :confirmDisable="!isInWork || disabled"
          :cancelDisable="!isInWork || disabled">
        </ActionButtons>
      </div>
    </v-fade-transition>
  </div>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'
import ActionButtons from '@/components/action-buttons.vue'
import {
  urlGetRiskAnalyzeById,
  urlTakeToWorkRiskAnalyze,
  urlRiskAnalyzeSetDocuments,
  urlRiskAnalyzeSetStatus,
  urlGetRiskAnalyzeStatuses,
  urlRiskTradeParams,
} from '@/pages/request'

import {
  getAddress,
  getPhone,
  setErrHandler,
  setCache,
  v$Notify,
  contactDetailsWithType,
  getProjectGuarantors,
  getProjectSubtenants,
  getContactInitials,
  LODescription as getLoDescription,
  getSafe,
} from '@/utils/helperFunc'
import { formatDate, formutSum, humanDate } from '@/utils/formatFunc'
import { mapState } from 'vuex'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
export default {
  components: {
    SectionLoader,
    ActionButtons,
  },
  emits: ['updateProjects'],
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      rdAnalyze: {
        statusId: { required },
      },
    }
  },
  data: () => ({
    currentTab: 0,
    rdAnalyze: {},
    response: {},
    cache: null,
    loading: false,
    submitLoading: false,
  }),
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    disabled() {
      return [3, 4, 6].includes(this.rdAnalyze.statusId)
    },
    guarantors() {
      return this.getProjectGuarantors(
        this.rdAnalyze?.project.slider.guarantors
      )
    },
    subtenants() {
      return this.getProjectSubtenants(
        this.rdAnalyze?.project.slider.subtenants
      )
    },
    calculations() {
      if (!this.rdAnalyze.project.slider.calculations) return []
      return this.rdAnalyze.project.slider.calculations
    },
    id() {
      return this.$route.params.id
    },
    project() {
      return this.rdAnalyze?.project
    },
    projectCache() {
      return this.setCache([this.rdAnalyze])
    },
    isInWork() {
      return this.rdAnalyze?.statusId === 2
    },
    showLoTabs() {
      return ['rd-leasing-object', 'rd-provider-verification'].includes(
        this.$route.name
      )
    },
  },
  methods: {
    getSafe,
    setCache,
    getPhone,
    formutSum,
    formatDate,
    humanDate,
    getAddress,
    setErrHandler,
    contactDetailsWithType,
    getProjectGuarantors,
    getProjectSubtenants,
    getContactInitials,
    urlGetRiskAnalyzeById,
    urlTakeToWorkRiskAnalyze,
    urlRiskAnalyzeSetDocuments,
    urlRiskAnalyzeSetStatus,
    urlGetRiskAnalyzeStatuses,
    urlRiskTradeParams,
    getLoDescription,
    getGuarantorName(g) {
      if (!g) return
      return g.guarantorTypeId === 2
        ? g.contractor.shortName
        : this.getContactInitials(g.contact)
    },
    getSubtenantName(g) {
      if (!g) return
      return g.subtenantTypeId === 2
        ? g.contractor.shortName
        : this.getContactInitials(g.contact)
    },
    getRiskAnalyzeById(update) {
      this.loading = true
      return this.$axios
        .get(this.urlGetRiskAnalyzeById(this.id))
        .then(res => {
          Object.assign(this.rdAnalyze, res.data)
          Object.assign(this.response, res.data)
          if (!this.rdAnalyze.templateClientAnalysis) {
            this.rdAnalyze.templateClientAnalysis = []
          }
          if (!this.rdAnalyze.nbuRanking) {
            this.rdAnalyze.nbuRanking = []
          }
          if (!this.rdAnalyze.agreementParameters) {
            this.rdAnalyze.agreementParameters = []
          }
          this.cache = this.setCache([this.rdAnalyze])

          this.loading = false
          if (update) this.$emit('updateProjects')

          return res
        })
        .catch(this.$err)
    },
    async submit(type = 'accept') {
      const invalid = await this.validateProject(type)

      if (!invalid) {
        const resultId = type === 'accept' ? 1 : 2
        return this.$store.commit('setDialog', {
          title: type === 'accept' ? 'Передати в КЛП' : 'Відмова',
          dialogItem: {
            riskId: this.rdAnalyze?.id,
            category: this.project?.lessee?.category,
            resultId,
          },
          params: {
            cb: () => this.getRiskAnalyzeById(),
          },
          action: 'riskResult',
        })
      }
    },
    async validateProject(type) {
      let invalid = false
      if (type === 'accept') {
        this.v$.$touch()
        if (this.v$.$invalid) {
          this.v$.$anyError()
          v$Notify(this.v$.rdAnalyze, 'riskDepartmentAnalyze')
          return
        }
        if (!this.rdAnalyze?.templateClientAnalysis?.length) {
          this.$setSnackbar({
            text: 'Додайте документ: "Аналіз клієнта"',
            color: 'warning',
          })
          invalid = true

          return invalid
        }
        if (!this.rdAnalyze?.agreementParameters?.length) {
          this.$setSnackbar({
            text: 'Додайте документ: "Параметри угоди"',
            color: 'warning',
          })
          invalid = true

          return invalid
        }
      } else if (type === 'reject') {
        return false
      }
    },
    update() {
      this.getRiskAnalyzeById()
    },
  },
  created() {
    this.getRiskAnalyzeById()
  },
}
</script>
<style lang="scss" scoped>
.data-card {
  display: flex;
  flex-direction: column;
  margin-right: 25px;
}
.doc-link {
  padding: 4px;
  padding-left: 8px;
  a {
    color: steelblue;
    text-decoration: underline;
  }
}
.v-tab-item--selected.v-tab {
  font-weight: 700 !important;
  color: #08487a !important;
  font-size: 13px;
}
.v-tab {
  color: #838282 !important;
  font-size: 13px;
  letter-spacing: normal;
  text-transform: uppercase;
}
</style>
