import { computed, ref, watch } from 'vue'

export function useFilter(filters, filterDialog) {
  const paginationTrigger = ref(1)
  const updateListTimeout = ref(null)

  const updateList = () => {
    clearTimeout(updateListTimeout.value)
    updateListTimeout.value = setTimeout(
      () => (paginationTrigger.value += 1),
      1500
    )
  }

  const clearFilters = () => {
    for (const f in filters) {
      if (filters[f].value && f !== 'search') filters[f].value = null
    }
  }

  const filtersQueryObj = computed(() => {
    let temp = {}
    const queryFilters = JSON.parse(
      JSON.stringify(filters || {}, (key, value) => {
        if (key === '') return value
        if (!value || typeof value !== 'object') return value
        if (typeof value.value === 'object') {
          if (Array.isArray(value.value)) {
            temp[key] = value.value
          } else {
            temp = { ...temp, ...value.value }
          }
        }
        return value.value
      })
    )

    for (const f in queryFilters) {
      if (typeof queryFilters[f] === 'object') delete queryFilters[f]
    }

    return { ...queryFilters, ...temp }
  })

  const useFilterComponent = computed(() => {
    let res = true
    for (const f in filters || {}) {
      if (typeof filters[f] !== 'object') {
        res = false
        break
      }
    }
    return res
  })

  watch(
    filtersQueryObj,
    () => {
      if (filterDialog || !useFilterComponent.value) {
        return null
      } else {
        updateList()
      }
    },
    { deep: true }
  )

  watch(
    computed(() => filters),
    () => {
      if (useFilterComponent.value) {
        return null
      } else {
        updateList()
      }
    },
    { deep: true }
  )

  return {
    paginationTrigger,
    updateListTimeout,
    updateList,
    clearFilters,
    filtersQueryObj,
    useFilterComponent,
  }
}
