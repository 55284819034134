<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none">
    <path
      d="M2.913 18.738H2.91267L2.91334 18.748C2.95766 19.4108 3.26167 20.0293 3.75936 20.4693C4.25598 20.9083 4.90542 21.1341 5.56717 21.098H18.4328C19.0946 21.1341 19.744 20.9083 20.2406 20.4693C20.7383 20.0293 21.0424 19.4108 21.0867 18.748L21.087 18.748V18.738V7.738H21.0873L21.0867 7.72806C21.0426 7.06508 20.7387 6.44627 20.241 6.00609C19.7444 5.56685 19.0948 5.34089 18.4329 5.377H17.975C17.8026 5.377 17.6373 5.44548 17.5154 5.56738C17.3935 5.68928 17.325 5.85461 17.325 6.027C17.325 6.19939 17.3935 6.36472 17.5154 6.48662C17.6373 6.60852 17.8026 6.677 17.975 6.677H18.437V6.67767L18.4511 6.67634C18.7707 6.64618 19.0895 6.74219 19.3393 6.94386C19.5869 7.14373 19.7473 7.43168 19.787 7.74714V18.7288C19.747 19.0441 19.5866 19.3318 19.339 19.5315C19.0892 19.733 18.7706 19.8288 18.4511 19.7987L18.4512 19.798H18.437H5.563V19.7973L5.5489 19.7987C5.2294 19.8288 4.91076 19.733 4.66098 19.5315C4.41345 19.3318 4.25297 19.0441 4.213 18.7288V7.74714C4.25275 7.43168 4.41314 7.14373 4.66071 6.94386C4.91052 6.74219 5.22928 6.64618 5.54891 6.67634L5.54885 6.677H5.563H6.025C6.19739 6.677 6.36272 6.60852 6.48462 6.48662C6.60652 6.36472 6.675 6.19939 6.675 6.027C6.675 5.85461 6.60652 5.68928 6.48462 5.56738C6.36272 5.44548 6.19739 5.377 6.025 5.377H5.56715C4.90524 5.34089 4.25565 5.56685 3.759 6.00609C3.26129 6.44627 2.95738 7.06508 2.91333 7.72806L2.913 7.72804V7.738V18.738Z"
      fill="#FC7247"
      stroke="#FC7247"
      stroke-width="0.3" />
    <path
      d="M11.345 3.552V11.659L9.46107 9.77594C9.46106 9.77593 9.46105 9.77592 9.46104 9.77591C9.33876 9.65364 9.17292 9.58496 9 9.58496C8.82707 9.58496 8.66122 9.65365 8.53894 9.77594C8.41665 9.89822 8.34796 10.0641 8.34796 10.237C8.34796 10.4099 8.41665 10.5758 8.53894 10.6981L8.64464 10.5924L8.53894 10.6981L11.5386 13.6977C11.5386 13.6978 11.5387 13.6978 11.5387 13.6979C11.5998 13.7593 11.6724 13.8081 11.7523 13.8414C11.8324 13.8747 11.9182 13.8919 12.0049 13.892L12.0071 13.892C12.1783 13.8895 12.3416 13.8197 12.4616 13.6975C12.4618 13.6973 12.462 13.6972 12.4621 13.697L15.4611 10.6981C15.5216 10.6375 15.5696 10.5656 15.6024 10.4865C15.6352 10.4074 15.652 10.3226 15.652 10.237C15.652 10.1514 15.6352 10.0666 15.6024 9.98747C15.5696 9.90836 15.5216 9.83648 15.4611 9.77594C15.4005 9.71539 15.3286 9.66736 15.2495 9.63459C15.1704 9.60182 15.0856 9.58496 15 9.58496C14.9144 9.58496 14.8296 9.60182 14.7505 9.63459C14.6714 9.66736 14.5995 9.71539 14.5389 9.77594L14.5387 9.77618L12.645 11.6787V3.552C12.645 3.37961 12.5765 3.21428 12.4546 3.09238C12.3327 2.97048 12.1674 2.902 11.995 2.90198L11.9927 2.90202C11.8217 2.90469 11.6585 2.97378 11.5377 3.09466C11.4168 3.21555 11.3477 3.37873 11.345 3.54966L11.345 3.54966V3.552Z"
      fill="#FC7247"
      stroke="#FC7247"
      stroke-width="0.3" />
  </svg>
</template>

<script>
export default {
  name: 'Result',
}
</script>

<style scoped></style>
