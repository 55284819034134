<template>
  <div>
    <v-fade-transition hide-on-leave>
      <div>
        <v-row>
          <v-col :cols="12" :sm="dialogMode ? 12 : 4" class="pb-0 pt-0">
            <v-select
              v-model="provider"
              :error-messages="providerErr"
              label="Постачальник"
              :items="providers"
              item-title="name"
              :item-value="
                item => ({
                  providerId: item.providerId,
                  providerTypeId: item.providerTypeId,
                })
              "
              hide-details
              @blur="v$.provider.$touch()">
            </v-select>
          </v-col>
          <v-col :cols="12" :sm="dialogMode ? 12 : 4" class="pb-0 pt-0">
            <FileBinder
              v-model:document="dkpDoc"
              label="Microsoft .doc - шаблон"
              class="mt-3"
              :required="true">
            </FileBinder>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="pb-0 pt-5"
            :class="{ 'd-flex justify-center': dialogMode }">
            <v-btn
              class="text-white mb-3"
              color="grey darken-3"
              :small="!dialogMode"
              :disabled="v$.$invalid"
              :loading="loading"
              @click="uploadDoc">
              Додати шаблон до постачальника
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-fade-transition>
  </div>
</template>
<script>
import FileBinder from '@/components/FileBinder.vue'
import { mapState } from 'vuex'
import { required } from '@vuelidate/validators'
import { setErrHandler } from '@/utils/helperFunc'
import { urlAddTemplate, urlGetDKPFormVariables } from '@/pages/request'
import { useVuelidate } from '@vuelidate/core'
export default {
  components: {
    FileBinder,
  },
  setup() {
    return { v$: useVuelidate() }
  },
  props: {
    dialogMode: { type: Boolean, default: false },
    callBack: { type: Function },
    providerId: { type: Number },
  },
  validations() {
    return {
      provider: { required },
      dkpDoc: { required },
    }
  },
  data: () => ({
    initialized: false,
    provider: null,
    template: null,
    dkpDoc: null,
    variablesTemplates: [],
    variableReplaceValues: {},
    loading: false,
  }),
  computed: {
    ...mapState({
      providers: state => state.selectItems.allProviders,
    }),
    providerErr() {
      return this.setErrHandler(this.v$.provider)
    },
    templateErr() {
      return this.setErrHandler(this.v$.template)
    },
  },
  methods: {
    urlAddTemplate,
    setErrHandler,
    urlGetDKPFormVariables,
    uploadDoc() {
      if (this.v$.$invalid) {
        return this.highLightErr()
      }

      this.loading = true
      const formData = new FormData()
      formData.append('dkp', this.dkpDoc)
      formData.append('providerId', this.provider.providerId)
      formData.append('providerTypeId', this.provider.providerTypeId)

      return this.$axios
        .post(this.urlAddTemplate(), formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(res => {
          this.$setSnackbar({ text: 'Додано шаблон до постачальника' })
          this.loading = false
          if (this.callBack && typeof this.callBack === 'function') {
            this.callBack()
          }
          return res
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
    getDKPFormVariables() {
      return this.$axios
        .get(this.urlGetDKPFormVariables())
        .then(res => {
          const variables = res.data.map(k => ({
            title: k,
            description: k,
            content: `{$${k}}`,
          }))
          this.variablesTemplates.push(...variables)
        })
        .catch(this.$err)
    },
    highLightErr() {
      this.$setSnackbar({ text: 'Помилка валiдацii', color: 'error' })
      this.v$.$anyError
      this.v$.$touch()
    },
  },
  created() {
    this.getDKPFormVariables()
    this.$store.dispatch('addAllProviders')
  },
  mounted() {
    if (this.providerId) {
      this.provider = this.providerId
    }
  },
}
</script>
