<template>
  <div>
    <v-fade-transition>
      <div class="contact-list-wrapper">
        <table
          v-if="!editMode && localItems.length"
          class="contact-item month-table table">
          <tbody>
            <tr v-for="(item, key) in localItems" :key="key" class="contact-tr">
              <td class="contact-td" style="padding-left: 0 !important">
                {{ item[itemKey] }}
              </td>
              <td v-if="!!!readonly" class="pa-0 contact-td">
                <v-fade-transition hide-on-leave>
                  <v-btn
                    v-show="
                      !single &&
                      !addMode &&
                      !editMode &&
                      modelValue
                        .map(itm => itm[itemKey])
                        .includes(item[itemKey])
                    "
                    data-readonly="hide"
                    size="x-small"
                    icon
                    @click="deleteAction(item)">
                    <v-icon small>{{ 'mdi-close' }}</v-icon>
                  </v-btn>
                </v-fade-transition>
              </td>
              <td v-if="!!!readonly" class="pa-0 contact-td">
                <v-fade-transition hide-on-leave>
                  <v-btn
                    v-show="!editMode"
                    data-readonly="hide"
                    size="x-small"
                    icon
                    @click="
                      () => {
                        editMode = true
                        Object.assign(
                          currentItem,
                          localItems.splice(key, 1)[0] || {}
                        )
                        curItemOrigin = currentItem
                      }
                    ">
                    <v-icon size="small">
                      {{ 'mdi-circle-edit-outline' }}
                    </v-icon>
                  </v-btn>
                </v-fade-transition>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </v-fade-transition>
    <v-fade-transition hide-on-leave>
      <v-text-field
        v-show="!localItems.length || addMode || editMode"
        :id="uniqueId"
        v-model="currentItem[itemKey]"
        :error-messages="error"
        style="padding-left: 0 !important"
        class="pl-4 no-label pt-0 mt-0"
        :placeholder="placeholder"
        :disabled="!!readonly"
        :loading="loading"
        @blur="$emit('blur', $event)"
        @input="mask($event, uniqueId)">
        <template #append>
          <v-btn
            v-if="!!!readonly"
            v-show="
              isValidItem(currentItem[itemKey]) &&
              !modelValue
                ?.map(item => item[itemKey])
                ?.includes(currentItem[itemKey])
            "
            size="small"
            icon
            @click="handleContact">
            <v-icon :size="20" color="green">
              {{ 'mdi-check' }}
            </v-icon>
          </v-btn>
        </template>
        <template #append-inner>
          <v-scroll-x-transition hide-on-leave>
            <v-btn
              v-if="addMode || editMode"
              v-show="modelValue.length"
              small
              icon
              @click="substract">
              <v-icon :size="20" color="red">
                {{ 'mdi-minus' }}
              </v-icon>
            </v-btn>
          </v-scroll-x-transition>
        </template>
      </v-text-field>
    </v-fade-transition>
    <v-fade-transition hide-on-leave>
      <div
        v-show="
          !single && localItems.length && !addMode && !editMode && !!!readonly
        "
        data-readonly="hide"
        class="contact-add"
        @click="addMode = true">
        <v-btn size="small" icon>
          <PlusIcon />
        </v-btn>
      </div>
    </v-fade-transition>
  </div>
</template>
<script>
import PlusIcon from '@/assets/svg/plus-icon.vue'
export default {
  components: {
    PlusIcon,
  },
  emits: ['update:model-value', 'blur'],
  props: {
    title: { type: String },
    placeholder: { type: String },
    titleIcon: { type: String },
    readonly: { type: Boolean, default: false },
    isValidItem: { type: Function, default: () => false },
    mask: { type: Function, default: () => false },
    error: { type: [Array, String] },
    deleteItem: { type: Function, default: () => false },
    addItem: { type: Function, default: () => false },
    updateItem: { type: Function, default: () => false },
    handleItem: { type: Function, default: () => false },
    itemKey: { type: String },
    single: { type: Boolean, default: () => false },
    modelValue: { type: Array },
  },
  data: () => ({
    uniqueId: `field-${Math.random().toString(36).substr(2, 9)}`,
    currentItem: {},
    addMode: false,
    editMode: false,
    localItems: [],
    curItemOrigin: null,
    loading: false,
  }),
  methods: {
    substract() {
      if (
        this.editMode &&
        !this.localItems
          .map(item => item[this.itemKey])
          .includes(this.currentItem[this.itemKey])
      ) {
        this.localItems.push(this.curItemOrigin)
      }

      this.addMode = false
      this.editMode = false
      this.currentItem = {}
      this.curItemOrigin = null
    },
    async deleteAction(item) {
      this.loading = true

      await this.deleteItem(item)
      this.localItems = this.localItems.filter(p =>
        item?.[this.itemKey]
          ? p[this.itemKey] !== item[this.itemKey]
          : p !== item
      )

      this.loading = false
    },
    async handleContact() {
      this.loading = true
      const isCreate = !this.editMode
      this.addMode = false
      this.editMode = false

      await this.handleItem(this.currentItem, isCreate)
        .then(item => {
          if (item) {
            this.localItems.push(item)
            console.log('item', JSON.parse(JSON.stringify(item)))
            console.log(
              'localItems',
              JSON.parse(JSON.stringify(this.localItems))
            )
            // if (isCreate) {
            //   this.localItems.push(item)
            // } else {
            //   this.localItems = this.localItems.map(l =>
            //     l.id === item.id ? item : l
            //   )
            // }
            this.currentItem = {}
            this.$emit('update:model-value', this.localItems)
          }
        })
        .catch(this.$err)
      this.loading = false
    },
  },
  watch: {
    items(items) {
      const arr = items.filter(
        item => !this.localItems.map(item => item[this.itemKey]).includes(item)
      )
      this.localItems.push(...arr)
    },
    localItems(items) {
      return items
    },
  },
  mounted() {
    this.localItems.push(...(this.modelValue || []))
  },
}
</script>
<style lang="scss" scoped>
.crm-layout .crm .crm-content .v-data-table .v-data-table__wrapper td {
  border-bottom: none;
}
.v-application .month-table td {
  font-size: 13px !important;
}
.contact-add {
  margin-left: -5px;
  margin-top: 5px;
}
.contact-list-wrapper {
  overflow-x: auto;
  overflow-y: hidden;

  .contact-title {
    font-size: 0.85rem;
  }
  .contact-item {
    tbody {
      td {
        height: 32px !important;
      }
    }
    .item-title {
      padding-left: 0.75rem;
    }

    .action-wrapper {
      cursor: pointer;
      white-space: nowrap;
      display: inline;
    }
  }
}
.table {
  background: #ebebeb !important;
  border: 1px solid #c0c1c7 !important;
  border-radius: 12px !important;
  width: 100%;
}

.contact-tr {
  display: block;
  padding: 0.5px 10px !important;
  background: #ebebeb !important;
  border: 1px solid #00000000 !important;
  &:hover {
    background: #ebebeb !important;
  }
}
.contact-td {
  background: #ebebeb !important;
}
#app .v-data-table {
  background-color: transparent;
  border: none !important;
  padding: 0 !important;
}
</style>
