<template>
  <v-card
    :elevation="0"
    class="mb-3"
    style="
      background: rgb(255, 255, 255) !important;
      border-radius: 4px !important;
    ">
    <v-card-text>
      <div v-if="createApplicationMode">
        <span class="label">Назва заявки</span>
        <v-text-field
          v-model="applicationData.applicationName"
          :error-messages="applicationNameErr"
          class="label-top main mt-1"
          placeholder="Вкажіть назву заявки"
          dense
          hide-details
          :disabled="!!applicationData.readOnly"
          @blur="
            $v.application.applicationName &&
              $v.application.applicationName.$touch()
          ">
        </v-text-field>
      </div>
      <div v-if="!createApplicationMode">
        <span class="label">Назва заявки</span>
        <span class="text-desc d-block mb-4">{{
          getSafe(() => applicationData.applicationName, 'Не вказано')
        }}</span>
      </div>
      <div v-if="createApplicationMode">
        <span class="label">Потреба клієнта</span>
        <v-select
          v-model="applicationData.clientNeedId"
          :error-messages="clientNeedIdErr"
          :items="$directory.get('clientNeeds', applicationData.clientNeed)"
          item-title="name"
          item-value="id"
          class="label-top mt-1"
          placeholder="оберіть опцію"
          dense
          hide-details
          :disabled="!!applicationData.readOnly"
          @blur="$v.application.clientNeedId.$touch()"
          @focus="$directory.fill('clientNeeds')">
          <template #no-data><Loader /></template>
          <template
            v-if="
              applicationData.clientNeed && $loading.isLoading('clientNeeds')
            "
            #append-item>
            <loader></loader>
          </template>
        </v-select>
      </div>
      <div v-if="!createApplicationMode">
        <span class="label">Потреба клієнта</span>
        <span class="text-desc d-block mb-4">{{
          getSafe(() => applicationData.clientNeed.name, 'Не вказано')
        }}</span>
      </div>
      <div v-if="createApplicationMode">
        <span class="label">Тип джерела</span>
        <v-select
          v-model="applicationData.sourceTypeId"
          :items="$directory.get('applicationSourceTypes')"
          item-title="name"
          item-value="id"
          hide-details
          :error-messages="sourceTypeIdErr"
          class="label-top mt-1"
          placeholder="Оберіть опцію"
          dense
          @blur="$v.application.sourceTypeId.$touch()">
          <template #no-data><Loader /></template>
        </v-select>
        <div v-if="sourceAgent">
          <div>
            <span class="label">Агент</span>
            <b-server-autocomplete
              ref="agentInput"
              v-model="applicationData.sourceLinkAgentId"
              :url="urlGetAllAgents()"
              :search-min-length="3"
              :default-item="applicationData?.agent"
              item-title="fullName"
              item-value="id"
              :menu-props="{ offset: '5px', zIndex: 1000000000 }"
              placeholder="Введіть текст (мін. 3 символи)"
              hide-details
              dense
              :error-messages="applicationObjSourceContactIdErr"
              @blur="$v.application.sourceLinkAgentId.$touch()">
            </b-server-autocomplete>
          </div>
          <div
            v-if="applicationData.agent && applicationData.agent.dealer_point">
            <span class="subtitle">Торгова Точка</span>
            <span class="text-desc d-block mb-4">{{
              $getSafe(
                () => applicationData.agent.dealer_point.name,
                'Не вказано'
              )
            }}</span>
          </div>
        </div>
        <div v-if="sourceOnline">
          <span class="label">Джерело</span>
          <v-select
            v-model="applicationData.sourceId"
            :error-messages="sourceIdErr"
            :items="$directory.get('applicationOnlineSourceTypes')"
            item-title="name"
            item-value="id"
            class="label-top mt-1"
            placeholder="Оберіть джерело"
            :loading="sourceLoading"
            dense
            @blur="$v.application.sourceId.$touch()"
            @update:model-value="handleSource($event)">
            <template #no-data><Loader /></template>
          </v-select>
        </div>
        <div v-if="selectItems.applicationSourceLinks.length">
          <span class="label">Посилання на джерело</span>
          <v-select
            v-model="applicationData.sourceLinkId"
            :error-messages="applicationObjSourceLinkIdErr"
            :items="selectItems.applicationSourceLinks"
            item-title="name"
            item-value="id"
            class="label-top mt-1"
            placeholder="Оберіть опцiю"
            dense
            @blur="$v.application.sourceLinkId.$touch()">
            <template #no-data><Loader /></template>
          </v-select>
        </div>
      </div>
      <div v-else>
        <div class="text-field">
          <span class="label">Джерело:</span>
          <div v-if="sourceOnline" class="text-desc d-flex align-center">
            <WebSiteIcon
              v-if="
                applicationData.source.id === 1 ||
                applicationData.source.id === 2
              "
              class="mr-1" />
            <FacebookIcon v-if="applicationData.source.id === 3" class="mr-1" />
            <InstagramIcon
              v-if="applicationData.source.id === 4"
              class="mr-1" />
            <TikTokIcon v-if="applicationData.source.id === 5" class="mr-1" />
            <TellIcon
              v-if="[6, 7, 9].includes(applicationData.source.id)"
              class="mr-1" />
            <TgIcon v-if="applicationData.source.id === 8" class="mr-1" />
            <span>
              {{
                [6, 7, 9].includes(applicationData.source.id)
                  ? applicationData.source.name
                  : applicationData.source.name +
                    ` (${applicationData.sourceLink?.name || 'не вказано'})`
              }}
            </span>
          </div>
          <span v-else class="text-desc">
            {{ applicationData?.sourceType?.name }}
            <span
              v-if="
                sourceAgent &&
                applicationData.agent &&
                applicationData.agent.contact
              ">
              (<ALink
                style="font-weight: 600 !important"
                :text="applicationData?.agent?.contact?.fullName"
                :route="{
                  name: 'agent-form',
                  params: { id: applicationData.agent.id },
                }">
              </ALink
              >)
            </span>
          </span>
        </div>
        <div
          v-if="
            applicationData &&
            applicationData.agent &&
            applicationData.agent.dealer_point
          ">
          <span class="subtitle">Торгова Точка</span>
          <span class="text-desc d-block mb-4">{{
            $getSafe(
              () => applicationData.agent.dealer_point.name,
              'Не вказано'
            )
          }}</span>
        </div>
      </div>
      <div v-if="createApplicationMode">
        <span class="label">Метод комунiкацiї</span>
        <v-select
          v-model="applicationData.communicationMethodId"
          :error-messages="communicMethodsErr"
          :items="
            $directory.get(
              'applicationComunicationMethods',
              applicationData.communicationMethod
            )
          "
          item-title="name"
          item-value="id"
          class="label-top mt-1"
          placeholder="оберіть опцiю"
          dense
          :disabled="!!applicationData.readOnly"
          @blur="$v.application.communicationMethodId.$touch()"
          @focus="$directory.fill('applicationComunicationMethods')">
          <template
            v-if="$loading.isLoading('applicationComunicationMethods')"
            #no-data></template>
          <template
            v-if="
              applicationData.communicationMethod &&
              $loading.isLoading('applicationComunicationMethods')
            "
            #append-item>
            <loader></loader>
          </template>
        </v-select>
      </div>
      <div v-if="!createApplicationMode">
        <span class="label">Метод комунiкацiї</span>
        <span class="text-desc d-block mb-4">{{
          getSafe(() => applicationData.communicationMethod.name, 'Не вказано')
        }}</span>
      </div>
      <ChangeResponsible
        v-model:responsibleId="applicationData.responsibleId"
        :read-only="application.readOnly"
        :responsible="application.responsible" />
      <div v-if="applicationData.created_at">
        <span class="subtitle">Дата створення заявки</span>
        <span class="text-desc">{{
          toFormatDate(applicationData.created_at, false, true) || 'Не вказано'
        }}</span>
      </div>
      <div v-if="application.bitrixId" class="mt-1 text-field">
        <span class="text-title">Bitrix ID:</span>
        <span>{{ application.bitrixId }}</span>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import TgIcon from '@/assets/svg/tg-icon.vue'
import InstagramIcon from '@/assets/svg/instagram-icon.vue'
import TikTokIcon from '@/assets/svg/tiktok-icon.vue'
import TellIcon from '@/assets/svg/social/tell-icon.vue'
import FacebookIcon from '@/assets/svg/facebook-icon.vue'
import WebSiteIcon from '@/assets/svg/webSite-icon.vue'
import { mapState, mapGetters } from 'vuex'
import {
  setErrHandler,
  toFormatDate,
  getResponsibleShortName,
  getSafe,
} from '@/utils/helperFunc'
import Loader from '@/components/Loader.vue'
import ALink from '@/components/Link.vue'
import ChangeResponsible from '@/components/ChangeResponsible.vue'
import { useSelect } from '@/utils/mixins/useSelect'
import { fillDirectory } from '@/plugins/directory'
import { urlGetAllAgents } from '@/pages/request'
import { BServerAutocomplete } from 'best-modules/components'

export default {
  components: {
    ChangeResponsible,
    Loader,
    ALink,
    WebSiteIcon,
    FacebookIcon,
    TellIcon,
    TikTokIcon,
    InstagramIcon,
    TgIcon,
    BServerAutocomplete,
  },
  emits: [
    'update:application',
    'update:mainContactId',
    'update:mainContractorId',
  ],
  setup() {
    return {
      ...useSelect(),
    }
  },
  props: {
    application: { type: Object },
    v: { type: Object },
    createApplicationMode: { type: Boolean, default: false },
    mainContactId: { type: Number },
    mainContractorId: { type: Number },
  },
  data() {
    return {
      sourceLoading: false,
    }
  },
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
      user: state => state.user,
    }),
    ...mapGetters({
      activeUsers: 'activeUsers',
    }),
    applicationData: {
      get() {
        return this.application
      },
      set(val) {
        return this.$emit('update:application', val)
      },
    },
    $v() {
      return this.v
    },
    sourceAgent() {
      return this.applicationData.sourceTypeId === 1
    },
    sourceOnline() {
      return this.applicationData.sourceTypeId === 2
    },
    applicationNameErr() {
      return this.setErrHandler(this.$v.application?.applicationName)
    },
    clientNeedIdErr() {
      return this.setErrHandler(this.$v.application?.clientNeedId)
    },
    communicMethodsErr() {
      return this.setErrHandler(this.$v.application?.communicationMethodId)
    },
    sourceTypeIdErr() {
      return this.setErrHandler(this.$v.application?.sourceTypeId)
    },
    applicationObjSourceLinkIdErr() {
      return this.setErrHandler(this.$v.application?.sourceLinkId)
    },
    sourceIdErr() {
      return this.setErrHandler(this.$v.application?.sourceId)
    },
    applicationObjSourceContactIdErr() {
      return this.setErrHandler(this.$v.application?.sourceLinkAgentId)
    },
  },
  methods: {
    urlGetAllAgents,
    getSafe,
    setErrHandler,
    toFormatDate,
    getResponsibleShortName,
    handleSourceType() {
      this.$emit('update:mainContactId', null)
      this.$emit('update:mainContractorId', null)
      this.applicationData.sourceId = null
      this.applicationData.sourceLinkAgentId = null
      switch (true) {
        case this.sourceAgent:
          return this.$store.dispatch('addAgentsList', { noRepeat: true })
        case this.sourceOnline:
          return fillDirectory('applicationOnlineSourceTypes')
      }
    },
    handleSource(sourceId) {
      this.sourceLoading = true
      this.applicationData.sourceLinkId = null
      console.log('sourceId', sourceId)
      this.$store
        .dispatch('addApplicationSourceLinks', sourceId)
        .finally(() => (this.sourceLoading = false))
    },
  },
  watch: {
    'applicationData.sourceTypeId'(newVal) {
      this.handleSourceType(newVal)
    },
  },
}
</script>

<style scoped lang="scss">
.text-field {
  margin-bottom: 12px;
}
.text-title {
  color: #1c1d21;
  font-weight: 600;
  font-size: 14px;
  margin-right: 6px;
  display: block;
}
.text-desc {
  font-weight: 400;
  font-size: 13px;
  color: #1c1d21;
  display: block;
}

//.v-select__selections {
//  min-height: 20px !important;
//}
</style>
