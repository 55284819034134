import { open } from '@/components/print-forms'

export function generateAdditionalPdf(DFLPrintData) {
    const blank = {
        margin: [0, 0, 0, 30],
        layout: { defaultBorder: true },
        table: {
            widths: ['100%'],
            body: [
                [
                    [
                        {
                            text: 'Прошито, пронумеровано та скріплено печатками _____ (______________________________________________________________) аркушів',
                            margin: [-2, 2, 0, 20]
                        },
                        {
                            layout: 'noBorders',
                            table: {
                                widths: ['1%', '44%', '10%', '44%', '1%'],
                                body: [
                                    [
                                        { text: '' },
                                        { text: 'ЛІЗИНГОДАВЕЦЬ:', bold: true, alignment: 'center' },
                                        { text: '' },
                                        { text: 'ЛІЗИНГООДЕРЖУВАЧ:', bold: true, alignment: 'center'},
                                        { text: '' },
                                    ],
                                    [
                                        { text: '' },
                                        { text: DFLPrintData.lessorName, bold: true, alignment: 'center', margin: [0, 0, 0, 25] },
                                        { text: '' },
                                        { text: DFLPrintData.lesseeFullName, bold: true, alignment: 'center' },
                                        { text: '' },
                                    ],
                                    [
                                        { text: '' },
                                        { text: DFLPrintData.lessorSignerPost, margin: [0, 0, 0, 7] },
                                        { text: '' },
                                        { text: '' },
                                        { text: '' },
                                    ],
                                    [
                                        { text: '' },
                                        { text: DFLPrintData.lessorSignerNameByGost, alignment: 'right' },
                                        { text: '' },
                                        { text: DFLPrintData.lesseeSignerNameByGost, alignment: 'right' },
                                        { text: '' },
                                    ],
                                    [
                                        { text: '' },
                                        { text: '_____________________', margin: [0, 15, 0, 0] },
                                        { text: '' },
                                        { text: '_____________________', margin: [0, 15, 0, 0] },
                                        { text: '' },
                                    ],
                                    [
                                        { text: '' },
                                        { text: 'підпис', italics: true, margin: [35, 0, 0, 0] },
                                        { text: '' },
                                        { text: 'підпис', italics: true, margin: [35, 0, 0, 0] },
                                        { text: '' },
                                    ],
                                    [
                                        { text: '' },
                                        { text: 'М.П.', alignment: 'center' },
                                        { text: '' },
                                        { text: 'М.П. (при наявності)', alignment: 'center', margin: [0, 0, 0, 10] },
                                        { text: '' },
                                    ],
                                ],
                            },
                        },
                    ],
                ]
            ]
        }
    }
    const content = [
        Object.clone(blank),
        Object.clone(blank),
        Object.clone(blank),
    ]
    return { open: () => open(content, {}) }
}
