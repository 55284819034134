<template>
  <div class="mt-2">
    <v-data-table
      :items="connections"
      :headers="dflConnectionsHeaders"
      item-value="id">
      <template #item.number="{ item }">
        <div class="d-flex align-center">
          <v-icon color="#09487a" class="mr-2">mdi-file-document</v-icon>

          <ALink
            :route="{
              name: item.routeName,
              params: { id: item.id },
            }"
            :text="item.number" />
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import ALink from '@/components/Link.vue'
import { computed } from 'vue'
import { dflConnectionsHeaders } from '@/utils/tableHeaders'
import { toFormatDate } from '@/utils/helperFunc.js'

export default {
  name: 'DflСonnections',
  components: { ALink },
  props: {
    contract: { type: Object },
  },
  data: () => {
    return { dflConnectionsHeaders }
  },
  setup(props) {
    const connections = computed(() => {
      const list = []

      if (props.contract.dkp) {
        const { id, number, date, status } = props.contract.dkp
        list.push({
          type: 'Договір купівлі-продажу',
          routeName: 'dkp-contract',
          id,
          number,
          date: toFormatDate(date, true),
          status,
        })
      }

      props.contract.extraAgreements?.forEach(agreement => {
        const { id, number, date, status } = agreement
        list.push({
          type: 'Додаткова угода',
          routeName: 'du-dfl-agreement',
          id,
          number,
          date: toFormatDate(date, true),
          status,
        })
      })

      props.contract.serviceContracts?.forEach(serviceContract => {
        const { id, number, date, status } = serviceContract
        list.push({
          type: 'Договір сервісу',
          routeName: 'service-contract',
          id,
          number,
          date: toFormatDate(date, true),
          status,
        })
      })

      return list
    })

    return { connections }
  },
}
</script>

<style scoped></style>
