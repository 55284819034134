<template>
  <div>
    <SectionLoader v-if="pageLoad"></SectionLoader>
    <div v-if="!pageLoad">
      <v-row class="mb-2">
        <v-col :cols="12" :sm="9" class="pt-0 pb-0">
          <span class="title-name">{{ video.name }}</span>
        </v-col>
        <v-col :cols="12" :sm="3" class="pt-0 pb-0">
          <div class="label text-right">
            {{ formatDate(video.created_at) }}
          </div>
        </v-col>
      </v-row>
      <div class="iframe-wrapper">
        <video v-if="base64" controls class="keep-aspect">
          <source
            :src="base64"
            :type="`video/${(video.url || '').split('.').pop()}`" />
        </video>
        <iframe
          v-if="youtubeLink"
          title="YouTube video player"
          type="text/html"
          width="auto"
          class="keep-aspect"
          :src="youtubeLink"
          frameborder="0"
          allowfullscreen>
        </iframe>
      </div>
    </div>
  </div>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'
import { urlFaqGetVideoById } from '@/pages/request'
import { isValidUrl } from '@/utils/helperFunc'
import { formatDate } from '@/utils/formatFunc'
export default {
  components: {
    SectionLoader,
  },
  data: () => ({
    video: {
      name: null,
      url: null,
    },
    base64: null,
    youtubeLink: null,
    pageLoad: false,
  }),
  computed: {
    videoId() {
      return this.$route.params.id
    },
  },
  methods: {
    formatDate,
    getCurrentVideo() {
      this.pageLoad = true
      return this.$axios
        .get(urlFaqGetVideoById(this.$route.params?.id))
        .then(res => {
          Object.assign(this.video, res.data)
          const url = res.data.url
          const isValid = isValidUrl(url)
          this.pageLoad = false

          switch (true) {
            case isValid:
              return this.formatYoutubeLink(url)
            case !isValid:
              return this.setBase64(url)
          }
        })
        .catch(err => this.$err(err, () => (this.pageLoad = false)))
    },
    setBase64(url) {
      const locationUrl = location.origin + '/' + url

      fetch(locationUrl)
        .then(res => res.blob())
        .then(blob => {
          if (!blob.type.includes('video')) {
            throw new Error('Invalid file type', +blob.type)
          }
          const reader = new FileReader()
          reader.onload = e => (this.base64 = e.target.result)
          reader.readAsDataURL(blob)
        })
        .catch(this.$err)
    },
    formatYoutubeLink(url) {
      const separator = '?v='
      const youtube = location.protocol + '//www.youtube.com/embed/'
      const id = (url || separator).split(separator)[1].split('&')[0]
      this.youtubeLink = youtube + id
    },
  },
  created() {
    this.getCurrentVideo()
  },
}
</script>
<style scope>
.iframe-wrapper {
  position: relative;
  padding-top: 56.25%;
}
.iframe-wrapper .keep-aspect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.title-name {
  color: #174f7f;
  font-size: 16px !important;
  display: block;
  font-weight: 700;
}
</style>
