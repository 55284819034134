<template>
  <div>
    <div
      class="chapter"
      :style="fullHeight ? { height: '100vh' } : { height: '100%' }">
      <div class="chapter-title d-flex align-center justify-space-between">
        <span>{{ title }}</span>
        <AddBtn
          v-if="addAction && !readonly"
          data-readonly="hide"
          style="font-size: 12px; font-weight: 500"
          @click="() => addAction()" />
      </div>
      <div class="chapter-general">
        <slot name="general-fields"></slot>
        <slot name="agreements"></slot>
        <slot name="default"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import AddBtn from '@/components/AddBtn.vue'

export default {
  components: { AddBtn },
  props: {
    title: { type: String, required: true },
    fullHeight: { type: Boolean },
    readonly: { type: Boolean },
    addAction: { type: Function },
  },
}
</script>
